<div class="layout-wrapper" *ngIf="!pageLoading">
  <div class="wrapper">
    <div *ngIf="!showThanks">
      <div class="title">We're Sorry to See You Go, {{ prospectPortalData.customerName }}</div>
      <div class="green-bar"></div>
      <div class="paragraph">
        Thank you for considering InsureShield Shipping Insurance. We are committed to providing {{ prospectPortalData.company }} with the coverage needed to succeed.
      </div>
      <div class="paragraph">
        To help us improve our service, could you please share the reason why you decided to decline our coverage?
      </div>
      <form [formGroup]="declineForm">
        <mat-radio-group formControlName="option">
          <mat-radio-button value="high">
            The quote is too high
          </mat-radio-button>
          <mat-radio-button value="already">
            I already have coverage
          </mat-radio-button>
          <mat-radio-button value="chat">
            I'd like to chat with a representative about my insurance options
          </mat-radio-button>
          <mat-radio-button value="other">
            Other
          </mat-radio-button>
          <mat-error *ngIf="option.errors && option.touched">Please select an option.</mat-error>
        </mat-radio-group>
        <mat-form-field class="other" *ngIf="option.value == 'other'">
          <mat-label>Other</mat-label>
          <textarea matInput formControlName="other"></textarea>
          <mat-error>Other is required.</mat-error>
        </mat-form-field>
        <button class="upsc-primary-button icon-right" type="submit" *ngIf="!submitting" (click)="submit()">Submit <mat-icon>chevron_right</mat-icon></button>
        <button class="upsc-primary-button icon-right" disabled *ngIf="submitting">Submit <span class="spinner-border"></span></button>
      </form>
    </div>
    <div *ngIf="showThanks">
      <div class="title">Thank you for your feedback, {{ prospectPortalData.customerName }}</div>
      <div class="green-bar"></div>
      <div class="paragraph">If your business needs change, please do not hesitate to contact us again.</div>
      <button class="upsc-primary-button icon-right mb-3" (click)="homepage()">Go to Homepage <mat-icon>chevron_right</mat-icon></button>
    </div>
  </div>
</div>

<div class="system-error" *ngIf="systemError">
  Something went wrong. Please try again later.
</div>

<div class="spinner d-flex justify-content-center" *ngIf="pageLoading">
  <div class="spinner-border">
      <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>