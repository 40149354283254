<div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
  <div class="spinner-border">
    <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>
<div class="wrapper-tupss" *ngIf="!showSpinner">
  <div class="d-flex flex-column-reverse flex-md-row">
    <div class="left">
      <div class="title" [innerHTML]="'franchiseclaimportal.storeNumberIntro' | translate"></div>
      <div *ngIf="siteIds.length==0 && nextPage=='claims'" class="subtitle p-3" [innerHTML]="'franchiseclaimportal.storeNumberClaimsType' | translate"></div>
      <div *ngIf="siteIds.length==0 && nextPage=='policy'" class="subtitle p-3" [innerHTML]="'franchiseclaimportal.storeNumberPolicyType' | translate"></div>
      <div *ngIf="siteIds.length>0 && nextPage=='claims'" class="subtitle p-3" [innerHTML]="'franchiseclaimportal.storeNumberClaimsSelect' | translate"></div>
      <div *ngIf="siteIds.length>0 && nextPage=='policy'" class="subtitle p-3" [innerHTML]="'franchiseclaimportal.storeNumberPolicySelect' | translate"></div>
      <form [formGroup]="franchiseForm" (keyup.enter)="franchiseForm.valid">
        <div class="myclaims-info">
          <div class="p-3">
            <div class="">
              <!-- <div class="pb-2">{{ 'franchiseclaimportal.storeNumber' | translate }}*</div> -->
              <mat-form-field class="field">
                <input matInput required formControlName="siteIds" placeholder="{{ 'franchiseclaimportal.storeNumber' | translate }}" [matAutocomplete]="auto" (click)="emptyCheck()" maxlength="4">
                <mat-autocomplete #auto>
                  <mat-option *ngFor="let siteId of siteIds | carrierSearch : querySite" [value]="siteId">{{siteId}}</mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="franchiseForm.controls['siteIds'].errors">
                  <div *ngIf="franchiseForm.get('siteIds').errors?.required">
                    {{ 'franchiseclaimportal.storeNumberFromList' | translate }}
                  </div>
                  <div *ngIf="franchiseForm.controls['siteIds'].errors?.pattern">
                    {{ 'claims.edit.start.tracking.form.trackingNumber.errors.pattern' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="pl-3" *ngIf="errors && errors.length > 0">
            <upsc-notification-box class="error-box" [isDanger]="true">
              <span class="icon"><mat-icon>cancel_outline</mat-icon></span>
              <span class="errors">
                {{ errors[0] | translate }}
              </span>
            </upsc-notification-box>
          </div>
          <button class="upsc-button ml-3" [disabled]="!franchiseForm.valid" (click)="nextStep()" href="#">
            {{ (nextPage == 'claims' ? 'franchiseclaimportal.storeToClaims' : (nextPage == 'policy' ? 'franchiseclaimportal.storeToPolicy' : 'franchiseclaimportal.continue')) | translate }}
          </button>
        </div>
      </form>
    </div>
    <!-- <div class="right d-none d-md-block mb-md-0">
        <div class="side-image">
          <img src="assets/images/gettyimages-607975305-2048x2048.png">
        </div>
    </div> -->
  </div>
</div>