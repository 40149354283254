import { UserService } from '../../../services/user.service';
import { Component, HostBinding, OnInit } from '@angular/core';
import { PolicyService } from 'src/app/services/policy.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactUsComponent } from '../../dialogs/contact-us/contact-us.component';
import { UtilityService } from 'src/app/services/utility.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ClaimStats } from '../../models/claim.interface';
import { ClaimService } from 'src/app/services/claim.service';
import { TaggingService } from 'src/app/tags/tagging.service';
import { UserPermission } from '../../models/user-permission';
import { DeliveryDefenseAgreementComponent } from '../delivery-defense-agreement/delivery-defense-agreement.component';
import { DeliveryDefenseErrorComponent } from '../delivery-defense-error/delivery-defense-error.component';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'upsc-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @HostBinding('class') public hostClass = 'navigation';
  isAdjuster: boolean;
  isGuestClaim: boolean;
  isGuestUser: boolean;
  showBilling: boolean;
  isHPGuestUser: boolean;
  popoverPosition$: any;
  hasInsights: boolean;
  hasSmartUpload: boolean;
  hasDeliveryDefense: boolean;
  hasShipVision: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;

  claimStats: ClaimStats;
  showStats: boolean;

  isDdUser: boolean = false;

  showRLSNav: boolean; //whether to show navigation by role level security

  public userPermission: UserPermission = new UserPermission();

  constructor(private userService: UserService,
    private policyService: PolicyService,
    private utilityService: UtilityService,
    private claimService: ClaimService,
    private router: Router,
    private taggingService: TaggingService,
    private loginService: LoginService,
    public dialog: MatDialog) {
    this.popoverPosition$ = this.utilityService.popoverPositionWatch();
  }

  public ngOnInit() {
    this.isAdjuster = this.userService.isUserAdjuster();
    this.isGuestClaim = this.userService.isGuestClaim();
    this.isHPGuestUser = this.userService.isHPGuestUser();
    this.isGuestUser = this.userService.isGuestUser();
    this.showBilling = this.policyService.isGWPolicy();
    this.hasInsights = this.policyService.hasInsights();
    this.hasSmartUpload = this.policyService.hasSmartUpload();
    this.hasDeliveryDefense = this.policyService.hasDeliveryDefense();
    this.hasShipVision = this.policyService.hasShipVision();

    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();

    if (this.userService.getUserInfo().policyNumber) {
      this.claimService.getClaimStats(this.loginService.getPolicyDetails().policyNumber).subscribe(
        data => {
          this.claimStats = data;
          this.showStats = this.claimStats.claimsWithNewMessagesCnt > 0;
        }
      );
    }

    if (this.userService.getUserInfo().userRoleID === '7') {
      this.isDdUser = true;
    }

    this.userPermission = this.userService.getUserPermissions();
    this.showRLSNav = !this.userService.isInternalUser() || this.policyService.hasPolicyDetails();
  }

  public ngOnDestroy() {
    sessionStorage.removeItem('retainState');
    sessionStorage.removeItem('isPostBack');
    sessionStorage.removeItem('pageId');
    sessionStorage.removeItem('isadvanced');

  }
  public openContactUsDialog(): void {
    this.taggingService.link({
      link_name: 'contact_us_header_tab'
    });
    const dialogRef = this.dialog.open(ContactUsComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  startSmartUpload(event) {
    let smartUploadURL = environment.smartUpload.url;

    this.policyService.getSmartUploadUrl({
      policyno: this.loginService.getPolicyDetails().policyNumber,
      username: this.userService.getUserInfo().firstName
    }).subscribe(
      data => {
        if (data.url && data.url != '') {
          smartUploadURL = data.url;
        }
        window.open(smartUploadURL);
      },
      error => {
        window.open(smartUploadURL);
      }
    );
  }

  validateDeliveryDefense(event) {
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    if (policyDetails && policyDetails.ref2) { // this checks to see if DD agreement has already been accepted
      // Call startDeliveryDefense to reroute to DD
      this.startDeliveryDefense(event);
    } else { // this means ref2 was null or non-existent, which signals for us to display the pop-up
      const dialogRef = this.dialog.open(DeliveryDefenseAgreementComponent);
    }
  }

  // call startDeliveryDefense only if they've accepted terms and conditions- which is indicated by the presence of the ref2 field in policyDetails
  startDeliveryDefense(event) {
    this.policyService.getDeliveryDefenseUrl({
      emailId: this.userService.getUserInfo().emailAddress,
      contractNumber: JSON.parse(sessionStorage.getItem('policyDetails')).ref2

    }).subscribe(
      res => {
        if (res.data.url && res.data.url != '') {
          window.open(res.data.url);
        } else {
          let matDialog2 = this.dialog.open(DeliveryDefenseErrorComponent);
        }
      },
      error => {
        let matDialog = this.dialog.closeAll();
        let matDialog2 = this.dialog.open(DeliveryDefenseErrorComponent);
      }
    );
  }

  openShipVision(evt) {
    if (JSON.parse(sessionStorage.getItem('policyDetails')).shipVision) {
      window.open(JSON.parse(sessionStorage.getItem('policyDetails')).shipVision);
    } else {
      this.router.navigate(['/visibility']);
    }
  }

  gotoMessages(event: any) {
    this.router.navigate(['claims', 'message-list']);
  }

  tagging(type) {
    if (type == 'dashboard') {
      this.taggingService.link({ link_name: 'dashboard_header_tab' });
    } else if (type == 'policies') {
      this.taggingService.link({ link_name: 'policies_header_tab' });
    } else if (type == 'claims') {
      this.taggingService.link({ link_name: 'claims_header_tab' });
    } else if (type == 'billing') {
      this.taggingService.link({ link_name: 'billing_header_tab' });
    }
  }
}
