import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ISummary } from '../../../../shared/models/claim.interface';
import { IPolicies } from '../../../../shared/models/policies.interface';
import { BillingService } from 'src/app/services/billing.service';
import * as FileSaver from 'file-saver';
import { UserService } from 'src/app/services/user.service';
import { ExportService } from 'src/app/services/export.service';
import { CurrencyPipe, DatePipe } from '@angular/common';

const FILENAME = "transaction_history";

export interface oneTransactionSummary {
  shipDate: string;
  invoiceNo: string;
  trackingNo: string;
  dataSource: string;
  insuredValue: string;
  premiumAmt: string;
  policyNumber: string;
  orderNumber: string;
  claimStatus: string;
}

@Component({
  selector: 'upsc-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent {

  excelSelected = true;
  isCBPPolicy = false;
  pdfSelected = false;
  policy: IPolicies = {};
  summaryRequest: ISummary = {};
  billingSummaryJson: any[] = [];
  billingSummaryPHJson: any[] = [];
  loading = false;
  tableObj;
  documentList;
  invoiceCBPList;
  errors = [];
  tHistoryError = false;
  searchFiltersFormattedForRequests: any;
  selectedTHInvoices = [];
  typeOfDownload: 'transaction-history' | 'billing-invoice' = 'billing-invoice'; //transaction-history, or regular billing invoice downloads
  completeTransactionJSONStructure: oneTransactionSummary[] = [];
  isUspsUser: boolean;
  isDDUser: boolean;
  isXLshow: boolean = true;

  constructor (
    public dialogRef: MatDialogRef<ExportComponent>,
    private billingService: BillingService,
    private userService: UserService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private exportService: ExportService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    if (data.type && data.type == 'transaction-history') {
      this.typeOfDownload = 'transaction-history';
      if (data.filter) {
        this.searchFiltersFormattedForRequests = data.filter;
        this.selectedTHInvoices = data.selected;
      } else {
        this.searchFiltersFormattedForRequests = null;
        this.selectedTHInvoices = data.selected;
      }
    } else {
      this.tableObj = data.tableObj;
      this.documentList = data.documentList;
      this.invoiceCBPList = data.invoices; //PDF2024
    }

    this.isDDUser = this.userService.isDeliveryDefenseUser();
    this.isUspsUser = this.userService.isUSPSUser();
    if(this.isDDUser || this.isUspsUser) { //if the policy is from CBP then hide XL option.
      this.isXLshow = false;
      this.pdfSelected = true;
      this.isCBPPolicy = true;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
  
  download() {
    this.errors = [];
    this.loading = true;
    if((this.pdfSelected) && (this.isCBPPolicy)) { //PDF2024
        if (this.typeOfDownload == 'transaction-history') {
          this.downloadTHistorySummary('pdf');
        } else {
          this.downloadPDFInvoiceFilesCBP();
        }
    }
    else if (this.excelSelected && !this.isCBPPolicy) {
      if (this.typeOfDownload == 'transaction-history') {
        this.downloadTHistorySummary('excel');
      } else {
        this.downloadExcelInvoiceFiles();
      }
    } else if (this.pdfSelected && !this.isCBPPolicy) {
      if (this.typeOfDownload == 'transaction-history') {
        this.downloadTHistorySummary('pdf');
      } else {
        this.downloadPDFInvoiceFiles();
      }
    }
  }

  //This one downloads files from GW/CBP
  downloadExcelInvoiceFiles() {
    //First try to download xlsx files if they are available - this will have a full list of invoice info.
    let invoiceListWithPubIDsXLSX = this.getAllPublicIDsToDownload('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    if (invoiceListWithPubIDsXLSX.length > 0) {
      for (let i=0; i<invoiceListWithPubIDsXLSX.length; i++) {
        this.billingService.invoiceDownload(invoiceListWithPubIDsXLSX[i].publicId).subscribe(
          data => {
            if (data.headers.get('Content-Type') == 'application/json') {
              //Just json was sent to us. Most likely an error message
              this.errors.push({invoiceNumber: invoiceListWithPubIDsXLSX[i].invoiceNumber});
            } else {
              let file = new Blob([data.body], { type: data.headers.get('Content-Type') });
              FileSaver.saveAs(file, invoiceListWithPubIDsXLSX[i].invoiceNumber + "." + 'xlsx');
            }
          }, error => {
            this.errors.push({invoiceNumber: invoiceListWithPubIDsXLSX[i].invoiceNumber});
          }
        );
        if (i==invoiceListWithPubIDsXLSX.length-1) {
          this.loading = false;
        }
      }
    } else {
      //If no xlsx files were found - then try to download csv. CSV is less ideal. Business wanted to prioritize the xlsx files.
      let invoiceListWithPubIDs = this.getAllPublicIDsToDownload('application/csv');
      if (invoiceListWithPubIDs.length > 0) {
        for (let i = 0; i<invoiceListWithPubIDs.length; i++) {
          this.billingService.invoiceDownload(invoiceListWithPubIDs[i].publicId).subscribe(
            data => {
              if (data.headers.get('Content-Type') == 'application/json') {
                //Just json was sent to us. Most likely an error message
                this.errors.push({invoiceNumber: invoiceListWithPubIDs[i].invoiceNumber});
              } else {
                let file = new Blob([data.body], { type: data.headers.get('Content-Type') });
                FileSaver.saveAs(file, invoiceListWithPubIDs[i].invoiceNumber + "." + 'csv');
              }
            }, error => {
              this.errors.push({invoiceNumber: invoiceListWithPubIDs[i].invoiceNumber});
            }
          );
          if (i==invoiceListWithPubIDs.length-1) {
            this.loading = false;
          }
        }
      } else {
        this.loading = false;
      }
    }
  }

  //This one downloads files from GW/CBP
  downloadPDFInvoiceFiles() {
    let invoiceListWithPubIDs = this.getAllPublicIDsToDownload('application/pdf');
    if (invoiceListWithPubIDs.length > 0) {
      for (let i = 0; i<invoiceListWithPubIDs.length; i++) {
        this.billingService.invoiceDownload(invoiceListWithPubIDs[i].publicId).subscribe(
          data => {
            if (data.headers.get('Content-Type') == 'application/json') {
              //Just json was sent to us. Most likely an error message
              this.errors.push({invoiceNumber: invoiceListWithPubIDs[i].invoiceNumber});
            } else {
              let file = new Blob([data.body], { type: data.headers.get('Content-Type') });
              FileSaver.saveAs(file, invoiceListWithPubIDs[i].invoiceNumber + "." + 'pdf');
            }
          }, error => {
            this.errors.push({invoiceNumber: invoiceListWithPubIDs[i].invoiceNumber});
          }
        );
        if (i==invoiceListWithPubIDs.length-1) {
          this.loading = false;
        }
      }
    } else {
      this.loading = false;
    }
  }

  downloadPDFInvoiceFilesCBP() { //PDF2024
    let invoiceListWithPubIDs = this.getAllPublicIDsToDownloadCBP();
    if (invoiceListWithPubIDs.length > 0) {
      for (let i = 0; i<invoiceListWithPubIDs.length; i++) {
        this.billingService.invoiceDownloadPDFCBP(invoiceListWithPubIDs[i].invoiceNumber).subscribe(
          data => {
            if (data.headers.get('Content-Type') == 'application/json') {
              //Just json was sent to us. Most likely an error message
              this.errors.push({invoiceNumber: invoiceListWithPubIDs[i].invoiceNumber});
            } else {
              let file = new Blob([data.body], { type: data.headers.get('Content-Type') });
              FileSaver.saveAs(file, invoiceListWithPubIDs[i].invoiceNumber + "." + 'pdf');
            }
          }, error => {
            this.errors.push({invoiceNumber: invoiceListWithPubIDs[i].invoiceNumber});
          }
        );
        if (i==invoiceListWithPubIDs.length-1) {
          this.loading = false;
        }
      }
    } else {
      this.loading = false;
    }
  }

  getAllPublicIDsToDownload(fileType): any[] {
    let invoiceList = JSON.parse(localStorage.getItem('selectedRecentInvoices'));
    let historyList = JSON.parse(localStorage.getItem('selectedpaymenthistory'));
    let invoiceListWithPublicIDs = [];
    if (invoiceList) {
      for (let i = 0; i<invoiceList.length; i++) {
        let invoice = this.documentList.find(x => x.invoiceNumber == invoiceList[i].invoiceNumber && x.mimeType == fileType);
        if (invoice) {
          invoiceListWithPublicIDs.push({publicId: invoice.publicId.split(":")[1], invoiceNumber: invoice.invoiceNumber });
        } else {
          //Some invoices were not able to be found with documents. We should only add an error here if fileType == 'application/csv'
          //We do this because it will always run through XLSX format first. So only if it cannot find any XLSX, AND CSV, then add it to the error list.
          //PDF only runs through this once so just add that immediately to the error list.
          if (fileType == 'application/csv' || fileType == 'application/pdf') {
            this.errors.push({invoiceNumber: invoiceList[i].invoiceNumber});
          }
        }
      }
    }

    if (historyList) {
      for (let i = 0; i<historyList.length; i++) {
        let invoice = this.documentList.find(x => x.invoiceNumber == historyList[i].invoiceNumber && x.mimeType == fileType);
        if (invoice) {
          invoiceListWithPublicIDs.push({publicId: invoice.publicId.split(":")[1], invoiceNumber: invoice.invoiceNumber });
        } else {
          //Some invoices unavailable for download? Show them the invoice number not allowed to be downloaded
          if (this.errors.find(x => x.invoiceNumber == historyList[i].invoiceNumber)) {
            //About to put a duplicate invoice number in the error list. ignore it.
          } else {
            //Putting an invoice number in the error list
            this.errors.push({invoiceNumber: historyList[i].invoiceNumber});
          }
        }
      }
    }
    return invoiceListWithPublicIDs;
  }

  getAllPublicIDsToDownloadCBP() { //PDF2024
    let invoiceList = JSON.parse(localStorage.getItem('selectedRecentInvoices'));
    let historyList = JSON.parse(localStorage.getItem('selectedpaymenthistory'));
    let invoiceListWithPublicIDs = [];
    if (invoiceList) {
      for (let i = 0; i<invoiceList.length; i++) {
          invoiceListWithPublicIDs.push({invoiceNumber: invoiceList[i].invoiceNumber});
      }
    }
    if (historyList) {
      for (let i = 0; i<historyList.length; i++) {
          invoiceListWithPublicIDs.push({invoiceNumber: historyList[i].invoiceNumber});
      }
    }
    return invoiceListWithPublicIDs;
  }

  //This relies on UI to create files (Transaction History Tables)
  downloadTHistorySummary(type: 'excel' | 'pdf') {
    if (this.selectedTHInvoices?.length > 0) { // User did select invoices manually, so use those specified tracking numbers:
      this.gatherSummary(this.selectedTHInvoices, type);
    } else { // user did not manually select invoices to download, we will search by their advanced filter (or by all invoices if no filter selected)
      //Get all the IDs
      let policyNum = this.userService.getUserInfo().policyNumber
      
      // using advanced filter on TH page the user selected, 
      this.billingService.getTHistoryTrackingNumberList({policyNum, isInvoiced: this.data.isInvoiced, ...this.searchFiltersFormattedForRequests}).subscribe(
        tnList => {
          if (tnList && tnList?.message && tnList?.message.toLowerCase() == 'succeeded' && tnList?.data && tnList?.data.totalCount > 0) {
            this.gatherSummary(tnList.data.trackingNumbers, type);
          } else {
            this.tHistoryError = true;
            this.loading = false;
          }
        }, error => {
          this.tHistoryError = true;
          this.loading = false;
        }
      );  
    }
  }

  gatherSummary(listOfTNs: any[], type: 'excel' | 'pdf') {
    let request = {
      isInvoiced: this.data.isInvoiced,
      trackingNumbers: listOfTNs
    }
    let locale = sessionStorage.getItem('locale')?.toLowerCase();
    this.billingService.getTHistoryFullTrackingNumberData(request).subscribe(
      summary => {
        if (summary && summary.message && summary.message.toLowerCase() == 'succeeded') {
          let arraydata = summary.data.data_array;
          //Form a JSON Array using the data gotten from the summary API
          for (let i = 0; i < arraydata.length; i++) {
            this.completeTransactionJSONStructure.push({
              shipDate: this.datePipe.transform(arraydata[i]?.shipDate, 'MM/dd/yy', 'UTC'),
              invoiceNo: arraydata[i].invoiceNo,
              trackingNo: arraydata[i].trackingNo,
              dataSource: arraydata[i].dataSource,
              insuredValue: locale?.includes('fr') ? this.currencyPipe.transform(arraydata[i]?.insuredValue, 'EUR') : locale?.includes('de') ? this.currencyPipe.transform(arraydata[i]?.insuredValue, 'EUR') : locale?.includes('gb') ? this.currencyPipe.transform(arraydata[i]?.insuredValue, 'GBP') : locale?.includes('ca') ? this.currencyPipe.transform(arraydata[i]?.insuredValue, 'CAD') : locale?.includes('it') ? this.currencyPipe.transform(arraydata[i]?.insuredValue, 'EUR') : this.currencyPipe.transform(arraydata[i]?.insuredValue, 'USD'),
              premiumAmt: locale?.includes('fr') ? this.currencyPipe.transform(arraydata[i]?.premiumAmt, 'EUR') : locale?.includes('de') ? this.currencyPipe.transform(arraydata[i]?.premiumAmt, 'EUR') : locale?.includes('gb') ? this.currencyPipe.transform(arraydata[i]?.premiumAmt, 'GBP') : locale?.includes('ca') ? this.currencyPipe.transform(arraydata[i]?.premiumAmt, 'CAD') : locale?.includes('it') ? this.currencyPipe.transform(arraydata[i]?.premiumAmt, 'EUR') : this.currencyPipe.transform(arraydata[i]?.premiumAmt, 'USD'),
              policyNumber: arraydata[i].policyNumber,
              orderNumber: arraydata[i].orderNumber,
              claimStatus: arraydata[i].claimStatusName == "Claim Open" ? 
              (arraydata[i].claimSubstatusName == "Submitted" ? "Claim Received" :
              arraydata[i].claimSubstatusName == "Submitted for Payment" ? "Submitted for Payment" : 
              arraydata[i].claimSubstatusName == "Paid" ? "Paid" :
              arraydata[i].claimSubstatusName?.includes("IDR Request") ? "Documents Pending" : 
              arraydata[i].claimSubstatusName == "Pending Search Request" ? "Investigation Pending" : "") :
              arraydata[i].claimStatusName == "Claim Closed" ? 
                (arraydata[i].resolution?.includes("Denied") ? "Denied" :
                arraydata[i].resolution == "Mistake" || arraydata[i].resolution == "Duplicate" || arraydata[i].resolution == "Other" || arraydata[i].resolution == "Fraud" ? "Closed" :
                arraydata[i].resolution == "Incomplete Documentation" ? "Documents Pending" :
                arraydata[i].resolution == "Payments Complete" ? "Paid" :
                arraydata[i].resolution == "Withdrawn" ? "Withdrawn" : "") : 
                arraydata[i].claimStatusName == "-" ? "-" : 
                arraydata[i].claimStatusName?.includes("Subro") ? "Subro" /*TO-DO: figure out the 4 subro scenarios*/ : "",
            });
          }
          //Once the loop has been executed to get all summary info,
          //Call export service to export to Excel / PDF
          if (type == 'excel') {
            this.exportService.transactionHistoryExportToExcel(this.completeTransactionJSONStructure, FILENAME, this.data.isInvoiced)
            this.loading = false;
          } else {
            this.exportService.transactionHistoryExportToPDF(this.completeTransactionJSONStructure, FILENAME, this.data.isInvoiced);
            this.loading = false;
          }
        } else {
          this.tHistoryError = true;
          this.loading = false;
        }
      }, error => {
        this.tHistoryError = true;
        this.loading = false;
      }
    );
  }
}
