import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { SharedModule } from '../../shared/shared.module';
import { InternalUserRoutingModule } from './internal-user-routing.module';
import { InternalUserComponent } from './internal-user.component';


@NgModule({
  declarations: [
    InternalUserComponent
  ],
  imports: [
    CommonModule,
    InternalUserRoutingModule,
    MatFormFieldModule,
    SharedModule
  ]
})
export class InternalUserModule { }
