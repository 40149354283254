import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/services/auth-guard.service';
import { TupssGuard } from 'src/app/pages/claims-custom/tupss/services/tupss.guard';
import { TranslationLoaderResolver } from 'src/app/resolvers/translation-loader.resolver';
import { ClaimListComponent } from '../claims/components/list/claim-list.component';
import { PoliciesComponent } from './tupss/franchise-portal/policy/policies.component';
import { ClaimsCustomComponent } from './claims-custom.component';
import { FranchiseStartComponent } from './tupss/franchise-portal/franchise-start/franchise-start.component';
import { SiteSelectionComponent } from './tupss/franchise-portal/site-selection/site-selection.component';
import { PolicyResolver } from './tupss/services/policy.resolver';
import { tupssDetailComponent } from './tupss/tupss-edit/components/detail/detail.component';
import { StartComponent } from './tupss/tupss-edit/components/start/start.component';
import { TupssEditComponent } from './tupss/tupss-edit/tupss-edit.component';
import { TupssLayoutComponent } from 'src/app/shared/components/tupss-layout/tupss-layout.component';
import { TFCPAuthComponent } from './tupss/franchise-portal/franchise-start/tfcp-auth.component';
import { DetailVerificationComponent } from './tupss/tupss-edit/components/detail-verification/detail-verification.component';
import { FaqComponent } from './tupss/faq/faq.component';
import { UserResolver } from './tupss/services/user.resolver';
import { TupssHomeFooterComponent } from 'src/app/shared/components/tupss-home-footer/tupss-home-footer.component';
import { ConfirmPackageStatusComponent } from './tupss/tupss-edit/components/confirm-package-status/confirm-package-status.component';
import { DeliveryStatusComponent } from './tupss/tupss-edit/components/delivery-status/delivery-status.component';
import { PaymentLandingComponent } from './my-choice/payment-landing/payment-landing.component';
import { ClaimsLandingComponent } from './my-choice/claims-landing/claims-landing.component';
import { CongratulationsComponent } from './my-choice/congratulations/congratulations.component';
import { MyChoicePolicyResolver } from './tupss/services/my-choice-policy.resolver';
import { CustomizedLandingComponent } from './customized-claims-portal/customized-landing/customized-landing.component';
import { FaqsComponent } from './customized-claims-portal/faqs/faqs.component';
import { DynamicLayoutComponent } from 'src/app/shared/components/dynamic-layout/dynamic-layout.component';
import { SelfEditComponent } from './self-service-portal/self-edit/self-edit.component';
import { SelfFaqComponent } from './self-service-portal/components/self-faq/self-faq.component';
import { SelfDetailsComponent } from './self-service-portal/components/self-details/self-details.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: TupssLayoutComponent,
      resolve: { mode: TranslationLoaderResolver },
      children: [
        {
          path: '',
          component: ClaimsCustomComponent,
          children: [
            {
              path: '', component: TupssHomeFooterComponent,
              children: [
                {
                  path: 'tccp', component: StartComponent
                },
                {
                  path: 'tfcp', component: FranchiseStartComponent, canActivate: [TupssGuard]
                },
                {
                  path: 'tfcp/store', component: SiteSelectionComponent, //canActivate: [AuthGuardService],
                  resolve: { userData: UserResolver }
                }
              ]
            },
            {
              path: 'tupss/file', component: TupssEditComponent, canActivate: [TupssGuard], //, canActivate: [AuthGuardService],
              resolve: { policyDetails: PolicyResolver }
            },
            {
              path: 'tupss/faqs', component: FaqComponent,//, canActivate: [AuthGuardService],
              resolve: { userData: UserResolver }
            },
            {
              path: 'tupss/detail', component: tupssDetailComponent, // canActivate: [AuthGuardService],
              resolve: { policyDetails: PolicyResolver }
            },
            {
              path: 'tccp/confirm-package-status', component: ConfirmPackageStatusComponent, // canActivate: [AuthGuardService],
              resolve: { userData: UserResolver }
            },
            {
              path: 'tfcp/claims', component: ClaimListComponent, //canActivate: [AuthGuardService],
              data: { 'page': 'claims' },
              resolve: { userData: UserResolver, policyDetails: PolicyResolver }
            },
            {
              path: 'tfcp/policy', component: PoliciesComponent, //canActivate: [AuthGuardService],
              data: { 'page': 'policy' },
              resolve: { userData: UserResolver, policyDetails: PolicyResolver }
            },
            {
              path: 'tupss/detail-verification', component: DetailVerificationComponent, canActivate: [TupssGuard]
            },
            {
              path: 'tupss/delivery-status', component: DeliveryStatusComponent, canActivate: [TupssGuard]
            },
            {
              path: 'ups-my-choice', component: PaymentLandingComponent,
            },
            {
              path: 'ups-my-choice/claim-portal', component: ClaimsLandingComponent
            },
            {
              path: 'ups-my-choice/claim-portal/file', component: TupssEditComponent
            },
            {
              path: 'ups-my-choice/congratulations', component: CongratulationsComponent
            },
            {
              path: 'ups-my-choice/detail', component: tupssDetailComponent,
              resolve: { policyDetails: MyChoicePolicyResolver }
            },
            {
              path: 'unishippers', component: CustomizedLandingComponent,
            },
            {
              path: 'unishippers/file', component: TupssEditComponent
            },
            {
              path: 'unishippers/detail', component: tupssDetailComponent,
              resolve: { policyDetails: MyChoicePolicyResolver }
            },
            {
              path: 'unishippers/faq', component: FaqsComponent
            },
            {
              path: 'ajg', component: CustomizedLandingComponent,
            },
            {
              path: 'ajg/file', component: TupssEditComponent
            },
            {
              path: 'ajg/detail', component: tupssDetailComponent,
              resolve: { policyDetails: MyChoicePolicyResolver }
            },
            {
              path: 'ajg/faq', component: FaqsComponent
            },{
              path: 'inxpress', component: CustomizedLandingComponent,
            },
            {
              path: 'inxpress/file', component: TupssEditComponent
            },
            {
              path: 'inxpress/detail', component: tupssDetailComponent,
              resolve: { policyDetails: MyChoicePolicyResolver }
            },
            {
              path: 'inxpress/faq', component: FaqsComponent
            },                       
            {
              path: 'guest/detail', component: tupssDetailComponent,
              resolve: { policyDetails: MyChoicePolicyResolver }
            },
            {
              path: 'pirate-ship', component: CustomizedLandingComponent,
            },
            {
              path: 'pirate-ship/file', component: TupssEditComponent,
            },
            {
              path: 'pirate-ship/detail', component: tupssDetailComponent,
              resolve: { policyDetails: MyChoicePolicyResolver }
            }

          ]
        }
      ]
    }
  ])],
  exports: [RouterModule]
})
export class TUPSSRoutingModule { }
