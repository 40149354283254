import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PayeeService } from 'src/app/services/payee.service';
import { sessionInfo } from '../../models/payee.interface';
import { environment as ENV } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { IClaim, emptyClaim } from '../../models/claim.interface';

@Component({
  selector: 'upsc-payee-bank-popup',
  templateUrl: './payee-bank-popup.component.html',
  styleUrls: ['./payee-bank-popup.component.scss']
})
export class PayeeBankPopupComponent implements OnInit {

  ccDigits;
  payee;
  systemError = false;
  checkViaMail;
  currentPage;
  public claim: IClaim = emptyClaim;
  //sessionInfo used for initiate transfer session
  sessionInfo: sessionInfo = {
    "payeeId": "",
    "returnSessionId": "",  //This will be the same as payeeId. Used to update ACH indicator after adding bank info
    "returnUrl": `${ENV.baseUrl.web}claims/bankSuccess`
  };

  constructor(
    private payeeService: PayeeService,
    private router: Router,
    public dialogRef: MatDialogRef<PayeeBankPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.payee = data.payee;
    this.checkViaMail = data.checkViaMail;
    this.currentPage = data.currentPage;
    this.claim.policy.policyNumber = data.policyNum;
  }

  ngOnInit(): void {
    if (!this.checkViaMail) {
      this.payeeService.getCardInfo(this.payee.payeeID, this.claim.policy.policyNumber).subscribe(
        data => {
          if (data.accountNumberLast4) {
            this.systemError = false;
            this.ccDigits = data.accountNumberLast4;
          } else {
            this.systemError = true;
          }
        }, error => {
          this.systemError = true;
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  addBankInfo() {
    //Set the proper data for the session transfer request
    this.sessionInfo.payeeId = this.payee.payeeID;
    this.sessionInfo.returnSessionId = this.payee.payeeID;
    this.payeeService.displayPreexistingPayee = this.payee;  //Used for displaying preexisting payee info on bank page
    this.payeeService.recipientAddFlow.payeeFlow = "1";
    sessionStorage.setItem('selectedPayee', JSON.stringify({ "payeeID": this.sessionInfo.payeeId, "currentPage": this.currentPage }) );
    this.payeeService.initiatePublicSessionTransfer(this.sessionInfo).subscribe(
      data => {
        //Check if result.code == S031 for success
        if(data.result.code == 'S031') {
          this.payeeService.achURL = data.sessionTransferUrl;
        } else {
          //Send error notification to service, display error message instead of iframe
          this.payeeService.initiateSessionFailed = true;
        }
        //Always reroute
        this.close();
        this.router.navigate(['/claims/payee/banking']);
      }
    );
  }
}
