import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimService } from 'src/app/services/claim.service';
import { TupssService } from '../../../services/tupss.service';
import { DataService } from '../../../../../../services/data.service';
import { IShipmentDetailPage } from '../../../interfaces/claim-data.interface';
import { DynamicFlowService } from 'src/app/services/dynamic-flow.service';
import { IShippingDetailsAutofill } from 'src/app/shared/models/shippingDetails.interface';

@Component({
  selector: 'upsc-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})
export class ShipmentDetailsComponent implements OnInit {
  tupssInfo;
  showSpinner = true;
  userDetails: any;
  showSystemError = false;
  claimSubmittedAlready;
  flowType = this.dataService.flowType;
  easyDataDisplayObject: IShipmentDetailPage;

  constructor(
    private claimService: ClaimService,
    private tupssService: TupssService,
    private router: Router,
    private dataService: DataService,
    private dynamicFlowService: DynamicFlowService
  ) { }

  ngOnInit(): void {
    if (this.flowType == 'my-choice') {
      //my-choice flow - grab data based on the tracking number.
      this.getMyChoiceData();
    }
    // check if current flow is for channel partner portals
    else if (this.dataService.checkForCcp() === true) {
      //channel partner flow
      this.getCcpClaimsData();
    } else {
      //Check if the user submitted a claim and then pressed the back button or something like that. If they did, navigate back to the starting screens for TFCP and TCCP respectively.
      this.userDetails = this.tupssService.userDetails;
      this.claimSubmittedAlready = this.tupssService._claimSubmittedFor ? true : false;
      if (this.claimSubmittedAlready) {
        if (this.userDetails?.type == 'tfcp') {
          this.tupssService.claimSubmittedFor = null;
          this.router.navigate(['tfcp']);
        } else if (this.userDetails?.type == 'tccp') {
          this.tupssService.claimSubmittedFor = null;
          this.router.navigate(['tccp']);
        }
      }
      this.getiShipData();
    }
  }

  getiShipData() {
    let ts = new Date().getTime.toString();
    let payload;
    if (this.userDetails?.type == 'tccp') {
      payload = {
        transactionSrc: "Capital_TCCP",
        posReceiptID: this.userDetails.transactionID,
        trackNo: this.userDetails.trackingNumber,
        email: this.userDetails.email,
        shipmentId: this.userDetails.shipmentID
      }
    } else if (this.userDetails?.type == 'tfcp') {
      payload = {
        transactionSrc: "Capital_TFCP",
        trackNo: this.userDetails.trackingNumber
      }
    }

    this.claimService.getiShipData(payload).subscribe(
      data => {
        this.showSpinner = false;
        if (data.message != 'ValidationError') {
          this.showSystemError = false;
          this.tupssInfo = data;
          this.easyDataDisplayObject = {
            trackingNumber: this.userDetails.trackingNumber,
            name: this.tupssInfo?.data.response.cdsresponse.shipmentInfo.toAddress.company,
            city: this.tupssInfo?.data.response.cdsresponse.shipmentInfo.toAddress.city,
            state: this.tupssInfo?.data.response.cdsresponse.shipmentInfo.toAddress.stateProv,
            date: this.tupssInfo?.data.response.cdsresponse.shipmentInfo.shipDate,
            description: this.tupssInfo?.data.response.cdsresponse.shipmentInfo.description
          }
          this.claimService.setLocaliShipData(this.tupssInfo);
          this.tupssService.setSiteId(this.tupssInfo.data.response.cdsresponse.shipmentInfo.siteInfo.storeNumber);  //We set siteID here in case user refreshes the page. Service data is lost on refresh.
          if (!this.tupssService.userData?.contractId) {
            this.tupssService.setContractId(JSON.parse(sessionStorage.getItem('policyDetails')).policyNumber);
          }
        } else {
          this.showSystemError = true;
        }
      }, error => {
        this.showSystemError = true;
        this.showSpinner = false;
      }
    );
  }

  getMyChoiceData() {
    this.showSpinner = false;
    this.dataService.getTrack3ShipmentDetails().subscribe(
      data => {
        if (data) {
          let receiver = data.shipperConsigneeAddress.find(x => x.description.toLowerCase() == 'shipto address');
          //Fill up the displayObject using track 3 details
          this.easyDataDisplayObject = {
            trackingNumber: data.shipmentPackage.shipmentIdentificationNumber,
            name: receiver.companyName,
            city: receiver.city,
            state: receiver.stateProvinceCode,
            date: data.shipDate
          }
        } else {
          this.router.navigate(['/ups-my-choice/claim-portal']);
        }
      }
    );
  }

  //Get CCP Claims data based on channel partner
  getCcpClaimsData() {
    this.showSpinner = false;
    this.dataService.getChannelPartnerShipmentDetails().subscribe(
      data => {
        if (data) {
          this.easyDataDisplayObject = {
            trackingNumber: data.trackingNumber,
            name: data.consigneeName,
            city: data.destinationCity,
            state: data.destinationState,
            date: data.shipDate
          }
          this.getInsuredValue();


        } else {
          //redirect based on channel partner.
          this.dynamicFlowService.redirectToCcp();
        }
      }
    );
  }

   getInsuredValue() {
    try {
      const shipmentDetailsString = sessionStorage.getItem('channelPartner');      
      if (shipmentDetailsString) {
        const shipmentDetails = JSON.parse(shipmentDetailsString);        
        let insuredValue = shipmentDetails.insuredValue || 'N/A';        
        if (insuredValue !== 'N/A') {
          insuredValue = parseFloat(insuredValue).toFixed(2);
        }        
        this.easyDataDisplayObject.insuredValue = insuredValue;
      } else {
        this.easyDataDisplayObject.insuredValue = 'N/A';
      }
    } catch (error) {
      this.easyDataDisplayObject.insuredValue = 'N/A';
    }
  }


}
