import { ChangeDetectorRef, Component, HostBinding, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ClaimService } from 'src/app/services/claim.service';

@Component({
  selector: 'upsc-search-my-package',
  templateUrl: './search-my-package.component.html',
  styleUrls: ['./search-my-package.component.scss']
})
export class SearchMyPackageComponent implements OnInit {
  showSpinner: boolean;
  claimNumber: string;
  policyNumber: string;
  @Output() confirmationStatusSubmit = new EventEmitter<string>();

  public shippingStatus: any[] = [
    { id: '1', text: 'Continue Searching for My Package' },
    { id: '2', text: 'Stop Searching for My Package and Process My Claim' },
  ];

  shippingStatusFormGroup: UntypedFormGroup;
  isError: boolean = false;
  deliveryStatusRequest: any = {};
  timeLapsed: boolean;
  confirmed: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private claimService: ClaimService) {

    this.shippingStatusFormGroup = this.formBuilder.group({
      status: ['', Validators.compose([Validators.required])]
    });

  }

  ngOnInit(): void {

    let today = Date.now();
    let emailDate = new Date(this.userService.getUserInfo().emailDate);
    if(today > (emailDate.getTime() + 31 * 24 * 60 * 60 *1000)) {
      this.timeLapsed = true;
      this.notificationStatus = '4';
    }
    
    this.policyNumber = this.userService.getUserInfo().policyNumber;
    this.claimNumber = this.userService.getUserInfo().claimNumber;
    
  }

  hideMessage() {
    this.notificationStatus = '';
  }

  hideSubmitButton: boolean = false;
  disabledSubmitButton: boolean = false;

  public toggleStatus(type: string) {
    this.isError = false;
    if (type === '1') {
      this.hideSubmitButton = true;
      this.notificationStatus = '1';
      this.disabledSubmitButton = false;
      this.hideUpdateButton = false;
    }
    else if (type === '2') {
      this.hideSubmitButton = false;
      this.notificationStatus = '2';
      this.disabledSubmitButton = false;
      this.hideUpdateButton = true;
    }
    // else if (type === '3') {
    //   this.hideSubmitButton = false;
    //   this.notificationStatus = '';
    //   this.disabledSubmitButton = false;
    //   this.hideUpdateButton = true;
    // }
  }


  get status() { return this.shippingStatusFormGroup.controls.status }

  notificationStatus = '';

  public submitStatus() {
    if (this.shippingStatusFormGroup.valid) {
      this.isError = false;     
      // if (this.status.value === '1') {
      //   // call delivery status api
      //   //this.updateDeliverySatus('received');
      //   this.confirmed = true;
      //   //this.disabledSubmitButton = true;
      //   this.hideSubmitButton = true;
      //   this.confirmationStatusSubmit.emit('1');
      // }
      // else 
      if (this.status.value === '2') {
        // call delivery status api and enable update button
        this.updateDeliverySatus('process_claim');
        this.confirmed = true;
        //this.disabledSubmitButton = true;
        this.hideSubmitButton = true;
        this.confirmationStatusSubmit.emit('2');
      }
      return true;
    } else {
      this.isError = true;
      this.disabledSubmitButton = false;
      return false;
    }
  }

  continuing: boolean = false;
  public updateDeliverySatus(deliveryStatus: string) {
    this.deliveryStatusRequest.deliveryStatus = deliveryStatus;
    this.deliveryStatusRequest.policyNumber = this.policyNumber;
    this.deliveryStatusRequest.claimNumber = this.claimNumber;
    
    this.claimService.setDeliveryStatus(this.deliveryStatusRequest).subscribe(
      response => {
        if (response.code === "500" || response.code === "200") {
          this.continuing = false;
        }
        else {
          this.notificationStatus = this.status.value;
        }
      }, error => {
        this.continuing = false;
        this.notificationStatus = '0';
        this.disabledSubmitButton = false;
      }
    );
  }

  hideUpdateButton: boolean = true;
  public updateClaim() {
    this.confirmationStatusSubmit.emit('1');
    this.confirmed = true;
    this.disabledSubmitButton = true;
    this.hideUpdateButton = true;
    // window.location.href = 'NjIzODc2LTE0NzcvMDAwLTAwLTA1MzgyNC90Y2NwL25wYWRhZGVAdXBzLmNvbS8xWkU5OTE5MTAzMzMyMDA1MDc=';
    //this.router.navigate(['/tupss/detail'], { queryParams: {claimNumber: this.claimNumber}});
  }

  public unHideUpdateButton(){
    this.hideUpdateButton = false; 
    this.confirmed = false;
  }
}
