<div (click)="topCollapsed(isCollapsed)" class="collapsible-panel" [class.collapsed]="isCollapsed" [class.icons]="showInfo" [class.warning]="showWarning">
  <div class="panel-header"
       (click)="isCollapsed = !isCollapsed">
    <!-- <span *ngIf="isCollapsed" (click)="unCollapsed()" class="width100">
      <div>{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
      <span *ngIf="edit" (click)="editPayee()"><mat-icon class="ml-2 edit-icon">mode_edit</mat-icon></span><span *ngIf="showRed" class="red">*</span><mat-icon *ngIf="showInfo" ngbTooltip="{{ 'claims.edit.start.tracking.titleTooltip' | translate }}" tooltipClass="ngb-tooltip-class" class="info">info_outline</mat-icon>
    </span> -->

    <div class="d-flex flex-column">
      <div>{{ title }}</div>
      <div style="font-size: 14px">{{ subtitle }}</div>
    </div>
    <!-- <span *ngIf="!isCollapsed" (click)="collapsed()" class="width100">
      <div>{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
      <span *ngIf="edit" (click)="editPayee()"><mat-icon class="ml-2 edit-icon">mode_edit</mat-icon></span><span *ngIf="showRed" class="red">*</span><mat-icon *ngIf="showInfo" ngbTooltip="{{ 'claims.edit.start.tracking.titleTooltip' | translate }}" tooltipClass="ngb-tooltip-class" class="info">info_outline</mat-icon>
    </span> -->
    <div class="toggle">
      <mat-icon *ngIf="isCollapsed" (click)="unCollapsed()">add</mat-icon>
      <mat-icon *ngIf="!isCollapsed" (click)="collapsed()">remove</mat-icon>
    </div>
  </div> 
  <div class="panel-content" *ngIf="!isCollapsed">
    <ng-content></ng-content>
  </div>
  <div class="warning-message" *ngIf="showWarning">
    <mat-icon>info_outlined</mat-icon><span>{{ 'claims.edit.whatHappened.reason.warning' | translate }}</span>
  </div>
</div>
