import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-draft-delete-confirmation',
  templateUrl: './draft-delete-confirmation.component.html',
  styleUrls: ['./draft-delete-confirmation.component.scss']
})
export class DraftDeleteConfirmationComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DraftDeleteConfirmationComponent>) {

  }

  close() {
    this.dialogRef.close("Cancel");
  }

  delete() {
    this.dialogRef.close("Delete");
  }

  ngOnInit(): void {
  }

}
