<div *ngIf="saveStep1" class="wrapper">
  <div class="heading d-flex justify-content-end">
    <mat-icon (click)="close()">close</mat-icon>
  </div>

  <div class="body">
    <div class="title">
      {{ 'claims.list.claimsSearch.customizeLayout.newLayout.title' | translate }}
    </div>
    <hr class="green-hr">
    <form [formGroup]="layoutForm">
      <div class="content">
        <div>
          <div class="overflowScroll">
            <div class="d-flex justify-content-start">
              <input type="checkbox" class="size-22" name="claimNumber" [disabled]="true" [checked]="true">
              <label class="underline" for="claimNumber">Claim Number</label>
            </div>

            <div class="d-flex justify-content-start">
              <input type="checkbox" class="size-22" name="claimStatus" [disabled]="true" [checked]="true">
              <label class="underline" for="claimStatus">Claim Status</label>
            </div>

            <div class="d-flex justify-content-start">
              <input type="checkbox" class="size-22" name="fileDate" [disabled]="true" [checked]="true">
              <label class="underline" for="fileDate">File Date</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="receiver"  class="upsc-green-background-checkbox size-22" type="checkbox" (change)="onChange($event.target.checked, 3)" name="receiver"
                [checked]="col3Checked" [disabled]="col3Disabled">
              <label for="receiver">Receiver</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="paymentRecipient" class="upsc-green-background-checkbox size-22" type="checkbox" (change)="onChange($event.target.checked, 4)"
                name="paymentRecipient" [checked]="col4Checked" [disabled]="col4Disabled">
              <label for="paymentRecipient">Claim Payment Recipient</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="paidAmount" class="upsc-green-background-checkbox size-22" type="checkbox" name="paidAmount" (change)="onChange($event.target.checked, 5)"
                [checked]="col5Checked" [disabled]="col5Disabled">
              <label for="paidAmount">Paid Amount</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="trackingNumber1" class="upsc-green-background-checkbox size-22" type="checkbox" name="trackingNumber1"
                (change)="onChange($event.target.checked, 6)" [checked]="col6Checked" [disabled]="col6Disabled">
              <label for="trackingNumber1">Tracking Number</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="adjuster" class="upsc-green-background-checkbox size-22" type="checkbox" name="adjuster" (change)="onChange($event.target.checked, 7)"
                [checked]="col7Checked" [disabled]="col7Disabled">
              <label for="adjuster">Adjuster</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="checkACHNumber" class="upsc-green-background-checkbox size-22" type="checkbox" name="checkACHNumber"
                (change)="onChange($event.target.checked, 8)" [checked]="col8Checked" [disabled]="col8Disabled">
              <label for="checkACHNumber">Check/Electronic Deposit Number</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="checkACHIssueDate" class="upsc-green-background-checkbox size-22" type="checkbox" name="checkACHIssueDate"
                (change)="onChange($event.target.checked, 9)" [checked]="col9Checked" [disabled]="col9Disabled">
              <label for="checkACHIssueDate">Check/Electronic Deposit Date</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="lossDate" class="upsc-green-background-checkbox size-22" type="checkbox" name="lossDate" (change)="onChange($event.target.checked, 10)"
                [checked]="col10Checked" [disabled]="col10Disabled">
              <label for="lossDate">Loss Date</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="filedAmount" class="upsc-green-background-checkbox size-22" type="checkbox" name="filedAmount" (change)="onChange($event.target.checked, 11)"
                [checked]="col11Checked" [disabled]="col11Disabled">
              <label for="filedAmount">Filed Claim Amount</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="accountNumber" class="upsc-green-background-checkbox size-22" type="checkbox" name="accountNumber"
                (change)="onChange($event.target.checked, 12)" [checked]="col12Checked" [disabled]="col12Disabled">
              <label for="accountNumber">Account Number</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="declaredValue" class="upsc-green-background-checkbox size-22" type="checkbox" name="declaredValue"
                (change)="onChange($event.target.checked, 13)" [checked]="col13Checked" [disabled]="col13Disabled">
              <label for="declaredValue">Declared Value</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="referenceNumber" class="upsc-green-background-checkbox size-22" type="checkbox" name="referenceNumber"
                (change)="onChange($event.target.checked, 14)" [checked]="col14Checked" [disabled]="col14Disabled">
              <label for="referenceNumber">Reference Number</label>
            </div>

            <div class="d-flex justify-content-start">
              <input id="submissionNumber1" class="upsc-green-background-checkbox size-22" type="checkbox" name="submissionNumber1"
                (change)="onChange($event.target.checked, 15)" [checked]="col15Checked" [disabled]="col15Disabled">
              <label for="submissionNumber1">Submission Number</label>
            </div>

          </div>
        </div>

      </div>
      <div>
        <label for="submissionNumber1">({{saveUpdateItems.length}}/11)</label>
      </div>
      <div>
        <div class="dbutton" *ngIf="saveButtons">
          <button class="btn btn-solid" [disabled]="maxLengthError" (click)="applyCurrentlayout()">{{
            'claims.list.claimsSearch.customizeLayout.newLayout.buttons.applyNewLayout' |
            translate }}</button>
          <button class="btn btn-white" (click)="save1()" [disabled]="maxLengthError">{{
            'claims.list.claimsSearch.customizeLayout.newLayout.buttons.saveAsNewLayout' | translate }}</button>
          <button class="btn btn-white" (click)="close()">{{
            'claims.list.claimsSearch.customizeLayout.newLayout.buttons.closeWithoutSaving' | translate }}</button>
        </div>
        <div class="dbutton" *ngIf="updateButtons">
          <button class="btn btn-solid" [disabled]="maxLengthError" (click)="applyCurrentlayout()">{{
            'claims.list.claimsSearch.customizeLayout.newLayout.buttons.applyUpdatedLayout' |
            translate }}</button>
          <button class="btn btn-white" (click)="update1()" [disabled]="maxLengthError">{{
            'claims.list.claimsSearch.customizeLayout.newLayout.buttons.updateNewLayout' | translate }}</button>
          <button class="btn btn-white" (click)="close()">{{
            'claims.list.claimsSearch.customizeLayout.newLayout.buttons.closeWithoutUpdating' | translate }}</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="saveStep2" class="wrapper">
  <div class="heading d-flex justify-content-end">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="body">
    <div class="title">
      {{
      'claims.list.claimsSearch.customizeLayout.newLayout.title2' | translate }}
    </div>
    <hr class="green-hr">
    <div class="content">
      <form [formGroup]="layoutstep2Form">
        <mat-form-field class="myInput">
          <mat-label>Layout Name</mat-label>
          <input required matInput #message formControlName="layoutName" maxlength="15">
          <mat-hint align="end">
            {{15 - message.value.length}}
          </mat-hint>
          <mat-error *ngIf="layoutstep2Form.get('layoutName').errors">
            <div *ngIf="layoutstep2Form.get('layoutName').errors['required']">
              {{ 'claims.list.claimsSearch.customizeLayout.newLayout.requiredfield' | translate }}
            </div>
          </mat-error>
        </mat-form-field>

        <div class="dbutton">
          <button class="btn btn-white" (click)="close()">{{
            'claims.list.claimsSearch.customizeLayout.newLayout.buttons.cancel' | translate
            }}</button>
          <button class="btn btn-solid" (click)="success()">{{
            'claims.list.claimsSearch.customizeLayout.newLayout.buttons.save' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="saveStep3" class="wrapper">
  <div class="heading d-flex justify-content-end">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="body">
    <div class="title">
      {{ 'claims.list.claimsSearch.customizeLayout.newLayout.buttons.saved' | translate }}
    </div>
    <hr class="green-hr">
    <div class="content">
      {{'claims.list.claimsSearch.customizeLayout.newLayout.title3' | translate}}
    </div>
    <div class="dbutton">
      <button class="btn btn-solid d-flex justify-content-middle" (click)="continue()">
        {{ 'claims.list.claimsSearch.customizeLayout.newLayout.buttons.continue' | translate }}</button>
    </div>
  </div>
</div>

<div *ngIf="updateStep3" class="wrapper">
  <div class="heading d-flex justify-content-end">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="body">
    <div class="title">
      {{ 'claims.list.claimsSearch.customizeLayout.newLayout.buttons.saved' | translate }}
    </div>
    <hr class="green-hr">
    <div class="content">
      {{'claims.list.claimsSearch.customizeLayout.newLayout.updateTitle' | translate}}
    </div>
    <div class="dbutton">
      <button class="btn btn-solid d-flex justify-content-middle" (click)="continue()">{{
        'claims.list.claimsSearch.customizeLayout.newLayout.buttons.continue' | translate
        }}</button>
    </div>
  </div>
</div>

<div *ngIf="!maxLimit" class="wrapper">
  <div class="heading d-flex justify-content-end">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="body">
    <div class="title">
      {{ 'claims.list.claimsSearch.customizeLayout.newLayout.Note' | translate }}
    </div>
    <hr class="green-hr">
    <div class="content">
      {{ 'claims.list.claimsSearch.customizeLayout.newLayout.maxLimitTitle' | translate }}
    </div>
    <div class="dbutton">
      <button class="btn btn-solid d-flex justify-content-middle" (click)="close()">{{
        'claims.list.claimsSearch.customizeLayout.newLayout.buttons.ok' | translate
        }}</button>
    </div>
  </div>
</div>