import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'upsc-paginate-list',
  templateUrl: './paginate-list.component.html',
  styleUrls: ['./paginate-list.component.scss']
})
export class PaginateListComponent {
  @Output() pagerNotification = new EventEmitter<number>(true);
  @Output() assetNotification = new EventEmitter<number>(true);
  @Input() pager: any = {};
  @Input() currentPage: any;
  @Input() pagePerAsset: any;

  pageAssets = [
    { size: '5' },
    { size: '10' },
    { size: '15' },
    { size: '20' },
    { size: '30' },
    { size: '50' }
  ];


  pageSelectorForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPage && changes.currentPage.currentValue) {
      this.pageSelectorForm = this.fb.group({
        numberPage: [this.pager.currentPage, Validators.nullValidator],
        assetsPerPage: [this.pager.pageSize.toString(), Validators.nullValidator]
      });
    }
  }

  ngOnInit() {
    this.pageSelectorForm = this.fb.group({
      numberPage: [this.pager.currentPage, Validators.nullValidator],
      assetsPerPage: [this.pager.pageSize.toString(), Validators.nullValidator]
    });
    this.pageNumberControl.setValue(this.pager.currentPage);
  }

  get pageNumberControl() { return this.pageSelectorForm.controls.numberPage; }
  get pageAssetControl() { return this.pageSelectorForm.controls.assetsPerPage; }

  setPage(page: any) {
    this.pagerNotification.emit(page);
  }

  onPagerChange(event) {
    this.pagerNotification.emit(this.pageNumberControl.value);
  }

  onAssetChange(event) {
    this.assetNotification.emit(event.value);
  }
}

