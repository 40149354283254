<div class="popup-global-layout width-full p-4 p-md-5">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">{{ 'payee.remove.title' | translate }}</div>
  <div class="global-green-bar mb-4"></div>
  <div class="payee-info">
    <div class="title">{{ payee.payeeName }}</div>
    <div class="text">{{ payee.payeeEmail }}</div>
    <div class="text">{{ payee.payeePhone | phoneNumber }}</div>
  </div>
  <div class="global-button-format">
      <button type="button" class="upsc-white-button2" (click)="close()"><mat-icon class="icon-key">keyboard_arrow_left</mat-icon>{{ 'common.cancel' | translate }}</button>
      <button class="upsc-green-popup-button" (click)="removePayee(payee)" *ngIf="!loading">{{ 'common.delete' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
      <button class="loading d-flex align-items-center justify-content-between" *ngIf="loading">
        <div>{{ 'common.delete' | translate }}</div>
        <div class="spinner-border" role="status">
          <span class="sr-only">{{ 'common.loading' | translate }}</span>
        </div>
      </button>
  </div>
</div>