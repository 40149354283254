
export class ShipperAccountList {
    name: string;
    shipperName?: string;
    shipperNumber: string;
    notification?: string;
    email: string;
    role?: string;
    status?: string;
    srno?: number;
    userId?: number;
    objectUID?: any;

    constructor(){}

  }

  
  