import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment as ENV } from '../../environments/environment';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver  {
  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService,
    private userService: UserService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let id_token = route.fragment?.match (/^id_token=([\w_.\-]+)?&/i) || ['', localStorage.getItem('id_token')];
    if(!id_token || id_token.length != 2 || !id_token[1] || this.jwtHelper.isTokenExpired(id_token[1])) {
      window.location.href = ENV.roleLevelSecurity.signin;
      return EMPTY;
    }
    else {
      sessionStorage.setItem('userDetails', JSON.stringify({internalUser: true}));
      sessionStorage.setItem('permissions', JSON.stringify([]));
      
      let idToken = id_token[1];
      localStorage.setItem('id_token', idToken);

      return this.userService.validateIDToken(idToken);
    }
  }
}
