import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment as ENV } from '../../environments/environment';
import { ApiEndpoints as API } from '../../config/api-endpoints';
import { IEmail } from "../shared/models/email.interface";
import { retry } from "rxjs/operators";
import { IUpdate } from "../pages/profile/models/update.interface";
import { AccountInfo } from "../shared/models/account-info.interface";
import { UserService } from "./user.service";

@Injectable()
export class AccountService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient,
    private userService: UserService) { }

  //Send the user data to the create-user api
  public register(user): Observable<any>{
    return this.http.post<any>(`${ENV.baseUrl.api}${API.register}`, user, this.httpOptions);
  }

  public update(user): Observable<any>{
    return this.http.post<any>(`${ENV.baseUrl.api}${API.updateUser}`, user, this.httpOptions)
  }

  public updateUserName(user): Observable<any>{
    return this.http.post<any>(`${ENV.baseUrl.api}${API.updateUserName}`, { "ObjectUId": user.userObjectId, "NewUserName": user.userName }, this.httpOptions);
  }


  // 3/5/2024 we changed them from text into number values - for security purposes
  //1 - UserNameNotExist
  //2 - PolicyExist
  //3 - PolicyNotExist
  //4 - EmailNotExist
  //5 - TempUserExists

  public checkEmail(email: string): Observable<any>{
    const endpoint2 = ENV.baseUrl.api + API.checkUser;
    return this.http.post<any>(endpoint2, { 'Email': email }, this.httpOptions);
  }

  public checkUserID(userID: string): Observable<any>{
    const endpoint2 = ENV.baseUrl.api + API.verifyUserID;
    return this.http.post<any>(endpoint2, { 'UserId': userID }, this.httpOptions);
  }
  public checkRoleUsers(policyNumber: string): Observable<any>{
    const endpoint2 = ENV.baseUrl.api + API.checkRoleUsers;
    return this.http.post<any>(endpoint2, { 'policyNumber': policyNumber }, this.httpOptions);
  }

  public getTempUserDetails(tempUserID): Observable<any>{
    let id = tempUserID;
    const endpoint2 = ENV.baseUrl.api + API.getTempUserDetails;
    return this.http.post<any>(endpoint2, { 'tempUserId': id }, this.httpOptions);
  }

  sendCompletedRegistrationEmail(email: IEmail) {
    email.CountryLocale = sessionStorage.getItem('locale');
    return this.http.post(`${ENV.baseUrl.api}${API.REGISTRATION_COMPLETE}`, email, this.httpOptions).pipe(
      retry(3)
    );
  }

  sendVerificationCodeEmail(email: IEmail) {
    email.CountryLocale = sessionStorage.getItem('locale');
    return this.http.post(`${ENV.baseUrl.api}${API.CREATE_ACCOUNT}`, email, this.httpOptions).pipe(
      retry(3)
    );
  }

  sendTupssTccpVerification(email: IEmail) {
    return this.http.post(`${ENV.baseUrl.api}${API.TUPSS_VERIFICATION}`, email, this.httpOptions).pipe(
      retry(3)
    );
  }

  //Policy Search API but without Auth, so no actual policy info is returned
  public policyCheck(policyNumber: string, zipCode: string): Observable<any>{
    let id = policyNumber;
    let zip = zipCode;
    return this.http.get<any>(`${ENV.baseUrl.api}${API.getPolicyDetailsNoAuth}?policyNumber=${id}&zip=${zip}`, this.httpOptions);
  }

  public saveVerificationCode(email): Observable<any>{
    return this.http.post<any>(`${ENV.baseUrl.api}${API.saveVerificationCode}`, email, this.httpOptions);
  }

  public createVerificationCode(email): Observable<any>{
    return this.http.post<any>(`${ENV.baseUrl.api}${API.createVerificationCode}`, email, this.httpOptions);
  }

  public validateVerificationCode(verifyDetails){
    return this.http.post<any>(`${ENV.baseUrl.api}${API.tupssVerify}`, verifyDetails, this.httpOptions);
  }

  public getVerificationCode(code){
    return this.http.post<any>(`${ENV.baseUrl.api}${API.retrieveVerificationCode}`, code, this.httpOptions);
  }

  public verifyCode(code){
    return this.http.post<any>(`${ENV.baseUrl.api}${API.verifyCode}`, code, this.httpOptions);
  }

  public verifyTupssCode(code){
    return this.http.post<any>(`${ENV.baseUrl.api}${API.verifyTupssCode}`, code, this.httpOptions);
  }

  public saveUser(user): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.saveUser}`, user, this.httpOptions);
  }

  acceptTerms(requestBody): Observable<any> {
    return this.http.post(`${ENV.baseUrl.api}${API.POST_TERMS}`, requestBody, this.httpOptions);
  }

  public passwordReset(reset): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.passwordReset}`, reset, this.httpOptions);
  }

  public forgotUserId(payload): Observable<any> {
    let countryLocale = sessionStorage.getItem('locale');
    const endpoint2 = ENV.baseUrl.api + API.userIDRecovery;
    return this.http.post<any>(endpoint2, payload, this.httpOptions);
  }

  public verifyUserEmail(details: string): Observable<any> {
    const endpoint2 = ENV.baseUrl.api + API.verifyemail;
    return this.http.post<any>(endpoint2, { 'Email': details }, this.httpOptions);
  }

  public MailResetPassword(email: IEmail) {
      email.CountryLocale = sessionStorage.getItem('locale');
    return this.http.post(`${ENV.baseUrl.api}${API.RESET_PASSWORD}`, email, this.httpOptions).pipe(
      retry(3)
    );
  }

  public changePassword(update: IUpdate): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.changePassword}`, update, this.httpOptions);
  }

  saveUserSettings(user: AccountInfo): Observable<any> {
    return this.http.post(`${ENV.baseUrl.api}${API.UPDATE_USER_PREFERENCES}`, user, this.httpOptions).pipe(retry(3));
  }

  updateContact(updateContactRequest: any): Observable<any> {
    return this.http.post(`${ENV.baseUrl.api}${API.POLICY_UPDATE_CONTACT}`, updateContactRequest, this.httpOptions).pipe(retry(3));
  }

  retrieveCRMData(payload: any): Observable<any> {
    return this.http.post(`${ENV.baseUrl.api}${API.GET_CRM_DATA}`, payload, this.httpOptions).pipe(retry(3));
  }

  verifyCRMProspect(payload: any): Observable<any> {
    return this.http.post(`${ENV.baseUrl.api}${API.VERIFY_CRM_PROSPECT}`, payload, this.httpOptions);
  }
}
