import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AccountInfo } from '../../../shared/models/account-info.interface';
import { LoginService } from '../../../services/login.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { States } from '../../../../config/metadata-config';
import { IPolicies, POLICYTYPES } from '../../../shared/models/policies.interface';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { ClaimService } from '../../../services/claim.service';
import { Observable } from 'rxjs';
import { ICategory } from '../../../shared/models/claim.interface';
import { startWith, map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { AccountService } from 'src/app/services/account.service';
import { countries, carriers, productCategory, limitedCarriers } from '../../../../config/metadata-config';
import { TranslateService } from '@ngx-translate/core';
import { TaggingService } from 'src/app/tags/tagging.service';
import { ContactComponent } from '../contact/contact.component';
import { PolicyService } from 'src/app/services/policy.service';

@Component({
  selector: 'upsc-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  //Interfaces
  userDetails: AccountInfo;
  policyDetails: IPolicies;
  updateUserPhone: AccountInfo;  //This object used when updating phone number
  updateUserDetails: AccountInfo;  //This object used when updating autofill preferences
  
  isCBPPolicy: boolean = false;

  //FormGroups
  userDetailsFormGroup: UntypedFormGroup;
  payeeForm: UntypedFormGroup;
  userInfoForm: UntypedFormGroup;
  autofillPreferencesForm: UntypedFormGroup;
  profileForm: UntypedFormGroup;

  scriptError: boolean = false;

  //Data
  fullStateList = States; //USA + Canadian states/provinces
  carrierList: [] | {} = [];
  categoryList = [];
  countryList = [];
  payeeList = [];
  policyType: POLICYTYPES;

  //Helpers
  showCreatePayee: boolean = false;
  showPhoneNumberInput: boolean = false;
  cargo: boolean = false;
  flex: boolean = false;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean = false;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  queryCarrier: string;
  Object = Object;
  loopCounter = 0;
  filteredCategories: Observable<ICategory[]>;

  //HTML Vars
  carrierCollapsed = true;
  categoryCollapsed = true;
  updateSuccess = false;
  isUpdated = 0;
  isInternalUser: boolean = false;
  isFileClaim: boolean = false;
  //Permissions
  isClaimViewer: boolean = false;

  @ViewChild(ContactComponent, { static: false }) contactComponent: ContactComponent;

  constructor(private loginService: LoginService,
    public accountService: AccountService,
    private fb: UntypedFormBuilder,
    private phonePipe: PhoneNumberPipe,
    private claimService: ClaimService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private translateService: TranslateService,
    private taggingService: TaggingService,
    private policyService: PolicyService
  ) {
    this.userDetails = this.userService.getUserInfo();
    this.policyDetails = this.loginService.getPolicyDetails();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.updateUserDetails = this.userDetails;  //Start with userdetails that matches what we currently have
    this.updateUserPhone = this.userDetails;
    this.isClaimViewer = this.userService.getUserPermissions()?.isClaimView;  //ClaimViewer cannot change autofill preferences    //When loading metadata, we also instantiate categories, so the list will populate
    this.isFileClaim = this.userService.getUserPermissions()?.isFileClaim;
    this.policyType = this.policyService.determinePolicyType(this.policyDetails);
    //When loading metadata, we also instantiate categories, so the list will populate
    //on click, instead of waiting for the user to type.
    this.loadMetaData();
    this.checkLists();

    if(this.policyDetails?.policySource?.toLowerCase().includes('cbp')){
      this.isCBPPolicy = true;
    }

    var zipCode = "";
    if (this.userDetails?.userPreference?.address?.zip !== undefined &&
      this.userDetails?.userPreference?.address?.zip !== null && this.userDetails?.userPreference?.address?.zip?.length > 1) {
      zipCode = this.userDetails.userPreference.address.zip;
    }
    else {
      if (this.userDetails.zipCode !== undefined && this.userDetails.zipCode !== null && this.userDetails.zipCode?.length > 1) {
        zipCode = this.userDetails.zipCode;
      }
    }

    if (this.isUsaUser) {
      this.profileForm = this.fb.group({
        autofillPreferences: this.fb.group({
          carrier: [this.userDetails.userPreference.carrier],
          category: [this.userDetails.userPreference.productCategory],
          company: [this.userDetails.userPreference.preferenceName],
          addressLine1: [this.userDetails.userPreference.address.addressLine1],
          addressLine2: [this.userDetails.userPreference.address.addressLine2],
          city: [this.userDetails.userPreference.address.city],
          state: [this.userDetails.userPreference.address.state],
          zip: [zipCode, this.isCanadaUser ? [Validators.required,
          Validators.pattern(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)]
            : this.isUkUser ? [Validators.required, Validators.pattern(/^[A-Za-z0-9\s]{2,8}$/)]
              : [Validators.required, Validators.pattern(/\d{5}/)]],
          country: [this.userDetails.userPreference.address.country]
        })
      })
    }
    else {
      this.profileForm = this.fb.group({
        firstName: [this.userDetails.firstName, Validators.compose([Validators.required])],
        lastName: [this.userDetails.lastName, Validators.compose([Validators.required])],
        phoneNumber: [this.phonePipe.transform(this.userDetails.contactPhone)],
        autofillPreferences: this.fb.group({
          carrier: [this.userDetails.userPreference.carrier],
          category: [this.userDetails.userPreference.productCategory],
          company: [this.userDetails.userPreference.preferenceName],
          addressLine1: [this.userDetails.userPreference.address.addressLine1],
          addressLine2: [this.userDetails.userPreference.address.addressLine2],
          city: [this.userDetails.userPreference.address.city],
          state: [this.userDetails.userPreference.address.state],
          zip: [zipCode, this.isCanadaUser ? [Validators.required,
          Validators.pattern(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)]
            : this.isUkUser ? [Validators.required, Validators.pattern(/^[A-Za-z0-9\s]{2,8}$/)]
              : [Validators.required, Validators.pattern(/\d{5}/)]],
          country: [this.userDetails.userPreference.address.country]
        })
      })
    }  

    // Handle errors with user input for carrier searching
    this.profileForm.get('autofillPreferences').get('carrier').valueChanges.subscribe(
      inp => {
        this.queryCarrier = inp;
        Object.keys(this.carrierList).forEach(key => {
          if ((this.profileForm.get('autofillPreferences').get('carrier').value) && this.carrierList[key].toLowerCase() == this.profileForm.get('autofillPreferences').get('carrier').value.toLowerCase()) {
          }
        });
        for (let key in this.carrierList) {
          if ((inp) && inp.toLowerCase() == this.carrierList[key].toLowerCase()) {
            if (inp !== this.carrierList[key]) {
              this.profileForm.get('autofillPreferences').get('carrier').setValue(this.carrierList[key]);
            }
            this.profileForm.get('autofillPreferences').get('carrier').setErrors(null);
            break;
          } else {
            if (inp == "") {
              // This allows the user to type nothing - so that they can have no preferred carrier
            } else {
              this.profileForm.get('autofillPreferences').get('carrier').setErrors({ 'invalid': true });
            }
          }
        }
      }
    );

    this.profileForm.get('autofillPreferences').get('category').valueChanges.subscribe(val => {
      this.profileForm.get('autofillPreferences').get('category').setErrors({ 'invalid': true });
      if (val == "" || val == undefined || val == null) { // Allows the user to type nothing - setting no preferred category
        this.profileForm.get('autofillPreferences').get('category').setErrors(null);
      } else if (typeof val === 'object') { //selected from the list
        this.profileForm.get('autofillPreferences').get('category').setErrors(null);
      } else { //typed in / autofilled in
        for (let cat of this.categoryList) {
          if (cat.value.toLowerCase() === val.toLowerCase()) {
            if (cat.value !== val) {
              this.profileForm.get('autofillPreferences').get('category').setValue(cat);
            }
            this.profileForm.get('autofillPreferences').get('category').setErrors(null);
            break;
          }
        }
      }
    });

    this.profileForm.get('autofillPreferences').get('country').valueChanges.subscribe(val => {
      if (val == 'US' || val == 'CA') {
        this.profileForm.get('autofillPreferences').get('state').setValidators([Validators.required]);
        this.profileForm.get('autofillPreferences').get('state').updateValueAndValidity();
        this.profileForm.get('autofillPreferences').get('state').markAsTouched();
      } else {
        this.profileForm.get('autofillPreferences').get('state').setValue(null);
        this.profileForm.get('autofillPreferences').get('state').setValidators(null);
        this.profileForm.get('autofillPreferences').get('state').updateValueAndValidity();
      }
    });

    this.profileForm.get('autofillPreferences').get('state').valueChanges.subscribe(val => {
      if (!val && (this.profileForm.get('autofillPreferences').get('country').value == 'US' || this.profileForm.get('autofillPreferences').get('country').value == 'CA')) {
        this.profileForm.get('autofillPreferences').get('state').setValidators([Validators.required]);
      } else {
        this.profileForm.get('autofillPreferences').get('state').setValidators(null);
      }
    })
  } //End of constructor
  
  // onContactUpdated(event){
  //   if (typeof event == 'number') {
  //     this.isUpdated = event;
  //   } 
  //   window.scroll(0, 0);
  // }

  hideAutofillMessage() {
    this.updateSuccess = false;
  }

  hideMessage() {
    this.isUpdated = 0;
  }

  ngOnInit() {
    this.checkPolicyType();
    let isInternal = this.userDetails.internalUser;
    if (isInternal) {
      this.isInternalUser = true;
    } else {
      this.isInternalUser = false;
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  disableUpdate(event) {
  //   if(this.isUsaUser){
  //     this.contactComponent.disableUpdate(!event);
  //   }    
  }

  loadMetaData() {
    this.claimService.getMetaData().subscribe(
      data => {
        this.scriptError = false;
        try {
          this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });
          this.countryList.sort(this.compare);
          this.categoryList = Object.entries(productCategory).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.productCategory.${key}`) } }); //translated categories
          //When user starts typing, filteredCategories will populate.
          this.filteredCategories = this.profileForm.get('autofillPreferences').get('category').valueChanges
            .pipe(
              startWith<string | ICategory>(''),
              map(value => typeof value === 'string' ? value : value == null ? null : value.key),
              map(name => name ? this.filter(name) : this.categoryList.slice())
            );
          if (Object.keys(data.metaData.transportationid_Ext).find(x => x === 'UPS Freight')) { //UPS Freight needs to be removed from the carrier list
            delete data.metaData.transportationid_Ext['UPS Freight'];
          }
          if (this.flex) {
            this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
          } else if (this.cargo) {
            this.carrierList = data.metaData.transportationid_Ext;
          }
        } catch (e) {
          this.loadLocalMetaData();
        }
      }, error => {
        if (error.error != null && error.error.errorMessage !== undefined && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input '))) {
          this.scriptError = true;
        }
        this.loadLocalMetaData();
      }
    );
  }

  loadLocalMetaData() {
    this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });//translated countries
    this.countryList.sort(this.compare);
    this.categoryList = Object.entries(productCategory).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.productCategory.${key}`) } }); //translated categories
    //When user starts typing, filteredCategories will populate.
    this.filteredCategories = this.profileForm.get('autofillPreferences').get('category').valueChanges.pipe(
      startWith<string | ICategory>(''),
      map(value => typeof value === 'string' ? value : value.key),
      map(name => name ? this.filter(name) : this.categoryList.slice())
    );
    if (this.flex) {
      this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    } else if (this.cargo) {
      this.carrierList = Object.fromEntries(Object.entries(carriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    }
  }

  //Check if the policy is cargo or flex
  checkPolicyType() {
    if (this.policyType == 'transactional_cargo' || this.policyType == 'traditional_cargo') {
      this.cargo = true;
    } else if (this.policyType == 'transactional_parcel') {
      this.flex = true;
    }
  }

  //Listen for state changes, if possible, autoselect country when state is chosen
  autoCountry() {
    let state = this.profileForm.get('autofillPreferences').get('state').value;
    if (state) {
      if (state == 'AB' || state == 'BC' || state == 'MB' || state == 'NB' || state == 'NL' || state == 'NS' || state == 'NT' ||
        state == 'NU' || state == 'ON' || state == 'PE' || state == 'QC' || state == 'SK' || state == 'YT') {
        this.profileForm.get('autofillPreferences').get('country').setValue('CA');
        this.profileForm.get('autofillPreferences').get('country').updateValueAndValidity();
      } else {
        this.profileForm.get('autofillPreferences').get('country').setValue('US');
        this.profileForm.get('autofillPreferences').get('country').updateValueAndValidity();
      }
    }
  }

  onSubmit() {
    this.updateUserDetails.userPreference.address.addressLine1 = this.profileForm.get('autofillPreferences').get('addressLine1').value ? this.profileForm.get('autofillPreferences').get('addressLine1').value : null;
    this.updateUserDetails.userPreference.address.addressLine2 = this.profileForm.get('autofillPreferences').get('addressLine2').value ? this.profileForm.get('autofillPreferences').get('addressLine2').value : null;
    this.updateUserDetails.userPreference.address.addressLine3 = null;
    this.updateUserDetails.userPreference.address.city = this.profileForm.get('autofillPreferences').get('city').value ? this.profileForm.get('autofillPreferences').get('city').value : null;
    this.updateUserDetails.userPreference.address.country = this.profileForm.get('autofillPreferences').get('country').value ? this.profileForm.get('autofillPreferences').get('country').value : null;
    this.updateUserDetails.userPreference.address.zip = this.profileForm.get('autofillPreferences').get('zip').value ? this.profileForm.get('autofillPreferences').get('zip').value : null;
    this.updateUserDetails.userPreference.carrier = this.profileForm.get('autofillPreferences').get('carrier').value ? this.profileForm.get('autofillPreferences').get('carrier').value : null;
    this.updateUserDetails.userPreference.preferenceName = this.profileForm.get('autofillPreferences').get('company').value ? this.profileForm.get('autofillPreferences').get('company').value : null;

    if (!this.isUsaUser) {
      this.updateUserDetails.firstName = this.profileForm.get('firstName').value ? this.profileForm.get('firstName').value : null;
      this.updateUserDetails.lastName = this.profileForm.get('lastName').value ? this.profileForm.get('lastName').value : null;
      this.updateUserDetails.contactPhone = this.profileForm.get('phoneNumber').value ? this.profileForm.get('phoneNumber').value.replace(/\./g, '') : null;
      this.updateUserDetails.contactName = `${this.updateUserDetails.firstName} ${this.updateUserDetails.lastName}`;
    }
    else {
      this.updateUserDetails.firstName = this.userDetails.firstName;
      this.updateUserDetails.lastName = this.userDetails.lastName;
      this.updateUserDetails.contactPhone = this.userDetails.contactPhone;
      this.updateUserDetails.contactName = `${this.updateUserDetails.firstName} ${this.updateUserDetails.lastName}`;
    }


    if (this.profileForm.get('autofillPreferences').get('category').value == null || this.profileForm.get('autofillPreferences').get('category').value == undefined) {
      this.updateUserDetails.userPreference.productCategory = null;
    } else {
      this.updateUserDetails.userPreference.productCategory = this.profileForm.get('autofillPreferences').get('category').value.key ? this.profileForm.get('autofillPreferences').get('category').value.key : null;
    }
    if (this.profileForm.get('autofillPreferences').get('state').value == null || this.profileForm.get('autofillPreferences').get('state').value == undefined) {
      this.updateUserDetails.userPreference.address.state = null;
    } else {
      try{
        let stat = this.fullStateList.find(x => x.state === this.profileForm.get('autofillPreferences').get('state').value);
        this.updateUserDetails.userPreference.address.state = stat.state;
      }
      catch{
        this.updateUserDetails.userPreference.address.state = null;
      }     
    }

   
    this.accountService.saveUserSettings(this.updateUserDetails).subscribe(
      data => {
        this.scriptError = false;
        //setting and getting updated userDetails session storage
        this.loginService.updateUserDetails(this.updateUserDetails);
        this.userDetails = this.userService.getUserInfo();
        this.updateSuccess = true;
        window.scroll(0, 0);
      }, error => { 
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
        }
      }
    );
  }

  //For some reason, categoryList and stateList would randomly return empty, OR with the correct data,
  //So, I am making a loop to keep checking until all lists are populated with the correct data, and only
  //then will I attempt to autofill the fields.
  //Will terminate itself after 10 tries.
  checkLists() {
    if (this.loopCounter > 10) {
      return;
    } else {
      if (this.categoryList.length === 0 || this.fullStateList.length === 0) { //Lists not yet populated
        this.loopCounter++;
        setTimeout(() => this.checkLists(), 500);
      } else {  //Lists are populated, continue as normal
        //We need to find the OBJECT reference for the key that the API Returned
        if (this.userDetails.userPreference.productCategory != "" && this.userDetails.userPreference.productCategory != undefined && this.userDetails.userPreference.productCategory != null) {
          let cat = this.categoryList.find(x => x.key === this.userDetails.userPreference.productCategory);
          this.profileForm.get('autofillPreferences').get('category').setValue(cat); //This value must be an object, not a string
        }
        if (this.userDetails.userPreference.address.state != "" && this.userDetails.userPreference.address.state != undefined && this.userDetails.userPreference.address.state != null) {
          let stat = this.fullStateList.find(x => x.state === this.userDetails.userPreference.address.state);
          this.profileForm.get('autofillPreferences').get('state').setValue(stat.state); //Value must be the ID of the state
        }
      }
    }
  }

  //Used to filter categories based on what user is typing.
  //Works as a "contains" instead of "startsWith" due to !== -1
  filter(name: string): ICategory[] {
    return this.categoryList.filter(option =>
      option.value.toLowerCase().indexOf(name.toLowerCase()) !== -1);
  }

  //Handles how the data gets displayed vs how the value is represented in the database.
  displayCategories(obj?: ICategory): string | undefined {
    return obj ? obj.value : undefined;
  }

  //Used to check all form validations on payeeForm.
  getFormValidationErrors() {
    Object.keys(this.autofillPreferencesForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.profileForm.get('autofillPreferences').get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  //You can create get functions to clean up the html code
  get name(): string {
    return `${this.userDetails.firstName} ${this.userDetails.lastName}`;
  }
  get email(): string {
    return this.userDetails.emailAddress;
  }

  //Sorts an array alphabetically
  //If needing case insensitive, add .toUpperCase()
  //To use: this.someArray.sort(this.compare);
  public compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }

  tagging() {
    this.taggingService.link({ link_name: 'update' });
  }
}
