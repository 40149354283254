import { Component } from '@angular/core';

@Component({
  selector: 'upsc-under-review',
  templateUrl: './under-review.component.html',
  styleUrls: ['./under-review.component.scss']
})
export class UnderReviewComponent {

}
