import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared.module';
import { NavigationComponent } from './navigation.component';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
  ],
  declarations: [NavigationComponent],
  providers: [],
  exports: [NavigationComponent],
})
export class NavigationModule {}
