import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MyChoicePolicyResolver  {

  constructor(
    private userService: UserService,
    private router: Router,
    private http: HttpClient
  ) { }

  /* THIS IS WORKING FOR MY CHOICE AND CUSTOMIZED CLAIMS PORTALS */

  //The purpose of the resolver is to make sure that the waiting component has data before it loads.
  //If the resolver is not able to make a successful API call to load that data, it has the option to navigate away from the desired component entirely. 
  //So if there are no user details with policy number in sessionStorage, something bad happened and we don't let them move to claim details in this case.
  //If successful, the waiting component is also able to grab the resolved data using the variable specified in the routing. "policyDetails"
  //Some useful error handling scenarios - https://stackoverflow.com/questions/43898934/how-to-handle-error-in-a-resolver
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let policy = JSON.parse(sessionStorage.getItem('userDetails')).policyNumber;
    if (!policy) {
      this.router.navigate(['unauthorized']);
    } else {
      return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.getPolicyDetails}?policyNumber=${policy}`);
    }
  }

}
