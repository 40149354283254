import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'carrierSearch'
})
export class CarrierSearchPipe implements PipeTransform {

  transform(value: any[], carrierInput: string): any[] {
    if(carrierInput){
      //IndexOf filters by 'contains'
      ////console.log("Value: " + value);
      ////console.log("carrierInput: " + carrierInput);
      return value.filter((x:any) => x.toLowerCase().indexOf(carrierInput.toLowerCase()) !== -1);
      //return value.filter((x:any) => //console.log("X.Value: " + x));
    }else{
      return value;
    }
  }
}
