<div class="profile-section">
  <h3 class="page-title d-flex align-items-center justify-content-between">
    <div class="welcome">{{ 'profile.welcome' | translate }}&nbsp;{{policy?.insured}}
      <div class="bluebar"></div>
    </div>

    <div class="btnCenter">
      <button class="upsc-button" *ngIf="!isInternalUser && isFileClaim" [disabled]="isClaimViewer"
        routerLink="/claims/new" (click)="tagging()">
        <div class="btn-text-icon">
          <div class="text">{{ 'profile.file' | translate }}</div><mat-icon>chevron_right</mat-icon>
        </div>
      </button>
    </div>
    <!-- <button *ngIf="!isInternalUser && isFileClaim" [disabled]="isClaimViewer" class="upsc-button"
      routerLink="/claims/new" (click)="tagging()">{{ 'profile.file' | translate }}<mat-icon class="back-icon">chevron_right</mat-icon></button> -->
  </h3>
  <div class="isop-navigation">
    <ul class="navigation-items">
      <li class="navigation-item">
        <a href="#" routerLink="/profile" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <div class="text">{{ 'profile.profileMenus.userProfile' | translate }}</div>
        </a>
      </li>
      <li class="profile-menu divider" *ngIf="!isInternalUser"></li>
      <li class="navigation-item" *ngIf="!isInternalUser">
        <a href="#" routerLink="/profile/security" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <div class="text">{{ 'profile.profileMenus.updatePassword' | translate }}</div>
        </a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</div>