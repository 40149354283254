import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClaimService } from 'src/app/services/claim.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { columnSequences, ILayoutDetails, layoutdata } from '../../models/claim.interface';
import { TupssService } from 'src/app/pages/claims-custom/tupss/services/tupss.service';
import { ClaimLayoutsService } from 'src/app/services/claim-layouts.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'upsc-add-customizable-data-popup',
  templateUrl: './add-customizable-data-popup.component.html',
  styleUrls: ['./add-customizable-data-popup.component.scss']
})
export class AddCustomizableDataPopupComponent implements OnInit {

  //Interfaces
  layout: ILayoutDetails = {};
  layoutcolumns: columnSequences[] = [];
  layoutForm: UntypedFormGroup;
  layoutstep2Form: UntypedFormGroup;
  userDetails;
  showData: any = [];
  layoutdata: layoutdata[] = [];
  layoutId: any;
  saveStep1 = true;
  saveStep2 = false;
  saveStep3 = false;
  updateStep3 = false;
  layoutlist;
  maxLengthError = false;
  layoutsname = new Array();
  saveUpdateItems = [1, 2, 16];
  deSelectedColumns = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  disabledArray: any;
  test: string = '';
  col3Checked = false;
  col4Checked = false;
  col5Checked = false;
  col6Checked = false;
  col7Checked = false;
  col8Checked = false;
  col9Checked = false;
  col10Checked = false;
  col11Checked = false;
  col12Checked = false;
  col13Checked = false;
  col14Checked = false;
  col15Checked = false;
  updateButtons = false;
  saveButtons = false;
  col3Disabled = false;
  col4Disabled = false;
  col5Disabled = false;
  col6Disabled = false;
  col7Disabled = false;
  col8Disabled = false;
  col9Disabled = false;
  col10Disabled = false;
  col11Disabled = false;
  col12Disabled = false;
  col13Disabled = false;
  col14Disabled = false;
  col15Disabled = false;
  maxLimit = false;
  sessionCheck = false;
  userInactiveWarning = new Subject<void>();
  userActivityWarning;
  constructor(
    public claimservice: ClaimService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddCustomizableDataPopupComponent>,
    private ref: ChangeDetectorRef,
    private tupssService: TupssService,
    private layoutService: ClaimLayoutsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.layoutId = data.layoutsid;
    this.maxLimit = data.isMaxLimit;
    this.layoutForm = this.fb.group({
      claimNumber: ['',],
      claimStatus: ['',],
      receiver: ['',],
      paymentRecipient: ['',],
      paidAmount: ['',],
      trackingNumber: ['',],
      adjuster: ['',],
      checkACHNumber: ['',],
      checkACHIssueDate: ['',],
      lossDate: ['',],
      filedAmount: ['',],
      accountNumber: ['',],
      declaredValue: ['',],
      referenceNumber: ['',],
      submissionNumber: ['',],
      fileDate: ['',],
    });

    this.layoutstep2Form = this.fb.group({
      layoutName: ['', Validators.compose([Validators.required])],
    });
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    //If the inactivity reaches the threshold, run these functions
    this.userInactiveWarning.subscribe(() => this.closePopUp());
  }

  ngOnInit(): void {
    if (!this.maxLimit) {
      this.saveStep1 = false;
      this.saveStep2 = false;
      this.saveStep3 = false;
      this.updateStep3 = false;
    }
    else if (this.layoutId) {
      this.getLayoutDetails(this.layoutId.item1);
      this.updateButtons = true;
    }
    else {
      this.saveButtons = true;
    }
  }
  //Sets the warning timer to 30 minutes
  private setWarningTimeout() {
    this.userActivityWarning = setTimeout(() => this.userInactiveWarning.next(), 1800000);
  }

  closePopUp() {
    this.sessionStateCheck();
    if (this.sessionCheck) {
      // don't do anything
    }
    else {
      this.dialogRef.close();
    }
  }
  //Listens for mouse movement
  //If user moves mouse, then it will reset the timer
  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivityWarning);
    this.setWarningTimeout();
  }

  sessionStateCheck() {
    let sessioncheck = JSON.parse(sessionStorage.getItem('userDetails'));
    if (sessioncheck) {
      this.sessionCheck = true;
    }
    else {
      this.sessionCheck = false;
    }
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  save1() {
    this.saveStep1 = false;
    this.saveStep2 = true;
  }
  update1() {
    this.saveStep1 = false;
    this.updateStep3 = true;
    this.submit();
  }
  // when checkbox change, add/remove the item from the array
  onChange(checked, item) {
    if (checked) {
      this.saveUpdateItems.push(item);
      if (this.saveUpdateItems.length >= 11) {
        this.disabledArray = this.deSelectedColumns.filter(x => !this.saveUpdateItems.includes(x));
        this.maxDisabled(this.disabledArray);
      }
    }
    else {
      this.saveUpdateItems.splice(this.saveUpdateItems.indexOf(item), 1)
      if (this.saveUpdateItems.length <= 11) {
        this.maxEnabled(this.disabledArray);
      }
    }
  }

  maxDisabled(diffs) {
    for (let i = 0; i < diffs.length; i++) {
      if (diffs[i] == 3) {
        this.col3Disabled = true;
      }
      if (diffs[i] == 4) {
        this.col4Disabled = true;
      }
      if (diffs[i] == 5) {
        this.col5Disabled = true;
      }
      if (diffs[i] == 6) {
        this.col6Disabled = true;
      }
      if (diffs[i] == 7) {
        this.col7Disabled = true;
      }
      if (diffs[i] == 8) {
        this.col8Disabled = true;
      }
      if (diffs[i] == 9) {
        this.col9Disabled = true;
      }
      if (diffs[i] == 10) {
        this.col10Disabled = true;
      }
      if (diffs[i] == 11) {
        this.col11Disabled = true;
      }
      if (diffs[i] == 12) {
        this.col12Disabled = true;
      }
      if (diffs[i] == 13) {
        this.col13Disabled = true;
      }
      if (diffs[i] == 14) {
        this.col14Disabled = true;
      }
      if (diffs[i] == 15) {
        this.col15Disabled = true;
      }
    }
  }

  maxEnabled(diffs) {
    for (let i = 0; i < diffs.length; i++) {
      if (diffs[i] == 3) {
        this.col3Disabled = false;
      }
      if (diffs[i] == 4) {
        this.col4Disabled = false;
      }
      if (diffs[i] == 5) {
        this.col5Disabled = false;
      }
      if (diffs[i] == 6) {
        this.col6Disabled = false;
      }
      if (diffs[i] == 7) {
        this.col7Disabled = false;
      }
      if (diffs[i] == 8) {
        this.col8Disabled = false;
      }
      if (diffs[i] == 9) {
        this.col9Disabled = false;
      }
      if (diffs[i] == 10) {
        this.col10Disabled = false;
      }
      if (diffs[i] == 11) {
        this.col11Disabled = false;
      }
      if (diffs[i] == 12) {
        this.col12Disabled = false;
      }
      if (diffs[i] == 13) {
        this.col13Disabled = false;
      }
      if (diffs[i] == 14) {
        this.col14Disabled = false;
      }
      if (diffs[i] == 15) {
        this.col15Disabled = false;
      }
    }
  }

  success() {
    if (this.layoutstep2Form.valid) {
      this.saveStep2 = false;
      this.saveStep3 = true;
      this.submit();
    }
  }

  submit() {
    this.layout.userId = this.userDetails.userId ? this.userDetails.userId : '';
    // Updating layout
    if (this.layoutId) {
      this.layout.layoutId = this.layoutId.item1;
      this.layout.layoutName = this.layoutId.item2;
    }
    else {
      this.layout.layoutId = "";
      this.layout.layoutName = this.layoutstep2Form.value.layoutName;
    }
    this.layout.email = this.userDetails.emailAddress ? this.userDetails.emailAddress : this.tupssService.userData && this.tupssService.userData.userPrincipalName ? this.tupssService.userData.userPrincipalName : sessionStorage.getItem('guestUserEmail') ? sessionStorage.getItem('guestUserEmail') : '';

    for (let i = 0; i < this.saveUpdateItems.length; i++) {
      var test: columnSequences = {};
      test.columnId = this.saveUpdateItems[i];
      test.sequenceNumber = i;
      this.layoutcolumns.push(test);
    }
    this.layout.columnSequences = (this.layoutcolumns);

    this.claimservice.submitUserLayoutDetails(this.layout).subscribe(
      data => {
        if (data && data.layoutId) {

        }
      }, error => {
        this.dialogRef.close();
      }
    );
  }

  applyCurrentlayout() {
    this.layoutService.currentColumnList = this.saveUpdateItems;
    this.close();
  }

  getLayoutDetails(layoutid) {
    let request = {
      "email": this.userDetails.emailAddress ? this.userDetails.emailAddress : this.tupssService.userData && this.tupssService.userData.userPrincipalName ? this.tupssService.userData.userPrincipalName : sessionStorage.getItem('guestUserEmail') ? sessionStorage.getItem('guestUserEmail') : '',
      "userId": this.userDetails.userId ? this.userDetails.userId : '',
      "layoutId": layoutid
    }
    this.claimservice.getUserLayoutDetails(request).subscribe(
      data => {
        if (data && data.data.columns) {
          for (let i = 0; i < data.data.columns.length; i++) {
            if (data.data.columns[i] == "3") {
              this.col3Checked = true;
              this.onChange(true, 3);
            }
            if (data.data.columns[i] == "4") {
              this.col4Checked = true;
              this.onChange(true, 4);
            }
            if (data.data.columns[i] == "5") {
              this.col5Checked = true;
              this.onChange(true, 5);
            }
            if (data.data.columns[i] == "6") {
              this.col6Checked = true;
              this.onChange(true, 6);
            }
            if (data.data.columns[i] == "7") {
              this.col7Checked = true;
              this.onChange(true, 7);
            }
            if (data.data.columns[i] == "8") {
              this.col8Checked = true;
              this.onChange(true, 8);
            }
            if (data.data.columns[i] == "9") {
              this.col9Checked = true;
              this.onChange(true, 9);
            }
            if (data.data.columns[i] == "10") {
              this.col10Checked = true;
              this.onChange(true, 10);
            }
            if (data.data.columns[i] == "11") {
              this.col11Checked = true;
              this.onChange(true, 11);
            }
            if (data.data.columns[i] == "12") {
              this.col12Checked = true;
              this.onChange(true, 12);
            }
            if (data.data.columns[i] == "13") {
              this.col13Checked = true;
              this.onChange(true, 13);
            }
            if (data.data.columns[i] == "14") {
              this.col14Checked = true;
              this.onChange(true, 14);
            }
            if (data.data.columns[i] == "15") {
              this.col15Checked = true;
              this.onChange(true, 15);
            }
          }
        }
      }, error => {
        this.dialogRef.close();
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  continue() {
    this.dialogRef.close();
  }

}
