import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { InsightsContainerRoutingModule } from './insights-container-routing.module';
import { InsightsContainerComponent } from './insights-container.component';


@NgModule({
  declarations: [
    InsightsContainerComponent
  ],
  imports: [
    CommonModule,
    InsightsContainerRoutingModule,
    TranslateModule.forChild()
  ]
})
export class InsightsContainerModule { }
