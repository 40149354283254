import { Component, Inject} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-confirm-cancel',
  templateUrl: './confirm-cancel.component.html',
  styleUrls: ['./confirm-cancel.component.scss']
})
export class ConfirmCancelComponent {
  mychoice: boolean = false; //If it is the my choice flow, they want custom text for buttons.

  constructor(
    public dialogRef: MatDialogRef<ConfirmCancelComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.mychoice = data?.mychoice;
  }

  public cancel(){
    this.dialogRef.close();
  }

  public confirm(){
    this.dialogRef.close("confirm");
  }

}
