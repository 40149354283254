import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-withdraw-popup',
  templateUrl: './withdraw-popup.component.html',
  styleUrls: ['./withdraw-popup.component.scss']
})
export class WithdrawPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WithdrawPopupComponent>) { }

  ngOnInit(): void {
  }

  @Output() refreshWithdrawStatus = new EventEmitter<any>();

  public close(){
    this.dialogRef.close();
  }
  
  public withdrawn() {
    this.refreshWithdrawStatus.emit(true);
    this.dialogRef.close();
  }
}
