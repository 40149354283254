<div class="popup-global-layout width-600 p-4 p-md-5">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="cancel()">close</mat-icon></div>
  <div class="global-title">{{ 'app.errors.system' | translate }}</div>
  <div class="global-green-bar"></div>
  <div class="global-text">
    {{'deliveryDefense.errorPopup.text1' | translate}}
    <a href="mailto:DeliveryDefenseCustomerService@ups.com" class="">
      {{'deliveryDefense.errorPopup.text2' | translate}}
    </a>
    {{'deliveryDefense.errorPopup.text3' | translate}}
  </div>
  <div class="global-button-format">
    <button type="button" class="upsc-green-popup-button" (click)="cancel()">{{ 'app.errors.ok' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
  </div>
  </div>