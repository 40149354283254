<div class="wrapper d-flex flex-column-reverse flex-md-row">
  <div class="left d-flex flex-column justify-content-center align-items-center">
    <router-outlet></router-outlet>   
  </div>
  <div class="right" [ngClass]="isCanadaUser == true ? 'rightCanada' : ''">
    <img class="bg" *ngIf="!isCanadaUser" alt="{{ 'billing.method.backGround' | translate }}" src="../../../../assets/images/login_background.jpg">
    <img class="bg" *ngIf="isCanadaUser" alt="{{ 'billing.method.backGround' | translate }}" src="../../../../assets/images/login_background-Canada.png">
  </div>
</div>
<div class="footer">
  <upsc-footer></upsc-footer>
</div>