import { Component, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-card-display',
  templateUrl: './card-display.component.html',
  styleUrls: ['./card-display.component.scss']
})
export class CardDisplayComponent {
  @Input() icon: 'folder' | 'wrench' | 'progress' | 'shield' | 'star';
  @Input() number: string;  //Can be something like "265"
  @Input() money: string;   //This will be given as something like 123.4500, but using the pipe in the HTML it will show as a monetary value "$123.45"
  @Input() text: string;
  @Input() img: string;
  @Input() noImg: boolean = false;


  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;

  constructor(
    public userService: UserService
    ) { 
      this.isCanadaUser = this.userService.isCanadaUser();
      this.isUkUser = this.userService.isUkUser();
      this.isUsaUser = this.userService.isUsaUser();
      this.isGermanyUser = this.userService.isGermanyUser();
      this.isFranceUser = this.userService.isFranceUser();
      this.isItalyUser = this.userService.isItalyUser();
    }
}