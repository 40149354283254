import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddPolicyComponent } from './add-policy/add-policy.component'; 
import { LayoutComponent } from 'src/app/shared/components/layout/layout.component';
import { AuthGuardService } from 'src/app/core/services/auth-guard.service';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: LayoutComponent,
      children:[
      {
      path: '',
      component: AddPolicyComponent
      //canActivate:[AuthGuardService],
    }]
}])],
 exports: [RouterModule]
})
export class NewPolicyRoutingModule { }
