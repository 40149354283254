import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'upsc-commodities',
  templateUrl: './commodities.component.html',
  styleUrls: ['./commodities.component.scss']
})
export class CommoditiesComponent {

  @Input() public isCollapsed = true;
  @Input() public title: string;
  @Input() public subtitle: string;

  @Output() collapsedEvent = new EventEmitter<boolean>(false);

  unCollapsed(){
    this.collapsedEvent.emit(true);
  }

  collapsed(){
    this.collapsedEvent.emit(false);
  }

}
