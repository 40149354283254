export interface IShippingDetails {
    senderDetails?: ISenderDetails;
    receiverDetails?: IReceiverDetails;
    trackingNumber?: string;
    weight?: string;
    insuredAmount?: string;
    serviceLevel?: string;
    invoiceNumber?: string;
    referenceNumber?: string;
    merchandiseDescription?: string;
    insuredValueCurrency?: string;
    shipDate?: Date | string;
    merchandiseValue?: string;
    verifiedShippingCharge?: string;
    shipmentConfirmation?: string;
    insuredValue?: string;
    invoiceAmount?: string;
    
    //Add Track 3.0 Response to the Shipping Details section of claim requests
    shipperNumber?: string;
    shipperCompanyName?: string;
    consigneeAttentionName?: string;
    shipperAddressCode?: string;
    shipperAddressLine?: string;
    shipperCity?: string;
    shipperStateProvinceCode?: string;
    shipperPostalCode?: string;
    shipperCountryCode?: string;
    consigneeAddressCode?: string;
    consigneeAddressLine?: string;
    consigneeCity?: string;
    consigneeStateProvinceCode?: string;
    consigneePostalCode?: string;
    consigneeCountryCode?: string;
    serviceCode?: string;
    serviceDescription?: string;
    referenceFieldsValue?: string;
    shipmentStatusDescription?: string;
    deliveryDetailsCode?: string;
    deliveryDetailsDescription?: string;
    deliveryDetailsDate?: Date | string;
    deliveryDetailsTime?: string;
    declaredValueCurrencyCode?: string;
    declaredValueMonetaryValue?: string;
    currentStatusDescription?: string;
    serviceOptionsDescription?: string;
    modeOfTransportationCode?: string;
    modeOfTransportationDescription?: string;
    shipmentIdentificationNumber?: string;
    /* shipperAddressCode?: string;
    consigneeAddressCode?: string; */
}

export interface IShippingDetailsAutofill {
    shipperConsigneeAddress?: IShipperConsigneeAddress[];
    shipDate?: string | Date;
    shipmentIdentificationNumber?: string;
    shipperNumber?: string;
    companyName?: string;
    attentionName?: string;
    serviceCode?: {
        code?: string;
        description?: string;
    }
    deliveryDetails?: {
        code?: string;
        description?: string;
        date?: string;
        time?: string;
    }
    declaredValue?: {
        currencyCode?: string;
        monetaryValue?: string;
    }
    shipmentPackage?: {
        deliveryDate?: string;
        shipmentIdentificationNumber?: string;
        currentStatusDescription?: string;
        serviceOptionTypeDescription?: string;
    }
    shipmentType?: {
        code?: string;
        description?: string;
    },
    referenceFieldsValue?: string;
    noInfoReturned?: boolean;
}

export interface IShipperConsigneeAddress {
    code?: string;
    city?: string;
    stateProvinceCode?: string;
    countryCode?: string;
    addressLine?: string;
    addressLine2?: string;
    postalCode?: string;
    description?: string;
    companyName?: string;
}

export const emptyShippingDetails = {
    shipperConsigneeAddress: [
        {
            code: "",
            city: "",
            stateProvinceCode: "",
            countryCode: "",
            addressLine: "",
            addressLine2: "",
            postalCode: "",
        },
        {
            code: "",
            city: "",
            stateProvinceCode: "",
            countryCode: "",
            addressLine: "",
            addressLine2: "",
            postalCode: "",
        },
    ],
    shipDate: "",
    shipmentIdentificationNumber: "",
    shipperNumber: "",
    companyName: "",
    attentionName: "",
    serviceCode: {
        code: "",
        description: "",
    },
    value: "",
    deliveryDetails: {
        code: "",
        description: "",
        date: "",
        time: "",
    },
    declaredValue: {
        currencyCode: "",
        monetaryValue: "",
    },
    shipmentPackage: {
        deliveryDate: "",
        shipmentIdentificationNumber: "",
        currentStatusDescription: "",
        serviceOptionTypeDescription: "",
    },
    shipmentType: {
        code: "",
        description: "",
    },
    referenceFieldsValue: "",
}

export const emptyShippingDetailsWithErrorResponse = {
    shipperConsigneeAddress: [
        {
            code: "",
            city: "",
            stateProvinceCode: "",
            countryCode: "",
            addressLine: "",
            addressLine2: "",
            postalCode: "",
        },
        {
            code: "",
            city: "",
            stateProvinceCode: "",
            countryCode: "",
            addressLine: "",
            addressLine2: "",
            postalCode: "",
        },
    ],
    shipDate: "",
    shipmentIdentificationNumber: "",
    shipperNumber: "",
    companyName: "",
    attentionName: "",
    serviceCode: {
        code: "",
        description: "",
    },
    value: "",
    deliveryDetails: {
        code: "",
        description: "",
        date: "",
        time: "",
    },
    declaredValue: {
        currencyCode: "",
        monetaryValue: "",
    },
    shipmentPackage: {
        deliveryDate: "",
        shipmentIdentificationNumber: "",
        currentStatusDescription: "",
        serviceOptionTypeDescription: "",
    },
    shipmentType: {
        code: "",
        description: "",
    },
    referenceFieldsValue: "",
    noInfoReturned: true
}

export interface IReceiverDetails{
    companyName?: string;
    city?: string;
    state?: string;
    country?: string;
    zipCode: string;
    address1?: string;
    address2?: string;
}

export interface ISenderDetails{
    zipCode: string;
    companyName?: string;
    city?: string;
    state?: string;
    country?: string;
    address1?: string;
    address2?: string;
}

export const hardCodedTrack3ShipmentDetails = {
    "shipperConsigneeAddress": [
        {
            "code": "01", 
            "description": "Shipper Address", 
            "companyName": "THE UPS STORE", 
            "addressLine": "21301 STATE ROUTE 410  E", 
            "addressLine2": null, 
            "city": "BONNEY LAKE", 
            "stateProvinceCode": "WA", 
            "countryCode": "US", 
            "postalCode": "983918468"
        }, 
        {
            "code": "02", 
            "description": "ShipTo Address", 
            "companyName": "JULIE LINDERMAN", 
            "addressLine": "2150 N 107TH ST", 
            "addressLine2": null, 
            "city": "SEATTLE", 
            "stateProvinceCode": "WA", 
            "countryCode": "US", 
            "postalCode": "98133"
        }
    ], 
    "shipperNumber": "436186", 
    "companyName": "THE UPS STORE", 
    "attentionName": null, 
    "serviceCode": {
        "code": "003", 
        "description": "UPS Ground"
    }, 
    "value": null, 
    "deliveryDetails": {
        "code": null, 
        "description": null, 
        "date": null, 
        "time": null
    }, 
    "declaredValue": null, 
    "shipmentPackage": {
        "shipmentIdentificationNumber": "1Z4361860360872499", 
        "currentStatusDescription": "Returned to Sender", 
        "serviceOptionTypeDescription": null, 
        "deliveryDate": null, 
        "activity": [ ]
    }, 
    "shipmentType": {
        "code": "01", 
        "description": "Small Package"
    }, 
    "shipDate": "20230801"
}

export interface IChannelPartnerShipmentDetails {
    originAddress: string,
    originCity: string,
    originState: string,
    originCountryCode: string,
    originPostalCode: string,
    destinationAddress: string,
    destinationCity: string,
    destinationState: string,
    destinationCountryCode: string,
    destinationPostalCode: string,
    shipperName: string,    //Origin Name
    consigneeName: string,  //Destination Name
    shipDate: string,
    carrier: string,
    policyNumber: string,
    insuredValue: string,
    trackingNumber: string
}

export const hardCodedChannelPartnerShipmentDetails = {
    originAddress: '123 Street',
    originCity: 'city',
    originState: 'AA',
    originCountryCode: 'US',
    originPostalCode: '12345',
    destinationAddress: '321 Street',
    destinationCity: 'dCity',
    destinationState: 'GA',
    destinationCountryCode: 'US',
    destinationPostalCode: '54321',
    shipperName: 'og',
    consigneeName: 'dg',
    shipDate: '2023-10-30T12:00:00.000Z',
    carrier: 'UPS',
    policyNumber: '15914024-650316',
    insuredValue: '8000.00',
    trackingNumber: '356823305'
}

export const emptyChannelPartnerShipmentDetails = {
    originAddress: '',
    originCity: '',
    originState: '',
    originCountryCode: '',
    originPostalCode: '',
    destinationAddress: '',
    destinationCity: '',
    destinationState: '',
    destinationCountryCode: '',
    destinationPostalCode: '',
    shipperName: '',
    consigneeName: '',
    shipDate: '',
    carrier: '',
    policyNumber: '',
    insuredValue: '',
    trackingNumber: ''
}
