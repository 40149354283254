import { NgModule } from '@angular/core';

import { TupssLayoutComponent } from './tupss-layout.component';
import { SharedModule } from '../../shared.module';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { NavigationModule } from '../navigation/navigation.module';
import { InsureshieldFooterModule } from '../insureshield-footer/insureshield-footer.module';

@NgModule({
  declarations: [TupssLayoutComponent],
  imports: [
    FooterModule,
    HeaderModule,
    NavigationModule,
    SharedModule,
    InsureshieldFooterModule
  ]
})
export class TupssLayoutModule { }
