import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProspectService } from 'src/app/services/prospect.service';
import { TaggingService } from 'src/app/tags/tagging.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'upsc-decline',
  templateUrl: './decline.component.html',
  styleUrls: ['./decline.component.scss']
})
export class DeclineComponent implements OnInit {

  //Data
  declineForm: FormGroup;
  prospectPortalData;

  //Page Logic
  pageLoading: boolean = true;
  submitting: boolean = false;
  showThanks: boolean = false;
  upcomingSunday;

  //Errors
  systemError: boolean = false;

  constructor (
    private fb: FormBuilder,
    private router: Router,
    private prospectService: ProspectService,
    private taggingService: TaggingService
  ) {
    this.upcomingSunday = this.determineUpcomingSunday();
    this.declineForm = this.fb.group({
      option: [null, Validators.required],
      other: [null]
    });
    
    this.gatherSessionData();
  }

  ngOnInit(): void {
    this.taggingService.view({
      page_id: "decline_feedback_page",
      page_name: "Decline Feedback"
    });
    //Set Required flags depending on options selected.
    this.option.valueChanges.subscribe(
      val => {
        if (val == 'other') {
          this.other.setValidators(Validators.required);
        } else {
          this.other.setErrors(null);
          this.other.setValidators(null);
        }
      }
    );
  }

  homepage() {
    this.router.navigate(['/login']);
    window.scrollTo(0,0);
  }

  //"Bind Policy" with a declined reason
  //Also send an email to an internal UPSC user, with info about the decline.
  submit() {
    this.taggingService.link({
      link_name: "Submit",
      link_page_id: "decline_feedback_page"
    });
    this.submitting = true;
    this.systemError = false;
    if (this.declineForm.valid) {

      let emailRequest = this.buildEmailRequest();
      let bindRequest = this.formBindPolicyRequest();
      this.prospectPortalData = { ...this.prospectPortalData,
        currentPage: '/prospect-portal/under-review'
      }
      sessionStorage.setItem('prospectPortalData', JSON.stringify(this.prospectPortalData));
      this.saveSession();

      this.prospectService.bindPolicyCRM(bindRequest).subscribe(
        data => {
          if (data && data?.status.toLowerCase() == 'success') {
            this.prospectService.sendDeclineEmail(emailRequest).subscribe(
              () => { 
                this.showThanks = true;
                this.submitting = false;
              }
            );
          } else {
            this.systemError = true;
            this.submitting = false;
          }
        }, error => {
          this.submitting = false;
          this.systemError = true;
        }
      );
    } else {
      this.declineForm.markAllAsTouched();
      this.submitting = false;
    }
  }

  gatherSessionData() {
    this.prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData'));

    if (this.prospectPortalData) {
      this.pageLoading = false;
      this.systemError = false;
    } else {
      this.pageLoading = false;
      this.systemError = true;
    }
  }

  buildEmailRequest() {
    let request = {
      applicationName: 'OnlinePortal',
      emailFor: 'PPDeclineCoverage',
      environment: environment.webjob.env,
      data: {
        assuredName: this.prospectPortalData.customerCompany,
        firstName: this.prospectPortalData.customerName,
        lastName: '',
        salesPersonName: '',
        opportunityId: this.prospectPortalData.opportunityID,
        contactPhoneNumber: this.prospectPortalData.customerPhone,
        contactEmail: this.prospectPortalData.customerEmail,
        email: this.prospectPortalData.accountOwnerEmail,
        rejectionReason: this.option.value == 'high' ? 'The quote is too high' : this.option.value == 'already' ? 'I already have coverage' : this.option.value == 'chat' ? 'I would like to chat with a representative about my insurance options' : this.option.value == 'other' ? this.other.value : '',
        rejectionDate: new Date()
      },
      countryLocale: 'en-US'
    }
    return request;
  }

  formBindPolicyRequest() {
    let request = {
      domain: 'upsprospectpolicy',
      companyName: this.prospectPortalData.customerCompany,
      fullName: this.prospectPortalData.customerName,
      addressLine1: this.prospectPortalData.billingStreet,
      city: this.prospectPortalData.billingCity,
      state: this.prospectPortalData.billingStateCode,
      zipCode: this.prospectPortalData.billingPostalCode,
      country: 'US',
      phoneNumber: this.prospectPortalData.customerPhone,
      email: this.prospectPortalData.customerEmail,
      accountHolderContact: {
        customerNumber: this.prospectPortalData.numberofParent,
        taxID: this.formatTaxId(),
        companyWebsite: this.prospectPortalData.webSite
      },
      billingContact: {
        firstName: this.getFirstName(),
        lastName: this.getLastName()
      },
      shipperDetails: this.determineShippers(),
      policy: {
        effectiveDate: `${this.upcomingSunday?.getFullYear()}-${this.upcomingSunday?.getMonth()+1}-${this.upcomingSunday?.getDate()}`,
        onlineQuoteRequestUID: this.prospectPortalData.quote_ID,
        transportationCarrier: {
          code: this.determineCarrierCode()
        }
      },
      commodityShipment: {
        commodities: this.determineCommodities()
      },
      tcrates: this.formTCRates(this.prospectPortalData.rate),
      isProspectPortalQuoteAccepted: false,
      rejectionReason: this.option.value == 'high' ? 'The quote is too high' : this.option.value == 'already' ? 'I already have coverage' : this.option.value == 'chat' ? 'I would like to chat with a representative about my insurance options' : this.option.value == 'other' ? this.other.value : '',
      opportunityID: this.prospectPortalData.opportunityID,
      producerCode: this.prospectPortalData.accountOwner
    }

    return request;
  }

  determineShippers() {
    let carrierArray = [];
    let hasUPS: boolean = false;
    let shipperNumbers = this.prospectPortalData.shipperNumbers;
    hasUPS = this.prospectPortalData.selectedCarriers.filter(x => x.toLowerCase() == 'ups').length > 0 ? true : false;

    if (hasUPS) {
      for (let i = 0; i < shipperNumbers.length; i++) {
        carrierArray.push({
          shipperCarrier: {
            code: 'ups'
          },
          shipperNumber: shipperNumbers[i],
          startDate: `${this.upcomingSunday?.getFullYear()}-${this.upcomingSunday?.getMonth()+1}-${this.upcomingSunday?.getDate()}`
        });
      }
    }

    //Working testing data
    // carrierArray = [{
    //   shipperCarrier: {
    //       code: "ups"
    //   }, 
    //   shipperNumber: "TestShipper1", 
    //   startDate: "2027-09-22"
    // }]

    return carrierArray;
  }

  determineUpcomingSunday(): Date {
    let now = new Date();
    let result = new Date();
    if (now.getDay() == 0) {  //today is Sunday
      result.setDate(now.getDate() + 7);
    } else if (now.getDay() == 1) {  //today is monday
      result.setDate(now.getDate() + 6);
    } else if (now.getDay() == 2) {  //today is tuesday
      result.setDate(now.getDate() + 5);
    } else if (now.getDay() == 3) {  //today is wednesday
      result.setDate(now.getDate() + 4);
    } else if (now.getDay() == 4) {  //today is thursday
      if (now.getHours() > 12) {  //today is after 12PM
        result.setDate(now.getDate() + 10);
      } else {  //today is before 12PM
        result.setDate(now.getDate() + 3);
      }
    } else if (now.getDay() == 5) {  //today is friday
      result.setDate(now.getDate() + 9);
    } else if (now.getDay() == 6) {  //today is saturday
      result.setDate(now.getDate() + 8);
    }
    return result;
  }

  determineCommodities() {
    let commodities;
    if (this.prospectPortalData.selectedProductsKeysOnly.length == 1) {
      commodities = [
        {
          percentageOfInsuredValue: 100,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[0].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[0].key
        }
      ]
    } else if (this.prospectPortalData.selectedProductsKeysOnly.length == 2) {
      commodities = [
        {
          percentageOfInsuredValue: 50,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[0].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[0].key
        },
        {
          percentageOfInsuredValue: 50,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[1].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[1].key
        }
      ]
    } else if (this.prospectPortalData.selectedProductsKeysOnly.length == 3) {
      commodities = [
        {
          percentageOfInsuredValue: 33,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[0].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[0].key
        },
        {
          percentageOfInsuredValue: 33,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[1].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[1].key
        },
        {
          percentageOfInsuredValue: 34,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[2].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[2].key
        }
      ]
    }
    //Working testing data
    // commodities = [
    //   {
    //     percentageOfInsuredValue: 100,
    //     packagingDescription: this.prospectPortalData.selectedProducts[0],
    //     code: '110'
    //   }
    // ]
    return commodities;
  }

  determineCarrierCode() {
    let hasOtherCarriers: boolean = false;
    let hasUPS: boolean = false;

    hasUPS = this.prospectPortalData.selectedCarriers.filter(x => x.toLowerCase() == 'ups').length > 0 ? true : false;
    hasOtherCarriers = this.prospectPortalData.selectedCarriers.filter(x => x.toLowerCase() != 'ups').length > 0 ? true : false;

    if (hasUPS && hasOtherCarriers) {
      return 'Both';
    } else if (!hasUPS && hasOtherCarriers) {
      return 'Non-UPS';
    } else if (hasUPS && !hasOtherCarriers) {
      return 'UPS';
    } else {
      return 'error';
    }
  }

  saveSession() {
    this.prospectService.saveProspectPortalSession({
      opportunityID: this.prospectPortalData.opportunityID,
      emailAddress: this.prospectPortalData.customerEmail,
      data: JSON.stringify(this.prospectPortalData)
    }).subscribe(() => {});
  }

  formatTaxId() {
    let taxId = this.prospectPortalData.taxID;
    if (!taxId) {
      return;
    }
    let firstPart = taxId.substring(0, 2);
    let secondPart = taxId.substring(2);
    if (taxId.includes('-')) {
      return taxId;
    } else {
      taxId = firstPart + '-' + secondPart;
      return taxId
    }
  }

  getFirstName() {
    if (this.prospectPortalData.customerName.includes(' ')) {
      //There are spaces in the name. Take the first part of the name.
      return this.prospectPortalData.customerName.split(' ')[0];
    } else {
      //There are no spaces in the name. Return the entire string.
      return this.prospectPortalData.customerName;
    }
  }

  getLastName() {
    if (this.prospectPortalData.customerName.includes(' ')) {
      //There are spaces in the name. Take everything after the first space.
      return this.prospectPortalData.customerName.substring(this.prospectPortalData.customerName.indexOf(' ')+1);
    } else {
      //There are no space in the name. Return nothing.
      return '';
    }
  }

  formTCRates(rate) {
    let tcrates = [
      {
        mode: 'Small Package',
        adjRate: rate,
        limit: 50000.00
      },
      {
        mode: 'Mail or Parcel Post',
        adjRate: rate,
        limit: 1000.00
      },
      {
        mode: 'Truck or Train',
        adjRate: rate,
        limit: 150000.00
      },
      {
        mode: 'Ocean',
        adjRate: rate,
        limit: 250000.00
      },
      {
        mode: 'Air Freight',
        adjRate: rate,
        limit: 150000.00
      }
    ];

    return tcrates;
  }

  get option() { return this.declineForm.controls.option; }
  get other() { return this.declineForm.controls.other; }
}
