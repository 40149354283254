<form [formGroup]="shippingStatusFormGroup" class="form-content">
    <div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
        <div class="spinner-border">
            <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
        </div>
    </div>
    <div *ngIf="!showSpinner">
        <div *ngIf="notificationStatus==='1'" class="notification-green" (click)="hideMessage()">
            <mat-icon>check_circle</mat-icon>
            <div class="notify-sub-title"> {{ 'claims.edit.shippingStatus.messages.packageReceivedMsg' | translate }}
            </div>
        </div>
        <div *ngIf="notificationStatus==='2'" class="notification-yellow">
            <mat-icon>info</mat-icon>
            <div class="notify-sub-title">{{ 'claims.edit.shippingStatus.messages.damagedOrMissingPartMsg1' | translate
                }}
            </div>
            <div class="notify-sub-title-2">{{ 'claims.edit.shippingStatus.messages.damagedOrMissingPartMsg2' |
                translate }}
            </div>
        </div>
        <div *ngIf="notificationStatus==='3'" class="notification-yellow">
            <mat-icon>info</mat-icon>
            <div class="notify-sub-title">{{ 'claims.edit.shippingStatus.messages.packageHasNotReceivedMsg1' | translate
                }}
            </div>
            <div class="notify-sub-title-2">{{ 'claims.edit.shippingStatus.messages.packageHasNotReceivedMsg2' |
                translate
                }}</div>
        </div>
        <div *ngIf="notificationStatus==='4'" class="notification-yellow">
            <mat-icon>info</mat-icon>
            <div class="notify-sub-title">{{ 'claims.edit.shippingStatus.messages.timeHasExpired' | translate }}</div>
        </div>
        <div *ngIf="notificationStatus==='0'" class="notification-rejected" (click)="hideMessage()">
            <mat-icon>report_problem</mat-icon>
            <div class="notify-sub-title">{{ 'claims.edit.shippingStatus.errors.somethingWentWrong' | translate }}
            </div>
        </div>

        <div class="white-on-gray" *ngIf="!timeLapsed">
            <div *ngIf="!confirmed">
                <div class="mat-form-fields">
                    <mat-radio-group class="radio-group" formControlName="status">
                        <mat-radio-button *ngFor="let item of shippingStatus" value="{{item.id}}"
                            (click)="toggleStatus(item.id)">
                            {{ item.text }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="mat-form-fields">
                <mat-error *ngIf="isError">
                    Status Required
                </mat-error>
            </div>
            <div class="step-controls">
                <div class="btnAlign" *ngIf="!hideSubmitButton">
                    <button class="upsc-button" role="button" type="button" (click)="submitStatus()"
                        [disabled]="disabledSubmitButton">
                        <div>{{ 'claims.edit.shippingStatus.buttons.submit' | translate }}</div>
                    </button>
                </div>
                <div class="btnAlign" *ngIf="!hideUpdateButton">
                    <button class="upsc-button" role="button" type="button" (click)="updateClaim()">
                        <div>{{ 'claims.edit.shippingStatus.buttons.updateClaim' | translate }}</div>
                    </button>
                </div>
                
                <!-- <div class="button-space">
                    <button class="upsc-button" role="button" type="button">
                        <div>{{ 'claims.edit.shippingStatus.buttons.learnMore' | translate }}</div>
                    </button>
                </div> -->
            </div>
        </div>       
    </div>
</form>