export class UserPermission {
    isAnalytics?: boolean;
    isBillingView?: boolean;
    isBillingPayments?: boolean;
    isFileClaim?: boolean;
    isClaimPayee?: boolean;
    isPolicyView?: boolean;
    isPolicyUpdate?: boolean;
    isClaimView?: boolean;
    isHVCM?: boolean;
    isManagePaymentMethods?: boolean;
    isSmartUpload?: boolean;
    isUserManagement?: boolean;
}
