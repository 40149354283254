<div class="progress-bar-container">
  <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
</div>

<ul class="steps">
  <li class="step" *ngFor="let step of steps" [class.active]="step.isActive">
    <div class="order" (click)="setCurrentStepManual(step.order)">{{ step.order }}</div>
    <div class="label">{{ step.label }}</div>
  </li>
</ul>
