<form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
  <div class="wrap">
    <div class="title mb-3">
      <div>{{ 'addressvalidation.labels.title' | translate }} </div>
    </div>
    <div class="mb-3 current-address">
      <div>{{ data.addressLine1}}</div>
      <div>{{ data.addressLine2 }}</div>
      <div>{{ data.city }}, {{ data.state }} {{ data.zipcode }}</div>
    </div>
    <div class="mb-4" *ngIf="isAddressValid">
      {{ 'addressvalidation.labels.match' | translate }}
    </div>

    <div class="address-list mb-3">
      <mat-radio-group formControlName="selection" [(ngModel)]="SelectedValue">
        <div class="address" *ngFor="let address of addressList.slice(0,3)"
          [class.selected-border]="selection.value == addressList.indexOf(address)">
          <mat-radio-button value="{{addressList.indexOf(address)}}">
            <div>{{address.AddressLine[0]}} {{address.AddressLine[1]}}</div>
            <div>{{address.AddressLine2}}</div>
            <div>{{address.PoliticalDivision2}}, {{address.PoliticalDivision1}}
              {{address.PostcodePrimaryLow}}-{{address.PostcodeExtendedLow}}</div>
          </mat-radio-button>
        </div>
        <div class="address" [class.selected-border]="selection.value == '-1'">
          <mat-radio-button value="-1" (change)="radioChange($event)">
            {{ 'addressvalidation.labels.continue' | translate }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <div class="mb-3 mobile__note-content">
      <span class="bold">{{ 'addressvalidation.labels.note' | translate }}</span>
      {{ 'addressvalidation.labels.standardized' | translate }}
    </div>
    <div class="mb-3 mobile__note-content">{{ 'addressvalidation.labels.ups' | translate }}</div>
  </div>
  <div class="mt-auto d-flex py-md-3 justify-content-end align-items-center button-alignment__mobile">
    <button class="previous-step upsc-button_back" (click)="edit()" type="button">
      <mat-icon>chevron_left</mat-icon> {{ 'claims.edit.back' | translate }}
    </button>
    <button *ngIf="!loading && isAddressValid" class="upsc-button" type="submit">{{
      'claims.edit.validate' | translate }} <mat-icon>chevron_right</mat-icon></button>

    <button *ngIf="!loading && !isAddressValid" class="upsc-button" [disabled]="disableButton">{{
      'claims.edit.validate' | translate }}<mat-icon>chevron_right</mat-icon></button>

    <button *ngIf="loading" class="upsc-button loading d-flex align-items-center justify-content-between">
      <div>{{ 'claims.edit.validate' | translate }}</div>
      <div class="spinner-border" role="status">
        <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
      </div>
    </button>
  </div>
</form>