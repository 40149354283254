<div class="wrapper">
    <div class="heading d-flex justify-content-end">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="body">
        <div class="title">
            {{ 'claims.edit.draftDelete.title' | translate }}
        </div>
        <hr class="green-hr">
        <div class="content">
            {{ 'claims.edit.draftDelete.descr' | translate }}
        </div>
    </div>
    <div class="navi d-flex justify-content-start align-items-center">
        <button class="upsc-button" (click)="delete()">
            {{ 'claims.edit.draftDelete.delete' | translate }}
        </button>
        <div class="cancel ml-3" (click)="close()">
            {{ 'claims.edit.draftDelete.cancel' | translate }}
        </div>
    </div>
</div>