<div class="wrapper-tupss" *ngIf="!showEmailVerification">
  <div class="d-flex flex-column-reverse flex-md-row" *ngIf="tupssType=='tccp'">
    <div class="left">
      <div class="title">{{ 'customerclaimsportal.intro' | translate }}</div>
      <div class="subtitle p-3">{{ 'customerclaimsportal.intro1' | translate }}</div>
      <form [formGroup]="customerForm" (keyup.enter)="customerForm.valid">
        <div class="myclaims-info">
          <div class="p-3">
            <div class="field">
              <!-- <div class="pb-2">{{ 'customerclaimsportal.form.tracking' | translate }}</div> -->
              <mat-form-field class="w-100">
                <input matInput formControlName="trackingNumber"
                  placeholder="{{'customerclaimsportal.form.enterTN' | translate}}" (blur)="getShipmentDetails()">
                <mat-error *ngIf="customerForm.controls['trackingNumber'].errors">
                  <div *ngIf="customerForm.get('trackingNumber').errors?.required">
                    {{ 'claims.edit.start.tracking.form.trackingNumber.errors.required' | translate }}
                  </div>
                  <div *ngIf="customerForm.controls['trackingNumber'].errors?.pattern">
                    {{ 'claims.edit.start.tracking.form.trackingNumber.errors.pattern' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <!-- <div class="pb-2">{{ 'customerclaimsportal.form.email' | translate }}</div> -->
              <mat-form-field class="w-100">
                <input matInput formControlName="email"
                  placeholder="{{'customerclaimsportal.form.enterE' | translate}}">
                <mat-error *ngIf="customerForm.controls['email'].errors">
                  <div *ngIf="customerForm.controls['email'].errors?.required">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.required' | translate
                    }}
                  </div>
                  <div *ngIf="customerForm.controls['email'].errors?.pattern">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valid' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="background-wrap mb-3">
            <div class="d-flex justify-content-between pb-2">
              <div>{{ 'customerclaimsportal.form.shipmentid' | translate }} {{ 'customerclaimsportal.form.or' | translate }} {{
                'customerclaimsportal.form.transactionid' | translate }} </div>
            </div>
            <div class="field">
              <div class="d-flex justify-content-between">
                <mat-form-field class="w-100">
                  <input matInput formControlName="shipmentId" placeholder="{{'customerclaimsportal.form.enterS' | translate}}">
                  <mat-error *ngIf="customerForm.controls['shipmentId'].errors">
                    <div *ngIf="customerForm.get('shipmentId').errors?.required">
                      {{ 'customerclaimsportal.form.errors.reqshipmentid' | translate }}
                    </div>
                  </mat-error>
                </mat-form-field>
                <mat-icon ngbTooltip=" " [autoClose]="false" tooltipClass="ngb-tooltip-class shipment-image">info</mat-icon>
              </div>
              <div class="d-flex justify-content-between">
                <mat-form-field class="w-100">
                  <input matInput formControlName="transactionId"
                    placeholder="{{'customerclaimsportal.form.enterT' | translate}}">
                </mat-form-field>
                <mat-error *ngIf="customerForm.controls['transactionId'].errors">
                  <div *ngIf="customerForm.get('transactionId').errors?.required">
                    {{ 'customerclaimsportal.form.errors.reqshipmentid' | translate }}
                  </div>
                </mat-error>
                <mat-icon ngbTooltip=" " [autoClose]="false" tooltipClass="ngb-tooltip-class transaction-image">info</mat-icon>
              </div>
              <div>
                <a href="#" (click)="openFAQs($event)">
                  {{ 'customerclaimsportal.form.faqLinkTitle' | translate }}
                </a>
              </div>
            </div>
          </div>
          <re-captcha formControlName="recaptchaControl"></re-captcha>
          <div class="mt-3" *ngIf="errors && errors.length > 0">
            <upsc-notification-box class="error-box" [isDanger]="true">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon p-2 d-flex"><mat-icon>cancel_outline</mat-icon></div>
                  <div class="errors">
                    <div [innerHTML]="errors[0] | translate" (click)="processLinks($event)"></div>
                  </div>
                  <div class="icon2 p-2 d-flex" (click)="closeErrorMsg()"><mat-icon>highlight_off</mat-icon></div>
                </div>
              </div>
            </upsc-notification-box>
          </div>
          <div class="error" *ngIf="systemError">{{ 'common.systemError' | translate }}</div>
          <button class="upsc-button" *ngIf="!loading" [disabled]="!customerForm.valid || currentlyCheckingDupeTrackingNumbers" (click)="nextStep()" href="#">
            {{ 'claims.edit.beginNewClaim' | translate }}
          </button>
          <button class="upsc-button loading" *ngIf="loading">
            {{ 'claims.edit.beginNewClaim' | translate }}
            <div class="spinner-border" role="status">
              <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
            </div>
          </button>
        </div>
      </form>
    </div>
    <div class="right mb-5 mb-md-0">
      <div class="side-bg d-flex justify-content-center align-items-center">
        <div class="process">
          <div class="intro mb-3">{{ 'customerclaimsportal.claimintro' | translate }}</div>
          <div class="step">
            <div class="step-icon">1</div>
            {{ 'customerclaimsportal.step1' | translate }}
          </div>
          <div class="step">
            <div class="step-icon">2</div>
            {{ 'customerclaimsportal.step2' | translate }}
          </div>
          <div class="step">
            <div class="step-icon">3</div>
            {{ 'customerclaimsportal.step3' | translate }}
          </div>
          <div class="step">
            <div class="step-icon">4</div>
            {{ 'customerclaimsportal.step4' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showEmailVerification">
  <upsc-email-verification [email]="email.value" (canceled)="cancel()"></upsc-email-verification>
</div>

<ng-template #shipmentIdTemplate>
  <img src="../../../../../../../assets/images/ShipmentID.png" />
</ng-template>