<div class="main d-flex flex-column justify-content-around align-items-center">
  <div class="icon">
    <em class="material-icons upsgreen600 md-72">check_circle</em>
  </div>
  <div class="password">
    {{ 'resetPasswordSuccess.message' | translate }}
  </div>
  <div class="button">
    <button class="upsc-button" role="button" type="button" routerLink='/login'>
      {{ 'resetPasswordSuccess.login' | translate }}
    </button>
  </div>
  <div class="contact">
    {{ 'loginHelp.contactUs' | translate }}
    <div class="contact-bold">{{ 'loginHelp.contactUsBold' | translate }}</div>
  </div>
</div>