import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class LoginResolver  {
  constructor(private auth: AuthService,
              private router: Router) {
  }

  public resolve(): Observable<any> {
    if (this.auth.isAuthenticated) {
      this.router.navigate(['/home']);
      return of(true);
    }

    return of(false);
  }
}
