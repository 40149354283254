import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { updateACH } from '../../../../../../shared/models/payee.interface';
import { PayeeService } from 'src/app/services/payee.service';
import { UserService } from '../../../../../../services/user.service';
import { environment as ENV } from '../../../../../../../environments/environment';

@Component({
  selector: 'upsc-bank-success',
  templateUrl: './bank-success.component.html',
  styleUrls: ['./bank-success.component.scss']
})
export class BankSuccessComponent {

  //Banking
  updateACH: updateACH = {
    "payeeID": "",
    "achIndicator": "T"
  };

  constructor(
    private route: ActivatedRoute,
    private payeeService: PayeeService,
    private userService: UserService
  ) {
    this.route.queryParamMap.subscribe(queryParams => {
      if(queryParams.get('returnSessionId')) {
        //Update ACH Indicator
        this.updateACHIndicator(queryParams.get('returnSessionId'));
      } else {
        //Continue as normal if there are no parameters. It means we are not loading from within an iFrame
      }
    });
  }


  //Will only be called if params are sent back from Chase.
  updateACHIndicator(payeeID: string) {
    this.updateACH.payeeID = payeeID;
    this.payeeService.updateACHIndicator(this.updateACH).subscribe(
      data => {
        if(this.userService.isUserAdjuster()) {
          window.top.location.href = `${ENV.baseUrl.web}/adjusterDashboard`;
        }
        else {
          //On success, reroute to the payee details screen
          window.top.location.href = `${ENV.baseUrl.web}/claims/payee`;
        }
      }, error => { }
    );
  }

}
