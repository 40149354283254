
  <!-- new insureshield footer design -->
<div class="insureshield-footer-wrapper">
  <div class="footer-component">
    <!-- Desktop -->
    <div class="desktop" *ngIf="!isMobile">
      <div class="top-portion">
        <div class="first-column">
          <div class="column-title">This Site</div>
          <hr class="divider">
          <ul class="column-list">
            <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/shipping-insurance.html" target="_blank">Shipping Insurance</a></li>
            <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/business-services.html" target="_blank">Business Services</a></li>
            <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/claims.html" target="_blank">Claims</a></li>
            <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/partners.html" target="_blank">Partners</a></li>
            <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/resources.html" target="_blank">Resources</a></li>
            <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/about.html" target="_blank">About</a></li>
          </ul>
        </div>
        <div class="second-column">
          <div class="column-title">Other UPS Sites</div>
          <hr class="divider">
          <ul class="column-list">
            <li class="item-format"><a class="list-text" href="https://upscapital.com/" target="_blank">UPS Capital<mat-icon class="icon-format">launch</mat-icon></a></li>
            <li class="item-format"><a class="list-text" href="https://www.parcelpro.com/us/en/home.html?_gl=1*1yfgisx*_gcl_au*MTc1MjM4NzcxMi4xNjk0MDk5NTk0" target="_blank">Parcel Pro<mat-icon class="icon-format">launch</mat-icon></a></li>
            <li class="item-format"><a class="list-text" href="https://www.ups.com/us/en/Home.page" target="_blank">UPS<mat-icon class="icon-format">launch</mat-icon></a></li>
            <li class="item-format"><a class="list-text" href="https://www.ups.com/us/en/supplychain/Home.page" target="_blank">UPS Supply Chain Solutions<mat-icon class="icon-format">launch</mat-icon></a></li>
            <li class="item-format"><a class="list-text" href="https://about.ups.com/us/en/home.html" target="_blank">About UPS<mat-icon class="icon-format">launch</mat-icon></a></li>
          </ul>
        </div>
        <div class="third-column">
          <div class="column-title">Connect with Us</div>
          <hr class="divider">
          <ul class="column-list">
            <li class="item-format"><a class="list-text" href="https://www.facebook.com/InsureShield/" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-facebook">Facebook<mat-icon class="icon-format">launch</mat-icon></a></li>
            <li class="item-format"><a class="list-text" href="https://www.instagram.com/insureshield/" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-instagram">Instagram<mat-icon class="icon-format">launch</mat-icon></a></li>
            <li class="item-format"><a class="list-text" href="https://twitter.com/upscapital" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-twitter">Twitter<mat-icon class="icon-format">launch</mat-icon></a></li>
            <li class="item-format"><a class="list-text" href="https://www.linkedin.com/company/insureshield/" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-linkedin">LinkedIn<mat-icon class="icon-format">launch</mat-icon></a></li>
            <li class="item-format"><a class="list-text" href="https://www.youtube.com/@InsureShield" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-youtube">YouTube<mat-icon class="icon-format">launch</mat-icon></a></li>
          </ul>
        </div>
      </div>
      <div class="bottom-portion d-flex flex-column-reverse mb-3 mb-lg-4">
        <div id="divFooter">
          <div class="footer-wrap-left mt-3">
            <div class="footer-align">
              <div class="break"></div>
              <!--  USA footer start -->
              <div class="footer-link" *ngIf="isUsaUser">
                <a href="http://www.ups.com/content/us/en/resources/ship/terms/use.html" target="_blank"
                  rel="noopener noreferrer">
                  {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUsaUser">
                <a href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank">
                  {{'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUsaUser">
                <a href=" https://www.insureshield.com/us/en/legal/product-disclosure.html" target="_blank">
                  {{ 'app.footer.links.product' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUsaUser">
                <a href="http://www.ups.com/content/us/en/resources/ship/terms/california_privacy.html" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.privacyRights' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUsaUser">
                <a href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUsaUser">
                <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
                  {{ 'app.footer.cookies' | translate }}
                </a>
              </div>
              <div class="footer-link" *ngIf="isUsaUser">
                <a href="https://www.ups.com/ppwa/doWork?loc=en_US" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.sell' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <!-- // Canada footer  Start-->
              <div class="footer-link" *ngIf="isCanadaUser">
                <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
                  {{ 'app.footer.cookies' | translate }}
                </a>
              </div>
              <div class="footer-link" *ngIf="isCanadaUser">
                <a href="https://www.insureshield.com/ca/en/legal/technology-agreement.html" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isCanadaUser">
                <a href="https://www.ups.com/ca/en/support/shipping-support/legal-terms-conditions.page" target="_blank"
                  rel="noopener noreferrer">
                  {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isCanadaUser">
                <a href="https://www.insureshield.com/ca/en/legal/privacy-notice.html" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isCanadaUser">
                <a href="https://www.insureshield.com/ca/en/legal/product-disclosure.html" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.product' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <!-- // Europe footer  UK-->
              <div class="footer-link" *ngIf="isUkUser">
                <a href="https://www.ups.com/gb/en/help-center/legal-terms-conditions/website.page" target="_blank"
                  rel="noopener noreferrer">
                  {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUkUser">
                <a href="https://www.insureshield.com/gb/en/legal/technology-agreement.html" target="_blank">
                  {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUkUser">
                <a href="https://www.insureshield.com/gb/en/legal/product-disclosure.html" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUkUser">
                <a href="https://www.insureshield.com/gb/en/legal/complaints.html" target=" _blank">
                  {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUkUser">
                <a href="https://www.insureshield.com/gb/en/legal/privacy-notice.html" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isUkUser">
                <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
                  {{ 'app.footer.cookies' | translate }}
                </a>
              </div>
              <!--// Europe footer Germany -->
              <div class="footer-link" *ngIf="isGermanyUser">
                <a href="{{ determsOfuse }}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isGermanyUser">
                <a href="{{ deTechAgreementLink}}" target="_blank">
                  {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isGermanyUser">
                <a href="{{deproductDisclosures}}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isGermanyUser">
                <a href="{{ deComplints }}" target=" _blank">
                  {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isGermanyUser || isDeEnglish">
                <a href="{{ deImpressum }}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.impressum' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isGermanyUser">
                <a href="{{ deprivacyNotice }}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isGermanyUser">
                <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
                  {{ 'app.footer.cookies' | translate }}
                </a>
              </div>
              <!--// Europe footer France -->
              <div class="footer-link" *ngIf="isFranceUser">
                <a href="{{ frtermsOfuse }}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isFranceUser">
                <a href="{{ frTechAgreementLink }}" target="_blank">
                  {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isFranceUser">
                <a href="{{ frproductDisclosures }}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isFranceUser">
                <a href="{{ frComplints }}" target=" _blank">
                  {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isFranceUser">
                <a href="{{ frprivacyNotice }}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isFranceUser">
                <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
                  {{ 'app.footer.cookies' | translate }}
                </a>
              </div>
              <!--// Europe footer Italy -->
              <div class="footer-link" *ngIf="isItalyUser">
                <a href="{{ ittermsOfuse }}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isItalyUser">
                <a href="{{ itTechAgreementLink}}" target="_blank">
                  {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isItalyUser">
                <a href="{{ itproductDisclosures }}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isItalyUser">
                <a href="{{ itComplints }}" target=" _blank">
                  {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              
              <div class="footer-link" *ngIf="isItalyUser">
                <a href="{{ itprivacyNotice }}" target="_blank" rel="noopener noreferrer">
                  {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
                </a>
              </div>
              <div class="footer-link" *ngIf="isItalyUser">
                <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
                  {{ 'app.footer.cookies' | translate }}
                </a>
              </div>
            </div>   
            <div class="copyright-left">
              {{ 'app.footer.copyright_insureshield' | translate:{ year: thisYear } }}
            </div>
            <div class="copyright-mobile">
              {{ 'app.footer.copyright_insureshield' | translate:{ year: thisYear } }}
            </div>
          </div>
        </div>
        </div>
    </div>
    <!-- Mobile -->
    <div class="mobile" *ngIf="isMobile">
      <div class="mobile-top-portion">
        <mat-accordion>
          <mat-expansion-panel class="first-one">
            <mat-expansion-panel-header>
              <mat-panel-title><div class="white-text">This Site</div></mat-panel-title>
            </mat-expansion-panel-header>
            <div class="white-options">
              <ul class="column-list">
                <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/shipping-insurance.html" target="_blank">Shipping Insurance</a></li>
                <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/business-services.html" target="_blank">Business Services</a></li>
                <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/claims.html" target="_blank">Claims</a></li>
                <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/partners.html" target="_blank">Partners</a></li>
                <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/resources.html" target="_blank">Resources</a></li>
                <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/about.html" target="_blank">About</a></li>
              </ul>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title><div class="white-text">Other UPS Sites</div></mat-panel-title>
            </mat-expansion-panel-header>
            <div class="white-options">
              <ul class="column-list">
                <li class="item-format"><a class="list-text" href="https://upscapital.com/" target="_blank">UPS Capital<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.parcelpro.com/us/en/home.html?_gl=1*1yfgisx*_gcl_au*MTc1MjM4NzcxMi4xNjk0MDk5NTk0" target="_blank">Parcel Pro<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.ups.com/us/en/Home.page" target="_blank">UPS<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.ups.com/us/en/supplychain/Home.page" target="_blank">UPS Supply Chain Solutions<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://about.ups.com/us/en/home.html" target="_blank">About UPS<mat-icon class="icon-format">launch</mat-icon></a></li>
              </ul>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title><div class="white-text">Connect with Us</div></mat-panel-title>
            </mat-expansion-panel-header>
            <div class="white-options">
              <ul class="column-list">
                <li class="item-format"><a class="list-text" href="https://www.facebook.com/InsureShield/" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-facebook">Facebook<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.instagram.com/insureshield/" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-instagram">Instagram<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://twitter.com/upscapital" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-twitter">Twitter<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.linkedin.com/company/insureshield/" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-linkedin">LinkedIn<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.youtube.com/@InsureShield" target="_blank"><img class="social-img" src="https://s7d9.scene7.com/is/content/upsprod/social-youtube">YouTube<mat-icon class="icon-format">launch</mat-icon></a></li>
              </ul>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="last-one">
            <mat-expansion-panel-header>
              <mat-panel-title><div class="white-text">Legal</div></mat-panel-title>
            </mat-expansion-panel-header>
            <div class="white-options">
              <ul class="column-list">
                <li class="item-format"><a class="list-text" href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page" target="_blank">Website Terms of Use<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank">Technology Agreement<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/legal/product-disclosure.html" target="_blank">Product Disclosure<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/california-privacy-rights.page" target="_blank">Your California Privacy Rights<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank">Privacy Notice<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">Cookie Settings<mat-icon class="icon-format">launch</mat-icon></a></li>
                <li class="item-format"><a class="list-text" href="https://www.ups.com/ppwa/doWork?loc=en_US" target="_blank">Do Not Sell or Share My Personal Information<mat-icon class="icon-format">launch</mat-icon></a></li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mobile-bottom-portion">
        <div class="d-flex flex-column-reverse mb-3 mb-lg-4">
          <div id="divFooter">
            <div class="footer-wrap-left mt-3">
              <div class="copyright-left">
                {{ 'app.footer.copyright_insureshield' | translate:{ year: thisYear } }}
              </div>
              <div class="copyright-mobile">
                {{ 'app.footer.copyright_insureshield' | translate:{ year: thisYear } }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>