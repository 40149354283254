<div class="main">
  <div class="page-title" *ngIf="isCanadaUser">{{ 'payee.creation.title' | translate }}</div>
  <div class="page-title" *ngIf="isEuroUser">{{ 'payee.creation.title' | translate }}</div>
  <div class="page-subtitle" *ngIf="!isCanadaUser">{{ 'payee.creation.subtitle' | translate }}</div>
  <div class="page-subtitle" *ngIf="isCanadaUser">{{ 'payee.creation.succesTitle' | translate }}</div>
  <upsc-notification-box [isSuccess]="true" *ngIf="showNotification">
    <div class="d-flex">
      <mat-icon>check_circle_outline</mat-icon>
      <div class="pl-2">
        <div class="title pb-3">{{ 'notifications.payee.success' | translate }}</div>
        <div class="content">
          <div><b>{{ recentPayeeData.payeeName }}</b></div>
          <div>{{ recentPayeeData.emailAddress }}</div>
          <div>{{ recentPayeeData.domesticPhoneNumber | phoneNumber }}</div>
          <!-- <div *ngIf="isCanadaUser">{{ recentPayeeData.domesticPhoneNumber }}</div> -->
          <div>{{ recentPayeeData.domesticMailingAddress?.streetAddress1 }}</div>
          <div>{{ recentPayeeData.domesticMailingAddress?.streetAddress2 }}</div>
          <div *ngIf="!isCanadaUser">{{ recentPayeeData.domesticMailingAddress?.city }}, <span *ngIf="!isEuroUser">{{ recentPayeeData.domesticMailingAddress?.stateProvince }}</span> {{ recentPayeeData.domesticMailingAddress?.zip }}</div>
          <div *ngIf="!isCanadaUser">{{ recentPayeeData.domesticMailingAddress?.country}}</div>
          <div class="address" *ngIf="isCanadaUser">
            <div class="line1">{{ recentPayeeData.domesticMailingAddress?.city }}</div>
            <div class="line2">{{ recentPayeeData.domesticMailingAddress?.stateProvince }}</div>
            <div class="line3">{{ recentPayeeData.domesticMailingAddress?.zip }}</div>
            <div class="line4">{{ recentPayeeData.domesticMailingAddress?.country == 'CA' ? 'Canada' : recentPayeeData.domesticMailingAddress?.country}}</div>
          </div>
        </div>

      </div>
</div>
  </upsc-notification-box>

  <div class="default-payee-info" [class.bottom-spacing]="dangerNotification" *ngIf="showDefaultPayeeInfo">
    <div class="title mb-2">{{ 'payee.banking.payment' | translate }}</div>
    <div class="content">
      <div>{{ defaultPayeeData.payeeName }}</div>
      <div>{{ defaultPayeeData.payeeEmail }}</div>
      <div>{{ defaultPayeeData.payeePhone | phoneNumber }}</div>
      <!-- <div *ngIf="isCanadaUser">{{ defaultPayeeData.payeePhone }}</div> -->
      <div>{{ defaultPayeeData.address?.streetAddress1 }}</div>
      <div>{{ defaultPayeeData.address?.streetAddress2 }}</div>
      <div *ngIf="!isCanadaUser">{{ defaultPayeeData.address?.city }}, {{ defaultPayeeData.address?.stateProvince }} {{ defaultPayeeData.address?.zip }}</div>
      <div class="address" *ngIf="isCanadaUser">
        <div class="line1">{{ defaultPayeeData.address?.city }}</div>
        <div class="line2">{{ defaultPayeeData.address?.stateProvince }}</div>
        <div class="line3">{{ defaultPayeeData.address?.zip }}</div>
        <div class="line4">{{ defaultPayeeData.address?.country == 'CA' ? 'Canada' : recentPayeeData.domesticMailingAddress?.country}}</div>
      </div>
    </div>
  </div>

  <upsc-notification-box [isDanger]="true" *ngIf="dangerNotification">
    <div class="d-flex">
      <mat-icon class="danger">cancel_outline</mat-icon>
      <div class="text">{{ 'notifications.payee.danger' | translate }}</div>
    </div>
  </upsc-notification-box>

  <div *ngIf="!dangerNotification && !isCanadaUser &&!isEuroUser">
    <div class="bank-title mb-2" [ngClass]="{'margin' : showNotification || showDefaultPayeeInfo || dangerNotification }">{{ 'payee.banking.title' | translate }}</div>
    <div class="bank-subtitle mb-3">{{ 'payee.banking.subtitle' | translate }}</div>
    <div *ngIf="showWarning" class="bank-subtitle mb-3">{{ 'payee.banking.please_note_1' | translate }} 
      {{ 'payee.banking.please_note_2' | translate }}</div>
    <iframe id="iframe" title="" [src]="achurl" height="735px" ></iframe>
  </div>
  <div *ngIf="!hvcmFlow">
  <button *ngIf="!isAdjuster" class="upsc-button mt-3" [class.top-spacing]="dangerNotification" (click)="backtoClaim()">
    <div *ngIf="!payeeClaimFlowText">{{ 'payee.banking.button' | translate }} </div>
    <div *ngIf="payeeClaimFlowText">{{ 'payee.banking.button_2' | translate }} </div>
  </button>
  <button *ngIf="isAdjuster" class="upsc-button" [class.top-spacing]="dangerNotification" routerLink="/adjusterDashboard">{{ 'payee.banking.adjusterButton' | translate }}</button>
  </div>
  <div *ngIf="hvcmFlow" class="d-flex">
    <button class="upsc-button hvcm mt-3 mr-1" [class.top-spacing]="dangerNotification" (click)="backOrCancel('back')">
      {{ 'payee.banking.hvcmBackbutton' | translate }}
    </button>
    <button class="upsc-button hvcm mt-3 mr-1" [class.top-spacing]="dangerNotification" (click)="backOrCancel('cancel')">
      {{ 'payee.banking.hvcmCancelbutton' | translate }}
    </button>
    <button class="upsc-button hvcm mt-3" [class.top-spacing]="dangerNotification" (click)="openHVCM()">
      {{ 'payee.banking.hvcmContinuebutton' | translate }}
    </button>
  </div>
</div>