import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BillingService } from 'src/app/services/billing.service';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { TaggingService } from 'src/app/tags/tagging.service';

@Component({
  selector: 'upsc-check-fee-popup',
  templateUrl: './check-fee-popup.component.html',
  styleUrls: ['./check-fee-popup.component.scss']
})
export class CheckFeePopupComponent implements OnInit {
  userDetails;
  policyDetails;

  currentTemplate: any;
  canAccessDigitalWallet: boolean = false;
  sessionError: boolean = false;

  walletUser;
  acceptedTCs;
  checkFeeAmount;

  constructor(
    public dialogRef: MatDialogRef<CheckFeePopupComponent>,
    private taggingService: TaggingService,
    private loginService: LoginService,
    private userService: UserService,
    private billingService: BillingService
  ) {
    this.policyDetails = this.loginService.getPolicyDetails();
    this.userDetails = this.userService.getUserInfo();
    this.canAccessDigitalWallet = this.userService.canAccessDigitalWallet(); // If they don't have billing permissions, we hide any enroll in wallet buttons.
    this.walletUser = this.userService.isWalletUser();
    this.acceptedTCs = this.userService.isWalletTCsAccepted();
    this.checkFeeAmount = this.userService.getCheckFeeAmount();
  }

  ngOnInit() {
    // 3 Possible Flows for check fee pop-up
    // Flow #1 (currentTemplate == 1, #walletNoTCsWithCheckFeeTemplate)
    // - User has a wallet, has not accepted T&Cs yet, and is being a charged a check fee, and that to use ACH you must enroll in wallet.
    //   - We display pop-up informing them they will be charged a fee if they use a check payee
    //     They see two options: proceed with check + fee (go to DPAT) or enroll in wallet (session transfer to wallet) to use ACH payments

    // Flow #2 (currentTemplate == 2, #walletNoTCsNoCheckFeeTemplate)
    // - User has a wallet, has not accepted T&Cs yet, and is not being charged a check fee
    //   - We display pop-up informing them that if they proceed to DPAT, they will only see check payees (no mention of fee since they don't pay one)
    //     They see two options: proceed with using a check payee (go to DPAT) or enroll in wallet (session transfer to wallet) to use ACH payments

    // Flow #3 (currentTemplate == 3, #noWalletNoCheckFeeTemplate)
    // - User does not have a wallet, but is still being charged a check fee
    //   - We display pop-up informing them that when they proceed to DPAT, they'll see both check and ach payees, but that choosing check will incur the check fee upon payout
    //     They see one option: acknowledge that there will be a fee if they choose a check payee on the next step (go to DPAT)

    // For flow #1 and #2, if the user does not have billing permissions, we hide the enroll in wallet option button from them.
    // For flow #1 and #2, if they go to DPAT, they will only see check payees because they were onboarded onto the wallet already, just haven't accepted T&Cs. Business wants wallet users to use wallet for ACH
    // For flow #3, if they go to DPAT, they will see both check and ach payees on DPAT since they have no ability to enroll in the wallet yet.

    if (this.walletUser && !this.acceptedTCs && this.checkFeeAmount > 0) {
      this.currentTemplate = 1;
    } else if (this.walletUser && !this.acceptedTCs && this.checkFeeAmount == 0) {
      // this.currentTemplate = 2;
      this.currentTemplate = 1; // As of 7/5: Business asked us to use flow #1 text in the pop-up for this flow.
    } else if (!this.walletUser && this.checkFeeAmount > 0) {
      this.currentTemplate = 3;
    } else {
      this.dialogRef.close('error'); // Error handling just in case.
    }

    // this.hardcodeScenario(); // In case you wish to test all possible scenarios
  }

  hardcodeScenario() {
    // Flow #1a: Wallet, no T&Cs, is being charged a check fee, and is admin/account manager/billing manager
    // this.currentTemplate = 1;
    // this.canAccessDigitalWallet = true;

    // Flow #1b: Wallet, no T&Cs, is being charged a check fee, and is a claims filer or claims manager
    // this.currentTemplate = 1;
    // this.canAccessDigitalWallet = false;

    // Flow #2a: Wallet, no T&Cs, is NOT being charged a check fee, and is admin/account manager/billing manager
    // this.currentTemplate = 2;
    // this.canAccessDigitalWallet = true;

    // Flow #2b: Wallet, no T&Cs, is NOT being charged a check fee, and is a claims filer or claims manager
    // this.currentTemplate = 2;
    // this.canAccessDigitalWallet = false;

    // Flow #3: No wallet, is being charged a check fee, and is any role
    // this.currentTemplate = 3;

    // Error handling flow: None of the above 3 flows were true when the pop-up was opened, so just close the pop-up immediately.
    // this.dialogRef.close('error');
  }

  close() {
    this.taggingService.link({link_name: 'Close'});
    this.dialogRef.close();
  }

  continueWithCheck() {
    this.taggingService.link({link_name: 'Continue_With_Check'});
    if (this.currentTemplate == 1 || this.currentTemplate == 2) {
      // When we make the DPAT call, these users should only see check payees. When the getRedirect call is made,
      // we check sessionStorage for this value, and if it exists, we pass paymentMode: "check" to tell DPAT to hide ach/zelle payees
      sessionStorage.setItem('paymentMode', 'check');
    } // For template == 3, we do not want to have this restriction.

    this.dialogRef.close('continueToDPAT');
  }

  enrollInWallet() {
    this.taggingService.link({link_name: 'Enroll_In_Wallet'});
    this.goToWallet();
  }

  goToWallet() {
    this.sessionError = false;
    let request = {
      action: "accesswallet", //Hardcoded
      navigatingSystem: "onl", //Hardcoded
      navigatingSystemRole: "admin", //Hardcoded (assume admin)
      productSystem: "gw", //Hardcoded (not coming from cbp/dd)
      productSystemIdentifier1: this.policyDetails?.policyNumber,
      productSystemIdentifier1Name: "policyno",
      productSystemIdentifier2: this.policyDetails?.accountNumber,
      productSystemIdentifier2Name: "pcAccountNumber",
      productType: "iscomplete", //Hardcoded (unless we want to change later based on databricks policytype value)
      country: "us", //Harcoded (wallet is US only)
      locale: "en", //Hardcoded (wallet is US only)
      userIdentifier1Name: "createdbyuserid",
      userIdentifier1: this.userDetails?.userId?.toString(),
      displayName: this.userDetails?.contactName, //QUESTION: Use user's name or policy holder's name?
      userEmail: this.userDetails?.emailAddress, //QUESTION: Do we want to use user's info here or policy's email?
      objectUID: this.userDetails?.objectUID,
      callBackURL: "https://online.upscapital.com", //Hardcoded (we don't need this field, remove if possible)
      returnURL: "https://online.upscapital.com" //Hardcoded (we don't need this field, remove if possible)
    };
    this.billingService.DWstartSession(request).subscribe(
      data => {
        if (data?.data?.url) {
          this.dialogRef.close();
          window.open(data?.data?.url, '_blank');
        } else {
          this.sessionError = true;
        }
      }, error => {
        this.sessionError = true;
      }
    );
  }
}
