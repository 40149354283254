<div class="upsc-custom-claims-detail">

  <!-- Claim Detail -->
  <div class="container" [ngClass]="{'pirateContainer': flowType === 'pirateship'}">
    <div class="back" *ngIf="isTfcp">
      <a href="#" [routerLink]="['/tfcp/claims']" class="d-flex"
        (click)="persistSearch()"><mat-icon>arrow_left</mat-icon><span>{{ 'claimsDetail.back' | translate }}</span></a>
    </div>

    <div class="gray-drop p-4" *ngIf="flowType !== 'pirateship'">
      <div class="main-panel" id="targetMainPanel">
        <div class="mobile">
          <div class="title">
            <div class="left d-flex align-items-center">
              <div class="left-left">
                <div class="left-top">
                  <div class="bold">{{ 'claimsDetail.title' | translate }}&nbsp;</div>
                  <div class="text">{{ claim.claimNumber }}</div>
                </div>
                <div class="status-box" [ngClass]="
                    claim.customerDisplayStatus == 'Claim Received' ? 'blue' : 
                    claim.customerDisplayStatus && claim.customerDisplayStatus.includes('Closed') ? 'teal' : 
                    claim.customerDisplayStatus == 'Withdrawn' ? 'plum' : 
                    claim.customerDisplayStatus == 'Submitted for Payment' ? 'dark-blue' : 
                    claim.customerDisplayStatus == 'Incomplete Submission' ? 'orange' : 
                    claim.customerDisplayStatus == 'Investigation Pending' ? 'yellow' : 
                    claim.customerDisplayStatus == 'Documents Pending' ? 'red' : 
                    claim.customerDisplayStatus == 'Paid' ? 'olive' : 
                    claim.customerDisplayStatus == 'Denied' ? 'gray' : 
                    claim.customerDisplayStatus == 'Pending Delivery Confirmation' ? 'blue' :
                    claim.customerDisplayStatus == 'Search In Progress' ? 'blue' :
                    claim.customerDisplayStatus == 'Submitted' ? 'blue' :
                    'none'">
                  <div *ngIf="!statusDetails">{{ claim.customerDisplayStatus }}</div>
                  <div *ngIf="statusDetails">{{ statusDetails }}</div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="d-flex">
                <div class="icon-bg">
                  <img src="../../../../assets/images/download.svg" (click)="export('download')">
                </div>
                <div class="icon-bg second" (click)="export('print')">
                  <img alt="" src="../../../../../assets/images/printer.svg">
                </div>
              </div>
            </div>
          </div>
          <div class="left-right">
            <upsc-new-notification-box [isSuccess]="true" [marginBottom]="true" [marginTop]="true"
              *ngIf="resendSuccess">{{ 'claimsDetail.resendSuccess' | translate }}</upsc-new-notification-box>
            <upsc-new-notification-box [isDanger]="true" [marginBottom]="true" [marginTop]="true" *ngIf="resendError">{{
              'common.systemError' | translate }}</upsc-new-notification-box>
            <button class="upsc-button" *ngIf="isTfcp && !resendLoading" (click)="sendTFCPClaimSubmissionEmail()">{{
              'claimsDetail.resend' | translate }}<mat-icon>chevron_right</mat-icon></button>
            <button class="upsc-button continuing" *ngIf="isTfcp && resendLoading" role="button" type="button">
              <div>{{ 'claimsDetail.resend' | translate }}<mat-icon>chevron_right</mat-icon></div>
              <div class="spinner-border" role="status">
                <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
              </div>
            </button>
          </div>
          <div *ngIf="statusConfirm" class="notification-yellow">
            <mat-icon>info</mat-icon>
            <div class="notify-sub-title">Thank you for updating your claim. Your adjuster will review and evaluate the
              information provided.</div>
          </div>
          <div class="subtitle">
            <div class="bold">{{ 'claimsDetail.paidAmount' | translate }}&nbsp;</div>
            <div *ngIf="claim.currency=='usd'" class="text">${{ claim.totalPaymentAmount | number:'1.2-2' }}</div>
          </div>
        </div>
        <div class="desktop">
          <div class="title">
            <div class="left d-flex align-items-center">
              <div class="left-left">
                <div class="left-top">
                  <div class="bold">{{ 'claimsDetail.title' | translate }}&nbsp;</div>
                  <div class="text">{{ claim.claimNumber }}</div>
                </div>
                <div class="status-box" [ngClass]="
                      claim.customerDisplayStatus == 'Claim Received' ? 'blue' : 
                      claim.customerDisplayStatus && claim.customerDisplayStatus.includes('Closed') ? 'teal' : 
                      claim.customerDisplayStatus == 'Withdrawn' ? 'plum' : 
                      claim.customerDisplayStatus == 'Submitted for Payment' ? 'dark-blue' : 
                      claim.customerDisplayStatus == 'Incomplete Submission' ? 'orange' : 
                      claim.customerDisplayStatus == 'Investigation Pending' ? 'yellow' : 
                      claim.customerDisplayStatus == 'Documents Pending' ? 'red' : 
                      claim.customerDisplayStatus == 'Paid' ? 'olive' : 
                      claim.customerDisplayStatus == 'Denied' ? 'gray' : 
                      claim.customerDisplayStatus == 'Pending Delivery Confirmation' ? 'blue' : 
                      claim.customerDisplayStatus == 'Search In Progress' ? 'blue' :
                      'none'">
                  <div *ngIf="!statusDetails">{{ claim.customerDisplayStatus }}</div>
                  <div *ngIf="statusDetails">{{ statusDetails }}</div>
                </div>
              </div>
              <div class="left-right" [class.end]="!resendSuccess">
                <upsc-new-notification-box [isSuccess]="true" [marginBottom]="true" *ngIf="resendSuccess">{{
                  'claimsDetail.resendSuccess' | translate }}</upsc-new-notification-box>
                <upsc-new-notification-box [isDanger]="true" [marginBottom]="true" [marginTop]="true"
                  *ngIf="resendError">{{ 'common.systemError' | translate }}</upsc-new-notification-box>
                <button class="upsc-button" *ngIf="isTfcp && !resendLoading" (click)="sendTFCPClaimSubmissionEmail()">{{
                  'claimsDetail.resend' | translate }}<mat-icon>chevron_right</mat-icon></button>
                <button class="upsc-button continuing" *ngIf="isTfcp && resendLoading" role="button" type="button">
                  <div>{{ 'claimsDetail.resend' | translate }}<mat-icon>chevron_right</mat-icon></div>
                  <div class="spinner-border" role="status">
                    <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
                  </div>
                </button>
              </div>
            </div>
            <div class="right">
              <div class="d-flex">
                <div class="icon-bg">
                  <img src="../../../../assets/images/download.svg" (click)="export('download')">
                </div>
                <div class="icon-bg second" (click)="export('print')">
                  <img alt="" src="../../../../../assets/images/printer.svg">
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="statusConfirm" class="notification-yellow">
            <mat-icon>info</mat-icon>
            <div class="notify-sub-title">Thank you for updating your claim. Your adjuster will review and evaluate the
              information provided.</div>
          </div>
          <div class="subtitle d-flex">
            <div class="bold">{{ 'claimsDetail.paidAmount' | translate }}&nbsp;</div>
            <div *ngIf="claim.currency=='usd'" class="text">${{ claim.totalPaymentAmount | number:'1.2-2' }}</div>
          </div>
        </div>
        <br />
        <!-- <hr> -->
        <!-- <div class="title-alone">
              {{ 'claimsDetail.adjuster.title' | translate }}
            </div> -->
        <!-- <div class="adjuster">
              <div class="name">{{ claim.adjusterName }}</div>
              <div class="email"><a href="mailto:{{ claim.adjusterEmail }}?subject=[{{ insured }}] {{ 'claimsDetail.title' | translate }} {{ claim.claimNumber }}">{{ claim.adjusterEmail }}</a></div>
              <div class="phone">{{ claim.adjusterPhone | phoneNumber }}</div>
            </div> -->
        <div class="title-alone">
          {{ 'claimsDetail.d&n' | translate }}
        </div>
        <table *ngIf="sgDocuments.length > 0">
          <tr *ngFor="let doc of sgDocuments">
            <td class="date">{{ doc.dateModified | date: 'MM/dd/yyyy' }}</td>
            <td class="name" (click)="download(doc)" tabindex="0">{{ doc.name }}</td>
          </tr>
        </table>
        <table *ngIf="sgDocuments.length == 0" class="not-provided">
          <tr>
            <td class="date">{{ 'claimsDetail.not_p' | translate }}</td>
          </tr>
        </table>
        <div class="dn">
          <div class="dn-mobile" *ngFor="let doc of sgDocuments">
            <div class="date">{{ doc.dateModified | date: 'MM/dd/yyyy' }}</div>
            <div class="name">{{ doc.name }}</div>
          </div>
        </div>
      </div>




      <upsc-collapsible-panel [isCollapsed]="isMessageCollapsed"
        *ngIf="flowType == 'my-choice' || flowType == 'customized-claims-portal' || flowType == 'ajg' || flowType == 'inxpress' || flowType == 'upshealthcare' || flowType == 'freightcenterinc'"
        [title]="'claims.MessageList.MessageAdjuster' | translate">
        <div class="section">
          <hr>
          <div class="contents">
            <upsc-message-adjuster [customAuthor]="claim.fName" [claimData]="selectedClaimNumber"
              [canMessageAdjuster]="true"></upsc-message-adjuster>
          </div>
        </div>
      </upsc-collapsible-panel>

      <div id="targetEditable">
        <upsc-collapsible-panel [title]="'claims.edit.review.reviewAndSubmit.reviewList.shipmentDetail' | translate">
          <div class="section">
            <hr>
            <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
              <div class="column mr-md-2">
                <div class="subsection">
                  <div class="subsection-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.tupssTrackingNumber' | translate }}</div>
                  <div class="subsection-text text-break">{{ claim.tracking | uppercase }}</div>
                </div>
                <div class="subsection">
                  <div class="subsection-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.carrier' | translate }}</div>
                  <div class="subsection-text">{{ claim.carrier }}</div>
                </div>
                <div class="subsection" *ngIf="isTfcp && packedBy">
                  <div class="subsection-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.tupssCoverageType' | translate }}</div>
                  <div class="subsection-text">{{ coverageType }}</div>
                </div>
                <div class="subsection" *ngIf="isTfcp && packedBy">
                  <div class="subsection-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.tupssPackedBy' | translate }}</div>
                  <div class="subsection-text">{{ packedBy }}</div>
                </div>
              </div>
              <div class="column mr-md-2">
                <div class="subsection" *ngIf="isTfcp || isGuestUser">
                  <div class="subsection-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.shipperInformation' | translate }}</div>
                  <div class="subsection-text">
                    <div>{{ claim.shipperCompany }}</div>
                    <div>{{ claim.shipperCity | titlecase }}<span>,{{ claim.shipperState }}</span></div>
                    <div>{{ claim.shipperCountry }}</div>
                  </div>
                </div>
                <div class="subsection">
                  <div class="subsection-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.recipientInformation' | translate }}</div>
                  <div class="subsection-text">
                    <div>{{ claim.recipientCompany }}</div>
                    <div>{{ claim.recipientCity | titlecase }}<span>,{{ claim.recipientState }}</span></div>
                    <div>{{ claim.recipientCountry }}</div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="subsection">
                  <div class="subsection-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.tupssShipDate' | translate }}</div>
                  <div class="subsection-text">{{ claim.shipDate | date: 'MM/dd/yyyy': 'UTC' }}</div>
                </div>
                <div class="subsection" *ngIf="isGuestUser">
                  <div class="subsection-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.referenceNumber' | translate }}</div>
                  <div class="subsection-text" *ngIf="claim.reference">{{ claim.reference }}</div>
                </div>
                <div class="subsection" *ngIf="isGuestUser">
                  <div class="subsection-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.upsAccountNumber' | translate }}</div>
                  <div class="subsection-text">{{ claim.upsAccountNumber | uppercase }}</div>
                </div>
              </div>
            </div>
          </div>
        </upsc-collapsible-panel>

      </div>

      <div *ngIf="isPackageEditable && iShipDataReady">
        <upsc-collapsible-panel
          [title]="'claims.edit.review.reviewAndSubmit.reviewList.tupssTellUsWhatHappened' | translate">
          <div class="section">
            <hr>
            <div class="contents">
              <div class="subsection stand-alone mb-0">
                <upsc-damage-missing-parts (isClaimSubmit)="onClaimSubmit($event)"></upsc-damage-missing-parts>
              </div>
            </div>
          </div>
        </upsc-collapsible-panel>
      </div>
      <div *ngIf="isPackageEditableContinueSearch && iShipDataReady">
        <upsc-collapsible-panel
          [title]="'claims.edit.review.reviewAndSubmit.reviewList.tupssTellUsWhatHappened' | translate">
          <div class="section">
            <hr>
            <div class="contents">
              <div class="subsection stand-alone mb-0">
                <upsc-edit-continue-search-package
                  (isClaimSubmit)="onClaimSubmit($event)"></upsc-edit-continue-search-package>
              </div>
            </div>
          </div>
        </upsc-collapsible-panel>
      </div>

      <upsc-collapsible-panel *ngIf="!isPackageEditable"
        [title]="'claims.edit.review.reviewAndSubmit.reviewList.tupssTellUsWhatHappened' | translate">
        <div class="section">
          <hr>
          <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
            <div class="column mr-md-2">
              <div class="subsection">
                <div class="subsection-title">{{ 'claimsDetail.claimFiled' | translate }}</div>
                <div class="subsection-text">{{ claim.fileDate | date: 'MM/dd/yyyy': 'UTC' }}</div>
              </div>
              <div class="subsection">
                <div class="subsection-title">
                  {{ 'claims.edit.review.reviewAndSubmit.reviewList.reasonForClaim' | translate }}</div>
                <div class="subsection-text">{{ claim.reason }}</div>
              </div>
              <div class="subsection">
                <div class="subsection-title">
                  {{ 'claims.edit.review.reviewAndSubmit.reviewList.productCategory' | translate }}</div>
                <div class="subsection-text">{{ claim.productCategory }}</div>
              </div>
              <div class="subsection">
                <div class="subsection-title">
                  {{ 'claims.edit.review.reviewAndSubmit.reviewList.quantity' | translate }}</div>
                <div class="subsection-text">{{ claim.quantity }}</div>
              </div>
            </div>
            <div class="column mr-md-2">
              <div class="subsection">
                <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.filedClaimAmount' |
                  translate }}</div>
                <div *ngIf="currencyType=='usd'" class="subsection-text">${{ claim.claimedAmount }}</div>
              </div>
              <!-- <div class="subsection">
                    <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.checkAchNumber' | translate }}</div>
                    <div class="subsection-text">{{ claim.checkNumber }}</div>
                  </div>
                  <div class="subsection">
                    <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.checkElectronicIssueDate' | translate }}</div>
                    <div class="subsection-text" *ngIf="claim.checkIssueDate == 'null'">{{ 'claimsDetail.notProvided' | translate }}</div>
                    <div class="subsection-text" *ngIf="(claim.checkIssueDate !== 'null')">{{ claim.checkIssueDate | date: 'MM/dd/yyyy'  }}</div>
                  </div> -->
            </div>
            <div class="column">
              <div class="subsection" *ngIf="uuDocuments.length > 0">
                <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.uploadedFiles' |
                  translate }}</div>
                <div class="subsection-text document" *ngFor="let file of uuDocuments">
                  <div (click)="download(file)">{{ file.name }}</div>
                </div>
              </div>
              <div class="subsection" *ngIf="uuDocuments.length == 0">
                <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.uploadedFiles' |
                  translate }}</div>
                <div class="subsection-text">{{ 'claimsDetail.notProvided' | translate }}</div>
              </div>
            </div>
          </div>
          <hr class="mt-3 mb-2">
          <upsc-notification-box [isDanger]="true"
            *ngIf="claim?.idrdocList?.length > 0 && claim?.idrdocList[0] != '' && (claim?.customerDisplayStatus == 'Documents Pending' || claim?.closedOutCome == 'incomplete_documentation_Ext')">
            <div class="left mr-3">
              <svg id="warning" xmlns="http://www.w3.org/2000/svg" width="22.607" height="20" viewBox="0 0 22.607 20">
                <g id="Group_69" data-name="Group 69">
                  <g id="Group_68" data-name="Group 68">
                    <path id="Path_74" data-name="Path 74"
                      d="M22.391,47.132,12.682,30.315a1.591,1.591,0,0,0-2.757,0L.216,47.132a1.591,1.591,0,0,0,1.378,2.387H21.013a1.591,1.591,0,0,0,1.378-2.387Zm-1.149.928a.257.257,0,0,1-.23.133H1.594a.265.265,0,0,1-.23-.4l9.709-16.817a.265.265,0,0,1,.459,0l9.709,16.817A.257.257,0,0,1,21.242,48.06Z"
                      transform="translate(0 -29.519)" fill="#c61e1e" />
                    <rect id="Rectangle_168" data-name="Rectangle 168" width="1.178" height="6.285"
                      transform="translate(10.714 6.398)" fill="#c61e1e" />
                    <circle id="Ellipse_20" data-name="Ellipse 20" cx="0.786" cy="0.786" r="0.786"
                      transform="translate(10.518 14.586)" fill="#c61e1e" />
                  </g>
                </g>
              </svg>
            </div>
            <div class="right">
              <div *ngFor="let doc of claim?.idrdocList" class="mb-1">{{ 'claimsDetail.upload.alert' | translate }}<span
                  class="b">{{ doc }}</span></div>
            </div>
          </upsc-notification-box>
          <div class="upload">
            <div class="subsection stand-alone notes">
              <div class="subsection-title">{{ 'claimsDetail.upload.note' | translate }}</div>
              <div class="subsection-text">{{ 'claimsDetail.upload.note1' | translate }}</div>
              <div class="subsection-text">{{ 'claimsDetail.upload.note2' | translate }}</div>
              <div class="subsection-text">{{ 'claimsDetail.upload.note3' | translate }}</div>
              <!-- <div *ngIf="showFileProgress" class="progress-wrapper">
                      <div class="single d-flex" *ngFor="let file of listOfFiles">
                        <div class="left d-flex flex-column justify-content-around">
                          <div class="top d-flex justify-content-between">
                            <div class="title">{{ file.name }}</div>
                            <div class="percent" *ngIf="!file.processing && !file.error">{{ 'claimsDetail.upload.100' |
                              translate }}</div>
                            <div class="percent" *ngIf="!file.processing && file.error">{{ 'claimsDetail.upload.failed'
                              |
                              translate }}</div>
                          </div>
                          <div class="bot">
                            <mat-progress-bar mode="indeterminate" *ngIf="file.processing"></mat-progress-bar>
                            <mat-progress-bar mode="determinate" value="100" *ngIf="!file.processing && !file.error">
                            </mat-progress-bar>
                            <mat-progress-bar mode="determinate" value="0" *ngIf="!file.processing && file.error">
                            </mat-progress-bar>
                          </div>
                        </div>
                        <div class="right">
                          <mat-icon (click)="remove(file)" *ngIf="!file.processing && !file.error">delete</mat-icon>
                        </div>
                      </div>
                    </div> -->
              <!-- <div class="upload-box" (click)="fileInput.click()" upscDragNDrop
                      (onFileDropped)="uploadFile($event)">
                      <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
                      <div class="text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.005" height="22.926"
                          viewBox="0 0 28.005 22.926">
                          <g id="noun_Upload_3443635" transform="translate(-1.995 -4.074)">
                            <g id="_24-Upload" data-name="24-Upload">
                              <path id="Path_123" data-name="Path 123"
                                d="M30,14.81C30,18.4,27.9,21,25,21H22a1,1,0,0,1,0-2h3c2.06,0,3-2.17,3-4.19,0-2.31-1.57-4.19-3.5-4.19a3.11,3.11,0,0,0-1.2.25A1.11,1.11,0,0,1,23,11a.8.8,0,0,1-.74-.19.89.89,0,0,1-.25-.32,1,1,0,0,1-.11-.35h0a1,1,0,0,1,0-.34A3.31,3.31,0,0,0,22,9a3,3,0,0,0-6,0,2.81,2.81,0,0,0,.07.67,1,1,0,0,1-.74,1.2.81.81,0,0,1-.41,0,.92.92,0,0,1-.6-.35A3,3,0,0,0,9,12.35a.82.82,0,0,1,0,.26,1.07,1.07,0,0,1-.47.59,1,1,0,0,1-.41.12,1.1,1.1,0,0,1-.36,0l-.23-.1a2,2,0,0,0-1-.27A2.82,2.82,0,0,0,4,16a2.81,2.81,0,0,0,2.5,3H10a1,1,0,0,1,0,2H6a.94.94,0,0,1-.47-.12A5,5,0,0,1,2,16a4.8,4.8,0,0,1,4.5-5,3.54,3.54,0,0,1,.73.07,5,5,0,0,1,6.89-3A5,5,0,0,1,24,8.65h.51C27.53,8.62,30,11.4,30,14.81ZM16,24a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V25A1,1,0,0,0,16,24Zm4.69-6.88-4-3.84a1,1,0,0,0-1.38,0l-4,3.84a1,1,0,0,0,1.38,1.44L15,16.35V22a1,1,0,0,0,2,0V16.35l2.31,2.21a1,1,0,0,0,1.38-1.44Z"
                                fill="#64c5b4" />
                            </g>
                          </g>
                        </svg>
                        {{ 'claimsDetail.upload.uploadMessage' | translate }}
                        <span class="green">{{ 'claimsDetail.upload.uploadMessageGreen' | translate }}</span>
                        {{ 'claimsDetail.upload.uploadMessage2' | translate }}
                      </div>
                    </div> -->
              <div class="uploaded-documents d-flex justify-content-center align-items-center"
                (click)="fileInput.click()" upscDragNDrop (onFileDropped)="uploadFile($event)">
                <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <!-- <img class="pb-2" src="../../../../../assets/images/upload-file-icon.png"> -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="28.005" height="22.926" viewBox="0 0 28.005 22.926">
                    <g id="noun_Upload_3443635" transform="translate(-1.995 -4.074)">
                      <g id="_24-Upload" data-name="24-Upload">
                        <path id="Path_123" data-name="Path 123"
                          d="M30,14.81C30,18.4,27.9,21,25,21H22a1,1,0,0,1,0-2h3c2.06,0,3-2.17,3-4.19,0-2.31-1.57-4.19-3.5-4.19a3.11,3.11,0,0,0-1.2.25A1.11,1.11,0,0,1,23,11a.8.8,0,0,1-.74-.19.89.89,0,0,1-.25-.32,1,1,0,0,1-.11-.35h0a1,1,0,0,1,0-.34A3.31,3.31,0,0,0,22,9a3,3,0,0,0-6,0,2.81,2.81,0,0,0,.07.67,1,1,0,0,1-.74,1.2.81.81,0,0,1-.41,0,.92.92,0,0,1-.6-.35A3,3,0,0,0,9,12.35a.82.82,0,0,1,0,.26,1.07,1.07,0,0,1-.47.59,1,1,0,0,1-.41.12,1.1,1.1,0,0,1-.36,0l-.23-.1a2,2,0,0,0-1-.27A2.82,2.82,0,0,0,4,16a2.81,2.81,0,0,0,2.5,3H10a1,1,0,0,1,0,2H6a.94.94,0,0,1-.47-.12A5,5,0,0,1,2,16a4.8,4.8,0,0,1,4.5-5,3.54,3.54,0,0,1,.73.07,5,5,0,0,1,6.89-3A5,5,0,0,1,24,8.65h.51C27.53,8.62,30,11.4,30,14.81ZM16,24a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V25A1,1,0,0,0,16,24Zm4.69-6.88-4-3.84a1,1,0,0,0-1.38,0l-4,3.84a1,1,0,0,0,1.38,1.44L15,16.35V22a1,1,0,0,0,2,0V16.35l2.31,2.21a1,1,0,0,0,1.38-1.44Z"
                          fill="#64c5b4" />
                      </g>
                    </g>
                  </svg>
                  <div class="attachments-title pb-2">
                    <span class="upload-underline">{{ 'claimsDetail.upload.message1' | translate }}</span> {{
                    'claimsDetail.upload.message2' | translate }}
                  </div>
                  <div class="pb-2">
                    {{ 'claimsDetail.upload.filesAllowed' | translate }}
                  </div>
                </div>
              </div>
              <div class="uploaded-attachments">
                <ul class="your-attachments">
                  <li class="d-inline-flex justify-content-start align-items-center" *ngFor="let file of listOfFiles">
                    <div>{{file.name}}</div>
                    <mat-icon class="delete-icon ml-2" (click)="remove(file)">clear</mat-icon>
                  </li>
                </ul>
              </div>
            </div>
            <div class="error" *ngIf="fileNameError">{{ 'claimsDetail.upload.fileName' | translate }}</div>
            <div class="error" *ngIf="fileTypeError">{{ 'claimsDetail.upload.fileType' | translate }}</div>
            <div class="error" *ngIf="fileTooBig">{{ 'claimsDetail.upload.limit' | translate }}</div>
            <div class="error" *ngIf="systemError">{{ 'common.systemError' | translate }}</div>
          </div>
          <div class="additional-contents">
            <div class="subsection stand-alone">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.merchandiseDescription' | translate }}</div>
              <div class="subsection-text break">{{ claim.description }}</div>
            </div>
            <div class="subsection stand-alone mb-0">
              <div class="subsection-title">
                {{ 'claims.edit.review.reviewAndSubmit.reviewList.additionalComments' | translate }}</div>
              <div class="subsection-text break">{{ claim.customerNotes }}</div>
            </div>
          </div>
        </div>
      </upsc-collapsible-panel>

      <upsc-collapsible-panel
        [title]="'claims.edit.review.reviewAndSubmit.reviewList.claimPaymentReceipent' | translate"
        [edit]="canUpdatePayee" (updatePayee)="updateClaimPaymentRecipient()" *ngIf="claim.payeeDetails">
        <!-- <mat-panel-description *ngIf="claim.customerDisplayStatus == 'Claim Received' || claim.customerDisplayStatus == 'Submitted for Payment' ||
            claim.customerDisplayStatus == 'Incomplete Submission'">
              <span *ngIf="claim.customerDisplayStatus == 'Claim Received' || claim.customerDisplayStatus == 'Submitted for Payment' ||
              claim.customerDisplayStatus == 'Incomplete Submission'" class="edit" (click)="updateClaimPaymentRecipient()">edit
                <mat-icon>mode_edit</mat-icon>
              </span>
            </mat-panel-description>  -->
        <div class="section">
          <hr>
          <div class="contents">
            <div class="subsection stand-alone">
              <div class="subsection-text">{{ claim.payeeDetails.fullName }}
              </div>
              <div class="subsection-text text-break"><a href="mailto:{{ claim.payeeDetails.email }}">{{
                  claim.payeeDetails.email }}</a></div>
              <div class="subsection-text" *ngIf="claim.payeeDetails.phone">+ {{ claim.payeeDetails.phone | phoneNumber
                }}</div>
            </div>
            <div class="subsection stand-alone">
              <div class="subsection-text">{{ claim.payeeDetails.addressLine1 }} </div>
              <div class="subsection-text">{{ claim.payeeDetails.addressLine2 }}</div>
              <div class="subsection-text">{{ claim.payeeDetails.city }}, {{ claim.payeeDetails.state }} {{
                claim.payeeDetails.zip }}</div>
              <div class="subsection-text">{{ claim.payeeDetails.country }}</div>
            </div>
            <div class="subsection stand-alone mb-0">
              <div class="subsection-title" *ngIf="claim.ach?.startsWith('**')">{{ 'payee.table.achelectronic' |
                translate }}</div>
              <div class="subsection-title">{{ claim.ach }}</div>
            </div>
          </div>
        </div>
      </upsc-collapsible-panel>



      <div class="error" *ngIf="systemError2">{{ 'common.systemError' | translate }}</div>
      <upsc-collapsible-panel [title]="'claims.edit.review.reviewAndSubmit.reviewList.contactInfo' | translate">
        <div class="section">
          <hr>
          <div class="contents">
            <div class="subsection stand-alone mb-0">
              <div class="subsection-title">{{ 'claimsDetail.contactDetails' | translate }}</div>
              <div class="subsection-text">{{ claim.fName }} {{ claim.lName }}</div>
              <div class="subsection-text">{{ claim.phoneNumber | phoneNumber }}</div>
              <div class="subsection-text text-break">{{ claim.email }}</div>
            </div>
          </div>
        </div>
      </upsc-collapsible-panel>

      <div *ngIf="isConfirmationStatus">
        <upsc-collapsible-panel
          [title]="'claims.edit.review.reviewAndSubmit.reviewList.confirmShippingStatus' | translate">
          <div class="section">
            <hr>
            <div class="contents">
              <upsc-confirm-package-status
                (confirmationStatusSubmit)="onConfirmationSubmit($event)"></upsc-confirm-package-status>
            </div>
          </div>
        </upsc-collapsible-panel>
      </div>
      <div id='isConfirmationStatus' *ngIf="isTfcp && isConfirmationStatus">
        <hr>
      </div>
      <div *ngIf="deliveryScan">
        <upsc-collapsible-panel [title]="'claims.edit.review.reviewAndSubmit.reviewList.howWouldYouLike' | translate">
          <div class="section">
            <hr>
            <div class="contents">
              <upsc-search-my-package
                (confirmationStatusSubmit)=" onConfirmationSubmitContinueSearch($event)"></upsc-search-my-package>
            </div>
          </div>
        </upsc-collapsible-panel>
      </div>
      <div id='isConfirmationStatus22' *ngIf="isTfcp && deliveryScan">
        <hr>
      </div>
      <div id='targetConfirmationStatus' *ngIf="isConfirmationStatus">
        <hr>
      </div>
    </div>


    <div class="pirateShipHeader" *ngIf="flowType === 'pirateship'">
      <div class="title">
        <div class="text">{{ 'claims.edit.review.reviewAndSubmit.reviewList.claimDetails' | translate}}</div>
        <div class="bluebar"></div>
      </div>
      <img class="pirateImage" src="../../../../../assets/images/pirateShipStep5.png">
    </div>

    <div class="pirateDetailsWrapper" *ngIf="flowType === 'pirateship'">

      <div class="pirateShipmentDetails" *ngIf="flowType === 'pirateship'">
        <div class="wrapper">
          <div class="header">{{'claims.edit.review.reviewAndSubmit.reviewList.shipmentDetail' | translate}}</div>
        </div>
        <div class="content d-flex flex-row">
          <div class="bold">{{ 'myChoice.howToFileAClaim.pirateTracking2' | translate }}</div>
          <div class="text">{{ claim.tracking }}</div>
        </div>
        <div class="content d-flex flex-row">
          <div class="bold">{{ 'claimsDetail.title' | translate }}</div>
          <div class="text">{{ claim.claimNumber }}</div>
        </div>
        <div class="content d-flex flex-row">
          <div class="bold">{{ 'claims.edit.review.reviewAndSubmit.reviewList.pirateReason' | translate }}</div>
          <div class="text">{{ claim.reason }}</div>
        </div>
        <!-- <div class="wrapper">
          <div class="header">{{'claims.edit.review.reviewAndSubmit.reviewList.merchandiseDescription' | translate}}
          </div>
        </div> -->
        <!-- <div class="table">
          <div class="table-header">
            <div class="header-section">{{'billing.toolbar.pirateNumber' | translate}}</div>
            <div class="header-section">{{'billing.toolbar.billDate' | translate}}</div>
            <div class="header-section">{{'billing.toolbar.invoiceAmount' | translate}}</div>
            <div class="header-section">{{'billing.toolbar.amountDue' | translate}}</div>
          </div>
          <div class="table-row">
            <div class="row-section">{{ claim.claimNumber }}</div>
            <div class="row-section">Row 1, Col 2</div>
            <div class="row-section">Row 1, Col 3</div>
            <div class="row-section">Row 1, Col 4</div>
          </div>
        </div> -->
        <div class="d-flex flex-row" style="gap: 8px;">
          <div class="text-bold" style="font-weight: 700;">{{ 'billing.onetime.totalPirate' | translate }}</div>
          <div>${{ claim.claimedAmount | number:'1.2-2' }}</div>
        </div>
        <div class="wrapper">
          <div class="header">{{'claims.MessageList.MessageAdjuster' | translate}}</div>
        </div>
        <upsc-collapsible-panel [isCollapsed]="isMessageCollapsed" *ngIf="flowType == 'pirateship'"
          [title]="'claims.MessageList.MessageAdjuster' | translate" class="full-width-panel">
          <div class="section">
            <hr class="pirateMessageHeader">
            <div class="contents">
              <upsc-message-adjuster [customAuthor]="claim.fName" [claimData]="selectedClaimNumber"
                [canMessageAdjuster]="true"></upsc-message-adjuster>
            </div>
          </div>
        </upsc-collapsible-panel>

        <div class="content d-flex flex-column" style="gap: 16px;">
          <div class="header">{{ 'claims.edit.review.reviewAndSubmit.reviewList.additional' | translate }}</div>
          <div class="d-flex flex-column">
            <div class="text-bold" style="font-weight: 700;">{{
              'claims.edit.review.reviewAndSubmit.reviewList.contactInfo' | translate }}</div>
            <div>{{ claim.email }}</div>
          </div>
        </div>

        <div class="content d-flex flex-column" style="gap: 16px;">
          <div class="header" style="font-style: italic;">{{ 'claims.edit.review.reviewAndSubmit.reviewList.upload' |
            translate }}</div>
          <div class="d-flex flex-column">
            <div class="text">{{ 'claims.edit.whatHappened.documents.pirateSubtitle' | translate }}</div>
            <div class="text">{{ 'claims.edit.whatHappened.documents.pirateSubtitle2' | translate }}</div>
          </div>
        </div>

        <div class="upload-wrapper d-flex flex-row">
          <ul class="document-list d-flex flex-column" style="gap: 16px;">
            <li>Receipt, Invoice, or Order Confirmation</li>
            <li>Proof of Payment</li>
            <li>Proof of Value</li>
            <li>Proof of Loss</li>
            <li>Carrier Notification</li>
          </ul>
          <div class="uploaded-documents d-flex justify-content-center align-items-center" (click)="fileInput.click()"
            upscDragNDrop (onFileDropped)="uploadFile($event)">
            <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <!-- <img class="pb-2" src="../../../../../assets/images/upload-file-icon.png"> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="28.005" height="22.926" viewBox="0 0 28.005 22.926">
                <g id="noun_Upload_3443635" transform="translate(-1.995 -4.074)">
                  <g id="_24-Upload" data-name="24-Upload">
                    <path id="Path_123" data-name="Path 123"
                      d="M30,14.81C30,18.4,27.9,21,25,21H22a1,1,0,0,1,0-2h3c2.06,0,3-2.17,3-4.19,0-2.31-1.57-4.19-3.5-4.19a3.11,3.11,0,0,0-1.2.25A1.11,1.11,0,0,1,23,11a.8.8,0,0,1-.74-.19.89.89,0,0,1-.25-.32,1,1,0,0,1-.11-.35h0a1,1,0,0,1,0-.34A3.31,3.31,0,0,0,22,9a3,3,0,0,0-6,0,2.81,2.81,0,0,0,.07.67,1,1,0,0,1-.74,1.2.81.81,0,0,1-.41,0,.92.92,0,0,1-.6-.35A3,3,0,0,0,9,12.35a.82.82,0,0,1,0,.26,1.07,1.07,0,0,1-.47.59,1,1,0,0,1-.41.12,1.1,1.1,0,0,1-.36,0l-.23-.1a2,2,0,0,0-1-.27A2.82,2.82,0,0,0,4,16a2.81,2.81,0,0,0,2.5,3H10a1,1,0,0,1,0,2H6a.94.94,0,0,1-.47-.12A5,5,0,0,1,2,16a4.8,4.8,0,0,1,4.5-5,3.54,3.54,0,0,1,.73.07,5,5,0,0,1,6.89-3A5,5,0,0,1,24,8.65h.51C27.53,8.62,30,11.4,30,14.81ZM16,24a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V25A1,1,0,0,0,16,24Zm4.69-6.88-4-3.84a1,1,0,0,0-1.38,0l-4,3.84a1,1,0,0,0,1.38,1.44L15,16.35V22a1,1,0,0,0,2,0V16.35l2.31,2.21a1,1,0,0,0,1.38-1.44Z"
                      fill="#64c5b4" />
                  </g>
                </g>
              </svg>
              <div class="attachments-title pb-2">
                <span class="upload-underline">{{ 'claimsDetail.upload.message1' | translate }}</span> {{
                'claimsDetail.upload.message2' | translate }}
              </div>
              <div class="pb-2">
                {{ 'claimsDetail.upload.pirateFiles' | translate }}
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>



  </div>
</div>