<div class="upsc-custom-coi-zip">
<div class="content p-3" [class.height-change]="codeSent">
  <a href="https://upscapital.com/" target="_blank" rel="noopener noreferrer">
    <img alt="UPS Capital Logo" src="../../../assets/logos/ca_us/InsureShield_Logo_226x79_larger_tagline-01.png">
  </a>
  <upsc-notification-box class="messages" *ngIf="coiNotFound || codeNotMatched || systemError" [isDanger]="true">
    <div class="d-flex">
      <div class="icon mr-3 text-danger"><mat-icon>cancel_outline</mat-icon></div>
      <div class="text">
        <div class="d-flex flex-column" *ngIf="coiNotFound">
          <span>{{ 'coiPolicy.messages.coiNotFound' | translate }}</span>
          <span class="spacer">{{ 'coiPolicy.messages.tryAgain' | translate }}</span>
        </div>
        <div class="d-flex flex-column" *ngIf="codeNotMatched">
          <span>{{ 'coiPolicy.messages.codeNotMatched' | translate }}</span>
          <span class="spacer">{{ 'coiPolicy.messages.tryAgain' | translate }}</span>
        </div>
        <div *ngIf="systemError">
          <span>{{ 'common.systemError' | translate }}</span>
        </div>
      </div>
    </div>
  </upsc-notification-box>
  <upsc-notification-box class="messages" *ngIf="codeSent && !codeNotMatched" [isSuccess]="true">
    <div class="d-flex">
      <div class="icon mr-3 text-success"><mat-icon>check_circle_outline</mat-icon></div>
      <div class="text">
        <div class="d-flex flex-column" *ngIf="codeSent">
          <span>{{ 'coiPolicy.messages.codeSent' | translate }}</span>
          <span class="spacer">{{ 'coiPolicy.messages.delayedEmail' | translate }}</span>
        </div>
      </div>
    </div>
  </upsc-notification-box>
  <h1 class="title">{{ 'coiPolicy.getStarted' | translate }}</h1>
  <form [formGroup]="accountFormGroup" [class.height-change]="codeSent">
    <mat-form-field ngbTooltip="{{ 'coiPolicy.tooltip.COITip' | translate }}" tooltipClass="ngb-tooltip-class">
      <input autocomplete="off" matInput required formControlName="policyNumberFormControl" placeholder="{{ 'coiPolicy.form.COINumber' | translate }}">
      <mat-error *ngIf="accountFormGroup.controls['policyNumberFormControl'].errors">
        <div *ngIf="accountFormGroup.get('policyNumberFormControl').errors?.required">
          {{ 'coiPolicy.form.COIRequired' | translate }}
        </div>
        <div *ngIf="accountFormGroup.controls['policyNumberFormControl'].errors?.pattern">
          {{ 'coiPolicy.form.validCOI' | translate }}
        </div>
      </mat-error>
    </mat-form-field>
    <mat-form-field ngbTooltip="{{ 'coiPolicy.tooltip.zipTip' | translate }}" tooltipClass="ngb-tooltip-class">
      <input autocomplete="off" matInput required formControlName="zipFormControl" placeholder="{{ 'common.zip' | translate }}" maxlength="10">
        <mat-error *ngIf="accountFormGroup.controls['zipFormControl'].errors">
          <div *ngIf="accountFormGroup.controls['zipFormControl'].errors?.required">
            {{ 'coiPolicy.form.zipRequired' | translate }}
          </div>
          <div *ngIf="accountFormGroup.controls['zipFormControl'].errors?.pattern">
            {{ 'common.validZip' | translate }}
          </div>
        </mat-error>
    </mat-form-field>
    <mat-form-field ngbTooltip="{{ 'coiPolicy.tooltip.emailTip' | translate }}" tooltipClass="ngb-tooltip-class">
      <input matInput required formControlName="emailFormControl" placeholder="{{ 'coiPolicy.form.email' | translate }}">
      <mat-error *ngIf="accountFormGroup.controls['emailFormControl'].errors">
        <div *ngIf="accountFormGroup.controls['emailFormControl'].errors?.required">
          {{ 'coiPolicy.form.emailRequired' | translate }}
        </div>
        <div
          *ngIf="accountFormGroup.controls['emailFormControl'].errors?.pattern">
          {{ 'common.validEmail' | translate }}
        </div>
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="codeSent">
      <input matInput required formControlName="codeFormControl" placeholder="{{ 'coiPolicy.form.verificationCode' | translate }}">
      <mat-error *ngIf="accountFormGroup.controls['codeFormControl'].errors">
        <div *ngIf="accountFormGroup.controls['codeFormControl'].errors?.required">
          {{ 'coiPolicy.form.verificationCodeRequired' | translate }}
        </div>
        <div
          *ngIf="accountFormGroup.controls['codeFormControl'].errors?.pattern">
          {{ 'coiPolicy.form.validVerificationCode' | translate }}
        </div>
      </mat-error>
    </mat-form-field>
    <div *ngIf="codeSent" class="tech-agreement-wrapper">
      <mat-checkbox class="green-background-checkbox agreement-layout" formControlName="techAgreement" ></mat-checkbox>
      <span class="tech-agreement">{{ 'createAccount.review.form.checkbox' | translate }}
        <a href="{{ privacyLink }}" target="_blank" rel="noopener noreferrer">{{ 'createAccount.review.form.checkboxLink' | translate }}</a>
        {{ 'createAccount.review.form.checkbox1.5' | translate }}
        <a [routerLink]="['/technology-agreement']" target="_blank">{{ 'createAccount.review.form.checkboxLink2' | translate }}</a>
        {{ 'createAccount.review.form.checkbox2' | translate }}</span>&nbsp;<span class="text-danger">*</span>
      <mat-error class="standalone" *ngIf="accountFormGroup.controls['techAgreement'].errors?.required && accountFormGroup.controls['techAgreement'].touched==true">
          {{ 'claims.edit.techAgreementRequired' | translate }}
      </mat-error>
    </div>
    <button  *ngIf="!codeSent" class="upsc-button" type="button" 
      [disabled]="apiInProgress || !readyForVerify" (click)="searchPolicyNumber($event)">
      {{ 'common.continue' | translate }}
    </button>
    <div class="d-flex" *ngIf="codeSent">
      <button class="upsc-button verify" type="button" 
        [disabled]="apiInProgress || accountFormGroup.controls.codeFormControl.invalid || accountFormGroup.controls.techAgreement.value!==true" (click)="checkVerificationCode($event)">
        {{ 'coiPolicy.form.verify' | translate }}
      </button>
      <button class="resend" type="button" [disabled]="apiInProgress" (click)="resendCode($event)">
        {{ 'coiPolicy.form.resendCode' | translate }}
      </button>
    </div>
  </form>
</div>
</div>