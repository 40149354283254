import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { PolicyService } from './../../../../services/policy.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingService } from 'src/app/services/billing.service';
import { ClaimService } from 'src/app/services/claim.service';
import { UserService } from 'src/app/services/user.service';
import { environment as ENV } from 'src/environments/environment'
import { emptyPolicyData } from '../models/policy.interface';
import { IPolicy } from '../models/policy.interface';
import { ViewportScroller } from '@angular/common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { TermsOfAgreementComponent } from '../terms-of-agreement/terms-of-agreement.component';
import * as _ from 'lodash';
import { iif, of } from 'rxjs';
import { TaggingService } from 'src/app/tags/tagging.service';
import { emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'upsc-payment-landing',
  templateUrl: './payment-landing.component.html',
  styleUrls: ['./payment-landing.component.scss']
})
export class PaymentLandingComponent implements OnInit {

  protectPackageFormGroup: UntypedFormGroup;
  mcData: any;
  showSpinner: boolean = true;
  systemError: boolean = false;
  myPolicy: IPolicy = emptyPolicyData;
  emailAddress: string;
  isFormValid: boolean = true;
  noPackageEligible: boolean = false;
  coverageFound = false;
  confirmationNumber = '';
  isTermsChecked: boolean = false;
  showEmailVerification = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private policyService: PolicyService,
    private billingService: BillingService,
    private claimService: ClaimService,
    private taggingService: TaggingService,
    private accountService: AccountService,
    private scroller: ViewportScroller,
    public dialog: MatDialog
  ) {
    this.protectPackageFormGroup = this.formBuilder.group({
      cbTermsOfServices: ['', [(control) => {
        return !control.value ? { 'required': true } : null;
      }]]
    });
  }

  showCheckedTermsOfServices(cbEvent: any) {
   this.termsOfAgreement(cbEvent);
  }

  dialogRef: any;
  termsOfAgreement(cbEvent: any) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '100%';
    config.data = {};
    this.dialogRef = this.dialog
      .open(TermsOfAgreementComponent, config)
      .afterClosed().subscribe(result => {
        if (result === "Accept") {
          this.isFormValid = true;
          this.isTermsChecked = true;          
          this.protectPackageFormGroup.get("cbTermsOfServices").setValue(true);
        }
        else{
          this.isFormValid = false;
          this.isTermsChecked = false;
          this.protectPackageFormGroup.get("cbTermsOfServices").setValue(false);
        }        
      });
  }

  ngOnInit(): void {
    //hard coded data to be removed after the API call works
    //sessionStorage.setItem('enrollmentInfo', JSON.stringify(enrollmentInfo));
    //temp track3data data
    //sessionStorage.setItem('track3data', JSON.stringify(track3data));

    this.route.queryParams.subscribe(
      params => {
        if (params.eqp) {
          this.taggingService.load('upsc_my_choice_landing_from_email');

          let eqp = params.eqp.replaceAll(' ', '+');
          // sessionStorage.clear();
          localStorage.setItem('eqp', eqp);
          this.userService.decryptParams({
            token: eqp
          }).subscribe(
            data => {
              if (data.trackingNumber && data.token && data.email) {
                sessionStorage.setItem('paramInfo', JSON.stringify(data));
                this.emailAddress = data.email;
                this.policyService.checkCoverage(data.trackingNumber).subscribe(
                  res => {
                    if(res.message == 'CoverageFound') {
                      this.showSpinner = false;
                      this.confirmationNumber = res.policyNumber;
                      this.coverageFound = true;
                    }
                    else {
                      iif(() => !!sessionStorage.getItem('access_token'), of(1), this.authService.refreshToken()).pipe(
                        switchMap(() => this.claimService.getShipmentDetailsAutofill(data.trackingNumber, 'my-choice'))
                        ).subscribe(
                          data => {
                            this.showSpinner = false;
                            sessionStorage.setItem('track3data', JSON.stringify(data));
                            if (data?.shipmentPackage?.deliveryDate !== null) {
                              this.noPackageEligible = true;
                            }
                            else {
                              //window.location.href = `${ENV.myChoice.oAuthUrl}?client_id=${ENV.myChoice.clientId}&redirect_uri=${ENV.baseUrl.web}${ENV.myChoice.redirectUri}&response_type=code&scope=read&state=isop`;
                            }
                          },
                          error => {
                            this.showSpinner = false;
                            this.systemError = true;
                          }
                        );
                    }
                  },
                  error => {
                      this.showSpinner = false;
                      this.systemError = true;
                  }
                );
              }
            },
            error => {
              this.showSpinner = false;
              this.systemError = true;
            }
          );
        }
        /* else if (params.code) {
          this.taggingService.load('upsc_my_choice_landing_after_signedin');
          
          this.showSpinner = true;
          if (!sessionStorage.getItem('tokenInfo') || !sessionStorage.getItem('enrollmentInfo') || !sessionStorage.getItem('track3data')) {
            this.userService.getTokens({
              authCode: params.code
            }).subscribe(
              data => {
                sessionStorage.setItem('tokenInfo', JSON.stringify(data));
                let paramInfo = JSON.parse(sessionStorage.getItem('paramInfo'));
                this.userService.getMCInfo({
                  addressToken: paramInfo.token,
                  accessToken: data.access_token
                }).subscribe(
                  data => {
                    this.showSpinner = false;
                    this.mcData = data;
                    sessionStorage.setItem('enrollmentInfo', JSON.stringify(data));
                  },
                  error => {
                    this.showSpinner = false;
                    this.systemError = true;
                  }
                );
              },
              error => {
                this.showSpinner = false;
                this.systemError = true;
              }
            );
          }
          else {
            this.showSpinner = false;
          }
        } */
        else { //direct URL or coming back from payment page
          if (!sessionStorage.getItem('paramInfo') //|| !sessionStorage.getItem('tokenInfo') || !sessionStorage.getItem('enrollmentInfo')
            || !sessionStorage.getItem('track3data')) {
            // this.showSpinner = false;
            this.systemError = true; //show error message to direct URL
          }
          else {
            this.emailAddress = JSON.parse(sessionStorage.getItem('paramInfo')).email;
          }
          this.showSpinner = false;
          /* else {
            this.showSpinner = true;
            if (!sessionStorage.getItem('policyData')) {
              this.myPolicy = emptyPolicyData;
              this.setPolicyData();
             
              this.policyService.createPolicy(this.myPolicy).subscribe(
                data => {
                  if (data.isSuccess) {
                    sessionStorage.setItem("policyData", JSON.stringify(data));
                    this.showSpinner = false;
                    this.proceedToPayment();
                  }
                },
                error => {
                  this.showSpinner = false;
                  this.systemError = true;
                }
              );
            }
            else {
              this.showSpinner = false;
              this.showEmailVerification = false;
              // this.proceedToPayment();
            } 
          } */
        }
      }
    );
  }

  public sendVerificationCode(emailId?: any) {
    if(JSON.parse(sessionStorage.getItem('verifyData'))?.code){
      
      this.showEmailVerification = true;
    }
    else {
      this.showSpinner = true;
      let emailModel = {
        ...emptyVerificationInterface,
        data: {
          emailAddress: emailId,
        }
      }

      this.accountService.createVerificationCode(emailModel).subscribe(
        data => {
          this.showSpinner = false;
          if (data.responseMessage) {
            this.showEmailVerification = true;
          }
          else {
            this.systemError = true;
          }
        }, error => {
          this.showSpinner = false;
          this.systemError = true;
        }
      );
    }
  }

  setPolicyData() {
    // let enrollmentInfo = JSON.parse(sessionStorage.getItem('enrollmentInfo'));
    //let contactName = enrollmentInfo.enrollmentInformation.primaryName.firstName + ' ' + enrollmentInfo.enrollmentInformation.primaryName.lastName;
    let contactName = '';

    let track3Info = JSON.parse(sessionStorage.getItem('track3data'));
    let shipperAddress = track3Info.shipperConsigneeAddress.find(a => a.code == '01');
    let shipToAddress = track3Info.shipperConsigneeAddress.find(a => a.code == '02');
    let dateOfShipment = new Date(_.toNumber(track3Info.shipDate.substr(0, 4)), _.toNumber(track3Info.shipDate.substr(4, 2)) - 1, _.toNumber(track3Info.shipDate.substr(6, 2))).toISOString().split('T')[0];

    let email = '';
    let trackingNumber = '';
    let paramInfo = sessionStorage.getItem('paramInfo') !== null ? JSON.parse(sessionStorage.getItem('paramInfo')) : null;
    if (paramInfo !== null) {
      email = paramInfo.email;
      trackingNumber = paramInfo.trackingNumber;
    }


    this.myPolicy.account.accountHolderContact.address.addressLine1 = shipToAddress.addressLine;
    this.myPolicy.account.accountHolderContact.address.addressLine2 = shipToAddress.addressLine2;
    this.myPolicy.account.accountHolderContact.address.city = shipToAddress.city;
    this.myPolicy.account.accountHolderContact.address.state.code = shipToAddress.stateProvinceCode;
    this.myPolicy.account.accountHolderContact.address.postalCode = shipToAddress.postalCode;
    this.myPolicy.account.accountHolderContact.address.country.code = shipToAddress.countryCode;
    this.myPolicy.account.accountHolderContact.emailAddress1 = email;
    this.myPolicy.account.accountHolderContact.name = `UPS My Choice - ${paramInfo.token}`;
    this.myPolicy.account.accountHolderContact.workPhone = '999-999-9999';
    this.myPolicy.account.contactPerson = contactName;

    this.myPolicy.account.policy.informationDeclaration = true;
    // this.myPolicy.account.policy.masterCustomerID = enrollmentInfo.enrollmentInformation.enrollmentNumber;
    this.myPolicy.account.policy.masterCustomerID = paramInfo.token;
    this.myPolicy.account.policy.merchantOrderID = ''; //MC + 4 digits sequence number - polulated by BL
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].originAddress.addressLine1 = shipperAddress.addressLine;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].originAddress.addressLine2 = shipperAddress.addressLine2;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].originAddress.city = shipperAddress.city;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].originAddress.postalCode = shipperAddress.postalCode;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].originAddress.state.code = shipperAddress.stateProvinceCode;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].originAddress.country.code = shipperAddress.countryCode;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].originCompany = shipperAddress.companyName;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].premium = 0.99;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].shipmentMode.code = 'Ground';
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].specialConditions = '';
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].transportationCarrier.code = 'ups';
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].typeOfShipment.code = 'Small Package';
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].valueOfShipment = 0;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].dateOfShipment = dateOfShipment;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].destinationAddress.addressLine1 = shipToAddress.addressLine;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].destinationAddress.addressLine2 = shipToAddress.addressLine2;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].destinationAddress.city = shipToAddress.city;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].destinationAddress.postalCode = shipToAddress.postalCode;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].destinationAddress.state.code = shipToAddress.stateProvinceCode;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].destinationAddress.country.code = shipToAddress.countryCode;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].destinationCompany = shipToAddress.companyName;
    this.myPolicy.account.policy.onDemand.oneTimeShipments[0].goodsBeingShipped = '';
    this.myPolicy.account.policy.onlineQuoteRequestUID = ''; //policy number - populated by BL
    this.myPolicy.account.policy.paymentAmount = 0.99;
    this.myPolicy.account.policy.policyNumber = ''; //policy number - populated by BL
    this.myPolicy.account.policy.premium = 0.99;
    this.myPolicy.account.policy.recipientName = shipToAddress.companyName;
    this.myPolicy.account.policy.requestedPaymentPlan = 'Annual Installments';
    this.myPolicy.account.policy.roleAssignments = [{ assignedRole: 'Underwriter', user: { userName: 'BQN9JLS', employeeNumber: '4448836' } }]
    this.myPolicy.account.policy.tax = 0;
    this.myPolicy.account.policy.technologyAgreement = true;
    this.myPolicy.account.policy.termType.code = 'Annual';
    this.myPolicy.account.policy.usedAgreement = false;
    this.myPolicy.account.policy.uwcompany = '';
    this.myPolicy.account.producerCode = 'MyChoice';
    this.myPolicy.account.uid = ''; //UID - populated by congratulations

    this.myPolicy.emailRequest.applicationName = ''; //by congratulations
    this.myPolicy.emailRequest.emailFor = ''; //by congratulations
    this.myPolicy.emailRequest.environment = ENV.baseUrl.urlType;
    this.myPolicy.emailRequest.data.coiNumber = ''; // 4 digits sequence number - populated by BL
    this.myPolicy.emailRequest.data.commodity = 'General Merchandise';
    this.myPolicy.emailRequest.data.companyName = 'UPS My Choice';
    this.myPolicy.emailRequest.data.contactInfo.contactName = contactName;
    this.myPolicy.emailRequest.data.contactInfo.email = email;
    this.myPolicy.emailRequest.data.contactInfo.phoneNumber = '999-999-9999';
    this.myPolicy.emailRequest.data.deductible = '0';
    this.myPolicy.emailRequest.data.insuredAmt = '500';
    this.myPolicy.emailRequest.data.insuredAmtInWord = 'Five Hundred 00 / 100';
    this.myPolicy.emailRequest.data.merchandiseDesc = trackingNumber;
    this.myPolicy.emailRequest.data.policyNumber = '', //policy number - populated by BL
    this.myPolicy.emailRequest.data.shipDate = dateOfShipment;
    this.myPolicy.emailRequest.data.shipToAddress.addressLine1 = shipToAddress.addressLine;
    this.myPolicy.emailRequest.data.shipToAddress.addressLine2 = shipToAddress.addressLine2;
    this.myPolicy.emailRequest.data.shipToAddress.city = shipToAddress.city;
    this.myPolicy.emailRequest.data.shipToAddress.country = shipToAddress.countryCode;
    this.myPolicy.emailRequest.data.shipToAddress.state = shipToAddress.stateProvinceCode;
    this.myPolicy.emailRequest.data.shipToAddress.zipCode = shipToAddress.postalCode;
    this.myPolicy.emailRequest.data.shipmentMode = 'UPS Ground'; //to be confirmed
    this.myPolicy.emailRequest.data.shipmentType = 'Small Package'; //to be confirmed
    this.myPolicy.emailRequest.data.shipperAddress.addressLine1 = shipperAddress.addressLine;
    this.myPolicy.emailRequest.data.shipperAddress.addressLine2 = shipperAddress.addressLine2;
    this.myPolicy.emailRequest.data.shipperAddress.city = shipperAddress.city;
    this.myPolicy.emailRequest.data.shipperAddress.country = shipperAddress.countryCode;
    this.myPolicy.emailRequest.data.shipperAddress.state = shipperAddress.stateProvinceCode;
    this.myPolicy.emailRequest.data.shipperAddress.zipCode = shipperAddress.postalCode;
    this.myPolicy.emailRequest.data.totalPremium = '0.99';
    this.myPolicy.emailRequest.data.transactionDate = new Date().toISOString().split('T')[0]; //YYYY-MM-DD
    this.myPolicy.emailRequest.data.uID = ''; //UID - populated by congratulations
    this.myPolicy.validationStatus = 'Validated';
  }

  onNavigate() {
    console.log("Navigate:");
    window.open("https://www.insureshield.com/content/terms-and-conditions/my-choice.pdf", "_blank");

  }

  continue(evt) {
    if (this.protectPackageFormGroup.valid) {
      this.taggingService.link({
        link_name: 'upsc_my_choice_protect_package',
        link_page_name: 'upsc_my_choice_landing_after_signedin'
      });

      /* this.isFormValid = true;
      let policyData: any;
      let policyNumber = '';

      // policy data should exist when coming back to the page from the cancel button on the payment page
      if (sessionStorage.getItem('policyData') === null) {
        // policy Data Binding
        this.myPolicy = emptyPolicyData;
        this.setPolicyData();
      }
      else {
        policyData = JSON.parse(sessionStorage.getItem('policyData'));
        policyNumber = policyData.policyNumber;
      } */

      // this.sendVerificationCode(data.email);
      this.sendVerificationCode(this.emailAddress);

      /* this.showSpinner = true;
      this.scroller.scrollToAnchor("targetSpinner");
      if (policyNumber == '') { //policy has not been created yet
        this.policyService.createPolicy(this.myPolicy).subscribe(
          data => {
            if (data.isSuccess) {
              sessionStorage.setItem("policyData", JSON.stringify(data));
              this.proceedToPayment();
            }
          },
          error => {
            this.showSpinner = false;
            this.systemError = true;
          }
        );
      }
      else { //policy has been previously created
        //this.showSpinner = false;
        this.proceedToPayment();
      } */
    }
    else {
      // error
      this.isFormValid = false;
    }
  }

  afterVerification(event) {
      this.showSpinner = true;
      // this.showEmailVerification = false;
      if (!sessionStorage.getItem('policyData')) {
        this.myPolicy = emptyPolicyData;
        this.setPolicyData();
       
        this.policyService.createPolicy(this.myPolicy).subscribe(
          data => {
            if (data.isSuccess) {
              sessionStorage.setItem("policyData", JSON.stringify(data));
              // this.showSpinner = false;
              this.proceedToPayment();
            }
          },
          error => {
            this.showEmailVerification = false;
            this.showSpinner = false;
            this.systemError = true;
          }
        );
      }
      else {
        // this.showSpinner = false;
        this.proceedToPayment();
      } 
  }

  cancelVevify(event: any) {
    if(event == 'yes') {
      this.showEmailVerification = false;
    }
  }



  proceedToPayment() {
    let policy = JSON.parse(sessionStorage.getItem('policyData')).policyRequest;
    let request = {
      orderId: policy.account.policy.merchantOrderID,
      orderDescription: 'housePolicy',
      totalAmount: '0.99',
      returnUrl: `${ENV.baseUrl.web}ups-my-choice/congratulations`,
      contentTemplateUrl: ENV.hpp.contentTemplateUrl,
      cssUrl: `${ENV.baseUrl.web}assets/hpp/styles_mc.css`,
      cancelUrl: `${ENV.baseUrl.web}ups-my-choice`,
      maxUserRetries: '5',
      transType: 'auth_capture',
      paymentType: 'Credit_Card',
      sessId: policy.account.policy.merchantOrderID,
      allowedTypes: 'Visa|MasterCard|American Express',
      paymentProcessIndicator: 'mychoice',
      countryCode: 'US',
      locale: 'en_US'
    }

    this.billingService.DPAT_GET_RTP_HOSTED_PAGE(request).subscribe(
      data => {
        if (data && data.data && data.code == 200 && data.data.redirectURL) {
          window.location.href = data.data.redirectURL;
        } else {
          this.showSpinner = false;
          this.systemError = true;
        }
      }, error => {
        this.showSpinner = false;
        this.systemError = true;
      }
    );
  }
}

const track3data = {
  "shipperConsigneeAddress": [
    {
      "code": "01",
      "description": "Shipper Address - temp",
      "companyName": "D4 MEDIA CORP.",
      "addressLine": "4865 LONGLEY LN",
      "addressLine2": null,
      "city": "RENO",
      "stateProvinceCode": "NV",
      "countryCode": "US",
      "postalCode": "895027936"
    },
    {
      "code": "02",
      "description": "ShipTo Address",
      "companyName": "DEFENSIVE SH",
      "addressLine": "4059 IRONWOOD DR",
      "addressLine2": null,
      "city": "EL DORADO HILLS",
      "stateProvinceCode": "CA",
      "countryCode": "US",
      "postalCode": "95762"
    }
  ],
  "shipperNumber": "17R1Y9",
  "companyName": "D4 MEDIA CORP.",
  "attentionName": null,
  "serviceCode": {
    "code": "003",
    "description": "UPS Ground"
  },
  "value": null,
  "deliveryDetails": {
    "code": null,
    "description": null,
    "date": "20230715",
    "time": null
  },
  "declaredValue": null,
  "shipmentPackage": {
    "shipmentIdentificationNumber": "1Z17R1Y9A849633247",
    "currentStatusDescription": "Delivered",
    "serviceOptionTypeDescription": "Adult Signature Required",
    "deliveryDate": "20230715",
    "activity": [
      {
        "activityLocation": {
          "address": {
            "postalCode": null,
            "countryCode": "US",
            "city": "Sparks",
            "stateProvinceCode": "NV",
            "addressLine": null
          }
        },
        "status": {
          "activityType": "I",
          "description": "Origin Scan",
          "activityCode": "OR",
          "statusCode": "I"
        },
        "date": "20230714",
        "time": "213129"
      }
    ]
  },
  "shipmentType": {
    "code": "01",
    "description": "Small Package"
  },
  "shipDate": "20230717"
}


const enrollmentInfo = {
  "transId": "f8b768bc66684fc99f5e24966c931230",
  "enrollmentInformation": {
    "enrollmentNumber": "1D60481AF6BF71E8",
    "primaryName": {
      "firstName": "Jayna",
      "middleInitial": "",
      "lastName": "Shamblin",
      "suffix": "",
      "dob": null,
      "nicknames": null,
      "verified": null
    },
    "householdMembers": null,
    "deliveryAddress": {
      "addressLine1": "17 Oak Ridge Dr",
      "addressLine2": "",
      "addressLine3": "",
      "city": "SAINT GEORGE",
      "state": "GA",
      "postalCode": "31562",
      "country": "US"
    },
    "validUSPSAddress": false,
    "token": "22e393737fe94107b43db25cc806084a",
    "basicPromoCode": null,
    "basicPromoCodeHistory": null,
    "premiumPromoCode": null,
    "premiumPromoCodeHistory": null,
    "enrollmentStatus": "01",
    "incompleteStatus": null,
    "identityVerificationStatus": "02",
    "enrollmentStatusDescription": "Active",
    "feeType": "03",
    "startDate": "04/23/2019",
    "expirationDate": "01/01/9999",
    "cancellationDate": "01/01/9999",
    "creationDate": "04/23/2019",
    "startDateAsCalendar": null,
    "expirationDateAsCalendar": 253370782800000,
    "creationDateAsCalendar": 1556048703528,
    "enrollmentDescription": "17 Oak Ridge Dr",
    "gmtOffset": "-05.00",
    "dayLightSavingsIndicator": true,
    "timeZone": "America/New_York",
    "driverRelease": false,
    "leaveAt": "OO",
    "leaveAtDescription": "None Selected",
    "customerAuthorizeDriverRelease": false,
    "securityCodeEntered": false,
    "deliveryAlerts": [
      {
        "type": "01",
        "typeDescription": "Day Before Delivery Reminder",
        "alertDestinations": [
          {
            "sendBy": "04",
            "sendByDescription": "E-mail",
            "sendTo": [
              "rashmisharma@ups.com"
            ],
            "removeDestination": false,
            "name": "Jayna",
            "locale": "en_US"
          }
        ]
      },
      {
        "type": "02",
        "typeDescription": "Delivery Schedule Update",
        "alertDestinations": [
          {
            "sendBy": "04",
            "sendByDescription": "E-mail",
            "sendTo": [
              "rashmisharma@ups.com"
            ],
            "removeDestination": false,
            "name": "Jayna",
            "locale": "en_US"
          }
        ]
      },
      {
        "type": "03",
        "typeDescription": "Confirmation of Delivery",
        "alertDestinations": [
          {
            "sendBy": "04",
            "sendByDescription": "E-mail",
            "sendTo": [
              "rashmisharma@ups.com"
            ],
            "removeDestination": false,
            "name": "Jayna",
            "locale": "en_US"
          }
        ]
      },
      {
        "type": "04",
        "typeDescription": "Package Available for Pickup",
        "alertDestinations": [
          {
            "sendBy": "04",
            "sendByDescription": "E-mail",
            "sendTo": [
              "rashmisharma@ups.com"
            ],
            "removeDestination": false,
            "name": "Jayna",
            "locale": "en_US"
          }
        ]
      },
      {
        "type": "06",
        "typeDescription": "Day of Delivery Notification",
        "alertDestinations": [
          {
            "sendBy": "04",
            "sendByDescription": "E-mail",
            "sendTo": [
              "rashmisharma@ups.com"
            ],
            "removeDestination": false,
            "name": "Jayna",
            "locale": "en_US"
          }
        ]
      },
      {
        "type": "07",
        "typeDescription": "Ship Notification",
        "alertDestinations": [
          {
            "sendBy": "OO",
            "sendByDescription": "None",
            "sendTo": null,
            "removeDestination": false,
            "name": null,
            "locale": null
          }
        ]
      },
      {
        "type": "08",
        "typeDescription": "Imminent Delivery In 60 Minutes",
        "alertDestinations": [
          {
            "sendBy": "OO",
            "sendByDescription": "None",
            "sendTo": null,
            "removeDestination": false,
            "name": null,
            "locale": null
          }
        ]
      },
      {
        "type": "09",
        "typeDescription": "Imminent Delivery In 45 Minutes",
        "alertDestinations": [
          {
            "sendBy": "OO",
            "sendByDescription": "None",
            "sendTo": null,
            "removeDestination": false,
            "name": null,
            "locale": null
          }
        ]
      },
      {
        "type": "10",
        "typeDescription": "Imminent Delivery In 30 Minutes",
        "alertDestinations": [
          {
            "sendBy": "OO",
            "sendByDescription": "None",
            "sendTo": null,
            "removeDestination": false,
            "name": null,
            "locale": null
          }
        ]
      },
      {
        "type": "11",
        "typeDescription": "Imminent Delivery In 15 Minutes",
        "alertDestinations": [
          {
            "sendBy": "OO",
            "sendByDescription": "None",
            "sendTo": null,
            "removeDestination": false,
            "name": null,
            "locale": null
          }
        ]
      },
      {
        "type": "12",
        "typeDescription": "My Choice For Home",
        "alertDestinations": [
          {
            "sendBy": "OO",
            "sendByDescription": "None",
            "sendTo": null,
            "removeDestination": false,
            "name": null,
            "locale": null
          }
        ]
      }
    ],
    "enctyptedSecurityCode": null,
    "decoder": null,
    "autoRenewal": true,
    "premiumRenewalFeeType": null,
    "premiumRenewalStartDate": null,
    "premiumRenewalExpirationDate": null,
    "premiumRenewalExpirationDateAsCalender": null,
    "vacationInformation": null,
    "surePostOrBasicPackageSetting": {
      "surePostOrBasicPackageNotify": {
        "type": "05",
        "typeDescription": "Notify Me When a SurePost Package Is Sent To Me",
        "alertDestinations": [
          {
            "sendBy": "04",
            "sendByDescription": "E-mail",
            "sendTo": [
              "rashmisharma@ups.com"
            ],
            "removeDestination": false,
            "name": "Jayna",
            "locale": "en_US"
          }
        ]
      },
      "autoUpgradeEnabled": true,
      "autoUpgrade": false,
      "paymentType": null,
      "defaultPaymentMethod": null
    },
    "eligibleForUpgrade": true,
    "eligibleForCancel": true,
    "eligibleForRenewal": false,
    "eligibleForReactivate": false,
    "numberOfPINRequestAttemptsLeft": 1,
    "numberOfPINValidationAttemptsLeft": 3,
    "acceptedLatestTC": true,
    "faceBookUID": null,
    "packageDeliveryOptions": {
      "deliveryPreference": null,
      "preferredAccessPointId": null,
      "alternateAccessPointId": null,
      "preferredAccessPointIdType": null,
      "alternateAccessPointIdType": null,
      "preferredUAPId": null,
      "alternateUAPId": null
    },
    "locale": "en_US",
    "paymentMethod": null,
    "authenticationMethod": "",
    "updateInfo": null,
    "platformType": "",
    "lastNameChangeDate": null,
    "lastNameChangeBypassAuthenticationDate": null,
    "addressChangeDate": null,
    "addressChangeAuthenticated": false,
    "businessName": "",
    "accessPointId": "",
    "matchingEntities": null,
    "originalMatchType": null,
    "addressChangePending": false
  },
  "locale": "en_US",
  "response": {
    "code": 0,
    "message": "SUCCESS"
  }
}
