import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormArray } from '@angular/forms';
import { NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ClaimService } from 'src/app/services/claim.service';
export interface claimStatusResult {
  claimSubStatus: string[];
  claimClosedOutcome: string[];
}
@Component({
  selector: 'upsc-claimdetails-search-form',
  templateUrl: './claimdetails-search-form.component.html',
  styleUrls: ['./claimdetails-search-form.component.scss']
})
export class ClaimDetailsSearchFormComponent implements OnInit {
  @ViewChild(NgbDropdownMenu) drop: NgbDropdownMenu;
  subscription: Subscription;
  claimStatusForm: UntypedFormGroup;
  applied = false;
  valueErrors = false;
  claimStatus: string[];
  searchQuery: string;
  searchByValue: string;
  showStatuses = new Array();
  displayStatus: any;
  statusSelected;
  defaultFormValues: any = {
    status: null
  };
  constructor(
    public claimservice: ClaimService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService
  ) {

    /* this.claimStatusForm = this.fb.group({
      status: [, Validators.required]
    }); */

    this.subscription = this.claimservice.getResetValue().subscribe(data => {
      if (data) {
        this.reset();
      }
    });
  }
  claimStates = new Array();
  claimClosedOutcome = new Array();
  @Output() claimStatusResult = new EventEmitter<claimStatusResult>();
  @Input() presetStatus: string;

  ngOnInit(): void {
    this.claimStatus = [
      this.translateService.instant('claimsDetail.paid'),
      this.translateService.instant('claimsDetail.pending'),
      this.translateService.instant('claimsDetail.closed'),
      this.translateService.instant('claimsDetail.received'),
      this.translateService.instant('metadata.claimStatusMapping.withdrawn_Ext'),
      this.translateService.instant('claimsDetail.submitted'),
      this.translateService.instant('metadata.claimStatusMapping.denied_Ext'),
      this.translateService.instant('claimsDetail.investigation'),
    ];

    this.claimStatusForm = new UntypedFormGroup({}, Validators.required);
    this.claimStatus.forEach((s, i) => {
      if(this.presetStatus == s ) {
        this.claimStatusForm.addControl(s, new UntypedFormControl(true));
        this.check({checked: true}, s)
      }
      else {
        this.claimStatusForm.addControl(s, new UntypedFormControl(false));
      }
    });

    if(sessionStorage.getItem('retainState')) {
      if(sessionStorage.getItem('isadvanced') && sessionStorage.getItem('isSearchMade')){
        let statusdata = JSON.parse(sessionStorage.getItem('claimStatusSearch'));
        if(statusdata){
          this.applied = true;
          this.displayStatus = [... statusdata]

        }
      }
    }
  }

  //Single selection code
  // check (event, category) {
  //   this.showStatuses = category;
  //   let closedOutcome = [];
  //   let claimSubStatus = [];

  //   if (category == "Claim Received") {
  //     claimSubStatus = ["submitted"];
  //     closedOutcome = [];
  //   } else if (category == "Submitted for Payment") {
  //     claimSubStatus = ["Submitted_for_Payment"];
  //     closedOutcome = [];
  //   } else if (category == "Investigation Pending") {
  //     claimSubStatus = ["Pending_Insurance_Carrier", "Report_to_transportation_carrier", "Security_Investigation"];
  //     closedOutcome = [];
  //   } else if (category == "Documents Pending") {
  //     claimSubStatus = ["IDR_Request_1", "IDR_Request_2", "IDR_Request_Final"];
  //     closedOutcome = ["incomplete_documentation_Ext"];
  //   } else if (category == "Paid") {
  //     claimSubStatus = [];
  //     closedOutcome = ["paymentscomplete"];
  //   } else if (category == "Withdrawn") {
  //     claimSubStatus = [];
  //     closedOutcome = ["withdrawn_Ext"];
  //   } else if (category == "Closed") {
  //     claimSubStatus = [];
  //     closedOutcome = ["mistake", "duplicate", "other_Ext"];
  //   } else if (category == "Denied") {
  //     claimSubStatus = [];
  //     closedOutcome = ["denied_Ext"];
  //   }

  //   this.claimClosedOutcome = closedOutcome;
  //   this.claimStates = claimSubStatus;
  //   const claimSearchResult = {
  //     claimSubStatus: this.claimStates,
  //     claimClosedOutcome: this.claimClosedOutcome
  //   }
  //   this.claimStatusResult.emit(claimSearchResult);
  //   this.applied = true;
  //   this.displayStatus = this.showStatuses;
  //   sessionStorage.setItem('claimStatusSearch', JSON.stringify(this.displayStatus));
  // }

  check(event, category) {

    if (this.showStatuses.length > 0) {
      const index: number = this.showStatuses.indexOf(category);
      if (index !== -1) {
        this.showStatuses.splice(index, 1);
      }
    }
    if (event.checked === true) {
      this.statusSelected = category;
      this.showStatuses.push(this.statusSelected);
      let closedOutcome = [];
      let claimSubStatus = [];
      if ((category == "Claim Received" || category == "Reclamo ricevuto" || category == "Schadenforderung empfangen" || category == "Réclamation reçue") ||
        (category == "Submitted for Payment" || category == "Inviato per il pagamento" || category == "Soumis pour paiement" || category == "Eingereicht zur Zahlung") ||
        (category == "Investigation Pending" || category == "Indagine in attesa" || category == "Untersuchung ausstehend" || category == "Enquête en cours") ||
        (category == "Documents Pending" || category == "Documenti in attesa" || category == "Documents en attente" || category == "Ausstehende Dokumente")) {
        if (category == "Claim Received" || category == "Reclamo ricevuto" || category == "Schadenforderung empfangen" || category == "Réclamation reçue") {
          claimSubStatus = ["submitted"];
        }
        else if (category == "Submitted for Payment" || category ==  "Inviato per il pagamento" || category ==  "Soumis pour paiement" || category ==  "Eingereicht zur Zahlung") {
          claimSubStatus = ["Submitted_for_Payment"];
        }
        else if (category == "Investigation Pending" || category == "Indagine in attesa" || category == "Untersuchung ausstehend" || category ==  "Enquête en cours") {
          claimSubStatus = ["Pending_Insurance_Carrier", "Report_to_transportation_carrier", "Security_Investigation"];
        }
        else if (category == "Documents Pending" || category == "Documenti in attesa" || category == "Documents en attente" || category == "Ausstehende Dokumente") {
          claimSubStatus = ["IDR_Request_1", "IDR_Request_2", "IDR_Request_Final"];
          closedOutcome = ["incomplete_documentation_Ext"];
        }
        this.claimStates = [...this.claimStates, ...claimSubStatus]
        this.claimClosedOutcome = [...this.claimClosedOutcome, ...closedOutcome];
      }
      else {
        if (category == "Paid" || category == "Payé" || category == "Bezahlt"
          || category == "Pagato") {
          claimSubStatus = ["Paid"];
          closedOutcome = ["paymentscomplete"];
        }
        else if (category == "Withdrawn" || category =="Ritirato"
        || category =="Retiré" || category =="Zurückgezogen") {
          closedOutcome = ["withdrawn_Ext"];
        }
        else if (category == "Closed" || category == "Chiuso" || category == "Abgeschlossen"
          || category == "Fermé") {
          closedOutcome = [ "mistake", "duplicate", "other_Ext"];
        }
        else if (category == "Denied" || category == "Abgelehnt"
          || category == "Refusé" || category == "Rifiutato") {
          closedOutcome = ["denied_Ext"];
        }
        this.claimStates = [...this.claimStates, ...claimSubStatus]
        this.claimClosedOutcome = [...this.claimClosedOutcome, ...closedOutcome];
      }
    }
    else {
      if (category == "Claim Received"|| category ==  "Reclamo ricevuto" || category ==  "Schadenforderung empfangen" || category ==  "Réclamation reçue") {
        const index = this.claimStates.indexOf("submitted");
        if (index > -1) {
          this.claimStates.splice(index, 1);
        }
      }
      else if (category == "Submitted for Payment" || category ==  "Inviato per il pagamento" || category ==  "Soumis pour paiement" || category ==  "Eingereicht zur Zahlung") {
        const index = this.claimStates.indexOf("Submitted_for_Payment");
        if (index > -1) {
          this.claimStates.splice(index, 1);
        }
      }
      else if (category == "Investigation Pending" || category == "Indagine in attesa" || category == "Untersuchung ausstehend" || category ==  "Enquête en cours") {
        const index = this.claimStates.indexOf("Pending_Insurance_Carrier");
        const index1 = this.claimStates.indexOf("Report_to_transportation_carrier");
        const index2 = this.claimStates.indexOf("Security_Investigation");
        let arrayIndex = [index, index1, index2]
        for (var i = arrayIndex.length - 1; i >= 0; i--) {
          this.claimStates.splice(arrayIndex[i], 1);
        }
      }
      else if (category == "Documents Pending" || category == "Documenti in attesa" || category == "Documents en attente" || category == "Ausstehende Dokumente") {
        const index = this.claimStates.indexOf("IDR_Request_1");
        const index1 = this.claimStates.indexOf("IDR_Request_2");
        const index2 = this.claimStates.indexOf("IDR_Request_Final");
        const index3 = this.claimClosedOutcome.indexOf("incomplete_documentation_Ext");
        this.presetStatus = null;
        
        let arrayIndex = [index, index1, index2]
        for (var i = arrayIndex.length - 1; i >= 0; i--) {
          this.claimStates.splice(arrayIndex[i], 1);
        }
        if (index3 > -1) {
          this.claimClosedOutcome.splice(index3, 1);
        }
      }
      else if (category == "Paid" || category == "Payé" || category == "Bezahlt"
        || category == "Pagato") {
        let index = this.claimClosedOutcome.indexOf("paymentscomplete");
        let index1 = this.claimStates.indexOf("Paid");
        if (index > -1) {
          this.claimClosedOutcome.splice(index, 1);
        }
        if (index1 > -1) {
          this.claimStates.splice(index1, 1);
        }
      }
      else if (category == "Withdrawn" || category == "Ritirato"
        || category == "Retiré" || category == "Zurückgezogen") {
        let index = this.claimClosedOutcome.indexOf("withdrawn_Ext");
        if (index > -1) {
          this.claimClosedOutcome.splice(index, 1);
        }
      }
      else if (category == "Closed" || category == "Chiuso" || category == "Abgeschlossen"
        || category == "Fermé") {
        let index = this.claimClosedOutcome.indexOf("incomplete_documentation_Ext");
        let index1 = this.claimClosedOutcome.indexOf("mistake");
        let index2 = this.claimClosedOutcome.indexOf("duplicate");
        let index3 = this.claimClosedOutcome.indexOf("other_Ext");
        let arrayIndex = [index, index1, index2, index3]
        for (var i = arrayIndex.length - 1; i >= 0; i--) {
          this.claimClosedOutcome.splice(arrayIndex[i], 1);
        }
      }
      else if (category == "Denied" || category == "Abgelehnt"
        || category == "Refusé" || category == "Rifiutato") {
        let index = this.claimClosedOutcome.indexOf("denied_Ext");
        if (index > -1) {
          this.claimClosedOutcome.splice(index, 1);
        }
      }
    }
    if(this.showStatuses.length > 0) {
      this.applied = true;
      this.displayStatus = [... this.showStatuses]
      const claimSearchResult = {
        claimSubStatus: this.claimStates,
        claimClosedOutcome: this.claimClosedOutcome
      }
      this.claimStatusResult.emit(claimSearchResult);
      sessionStorage.setItem('claimStatusSearch', JSON.stringify(this.displayStatus));
    }
    else{
      this.applied = false;
    }
  }

  apply(event) {
    if (this.checkVals()) {
      const claimSearchResult = {
        claimSubStatus: this.claimStates,
        claimClosedOutcome: this.claimClosedOutcome
      }
      this.claimStatusResult.emit(claimSearchResult);
      this.applied = true;
      this.displayStatus = [... this.showStatuses]
      this.valueErrors = false;
      this.drop.dropdown.close();
    } else {
      this.valueErrors = true;
    }
  }

  checkVals() {
    if (this.showStatuses.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  reset() {
    this.applied = false;
    this.claimStates = [];
    this.claimClosedOutcome = [];
    this.showStatuses = [];
    this.displayStatus = [];

    this.claimStatusForm.reset(this.defaultFormValues);
    this.claimStatusResult.emit();
    sessionStorage.removeItem('claimStatusSearch');

  }

  close() {
    this.valueErrors = false;
    this.drop.dropdown.close();
    this.reset();
  }

  get status() { return this.claimStatusForm.controls.status; }

}
