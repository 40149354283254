/**
 * Important Note:
 * When using BrowserAnimationModule, the router prevent destroying the component you are leaving if it throwing an error.
 * In this case, you may see a new component getting prepended to the current one instead of replacing it when navigating to the new one.
 * The temporarily solution is make sure that the component you navigate from not throwing an error and router will work properly.
 * Using zone to navigate to the new one seems to solve the problem too.
 */

import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private zone: NgZone;

  constructor(private injector: Injector) {
    this.zone = this.injector.get(NgZone);
  }

  public handleError(err: any) {
    
    const isUnauthenticatedMessage = err.error && err.error.message === 'Unauthenticated.';
    if (err.status === 401 || isUnauthenticatedMessage) {
      //const auth = this.injector.get(AuthService);
      this.zone.run(() => {
        //Marktodo
        //auth.logout();
      });


      return;
    }

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    // throw err;

    // Note: HttpErrorResponse.error is the error body.
    const errorBody = err.error;

    if (!errorBody) {
      throw err;
    }

    const apiErrorMessage = errorBody.error ? errorBody.error.message : null;
    const validationErrorMessages: string[] = [];
    if (!apiErrorMessage && errorBody.error && errorBody.error.validation) {
      Object.keys(errorBody.error.validation).forEach(
        (key) => {
          validationErrorMessages.push(`${key} - ${errorBody.error.validation[key][0]}`);
        });
    }

    const validationErrorMessage = validationErrorMessages && validationErrorMessages.length
      ? validationErrorMessages.join('<br>')
      : null;

    const errorMessage = apiErrorMessage ||
      validationErrorMessage ||
      err.message ||
      err.statusText ||
      'Something went wrong. Please try again later or contact administrator for further assistance.';
    console.error(errorMessage);    
    console.error(err.message);
  }
}
