import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ClaimService } from 'src/app/services/claim.service';
import { emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { usStates } from 'src/config/metadata-config';
import { UserService } from 'src/app/services/user.service';
import { ProspectPortal } from 'src/app/shared/models/prospect-portal';
import { ProspectService } from 'src/app/services/prospect.service';
import { environment } from 'src/environments/environment';
import { TaggingService } from 'src/app/tags/tagging.service';

@Component({
  selector: 'upsc-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent  implements OnInit {
  //Data holders
  opportunityID;
  fullStateList = usStates;
  addressList = [];
  currentAddress = null;
  prospectPortalData: ProspectPortal;

  //Conditional views
  showSpinner: boolean = false;
  loading: boolean = false;
  showEmailVerification: boolean = false;
  showAddressValidation: boolean = false;
  verificationCodeError: boolean = false;
  systemError: boolean = false;
  taxIDMessage: boolean = false;
  denied: boolean = false;
  leftOffPage: string;
  showTaxInfo: boolean = false;

  //Form
  formGroup: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private claimService: ClaimService,
    private userService: UserService,
    private prospectService: ProspectService,
    private router: Router,
    private taggingService: TaggingService
  ) {
    this.prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData'));

    this.formGroup = this.formBuilder.group({
      name: [{value: this.prospectPortalData?.customerName, disabled: true}, Validators.compose([Validators.required])],
      email: [{value: this.prospectPortalData?.customerEmail, disabled: true}, Validators.compose([Validators.required, Validators.email])],
      company: [{value: this.prospectPortalData?.customerCompany, disabled: true}, Validators.compose([Validators.required])],
      phone: [this.prospectPortalData?.customerPhone, Validators.compose([Validators.required])],
      city: [this.prospectPortalData?.billingCity, Validators.compose([Validators.required])],
      state: [this.prospectPortalData?.billingStateCode, Validators.compose([Validators.required])],
      zip: [this.prospectPortalData?.billingPostalCode, Validators.compose([Validators.required])],
      address1: [this.prospectPortalData?.billingStreet, Validators.compose([Validators.required])],
      taxID: [this.prospectPortalData?.taxID],
      webSite: [this.prospectPortalData?.webSite],
      recaptcha: [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.taggingService.view({
      page_id: "let's_get_started_page",
      page_name: "let's_get_started_page"
    });
    //retrieve CRM data and prepopulate the form.
    if(!this.prospectPortalData) {
      this.showSpinner = true;
      this.route.queryParams.subscribe(
        params => {
          if(params?.param) {
            this.opportunityID = atob(params.param);
            this.prospectService.getProspectPortalSession({
              opportunityID: this.opportunityID
            }).subscribe(
              res => {
                if(res.opportunityID && res.data) {
                  this.showSpinner = false;
                  let dataObj = JSON.parse(res.data);
                  if(dataObj.reviewStatus && dataObj.reviewStatus.toLowerCase() != 'pass' || dataObj.dpsFound === true) {
                    let request = {
                      applicationName: 'OnlinePortal',
                      emailFor: 'PPAppRejection',
                      environment: environment.webjob.env,
                      data: {
                        upscCustomerNumber: dataObj.numberofParent,
                        companyName: dataObj.customerCompany,
                        contactName: dataObj.customerName,
                        phoneNumber: dataObj.customerPhone,
                        email: dataObj.accountOwnerEmail,
                        customerEmail: dataObj.customerEmail
                      },
                      countryLocale: 'en-US'
                    }

                    this.prospectService.sendLexusRejectionEmail(request).subscribe();
                    this.router.navigateByUrl('/prospect-portal/under-review')
                  }
                  else {
                  this.populateData(dataObj);
                  if(dataObj.currentPage && dataObj.currentPage != this.router.url) {
                    // this.router.navigateByUrl(dataObj.currentPage)
                    this.leftOffPage = dataObj.currentPage;
                    this.taggingService.view({
                      page_id: "verification_email_code_page",
                      page_name: "Verification Email Code Page"
                    });
                    this.showEmailVerification = true;
                  }
                }
                }
                else {
                  this.getCRMData();
                }
              },
              err => {
                this.getCRMData();
              }
            )
          }
        }
      );
    }
    else {
      this.opportunityID = this.prospectPortalData.opportunityID;
    }
  }

  getCRMData() {
    this.accountService.retrieveCRMData({
      opportunityID: this.opportunityID
    }).subscribe(
      res => {
        this.showSpinner = false;
        if(res?.message == 'Success') {
          this.populateData(res.data, {opportunityID: this.opportunityID});
        }
        else {
          this.systemError = true;
        }
      },
      err => {
        this.showSpinner = false;
        this.systemError = true;
      }
    )
  }

  sendVerificationCode(emailId: string, prepopData?) {
    let emailModel = {
      ...emptyVerificationInterface,
      data: {
        emailAddress: emailId,
        phoneNumber: this.phone.value
      },
      emailFor: 'PPVerification'
    }

    this.accountService.createVerificationCode(emailModel).subscribe(
      data => {
        this.scrollToTop();
        this.loading = false;
        this.systemError = false;
        this.showEmailVerification = true;
        this.taggingService.view({
          page_id: "verification_email_code_page",
          page_name: "Verification Email Code Page"
        });
        this.showSpinner = false;
      }, error => {
        this.loading = false;
        this.systemError = true;
        this.showSpinner = false;
      }
    );
  }

  populateData(data, addlData = {}) {
    this.prospectPortalData = {...data, ...addlData};
    sessionStorage.setItem('prospectPortalData', JSON.stringify(this.prospectPortalData));
    this.name.setValue(data?.customerName);
    this.company.setValue(data?.customerCompany);
    this.email.setValue(data?.customerEmail)
    this.phone.setValue(data?.customerPhone);
    this.address1.setValue(data?.billingStreet);
    this.city.setValue(data?.billingCity);
    this.state.setValue(data?.billingStateCode ? data?.billingStateCode.toUpperCase() : '');
    this.zip.setValue(data?.billingPostalCode ? data?.billingPostalCode.substring(0,5) : '');
    this.taxID.setValue(data?.taxID ? data?.taxID : '');
    this.webSite.setValue(data?.webSite ? data?.webSite : '');
  }

  //User filled out the form and pressed "Confirm Details"
  submitForm() {
    this.taggingService.link({
      link_name: "Confirm Details",
      link_page_id: "let's_get_started_page"
    });
    this.loading = true;
    this.systemError = false;
    this.taxIDMessage = false;
    if (this.formGroup.valid) {
      this.showSpinner = true;
      this.storeData();
      this.saveSession();
      this.accountService.verifyCRMProspect({
        'opportunity_id': this.opportunityID,
        'business_name': this.company.value,
        'business_address_street1': this.address1.value,
        'business_address_street2': '',
        'business_address_city': this.city.value,
        'business_address_state': this.state.value,
        'business_address_zip': this.zip.value,
        'business_telephone_number': this.phone.value,
        'business_fein': this.formatTaxIDForAPI(this.taxID.value),
        'primary_account_first_name': this.getFirstName(),
        'primary_account_last_name': this.getLastName(),
        'account_email': this.email.value
      }).subscribe(
        res => {
          this.storeData({reviewStatus: res.data?.reviewStatus})
          if(JSON.parse(sessionStorage.getItem('verifyData'))?.code) {
            this.addressValidation();
          }
          else {
            this.sendVerificationCode(this.email.value);
        }},
        error => {
          this.loading = false;
          this.showSpinner = false;
          this.systemError = true;
          this.taxIDMessage = true;
        }
      )
    } else {
      this.loading = false;
      this.formGroup.markAllAsTouched();
    }
  }

  onVerified() {
    this.taggingService.link({
      link_name: "Continue",
      link_page_id: "verification_email_code_page"
    });
    this.showEmailVerification = false;
    if(this.leftOffPage) {
      this.router.navigateByUrl(this.leftOffPage);
    }
    else {
      this.addressValidation();
    }
  }

  //Check the address the user entered vs the address API
  addressValidation() {
    this.showSpinner = true;
    this.claimService.addressValidation({
      customerName: this.name.value,
      addressLine1: this.address1.value,
      addressLine2: '',
      city: this.city.value,
      countrycode: 'US',
      zipcode: this.zip.value,
      state: this.state.value
    }).subscribe(
      data => {
        if (data.status == 'Valid') {
          let hasMatch = false;
          for(let a of data.candidateList) {
            if(
              a?.AddressLine[0]?.toLowerCase() == this.address1?.value.toLowerCase() &&
              a?.PoliticalDivision2?.toLowerCase() == this.city?.value.toLowerCase() &&
              a?.PoliticalDivision1?.toLowerCase() == this.state?.value.toLowerCase() &&
              a.PostcodePrimaryLow == this.zip.value &&
              a.CountryCode == 'US'
            ) {
              hasMatch = true;
              break;
            }
          }
          if(hasMatch) {
            //User address matched perfectly. just go straight to denied party.
            this.deniedPartyScreening();
          }
          else {
            //Need to show the user an address
            this.addressList = data.candidateList;
            this.currentAddress = {
              address1: this.address1.value,
              address2: '',
              city: this.city.value,
              state: this.state.value,
              zip: this.zip.value
            }
            this.showAddressValidation = true;
            this.taggingService.view({
              page_id: "address_validation_page",
              page_name: "Address validation Page"
            });
            this.loading = false;
            this.showSpinner = false;
            this.scrollToTop();
          }
        }
        else {
          this.deniedPartyScreening();
        }
      }, error => {
        this.deniedPartyScreening();
        this.loading = false;
      }
    );
  }

  updateAddress(userInput) {
    if(userInput == 'edit') {
      this.showAddressValidation = false;
      this.taggingService.link({
        link_name: "Back",
        link_page_id: "address_validation_page"
      });
    }
    else if(userInput) {
      this.taggingService.link({
        link_name: "Validate",
        link_page_id: "address_validation_page"
      });
      this.address1.setValue(userInput.AddressLine[0]);
      this.state.setValue(userInput.PoliticalDivision1);
      this.city.setValue(userInput.PoliticalDivision2);
      this.zip.setValue(userInput.PostcodePrimaryLow);
      this.storeData();
      this.deniedPartyScreening();
    }
    else {
      this.deniedPartyScreening();
    }
  }

  deniedPartyScreening() {
    this.loading = true;
    this.denied = false;
    this.userService.deniedPartyCheckV2({
      company: this.company.value,
      address: this.address1.value,
      city: this.city.value,
      state: this.state.value,
      country: 'US',
      zip: this.zip.value
    }).subscribe(
      deniedPartyFound => {
        this.loading = false;
        this.showSpinner = false;
        this.storeData({dpsFound: deniedPartyFound})
       /*  if (deniedPartyFound == false) {
          this.continueToNextPage();
        } else {
          this.showAddressValidation = false;
          this.denied = true;
          this.scrollToBottom();
        } */
        this.continueToNextPage();
      }, error => {
        this.showSpinner = false;
        this.showAddressValidation = false;
        this.loading = false;
        this.systemError = true;
      }
    );
  }

  getFirstName() {
    if (this.name.value.includes(' ')) {
      //There are spaces in the name. Take the first part of the name.
      return this.name.value.split(' ')[0];
    } else {
      //There are no spaces in the name. Return the entire string.
      return this.name.value;
    }
  }

  getLastName() {
    if (this.name.value.includes(' ')) {
      //There are spaces in the name. Take everything after the first space.
      return this.name.value.substring(this.name.value.indexOf(' ')+1);
    } else {
      //There are no space in the name. Return nothing.
      return '';
    }
  }

  continueToNextPage() {
    this.saveSession();
    this.router.navigate(['/prospect-portal/shipping']);
  }

  expandTaxInfo() {
    if (this.showTaxInfo == false) {
      this.showTaxInfo = true;
    } else {
      this.showTaxInfo = false;
    }
  }

  storeData(data?: any) {
    if(data) { //update data with API responses
      this.prospectPortalData =  {...this.prospectPortalData, ...data}
    }
    else { //update with user input
      this.prospectPortalData =  {...this.prospectPortalData, ...{
        customerName: this.name.value,
        customerEmail: this.email.value,
        customerCompany: this.company.value,
        customerPhone: this.phone.value,
        billingStreet: this.address1.value,
        billingCity: this.city.value,
        billingStateCode: this.state.value,
        billingPostalCode: this.zip.value,
        taxID: this.taxID.value,
        webSite: this.webSite.value,
        currentPage: this.router.url
      }}
    }
    sessionStorage.setItem('prospectPortalData', JSON.stringify(this.prospectPortalData));
  }

  saveSession() {
    this.prospectService.saveProspectPortalSession({
      opportunityID: this.opportunityID,
      emailAddress: this.prospectPortalData.customerEmail,
      data: JSON.stringify(this.prospectPortalData)
    }).subscribe();
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  formatTaxIDForAPI(taxID) {
    let formattedTaxID = '';
    if (taxID) {
      formattedTaxID = taxID.replace('-', '');
    }
    return formattedTaxID;
  }

  cancelEmailVerification() {
    this.showEmailVerification = false;
    this.taggingService.link({
      link_name: "Cancel",
      link_page_id: "verification_email_code_page"
    });
  }

  get name() { return this.formGroup.controls.name }
  get company() { return this.formGroup.controls.company }
  get email() { return this.formGroup.controls.email }
  get phone() { return this.formGroup.controls.phone }
  get country() { return this.formGroup.controls.country }
  get zip() { return this.formGroup.controls.zip }
  get city() { return this.formGroup.controls.city }
  get state() { return this.formGroup.controls.state }
  get address1() { return this.formGroup.controls.address1 }
  get taxID() { return this.formGroup.controls.taxID}
  get webSite() { return this.formGroup.controls.webSite}
  get recaptcha() { return this.formGroup.controls.recaptcha }

}
