<div class="upsc-custom-mobile-search">
<div class="mobile-margins">
  <div class="search-bar d-flex">
    <span class="text align-self-center">{{ 'dashboard.ClaimsActivity.PolicyType' | translate }}
      {{policyType}}<br>{{'dashboard.ClaimsActivity.PolicyNo' | translate }}{{policyNumber}}</span>
    <button mat-button>
      <mat-icon>{{'search'}}</mat-icon>
    </button>
  </div>
</div>

<div class="search d-flex">
  <mat-form-field class="category plain-form-field" floatLabel=never>
    <mat-select placeholder="{{ 'claims.list.dialog.searchBy' | translate }}" [(value)]="searchByValue" (selectionChange)="checkCategory()">
      <mat-option *ngFor="let category of searchBy" [value]="category.value">
        {{ category.label | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="search-child2 plain-form-field" floatLabel=never [hidden]="calendarBool || amountBool">
    <input matInput placeholder="{{ 'claims.list.dialog.search' | translate }}" name="searchQuery" [(ngModel)]="searchQuery" (change)="checkQuery()">
    <mat-icon matSuffix (click)="refreshInputs()">{{'close'}}</mat-icon>
  </mat-form-field>
  <mat-form-field class="search-child2 calendar  plain-form-field" floatLabel=never [hidden]="!calendarBool">
    <input matInput [matDatepicker]="picker" placeholder="{{ 'claims.list.dialog.date' | translate }}" [(ngModel)]="searchQuery" (ngModelChange)="checkQuery()">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [touchUi]="isMobile" #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="search-child2 amount plain-form-field" floatLabel=never [hidden]="!amountBool">
    <input matInput appDecimalFieldMask placeholder="{{ 'claims.list.dialog.search' | translate }}" name="searchQuery" [(ngModel)]="searchQuery" (change)="checkQuery()">
    <mat-icon matSuffix (click)="refreshInputs()">{{'close'}}</mat-icon>
  </mat-form-field>
</div>

<div class="buttons d-flex justify-content-center">
  <button mat-button upscDebounce [debounceTime]="1000" [disabled]="!searchable" (debounceClick)="search()">
    {{ 'claims.list.dialog.search' | translate }}
  </button>
</div>
<div class="cancel d-flex justify-content-center">
  <span class="button" (click)="cancel()">{{ 'claims.list.dialog.cancel' | translate }}</span>
</div>
</div>