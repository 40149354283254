import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ClaimService } from 'src/app/services/claim.service';
import { TranslateService } from '@ngx-translate/core';
import { IPolicies } from '../../models/policies.interface';
import { IEmail, emptyIEmail } from '../../models/email.interface';
import { PhoneNumberPipe } from '../../pipes/phone-number.pipe';
import { LoginService } from 'src/app/services/login.service';
import { TupssService } from 'src/app/pages/claims-custom/tupss/services/tupss.service';


@Component({
  selector: 'tupss-upsc-contact-us',
  templateUrl: './tupss-contact-us.component.html',
  styleUrls: ['./tupss-contact-us.component.scss']
})
export class CustomContactUsComponent {

  contactForm: UntypedFormGroup;
  pref: string;
  policyDetails: IPolicies;
  userDetails;
  page = 1;
  adjusterEmail: string = "";
  private emailModel: IEmail = emptyIEmail;
  processing: boolean = false;
  i18 = require('../../../../assets/i18n/' + this.translateService.getDefaultLang() + '.json');
  
  //TUPSS Variables
  contactType = 'tccp';  //Can be "tccp", or "tfcp"
  claimData;
  iShipData;
  siteIds;  //Can be either 1 number, a list of numbers, or the text 'all'

  //Warning Scenarios
  noData = false;
  duplicateTrackingNumber = false;
  invalidTrackNoFormat = false;
  noLongerEligible = false;
  declaredValue = false;
  carrierLiability = false;
  not1ZAndNoData = false;
  billingRecipient = false;
  billingThirdParty = false;
  BMA = false;
  FAQ = false;
  maintenance = false;
  submitted = false;
  submittedForPayment = false;
  sfpCarrierLiability = false;
  documents = false;
  investigation = false;
  denied = false;
  paid = false;
  coverage = false;

  topicListTCCP = [
    { key: 1, value: this.i18.support.dialog.topicList.tupss.topic1 },
    { key: 2, value: this.i18.support.dialog.topicList.tupss.topic2 },
    { key: 3, value: this.i18.support.dialog.topicList.tupss.topic3 },
    { key: 4, value: this.i18.support.dialog.topicList.tupss.topic4 },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private claimService: ClaimService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<CustomContactUsComponent>,
    private phonePipe: PhoneNumberPipe,
    private loginService: LoginService,
    private tupssService: TupssService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (sessionStorage.getItem('userDetails') && JSON.parse(sessionStorage.getItem('userDetails')).type) {
      this.contactType = JSON.parse(sessionStorage.getItem('userDetails')).type;
    } else if (sessionStorage.getItem('URLFragment')) {
      this.contactType = 'tfcp';
    }

    if (this.contactType == 'tfcp') {
      this.topicListTCCP[1].value = this.i18.support.dialog.topicList.tupss.tfcpTopic2;
    }

    this.policyDetails = this.loginService.getPolicyDetails();
    this.siteIds = this.contactType == 'tfcp' ? this.tupssService.getSiteIds() : null;    //Need this in order to get TFCP store numbers userDetails.tupssScope
    this.contactForm = this.fb.group({
      topic: ['', Validators.compose([Validators.required])],
      claim: [''],
      trackingNumber: [JSON.parse(sessionStorage.getItem('userDetails'))?.trackingNumber, Validators.compose([Validators.required, Validators.pattern(/^[a-z\-0-9]+$/i)])],  //TCCP TN is required. TFCP is not.
      siteIdSelection: [this.siteIds && this.siteIds.length == 1 && this.siteIds[0] != 'all' ? this.siteIds[0] : ''],
      description: ['', Validators.compose([Validators.required])],
      name: ['', Validators.compose([Validators.required])],
      contact: ['', Validators.compose([Validators.required])],
      phone: [],
      email: ['']
    });

    this.claimService.getTupssClaimData().subscribe(
      data => {
        if (data) { //This should mean they are now in POST claim-filing process
          this.claimData = data;
          this.contactForm.controls.phone.setValue(this.phonePipe.transform(this.claimData.mainContact.workNumber));
          this.contactForm.controls.email.setValue(this.claimData.mainContact.emailAddress1);
          if(this.claimData.customerDisplayStatus == "Paid") {
            this.FAQ = true;
          }
          if(this.claimData.customerDisplayStatus == "Submitted for Payment") {
            this.FAQ = true;
          }
          if(this.contactType == 'tccp' && this.claimData.claimNumber){
            this.getShipmentDetailsPostTCCP(this.claimData);
          }
        }
      }
    );

    //If user chooses phone, make it required and remove email requirement.
    //If opposite is chosen, opposite is required.
    this.contactForm.controls.contact.valueChanges.subscribe(
      value => {
        if (value === 'phone') {
          this.contactForm.get('phone').setValidators(Validators.compose([Validators.required, Validators.pattern(/\d{1,3}/)]));
          this.contactForm.get('email').clearValidators();
          this.contactForm.get('phone').updateValueAndValidity();
          this.contactForm.get('email').updateValueAndValidity();
        } else if (value === 'email') {
          this.contactForm.get('email').setValidators(Validators.compose([Validators.required, Validators.email]));
          this.contactForm.get('phone').clearValidators();
          this.contactForm.get('email').updateValueAndValidity();
          this.contactForm.get('phone').updateValueAndValidity();
        }
      }
    );

    this.contactForm.controls.topic.valueChanges.subscribe(
      valChanged => { this.resetWarnings(); }
    );
  }

  getShipmentDetailsPostTCCP(data) {
    //iShip data check
    let payload;
    payload = {
      transactionSrc: "Capital_TFCP",
      trackNo: data.shippingDetailsDTO.trackingNumber
    }
    this.tupssService.getIShipData(payload).subscribe(
      data => {
        if (data.data.shipDate) {
          this.noData = false;
          this.iShipData = data.data;
        } else {
          //No data was found
          
        }
      }, error => {
        //IShip didn't find any data from this tracking number?
        if (this.trackingNumber.value.length == 18) {
          this.noData = true;
        }
      }
    );
  }

  //Submit data to email service & change page to confirmation page
  onSubmit() {
    let topicKey = this.contactForm.get('topic').value;
    let email;
    let cc;

    //"Technical Issue (3)" type emails go to capitalus@ups.com. Everything else will go to the UPSC TUPSS inbox (claimsinquiry)
    // if (this.contactForm.controls.topic.value == 3) {
    //   email = 'capitalus@ups.com';
    //   cc = 'upscapitalcx@ups.com';
    // } else {
    //   email = 'claimsinquiry@ups.com';
    //   cc = 'upscapitalcx@ups.com';
    // }

    let nameArray = this.contactForm.controls.name.value.split(' ');
    let firstname = nameArray[0];
    let lastname = '';
    if (nameArray.length > 1) {
      nameArray.slice(1, nameArray.length).forEach(element => {
        lastname += element + ' ';
      });
    } else {
      lastname = '';
    }

    this.emailModel.emailFor = this.contactType == 'tfcp' ? 'TFCPContactUs' : 'TCCPContactUs';
    this.emailModel.data.email = 'none';
    this.emailModel.data.ccEmail = '';
    this.emailModel.data.emailAbout = topicKey === 1 ? 'MyClaim' : topicKey === 2 ? 'MyCoverage' : topicKey === 3 ? 'TechnicalIssue' : topicKey === 4 ? 'Other' : 'Error';
    this.emailModel.data.policyNumber = this.policyDetails ? this.policyDetails?.policyNumber : '';
    this.emailModel.data.fName = firstname;
    this.emailModel.data.lName = lastname.trim();
    this.emailModel.data.methodOfContact = this.contactForm.get('contact').value === 'phone' ? this.contactForm.get('phone').value : this.contactForm.get('email').value;
    this.emailModel.data.contactInfo = this.contactForm.get('contact').value;
    this.emailModel.data.description = this.contactForm.get('description').value ? this.contactForm.get('description').value : "None";
    this.emailModel.data.claimNumber = this.contactForm.get('claim').value ? this.contactForm.get('claim').value : "None";
    this.emailModel.data.trackingNumber = this.trackingNumber.value ? this.trackingNumber.value : '';
    this.emailModel.data.storeNumber = this.siteID.value ? this.siteID.value : '';
    this.emailModel.CountryLocale = sessionStorage.getItem('locale');

    this.processing = true; //Prevents button from being clicked multiple times

    if (this.contactType == 'tfcp') {
      this.tupssService.sendTFCPContactUs(this.emailModel).subscribe(
        data => { this.processing = false; this.page = 2;}
      );
    } else {
      this.tupssService.sendTCCPContactUs(this.emailModel).subscribe(
        data => { this.processing = false; this.page = 2;}
      );
    }
  }

  getShipmentDetails() {
    //Don't call any API's if the field is not valid, and don't call any APIs if they are on "Technical Issue" topic
    if (!this.trackingNumber.valid || this.topic.value == 3) {
      return;
    }

    this.resetWarnings();

    if ((!(/^[a-z\-0-9]+$/i).test(this.trackingNumber.value) || this.trackingNumber.value.length!=18) && this.trackingNumber.value.toUpperCase().startsWith('1Z')) {
      this.invalidTrackNoFormat = true;
    }

    this.claimService.getShipmentDetails(this.trackingNumber.value.toUpperCase()).subscribe(
      data => {
        if (data.TrackingNumberDetails.IsDuplicateTrackingNumber == true) {
          this.duplicateTrackingNumber = true;
        }
      }, error => { }
    );

    //iShip data check
    let payload;
    payload = {
      transactionSrc: "Capital_TFCP",
      trackNo: this.trackingNumber.value
    }

    this.tupssService.getIShipData(payload).subscribe(
      data => {
        if (data.data.shipDate) {
          this.noData = false;
          this.iShipData = data.data;
          this.checkCoverageTypeAndShipDate(data);
          this.setFormValues(this.iShipData);
        } else {
          //No data was found, could have a message though
          this.checkBillingType(data);
          this.iShipData = null;
          if (this.trackingNumber.value.length == 18 && this.trackingNumber.value.toUpperCase().startsWith('1Z') && data.message.toLowerCase() == 'notfound') {
            this.noData = true;
          }
          if (!this.trackingNumber.value.toUpperCase().startsWith('1Z')) {
            this.not1ZAndNoData = true;
          }
        }
      }, error => {
        //IShip didn't find any data from this tracking number?
        if (this.trackingNumber.value.length == 18) {
          this.noData = true;
        }
      }
    );
  }

  //Close dialog
  close() {
    this.dialogRef.close();
  }

  openFaqs(event) {
    event.preventDefault();
    this.tupssService.openFaqs();
  }

  setFormValues(data) {
    this.contactForm.controls.phone.setValue(this.phonePipe.transform(data.sitePhone));
    this.contactForm.controls.email.setValue(data.siteEmail);
  }

  //Handles noLongerEligible, declaredValue, carrierLiability, 
  checkCoverageTypeAndShipDate(data) {
    //noLongerEligible
    // let shipDateFormatted = new Date(`${data.shipDate.substr(4,2)}-${data.shipDate.substr(6,2)}-${data.shipDate.substr(0,4)}`);
    // let currentDate = new Date();
    // let dayDifference = Math.floor((currentDate.getTime() - shipDateFormatted.getTime()) / 1000 / 60 / 60 / 24);
    // if (dayDifference > 75 && (data.coverageType == 0 || data.coverageType == 1 || data.coverageType == 2)) {
    //   this.noLongerEligible = true;
    // }
    if (data.message.toLowerCase() == 'dateexceeded') {
      this.noLongerEligible = true;
    }

    //declaredValue
    if (data.data.coverageType == 1 || data.data.coverageType == 2 || data.data.coverageType == 3) {
      this.declaredValue = true;
    }

    //carrierLiability
    if (data.data.coverageType == 0) {
      this.carrierLiability = true;
    }
  }

  //Handles billingRecipient, billingThirdParty, and BMA
  checkBillingType(data) {
    //billingRecipient
    if (data.message.toLowerCase() == 'billrecipient') {
      this.billingRecipient = true;
    }

    //billingThirdParty
    if (data.message.toLowerCase() == 'billthirdparty') {
      this.billingThirdParty = true;
    }

    //BMA
    if (data.message.toLowerCase() == 'bmaups') {
      this.BMA = true;
    }
  }

  resetWarnings() {
    this.noData = false;
    this.duplicateTrackingNumber = false;
    this.invalidTrackNoFormat = false;
    this.noLongerEligible = false;
    this.declaredValue = false;
    this.carrierLiability = false;
    this.not1ZAndNoData = false;
    this.billingRecipient = false;
    this.billingThirdParty = false;
    this.BMA = false;
    this.FAQ = false;
    this.maintenance = false;
    this.submitted = false;
    this.submittedForPayment = false;
    this.documents = false;
    this.investigation = false;
    this.denied = false;
    this.paid = false;
    this.coverage = false;
  }

  get trackingNumber() { return this.contactForm.controls.trackingNumber }
  get siteID() { return this.contactForm.controls.siteIdSelection }
  get topic() { return this.contactForm.controls.topic }
}
