import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UtilityService } from '../../services/utility.service';
import { AccountService } from 'src/app/services/account.service';
import en from 'src/assets/i18n/en.json';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  queryParam: string;
  public resetPasswordForm: UntypedFormGroup;
  reset = {
    userId: "",
    password: ""
  }
  public errorReturned: boolean = false;
  public hide: boolean = true;
  public hide2: boolean = true;
  public tooltipPosition$: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private userService: UserService,
    private titleService: Title,
    private utilService: UtilityService) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, this.userService.isCanadaUser()? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : (this.userService.isUkUser() || this.userService.isCanadaUser() || this.userService.isFranceUser() || this.userService.isGermanyUser() || this.userService.isItalyUser() || this.userService.isUsaUser())? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/)])],
      reenterPassword: ['', Validators.compose([Validators.required, this.userService.isCanadaUser()? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : (this.userService.isUkUser() || this.userService.isCanadaUser() || this.userService.isFranceUser() || this.userService.isGermanyUser() || this.userService.isItalyUser() || this.userService.isUsaUser())? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/)])]
    }, { validator: this.passwordMatchValidator('password', 'reenterPassword') });

    this.route.queryParams
      .subscribe(params => {
        this.queryParam = params['Q'];
        if (this.queryParam) {
          this.getLinkData();
        }
      });

    this.tooltipPosition$ = this.utilService.tooltipPositionWatch();
  }

  ngOnInit() {
    this.titleService.setTitle(en.resetPassword.setTitle);
  }
  getLinkData() {
    const decString = atob(this.queryParam);
    const params = decString.split('&&&&');
    const userId = params['0'];
    const expiryDate = new Date(params['1']);
    const todayDate = new Date();
    if (expiryDate < todayDate) {
      this.router.navigate(['/expired']);
    }
    else {
      this.reset.userId = userId;
    }
  }
  public passwordMatchValidator(passwordKey: string, passwordConfirmationKey: string) {
    return (group: UntypedFormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  public onSubmit(event, value) {
    event.preventDefault();
  }

  public resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.reset.password = this.resetPasswordForm.get('password').value;
      this.accountService.passwordReset(this.reset).subscribe(
        status => {
          this.router.navigate(['/reset-password/success']);
        },
        error => {
          this.errorReturned = true;
        });
    }
  }
}
