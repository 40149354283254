import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAccountComponent } from './create-account.component';
import { LayoutModule } from '../../shared/components/layout/layout.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CreateAccountRoutingModule } from './create-account-routing.module';
import { Stepper2Module } from '../../shared/components/stepper2/stepper2.module';
import { PrivacyComponent } from '../../shared/components/privacy/privacy.component';
import { ProductComponent } from '../../shared/components/product/product.component';
import { FooterModule } from '../../shared/components/footer/footer.module';
import { Stepper5Module } from 'src/app/shared/components/stepper5/stepper5.module'; 
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { LoginHeaderModule } from 'src/app/shared/components/login-header/login-header.module';

@NgModule({
    imports: [
        CommonModule,
        CreateAccountRoutingModule,
        LayoutModule,
        MatStepperModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        Stepper2Module,
        Stepper5Module,
        FooterModule,
        LoginHeaderModule,
        MatSlideToggleModule
    ],
    declarations: [
        CreateAccountComponent,
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateAccountModule { }
