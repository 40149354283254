import { environment } from '../../../environments/environment';

export interface IVerificationInterface {
    applicationName: string,
    emailFor: string,
    environment: string,
    data: {
        emailAddress: string,
    },
    countryLocale: string
}

export const emptyVerificationInterface = {
    applicationName: "OnlinePortal",
    emailFor: "CreateAccount",
    environment: environment.webjob.env,
    data: {
      emailAddress: ""
    },
    countryLocale: "en-US"
  };