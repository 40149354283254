import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NotificationBoxModule } from 'src/app/shared/components/notification-box/notification-box.module';
import { IdentityLayoutModule } from 'src/app/shared/components/identity-layout/identity-layout.module';
import { ClaimsCustomComponent } from './claims-custom.component';
import { TuppsStepperModule } from 'src/app/shared/components/tupps-stepper/stepper.module';
import { Stepper2Module } from 'src/app/shared/components/stepper2/stepper2.module';
import { StartComponent } from './tupss/tupss-edit/components/start/start.component';
import { EmailVerificationComponent } from './tupss/tupss-edit/components/email-verification/email-verification.component';
import { TupssEditComponent } from './tupss/tupss-edit/tupss-edit.component';
import { tupssDetailComponent } from './tupss/tupss-edit/components/detail/detail.component';
import { ShipmentDetailsComponent } from './tupss/tupss-edit/components/shipment-details/shipment-details.component';
import { TUPSSRoutingModule } from './claims-custom-routing.module';
import { FranchiseStartComponent } from './tupss/franchise-portal/franchise-start/franchise-start.component';
import { AchComponent } from './tupss/tupss-edit/components/ach/ach.component';
import { SiteSelectionComponent } from './tupss/franchise-portal/site-selection/site-selection.component';
import { TupssLayoutModule } from 'src/app/shared/components/tupss-layout/tupss-layout.module';
import { UpdateClaimPaymentRecipientPopupComponent } from 'src/app/shared/components/update-claim-payment-recipient-popup/update-claim-payment-recipient-popup.component';
import { DialogContentComponent } from '../claims/components/dialog-content/dialog-content.component';
import { ConfirmCancelComponent } from '../claims/components/confirm-cancel/confirm-cancel.component';
import { MobileSearchComponent } from '../claims/components/mobile-search/mobile-search.component';
import { ExportComponent } from '../claims/dialogs/export/export.component';
import { PoliciesComponent } from './tupss/franchise-portal/policy/policies.component';
import { TFCPAuthComponent } from './tupss/franchise-portal/franchise-start/tfcp-auth.component';
import { CollapsiblePanelModule } from 'src/app/shared/components/collapsible-panel/collapsible-panel.module';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import * as ENV from '../../../environments/environment';
import { DetailVerificationComponent } from './tupss/tupss-edit/components/detail-verification/detail-verification.component';
import { FaqComponent } from './tupss/faq/faq.component';
import { ClaimListComponent } from '../claims/components/list/claim-list.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslatePipe } from '@ngx-translate/core';
import { DamageMissingPartsComponent } from './tupss/tupss-edit/components/damage-missing-parts/damage-missing-parts.component';
import { ConfirmPackageStatusComponent } from './tupss/tupss-edit/components/confirm-package-status/confirm-package-status.component';
import { DeliveryStatusComponent } from './tupss/tupss-edit/components/delivery-status/delivery-status.component';
import { NewNotificationBoxModule } from 'src/app/shared/components/new-notification-box/new-notification-box.module';
import { PaymentLandingComponent } from './my-choice/payment-landing/payment-landing.component';
import { ClaimsLandingComponent } from './my-choice/claims-landing/claims-landing.component';
import { CollapsiblePanelLightModule } from 'src/app/shared/components/collapsible-panel-light/collapsible-panel-light.module';
import { CongratulationsComponent } from './my-choice/congratulations/congratulations.component';
import { CommoditiesComponent } from './my-choice/commodities/commodities.component';
import { SearchMyPackageComponent } from './tupss/tupss-edit/components/search-my-package/search-my-package.component';
import { EditContinueSearchPackageComponent } from './tupss/tupss-edit/components/edit-continue-search-package/edit-continue-search-package.component';
import { PolicySelectComponent } from './tupss/tupss-edit/components/policy-select/policy-select.component';
import { TermsOfAgreementComponent } from './my-choice/terms-of-agreement/terms-of-agreement.component';
import { CustomizedLandingComponent } from './customized-claims-portal/customized-landing/customized-landing.component';
import { FaqsComponent } from './customized-claims-portal/faqs/faqs.component';
import { DynamicLayoutModule } from 'src/app/shared/components/dynamic-layout/dynamic-layout.module';
import { SelfEditComponent } from './self-service-portal/self-edit/self-edit.component';
import { SelfStepperComponent } from './self-service-portal/components/self-stepper/self-stepper.component';
import { SelfFaqComponent } from './self-service-portal/components/self-faq/self-faq.component';
import { SelfDetailsComponent } from './self-service-portal/components/self-details/self-details.component';

@NgModule({
    declarations: [
        ClaimsCustomComponent,
        StartComponent,
        TupssEditComponent,
        tupssDetailComponent,
        ShipmentDetailsComponent,
        TFCPAuthComponent,
        FranchiseStartComponent,
        AchComponent,
        UpdateClaimPaymentRecipientPopupComponent,
        SiteSelectionComponent,
        PoliciesComponent,
        DetailVerificationComponent,
        FaqComponent,
        DamageMissingPartsComponent,
        ConfirmPackageStatusComponent,
        DeliveryStatusComponent,
        PaymentLandingComponent,
        ClaimsLandingComponent,
        CongratulationsComponent,
        CommoditiesComponent,
        SearchMyPackageComponent,
        EditContinueSearchPackageComponent,
        PolicySelectComponent,
        TermsOfAgreementComponent,
        CustomizedLandingComponent,
        FaqsComponent,
        SelfEditComponent,
        SelfStepperComponent,
        SelfFaqComponent,
        SelfDetailsComponent
    ],
    imports: [
        MatCheckboxModule,
        CommonModule,
        SharedModule,
        NotificationBoxModule,
        NewNotificationBoxModule,
        IdentityLayoutModule,
        TuppsStepperModule,
        Stepper2Module,
        TUPSSRoutingModule,
        TupssLayoutModule,
        CollapsiblePanelModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatDialogModule,
        CollapsiblePanelLightModule,
        DynamicLayoutModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: ENV.environment.recaptcha.siteKey } as RecaptchaSettings
        },        
        TranslatePipe
    ]
})
export class CustomClaimsModule { }
