<a href="https://upscapital.com/" *ngIf="!isCanadaUser" target="_blank" rel="noopener noreferrer">
  <img class="logo"  src="../../../../assets/logos/ca_us/InsureShield_Logo_226x79_larger_tagline-01.png" alt="{{ 'billing.method.altUps' | translate }}">
</a>
<a href="https://upscapital.com/ca-en/" *ngIf="isCanadaUser" target="_blank" rel="noopener noreferrer">
  <img class="logo" src="../../../../assets/logos/ca_us/UPSC_InsureShield_Horizontal CANADA_226x79_72dpi.png" alt="{{ 'billing.method.altUps' | translate }}">
</a>
<!-- <a href="https://upscapital.com/gb-en/" *ngIf="isUkUser" target="_blank" rel="noopener noreferrer">
  <img class="logoEurope" src="../../../../assets/logos/us_eu/InsureShield_horizontal_UPSCIA.png" alt="{{ 'billing.method.altUps' | translate }}">
</a>
<a href="https://upscapital.com/de-en/" *ngIf="isGermanyUser" target="_blank" rel="noopener noreferrer">
  <img class="logoEurope" src="../../../../assets/logos/us_eu/InsureShield_horizontal_UPSCIA.png" alt="{{ 'billing.method.altUps' | translate }}">
</a>
<a href="https://upscapital.com/fr-en/" *ngIf="isFranceUser" target="_blank" rel="noopener noreferrer">
  <img class="logoEurope" src="../../../../assets/logos/us_eu/InsureShield_horizontal_UPSCIA.png" alt="{{ 'billing.method.altUps' | translate }}">
</a>
<a href="https://upscapital.com/it-en/" *ngIf="isItalyUser" target="_blank" rel="noopener noreferrer">
  <img class="logoEurope" src="../../../../assets/logos/us_eu/InsureShield_horizontal_UPSCIA.png" alt="{{ 'billing.method.altUps' | translate }}">
</a> -->
<div class="welcome" *ngIf="!isGuestClaim">
  <table>
    <tr *ngIf="isShopifyUser">
      <td><span>{{ 'autoAuth.welcome' | translate }}, {{firstName | titlecase}} </span></td>
    </tr>
    <tr *ngIf="!isShopifyUser">
      <td>
        <span>{{ 'app.header.welcome' | translate }}, {{firstName | titlecase}} </span>
      </td>
      <td><span class="divider">|</span></td>
      <td>
        <mat-list-item>
          <button class="align welcome" #clickMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="clickMenu">
            {{ 'app.header.account' | translate }}
            <img *ngIf="clickMenuTrigger.menuOpen" class="arrow_down" src="../../../../assets/images/arrow-up.png" alt="{{ 'billing.method.menuOpens' | translate }}">
            <img *ngIf="!clickMenuTrigger.menuOpen" class="arrow_down" src="../../../../assets/images/arrow-down.png" alt="{{ 'billing.method.menuCloses' | translate }}">
          </button>
          <button class="small background" mat-icon-button [matMenuTriggerFor]="matMenu" #matMenuTrigger="matMenuTrigger">
            <mat-icon *ngIf="matMenuTrigger.menuOpen">close</mat-icon>
            <mat-icon *ngIf="!matMenuTrigger.menuOpen">menu</mat-icon>
          </button>
          <!-- Immediate below part is desktop -->
          <mat-menu overlapTrigger="false" #clickMenu="matMenu" class="position">
            <button [hidden]="!showRLSMenuItems" *ngIf="!isAdjuster && !isDdUser" mat-menu-item routerLink='/dashboard' (click)="tagging('dashboard')">{{ 'app.header.accountitems.dashboard' | translate }}</button>
            <button [hidden]="!showRLSMenuItems" *ngIf="(!isAdjuster) && !isDdUser" mat-menu-item routerLink='/profile' (click)="tagging('profile')">{{ 'app.header.accountitems.profile' | translate }}</button>
            <button [hidden]="!showRLSMenuItems" *ngIf="((!isAdjuster) && (isUsaUser) && (canManageUsers)) && !isDdUser" mat-menu-item routerLink='/manage-users'>{{ 'app.header.accountitems.manage' | translate }}</button>
            <button [hidden]="!showRLSMenuItems" *ngIf="canChangePolicy" mat-menu-item  (click)="resetPolicy()">Change Policy</button>
            <button *ngIf="!isDdUser" mat-menu-item (click)="logout($event)" [class.border-top]="showRLSMenuItems">{{ 'app.header.accountitems.logout' | translate }}</button>
            <button *ngIf="isDdUser" mat-menu-item (click)="logout($event)">{{ 'app.header.accountitems.logout' | translate }}</button>
          </mat-menu>
          <!-- Below part is mobile-only -->
          <mat-menu overlapTrigger="false" #matMenu="matMenu">
            <button [hidden]="!showRLSMenuItems" *ngIf="!isAdjuster" mat-menu-item routerLink='/dashboard' (click)="tagging('dashboard')">{{ 'app.header.accountitems.dashboard' | translate }}</button>
            <button [hidden]="!showRLSMenuItems" *ngIf="(!isAdjuster)" mat-menu-item routerLink='/profile' (click)="tagging('profile')">{{ 'app.header.accountitems.profile' | translate }}</button>
            <button [hidden]="!showRLSMenuItems" *ngIf="((!isAdjuster) && (isUsaUser) && (canManageUsers))" mat-menu-item routerLink='/manage-users'>{{ 'app.header.accountitems.manage' | translate }}</button>
            <button [hidden]="!showRLSMenuItems" *ngIf="canChangePolicy" mat-menu-item (click)="resetPolicy()">Change Policy</button>
            <button mat-menu-item (click)="logout($event)" [class.border-top]="showRLSMenuItems">{{ 'app.header.accountitems.logout' | translate }}</button>
          </mat-menu>
        </mat-list-item>
      </td>
    </tr>
  </table>
</div>
