<div class="d-flex flex-column-reverse mb-3 mb-lg-4">
  <!--  new footer start -->
  <div id="divFooter" [ngClass]="{'pirateShipBackground' : pirateShipFlow}">
    <div class="footer-wrap-left mt-3">
      <!-- desktop -->
      <div class="footer-align">
        <div class="break"></div>
        <!--  USA footer start -->
        <div class="footer-link" *ngIf="isUsaUser">
          <a href="http://www.ups.com/content/us/en/resources/ship/terms/use.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUsaUser">
          <a href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank">
            {{'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUsaUser">
          <a href=" https://www.insureshield.com/us/en/legal/product-disclosure.html" target="_blank">
            {{ 'app.footer.links.product' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUsaUser">
          <a href="http://www.ups.com/content/us/en/resources/ship/terms/california_privacy.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.privacyRights' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUsaUser">
          <a href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUsaUser">
          <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
            {{ 'app.footer.cookies' | translate }}
          </a>
        </div>
        <div class="footer-link" *ngIf="isUsaUser">
          <a href="https://upscapital.com/opt-out/" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.sell' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <!-- // Canada footer  Start -->
        <div class="footer-link" *ngIf="isCanadaUser">
          <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
            {{ 'app.footer.cookies' | translate }}
          </a>
        </div>
        <div class="footer-link" *ngIf="isCanadaUser">
          <a href="https://www.insureshield.com/ca/en/legal/technology-agreement.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isCanadaUser">
          <a href="https://www.ups.com/ca/en/support/shipping-support/legal-terms-conditions.page" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isCanadaUser">
          <a href="https://www.insureshield.com/ca/en/legal/privacy-notice.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isCanadaUser">
          <a href="https://www.insureshield.com/ca/en/legal/product-disclosure.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.product' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <!-- // Europe footer  UK-->
        <div class="footer-link" *ngIf="isUkUser">
          <a href="https://www.ups.com/gb/en/help-center/legal-terms-conditions/website.page" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUkUser">
          <a href="https://www.insureshield.com/gb/en/legal/technology-agreement.html" target="_blank">
            {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUkUser">
          <a href="https://www.insureshield.com/gb/en/legal/product-disclosure.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUkUser">
          <a href="https://www.insureshield.com/gb/en/legal/complaints.html" target=" _blank">
            {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUkUser">
          <a href="https://www.insureshield.com/gb/en/legal/privacy-notice.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isUkUser">
          <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
            {{ 'app.footer.cookies' | translate }}
          </a>
        </div>
        <!--// Europe footer Germany -->
        <div class="footer-link" *ngIf="isGermanyUser">
          <a href="{{ determsOfuse }}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isGermanyUser">
          <a href="{{ deTechAgreementLink}}" target="_blank">
            {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isGermanyUser">
          <a href="{{deproductDisclosures}}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isGermanyUser">
          <a href="{{ deComplints }}" target=" _blank">
            {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isGermanyUser || isDeEnglish">
          <a href="{{ deImpressum }}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.impressum' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isGermanyUser">
          <a href="{{ deprivacyNotice }}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isGermanyUser">
          <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
            {{ 'app.footer.cookies' | translate }}
          </a>
        </div>
        <!--// Europe footer France -->
        <div class="footer-link" *ngIf="isFranceUser">
          <a href="{{ frtermsOfuse }}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isFranceUser">
          <a href="{{ frTechAgreementLink }}" target="_blank">
            {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isFranceUser">
          <a href="{{ frproductDisclosures }}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isFranceUser">
          <a href="{{ frComplints }}" target=" _blank">
            {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isFranceUser">
          <a href="{{ frprivacyNotice }}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isFranceUser">
          <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
            {{ 'app.footer.cookies' | translate }}
          </a>
        </div>
        <!--// Europe footer Italy -->
        <div class="footer-link" *ngIf="isItalyUser">
          <a href="{{ ittermsOfuse }}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isItalyUser">
          <a href="{{ itTechAgreementLink}}" target="_blank">
            {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isItalyUser">
          <a href="{{ itproductDisclosures }}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isItalyUser">
          <a href="{{ itComplints }}" target=" _blank">
            {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>

        <div class="footer-link" *ngIf="isItalyUser">
          <a href="{{ itprivacyNotice }}" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link" *ngIf="isItalyUser">
          <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
            {{ 'app.footer.cookies' | translate }}
          </a>
        </div>
        <div class="footer-link" *ngIf="isUsaUser && pirateShipFlow">
          {{ 'app.footer.pirateText' | translate }}
        </div>
      </div>


      <!-- mobile -->
        <div class="footer-align-mobile">
          <!-- mobile USA footer start -->
          <div class="footer-link-mobile" *ngIf="isUsaUser">
            <a href="http://www.ups.com/content/us/en/resources/ship/terms/use.html" target="_blank"
              rel="noopener noreferrer">
              {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUsaUser">
            <a href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank">
              {{'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUsaUser">
            <a href="https://www.insureshield.com/us/en/legal/product-disclosure.html" target="_blank">
              {{ 'app.footer.links.product' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUsaUser">
            <a href="http://www.ups.com/content/us/en/resources/ship/terms/california_privacy.html" target="_blank"
              rel="noopener noreferrer">
              {{ 'app.footer.links.privacyRights' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUsaUser">
            <a href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank"
              rel="noopener noreferrer">
              {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUsaUser">
            <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
              {{ 'app.footer.cookies' | translate }}
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUsaUser">
            <a href="https://upscapital.com/opt-out/" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.sell' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <!-- //Canada Footer start -->
          <div class="footer-link-mobile" *ngIf="isCanadaUser">
            <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
              {{ 'app.footer.cookies' | translate }}
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isCanadaUser">
            <a href="{{ techAgreementLink }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.techAgreement' | translate }}
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isCanadaUser">
            <a href="http://www.ups.com/content/us/en/resources/ship/terms/use.html" target="_blank"
              rel="noopener noreferrer">
              {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isCanadaUser">
            <a href="{{ canadaprivacyLink }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.privacyNotice' | translate }}
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isCanadaUser">
            <a href="{{ canadaproductLink }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.product' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isCanadaUser">
            <a href="https://www.ups.com/ppwa/doWork?loc=en_US" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.sell' | translate }}
            </a>
          </div>
          <!-- // Europe footer  UK-->
          <div class="footer-link-mobile" *ngIf="isUkUser">
            <a href="https://www.ups.com/gb/en/help-center/legal-terms-conditions/website.page" target="_blank"
              rel="noopener noreferrer">
              {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUkUser">
            <a href="https://www.insureshield.com/gb/en/legal/technology-agreement.html" target="_blank">
              {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUkUser">
            <a href="https://www.insureshield.com/gb/en/legal/product-disclosure.html" target="_blank"
              rel="noopener noreferrer">
              {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUkUser">
            <a href="https://www.insureshield.com/gb/en/legal/complaints.html" target=" _blank">
              {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUkUser">
            <a href="https://www.insureshield.com/gb/en/legal/privacy-notice.html" target="_blank"
              rel="noopener noreferrer">
              {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUkUser">
            <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
              {{ 'app.footer.cookies' | translate }}
            </a>
          </div>
          <!--// Europe footer Germany -->
          <div class="footer-link-mobile" *ngIf="isGermanyUser">
            <a href="{{ determsOfuse }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isGermanyUser">
            <a href="{{ deTechAgreementLink}}" target="_blank">
              {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isGermanyUser">
            <a href="{{deproductDisclosures}}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isGermanyUser">
            <a href="{{ deComplints }}" target=" _blank">
              {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isGermanyUser">
            <a href="{{ deImpressum }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.impressum' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isGermanyUser">
            <a href="{{ deprivacyNotice }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isGermanyUser">
            <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
              {{ 'app.footer.cookies' | translate }}
            </a>
          </div>
          <!--// Europe footer France -->
          <div class="footer-link-mobile" *ngIf="isFranceUser">
            <a href="{{ frtermsOfuse }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isFranceUser">
            <a href="{{ frTechAgreementLink }}" target="_blank">
              {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isFranceUser">
            <a href="{{ frproductDisclosures }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isFranceUser">
            <a href="{{ frComplints }}" target=" _blank">
              {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isFranceUser">
            <a href="{{ frprivacyNotice }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isFranceUser">
            <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
              {{ 'app.footer.cookies' | translate }}
            </a>
          </div>
          <!--// Europe footer Italy -->
          <div class="footer-link-mobile" *ngIf="isItalyUser">
            <a href="{{ ittermsOfuse }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isItalyUser">
            <a href="{{ itTechAgreementLink}}" target="_blank">
              {{ 'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isItalyUser">
            <a href="{{ itproductDisclosures }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.supplyChain' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isItalyUser">
            <a href="{{ itComplints }}" target=" _blank">
              {{ 'app.footer.links.complaints' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>

          <div class="footer-link-mobile" *ngIf="isItalyUser">
            <a href="{{ itprivacyNotice }}" target="_blank" rel="noopener noreferrer">
              {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isItalyUser">
            <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
              {{ 'app.footer.cookies' | translate }}
            </a>
          </div>
          <div class="footer-link-mobile" *ngIf="isUsaUser && pirateShipFlow" style="text-align: left;">
            {{ 'app.footer.pirateText' | translate }}
          </div>
        </div>
      <div class="copyright-left">
        {{ 'app.footer.copyright' | translate:{ year: thisYear } }}
      </div>
      <div class="copyright-mobile">
        {{ 'app.footer.copyright' | translate:{ year: thisYear } }}
      </div>
    </div>
  </div>
  <div class="disclaimer-wrap pb-3" *ngIf="isCanadaUser && disclaimerOnload">
    <div class=" d-flex align-items-center flex-sm-row flex-wrap">
      <div class="disclaimer">
        <div>
          <b>{{ 'app.footer.canadaDisclaimer.title' | translate }}</b>
          <span>{{ 'app.footer.canadaDisclaimer.disclaimer' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="disclaimer-wrap-mobile pb-3" *ngIf="isCanadaUser && disclaimerOnload">
    <div class=" d-flex align-items-center flex-sm-row flex-wrap">
      <div class="disclaimer">
        <div>
          <b>{{ 'app.footer.canadaDisclaimer.title' | translate }}</b>
          <span>{{ 'app.footer.canadaDisclaimer.disclaimer' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- new footer end -->
</div>