<div class="tupss-contact-popup wrap p-3">
  <div class="title-block">
    <mat-icon (click)="close()">close</mat-icon>
    <br>
    <div class="title d-flex justify-content-center" mat-dialog-title *ngIf="page===1">{{ 'support.dialog.title' |
      translate }}</div>
  </div>

  <div class="page1" *ngIf="page===1">
    <div class="d-flex flex-column px-0">
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div class="block">
          <div class="subtitle">{{ 'support.dialog.subtitle1' | translate }}</div>
          <mat-form-field required [floatLabel]="'never'">
            <mat-select formControlName="topic" placeholder="{{ 'support.dialog.placeholder1' | translate }}">
              <mat-option *ngFor="let topic of topicListTCCP" [value]="topic.key">{{topic.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- TFCP post filing trackingNumber -->
        <div class="block" *ngIf="contactType=='tfcp'">
          <div class="subtitle">{{ 'support.dialog.optSubtitle1-1' | translate }}*</div>
          <mat-form-field>
            <input matInput formControlName="trackingNumber" (blur)="getShipmentDetails()">
          </mat-form-field>
        </div>
        <!-- TCCP pre filing trackingNumber -->
        <div class="block" *ngIf="contactType=='tccp' && !claimData">
          <div class="subtitle">{{ 'support.dialog.optSubtitle1-1' | translate }}*</div>
          <mat-form-field>
            <input matInput formControlName="trackingNumber" (blur)="getShipmentDetails()">
          </mat-form-field>
        </div>
        <!-- TCCP Post filing ClaimsNumber -->
        <div class="block" *ngIf="contactForm.get('topic').value===2 && contactType=='tccp' && claimData">
          <div class="subtitle">{{ 'support.dialog.subtitle5' | translate }}</div>
          <mat-form-field class="read-only">
            <input matInput readonly value="{{claimData.claimNumber}}">
          </mat-form-field>
        </div>
        <div class="block" *ngIf="contactForm.controls.topic.value===1 && contactType=='tccp' && claimData">
          <div class="subtitle">{{ 'support.dialog.subtitle5' | translate }}</div>
          <mat-form-field class="read-only">
            <input matInput readonly value="{{claimData.claimNumber}}">
          </mat-form-field>
        </div>
        <div class="notifications mb-3">
          <!-- TCCP Post-Claim Flow (Mainly claim statuses) -->
          <div *ngIf="contactType=='tccp' && claimData">
            <!-- TCCP Claim Status Warnings -->
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1 && claimData && claimData.customerDisplayStatus == 'Claim Submitted'" class="warning-box" [class.hide]="submitted">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">{{ 'tupssContact.submitted' | translate }}</div>
                </div>
                <div class="close-icon" (click)="submitted=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1 && claimData && claimData.customerDisplayStatus == 'Claim Received'" class="warning-box" [class.hide]="submitted">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">{{ 'tupssContact.submitted' | translate }}</div>
                </div>
                <div class="close-icon" (click)="submitted=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1 && claimData && claimData.customerDisplayStatus == 'Documents Pending'" class="warning-box" [class.hide]="documents">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">{{ 'tupssContact.pending' | translate }}</div>
                </div>
                <div class="close-icon" (click)="documents=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1 && claimData && claimData.customerDisplayStatus == 'Investigation Pending'" class="warning-box" [class.hide]="investigation">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">{{ 'tupssContact.investigation' | translate }}</div>
                </div>
                <div class="close-icon" (click)="investigation=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1 && claimData && claimData.customerDisplayStatus == 'Denied'" class="warning-box" [class.hide]="denied">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">{{ 'tupssContact.denied' | translate }}</div>
                </div>
                <div class="close-icon" (click)="denied=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1 && claimData && claimData.customerDisplayStatus == 'Submitted for Payment'" class="warning-box" [class.hide]="submittedForPayment">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">{{ 'tupssContact.submittedForPayment' | translate }}</div>
                </div>
                <div class="close-icon" (click)="submittedForPayment=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1 && claimData && claimData.customerDisplayStatus == 'Submitted for Payment' && iShipData?.coverageType==0" class="warning-box" [class.hide]="sfpCarrierLiability">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">{{ 'tupssContact.carrierLiability' | translate }}
                    <a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page" target="_blank">{{ 'tupssContact.tariffs' | translate }}</a>
                    </div>
                </div>
                <div class="close-icon" (click)="sfpCarrierLiability=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1 && claimData && claimData.customerDisplayStatus == 'Paid'" class="warning-box" [class.hide]="paid">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">
                    {{ 'tupssContact.paid' | translate }}
                    {{ claimData.checks?.length > 0 ? claimData.checks[0].issueDate : ''}}
                    {{ 'tupssContact.paid2' | translate }}
                  </div>
                </div>
                <div class="close-icon" (click)="paid=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1 && claimData && claimData.customerDisplayStatus == 'Paid'" class="warning-box" [class.hide]="paid">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">{{ 'tupssContact.declaredValue1' | translate }}${{ iShipData?.declaredValue }} {{ 'tupssContact.declaredValue2' | translate }}{{ 'tupssContact.declaredValue3' | translate }}<a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page" target="_blank">{{ 'tupssContact.tariffs2' | translate }}</a>.</div>
                </div>
                <div class="close-icon" (click)="paid=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
            <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===2" class="warning-box" [class.hide]="coverage">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="icon-warning-wrapper p-1 d-flex">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text">{{ 'tupssContact.coverage' | translate }} ${{ iShipData?.declaredValue }}  {{ 'tupssContact.coverage2' | translate }}<a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page" target="_blank">{{ 'tupssContact.declaredValue3' | translate }}</a>.</div>
                </div>
                <div class="close-icon" (click)="paid=true">
                  <mat-icon>cancel</mat-icon>
                </div>
              </div>
            </upsc-notification-box>
          </div>
          <!-- Regardless of tupss Type and regardless of flow. Most of the warnings go here. -->
          <upsc-notification-box [isWarning]="true" class="warning-box" *ngIf="contactForm.get('topic').value===3" [class.hide]="maintenance">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">{{ 'tupssContact.maintenance' | translate }}</div>
              </div>
              <div class="close-icon" (click)="maintenance=true">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="contactForm.get('topic').value===1" class="warning-box" [class.hide]="FAQ">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">
                  {{ 'tupssContact.FAQ1' | translate }}
                  <a href="#" (click)="openFaqs($event)">{{ 'tupssContact.FAQ2' | translate }}</a>
                  {{ 'tupssContact.FAQ3' | translate }}
                </div>
              </div>
              <div class="close-icon" (click)="FAQ=true">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="noData" class="warning-box" [class.hide]="!noData">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">
                  {{ 'tupssContact.noData1' | translate }}
                  <a href="https://www.ups.com/us/en/support/file-a-claim.page" target="_blank">{{ 'tupssContact.noData2' | translate }}</a>
                </div>
              </div>
              <div class="close-icon" (click)="noData=false">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="duplicateTrackingNumber" class="warning-box" [class.hide]="!duplicateTrackingNumber">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">
                  {{ 'tupssContact.duplicate1' | translate }}
                  <a href="#" (click)="openFaqs($event)">{{ 'tupssContact.FAQ2' | translate }}</a>
                  {{ 'tupssContact.duplicate2' | translate }}
                </div>
              </div>
              <div class="close-icon" (click)="duplicateTrackingNumber=false">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="invalidTrackNoFormat" class="warning-box" [class.hide]="!invalidTrackNoFormat">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">{{ 'tupssContact.invalid' | translate }}</div>
              </div>
              <div class="close-icon" (click)="invalidTrackNoFormat=false">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="noLongerEligible" class="warning-box" [class.hide]="!noLongerEligible">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">
                  {{ 'tupssContact.ineligible' | translate }}
                  <a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page" target="_blank">{{ 'tupssContact.tariffs' | translate }}</a>.
                </div>
              </div>
              <div class="close-icon" (click)="noLongerEligible = true">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="declaredValue" class="warning-box" [class.hide]="!declaredValue">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">{{ 'tupssContact.declaredValue1' | translate }} ${{ iShipData.declaredValue }}.
                </div>
              </div>
              <div class="close-icon" (click)="declaredValue=false">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="carrierLiability" class="warning-box" [class.hide]="!carrierLiability">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">{{ 'tupssContact.carrierLiability1' | translate }}
                  <!-- <a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page" target="_blank">{{ 'tupssContact.tariffs' | translate }}</a> -->
                  </div>
              </div>
              <div class="close-icon" (click)="carrierLiability=false">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="not1ZAndNoData" class="warning-box" [class.hide]="!not1ZAndNoData">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">{{ 'tupssContact.not1Z' | translate }}</div>
              </div>
              <div class="close-icon" (click)="not1ZAndNoData=false">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="billingRecipient" class="warning-box" [class.hide]="!billingRecipient">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">{{ 'tupssContact.billingRecipient' | translate }}
                  <a href="https://www.ups.com/us/en/support/file-a-claim.page" target="_blank">{{ 'tupssContact.ups' | translate }}</a>.
                </div>
              </div>
              <div class="close-icon" (click)="billingRecipient=false">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="billingThirdParty" class="warning-box" [class.hide]="!billingThirdParty">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">
                  {{ 'tupssContact.billingThirdParty' | translate }}
                  <a href="https://www.ups.com/us/en/support/file-a-claim.page" target="_blank">{{ 'tupssContact.ups' | translate }}</a>.
                </div>
              </div>
              <div class="close-icon" (click)="billingThirdParty=false">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" *ngIf="BMA" class="warning-box" [class.hide]="!BMA">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">
                  {{ 'tupssContact.billingThirdParty' | translate }}
                  <a href="https://www.ups.com/us/en/support/file-a-claim.page" target="_blank">{{ 'tupssContact.ups' | translate }}</a>.
                </div>
              </div>
              <div class="close-icon" (click)="BMA=false">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
        </div>
        
        <div class="block align-self-center">
          <div class="subtitle">{{ 'support.dialog.subtitle2' | translate }}*</div>
          <mat-form-field>
            <textarea matInput formControlName="description" matTextareaAutosize matAutosizeMinRows=5
              matAutosizeMaxRows=5 maxlength="250"></textarea>
          </mat-form-field>
        </div>
        <div class="block" *ngIf="contactType=='tfcp'">
          <div class="subtitle">{{ 'support.dialog.subtitle6' | translate }}</div>
          <mat-form-field [ngClass]="{'read-only': siteIds?.length==1}">
            <input *ngIf="siteIds?.length==1" formControlName="siteIdSelection" matInput readonly value="{{ siteIds[0] }}">
            <input matInput formControlName="siteIdSelection" *ngIf="siteIds.length==0">
            <mat-select formControlName="siteIdSelection" *ngIf="siteIds.length>1">
              <mat-option *ngFor="let siteId of siteIds" [value]="siteId">{{siteId}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="block align-self-center">
          <div class="subtitle">{{ 'support.dialog.subtitle3' | translate }}</div>
          <mat-form-field required>
            <input matInput formControlName="name">
          </mat-form-field>
        </div>
        <div class="block align-self-center">
          <div class="subtitle mb-2">{{ 'support.dialog.subtitle4' | translate }}*</div>
          <mat-radio-group required formControlName="contact">
            <div class="radio-group-wrapper d-flex flex-column flex-md-row">
              <div class="radio-button-wrapper">
                <mat-radio-button class="mb-1" value="phone">{{ 'support.dialog.radio1' | translate }}
                </mat-radio-button>
                <div class="method-wrapper">
                  <mat-form-field *ngIf="contactForm.get('contact').value==='phone'">
                    <input matInput required upscPhoneNumber maxlength="12" minlength="12" formControlName="phone">
                    <mat-error *ngIf="contactForm.get('phone').errors">
                      <div *ngIf="contactForm.get('phone').errors['required']">
                        {{ 'claims.edit.claimrecipient.errors.phn' | translate }}
                      </div>
                      <div *ngIf="contactForm.get('phone').errors['pattern'] || contactForm.get('phone').errors['minlength']">
                        {{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.invalid' | translate }}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="radio-button-wrapper">
                <mat-radio-button value="email">{{ 'support.dialog.radio2' | translate }}</mat-radio-button>
                <div class="method-wrapper">
                  <mat-form-field *ngIf="contactForm.get('contact').value==='email'">
                    <input matInput required formControlName="email">
                    <mat-error *ngIf="contactForm.get('email').errors">
                      <div *ngIf="contactForm.get('email').errors['required']">
                        {{ 'claims.edit.claimrecipient.errors.email' | translate }}
                      </div>
                      <div *ngIf="contactForm.get('email').errors['email']">
                        {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valid' | translate }}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </form>
    </div>
    <mat-dialog-actions class="d-flex flex-column flex-md-row-reverse justify-content-md-center mb-md-2">
      <div class="submit py-2">
        <button type="submit" [disabled]="!contactForm.valid || processing" (click)="onSubmit()">
          {{ 'support.dialog.submit' | translate }}
        </button>
      </div>
      <div class="cancel py-2">
        <button class="cancel-button" (click)="close()">
          {{ 'support.dialog.cancel' | translate }}
        </button>
      </div>
    </mat-dialog-actions>
  </div>

  <div class="page2" *ngIf="page===2">
    <mat-dialog-content class="d-flex flex-column justify-content-center">
      <div class="text align-self-center">{{ 'support.dialog.confirm.1' | translate }}</div>
      <div class="text align-self-center">{{ 'support.dialog.confirm.2' | translate }}</div>
      <div class="text align-self-center">{{ 'support.dialog.confirm.3' | translate }}</div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex flex-column">
      <div class="single-close my-2"><button (click)="close()">{{ 'support.dialog.close' | translate }}</button></div>
    </mat-dialog-actions>
  </div>
</div>