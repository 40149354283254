import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { HeaderComponent } from './header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule
  ],
  declarations: [HeaderComponent],
  providers: [],
  exports: [HeaderComponent],
})
export class HeaderModule {}
