<div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
    <div class="spinner-border">
        <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
    </div>
</div>

<div class="noPackageEligible" *ngIf="noPackageEligible && !showEmailVerification">
    <img src="../../../../../assets/images/noPackageEligible.PNG">
    <div class="separator"></div>
    <div class="heading">{{'myChoice.landingPage.noPackageEligible.weAreSorry' | translate }}</div>
    <div class="heading">{{'myChoice.landingPage.noPackageEligible.myChoicePAckage' | translate }}</div>
    <div class="heading">{{'myChoice.landingPage.noPackageEligible.noLongerEligible' | translate }}</div>
    <div class="heading">{{'myChoice.landingPage.noPackageEligible.coverage' | translate }}</div>
    <div class="separator-2"></div>
    <div class="heading-2">{{'myChoice.landingPage.noPackageEligible.packageProtection' | translate }}</div>
    <div class="heading-2">{{'myChoice.landingPage.noPackageEligible.myChoice' | translate }}</div>
    <div class="heading-2">{{'myChoice.landingPage.noPackageEligible.purchased' | translate }}</div>
</div>

<div class="noPackageEligible" *ngIf="coverageFound && !showEmailVerification">
    <img src="../../../../../assets/images/noPackageEligible.PNG">
    <div class="separator"></div>
    <div class="heading">{{'myChoice.landingPage.coverageFound.found' | translate }}</div>
    <div class="heading-2">{{'myChoice.landingPage.coverageFound.confirmationNumber' | translate }}{{confirmationNumber}}.</div>
</div>


<div id='targetSpinner' *ngIf="!noPackageEligible && !coverageFound && !showEmailVerification">
    <div class="my-choice-wrapper">
        <div class="gradient-box">
            <div class="title">{{'myChoice.landingPage.insureShield' | translate }}<sup>®</sup></div>
            <div class="title">{{'myChoice.landingPage.protection' | translate }} </div>
            <div class="title">{{'myChoice.landingPage.myChoice' | translate }}<sup>®</sup></div>
            <div class="yellow-bar"></div>
            <div class="text">{{'myChoice.landingPage.subtitle1' | translate }}<sup>®</sup>
                {{'myChoice.landingPage.subtitle2' | translate }}</div>
            <div class="image-box">
                <img src="../../../../../assets/images/mychoice_top.svg">
            </div>
        </div>
        <div class="marketing-hero">
            <img src="../../../../../assets/images/my-choice-mobile/marketing-hero.svg">
        </div>
        <div class="white-box">
            <div class="header">{{'myChoice.landingPage.peaceOfMind' | translate }}</div>
            <p></p>
            <div class="text">
                <div class="inner">{{'myChoice.landingPage.porchPiracy' | translate }}
                    {{'myChoice.landingPage.insureShield' | translate }}<sup>®</sup>
                    {{'myChoice.landingPage.porchPiracyProtection' | translate }}</div>
                <div class="inner">{{'myChoice.landingPage.porchPiracyProtection_bottom' | translate }}</div>
            </div>
        </div>
        <div class="bottom-box">
            <div class="left">
                <img src="../../../../../assets/images/mobile-person-new.svg">
            </div>

            <div class="right">
                <div class="header">
                    <img src="../../../../../assets/images/mychoice_header_tick.svg">
                    <h6>{{'myChoice.landingPage.rightHeader1' | translate }}</h6>
                </div>
                <div class="header-text">
                    {{'myChoice.landingPage.rightHeader1_text' | translate }}
                </div>

                <div class="header">
                    <img src="../../../../../assets/images/mychoice_header_tick.svg">
                    <h6>{{'myChoice.landingPage.rightHeader2' | translate }}</h6>
                </div>
                <div class="header-text">
                    {{'myChoice.landingPage.rightHeader2_text' | translate }}
                </div>

                <div class="header">
                    <img src="../../../../../assets/images/mychoice_header_tick.svg">
                    <h6>{{'myChoice.landingPage.rightHeader3' | translate }}</h6>
                </div>
                <div class="header-text">
                    {{'myChoice.landingPage.rightHeader3_text' | translate }}
                </div>

                <div class="commodities">
                    <div class="header-commodities">
                        {{'myChoice.landingPage.rightHeader4_text' | translate }}
                    </div>

                    <upsc-commodities [title]="'myChoice.landingPage.commodities' | translate" [isCollapsed]="true">
                        <span class="phone-wrap">
                            <span class="listing">{{'myChoice.landingPage.excluded_commodities' | translate}}</span>
                        </span>
                    </upsc-commodities>
                </div>
                <div class="total-value">
                    <span class="text">{{'myChoice.landingPage.total' | translate }}</span>
                </div>
            </div>
        </div>
        <form [formGroup]="protectPackageFormGroup">
            <div class="terms-and-conditions-top">
                <div class="terms-check-box">
                    <div class="checkbox">
                        <mat-checkbox formControlName="cbTermsOfServices"
                            class="d-block green-background-checkbox-rounded-black size-25"
                            (change)="showCheckedTermsOfServices($event)">
                        </mat-checkbox>
                    </div>
                </div>
                <div class="terms-check-box-text">{{'myChoice.landingPage.termsOfService1' |
                    translate }}<a href="https://www.insureshield.com/content/terms-and-conditions/my-choice.pdf"
                        target="_blank" class="condition" rel="noopener noreferrer">
                        {{ 'myChoice.landingPage.termsOfService' | translate
                        }}</a>{{'myChoice.landingPage.termsOfService2' | translate }}
                </div>
            </div>
           <!--  <div *ngIf="!isFormValid" class="error">
                Please accept terms of service before proceeding
            </div> -->
            <div *ngIf="systemError" class="error">
                {{'common.systemError' | translate}}
            </div>
            <div class="terms-and-conditions-button">
                <button type="submit" class="upsc-button" (click)="continue($event)" [disabled]="!isTermsChecked">
                    {{'myChoice.landingPage.protectPackage' | translate }}</button>
            </div>
        </form>
        <div class="disclaimer">
            {{'myChoice.landingPage.disclaimer' | translate }}
        </div>
    </div>

    <div class="my-choice-wrapper-mobile">
        <div class="gradient-box">
            <div class="image-box">
                <img src="../../../../../assets/images/my-choice-mobile/mobile-banner.svg">
                <div class="banner-text">
                    <div class="banner-content">
                        <div class="title">{{'myChoice.landingPage.insureShield' | translate }}<sup>®</sup></div>
                        <div class="title">{{'myChoice.landingPage.protectionFor' | translate }} </div>
                        <div class="title">{{'myChoice.landingPage.upsMymyChoice' | translate }}<sup>®</sup></div>
                        <div class="yellow-bar"></div>
                        <div class="text">{{'myChoice.landingPage.subtitle1' | translate }}<sup>®</sup>
                            {{'myChoice.landingPage.subtitle2' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="peace-of-mind">
            <div class="title">{{'myChoice.landingPage.peaceOfMind' | translate }}</div>
            <div class="yellow-bar"></div>
        </div>

        <div class="person-youtube">
            <div class="image-box">
                <img src="../../../../../assets/images/mobile-person-new.svg">
            </div>
            <div class="text">
                <div class="inner">{{'myChoice.landingPage.porchPiracy' | translate }}
                    {{'myChoice.landingPage.insureShield' | translate }}<sup>®</sup>
                    {{'myChoice.landingPage.porchPiracyProtection' | translate }}
                    {{'myChoice.landingPage.porchPiracyProtection_bottom' | translate }}
                </div>
            </div>
        </div>



        <div class="text-checkbox-information">
            <div class="header">
                <img src="../../../../../assets/images/mychoice_header_tick.svg">
                <h6>{{'myChoice.landingPage.rightHeader1' | translate }}</h6>
            </div>
            <div class="header-text">
                {{'myChoice.landingPage.rightHeader1_text' | translate }}
            </div>

            <div class="header">
                <img src="../../../../../assets/images/mychoice_header_tick.svg">
                <h6>{{'myChoice.landingPage.rightHeader2' | translate }}</h6>
            </div>
            <div class="header-text">
                {{'myChoice.landingPage.rightHeader2_text' | translate }}
            </div>

            <div class="header">
                <img src="../../../../../assets/images/mychoice_header_tick.svg">
                <h6>{{'myChoice.landingPage.rightHeader3' | translate }}</h6>
            </div>
            <div class="header-text">
                {{'myChoice.landingPage.rightHeader3_text' | translate }}
            </div>

            <div class="commodities">
                <div class="header-commodities">
                    {{'myChoice.landingPage.rightHeader4_text' | translate }}
                </div>
                <upsc-commodities [title]="'myChoice.landingPage.commodities' | translate" [isCollapsed]="true">
                    <span class="phone-wrap">
                        <span class="listing">{{'myChoice.landingPage.excluded_commodities' | translate}}</span>
                    </span>
                </upsc-commodities>
            </div>
        </div>

        <form [formGroup]="protectPackageFormGroup">
            <div class="terms-and-conditions-top">
                <div class="terms-check-box">
                    <div class="checkbox">
                        <mat-checkbox formControlName="cbTermsOfServices" [disabled]="systemError || showSpinner"
                            class="d-block green-background-checkbox-rounded-black size-25"
                            (change)="showCheckedTermsOfServices($event)">
                        </mat-checkbox>
                    </div>
                </div>
                <div class="terms-check-box-text">{{'myChoice.landingPage.termsOfService1' |
                    translate }}<a href="https://www.insureshield.com/content/terms-and-conditions/my-choice.pdf"
                        target="_blank" class="condition" rel="noopener noreferrer">
                        {{ 'myChoice.landingPage.termsOfService' | translate
                        }}</a>{{'myChoice.landingPage.termsOfService2' | translate }}
                </div>
            </div>
            <div *ngIf="systemError" class="error">
                {{'common.systemError' | translate}}
            </div>
            <div class="terms-and-conditions-button">
                <button type="submit" (click)="continue($event)" class="upsc-button" [disabled]="!isTermsChecked || this.systemError || showSpinner">
                    {{'myChoice.landingPage.protectPackage' | translate }}</button>
            </div>
        </form>
        <div class="disclaimer">
            {{'myChoice.landingPage.disclaimer' | translate }}
        </div>
    </div>
    </div>
    <div *ngIf="showEmailVerification" class="mb-5">
        <upsc-email-verification [flow]="'myChoice'" [email]="emailAddress" (canceled)="cancelVevify($event)" 
        (resend)="sendVerificationCode($event)" (verified)="afterVerification($event)"></upsc-email-verification>
    </div>

