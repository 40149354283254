import { Component, HostBinding, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { LoginService } from '../../../../services/login.service';
import { IPolicies } from '../../../../shared/models/policies.interface';
import { ClaimService } from '../../../../services/claim.service';
import { retry } from 'rxjs/operators';
import { IClaimsDetails, ClaimSummary, ClaimSummaryFormatted, listOfClaims, ISummary, ClaimStats } from '../../../../shared/models/claim.interface';
import { PagerService } from '../../../../services/pager.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DecimalPipe } from '@angular/common';
import { ExportComponent } from '../../dialogs/export/export.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyRadioGroup as MatRadioGroup } from '@angular/material/legacy-radio';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { columnDictionary, defaultClaimsTable, IClaims_Table, CLAIM_CLOSED_OUTCOME } from 'src/app/shared/models/claim-list.interface';
import { ClaimLayoutsService } from 'src/app/services/claim-layouts.service';
import { TupssService } from 'src/app/pages/claims-custom/tupss/services/tupss.service';
import { forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { environment as ENV } from 'src/environments/environment';
import { TaggingService } from 'src/app/tags/tagging.service';
import { UserPermission } from 'src/app/shared/models/user-permission';
import { AdvancedSearchPopupComponent } from 'src/app/shared/components/advanced-search-popup/advanced-search-popup.component';
import { Overlay } from '@angular/cdk/overlay';
import { ColumnSettingsComponent } from 'src/app/shared/components/column-settings/column-settings.component';
import { PolicyService } from 'src/app/services/policy.service';
import { BillingService } from 'src/app/services/billing.service';
@Component({
  selector: 'upsc-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss'],
})
export class ClaimListComponent implements OnInit {
  policyNumber: boolean = true;
  shipperName: boolean = true;
  shipperNumber: boolean = true;

  toggleColumn(column: string) {
    switch (column) {
      case 'policy':
        this.policyNumber = !this.policyNumber;
        break;
      case 'name':
        this.shipperName = !this.shipperName;
        break;
      case 'number':
        this.shipperNumber = !this.shipperNumber;
        break;
    }
  }
  //For testing
  private expandState = new Map<string, boolean>();
  public claimDetails: IClaimsDetails = {}
  public isCollapsed = false;
  public summary: ISummary = {};
  public policy: IPolicies;
  claimSummary: ClaimSummary[] = [];
  claimModelCollection: listOfClaims[];
  claim: ClaimSummary;
  claimLowerCase: ClaimSummaryFormatted;
  public PolicyNo: string;
  public policyType: string;
  public searchReceiver: any = "";
  public searchupsAccountNo: any = "";
  public lowerCaseClaimList: any[];
  public calendarBool: boolean = false;
  public amountBool: boolean = false;
  public isEnabled: boolean = false;
  public applysearchReceiver: boolean = false;
  public isValidReceiver: boolean = false;
  public applysearchUpsAccNo: boolean = false;
  public dataInObj: Object;
  public claimSummaryJson: any[] = [];

  claimStats: Observable<ClaimStats>;
  showClaimStats = false;
  hasDocPending = false;

  tableObj: IClaims_Table = defaultClaimsTable;
  userDetails;
  policyDetails;
  dwDetails;
  togglePolicyDetails;
  defaultColumns = [1, 2, 3, 5, 6, 8, 9, 11, 12, 14, 16];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatRadioGroup) category: MatRadioGroup;

  @ViewChild('searchButton') searchButton: ElementRef;

  @HostBinding('class') public hostClass = 'claim-list';
  @Input() isPagination: boolean = true;
  @Input() isDraft: boolean = true;
  @Input() isLayout: boolean = true;
  @Output() showDocNotification = new EventEmitter<boolean>(true);
  manualPage: number;
  pager: any = {};
  allClaimIds: string[];
  public currentClaim;
  pagedItems: any[];
  placeholderPayee: any;
  placeholderStatus: any;
  pageSize: number = 10;
  searchIsActive: boolean = false;

  searchTypeControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }, Validators.required,);
  searchReceiverControl: UntypedFormControl = new UntypedFormControl('', Validators.minLength(3));
  searchUpsAcControl: UntypedFormControl = new UntypedFormControl('', Validators.required);

  searchByValueControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: true }, Validators.compose([Validators.required]));
  categorySelected: boolean;
  queryEntered: boolean;
  emptySearchResult: boolean = false;
  emptyClaimResult: boolean = false; //Shows "No claim found" on List. Only happens when account has no claims on it.
  isClaimSearch: boolean = false;
  isACHSearch: boolean = false;
  isTrackingSearch: boolean = false;
  isSubmissionNumberSearch: boolean = false;
  isShipperNameSearch: boolean = false;
  isShipperNumberSearch: boolean = false;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isTupssUser: boolean;
  isDDUser: boolean;
  tupssSiteId: string;
  isInsureShield: boolean = false;
  isDraftData: boolean = false;
  searchBarPlaceholder: any;
  panelIsOpen: boolean = false;
  isChecked: boolean = false;
  isSearch: boolean = false;
  isActive: boolean = true;
  newClaimsSelect = [];
  loading: boolean = false;
  parentIndicater: boolean = false;
  EAffiliateUsers: boolean = false;
  canAccessDigitalWallet: boolean;

  searchBy = [
    { value: 'claimNumber', label: 'Claim #' },
    { value: 'fileDate', label: 'File Date' },
    { value: 'totalPaidAmount', label: 'Total Paid Amount' },
    { value: 'trackingNumber', label: 'Tracking #/BOL' },
    { value: 'referenceNumber', label: 'Reference #' },
    { value: 'receiver', label: 'Receiver' }
  ];

  columns = [
    { columnDef: 'claimNumber', cell: (element: ClaimSummary) => `${element.claimNumber}` },
    { columnDef: 'fileDate', cell: (element: ClaimSummary) => `${element.fileDate}` },
    { columnDef: 'claimStatus', cell: (element: ClaimSummary) => `${element.claimStatus}` },
    { columnDef: 'amount', cell: (element: ClaimSummary) => `${element.amount}` },
    { columnDef: 'tracking', cell: (element: ClaimSummary) => `${element.tracking}` },
    { columnDef: 'reference', cell: (element: ClaimSummary) => `${element.reference}` },
    { columnDef: 'receiver', cell: (element: ClaimSummary) => `${element.receiver}` },
  ];
  displayedColumns = this.columns.map(c => c.columnDef);

  presetSearch: string;

  insightLabels = ["Paid", "Unpaid"];
  insightOption = {
    responsive: false,
    legend: {
      position: "right",
      aligh: "center",
      labels: {
        boxWidth: 20,
        fontSize: 20,
        generateLabels: (chart) => {
          return chart.data.labels.map((label, i) => {
            let dataset = chart.data.datasets[0];
            return {
              text: (dataset.data[0] + dataset.data[1] == 0 ? "0" : _.round(dataset.data[i] / (dataset.data[0] + dataset.data[1]) * 100)) + `% ${label}`,
              fillStyle: dataset.backgroundColor[i]
            }
          })
        }
      }
    }
  }

  // advanced search fields
  claimClosedOutcome = [];
  advancedDate: [];
  searchFilters: any;
  dateFilterDisplay;
  amountFilterDisplay;
  statusFilterDisplay;
  receiverFilterDisplay;
  accountFilterDisplay;
  shipperNumberFilterDisplay;
  shipperNameFilterDisplay;
  dateFilterEnabled: boolean = false;
  amountFilterEnabled: boolean = false;
  statusFilterEnabled: boolean = false;
  receiverFilterEnabled: boolean = false;
  accountFilterEnabled: boolean = false;
  shipperNameFilterEnabled: boolean = false;
  shipperNumberFilterEnabled: boolean = false;
  allStatusArray = [];
  dateQuery = {};
  amountQuery = {};
  claimStatusQuery = [];
  claimOutcomeQuery = [];
  receiverQuery: any;
  accountQuery: any;

  // column settings field
  selectedColumns = [];

  chartHovered(event) { }
  chartClicked(event) { }

  toggleStats(event: any) {
    event.preventDefault();
    this.showClaimStats = !this.showClaimStats;
    this.resetInsights();
  }

  resetInsights() {
    // Check when showClaimStats is false- which means we just hid the insights cards
    if (!this.showClaimStats) { // If condition is met, reset the search if an insights search was active and unflip all cards
      if (this.resolvedActive || this.inProgressActive || this.docPendingActive) {
        this.reset();
      }

      this.resolvedActive = false;
      this.inProgressActive = false;
      this.docPendingActive = false;
    }
  }

  resolvedActive: boolean = false;
  docPendingActive: boolean = false;
  inProgressActive: boolean = false;
  apiInProgress: boolean = false;
  newCardClick(card: any) {
    if (card == 'resolved') {
      this.resolvedActive = !this.resolvedActive;
      this.inProgressActive = false;
      this.docPendingActive = false;
    } else if (card == 'in-progress') {
      this.resolvedActive = false;
      this.inProgressActive = !this.inProgressActive;
      this.docPendingActive = false;
    } else if (card == 'documents-pending') {
      this.resolvedActive = false;
      this.inProgressActive = false;
      this.docPendingActive = !this.docPendingActive;
    }

    this.apiInProgress = true; // as we perform a search and call claimids, disable user from clicking the card again until API is done
    if (this.resolvedActive) {
      let closedOutcomes = _.clone(CLAIM_CLOSED_OUTCOME);
      this.getClaimSummary({ claimClosedOutCome: _.filter(closedOutcomes, o => ['withdrawn_Ext', 'incomplete_documentation_Ext', 'mistake', 'duplicate'].indexOf(o) < 0) }, true, ['closed']);
    } else if (this.inProgressActive) {
      this.getInProgressClaims();
    } else if (this.docPendingActive) {
      this.getClaimSummary({
        claimSubStatus: ["IDR_Request_1", "IDR_Request_2", "IDR_Request_Final"],
        claimClosedOutCome: ["incomplete_documentation_Ext"]
      }, true, ["open", "closed"]);
    } else { //no card is active (remove all search filters)
      this.reset();
    }
  }

  getInProgressClaims() {
    forkJoin([this.claimservice.getAllClaimIds({
      claimStates: ["closed"],
      policyNumber: this.PolicyNo,
      claimClosedOutCome: ["incomplete_documentation_Ext"]
    }),
    this.claimservice.getAllClaimIds({
      claimStates: ["open"],
      policyNumber: this.PolicyNo,
      claimSubStatus: ["IDR_Request_1", "IDR_Request_2", "IDR_Request_Final", "Pending_Insurance_Carrier", "Report_to_transportation_Carrier", "Security_Investigation", "Submitted", "Submitted_for_Payment", "submitted_for_payment_to_crawford"],
    })]).subscribe(
      ids => {
        this.apiInProgress = false;
        this.allClaimIds = (_.union(ids[0].claimIds, ids[1].claimIds)).sort().reverse();
        this.setPage(1);
      }, error => {
        this.apiInProgress = false;
      }
    )
  }

  // Dashboard message, when user clicks they go to claims tab and it triggers advanced search automatically with documents pending pre-filtered
  showDocPending(event) {
    event.preventDefault();
    this.router.navigate(['claims'], { fragment: 'docPending' });
  }

  toggleRow(claimId: string) {
    this.expandState.set(claimId, !this.expandState.get(claimId));
  }

  getExpandStatus(claimId: string): boolean {
    let result: boolean;
    if (this.expandState.has(claimId)) {
      result = this.expandState.get(claimId);
    } else {
      result = false;
      this.expandState.set(claimId, result);
    }
    return result;
  }


  public userPermission: UserPermission = new UserPermission();
  constructor(
    private pagerService: PagerService,
    public dialog: MatDialog,
    public loginservice: LoginService,
    public claimservice: ClaimService,
    public userService: UserService,
    private ref: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private number: DecimalPipe,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private layoutService: ClaimLayoutsService,
    private tupssService: TupssService,
    private taggingService: TaggingService,
    private formBuilder: UntypedFormBuilder,
    public overlay: Overlay,
    private policyService: PolicyService,
    private billingService: BillingService
  ) {
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.isGuestUser = this.userService.isGuestUser();

    if (this.isTupssUser = this.userService.isTupssUser()) {
      this.isTupssUser = this.userService.isTupssUser();
      this.tupssSiteId = this.userService.getUserInfo().siteId;
    }
    this.isDDUser = this.userService.isDeliveryDefenseUser();

    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.policyDetails = this.loginservice.getPolicyDetails();
    this.dwDetails = this.loginservice.getDWDetails();
    this.userPermission = this.userService.getUserPermissions();
    this.canAccessDigitalWallet = this.userService.canAccessDigitalWallet();

    if (this.isUsaUser && !this.isTupssUser) {
      this.newClaimsSelect = [
        { value: '0', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.claimLabel') },
        { value: '1', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.checkAchLabel') },
        { value: '2', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.trackingNumberLabel') },
        { value: '3', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.submissionNumberLabel') }
      ];
      if (this.userService.isEAUserorNonEA()) {
        this.newClaimsSelect = [
          { value: '0', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.claimLabel') },
          { value: '1', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.checkAchLabel') },
          { value: '2', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.trackingNumberLabel') },
          { value: '3', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.submissionNumberLabel') },
          { value: '4', label: 'Shipper Name' },
          { value: '5', label: 'Shipper Number' }
        ];
      }
    } else if (this.isUsaUser && this.isTupssUser) {
      this.newClaimsSelect = [
        { value: '0', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.claimLabel') },
        { value: '1', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.checkAchLabel') },
        { value: '2', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.trackingNumberLabel') }
      ];
    } else if (this.isCanadaUser || this.isFranceUser || this.isGermanyUser || this.isUkUser || this.isItalyUser) {
      this.newClaimsSelect = [
        { value: '0', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.claimLabel') },
        { value: '1', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.checkAchLabel') },
        { value: '2', label: this.translateService.instant('claims.list.component.toolbar.defaultSearch.trackingNumberLabel') }
      ];
    }

    this.EAffiliateUsers = this.userService.isEAUserorNonEA();
    if (this.EAffiliateUsers) {
      this.parentIndicater = this.userService.isParentPolicy();
    }
  }

  refreshDraft(event) {

  }

  getDraftSummary(filter = {}, search?: boolean): void {
    this.loading = true;
    this.policy = this.loginservice.getPolicyDetails();
    this.summary.claimStates = ["draft"];
    this.summary.policyNumber = this.policy.policyNumber;

    this.claimservice.getAllClaimIds({ ...this.summary, ...filter }).subscribe(data => {
      if (data.maxNumberOfResults > 0) {
        this.isDraftData = true;
      } else {
        this.isDraftData = false;
      }
    });
    this.loading = false;
  }

  loadDraft(event) {
    if (event === true) {
      this.isDraftData = true;
    }
    else {
      this.isDraftData = false;
    }
  }

  setPager(event) {
    var currentPage = Number(event.toString());
    this.setPage(currentPage);
  }

  loadAssetPerPage(event) {
    this.pageSize = Number(event.toString());
    this.setPage(1);
  }


  setPage(page: number) {
    this.loading = true;
    this.pager = this.pagerService.getPager(this.allClaimIds.length, page, this.pageSize);
    let pageIds = [];

    if (this.isPagination) {
      pageIds = this.allClaimIds.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
    else {
      pageIds = this.allClaimIds.slice(this.pager.startIndex, (this.pager.endIndex > 4 ? 4 : this.pager.endIndex) + 1);
    }

    this.claimservice.getClaimByIds(this.policy.policyNumber, pageIds, this.userService.isParentPolicy()).subscribe(
      data => {
        this.apiInProgress = false;
        let unSortedClaims = data.items.map(claim => {
          if (claim.customerDisplayStatus == 'Documents Pending') {
            this.showDocNotification.emit(true);
          }
          let sliceTrack = claim.shippingDetails.trackingNumber.slice(0, 2);
          if (sliceTrack.toUpperCase() == '1Z') {
            claim.upsAccount = claim.shippingDetails.trackingNumber.slice(2, 8);
          }
          else { claim.upsAccount = null; }
          return {
            claimNumber: claim.claimNumber,
            fileDate: claim.fileDate,
            claimStatus: claim.customerDisplayStatus == 'Closed' && claim.claimStatus.closedOutCome == 'incomplete_documentation_Ext' ? 'Documents Pending' : claim.customerDisplayStatus, //If there is a CLOSED claim with INCOMPLETE_DOCUMENTATION_EXT, then we remap it to DOCUMENTS PENDING
            //Handling all language status below
            claimStatusDisplay: claim.customerDisplayStatus == 'Claim Received' ? this.translateService.instant('metadata.claimStatusMapping.claim_received') :
              (claim.customerDisplayStatus == 'Closed' && claim.claimStatus.closedOutCome == 'incomplete_documentation_Ext') ?
                this.translateService.instant('metadata.claimStatusMapping.incomplete_documentation_Ext') :
                claim.customerDisplayStatus == 'Closed' ? this.translateService.instant('metadata.claimStatusMapping.closed') :
                  claim.customerDisplayStatus == 'Paid' ? this.translateService.instant('metadata.claimStatusMapping.paid') :
                    claim.customerDisplayStatus == 'Withdrawn' ? this.translateService.instant('metadata.claimStatusMapping.withdrawn_Ext') :
                      claim.customerDisplayStatus == 'Submitted for Payment' ? this.translateService.instant('metadata.claimStatusMapping.submitted_for_payment') :
                        claim.customerDisplayStatus == 'Incomplete Submission' ? this.translateService.instant('metadata.claimStatusMapping.incomplete_submission') :
                          claim.customerDisplayStatus == 'Investigation Pending' ? this.translateService.instant('metadata.claimStatusMapping.investigation_Pending') :
                            claim.customerDisplayStatus == 'Denied' ? this.translateService.instant('metadata.claimStatusMapping.denied_Ext') :
                              claim.customerDisplayStatus == 'Documents Pending' ? this.translateService.instant('metadata.claimStatusMapping.incomplete_documentation_Ext') :
                                claim.customerDisplayStatus == 'pending_search_request' ? this.translateService.instant('metadata.claimStatusMapping.search_in_progress') : claim.customerDisplayStatus,
            statusDetails: this.translateService.instant(`metadata.claimStatusMapping.${claim.claimStatus.closedOutCome}`),
            amount: claim.totalPayments.amount == "0" ? "0" : claim.totalPayments.amount,
            tracking: claim.shippingDetails.trackingNumber,
            reference: claim.shippingDetails.referenceNumber,
            receiver: claim.shippingDetails.receiverDetails == null ? "" : claim.shippingDetails.receiverDetails.companyName,
            currency: claim.totalPayments.currency,
            filedClaimAmount_Currency: claim.claimAmount_Ext && claim.claimAmount_Ext.split(' ').length > 0 ? claim.claimAmount_Ext.split(' ')[1] : '',
            filedClaimAmount: claim.claimAmount_Ext == null ? "0" : claim.claimAmount_Ext.split(' ')[1] == "eur" ? claim.claimAmount_Ext.replace(' eur', '') : claim.claimAmount_Ext.split(' ')[1] == "gbp" ? claim.claimAmount_Ext.replace(' gbp', '') : claim.claimAmount_Ext.replace(' usd', '').replace(' cad', ''),
            checkAchNumber: claim.checkNumber.length == 0 ? null : claim.checkNumber[claim.checkNumber.length - 1],
            checkAchIssueDate: claim.checkIssueDate.length == 0 ? null : claim.checkIssueDate[claim.checkIssueDate.length - 1],
            upsAccount: claim.upsAccount,
            submissionNumber: claim.submissionNumber,
            adjuster: claim.adjuster ? claim.adjuster.displayName : '',
            //declaredValue: claim.declaredValue,
            declaredValue: claim.declaredValue == null ? "0" : claim.claimAmount_Ext.replace(' usd', ''),
            paymentRecipient: claim.payeeName,
            lossDate: claim.lossDate,
            shiperName: claim.shipperName, // For EA users only
            shiperNumber: claim.shipperNumber, // For EA users only
            claimSource: claim.claimSource ? (claim.claimSource.toLowerCase().includes('tfcp') ? 'Franchise' : (claim.claimSource.toLowerCase().includes('tccp') ? 'Customer' : "")) : "",
            policyNumber: claim.policyNumber
          }

        });
        let sortedClaims = [];
        pageIds.forEach(id => {
          let matchedClaim = unSortedClaims.find(c => c.claimNumber == id);
          if (matchedClaim) {
            sortedClaims.push(matchedClaim);
          }
        });
        this.pagedItems = sortedClaims;
        this.loading = false;
      },
      error => {
        this.pagedItems = [];
        this.loading = false;
        this.apiInProgress = false;
      }
    );
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 991px)');
  }

  get isScreenMedium() {
    return this.breakpointObserver.isMatched('(max-width: 992px)');
  }

  handleOpenChange() {
    this.panelIsOpen = !this.panelIsOpen;
  }

  openColumnSettings() {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.restoreFocus = true;
    config.position = { right: '0' };
    config.scrollStrategy = this.overlay.scrollStrategies.noop();

    let dialogRef = this.dialog.open(ColumnSettingsComponent, config);
    dialogRef.componentInstance.filterResult.subscribe(
      val => {
        this.selectedColumns = [];
        for (let i = 0; i < val.length; i++) {
          if (val[i].selected == true) {
            this.selectedColumns.push(val[i].value);
          }
        }
        this.updateLayout(this.selectedColumns);
      }
    );
  }

  openAdvanced() {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.restoreFocus = true;
    config.position = { right: '0' };
    config.data = {
      searchSource: 'claims',
      filters: this.searchFilters ? this.searchFilters : undefined
    };
    config.scrollStrategy = this.overlay.scrollStrategies.noop();

    let dialogRef = this.dialog.open(AdvancedSearchPopupComponent, config);
    dialogRef.componentInstance.searchResults.subscribe(
      searchResults => {
        this.searchFilters = searchResults.filters;
        this.clearDefaultSearchbar();
        this.populateFilterBubbles();
        this.search('advanced');
      }
    );

    dialogRef.componentInstance.removeFilter.subscribe(
      removeFilter => {
        if (removeFilter == 'date' || removeFilter == 'amount') {
          this.removeFilterBubbles(removeFilter);
        } else if (removeFilter == 'statusClaims') {
          this.removeFilterBubbles('status');
          this.removeFilterBubbles('receiver');
          this.removeFilterBubbles('account');
        } else if (removeFilter == 'all') {
          this.removeFilterBubbles('all');
        }
      }
    );
  }

  populateFilterBubbles() {
    // UI- logic on what to display to the user after they've selected filters and continued after the advanced search pop-up
    if (this.searchFilters.dateFilterSelected) {
      this.dateFilterEnabled = true;
      let locale = sessionStorage.getItem('locale');
      let filteredStartDate = new Date(this.searchFilters.dateFilterSelected.startDate);
      let filteredEndDate = new Date(this.searchFilters.dateFilterSelected.endDate);
      let startDate = filteredStartDate?.toLocaleString(locale, { month: 'short', day: 'numeric', year: 'numeric' });;
      let endDate = filteredEndDate?.toLocaleString(locale, { month: 'short', day: 'numeric', year: 'numeric' });;
      let dateType = this.searchFilters.dateFilterSelected.dateType;

      if (dateType == 'fileDate') {
        dateType = this.translateService.instant('claims.list.component.toolbar.bubbles.fileDate');
      } else if (dateType == 'issueDate') {
        dateType = this.translateService.instant('claims.list.component.toolbar.bubbles.issueDate');
      }
      this.dateFilterDisplay = dateType + startDate + " - " + endDate;
    } else {
      this.dateFilterEnabled = false;
      this.dateFilterDisplay = "";
    }

    if (this.searchFilters.amountFilterSelected) {
      this.amountFilterEnabled = true;
      let amountType = this.searchFilters.amountFilterSelected.amountType;
      let currencyType = this.searchFilters.amountFilterSelected.currencyType;
      let minAmount = this.searchFilters.amountFilterSelected.minAmount?.toLocaleString();
      let maxAmount = this.searchFilters.amountFilterSelected.maxAmount?.toLocaleString();

      if (amountType == "paidAmount") {
        amountType = this.translateService.instant('claims.list.component.toolbar.bubbles.paidAmount');
      } else if (amountType == "submittedAmount") {
        amountType = this.translateService.instant('claims.list.component.toolbar.bubbles.submittedAmount');
      }

      if (currencyType == "CAD") {
        this.amountFilterDisplay = amountType + minAmount + " CAD - " + maxAmount + " CAD";
      } else if (currencyType == "GBP") {
        this.amountFilterDisplay = amountType + "£" + minAmount + " - £" + maxAmount;
      } else if (currencyType == "EUR") {
        this.amountFilterDisplay = amountType + "€" + minAmount + " - €" + maxAmount;
      } else if (currencyType == "USD") {
        this.amountFilterDisplay = amountType + "$" + minAmount + " - $" + maxAmount;
      }
    } else {
      this.amountFilterEnabled = false;
      this.amountFilterDisplay = "";
    }

    if (this.searchFilters.statusFilterSelected) {
      if (this.searchFilters.statusFilterSelected?.status && this.searchFilters.statusFilterSelected?.status != "") {
        this.statusFilterEnabled = true;
        let selectedStatuses = this.searchFilters.statusFilterSelected.status;
        let listOfTranslatedStatuses = [];
        if (selectedStatuses?.includes('Paid')) { listOfTranslatedStatuses.push(this.translateService.instant('advancedSearch.selectStatusOptionsClaims.paid')); }
        if (selectedStatuses?.includes('Documents Pending')) { listOfTranslatedStatuses.push(this.translateService.instant('advancedSearch.selectStatusOptionsClaims.docPending')); }
        if (selectedStatuses?.includes('Closed')) { listOfTranslatedStatuses.push(this.translateService.instant('advancedSearch.selectStatusOptionsClaims.closed')); }
        if (selectedStatuses?.includes('Claim Received')) { listOfTranslatedStatuses.push(this.translateService.instant('advancedSearch.selectStatusOptionsClaims.received')); }
        if (selectedStatuses?.includes('Withdrawn')) { listOfTranslatedStatuses.push(this.translateService.instant('advancedSearch.selectStatusOptionsClaims.withdrawn')); }
        if (selectedStatuses?.includes('Submitted for Payment')) { listOfTranslatedStatuses.push(this.translateService.instant('advancedSearch.selectStatusOptionsClaims.submitted')); }
        if (selectedStatuses?.includes('Denied')) { listOfTranslatedStatuses.push(this.translateService.instant('advancedSearch.selectStatusOptionsClaims.denied')); }
        if (selectedStatuses?.includes('Investigation Pending')) { listOfTranslatedStatuses.push(this.translateService.instant('advancedSearch.selectStatusOptionsClaims.investigation')); }

        let status = listOfTranslatedStatuses?.join(', ');
        let claimStatus = this.translateService.instant('claims.list.component.toolbar.bubbles.claimStatus');
        this.statusFilterDisplay = claimStatus + status;
      } else {
        this.statusFilterEnabled = false;
        this.statusFilterDisplay = "";
      }

      if (this.searchFilters.statusFilterSelected?.receiver && this.searchFilters.statusFilterSelected?.receiver != "") {
        this.receiverFilterEnabled = true;
        let receiver = this.searchFilters.statusFilterSelected.receiver;
        let receiverHeader = this.translateService.instant('claims.list.component.toolbar.bubbles.receiver');
        this.receiverFilterDisplay = receiverHeader + receiver;
      } else {
        this.receiverFilterEnabled = false;
        this.receiverFilterDisplay = "";
      }

      if (this.searchFilters.statusFilterSelected?.account && this.searchFilters.statusFilterSelected?.account != "") {
        this.accountFilterEnabled = true;
        let account = this.searchFilters.statusFilterSelected.account;
        let accountHeader = this.translateService.instant('claims.list.component.toolbar.bubbles.account');
        this.accountFilterDisplay = accountHeader + account;
      } else {
        this.accountFilterEnabled = false;
        this.accountFilterDisplay = "";
      }
    } else {
      this.statusFilterEnabled = false;
      this.receiverFilterEnabled = false;
      this.accountFilterEnabled = false;
    }

    if (this.searchFilters.eaFiltersSelected?.shipperName) {
      this.shipperNameFilterEnabled = true;
      this.shipperNameFilterDisplay = 'Shipper Name: ' + this.searchFilters.eaFiltersSelected.shipperName;
    } else {
      this.shipperNameFilterEnabled = false;
      this.shipperNameFilterDisplay = '';
    }

    if (this.searchFilters.eaFiltersSelected?.shipperNumber) {
      this.shipperNumberFilterEnabled = true;
      this.shipperNumberFilterDisplay = 'Shipper Number: ' + this.searchFilters.eaFiltersSelected.shipperNumber
    } else {
      this.shipperNumberFilterEnabled = false;
      this.shipperNumberFilterDisplay = '';
    }
  }

  removeFilterBubbles(source: any) {
    if (source == 'date') {
      this.dateFilterEnabled = false;
      this.searchFilters.dateFilterSelected = undefined;
      this.dateQuery = undefined;
      this.search('advanced');
    } else if (source == 'amount') {
      this.amountFilterEnabled = false;
      this.searchFilters.amountFilterSelected = undefined;
      this.amountQuery = undefined;
      this.search('advanced');
    } else if (source == 'status') {
      this.statusFilterEnabled = false;
      this.searchFilters.statusFilterSelected.status = undefined;
      this.claimStatusQuery = undefined;
      this.claimOutcomeQuery = undefined;
      this.search('advanced');
    } else if (source == 'receiver') {
      this.receiverFilterEnabled = false;
      this.searchFilters.statusFilterSelected.receiver = undefined;
      this.receiverQuery = undefined;
      this.search('advanced');
    } else if (source == 'account') {
      this.accountFilterEnabled = false;
      this.searchFilters.statusFilterSelected.account = undefined;
      this.accountQuery = undefined;
      this.search('advanced');
    } else if (source == 'shipperName') {
      this.shipperNameFilterEnabled = false;
      this.searchFilters.eaFiltersSelected.shipperName = undefined;
      this.search('advanced');
    } else if (source == 'shipperNumber') {
      this.shipperNumberFilterEnabled = false;
      this.searchFilters.eaFiltersSelected.shipperNumber = undefined;
      this.search('advanced');
    } else if (source == 'all') {
      this.dateFilterEnabled = false;
      this.amountFilterEnabled = false;
      this.statusFilterEnabled = false;
      this.receiverFilterEnabled = false;
      this.accountFilterEnabled = false;
      this.searchFilters = undefined;
      this.reset();
    } else if (source == 'insights') {
      this.dateFilterEnabled = false;
      this.amountFilterEnabled = false;
      this.statusFilterEnabled = false;
      this.receiverFilterEnabled = false;
      this.accountFilterEnabled = false;
      this.searchFilters = undefined;
    }
  }

  reset() {
    this.clearDefaultSearchbar();
    this.searchIsActive = false;
    this.resolvedActive = false;
    this.inProgressActive = false;
    this.docPendingActive = false;
    this.getClaimSummary();
    this.claimservice.clearStorage();
    sessionStorage.removeItem('pageId');
  }

  clearDefaultSearchbar() {
    this.claimservice.clearStorage();
    this.searchByValueControl.reset();
    this.searchByValueControl.disable();
    this.searchTypeControl.reset();
  }

  openSearch() {
    this.isSearch = !this.isSearch;
  }

  toggleActive(target: any) {
    if (target == "active") {
      this.isActive = true;
    } else {
      this.isActive = false;
      this.isSearch = false;
    }
  }

  isGuestUser: boolean = false;
  redirectToGetStarted: boolean = false;
  systemError: boolean = false;

  dpatManage() {
    let checkCountry = sessionStorage.getItem('locale').slice(3, 5);
    let request = {
      action: "Manage Payee",
      paymentDirection: "Claims",
      country: checkCountry ? checkCountry : "US",
      paymentMode: "",
      policyIdentifier: this.policy.policyNumber ? this.policy.policyNumber : "",
      email: this.userDetails.emailAddress,
      callBackURL: (this.isGuestUser || this.redirectToGetStarted) ? `${ENV.baseUrl.web}claims/new` : `${ENV.baseUrl.web}claims`,
      scenario: "US",
      createdByUserId: this.userDetails.userId ? this.userDetails.userId : "",
      guestUserIndicator: "N",
      tupssFlow: "",
      locale: sessionStorage.getItem('locale'),
      policyData: {
        insured: this.loginservice.getPolicyDetails().insured
      },
      cudAccess: this.userPermission.isClaimPayee ? true : false
    }
    this.claimservice.getRedirect(request).subscribe(
      data => {
        window.location.replace(data.data.url);
      }, error => {
        this.systemError = true;
      }
    );
  }

  ngOnInit() {
    if (this.route.snapshot.fragment == 'docPending') {
      window.location.hash = '';
      this.searchFilters = {
        statusFilterSelected: {
          "status": ["Documents Pending"],
          "receiver": "",
          "account": ""
        }
      };
      this.clearDefaultSearchbar();
      this.populateFilterBubbles();
      this.isSearch = true;
      this.search('advanced');
    } else if (sessionStorage.getItem('retainState') && sessionStorage.getItem('searchFilters')) {
      let repopulatedSearchFilters = JSON.parse(sessionStorage.getItem('searchFilters'));

      // default search needs to be repopulated
      if (repopulatedSearchFilters?.defaultSearchSelected) {
        this.searchTypeControl.enable();
        this.searchByValueControl.enable();
        this.searchTypeControl.setValue(repopulatedSearchFilters.defaultSearchSelected.category);
        this.searchByValueControl.setValue(repopulatedSearchFilters.defaultSearchSelected.input);
        this.search('default');
      }
      // advanced search needs to be repopulated
      else if (repopulatedSearchFilters?.dateFilterSelected || repopulatedSearchFilters?.amountFilterSelected || repopulatedSearchFilters?.statusFilterSelected) {
        this.searchFilters = repopulatedSearchFilters;
        this.clearDefaultSearchbar();
        this.populateFilterBubbles();
        this.search('advanced');
      }
    } else {
      this.clearDefaultSearchbar();
      this.getClaimSummary();
      this.claimservice.clearStorage();
      sessionStorage.removeItem('pageId');
    }
    sessionStorage.removeItem('isPostBack');
    this.policy = this.loginservice.getPolicyDetails();
    this.PolicyNo = this.policy.policyNumber;
    this.policyType = this.policyService.determinePolicyType(this.policy);

    this.route.queryParams.subscribe(params => {
      if (params) {
        this.placeholderPayee = params.payeeID;
        this.placeholderStatus = params.status;
      }
    });
    if (!this.isTupssUser) {
      this.hasDocPending = this.router.url.startsWith('/dashboard');
      this.claimStats = this.claimservice.getClaimStats(this.PolicyNo);
    }

    if (this.policyType == 'transactional_parcel' || this.policyType == 'transactional_cargo' || this.policyType == 'traditional_cargo') {
      this.policyType = "InsureShield";
      this.isInsureShield = true;
    } else {
      this.policyType = `${this.policy.productName} ${this.policy.subPolicyType}`;
      this.isInsureShield = false;
    }

    // default set-up for search bar
    this.activateDefaultSearchBar();
    this.setColumnsToDefault(); // display different set of default columsn for EA and Non EA users.
  }

  ngAfterViewInit() {
    this.searchReceiverControl.valueChanges.subscribe(
      val => {
        if (val && val.length > 0) {
          this.queryEntered = true;
        } else {
          this.queryEntered = false;
        }
        this.ref.detectChanges();
      }
    );
    this.searchTypeControl.valueChanges.subscribe(
      val => {
        this.searchByValueControl.reset();
        this.isClaimSearch = false;
        this.isACHSearch = false;
        this.isTrackingSearch = false;
        this.isSubmissionNumberSearch = false;
        this.isShipperNameSearch = false;
        this.isShipperNumberSearch = false;
        this.searchByValueControl.markAsUntouched({ onlySelf: true });
        this.searchBarPlaceholder = "";
        if (val?.length > 0) {
          this.searchByValueControl.enable();
          if (val == '0') {
            this.isClaimSearch = true;
            this.searchBarPlaceholder = this.translateService.instant('claims.list.component.toolbar.defaultSearch.claimLabel');
          } else if (val == '1') {
            this.isACHSearch = true;
            this.searchBarPlaceholder = this.translateService.instant('claims.list.component.toolbar.defaultSearch.checkAchLabel');
          } else if (val == '2') {
            this.isTrackingSearch = true;
            this.searchBarPlaceholder = this.translateService.instant('claims.list.component.toolbar.defaultSearch.trackingNumberLabel');
          } else if (val == '3') {
            this.isSubmissionNumberSearch = true;
            this.searchBarPlaceholder = this.translateService.instant('claims.list.component.toolbar.defaultSearch.submissionNumberLabel');
          } else if (val == '4') {
            this.isShipperNameSearch = true;
            this.searchBarPlaceholder = 'Shipper Name';
          } else if (val == '5') {
            this.isShipperNumberSearch = true;
            this.searchBarPlaceholder = 'Shipper Number';
          }
          this.queryEntered = true;
          this.isEnabled = true;
        } else {
          this.queryEntered = false;
          this.searchByValueControl.disable();
          this.isEnabled = false;
        }
        this.ref.detectChanges();
      }
    );
  }

  resetSearch() {
    this.emptySearchResult = false;
    this.isClaimSearch = false;
    this.isACHSearch = false;
    this.isTrackingSearch = false;
    this.isSubmissionNumberSearch = false;
  }

  activateDefaultSearchBar() {
    this.searchTypeControl.setValue('0');
    this.isClaimSearch = true;
    this.isEnabled = true;
    this.searchBarPlaceholder = this.translateService.instant('claims.list.component.toolbar.defaultSearch.claimLabel');
    this.searchByValueControl.enable();
  }

  markedAsTouched() {
    this.searchByValueControl.markAsTouched();
  }

  getClaimSummary(filter = {}, search?: boolean, claimStates?: any): void {
    this.loading = true;
    var pageId = 0;
    try {
      pageId = Number(sessionStorage.getItem('pageId'));
    }
    catch {
      pageId = 1;
    }
    this.policy = this.loginservice.getPolicyDetails();
    this.layoutService.policySelect$.subscribe(
      data => {
        if (data !== null) {
          this.summary.claimStates = claimStates ? claimStates : ["open", "closed"];
          this.summary.policyNumber = data;
          let gid = this.userService.getUserInfo().groupID;
          let num: number = parseInt(gid, 10);
          if (num) { this.summary.groupID = num; }
          this.summary.shipperNumber = this.userService.getUserInfo().shiperNumber;
          this.summary.shipperName = this.userService.getUserInfo().shiperName;
          if (this.userService.isParentPolicy()) {
            this.summary.childPolicyNumberList = JSON.parse(sessionStorage.getItem('childPolicies'));
          }
          localStorage.removeItem('params');
          localStorage.setItem('params', JSON.stringify({ ...this.summary, ...filter }));
          this.claimservice.getAllClaimIds({ ...this.summary, ...filter }).subscribe(data => {
            this.allClaimIds = data.claimIds;
            if (this.allClaimIds && this.allClaimIds.length > 0) {
              this.emptySearchResult = false;
              if (pageId) {
                this.setPage(pageId);
              } else {
                this.setPage(1);
              }
            } else {
              this.handlNoResults(search);
              this.apiInProgress = false;
            }
            this.ref.markForCheck();
            this.loading = false;
          },
            error => {
              this.handlNoResults(search);
              this.loading = false;
              this.apiInProgress = false;
            }
          );

        }
      }
    );
    this.summary.claimStates = claimStates ? claimStates : ["open", "closed"];
    this.summary.policyNumber = this.policy.policyNumber;
    if (this.userService.isParentPolicy()) {
      this.summary.childPolicyNumberList = JSON.parse(sessionStorage.getItem('childPolicies'));
    }
    let gid = this.userService.getUserInfo().groupID;
    let num: number = parseInt(gid, 10);
    if (num) { this.summary.groupID = num; }
    this.summary.shipperNumber = this.userService.getUserInfo().shiperNumber;
    this.summary.shipperName = this.userService.getUserInfo().shiperName;
    localStorage.removeItem('params');
    localStorage.setItem('params', JSON.stringify({ ...this.summary, ...filter }));
    this.loading = true;
    this.claimservice.getAllClaimIds({ ...this.summary, ...filter }).subscribe(data => {
      this.allClaimIds = data.claimIds;
      if (this.allClaimIds && this.allClaimIds.length > 0) {
        this.emptySearchResult = false;
        if (pageId) {
          this.setPage(pageId);
        } else {
          this.setPage(1);
        }
      } else {
        this.handlNoResults(search);
        this.apiInProgress = false;
      }
      this.ref.markForCheck();
      this.loading = false;
    },
      error => {
        this.handlNoResults(search);
        this.loading = false;
        this.apiInProgress = false;
      }
    );
  }




  public ngOnDestroy() {

  }

  //Used to open the claim detail page
  claimDetail(claimNumber) {
    if (this.router.url == '/dashboard') {
      sessionStorage.removeItem('retainState');
      sessionStorage.setItem('backPage', 'Dashboard');
    } else {
      sessionStorage.setItem('retainState', 'true');
      sessionStorage.setItem('pageId', JSON.stringify(this.pager.currentPage));
      sessionStorage.setItem('backPage', 'Claims');
    }

    this.router.navigate([this.isTupssUser ? `/tupss/detail` : `/claims/detail`], { queryParams: { claimNumber: claimNumber, isMessage: false } });
  }

  public openExport() {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '515px';
    config.data = this.tableObj;
    let dialogRef = this.dialog.open(ExportComponent, config);
    dialogRef.afterClosed().subscribe();
  }

  public formatAmount(event, backspace: boolean) {
    let plainNumber = event.replace(/[^-+0-9]/g, '');
    plainNumber = this.number.transform(plainNumber / 100, '1.2-2').replace(/,/g, '');
    return plainNumber;
  }

  trim(event) {
    if (this.searchByValueControl.value) {
      this.searchByValueControl.setValue(this.searchByValueControl.value.trim());
    }
  }

  setStatusFilters() {
    this.claimStatusQuery = [];
    this.claimOutcomeQuery = [];
    this.allStatusArray = this.searchFilters?.statusFilterSelected?.status;

    this.allStatusArray.map(category => {
      let closedOutcome = [];
      let claimSubStatus = [];
      if ((category == "Claim Received" || category == "Reclamo ricevuto" || category == "Schadenforderung empfangen" || category == "Réclamation reçue") ||
        (category == "Submitted for Payment" || category == "Inviato per il pagamento" || category == "Soumis pour paiement" || category == "Eingereicht zur Zahlung") ||
        (category == "Investigation Pending" || category == "Indagine in attesa" || category == "Untersuchung ausstehend" || category == "Enquête en cours") ||
        (category == "Documents Pending" || category == "Documenti in attesa" || category == "Documents en attente" || category == "Ausstehende Dokumente")) {
        if (category == "Claim Received" || category == "Reclamo ricevuto" || category == "Schadenforderung empfangen" || category == "Réclamation reçue") {
          claimSubStatus = ["submitted"];
        }
        else if (category == "Submitted for Payment" || category == "Inviato per il pagamento" || category == "Soumis pour paiement" || category == "Eingereicht zur Zahlung") {
          claimSubStatus = ["Submitted_for_Payment"];
        }
        else if (category == "Investigation Pending" || category == "Indagine in attesa" || category == "Untersuchung ausstehend" || category == "Enquête en cours") {
          claimSubStatus = ["Pending_Insurance_Carrier", "Report_to_transportation_carrier", "Security_Investigation"];
        }
        else if (category == "Documents Pending" || category == "Documenti in attesa" || category == "Documents en attente" || category == "Ausstehende Dokumente") {
          claimSubStatus = ["IDR_Request_1", "IDR_Request_2", "IDR_Request_Final"];
          closedOutcome = ["incomplete_documentation_Ext"];
        }
        this.claimStatusQuery = [...this.claimStatusQuery, ...claimSubStatus]
        this.claimOutcomeQuery = [...this.claimOutcomeQuery, ...closedOutcome];
      }
      else {
        if (category == "Paid" || category == "Payé" || category == "Bezahlt"
          || category == "Pagato") {
          claimSubStatus = ["Paid"];
          closedOutcome = ["paymentscomplete"];
        }
        else if (category == "Withdrawn" || category == "Ritirato"
          || category == "Retiré" || category == "Zurückgezogen") {
          closedOutcome = ["withdrawn_Ext"];
        }
        else if (category == "Closed" || category == "Chiuso" || category == "Abgeschlossen"
          || category == "Fermé") {
          closedOutcome = ["mistake", "duplicate", "other_Ext"];
        }
        else if (category == "Denied" || category == "Abgelehnt"
          || category == "Refusé" || category == "Rifiutato") {
          closedOutcome = ["denied_Ext"];
        }
        this.claimStatusQuery = [...this.claimStatusQuery, ...claimSubStatus]
        this.claimOutcomeQuery = [...this.claimOutcomeQuery, ...closedOutcome];
      }
    });
  }

  systemError2: boolean = false;
  //Handles claim search
  public search(type?: string, insightFilters?: any) {
    this.taggingService.link({ link_name: 'claim_search' });
    this.systemError2 = false;
    if (type == 'default') {
      // remove any leftover active advanced search filters
      this.dateFilterEnabled = false;
      this.amountFilterEnabled = false;
      this.statusFilterEnabled = false;
      this.receiverFilterEnabled = false;
      this.accountFilterEnabled = false;
      this.searchFilters = undefined;
      this.resolvedActive = false;
      this.inProgressActive = false;
      this.docPendingActive = false;

      if (this.searchByValueControl?.value == "") {
        this.searchByValueControl.markAsTouched();
      } else if (this.searchTypeControl?.value && this.searchByValueControl?.value) {
        if (this.searchTypeControl.value == '0') {
          this.getClaimSummary({ claimNumber: this.searchByValueControl.value }, true);
        } else if (this.searchTypeControl.value == '1') {
          this.getClaimSummary({ checkNumber: this.searchByValueControl.value }, true);
        } else if (this.searchTypeControl.value == '2') {
          this.getClaimSummary({ trackingNumber: this.searchByValueControl.value }, true);
        } else if (this.searchTypeControl.value == '3') {
          this.getClaimSummary({ submissionNumber: this.searchByValueControl.value }, true);
        } else if (this.searchTypeControl.value == '4') {
          this.getClaimSummary({ shipperName: this.searchByValueControl.value }, true);
        } else if (this.searchTypeControl.value == '5') {
          this.getClaimSummary({ shipperNumber: this.searchByValueControl.value }, true);
        }
        let searchFilters = {
          defaultSearchSelected: {
            category: this.searchTypeControl.value,
            input: this.searchByValueControl.value
          }
        }
        this.searchIsActive = true;
        sessionStorage.setItem('searchFilters', JSON.stringify(searchFilters));
      }
    } else if (type == 'advanced') {
      this.clearDefaultSearchbar();
      this.dateQuery = undefined;
      this.amountQuery = undefined;
      this.claimStatusQuery = undefined;
      this.claimOutcomeQuery = undefined;
      this.allStatusArray = undefined;
      this.receiverQuery = undefined;
      this.accountQuery = undefined;
      this.resolvedActive = false;
      this.inProgressActive = false;
      this.docPendingActive = false;

      // Set all fields to proper format for claim summary API
      if (this.searchFilters?.dateFilterSelected) {
        this.dateQuery = {
          dateType: this.searchFilters.dateFilterSelected.dateType,
          startDate: this.searchFilters.dateFilterSelected.startDate,
          endDate: this.searchFilters.dateFilterSelected.endDate
        }
      }

      if (this.searchFilters?.amountFilterSelected) {
        this.amountQuery = {
          amountType: this.searchFilters.amountFilterSelected.amountType,
          currency: this.searchFilters.amountFilterSelected.currencyType.toLowerCase(),
          startRange: this.searchFilters.amountFilterSelected.minAmount,
          endRange: this.searchFilters.amountFilterSelected.maxAmount
        }
      }

      if (this.searchFilters?.statusFilterSelected?.status) {
        this.setStatusFilters();
      }

      if (this.searchFilters?.statusFilterSelected?.receiver) {
        this.receiverQuery = this.searchFilters.statusFilterSelected.receiver;
      }

      if (this.searchFilters?.statusFilterSelected?.account) {
        this.accountQuery = this.searchFilters.statusFilterSelected.account;
      }

      if (this.searchFilters) {
        this.getClaimSummary({
          dateRange: this.dateQuery,
          amountRange: this.amountQuery,
          claimSubStatus: insightFilters?.claimSubStatus ? insightFilters.claimSubStatus : this.claimStatusQuery, //insight filter overrides normal search by status query
          claimClosedOutCome: insightFilters?.claimClosedOutCome ? insightFilters.claimClosedOutCome : this.claimOutcomeQuery,
          receiverCompanyName: this.receiverQuery,
          account: this.accountQuery,
          shipperName: this.searchFilters?.eaFiltersSelected?.shipperName ? this.searchFilters?.eaFiltersSelected?.shipperName : '',
          shipperNumber: this.searchFilters?.eaFiltersSelected?.shipperNumber ? this.searchFilters?.eaFiltersSelected?.shipperNumber : ''
        }, true, insightFilters?.claimStates);
        this.searchIsActive = true;
        sessionStorage.setItem('searchFilters', JSON.stringify(this.searchFilters));
      } else {
        this.systemError2 = true;
      }
    }
  }

  handlNoResults(search?: boolean) {
    this.emptySearchResult = true;
    if (search) {
      this.emptyClaimResult = false;
    } else {
      this.emptyClaimResult = true;
    }

    if (this.isPagination) {
      this.pagedItems = new Array(10);
    } else {
      this.pagedItems = new Array(5);
    }
    this.pagedItems.fill({});
  }


  updateLayout(columns) {
    this.loading = true;
    this.setAllColumnsFalse();
    for (let i = 0; i < columns.length; i++) {
      this.tableObj[columnDictionary[columns[i]]] = true;
    }
    this.updateColCount();
    this.loading = false;
  }

  updateColCount() {
    this.tableObj.columns = Object.values(this.tableObj).filter(x => x == true).length;
  }



  setAllColumnsFalse() {
    this.tableObj.filedAmount = false;
    this.tableObj.paidAmount = false;
    this.tableObj.checkACHNumber = false;
    this.tableObj.checkACHIssueDate = false;
    this.tableObj.trackingNumber = false;
    this.tableObj.referenceNumber = false;
    this.tableObj.accountNumber = false;
    this.tableObj.receiver = false;
    this.tableObj.paymentRecipient = false;
    this.tableObj.adjuster = false;
    this.tableObj.lossDate = false;
    this.tableObj.declaredValue = false;
    this.tableObj.submissionNumber = false;
    this.tableObj.claimSource = false;
  }

  setColumnsToDefault() {
    if (this.EAffiliateUsers) {
      this.setEADefaultColumns();
    } else {
      this.tableObj = defaultClaimsTable;
      if(this.isTupssUser) {
        this.tableObj.claimSource = true;
      }
    }
  }

  setEADefaultColumns() { // Enterprise Affiliate Default Columns
    this.tableObj.filedAmount = false;
    this.tableObj.paidAmount = false;
    this.tableObj.checkACHNumber = false;
    this.tableObj.checkACHIssueDate = false;
    this.tableObj.trackingNumber = false;
    this.tableObj.referenceNumber = false;
    this.tableObj.accountNumber = false;
    this.tableObj.receiver = false;
    this.tableObj.paymentRecipient = false;
    this.tableObj.adjuster = false;
    this.tableObj.lossDate = false;
    this.tableObj.declaredValue = false;
    this.tableObj.submissionNumber = false;
    this.tableObj.claimSource = false;
    this.tableObj.policyNumber = true;
    this.tableObj.shipperName = true;
    this.tableObj.shipperNumber = true;
  }

  goToWallet() {
    this.systemError = false;
    let request = {
      action: "accesswallet", //Hardcoded
      navigatingSystem: "onl", //Hardcoded
      navigatingSystemRole: "admin", //Hardcoded (assume admin)
      productSystem: "gw", //Hardcoded (not coming from cbp/dd)
      productSystemIdentifier1: this.policyDetails?.policyNumber,
      productSystemIdentifier1Name: "policyno",
      productSystemIdentifier2: this.policyDetails?.accountNumber,
      productSystemIdentifier2Name: "pcAccountNumber",
      productType: "iscomplete", //Hardcoded (unless we want to change later based on databricks policytype value)
      country: "us", //Harcoded (wallet is US only)
      locale: "en", //Hardcoded (wallet is US only)
      userIdentifier1Name: "createdbyuserid",
      userIdentifier1: this.userDetails?.userId?.toString(),
      displayName: this.userDetails?.contactName, //QUESTION: Use user's name or policy holder's name?
      userEmail: this.userDetails?.emailAddress, //QUESTION: Do we want to use user's info here or policy's email?
      objectUID: this.userDetails?.objectUID,
      callBackURL: "https://online.upscapital.com", //Hardcoded (we don't need this field, remove if possible)
      returnURL: "https://online.upscapital.com" //Hardcoded (we don't need this field, remove if possible)
    };
    this.billingService.DWstartSession(request).subscribe(
      data => {
        if (data?.data?.url) {
          window.open(data?.data?.url, '_blank');
        } else {
          this.systemError = true;
        }
      }, error => {
        this.systemError = true;
      }
    );
  }

  get isDashboard() {
    if (this.router?.url.includes('dashboard')) {
      return true;
    } else {
      return false;
    }
  }

  get show1000Limit() {
    if (this.pager?.totalItems > 999) {
      return true;
    } else {
      return false;
    }
  }
}
