export interface ITHistory_Table {
    invoiceNo: boolean;
    shipDate: true;
    trackingNumber: true;
    orderNumber: true;
    dataSource: true;
    insuredValue: true;
    premium: true;
    claimStatus: true;
    columns: number;    //Should always be 7
}

export const defaultRecentTHistoryTable: ITHistory_Table = {
    invoiceNo: false,
    shipDate: true,
    trackingNumber: true,
    orderNumber: true,
    dataSource: true,
    insuredValue: true,
    premium: true,
    claimStatus: true,
    columns: 7
}

export const defaultInvoicedTHistoryTable: ITHistory_Table = {
    invoiceNo: true,
    shipDate: true,
    trackingNumber: true,
    orderNumber: true,
    dataSource: true,
    insuredValue: true,
    premium: true,
    claimStatus: true,
    columns: 8
}