<div class="upsc-custom-userprofile">
  <upsc-contact *ngIf="isUsaUser && !isCBPPolicy"></upsc-contact>
  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="profile-form-content">
    <div class="wrapper-box" *ngIf="!isUsaUser">
      <div class="content-area d-flex flex-column flex-md-row">
        <div class="left">
          <div class="email">
            <div class="title">{{ 'userProfile.email' | translate }}</div>
            <div class="content d-flex">
              <div>{{ userDetails.emailAddress }}</div>
              <mat-icon ngbTooltip="{{ 'userProfile.tt' | translate }}"
                tooltipClass="ngb-tooltip-class">error</mat-icon>
            </div>
          </div>
          <div class="edit">
            <mat-form-field>
              <mat-label>{{ 'userProfile.fName' | translate }}</mat-label>
              <input matInput formControlName="firstName">
              <mat-error>{{ 'userProfile.errors.fname' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="edit">
            <mat-form-field>
              <mat-label>{{ 'userProfile.lName' | translate }}</mat-label>
              <input matInput formControlName="lastName">
              <mat-error>{{ 'userProfile.errors.lname' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="edit">
            <mat-form-field *ngIf="isUsaUser">
              <mat-label>{{ 'userProfile.phone' | translate }}</mat-label>
              <input matInput mask="000.000.0000" formControlName="phoneNumber">
              <mat-error>{{ 'userProfile.errors.phone' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="isCanadaUser">
              <mat-label>{{ 'userProfile.phone' | translate }}</mat-label>
              <input matInput mask="000-000-0000" formControlName="phoneNumber">
              <mat-error>{{ 'userProfile.errors.phone' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">
              <mat-label>{{ 'userProfile.phone' | translate }}</mat-label>
              <input matInput formControlName="phoneNumber" maxlength="20">
              <mat-error>{{ 'userProfile.errors.phone' | translate }}</mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" *ngIf="isGermanyUser">
              <input matInput formControlName="phoneNumber">
              <mat-error>{{ 'userProfile.errors.phone' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="isFranceUser">
              <input matInput formControlName="phoneNumber">
              <mat-error>{{ 'userProfile.errors.phone' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="isItalyUser">
              <input matInput formControlName="phoneNumber">
              <mat-error>{{ 'userProfile.errors.phone' | translate }}</mat-error>
            </mat-form-field> -->

          </div>
        </div>
        <div class="right">
          <div class="title">{{ 'userProfile.company' | translate }}</div>
          <div class="company" *ngIf="isUsaUser">
            <div class="line1">{{ policyDetails?.addressLine1_Ext }}</div>
            <div class="line2">{{ policyDetails?.addressLine2_Ext }}</div>
            <div class="line3">{{ policyDetails?.city }}, {{ policyDetails?.state }} {{ policyDetails?.zip }}</div>
          </div>
          <div class="company" *ngIf="isCanadaUser || isUkUser">
            <div class="line1">{{ policyDetails?.addressLine1_Ext }} {{ policyDetails?.addressLine2_Ext }}</div>
            <div class="line2">{{policyDetails?.city }}</div>
            <div class="line3">{{policyDetails?.state }}</div>
            <div class="line4">{{policyDetails?.zip }}</div>
            <div class="line5">{{policyDetails?.policyCountry_Ext }}</div>
          </div>
          <div class="company" *ngIf="isGermanyUser || isFranceUser || isItalyUser">
            <div class="line1">{{ policyDetails?.addressLine1_Ext }} {{ policyDetails?.addressLine2_Ext }}</div>
            <div class="line3">{{policyDetails?.state }}</div>
            <div class="line4">{{policyDetails?.zip }} {{policyDetails?.city }}</div>
            <div class="line5">{{policyDetails?.policyCountry_Ext }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="background-wrapper" *ngIf="!isInternalUser && isFileClaim">
      <upsc-collapsible-panel *ngIf="!isInternalUser && isFileClaim" [title]="'userProfile.autofill.title' | translate"
        class="upsc-custom-collapsible-panel-dark" [subtitle]="'userProfile.autofill.subtitle' | translate"
        formGroupName="autofillPreferences" (collapsedEvent)="disableUpdate($event)" [isCollapsed]="true">
        <div class="content">
          <div class="edit">
            <div class="notification-approved" *ngIf="updateSuccess" (click)="hideAutofillMessage()">
              <mat-icon>check_circle</mat-icon>
              <div class="notify-sub-title"> {{ 'userProfile.success' | translate }}</div>
            </div>
            <mat-form-field>
              <mat-label>{{ 'userProfile.autofill.carrierTitle' | translate }}</mat-label>
              <input matInput formControlName="carrier" [matAutocomplete]="auto"
                placeholder="{{ 'userProfile.autofill.carrierPlaceholder' | translate }}"
                (blur)="carrierCollapsed = !carrierCollapsed" (focus)="carrierCollapsed = !carrierCollapsed">
              <mat-icon matSuffix *ngIf="carrierCollapsed">arrow_drop_down</mat-icon>
              <mat-icon matSuffix *ngIf="!carrierCollapsed">arrow_drop_up</mat-icon>
              <!--On Focus-->
              <mat-autocomplete #auto>
                <mat-option *ngFor="let key of Object.keys(carrierList) | carrierSearch : queryCarrier" [value]="key">
                  {{carrierList[key]}}
                </mat-option>
              </mat-autocomplete>
              <mat-error>{{ 'userProfile.autofill.errors.carrier' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="edit pb-2">
            <mat-form-field>
              <mat-label>{{ 'userProfile.autofill.categoryTitle' | translate }}</mat-label>
              <input matInput [matAutocomplete]="autoCategory" formControlName="category"
                placeholder="{{ 'userProfile.autofill.categoryPlaceholder' | translate }}"
                (blur)="categoryCollapsed = !categoryCollapsed" (focus)="categoryCollapsed = !categoryCollapsed">
              <mat-icon matSuffix *ngIf="categoryCollapsed">arrow_drop_down</mat-icon>
              <mat-icon matSuffix *ngIf="!categoryCollapsed">arrow_drop_up</mat-icon>
              <!--On Focus-->
              <mat-autocomplete #autoCategory="matAutocomplete" [displayWith]="displayCategories">
                <mat-option *ngFor="let option of filteredCategories | async" [value]="option">{{ option.value }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>{{ 'userProfile.autofill.errors.category' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="shipper-address" *ngIf="policyType == 'transactional_cargo' || policyType == 'traditional_cargo'">
            <div class="subtitle">{{ 'userProfile.autofill.addressTitle' | translate }}</div>
            <div class="edit">
              <mat-form-field>
                <mat-label>{{ 'userProfile.autofill.companyPlaceholder' | translate }}</mat-label>
                <input matInput formControlName="company">
              </mat-form-field>
            </div>
            <div class="edit">
              <mat-form-field>
                <mat-label>{{ 'userProfile.autofill.address1Placeholder' | translate }}</mat-label>
                <input matInput formControlName="addressLine1">
              </mat-form-field>
            </div>
            <div class="edit">
              <mat-form-field>
                <mat-label>{{ 'userProfile.autofill.address2Placeholder' | translate }}</mat-label>
                <input matInput formControlName="addressLine2">
              </mat-form-field>
            </div>
            <div class="edit half d-flex flex-column flex-md-row justify-content-between">
              <div class="left">
                <mat-form-field>
                  <mat-label>{{ 'userProfile.autofill.cityPlaceholder' | translate }}</mat-label>
                  <input matInput formControlName="city">
                  <mat-error>{{ 'userProfile.payee.errors.required' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="right" *ngIf="isCanadaUser || isUsaUser">
                <mat-form-field class="states">
                  <mat-label>{{ 'userProfile.autofill.statePlaceholder' | translate }}</mat-label>
                  <mat-select formControlName="state" (selectionChange)="autoCountry()">
                    <mat-option>{{ 'userProfile.autofill.none' | translate }}</mat-option>
                    <mat-option *ngFor="let state of fullStateList" [value]="state.state">{{state.state}}</mat-option>
                  </mat-select>
                  <mat-error>{{ 'userProfile.payee.errors.required' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="edit half d-flex flex-column flex-md-row justify-content-between">
              <div class="left">
                <mat-form-field class="zip">
                  <mat-label *ngIf="isUsaUser">{{ 'userProfile.autofill.zipPlaceholder2' | translate }}</mat-label>
                  <mat-label *ngIf="!isUsaUser">{{ 'userProfile.autofill.zipPlaceholder' | translate }}</mat-label>
                  <input *ngIf="isUsaUser || isGermanyUser || isFranceUser || isItalyUser" matInput
                    formControlName="zip" maxlength="5">
                  <input *ngIf="isUkUser" matInput formControlName="zip" maxlength="8" minlength="2">

                  <input *ngIf="isCanadaUser" matInput formControlName="zip" maxlength="7">
                  <mat-error>{{ 'userProfile.payee.errors.zip' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="right">
                <mat-form-field class="country">
                  <mat-label>{{ 'userProfile.autofill.countryPlaceholder' | translate }}</mat-label>
                  <mat-select formControlName="country">
                    <mat-option>{{ 'userProfile.autofill.none' | translate }}</mat-option>
                    <mat-option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="scriptError" class="response-error">Invalid Input</div>
        <div class="navi d-flex justify-content-end">
          <a [routerLink]="['/dashboard']">{{ 'userProfile.cancel' | translate }}</a>
          <button class="upsc-button" type="submit" [disabled]="!profileForm.valid" (click)="tagging()">{{
            'userProfile.update' | translate }}</button>
        </div>
      </upsc-collapsible-panel>
    </div>
  </form>
</div>