import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { concat, forkJoin, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { ClaimService } from 'src/app/services/claim.service';
import { TupssService } from '../../services/tupss.service';

@Component({
  selector: 'upsc-tfcp-auth',
  templateUrl: './tfcp-auth.component.html',
  styleUrls: ['./tfcp-auth.component.scss']
})
export class TFCPAuthComponent implements OnInit {

  @Output() nextEvent = new EventEmitter<any>();

  // franchiseForm: FormGroup;
  tupssType = 'tfcp';
  userDetails: any;
  trackingNumber: string;
  goodTrackingNumber = false;
  showSpinner = true;
  systemError: boolean;
  authError: boolean;
  errors: string[] = []

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private tupssService: TupssService,
    private claimService: ClaimService,
    private jwtHelper: JwtHelperService
  ) { }

  ngOnInit(): void {
    let tupssToken: any = this.route.snapshot.fragment.match(/id_token=([\w_.\-]+)/i);
    if(tupssToken.length > 1) {
      tupssToken = tupssToken[1];
    }
    console.log('id_token', this.jwtHelper.decodeToken(tupssToken), this.jwtHelper.isTokenExpired(tupssToken));

    this.tupssService.tfcpAuthentication(tupssToken).subscribe(
      data => {
        this.showSpinner = false;
        if(data.accessToken && data.tupssUserDetails) {
          sessionStorage.setItem(`access_token`, data.accessToken);
          this.userDetails = {
            ...data.tupssUserDetails,
            type: this.tupssType,
            'tfcpTokenString': this.route.snapshot.fragment,
            'userRoleID': "6" //to indicate tupss uses a different kind of token
          }
          sessionStorage.setItem(`userDetails`, JSON.stringify(this.userDetails));
          this.router.navigate(['tfcp', 'start'])
        }
        else {
          this.authError = true;
        }       
      },
      error => {
        this.showSpinner = false;
        this.authError = true;
      }
    )
  }
}
