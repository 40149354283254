<div class="main">
  <!--  <div *ngIf="content">
    <div class="title"> {{ 'security.title' | translate }} </div>
    <div class="margin">
      <mat-form-field class="custom-width">
        <mat-label> {{ 'security.userid' | translate }}</mat-label>
        <input type="text" value="{{userid}}" readonly matInput [disabled]="disabledField">
      </mat-form-field>
    </div>
    <div class="margin">
      <mat-form-field class="custom-width">
        <mat-label> {{ 'security.password' | translate }}</mat-label>
        <input readonly type="password" id="password" value="xxxxxxxxxxxxxx" matInput [disabled]="disabledField">
        <mat-icon matSuffix class="icon" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
    </div>

    <div class="btn-containers">
     <button class="upsc-button" type="submit" (click)="showUpdate();">
         {{ 'security.update' | translate }}<mat-icon>chevron_right</mat-icon>
      </button>
    </div>
   
    //Already commented code
    <button class="upsc-button" (click)="showUpdate();">
      <div class="btn-text-icon">
        <div class="text">{{ 'security.update' | translate }}</div><mat-icon>chevron_right</mat-icon>
      </div>
    </button>

  </div>
  <div *ngIf="update && !content">
    
  </div> -->

  <upsc-update (isCancel)="hideChangePassword($event)"></upsc-update>
</div>