import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { DigitalWalletBannerComponent } from './digital-wallet-banner.component';

@NgModule({
  declarations: [DigitalWalletBannerComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [DigitalWalletBannerComponent]
})
export class DigitalWalletBannerModule { }
