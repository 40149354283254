import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TupssService } from '../../services/tupss.service';


@Component({
  selector: 'upsc-site-selection',
  templateUrl: './site-selection.component.html',
  styleUrls: ['./site-selection.component.scss']
})
export class SiteSelectionComponent implements OnInit {

  franchiseForm: UntypedFormGroup;
  showSpinner = false;
  systemError: boolean;
  siteIds: string [];
  errors: string[] = []
  querySite: string;
  nextPage: String;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private tupssService: TupssService,
  ) { }

  ngOnInit(): void {
    this.siteIds = this.tupssService.getSiteIds();

    this.nextPage = this.route.snapshot.fragment;
    this.route.fragment.subscribe(
      fragment => this.nextPage = fragment
    )

    this.franchiseForm = this.fb.group({
      siteIds: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z\-0-9]+$/i)])],
    });

    this.franchiseForm.controls['siteIds'].valueChanges.subscribe(
      val => { this.querySite = val;
        if (this.siteIds.includes(this.querySite)) {
          this.franchiseForm.controls['siteIds'].setErrors(null);
        } else {
          this.franchiseForm.controls['siteIds'].setErrors({'required': true});
        }
        if (this.siteIds.length == 0) {
          this.franchiseForm.controls['siteIds'].setErrors(null);
        }
      }
    )
  }

  emptyCheck() {
  if (this.siteIds.length == 0) {
    this.franchiseForm.controls['siteIds'].setErrors(null);
  }
}
  nextStep() {
    this.systemError = false;
    this.errors = [];
    this.franchiseForm.get('siteIds').setErrors(null);
    let siteId = this.franchiseForm.get('siteIds').value;
    this.showSpinner = true;
    this.tupssService.setSiteId(siteId);
    /* if(siteId.length != 4) {
      this.showSpinner = false;
      this.errors.push('franchiseclaimportal.storeNumberNotFourDigits');
    } else { */
    this.tupssService.getContractId(siteId).subscribe(
      res => {
        if (!res.contractIDResponse || res.message?.indexOf('NOT Found') > 0) {
          this.showSpinner = false;
          this.errors.push('franchiseclaimportal.storeNumberNotFound');
        }
        else {
          this.tupssService.userData['contractData'] = res.contractIDResponse;
          if(this.nextPage == 'claims') {
            this.tupssService.setItem('siteFor', 'claims')
            let contract = res.contractIDResponse.find(cd => cd && cd.programType == 'UPSSTORES');
            if(contract) {
              this.tupssService.setContractId(contract.contractID);
              this.router.navigateByUrl(`/tfcp/${this.nextPage}`);
            }
            else {
              this.showSpinner = false;
              this.systemError = true;
              this.errors.push('common.systemError');
            }
          }
          else if(this.nextPage == 'policy') {
            this.tupssService.setItem('siteFor', 'policy')
            let flexContract = res.contractIDResponse.find(cd => cd && cd.programType == 'FLEX');
            if(flexContract) {
              this.tupssService.setContractId(flexContract.contractID);
              this.router.navigateByUrl(`/tfcp/${this.nextPage}`);
            }
            else {
              this.showSpinner = false;
              this.errors.push('franchiseclaimportal.storeNumberNoFlex');
            }
          }
          else {
            this.router.navigateByUrl(`/tfcp`);
          }
      }
      },
      error => {
        this.showSpinner = false;
        this.systemError = true;
        this.errors.push('common.systemError');
      }
    );
    // }
  }
}
