import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CollapsiblePanelComponent } from './collapsible-panel.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    SharedModule,
  ],
  declarations: [CollapsiblePanelComponent],
  providers: [],
  exports: [CollapsiblePanelComponent],
})
export class CollapsiblePanelModule {}
