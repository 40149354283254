import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'upsc-collapsible-panel-dark',
  templateUrl: './collapsible-panel-dark.component.html',
  styleUrls: ['./collapsible-panel-dark.component.scss']
})
export class CollapsiblePanelDarkComponent {

  @Input() public isCollapsed = true;
  @Input() public title: string;
  @Input() public subtitle: string;

  @Output() collapsedEvent = new EventEmitter<boolean>(false);

  unCollapsed(){
    this.collapsedEvent.emit(true);
  }

  collapsed(){
    this.collapsedEvent.emit(false);
  }

  collapsedUncollapsed(){
    if(this.isCollapsed){
      this.collapsedEvent.emit(false);
    }
    else
    this.collapsedEvent.emit(true);
  }

}
