import { Component } from '@angular/core';
import { MyPolicy } from '../models/my-policy';
import { PolicyService } from 'src/app/services/policy.service';
import { emptyIEmail } from 'src/app/shared/models/email.interface';
import { ClaimService } from 'src/app/services/claim.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { environment as ENV } from '../../../../../environments/environment';
import { TaggingService } from 'src/app/tags/tagging.service';

@Component({
  selector: 'upsc-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss']
})
export class CongratulationsComponent {
  myPolicy: MyPolicy;
  loading: boolean = false;
  systemError: boolean = false;
  policyData: any;
  paramInfo: any;
  paramRoutInfo: any;
  submitApiCheck: any;
  contactName = {
    firstName: '',
    lastName: ''
  };
  showSpinner: boolean = false;
  failedCase: boolean = false;
  successCase: boolean = false;
  pageRefreshCase: boolean = false;
  confirmationNumber: any;
  myDate = new Date();
  constructor(
    private policyService: PolicyService,
    private claimService: ClaimService,
    private taggingService: TaggingService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe(params => {
      if ((params.code == '000') && (params.message == 'Success') && (params.uID)) {
        this.paramRoutInfo = params;
        sessionStorage.setItem('paramRoutInfo', JSON.stringify(this.paramRoutInfo));
        this.paramRoutInfo = null;
      }
      this.router.navigate(['ups-my-choice/congratulations']);
    });
  }

  ngOnInit() {
    this.taggingService.load('upsc_my_choice_congratulations')

    this.policyData = JSON.parse(sessionStorage.getItem('policyData'));
    if (this.policyData) {
      this.confirmationNumber = this.policyData.policyNumber;
    }
    else { this.confirmationNumber = ""; }
    this.paramInfo = JSON.parse(sessionStorage.getItem('paramInfo'));
    // this.enrollmentInfo = JSON.parse(sessionStorage.getItem('enrollmentInfo'));
    this.paramRoutInfo = JSON.parse(sessionStorage.getItem('paramRoutInfo'));

    if (this.policyData == null || this.paramInfo == null) {
      this.failedCase = true;
    }
    else {
      this.successCase = true;
      this.submitApiCheck = JSON.parse(sessionStorage.getItem('submitApicheck'));
      if (this.submitApiCheck !== null) {
        if (this.submitApiCheck.policyNumber == this.policyData.policyNumber && this.submitApiCheck.isSuccess == true) {
          // skip duplicate submit API call and ChoicePaymentReceiptEmail
        }
      }
      else {
        //MyChoicePaymentReceiptEmail
        this.getContactName();
        this.sendMyChoicePaymentReceiptEmail();
        this.myPolicy = new MyPolicy().setData(this.policyData, this.paramRoutInfo.uID);
        this.myPolicy.account.contactPerson = `${this.contactName.firstName} ${this.contactName.lastName}`;
        this.MyChoiceSubmit();
      }
    }
  }

  getContactName() {
    let verifyData = JSON.parse(sessionStorage.getItem('verifyData'));
    if(verifyData?.firstName && verifyData?.lastName) {
      this.contactName.firstName = verifyData.firstName;
      this.contactName.lastName = verifyData.lastName;
    }
  }

  MyChoiceSubmit() {
    this.showSpinner = true;
    if (this.myPolicy) {
      this.policyService.submitPolicy(this.myPolicy).subscribe(
        data => {
          if (data) {
            if (data.isSuccess == true) {
              this.showSpinner = false;
              sessionStorage.setItem('submitApicheck', JSON.stringify(data));
            }
          }
          else {
            this.showSpinner = false;
            this.systemError = true;
          }
        },
        error => {
          this.showSpinner = false;
          this.systemError = true;
        });
    }
  }

  sendMyChoicePaymentReceiptEmail() {
    let request = emptyIEmail;
    request = {
      ...request,
      emailFor: 'MCPaymentReceipt',
      CountryLocale: sessionStorage.getItem('locale'),
      data: {
        trackingNumber: this.paramInfo.trackingNumber,
        confirmationNumber: this.policyData.policyNumber,
        email: this.paramInfo.email,
        paymentMethod: "DD",
        firstName: this.contactName.firstName,
        receiptDate: this.datePipe.transform(this.myDate, 'M/d/yyyy, h:mm a'),
        MCPackageProtectionAmt: this.policyData.policyRequest.account.policy.premium,
        total: this.policyData.policyRequest.account.policy.premium,
        fileClaimURL: ENV.baseUrl.web + btoa(`ups-my-choice/claim-portal?policy=${this.policyData.policyNumber}&tracking=${this.paramInfo.trackingNumber}&email=${this.paramInfo.email}`),
        uID: this.paramRoutInfo.uID,
      }
    }
    this.claimService.sendMyChoicePaymentReceiptEmail(request).subscribe(
      data => { },
      error => { }
    );
  }

  contactUs() {
    this.taggingService.link({
      link_name: 'upsc_my_choice_contact_us',
      link_page_name: 'upsc_my_choice_congratulations'
    });
  }
}
