import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-jpmc-error-dialog',
  templateUrl: './jpmc-error-dialog.component.html',
  styleUrls: ['./jpmc-error-dialog.component.scss']
})
export class JpmcErrorDialogComponent {

  constructor(public dialogRef: MatDialogRef<JpmcErrorDialogComponent>) { }

  public cancel(){
    this.dialogRef.close();
  }

}
