import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { IdentityLayoutModule } from '../../../shared/components/identity-layout/identity-layout.module';
import { UpdatesuccessComponent } from './updatesuccess.component';
import { UpdateSuccessRoutingModule } from './updatesuccess.routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
  declarations: [
    UpdatesuccessComponent
  ],
  imports: [
    CommonModule,
    UpdateSuccessRoutingModule,
    SharedModule,
    IdentityLayoutModule,
    MatIconModule, 
    MatInputModule, 
  ]
})
export class UpdateSuccessModule { }
