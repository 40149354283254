import { Component, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-policies-sub-tab',
  templateUrl: './policies-sub-tab.component.html',
  styleUrls: ['./policies-sub-tab.component.scss']
})
export class PoliciesSubTabComponent {

  @Input() currentStep: number = 1;
  @Output() togglePageEvent = new EventEmitter<any>();

  isCanadaUser: boolean;
  isUsaUser: boolean;

  // for sub-header horizontal scroll
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  @ViewChild('parent') slider: ElementRef;
  startDragging(e, flag) {
    this.mouseDown = true;
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
  }
  stopDragging(e, flag) {
    this.mouseDown = false;
  }
  moveEvent(e) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const scroll = x - this.startX;
    this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
  }

  constructor(private userService: UserService) {
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUsaUser = this.userService.isUsaUser();
  }

  togglePage(source) {
    if (source == 'policyDetails') {
      this.currentStep = 1;
    }
    else if (source == 'commodities') {
      this.currentStep = 2;
    }
    else if (source == 'coverage') {
      this.currentStep = 3;
    }
    else if (source == 'endorsements') {
      this.currentStep = 4;
    }
    else if (source == 'documents') {
      this.currentStep = 5;
    }

    let pageStatus = {
      currentStep: this.currentStep,
      stepSource: source
    }
    
    this.togglePageEvent.emit(pageStatus);
  }
}
