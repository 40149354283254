import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PolicyService } from 'src/app/services/policy.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-is4ups-banner',
  templateUrl: './is4ups-banner.component.html',
  styleUrls: ['./is4ups-banner.component.scss']
})
export class Is4upsBannerComponent {

  showIS4UPSBanner: boolean = false;
  is4upsDaysLeft: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private policyService: PolicyService
  ) { 
    if ( (this.router?.url == '/dashboard') || (this.router?.url == '/billing') || ( /^\/claims(\/.*)?$/.test(this.router?.url) ) ) {
      this.determineIS4UPSBannerDisplay();
      //this.showIS4UPSBanner = true;
    } else {
      this.showIS4UPSBanner = false;
    }
  }

  ngOnInit() {}

  determineIS4UPSBannerDisplay() {
    if (this.userService.isIS4UAcquisitionUser()) {
      let conversionInfo = this.policyService.getIS4UPSQuoteDetails();
      const createdDateTime = new Date(conversionInfo?.createdDateTime);
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - createdDateTime.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
      const daysLeft = 30 - daysDifference;

      if (daysDifference < 30) { // quote is less than 30 days old
        this.showIS4UPSBanner = true;
        this.is4upsDaysLeft = daysLeft == 1 ? daysLeft + " day" : daysLeft + " days";
      } else {
        this.showIS4UPSBanner = false; // do not show banner is quote is more than 30 days old
      }
    } else {
      this.showIS4UPSBanner = false;
    }
  }

  closeIS4UPSBanner() {
    this.showIS4UPSBanner = false;
  }
  
  goToIS4UPS() {
    this.router.navigate(['/multicarrier']);
  }
}
