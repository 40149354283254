<div class="popup-global-layout width-750 p-4 p-md-5">
    <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
    <div class="global-title">
        {{ title }}
    </div>
    <div class="global-green-bar"></div>

    <div class="global-text" *ngIf="isCopyrightPresent">{{ copyrightMsgFirst }}<sup>®</sup> {{copyrightMsgLast}}.</div>
    <div class="global-text" *ngIf="!isCopyrightPresent">{{ message }}</div>
   
    <div class="global-button-format-new">        
        <button class="upsc-green-popup-button" (click)="delete()">
            {{ 'userManagement.buttons.removeUser' | translate }}<span class="pad-arrow-left">></span>
        </button>
        <div class="upsc-white-button" (click)="close()">
            {{ 'userManagement.buttons.cancel' | translate }}<span class="pad-arrow-left">></span>
        </div>
    </div> 
</div>


