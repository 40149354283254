import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProspectService } from 'src/app/services/prospect.service';
import { COMMODITY_LISTS } from 'src/app/shared/models/prospect-portal';
import { TaggingService } from 'src/app/tags/tagging.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'upsc-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class ReviewComponent implements OnInit {

  //Page logic
  submitting: boolean = false;
  pageLoading: boolean = false;
  hasUPS: boolean = false;

  //Errors
  systemError: boolean = false;
  submissionError: boolean = false;

  //Data
  prospectPortalData; //Data object that holds all data for prospect portal
  selectedProducts = [];

  constructor(
    private prospectService: ProspectService,
    private router: Router,
    private taggingService: TaggingService
  ) {
    this.pageLoading = true;
    this.gatherSessionData();
  }

  ngOnInit() {
    this.taggingService.view({
      page_id: "review&submit_page",
      page_name: "Review & Submit"
    });
  }

  edit(page: 'contact' | 'tax' | 'product' | 'shipper' | 'carrier' | 'details') {
    switch (page) {
      case 'product':
        this.router.navigate(['/prospect-portal/products']);
        break;
      case 'shipper':
        this.router.navigate(['/prospect-portal/shipping']);
        break;
      case 'carrier':
        this.router.navigate(['/prospect-portal/shipping']);
        break;
      case 'details':
        this.router.navigate(['/prospect-portal/shipping']);
        break;
      default:
        console.log("Something went wrong.");
        this.systemError = true;
    }
  }

  back() {
    this.taggingService.link({
      link_name: "Back",
      link_page_id: "review&submit_page"
    });
    this.router.navigate(['/prospect-portal/products']);
  }

  submit() {
    this.taggingService.link({
      link_name: "Continue",
      link_page_id: "review&submit_page"
    });
    this.submissionError = false;
    this.submitting = true;
    let request = {
      instances: [
        {
          contactName: this.prospectPortalData.customerName,
          companyName: this.prospectPortalData.customerCompany,
          addressLine: this.prospectPortalData.billingStreet,
          city: this.prospectPortalData.billingCity,
          state: this.prospectPortalData.billingStateCode,
          zipCode: this.prospectPortalData.billingPostalCode,
          phoneNumber: this.prospectPortalData.customerPhone,
          upsShipperNumber: this.prospectPortalData.shipperNumbers,
          commodityDetails: this.formProductKeys(),
          opportunityId: this.prospectPortalData.opportunityID,
          upscCustomerNumber: this.prospectPortalData.numberofParent,
          carrierDetails: this.prospectPortalData.selectedCarriers, //Might need to be ups and non-ups
          packageValue: this.prospectPortalData.valueOfShipments,
          packageVolume: this.prospectPortalData.numberOfShipments
        }
      ]
    }

    this.prospectService.getQuote(request).subscribe(
      data => {
        this.submitting = false;
        //Save the rate, save the quote id,
        if (data && data.data && data.data?.message.toLowerCase() == 'success') {
          try {
            this.prospectPortalData = { ...this.prospectPortalData,
              rate: (Math.round(data.data.rate * 100)/100),
              quote_ID: data.data.quote_ID
            }
            sessionStorage.setItem('prospectPortalData', JSON.stringify(this.prospectPortalData));

            this.navigateAfterSubmission();
          } catch (error) { //Probably if a rounding error occurs, it will end up here.
            this.submissionError = true;
          }
        } else if (data && data.data && data.data?.message.toLowerCase().includes('application under review')) {
          this.applicationUnderReview();
        } else {
          this.submissionError = true;
        }
      }, error => {
        this.submitting = false;
        this.submissionError = true;
      }
    );
  }

  gatherSessionData() {
    this.prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData'));

    if (this.prospectPortalData) {
      for (let i=0; i<this.prospectPortalData.selectedProducts.length; i++) {
        this.selectedProducts.push(COMMODITY_LISTS.find(x => x.key == this.prospectPortalData.selectedProducts[i]));
      }
      this.hasUPS = this.prospectPortalData.selectedCarriers.filter(x => x.toLowerCase() == 'ups').length > 0 ? true : false;
      this.prospectPortalData.selectedProductsKeysOnly = this.selectedProducts;
      this.pageLoading = false;
    } else {
      this.pageLoading = false;
      this.systemError = true;
    }
  }

  //If they were rejected, send them to under review page. If not, send them to quote page.
  navigateAfterSubmission() {
    let rejected = this.prospectPortalData.reviewStatus.toLowerCase() == 'pass' ? false : true;
    if (rejected) {
      this.prospectPortalData = { ...this.prospectPortalData,
        currentPage: '/prospect-portal/under-review'
      }
      sessionStorage.setItem('prospectPortalData', JSON.stringify(this.prospectPortalData));
      let request = {
        applicationName: 'OnlinePortal',
        emailFor: 'PPAppRejection',
        environment: environment.webjob.env,
        data: {
          upscCustomerNumber: this.prospectPortalData.numberofParent,
          companyName: this.prospectPortalData.customerCompany,
          contactName: this.prospectPortalData.customerName,
          phoneNumber: this.prospectPortalData.customerPhone,
          email: this.prospectPortalData.accountOwnerEmail,
          customerEmail: this.prospectPortalData.customerEmail
        },
        countryLocale: 'en-US'
      }

      this.prospectService.sendLexusRejectionEmail(request).subscribe(
        () => {
          this.saveSession();
          this.router.navigate(['/prospect-portal/under-review']); 
          this.scrollToTop();
        }  //As long as the API finishes, just navigate them.
      );
    } else {
      this.prospectPortalData = { ...this.prospectPortalData,
        currentPage: '/prospect-portal/quote'
      }
      sessionStorage.setItem('prospectPortalData', JSON.stringify(this.prospectPortalData));
      this.saveSession();
      this.router.navigate(['/prospect-portal/quote']);
      this.scrollToTop();
    }
  }


  applicationUnderReview() {
    this.checkForLexisNexisRejection(); //Even though they had invalid shipper numbers, we still need to check for Lexis rejection, and send an email if they did get rejected.
    this.prospectPortalData = { ...this.prospectPortalData,
      currentPage: '/prospect-portal/under-review'
    }
    sessionStorage.setItem('prospectPortalData', JSON.stringify(this.prospectPortalData));
    let request = {
      applicationName: "OnlinePortal",
      emailFor: "PPAppUnderReview",
      environment: environment.webjob.env,
      data: {
        upscCustomerNumber: this.prospectPortalData.numberofParent,
        companyName: this.prospectPortalData.customerCompany,
        contactName: this.prospectPortalData.customerName,
        phoneNumber: this.prospectPortalData.customerPhone,
        email: this.prospectPortalData.accountOwnerEmail,
        customerEmail: this.prospectPortalData.customerEmail
      },
      countryLocale: 'en-US'
    }
    
    this.prospectService.sendCRMReviewEmail(request).subscribe(
      (success) => {
        this.saveSession();
        this.router.navigate(['/prospect-portal/under-review']); 
        this.scrollToTop();
      }, (error) => {
        this.saveSession();
        this.router.navigate(['/prospect-portal/under-review']); 
        this.scrollToTop();
      }  //As long as the API finishes, just navigate them.
    );
  }

  //This method doesn't care about navigation. Just checks for rejection and sends email if true.
  checkForLexisNexisRejection() {
    let rejected = this.prospectPortalData.reviewStatus.toLowerCase() == 'pass' ? false : true;
    if (rejected) {
      this.prospectPortalData = { ...this.prospectPortalData,
        currentPage: '/prospect-portal/under-review'
      }
      sessionStorage.setItem('prospectPortalData', JSON.stringify(this.prospectPortalData));
      let request = {
        applicationName: 'OnlinePortal',
        emailFor: 'PPAppRejection',
        environment: environment.webjob.env,
        data: {
          upscCustomerNumber: this.prospectPortalData.numberofParent,
          companyName: this.prospectPortalData.customerCompany,
          contactName: this.prospectPortalData.customerName,
          phoneNumber: this.prospectPortalData.customerPhone,
          email: this.prospectPortalData.accountOwnerEmail,
          customerEmail: this.prospectPortalData.customerEmail
        },
        countryLocale: 'en-US'
      }

      this.prospectService.sendLexusRejectionEmail(request).subscribe(
        data => { },
        error => { }
      );
    }
  }

  saveSession() {
    this.prospectService.saveProspectPortalSession({
      opportunityID: this.prospectPortalData.opportunityID,
      emailAddress: this.prospectPortalData.customerEmail,
      data: JSON.stringify(this.prospectPortalData)
    }).subscribe(() => {});
  }

  formProductKeys() {
    let keys = [];
    this.selectedProducts.forEach(x => keys.push(x.key));
    return keys;
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0
    });
  }
}
