import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

const script = document.createElement('script');
script.type = 'text/javascript';
script.src = `https://tags.tiqcdn.com/utag/ups/ups-capital/${environment.tealiumEnv}/utag.sync.js`;
document.head.appendChild(script);

const scriptAsync = document.createElement('script');
scriptAsync.type="text/javascript";
scriptAsync.async=true;
scriptAsync.charset="utf-8";
scriptAsync.src = `https://tags.tiqcdn.com/utag/ups/ups-capital/${environment.tealiumEnv}/utag.js`;
document.head.appendChild(scriptAsync);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap().catch(err => console.log(err));
}

