import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';


@Component({
  selector: 'upsc-technology-agreement',
  templateUrl: './technology-agreement.component.html',
  styleUrls: ['./technology-agreement.component.scss']
})
export class TechnologyAgreementComponent implements OnInit {

  technologyAgreement;

  constructor(
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.getTerms();
  }

  getTerms() {
    let body = {
      "termDescription": "UPS Capital Group Technology Agreement",
      "hostSystem": "Capital Online",
      "termVersion": "1"
    }

    this.utilityService.getTerms(body).subscribe(
      data => {
        this.technologyAgreement = data.data.termBody;
      }, error => {
      }
    );
  }

}
