<div *ngIf="scriptError" class="response-error">Invalid Input</div>
<div class="wrapper-full profile-wrapper">
  <div class="wrapper-box" *ngIf="!isInternalUser">
    <div class="your-profile-header">
      <div class="header">{{ 'userProfile.contact.profile' | translate }}</div>
      <div class="desc" *ngIf="isUsaUser">
        {{ 'userProfile.contact.header1' | translate }} {{ 'userProfile.contact.header2' | translate }} <a
          href="mailto:capitalus@ups.com">capitalus@ups.com.</a>
      </div>
      <div class="desc" *ngIf="isCanadaUser">
        {{ 'userProfile.contact.header1' | translate }} {{ 'userProfile.contact.header2' | translate }} <a
          href="mailto:insureshieldca@ups.com">insureshieldca@ups.com.</a>
      </div>
      <div class="desc" *ngIf="isUkUser">
        {{ 'userProfile.contact.header1' | translate }} {{ 'userProfile.contact.header2' | translate }} <a
          href="mailto:risksolutionsuk@ups.com">risksolutionsuk@ups.com.</a>
      </div>
      <div class="desc" *ngIf="isGermanyUser">
        {{ 'userProfile.contact.header1' | translate }} {{ 'userProfile.contact.header2' | translate }} <a
          href="mailto:risksolutionsde@ups.com">risksolutionsde@ups.com.</a>
      </div>
      <div class="desc" *ngIf="isFranceUser">
        {{ 'userProfile.contact.header1' | translate }} {{ 'userProfile.contact.header2' | translate }} <a
          href="mailto:risksolutionsfr@ups.com">risksolutionsfr@ups.com.</a>
      </div>
      <div class="desc" *ngIf="isItalyUser">
        {{ 'userProfile.contact.header1' | translate }} {{ 'userProfile.contact.header2' | translate }} <a
          href="mailto:risksolutionsit@ups.com">risksolutionsit@ups.com.</a>
      </div>
    </div>
    <div class="your-profile-desktop flex-row">
      <div class="d-flex flex-column col-sm" style="padding-left: 0;padding-right: 0;">
        <div class="text bold">{{ 'userProfile.contact.name' | translate}}</div>
        <div class="text">{{ userDetails.firstName }} {{userDetails.lastName}}</div>
      </div>
      <div class="gray-divider"></div>
      <div class="d-flex flex-column col-sm" style="padding-left: 0;padding-right: 0;">
        <div class="text bold">{{ 'userProfile.email' | translate}}</div>
        <div class="text">{{ userDetails.emailAddress }}</div>
      </div>
      <div class="gray-divider"></div>
      <div class="d-flex flex-column col-sm" style="padding-left: 0;padding-right: 0;">
        <div class="text bold">{{ 'userProfile.phone' | translate}}</div>
        <div class="text"> {{ userDetails.contactPhone }}</div>
      </div>
    </div>
    <div class="your-profile-mobile">
      <div class="d-flex flex-row">
        <div class="bold">{{ 'userProfile.contact.name' | translate}}</div>
        <div class="ml-auto">{{ userDetails.firstName }} {{userDetails.lastName}}</div>
      </div>
      <div class="d-flex flex-row">
        <div class="bold">{{ 'userProfile.email' | translate}}</div>
        <div class="ml-auto">{{ userDetails.emailAddress }}</div>
      </div>
      <div class="d-flex flex-row">
        <div class="bold">{{ 'userProfile.phone' | translate}}</div>
        <div class="ml-auto">{{ userDetails.contactPhone }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="isInternalUser || isProfileManager">
    <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
      <div class="wrapper-box">
        <div class="policy-information">
          <div class="header">{{'userProfile.contact.info' | translate}}</div>
          <div [ngClass]="{'readonly': (isInternalUser && !isProfileManager)===true}">
            <div class="row">
              <div class="col-sm-12">
                <mat-form-field>
                  <mat-label>{{ 'userProfile.contact.cName' | translate }}</mat-label>
                  <input matInput formControlName="companyName">
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="row">
                <div class="col-6">
                  <mat-form-field>
                    <mat-label>{{ 'userProfile.contact.streetAddress1' | translate }}</mat-label>
                    <input matInput formControlName="addressLine1">
                    <mat-error>{{ 'userProfile.payee.errors.required' | translate }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field>
                    <mat-label>{{ 'userProfile.contact.streetAddress2' | translate }}</mat-label>
                    <input matInput formControlName="addressLine2">
                    <mat-error>{{ 'userProfile.payee.errors.required' | translate }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field>
                  <mat-label>{{ 'userProfile.autofill.cityPlaceholder' | translate }}</mat-label>
                  <input matInput formControlName="city">
                  <mat-error>{{ 'userProfile.payee.errors.required' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <mat-form-field>
                  <mat-label>{{ 'userProfile.autofill.statePlaceholder' | translate }}</mat-label>
                  <mat-select formControlName="state">
                    <mat-option>{{ 'userProfile.autofill.none' | translate }}</mat-option>
                    <mat-option *ngFor="let state of fullStateList" [value]="state.state">{{state.state}}</mat-option>
                  </mat-select>
                  <mat-error>{{ 'userProfile.payee.errors.required' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <mat-form-field>
                  <mat-label>{{ 'userProfile.autofill.zipPlaceholder' | translate }}</mat-label>
                  <input *ngIf="isUsaUser || isGermanyUser || isFranceUser || isItalyUser" matInput
                    formControlName="zip" maxlength="10">
                  <input *ngIf="isUkUser" matInput formControlName="zip" maxlength="8" minlength="2">
                  <input *ngIf="isCanadaUser" matInput formControlName="zip" maxlength="7">
                  <mat-error>{{ 'userProfile.payee.errors.required' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="isUsaUser">
              <div class="col-sm-3">
                <mat-form-field>
                  <mat-label>{{ 'userProfile.policyHolderEmail' | translate }}</mat-label>
                  <input matInput formControlName="policyHolderEmail">
                  <mat-error *ngIf="addressForm.controls['policyHolderEmail'].errors?.required">              
                    {{ 'userProfile.policyHolderEmailRequired' | translate }}
                  </mat-error>
                  <mat-error *ngIf="(addressForm.controls['policyHolderEmail'].errors?.invalid || addressForm.controls['policyHolderEmail'].errors?.email) && !addressForm.controls['policyHolderEmail'].errors?.required">
                    {{ 'userProfile.invalidEmailError' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <mat-form-field>
                  <mat-label>{{ 'userProfile.billingEmail' | translate }}</mat-label>
                  <input matInput formControlName="billingEmail">
                  <mat-error *ngIf="addressForm.controls['billingEmail'].errors?.required">              
                    {{ 'userProfile.billingEmailRequired' | translate }}
                  </mat-error>
                  <mat-error *ngIf="(addressForm.controls['billingEmail'].errors?.invalid || addressForm.controls['billingEmail'].errors?.email) && !addressForm.controls['billingEmail'].errors?.required">
                    {{ 'userProfile.invalidEmailError' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field>
                  <mat-label>{{ 'userProfile.policyHolderPhone' | translate }}</mat-label>
                  <input matInput upscPhoneNumber formControlName="workPhone" maxlength="12" minlength="12">
                  <mat-error *ngIf="addressForm.controls['workPhone'].errors?.required">{{ 'userProfile.policyHolderPhoneRequired' | translate }}</mat-error>
                  <mat-error *ngIf="addressForm.controls['workPhone'].errors?.minlength">{{ 'userProfile.policyHolderPhoneMinLength' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="message" *ngIf="isUsaUser">
        {{ 'userProfile.message1' | translate }}<sup>®</sup>{{ 'userProfile.message2' | translate }}
        <span class="link" (click)="goToManageUsers()"><span class="underline">{{ 'userProfile.manageUsers' | translate }}</span><mat-icon>keyboard_arrow_right</mat-icon></span>
      </div>
      <div class="update-buttons" *ngIf="isUsaUser && isProfileManager">
        <!-- Disabled button logic: 1) Form is invalid or 2) User is trying to update (not confirm) their policy, we are requiring them to at least change one of the fields before being allowed to update. -->
        <button *ngIf="!loading" class="upsc-button format" type="submit" [disabled]="addressForm.invalid || (!canEarnDWPoints && addressForm?.pristine)">
          <div *ngIf="!canEarnDWPoints">{{ 'userProfile.updatePolicy' | translate }}</div>
          <div *ngIf="canEarnDWPoints">{{ 'userProfile.confirmPolicy' | translate }}</div>
          <mat-icon>chevron_right</mat-icon>
        </button>
        <button *ngIf="loading" class="next-step-isop loading d-flex align-items-center">
          <div *ngIf="!canEarnDWPoints">{{ 'userProfile.updatePolicy' | translate }}</div>
          <div *ngIf="canEarnDWPoints">{{ 'userProfile.confirmPolicy' | translate }}</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
        <div *ngIf="systemError" class="error mt-2">{{ 'common.systemError' | translate }}</div>
        <div class="notification-box" *ngIf="showReminderMessage">
          <upsc-notification-box [isWarning]="true">
            <div class="box-format">
              <mat-icon>warning</mat-icon>
              <div class="text">{{ 'userProfile.reminderMessage' | translate }}</div>
            </div>
          </upsc-notification-box>
        </div>
        <div class="notification-box" *ngIf="showSuccessMessage">
          <upsc-notification-box [isSuccess]="true">
            <div class="box-format">
              <mat-icon>check_circle</mat-icon>
              <div class="text">{{ 'userProfile.successMessage' | translate }}</div>
            </div>
          </upsc-notification-box>
        </div>
      </div>
    </form>
  </div>
</div>