<div class="popup-global-layout digital-wallet-TCs">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">Improved Claims Experience</div>
  <div class="global-green-bar"></div>
  <img src="../../../../assets/images/dw-TCs-stepper.png">
  <div class="global-text mt-3" *ngIf="isUPSCBilled">
    Exciting News! Your UPS Wallet has been created. You now have more transfer options for your approved claims payouts with instant transfer, ACH or Zelle. You are also eligible to start earning reward points that you can redeem to offset your UPS Capital invoices! Once you click ‘Enroll in UPS Wallet’ you will have access to the new UPS Wallet feature and rewards!
  </div>
  <div class="global-text mt-3" *ngIf="isUPSBilled">
    Exciting News! Your UPS Wallet has been created. You now have more transfer options for your approved claims payouts with instant transfer, ACH or Zelle. Once you click ‘Enroll in UPS Wallet’ you will have access to the new UPS Wallet feature!
  </div>
  <div class="global-button-format">
    <button class="upsc-primary-button icon-right" *ngIf="!loading" (click)="enroll()">Enroll in UPS Wallet <mat-icon>open_in_new</mat-icon></button>
    <button class="upsc-primary-button icon-right" *ngIf="loading" disabled>Enroll in UPS Wallet <span class="spinner-border"></span></button>
    <button class="upsc-secondary-button icon-right" (click)="startClaimFlow()">Request One-Time Check <mat-icon>keyboard_arrow_right</mat-icon></button>
  </div>
  <div class="error" *ngIf="sessionError">Something went wrong! Please try again later.</div>
</div>