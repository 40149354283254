import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { IPolicies } from '../shared/models/policies.interface';
import { environment as ENV } from '../../environments/environment';
import { ThemeInfo } from '../shared/models/theme-info';
import themes from 'src/assets/themes.json';
import { TabData } from '../shared/models/tab-data';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { emptyIEmail } from '../shared/models/email.interface';

@Injectable({
  providedIn: 'root'
})
export class DynamicFlowService {

  constructor(private dataService: DataService,
    private router: Router) { }

  themeData: any;
  currentThemeInfo: ThemeInfo = {};
  tabData: TabData = {};

  //Returns request object for redirection to DPAT based on current channel partner
  getCcpRequest(policy: IPolicies, email: string) {
    let shipmentInfo = this.dataService._channelPartnerShipmentDetails;
    // let persistIShipData = JSON.parse(sessionStorage.getItem('persistIShipData'));
    let recipient = this.dataService?._track3ShipmentDetails?.shipperConsigneeAddress.find(x => x.description?.toLowerCase() == 'shipto address');
    let request = {
      action: 'New Payee',
      paymentDirection: 'Claims',
      country: 'US',
      paymentMode: '',
      policyIdentifier: policy.policyNumber ? policy.policyNumber : '',
      email: email,
      callBackURL: `${ENV.baseUrl.web}${this.getCcpTupssFlow()}/file`,
      scenario: 'tupss',
      createdByUserId: '',
      guestUserIndicator: "N",
      locale: sessionStorage.getItem('locale'),
      tupssFlow: this.dataService.flowType == 'pirateship' ? 'pirateship' : this.getCcpTupssFlow(),
      policyData: {
        insured: policy.insured
      },
      iShipData: {
        companyName: shipmentInfo.shipperName
          ? shipmentInfo.shipperName
          : recipient?.companyName
            ? recipient.companyName
            : "",
        email: email,
        phone: '',
        addressLine1: shipmentInfo.originAddress,
        addressLine2: '',
        city: shipmentInfo.originCity,
        state: shipmentInfo.originState,
        zipCode: shipmentInfo.originPostalCode,
        payeeId: ""
      }
    }
    return request;
  }

  //Returns request object to send email for claim submission, for current CCP portal
  getCCPClaimSubmissionEmailRequest(policyNumber: string, email: string, claimNumber: string, firstName: string) {
    let ccpUrl = '';
    if (this.dataService.flowType === 'customized-claims-portal') {
      ccpUrl = 'unishippers';
    } else if (this.dataService.flowType === 'pirateship') {
      ccpUrl = 'pirate-ship';
    }
    else {
      ccpUrl = this.dataService.flowType;
    }

    let shipmentInfo = this.dataService._channelPartnerShipmentDetails;
    let request = emptyIEmail;
    request = {
      ...request,
      emailFor: 'CCPClaimConfirmation',
      CountryLocale: sessionStorage.getItem('locale'),
      data: {
        email: email,
        claimNumber: claimNumber,
        claimDetailLink: ENV.baseUrl.web + btoa(`${ccpUrl}/detail?policy=${policyNumber}&claimNumber=${claimNumber}&email=${email}`),
        firstName: firstName,
        companyName: shipmentInfo.consigneeName,
        streetAddress: shipmentInfo.destinationAddress,
        city: shipmentInfo.destinationCity,
        state: shipmentInfo.destinationState,
        zip: shipmentInfo.destinationPostalCode,
        lastName: '',
        channelPartnerName: this.dataService.flowType == 'customized-claims-portal' ? 'Unishippers' : this.dataService.flowType == 'pirateship' ? 'Pirate Ship' : this.dataService.flowType,
        policyNumber: policyNumber,
        trackingNumber: shipmentInfo.trackingNumber,
        shipDate: shipmentInfo.shipDate
      }
    }
    return request;
  }

  //Returns the name of current flow required for CCP portals for DPAT redirection
  private getCcpTupssFlow() {
    if (this.dataService.flowType == 'customized-claims-portal') {
      return 'unishippers';
    } else if
      (this.dataService.flowType == 'pirateship') {
      return 'pirate-ship';

    }
    else
      return this.dataService.flowType;
  }

  //Returns current theme data object based on current channel partner.
  loadThemeData(channelPartner) {
    this.themeData = JSON.parse(JSON.stringify(themes));
    let currentTheme = this.themeData.themes.find(x => x.flowType === channelPartner);
    if (currentTheme !== undefined || currentTheme !== null) {
      this.currentThemeInfo.logoDesktop = currentTheme.logo_desktop;
      this.currentThemeInfo.logoMobile = currentTheme.logo_mobile;
      this.currentThemeInfo.channelPartnerLogo = currentTheme.channel_partner_logo;
      if (this.currentThemeInfo.channelPartnerLogo.length > 0) {
        this.currentThemeInfo.isChannelPartnerLogo = true;
      }
      this.currentThemeInfo.channelPartnerLogoMobile = currentTheme.channel_partner_logo_mobile;
      //this.currentThemeInfo.navColor = currentTheme.navColor;
    } else {
      let currentTheme = this.themeData.themes.find(x => x.flowType === 'default'); //default theme
      this.currentThemeInfo.logoDesktop = currentTheme.logo_desktop;
      this.currentThemeInfo.logoMobile = currentTheme.logo_mobile;
      this.currentThemeInfo.channelPartnerLogo = currentTheme.channel_partner_logo;
      if (currentTheme.channelPartnerLogo.length > 0) {
        this.currentThemeInfo.isChannelPartnerLogo = true;
      }
      this.currentThemeInfo.channelPartnerLogoMobile = currentTheme.channel_partner_logo_mobile;
      //this.currentThemeInfo.navColor = currentTheme.navColor;
    }

    return this.currentThemeInfo;
  }


  //Returns the object which contains data to set top menu active tab
  setTabData() {
    this.tabData.isClaimTabActive = true;
    this.tabData.isFaqTabActive = false;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.endsWith('/faq')) {
          this.tabData.isClaimTabActive = false;
          this.tabData.isFaqTabActive = true;
          sessionStorage.removeItem("unishippersFile");
          sessionStorage.removeItem("ajgFile");
          sessionStorage.removeItem("inxpressFile");
          sessionStorage.removeItem("upshealthcareFile");
          sessionStorage.removeItem("freightcenterincFile");
          sessionStorage.removeItem("pirateshipFile");
        }

        if (this.router.url.endsWith('/unishippers' || '/file')) {
          this.tabData.isFaqTabActive = false;
          this.tabData.isClaimTabActive = true;
          sessionStorage.setItem("unishippersFile", "file");
        }

        if (this.router.url.endsWith('/ajg' || '/file')) {
          this.tabData.isFaqTabActive = false;
          this.tabData.isClaimTabActive = true;
          sessionStorage.setItem("ajgFile", "file");
        }

        if (this.router.url.endsWith('/inxpress' || '/file')) {
          this.tabData.isFaqTabActive = false;
          this.tabData.isClaimTabActive = true;
          sessionStorage.setItem("inxpressFile", "file");
        }
      }

      var unishippersFile = sessionStorage.getItem("unishippersFile");
      if (unishippersFile !== null && unishippersFile === 'file') {
        this.tabData.isClaimTabActive = true;
        this.tabData.isFaqTabActive = false;
      }

      var ajgFile = sessionStorage.getItem("ajgFile");
      if (ajgFile !== null && ajgFile === 'file') {
        this.tabData.isClaimTabActive = true;
        this.tabData.isFaqTabActive = false;
      }

      var inxpressFile = sessionStorage.getItem("inxpressFile");
      if (inxpressFile !== null && inxpressFile === 'file') {
        this.tabData.isClaimTabActive = true;
        this.tabData.isFaqTabActive = false;
      }

      var upshealthcareFile = sessionStorage.getItem("upshealthcareFile");
      if (upshealthcareFile !== null && upshealthcareFile === 'file') {
        this.tabData.isClaimTabActive = true;
        this.tabData.isFaqTabActive = false;
      }

      var freightcenterincFile = sessionStorage.getItem("freightcenterincFile");
      if (freightcenterincFile !== null && freightcenterincFile === 'file') {
        this.tabData.isClaimTabActive = true;
        this.tabData.isFaqTabActive = false;
      }

      var pirateshipFile = sessionStorage.getItem("pirateshipFile");
      if (pirateshipFile !== null && pirateshipFile === 'file') {
        this.tabData.isClaimTabActive = true;
        this.tabData.isFaqTabActive = false;
      }
    });

    return this.tabData;
  }

  //Checks and returns boolean: true/false value if the flow is for channel partner portals
  public checkCcpUrl() {
    let returnVal = false;
    if (this.router.url.includes('unishippers') || this.router.url.includes('ajg') || this.router.url.includes('inxpress') || this.router.url.includes('pirate-ship') || this.router.url.includes('guest')) {
      returnVal = true;
    }
    return returnVal;
  }



  //Checks and returns string value for current channel partner portals
  setChannelPartner() {
    let channelPartner = ' ';
    if (this.router.url.includes('unishippers')) {
      channelPartner = 'unishippers';
    } else if (this.router.url.includes('ajg') || this.router.url.includes('guest')) {  //guest is re-using AJG because they both share no style adjustments
      channelPartner = 'ajg';
    } else if (this.router.url.includes('inxpress')) {
      channelPartner = 'inxpress';
    } else if (this.router.url.includes('pirate-ship')) {
      channelPartner = 'pirate-ship';
    }
    return channelPartner;
  }

  //Checks and returns string value for displaying current channel partner portals
  setChannelPartnerName() {
    let channelPartnerName = ' ';
    if (this.router.url.includes('unishippers')) {
      channelPartnerName = 'Unishippers';
    } else if (this.router.url.includes('ajg')) {
      channelPartnerName = 'AJG';
    } else if (this.router.url.includes('inxpress')) {
      channelPartnerName = 'InXpress';
    } else if (this.router.url.includes('pirate-ship')) {
      channelPartnerName = 'pirate-ship';
    }
    return channelPartnerName;
  }

  //Redirect to claim details for ccp portals
  public exitToClaimDetails(submittedClaimNumber: string) {
    if (this.dataService.flowType === 'customized-claims-portal') {
      this.router.navigate(['/unishippers/detail'], { queryParams: { claimNumber: submittedClaimNumber } });
    } else if (this.dataService.flowType === 'ajg') {
      this.router.navigate(['/ajg/detail'], { queryParams: { claimNumber: submittedClaimNumber } });
    } else if (this.dataService.flowType === 'inxpress') {
      this.router.navigate(['/inxpress/detail'], { queryParams: { claimNumber: submittedClaimNumber } });
    } else if (this.dataService.flowType === 'pirateship') {
      this.router.navigate(['/pirate-ship/detail'], { queryParams: { claimNumber: submittedClaimNumber } });
    }
  }

  //Redirect to ccp portal based on channel partner/flow type and current step
  public checkStepNRedirectForCcp(currentStep: number) {
    if (this.dataService.flowType == 'customized-claims-portal' && currentStep == 1) {
      this.router.navigate(['unishippers']);
    } else if (this.dataService.flowType == 'ajg' && currentStep == 1) {
      this.router.navigate(['ajg']);
    } else if (this.dataService.flowType == 'inxpress' && currentStep == 1) {
      this.router.navigate(['inxpress']);
    } else if (this.dataService.flowType == 'pirateship' && currentStep == 1) {
      this.router.navigate(['pirate-ship']);
    }
  }


  //Redirects to ccp portal based on channel partner/flow type
  public redirectToCcp() {
    if (this.dataService.flowType === 'customized-claims-portal') {
      this.router.navigate(['unishippers']);
    } else if (this.dataService.flowType === 'ajg') {
      this.router.navigate(['ajg']);
    } else if (this.dataService.flowType === 'inxpress') {
      this.router.navigate(['inxpress']);
    } else if (this.dataService.flowType === 'pirateship') {
      this.router.navigate(['pirate-ship']);
    }
  }

  //Redirects to file a claim for current channel partner
  public redirectToFileClaimCcp() {
    if (this.dataService.flowType === 'customized-claims-portal') {
      sessionStorage.setItem("unishippersFile", "file");
      this.router.navigate(['/unishippers/file']);
    } else if (this.dataService.flowType === 'ajg') {
      sessionStorage.setItem("ajgFile", "file");
      this.router.navigate(['/ajg/file']);
    } else if (this.dataService.flowType === 'inxpress') {
      sessionStorage.setItem("inxpressFile", "file");
      this.router.navigate(['/inxpress/file']);
    } else if (this.dataService.flowType === 'pirateship') {
      sessionStorage.setItem("pirateshipFile", "file");
      this.router.navigate(['/pirate-ship/file']);
    }
  }

  //Checks and returns boolean: true/false if the flow is for ccp portals.
  checkUrlForCcpPortals(urls) {
    let returnVal = false;
    if (urls[0].includes('unishippers') || urls[0].includes('ajg') || urls[0].includes('inxpress') || urls[0].includes('pirate-ship')) {
      returnVal = true;
    }
    return returnVal;
  }

}
