<div class="contact-header">{{ 'claims.edit.contactInfo.contactInformation.title' | translate }}</div>
<form [formGroup]="frmGroupChoiceInfo">
  <mat-radio-group formControlName="choice" class="my-2" [(ngModel)]="contactType">
    <div *ngIf="!isDDUser" class="rdoCard" [ngClass]="{'default-background': selectedValue!=='0', 'selected': selectedValue==='0'}">
      <div class="title">
        <mat-radio-button value="0">
          {{'claims.edit.contactInfo.contactInformation.contactForm.policyHolderLabel' | translate}} - {{
          policy.insured }}
        </mat-radio-button>
      </div>
      <div class="cntainer-block">
        <div class="email">{{userDetails.emailAddress}}</div>
        <div class="address">{{userDetails.contactPhone | phoneNumber}}</div>
        <div class="address">{{ policy.addressLine1_Ext }} {{ policy.addressLine2_Ext }}{{ policy.city }},
          <span *ngIf="isUsaUser || isCanadaUser">{{ policy.state }},</span> {{ policy.zip }}, {{
          policy.policyCountry_Ext }}
        </div>
      </div>
    </div>
    <!-- Only show the payee info card for non-digital wallet users (for wallet + T&Cs accepted users, ) -->
    <div class="rdoCard" *ngIf="persistPayeeInfo && persistPayeeInfo.email" [ngClass]="{'default-background': selectedValue!=='1', 'selected': selectedValue==='1'}">
      <div class="title">
        <mat-radio-button value="1">
          {{'claims.edit.contactInfo.contactInformation.contactForm.recipientLabel' | translate}} - {{
          persistPayeeInfo.payeeName}}
        </mat-radio-button>
      </div>
      <div class="cntainer-block">
        <div class="email">{{persistPayeeInfo.email}}</div>
        <div class="address">{{persistPayeeInfo.phoneNumber | phoneNumber}}</div>
        <div class="address">{{ persistPayeeInfo.addressLine1 }} {{ persistPayeeInfo.addressLine2 }}, {{
          persistPayeeInfo.city }},
          <span *ngIf="isUsaUser || isCanadaUser">{{ persistPayeeInfo.state }},</span> {{
          persistPayeeInfo.zip }}, {{
          persistPayeeInfo.country }}
        </div>
      </div>
    </div>
    <div class="rdoCard" [ngClass]="{'default-background': selectedValue!=='2', 'selected': selectedValue==='2'}">
      <div class="title">
        <div class="icon" (click)="addAnother()">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="icon-text">Add Another</span>
        </div>
        <div class="sub-title">{{'claims.edit.contactInfo.contactInformation.contactForm.choiceLabel' |
          translate }}</div>
      </div>
      <div class="cntainer" *ngIf="contactType==='2'">
        <form [formGroup]="frmGroupContactInfo">
          <mat-form-field class="input-width">
            <mat-label>{{
              'claims.edit.contactInfo.contactInformation.contactForm.firstName.label' |
              translate
              }}</mat-label>
            <input matInput required formControlName="firstName" maxlength="30">
            <mat-error *ngIf="frmGroupContactInfo.get('firstName').errors?.required">
              {{
              'claims.edit.contactInfo.contactInformation.contactForm.firstName.errors.required'
              | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="input-width">
            <mat-label>{{
              'claims.edit.contactInfo.contactInformation.contactForm.lastName.label' |
              translate
              }}</mat-label>
            <input matInput required formControlName="lastName" maxlength="30">
            <mat-error *ngIf="frmGroupContactInfo.get('lastName').errors?.required">
              {{
              'claims.edit.contactInfo.contactInformation.contactForm.lastName.errors.required'
              | translate }}
            </mat-error>
          </mat-form-field>

          <div class="country-code-inputs" *ngIf="isItalyUser || isFranceUser || isGermanyUser || isUkUser">
            <mat-form-field class="input-code">
              <mat-label>{{ 'payee.creation.form.countryCode' | translate }}</mat-label>
              <span matPrefix>+ &nbsp;</span>
              <input matInput formControlName="countryCode" (keypress)="numberOnly($event)" maxlength="3"  [(ngModel)]="phoneCode"
                  minlength="1" matInput>
              <mat-error *ngIf="frmGroupContactInfo.get('countryCode').errors?.required">
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{
                    'claims.edit.contactInfo.contactInformation.contactForm.countryCode.errors.required'
                    |
                    translate }}</div>
                </div>
              </mat-error>
              <mat-error *ngIf="frmGroupContactInfo.get('countryCode').errors?.pattern">
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text"> {{
                    'claims.edit.contactInfo.contactInformation.contactForm.countryCode.errors.invalid'
                    | translate
                    }}</div>
                </div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="input-text">
              <mat-label>
                {{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.label' |
                translate }}
              </mat-label>
              <input required *ngIf="isItalyUser || isFranceUser || isGermanyUser || isUkUser" matInput
                  formControlName="phoneNumber" (keypress)="numberOnly($event)" maxlength="12" [(ngModel)]="phoneNumber">
              <input *ngIf="isGuestUser" matInput upscPhoneNumber required formControlName="phoneNumber"
                  [(ngModel)]="phoneNumber" maxlength="12" minlength="12">
              <mat-error *ngIf="frmGroupContactInfo.get('phoneNumber').errors?.required">
                <div class="error-input">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{
                    'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.required'
                    |
                    translate }}</div>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field *ngIf="isCanadaUser || isUsaUser" class="input-width">
            <mat-label>{{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.label' |
              translate
              }}</mat-label>
            <input *ngIf="!isGuestUser" matInput upscPhoneNumber formControlName="phoneNumber"
                [(ngModel)]="phoneNumber" maxlength="12" minlength="12" (keypress)="numberOnly($event)">
            <input *ngIf="isGuestUser" matInput upscPhoneNumber required formControlName="phoneNumber"
                [(ngModel)]="phoneNumber" maxlength="12" minlength="12" (keypress)="numberOnly($event)">
            <mat-error *ngIf="frmGroupContactInfo.get('phoneNumber').errors?.minlength">
              {{
              'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.invalid'
              |
              translate }}
            </mat-error>
            <mat-error *ngIf="frmGroupContactInfo.get('phoneNumber').errors?.required">
              {{
              'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.required'
              | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="input-width">
            <mat-label>{{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.label' |
              translate
              }}</mat-label>
            <input matInput required formControlName="emailAddress">
            <mat-error *ngIf="frmGroupContactInfo.get('emailAddress').errors?.required">
              {{
              'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.required'
              | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="frmGroupContactInfo.get('emailAddress').errors?.pattern || frmGroupContactInfo.get('emailAddress').errors?.invalid || frmGroupContactInfo.get('emailAddress').errors?.email">
              {{
              'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valid'
              |
              translate }}
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
    </mat-radio-group>
    <div class="input-wrapper">
      <mat-error
        *ngIf="frmGroupChoiceInfo.invalid && frmGroupChoiceInfo.get('choice').errors && frmGroupChoiceInfo.controls['choice']?.touched">
        Please select contact
      </mat-error>
    </div>
    <div class="step-controls-isop">
      <div class="d-flex continue-save">
        <button class="upsc-button format" *ngIf="!continuing"
          (click)="saveContact($event)">{{ 'claims.edit.continue' | translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
        <button class="next-step-isop loading d-flex align-items-center justify-content-between mr-2" *ngIf="continuing">
          <div>{{ 'claims.edit.continue' | translate }}</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
        <button class="upsc-white-button" *ngIf="isUsaUser && !isGuestUser"
          (click)="openDraftConfirmation()">{{ 'claims.edit.navi.save' | translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
      </div>
      <div class="d-flex align-items-center ml-auto">
        <a class="link" (click)="back($event)">{{ 'claims.edit.back' | translate }}</a>
        <div class="vertical-border"></div>
        <a class="link" (click)="cancel($event)">{{ 'claims.edit.cancel' | translate }}</a>
      </div>
    </div>
</form>
