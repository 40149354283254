<div class="spinner d-flex justify-content-center mb-5" *ngIf="showSpinner && !showError">
  <div class="spinner-border">
    <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>
<div class="error" *ngIf="showError">
  {{ 'guestVerification.form.systemError' | translate }}
</div>
<div *ngIf="sortedData && sortedData.length==0">
  <div>
    {{ 'payee.table.none2' | translate }}
  </div>
</div>
<div class="d-flex align-items-center flex-sm-row flex-wrap">
  <div class="custom_search">
    <div class="text" *ngIf="isHidesearch">{{ 'payee.table.search' | translate }}</div>
    <div class="search-wrap d-flex justify-content-between">
      <div class="d-flex align-items-center flex-wrap mb-3" *ngIf="isHidesearch">
        <div class="radio-group">
          <mat-radio-group required [(ngModel)]="searchByValue" (change)="changeCategory()">
            <mat-radio-button class="mr-3" value="recipientName" id="recipientName"> {{ 'payee.table.recipientName' | translate }} </mat-radio-button>
            <mat-radio-button class="mr-3" value="recipientEmail" id="recipientEmail"> {{ 'payee.table.recipientEmail' | translate }} </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="search-bar">
          <input [formControl]="searchTermControl" type="text" name="searchQuery" class="mb-3 mb-md-0" [(ngModel)]="searchQuery">
          <br />
            <span class="error fs12" *ngIf="searchQueryLength">
              {{ 'payee.table.querylengthmsg' | translate }}
            </span>
        </div>
        <div class="button-submit mb-3 mb-md-0">
          <button type="submit" [disabled]="!queryEntered" class="upsc-button" upscDebounce [debounceTime]="1000" (debounceClick)="search()">{{ 'payee.table.search' | translate }}</button>
        </div>
      </div>
      <div class="mb-3">
        <button *ngIf="!showSpinner && !showError && !isGuestUser" class="upsc-white-button" routerLink="/claims/payee/create" (click)="tagging()">
          + {{ 'payee.table.add' | translate }}
        </button>
        <button *ngIf="!showSpinner && !showError && isGuestUser" class="upsc-white-button" (click)="dpatGuest()">
          + {{ 'payee.table.add' | translate }}
        </button>
        <button>maybe add a 'manage claim payment recipient' button here to navigate to dpat for guest user flow?</button>
      </div>
    </div>
  </div>
</div>

<div class="payee-selection-wrapper">
  <form [formGroup]="payeeForm">
    <mat-radio-group formControlName="mobilePayee" name="mobilePayee">
      <div class="mobile" *ngIf="sortedData && sortedData.length>0">
        <mat-accordion class="upsc-accordion">
          <mat-expansion-panel class="panel" *ngFor="let payee of pagedPayeeList">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div *ngIf="payee.payeeID===payeeIdSelected">
                  <mat-radio-button *ngIf="showSelections" [checked]="true" class="name" [value]="payee">{{ payee.payeeName }}</mat-radio-button>
                </div>
                <div *ngIf="payee.payeeID!==payeeIdSelected">
                  <mat-radio-button *ngIf="showSelections" [checked]="false" class="name" [value]="payee">{{ payee.payeeName }}</mat-radio-button>
                </div>                
                <div class="break" *ngIf="!showSelections">{{ payee.payeeName }}</div>
              </mat-panel-title>
              <mat-panel-description *ngIf="payee.achIndicator=='F' && showSelections && isUsaUser">
                <div class="upsc-link d-flex align-items-center" (click)="openBankDetails(payee, true)">{{ 'payee.table.check' | translate }}</div>
              </mat-panel-description>
              <mat-panel-description *ngIf="payee.achIndicator=='F' && !showSelections && isUsaUser">
                <div class="button-wrapper">
                  <button class="upsc-rectangle-button" (click)="addBankInfo(payee)">
                    <mat-icon>add</mat-icon>{{ 'payee.buttons.bank' | translate }}
                  </button>
                </div>
                <div class="mx-3 delete-icon" (click)="removePayee(payee)"><img src="../../../../assets/images/delete_icon.svg"></div>
              </mat-panel-description>
              <mat-panel-description class="provided" *ngIf="payee.achIndicator=='T' && showSelections && isUsaUser">
                <div class="mr-1">{{ 'payee.table.electronic' | translate }}</div>
                <div class="upsc-link" (click)="openBankDetails(payee, false)">{{ 'payee.table.details' | translate }}</div>
              </mat-panel-description>
              <mat-panel-description class="provided" *ngIf="payee.achIndicator=='T' && !showSelections && isUsaUser">
                <div class="mr-1">{{ 'payee.table.provided' | translate }}</div>
                <div class="upsc-link" (click)="openBankDetails(payee, false)">{{ 'payee.table.details' | translate }}</div>
                <div class="mx-3 delete-icon" (click)="removePayee(payee)"><img src="../../../../assets/images/delete_icon.svg"></div>
              </mat-panel-description>
              <mat-panel-description class="provided" *ngIf="isCanadaUser || isUkUser || isItalyUser || isFranceUser || isGermanyUser">
                <!-- <div class="d-block">
                <div>{{ payee.address.streetAddress1 }}</div>
                <div>{{ payee.address.streetAddress2 }}</div>
                <div>{{ payee.address.city }}, {{ payee.address.stateProvince }} {{ payee.address.zip }}</div>
              </div> -->
                <div class="mx-3 delete-icon" (click)="removePayee(payee)"><img src="../../../../assets/images/delete_icon.svg"></div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-contents">
              <div class="pb-1">{{ payee.payeeEmail }}</div>
              <div class="pb-1">{{ payee.payeePhone | phoneNumber }}</div>
              <!-- <div class="pb-1" *ngIf="isCanadaUser">{{ payee.payeePhone }}</div> -->
              <div>{{ payee.address.streetAddress1 }}</div>
              <div>{{ payee.address.streetAddress2 }}</div>
              <div>{{ payee.address.city }}, {{ payee.address.stateProvince }} {{ payee.address.zip }}</div>
            </div>
          </mat-expansion-panel>
          <div class="panel-contents" *ngIf="emptySearchResult">
            <div>{{ "payee.table.searchNoResults" | translate}}</div>
          </div>
        </mat-accordion>
      </div>
    </mat-radio-group>
    <div class="desktop" *ngIf="sortedData && sortedData.length>0">
      <mat-radio-group formControlName="payee" name="payee">
        <table class="upsc-table">
          <tbody>
            <tr class="head">
              <th (click)="sortData('name')" class="d-flex align-items-center">
                {{ 'payee.table.name' | translate }}
                <mat-icon *ngIf="nameSort">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="!nameSort">keyboard_arrow_up</mat-icon>
              </th>
              <th class="email">{{ 'payee.table.email' | translate }}</th>
              <th>{{ 'payee.table.phone' | translate }}</th>
              <th>{{ 'payee.table.address' | translate }}</th>
              <th class="bank" *ngIf="isUsaUser">{{ 'payee.table.bank' | translate }}</th>
            </tr>
            <tr>
              <td colspan="4" class="no-results-found" *ngIf="emptySearchResult && !isUsaUser">
                <div>{{ "payee.table.searchNoResults" | translate}}</div>
              </td>
              <td colspan="5" class="no-results-found" *ngIf="emptySearchResult && isUsaUser">
                <div>{{ "payee.table.searchNoResults" | translate}}</div>
              </td>
            </tr>
            <tr *ngFor="let payee of pagedPayeeList">
              <td>
                <div *ngIf="payee.payeeID===payeeIdSelected">
                  <mat-radio-button *ngIf="showSelections" [checked]="true" class="name" [value]="payee">{{ payee.payeeName }}</mat-radio-button>
                </div>
                <div *ngIf="payee.payeeID!==payeeIdSelected">
                  <mat-radio-button *ngIf="showSelections" [checked]="false" class="name" [value]="payee">{{ payee.payeeName }}</mat-radio-button>
                </div>
                <div class="break" *ngIf="!showSelections">{{ payee.payeeName }}</div>
              </td>
              <td class="email">{{ payee.payeeEmail }}</td>
              <td class="phone" >{{ payee.payeePhone | phoneNumber }}</td>
              <td>
                <div class="d-flex align-items-center justify-content-between">
                <div class="address" >
                  <div>{{ payee.address.streetAddress1 }}</div>
                  <div *ngIf="payee.address.streetAddress2">{{ payee.address.streetAddress2 }}</div>
                  <div>{{ payee.address.city }}, <div *ngIf="isUsaUser || isCanadaUser">{{ payee.address.stateProvince }}</div> {{ payee.address.zip }}</div>
                  <div>{{ payee.address.country }}</div>
                </div>                
                <div class="mx-3 delete-icon" *ngIf="!showSelections && isCanadaUser || isUkUser || isItalyUser || isFranceUser || isGermanyUser" (click)="removePayee(payee)"><img src="../../../../assets/images/delete_icon.svg"></div>
                </div>                
              </td>
              <td *ngIf="payee.achIndicator=='T' && showSelections && isUsaUser">
                <div>{{ 'payee.table.electronic' | translate }}</div>
                <div class="upsc-link" (click)="openBankDetails(payee, false)">{{ 'payee.table.details' | translate }}</div>
              </td>
              <td *ngIf="payee.achIndicator=='F' && showSelections && isUsaUser" class="d-table-cell">
                <div class="upsc-link d-flex align-items-center" (click)="openBankDetails(payee, true)">{{ 'payee.table.check' | translate }}</div>
              </td>
              <td *ngIf="payee.achIndicator=='T' && !showSelections && isUsaUser">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex flex-column">
                    <div>{{ 'payee.table.provided' | translate }}</div>
                    <div class="upsc-link" (click)="openBankDetails(payee, false)">{{ 'payee.table.details' | translate }}</div>
                  </div>
                  <div class="mx-3 delete-icon" (click)="removePayee(payee)"><img src="../../../../assets/images/delete_icon.svg"></div>
                </div>
              </td>
              <td *ngIf="payee.achIndicator=='F' && !showSelections && isUsaUser">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="button-wrapper">
                    <button class="upsc-rectangle-button" (click)="addBankInfo(payee)">
                      <mat-icon>add</mat-icon>{{ 'payee.buttons.bank' | translate }}
                    </button>
                  </div>
                  <div class="mx-3 delete-icon" (click)="removePayee(payee)"><img src="../../../../assets/images/delete_icon.svg"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-radio-group>
    </div>
  </form>
  <div class="paginationAlign" *ngIf="isPagination">
    <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
      <li [ngClass]="{disabled:pager.currentPage === 1}">
        <a class="compact-link" (click)="setPage(1)">{{ 'guestVerification.form.first' | translate }}</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === 1}">
        <a class="compact-link" (click)="setPage(pager.currentPage - 1)"><em
            class="material-icons">chevron_left</em></a>
      </li>
      <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
        <a (click)="setPage(page)">{{page}}</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="compact-link" (click)="setPage(pager.currentPage + 1)"><em
            class="material-icons">chevron_right</em></a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="compact-link" (click)="setPage(pager.totalPages)">{{ 'guestVerification.form.last' | translate }}</a>
      </li>
    </ul>
  </div>
</div>