import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscoreConversion'
})
export class UnderscoreConversionPipe implements PipeTransform {

  transform(categories: any): string {
    categories = categories[0].toUpperCase() + categories.substring(1);
    return categories.replace(/_/g, ' ');
  }

}
