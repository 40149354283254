<div class="upsc-custom-payee-create">
<form [formGroup]="payeeForm" (ngSubmit)="onSubmit()">
  <div class="page-title">{{ 'payee.creation.title' | translate }}</div>
  <div class="page-subtitle">{{ 'payee.creation.subtitle' | translate }}</div>

  <div class="content">
    <div class="edit" *ngIf="isCanadaUser || isItalyUser || isFranceUser || isGermanyUser || isUkUser">
      <div class="title">{{ 'payee.creation.form.firstName' | translate }}</div>
      <mat-form-field appearance="outline">
        <input matInput formControlName="firstName" maxlength="30">
        <mat-error *ngIf="payeeForm.get('firstName').errors?.required">{{ 'payee.creation.form.firstNameReq' | translate }}</mat-error>
        <mat-error *ngIf="payeeForm.get('firstName').errors?.pattern">{{ 'payee.creation.form.firstNameInvalid' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="edit" *ngIf="isCanadaUser || isItalyUser || isFranceUser || isGermanyUser || isUkUser">
      <div class="title">{{ 'payee.creation.form.lastName' | translate }}</div>
      <mat-form-field appearance="outline">
        <input matInput formControlName="lastName"  maxlength="30">
        <mat-error *ngIf="payeeForm.get('lastName').errors?.required">{{ 'payee.creation.form.lastNameReq' | translate }}</mat-error>
        <mat-error *ngIf="payeeForm.get('lastName').errors?.pattern">{{ 'payee.creation.form.lastNameInvalid' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="edit" *ngIf="isUsaUser">
      <div class="title">{{ 'payee.creation.form.company' | translate }}</div>
      <mat-form-field appearance="outline">
        <input matInput formControlName="company" maxlength="30">
        <mat-error *ngIf="payeeForm.get('company').errors?.required">{{ 'payee.creation.form.companyReq' | translate }}</mat-error>
        <mat-error *ngIf="payeeForm.get('company').errors?.pattern">{{ 'payee.creation.form.companyInvalid' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="edit">
      <div class="title">{{ 'payee.creation.form.email' | translate }}</div>
      <mat-form-field appearance="outline">
        <input *ngIf="!isGuestUser" matInput formControlName="email" maxlength="75">
        <input *ngIf="isGuestUser" matInput readonly formControlName="email" maxlength="75">               
        <mat-error *ngIf="payeeForm.get('email').errors?.required">{{ 'payee.creation.form.emailReq' | translate }}</mat-error>
        <mat-error *ngIf="payeeForm.get('email').errors?.pattern || payeeForm.get('email').errors?.maxlength">{{ 'payee.creation.form.emailError' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="edit" *ngIf="isUsaUser">
      <div class="title">{{ 'payee.creation.form.phone' | translate }}</div>
      <mat-form-field appearance="outline">
        <input matInput upscPhoneNumber formControlName="phone" maxlength="12" minlength="12">
        <mat-error *ngIf="!payeeForm.get('phone').errors?.required">{{ 'payee.creation.form.phoneError' | translate }}</mat-error>
        <mat-error *ngIf="payeeForm.get('phone').errors?.required">{{ 'payee.creation.form.phoneReq' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="edit" *ngIf="isCanadaUser || isItalyUser || isFranceUser || isGermanyUser || isUkUser">
      <div *ngIf="isItalyUser || isFranceUser || isGermanyUser || isUkUser" class="title">{{ 'payee.creation.form.phonehed' | translate }}</div>
      <div *ngIf="isCanadaUser" class="title">{{ 'payee.creation.form.phone' | translate }}</div>
    <div class="togetherCode d-flex flex-column flex-md-row">
      <div class="edit code mr-3">
        <div class="title">{{ 'payee.creation.form.countryCode' | translate }}</div>
        <mat-form-field appearance="outline">
          <input *ngIf="isCanadaUser" matInput formControlName="countryCode" (keypress)="numberOnly($event)" maxlength="1" >
          <span *ngIf="isItalyUser || isFranceUser || isGermanyUser || isUkUser" matPrefix>+ &nbsp;</span>
          <input *ngIf="isItalyUser || isFranceUser || isGermanyUser || isUkUser" matInput formControlName="countryCode" (keypress)="numberOnly($event)" maxlength="3" minlength="1">
        </mat-form-field>
      </div>
      <div class="edit half d-flex flex-column justify-content-md-end">
        <div class="title">{{ 'payee.creation.form.phone' | translate }}</div>
        <mat-form-field appearance="outline">
          <input *ngIf="isCanadaUser" matInput formControlName="phone" mask="000-000-0000">
          <input *ngIf="isItalyUser || isFranceUser || isGermanyUser || isUkUser" matInput formControlName="phone" (keypress)="numberOnly($event)" maxlength="12">
          <mat-error *ngIf="!payeeForm.get('phone').errors?.required">{{ 'payee.creation.form.phoneError' | translate }}</mat-error>
          <mat-error *ngIf="payeeForm.get('phone').errors?.required">{{ 'payee.creation.form.phoneReq' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    </div>
    <div class="edit">
      <div class="title">{{ 'payee.creation.form.address1' | translate }}</div>
      <mat-form-field appearance="outline">
        <input matInput formControlName="addressLine1">
        <mat-error *ngIf="payeeForm.get('addressLine1').errors?.required">{{ 'payee.creation.form.address1Req' | translate }}</mat-error>
        <mat-error *ngIf="payeeForm.get('addressLine1').errors?.pattern">{{ 'payee.creation.form.address1Invalid' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="edit">
      <div class="title">{{ 'payee.creation.form.address2' | translate }}</div>
      <mat-form-field appearance="outline">
        <input matInput formControlName="addressLine2">
        <mat-error *ngIf="payeeForm.get('addressLine2').errors?.pattern">{{ 'payee.creation.form.address2Invalid' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="together d-flex flex-column flex-md-row">
      <div class="edit half">
        <div class="title">{{ 'payee.creation.form.city' | translate }}</div>
        <mat-form-field appearance="outline">
          <input matInput formControlName="city">
          <mat-error>{{ 'payee.creation.form.cityReq' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="edit half" *ngIf="isUsaUser || isCanadaUser">
        <div class="title">{{ 'payee.creation.form.state' | translate }}</div>
        <mat-form-field appearance="outline">
          <mat-select *ngIf="!isCanadaUser" formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state.state">{{state.state}}</mat-option>
          </mat-select>
          <mat-select *ngIf="isCanadaUser" formControlName="state">
            <mat-option *ngFor="let state of castates" [value]="state.state">{{state.state}}</mat-option>
          </mat-select>
          <mat-error>{{ 'payee.creation.form.stateReq' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="together d-flex flex-column flex-md-row">
      <div class="edit half">
        <div class="title">{{ 'payee.creation.form.zip' | translate }}</div>
        <mat-form-field appearance="outline" *ngIf="isUsaUser || isGermanyUser || isFranceUser || isItalyUser">
          <input matInput formControlName="zip" minlength="5" maxlength="5">
          <mat-error *ngIf="payeeForm.get('zip').errors?.required">{{ 'payee.creation.form.zipReq' | translate }}</mat-error>
          <mat-error *ngIf="payeeForm.get('zip').errors?.pattern">{{ 'payee.creation.form.zipError' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="isUkUser">
          <input matInput formControlName="zip" maxlength="8">
          <mat-error *ngIf="payeeForm.get('zip').errors?.required">{{ 'payee.creation.form.zipReq' | translate }}</mat-error>
          <mat-error *ngIf="payeeForm.get('zip').errors?.pattern">{{ 'payee.creation.form.zipError' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="isCanadaUser">
          <input matInput formControlName="zip" maxlength="7">
          <mat-error *ngIf="payeeForm.get('zip').errors?.required">{{ 'payee.creation.form.zipReq' | translate }}</mat-error>
          <mat-error *ngIf="payeeForm.get('zip').errors?.pattern">{{ 'payee.creation.form.zipError' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="edit half" *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
        <div class="title">{{ 'payee.creation.form.country' | translate }}</div>
        <mat-form-field *ngIf="isCanadaUser" appearance="outline" >
          <input matInput formControlName="country" readonly>
        </mat-form-field>
        <mat-form-field *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser" appearance="outline">               
          <mat-select required formControlName="country">
            <!-- <mat-option class="highlighted" value="US">{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.us' | translate }}</mat-option>
            <mat-option class="highlighted" value="CA">{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.canada' | translate }}</mat-option> -->
            <mat-option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</mat-option>
          </mat-select>
          <mat-error *ngIf="payeeForm.get('country').errors?.required">            
              {{ 'claims.edit.start.shippingInfo.form.senderInformation.country.errors.required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="navi d-flex align-items-center">
    <button class="upsc-button" type="submit" [disabled]="(!payeeForm.valid) || (disableSubmit)" (click)="tagging()">{{ 'payee.creation.form.submit' | translate }}</button>
    <a (click)="navBack()">{{ 'userProfile.cancel' | translate }}</a>
  </div>
</form>
</div>