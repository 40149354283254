import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { ClaimService } from 'src/app/services/claim.service';
import { UserService } from 'src/app/services/user.service';
import { TupssService } from '../../services/tupss.service';

@Component({
  selector: 'upsc-franchise-start',
  templateUrl: './franchise-start.component.html',
  styleUrls: ['./franchise-start.component.scss']
})
export class FranchiseStartComponent implements OnInit {

  @Output() nextEvent = new EventEmitter<any>();

  franchiseForm: UntypedFormGroup;
  userData: any;
  trackingNumber: string;
  goodTrackingNumber = false;
  showSpinner = true;
  systemError: boolean;
  authError: boolean;
  errors: string[] = [];
  claimSubmittedAlready;
  showOutageMessage: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private claimService: ClaimService,
    private tupssService: TupssService,
    private jwtHelper: JwtHelperService,
    private userService: UserService
  ) { 
    this.displayOutageMessage();
  }

  displayOutageMessage() {
    const currentDate = new Date();
    const targetDate = new Date('2024-07-30T22:00:00'); // after 10pm on 7/30, don't show outage message anymore.

    if (currentDate > targetDate) {
      this.showOutageMessage = false;
    } else {
      this.showOutageMessage = true;
    }
  }

  ngOnInit(): void {
    this.userData = this.tupssService.userData;
    if(this.userData) {
      this.showSpinner = false;
    }
    else {
      this.showSpinner = true;
      let tupssToken: any = (this.route.snapshot.fragment ? this.route.snapshot.fragment : this.tupssService.getTfcpTokenString()).match(/id_token=([\w_.\-]+)/i);
      if(tupssToken.length > 1) {
        tupssToken = tupssToken[1];
      }

      this.tupssService.tfcpAuthentication(tupssToken).pipe(
        switchMap(data => {
          let tupssScope = this.tupssService.convertToArray(data.tupssScope);
          sessionStorage.setItem('tupssScope', JSON.stringify(tupssScope));
          if(tupssScope.length == 1 && tupssScope[0].toLowerCase() != 'all') {
            return this.tupssService.getContractId(tupssScope[0]).pipe(
              switchMap(response => {
                return of({...data, ...{ contractData: response.contractIDResponse }});
              })
            )
          }
          else {
            return of({...data, ...{ contractData: null }});
          }
        })
      ).subscribe(
        data => {
          this.showSpinner = false;
          this.userData = data;
          this.tupssService.userData = this.userData;
        },
        error => {
          this.showSpinner = false;
          if(error.message == 'Unauthorized') {
            this.authError = true;
          }
          else {
            this.authError = true;
          }
        }
      )
    }

    this.franchiseForm = this.fb.group({
      trackingNumber: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z\-0-9]+$/i)])],
    });

    this.claimSubmittedAlready = this.tupssService._claimSubmittedFor ? true: false;
    if (this.claimSubmittedAlready) {
      this.tupssService.claimSubmittedFor = null;
    }
  }

  closeErrorMsg() {
    this.errors = [];
  }
  processLinks(event) {
    const element: HTMLElement = event.target;
    if (this.errors.includes('claims.edit.tupssErrors.existingClaim')) {
      if (element.nodeName === 'A') {
        event.preventDefault();
        this.tupssService.openFaqs();
        // event.preventDefault();
        // const link = element.getAttribute('href');
        // this.router.navigate([link]);
      }
    }
  }
  nextStep() {
    this.systemError = false;
    this.errors = [];
    this.franchiseForm.get('trackingNumber').setErrors(null);
    this.trackingNumber = this.franchiseForm.get('trackingNumber').value.toUpperCase();
    if(this.trackingNumber.startsWith('1Z') && this.trackingNumber.length != 18) {
      this.errors.push('claims.edit.tupssErrors.invalidTrackingNo')
      return;
    }

    if (this.franchiseForm.valid) {
      let payload = {
          transactionSrc: 'Capital_TFCP',
          trackNo: this.trackingNumber
      }

      this.claimService.checkDuplicateClaims(this.trackingNumber).pipe(
        tap(data => {
          if(data.IsDuplicateTrackingNumber) {
            throw Error("IsDuplicateTrackingNumber");
          }
        }),
        switchMap(() => this.claimService.getiShipData(payload))
      ).subscribe(
        data => {
          if(data && data.message == 'Success') {
            this.tupssService.userData['siteId'] = data.data.response.cdsresponse.shipmentInfo.siteInfo.storeNumber;
            this.tupssService.setItem('trackingNumber', this.trackingNumber);
            this.tupssService.getContractId(data.data.response.cdsresponse.shipmentInfo.siteInfo.storeNumber, '', data.data.response.cdsresponse.shipmentInfo.shipDate).subscribe(
              data => {
                if (data && data.contractIDResponse) {
                  //This tracking number is returning with more than 1 policy.
                  //If TN was 1Z, use UPSSTORE programtype.
                  //If TN was NOT 1Z, use FLEX programtype.
                  let policy = this.trackingNumber.toUpperCase().startsWith('1Z') ? data.contractIDResponse.find(cd => cd && cd.programType =='UPSSTORES') : data.contractIDResponse.find(cd => cd && cd.programType =='FLEX');
                  if (!policy) {
                    //TN was most likely a non-1Z number, and the store didn't have a FLEX coverage. So no policy found.
                    //In this case, should not allow user to continue.
                    this.errors.push('claims.edit.tupssErrors.notEligible');
                  } else {
                    this.userService.getPolicySearch(policy.contractID).subscribe(
                      data => {
                        if (data?.data && data?.message?.toLowerCase() == 'succeeded') {
                          this.tupssService.setPolicyToUseForFilingAClaim(data.data);
                          this.router.navigate(['tupss', 'file']);
                        } else {
                          this.errors.push('common.systemError');
                        }
                      },
                      error => {
                        this.errors.push('common.systemError');
                      }
                    );
                  }
                }
                else {
                  this.systemError = true;
                }
              }
            );
          }
          else if(data && data.message == 'NotFound') {
            if(!this.trackingNumber.startsWith('1Z')) {
              this.errors.push('claims.edit.tupssErrors.notEligible');
            }
            else {
              this.errors.push('claims.edit.tupssErrors.notManifestedFromTupss');
            }
          }
          else if(data && data.message == 'BillRecipient') {
            this.errors.push('claims.edit.tupssErrors.billRecipient');
          }
          else if(data && data.message == 'BillThirdParty') {
            this.errors.push('claims.edit.tupssErrors.billThirdParty');
          }
          else if(data && data.message == 'BMAUPS') {
            this.errors.push('claims.edit.tupssErrors.billMyAccount');
          }
          else if(data && data.message == 'DateExceeded') {
            this.errors.push('claims.edit.tupssErrors.periodPassed');
          }
          else {
            this.errors.push('common.systemError');
          }
        },
        error => {
          if(error.message == 'IsDuplicateTrackingNumber') {
            // this.franchiseForm.get('trackingNumber').setErrors({ 'inuse': true });
            this.errors.push('claims.edit.tupssErrors.existingClaim')
          }
          else {
            this.errors.push('common.systemError');
          }
        }
      );
    }
  }
}
