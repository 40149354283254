<div class="popup-global-layout width-950">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">{{'deliveryDefense.termsPopup.title1' | translate}}<sup>TM</sup>{{'deliveryDefense.termsPopup.title2' | translate}}</div>
  <div class="global-green-bar"></div>
  <div class="global-text">{{'deliveryDefense.termsPopup.text1' | translate}}<sup>©</sup>{{'deliveryDefense.termsPopup.text2' | translate}}</div>
  <br>
  <div class="global-text"><strong>{{'deliveryDefense.termsPopup.text3' | translate}}</strong>{{'deliveryDefense.termsPopup.text4' | translate}}</div>
  <br>
  <div class="d-flex align-items-center justify-content-center">    
    <div class="blue-box">
      <strong class="text">{{'deliveryDefense.termsPopup.bluebox1' | translate}}</strong>
      <br>
      <img src="../../../../assets/images/dd-house.png" class="house-img">
      <br>
      <div>
        <img src="../../../../assets/images/checkcircle-solid.png" class="mr-2">
        <strong>{{'deliveryDefense.termsPopup.bluebox2' | translate}}</strong>{{'deliveryDefense.termsPopup.bluebox3' | translate}}
      </div>
    </div>
  </div>
  <br>
  <div class="checkbox-text">
    <mat-checkbox class="green-background-checkbox delivery-defense-layout" [(ngModel)]="isChecked">
      {{'deliveryDefense.termsPopup.terms1' | translate}}
      <a href="https://www.insureshield.com/us/en/legal/deliverydefense-address-confidence-tool-terms-conditions.html" target="_blank" class="upsc-link">
        {{'deliveryDefense.termsPopup.terms2' | translate}}
      </a>
      {{'deliveryDefense.termsPopup.terms3' | translate}}</mat-checkbox>
  </div>

  <div class="global-button-format">
    <button class="upsc-white-button2" (click)="close()"><mat-icon class="icon-key">keyboard_arrow_left</mat-icon>Cancel</button>
    <button class="upsc-green-popup-button" (click)="continue()"  [disabled]="!isChecked" *ngIf="!loading">Continue<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
    <button type="button" class="next-step loading d-flex align-items-center justify-content-between" *ngIf="loading">
      <div>{{'deliveryDefense.termsPopup.continue' | translate}}</div>
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading</span>
      </div>
    </button>
  </div>
  <div class="error mt-2" *ngIf="systemError">
    {{'deliveryDefense.termsPopup.systemError1' | translate}}
    <a href="mailto:DeliveryDefenseCustomerService@ups.com">
      {{'deliveryDefense.termsPopup.systemError2' | translate}}
    </a>
    {{'deliveryDefense.termsPopup.systemError3' | translate}}
  </div>
  <div class="error mt-2" *ngIf="sessionError">
    {{'deliveryDefense.termsPopup.sessionError' | translate}}
  </div>
</div>