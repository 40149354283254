<div class="my-choice-wrapper">
  <div class="banner-text" *ngIf="successCase">
    <div class="success-icon">
      <em class="material-icons upsgreen600 md-96">check_circle</em>
    </div>
    <div class="banner-content" *ngIf="successCase">
      <div class="congrats">{{'myChoice.congratulations.Congrats' | translate }}</div>
      <div class="title">
        {{'myChoice.congratulations.yourPackageProtected1' | translate
        }}<sup>®</sup>{{'myChoice.congratulations.yourPackageProtected2' | translate }}
      </div>
      <div class="title">
        {{'myChoice.congratulations.ConfirmationNumber' | translate }}
        {{ confirmationNumber }}
      </div>
      <div class="what-happens">{{'myChoice.congratulations.WhatHappens' | translate }}</div>
      <p></p>
      <div class="space"></div>
      <div class="title-sub">
        {{'myChoice.congratulations.youWillReceiveEmail1' | translate }}
        {{'myChoice.congratulations.InsureShield2' | translate }}<sup>®</sup>
        {{'myChoice.congratulations.youWillReceiveEmail2' | translate }}
      </div>
      <div class="what-happens">
        {{'myChoice.congratulations.needSupport' | translate }}
      </div>
      <p></p>
      <div class="space"></div>
      <div class="image-box">
        <img src="../../../../../assets/images/contact-phone.svg">
      </div>
      <div class="contact-customer-service">
        {{'myChoice.congratulations.contactCustomerService' | translate }}
      </div>
      <div class="contact-customer-service-email">
        <a (click)="contactUs()" href="mailto:mychoiceinsureshieldsupport@ups.com">
          {{'myChoice.congratulations.email' | translate }}
        </a>
      </div>
      <div class="contact-customer-service">
        {{'myChoice.congratulations.time' | translate }}
      </div>
    </div>
  </div>

  <div *ngIf="failedCase">
    <div class="banner-text">
      <div class="banner-content">
        <div class="what-happens">
          {{'myChoice.congratulations.needSupport' | translate }}
        </div>
        <p></p>
        <div class="space"></div>
        <div class="image-box">
          <img src="../../../../../assets/images/contact-phone.svg">
        </div>
        <div class="contact-customer-service">
          {{'myChoice.congratulations.contactCustomerService' | translate }}
        </div>
        <div class="contact-customer-service-email">
          <a (click)="contactUs()" href="mailto:mychoiceinsureshieldsupport@ups.com">
            {{'myChoice.congratulations.email' | translate }}
          </a>
        </div>
        <div class="contact-customer-service">
          {{'myChoice.congratulations.time' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="my-choice-wrapper-mobile">
  <div class="banner-text" *ngIf="successCase">
    <div class="success-icon">
      <em class="material-icons upsgreen600 md-96">check_circle</em>
    </div>
    <div class="banner-content" *ngIf="successCase">
      <div class="congrats">{{'myChoice.congratulations.Congrats' | translate }}</div>
      <div class="title">
        {{'myChoice.congratulations.yourPackageProtected1' | translate
        }}<sup>®</sup>{{'myChoice.congratulations.yourPackageProtected2' | translate }}
      </div>
      <div class="confirmation-number">
        {{'myChoice.congratulations.ConfirmationNumber' | translate }}
        {{ confirmationNumber }}
      </div>
      <div class="what-happens">{{'myChoice.congratulations.WhatHappens' | translate }}</div>
      <p></p>
      <div class="space"></div>
      <div class="title-sub">
        {{'myChoice.congratulations.youWillReceiveEmail1' | translate }}
        {{'myChoice.congratulations.InsureShield2' | translate }}<sup>®</sup>
        {{'myChoice.congratulations.youWillReceiveEmail2' | translate }}
      </div>
      <div class="what-happens">
          {{'myChoice.congratulations.needSupport' | translate }}
      </div>
      <p></p>
      <div class="space"></div>
      <img src="../../../../../assets/images/contact-phone.svg">
      <div class="contact-customer-service">
        {{'myChoice.congratulations.contactCustomerService' | translate }}
      </div>
      <div class="contact-customer-service-email">
        <a (click)="contactUs()" href="mailto:mychoiceinsureshieldsupport@ups.com">
          {{'myChoice.congratulations.email' | translate }}
        </a>
      </div>
      <div class="contact-customer-service">
        {{'myChoice.congratulations.time' | translate }}
      </div>
    </div>
  </div>
  <div *ngIf="failedCase">
    <div class="banner-text" >
      <div class="banner-content" >
        <div class="what-happens">
            {{'myChoice.congratulations.needSupport' | translate }}
        </div>
        <p></p>
        <div class="space"></div>
        <img src="../../../../../assets/images/contact-phone.svg">
        <div class="contact-customer-service">
          {{'myChoice.congratulations.contactCustomerService' | translate }}
        </div>
        <div class="contact-customer-service-email">
          <a (click)="contactUs()" href="mailto:mychoiceinsureshieldsupport@ups.com">
            {{'myChoice.congratulations.email' | translate }}
          </a>
        </div>
        <div class="contact-customer-service">
          {{'myChoice.congratulations.time' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>