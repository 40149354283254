import { Component, Inject } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { TaggingService } from 'src/app/tags/tagging.service';
import { ContactUsComponent } from 'src/app/shared/dialogs/contact-us/contact-us.component'; 
import { MatLegacyDialog as MatLgDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-contact-us-popup',
  templateUrl: './contact-us-popup.component.html',
  styleUrls: ['./contact-us-popup.component.scss']
})
export class ContactUsPopupComponent {

  isCanadaUser: boolean;
  isUkUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;

  constructor(private userService: UserService,
    private taggingService: TaggingService,
    public cntUsDialog: MatLgDialog, 
    public dialogRef: MatDialogRef<ContactUsPopupComponent>) 
  {
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();  
  }

  contactusPopover: string = '';
  contactusPopoverCanada: string = '';

  ngOnInit(): void {
    this.contactusPopover = sessionStorage.getItem("contactusPopover");
    this.contactusPopoverCanada = sessionStorage.getItem("contactusPopoverCanada");    
  }

  
  public openContactUsDialog(): void {
    this.taggingService.link({
      link_name: 'contact_us_header_tab'
    });
    let dialogRef = this.cntUsDialog.open(ContactUsComponent);

    dialogRef.afterClosed().subscribe(result => {
     
    });
    this.close();
  }

  //Close dialog
  public close() {
    this.dialogRef.close();
  }
  
}
