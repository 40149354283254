import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TupssService } from '../services/tupss.service';
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'upsc-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  //TUPSS Variables
  contactType = "";  //Can be "tccp", or "tfcp"
  stage = ""; // Can be pre or post
  coverageType = 0; // Can be 1 or 2 or 3
  trackingNumber = "";
  question14 = "";
  isQ14Collasped: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private tupssService: TupssService,
    private scroller: ViewportScroller
  ) {
    
    sessionStorage.setItem('userDetails', this.route.snapshot.fragment);

    try {
      this.question14 = this.route.snapshot.queryParamMap.get('q14');
      if(this.question14===null){
        this.question14 = "0";
        this.isQ14Collasped = true; 
      }
    } catch (e) {
      this.question14 = "0";
      this.isQ14Collasped = true;
    }
  }

  ngAfterViewInit() {
    if (this.question14 !== undefined && this.question14 === "1") {
      this.scroller.scrollToAnchor("targetq14");
    }
  }

  ngOnInit(): void {

    

    if (sessionStorage.getItem('userDetails')
      && JSON.parse(sessionStorage.getItem('userDetails')).type) {
      this.contactType = JSON.parse(sessionStorage.getItem('userDetails')).type;
      this.stage = JSON.parse(sessionStorage.getItem('userDetails')).stage;
    }
    else {
      //temp hardcode
      this.contactType = 'tccp';
      this.stage = "post";
    }

    if (sessionStorage.getItem('userDetails') && JSON.parse(sessionStorage.getItem('userDetails')).trackingNumber) {
      this.trackingNumber = JSON.parse(sessionStorage.getItem('userDetails')).trackingNumber;

      //iShip data check
      let payload;
      payload = {
        transactionSrc: "Capital_TFCP",
        trackNo: this.trackingNumber
      }

      //getting Iship Data for identifying coverage type
      this.tupssService.getIShipData(payload).subscribe(
        data => {
          this.coverageType = data.data.coverageType;
        }
      );
    }

    if (this.question14 !== undefined && this.question14 === "1") {
      this.isQ14Collasped = false;
    }
  }
}
