import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ContactInfo, CustomContactData } from '../../models/contact-info';

@Component({
  selector: 'upsc-confirm-contact-card',
  templateUrl: './confirm-contact-card.component.html',
  styleUrls: ['./confirm-contact-card.component.scss']
})
export class ConfirmContactCardComponent {
 @Input() isCustom: boolean = false;
  @Input() policy: any;
  @Input() userDetails: any;
  @Input() persistPayeeInfo: any;

  @Output() selectedContact = new EventEmitter<ContactInfo>();
  @Output() saveDraft = new EventEmitter<ContactInfo>();
  @Output() goBack = new EventEmitter<any>();
  @Output() cancelClaim = new EventEmitter<any>();
  frmGroupContactInfo: UntypedFormGroup;
  frmGroupChoiceInfo: UntypedFormGroup;
  contactType: string = "";
  selectedValue = "";
  continuing = false; //Used with the continue button's progress
  contactInfo: ContactInfo = {};
  phoneNumber = "";
  phoneCode = "";

  isGuestUser: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isContactSelected: boolean = false;
  isDDUser: boolean;
  anotherPhone: string = "";

  constructor(private formBuilder: UntypedFormBuilder,
    private userService: UserService) {

    let contactType = sessionStorage.getItem("contactTypeSelected");
    if (contactType !== null && contactType !== undefined) {
      this.frmGroupChoiceInfo = this.formBuilder.group({
        choice: [contactType, Validators.required]
      });
      this.contactType = contactType;
    }
    else {
      this.frmGroupChoiceInfo = this.formBuilder.group({
        choice: ['', Validators.required]
      });
    }

    let contactInfo = JSON.parse(sessionStorage.getItem("contactInfo"));

    if (contactInfo !== null && contactInfo !== undefined && contactInfo.contactType === '2') {
      this.phoneNumber = contactInfo.workNumber;
      this.phoneCode = contactInfo.countryCode;
      this.frmGroupContactInfo = this.formBuilder.group({
        firstName: [contactInfo.firstName, Validators.compose([Validators.required])],
        lastName: [contactInfo.lastName, Validators.compose([Validators.required])],
        countryCode: [this.phoneCode, (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required, Validators.pattern(/\d{1,3}/)] : []],
        phoneNumber: [this.phoneNumber, Validators.required],
        emailAddress: [contactInfo.emailAddress1, Validators.compose([Validators.required, Validators.email])]
      });
    }
    else {
      this.frmGroupContactInfo = this.formBuilder.group({
        firstName: ['', Validators.compose([Validators.required])],
        lastName: ['', Validators.compose([Validators.required])],
        countryCode: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required, Validators.pattern(/\d{1,3}/)] : []],
        phoneNumber: ['', Validators.required],
        emailAddress: ['', Validators.compose([Validators.required, Validators.email])]
      });
    }

    this.isGuestUser = this.userService.isGuestUser();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.isDDUser = this.userService.isDeliveryDefenseUser();

    this.frmGroupChoiceInfo.valueChanges.subscribe(data => {
      if (data.choice == "0") {
        this.selectedValue = "0";
      }
      if (data.choice == "1") {
        this.selectedValue = "1";
      }
      if (data.choice == "2") {
        this.selectedValue = "2";
      }
    });

  }


  ngOnDestroy() {
    //sessionStorage.removeItem("contactTypeSelected");
  }


  //Used to manually check all fields & show errors
  validateAllFormFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  addAnother() {
    this.contactType = '2';
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && charCode != 43 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  saveContact(event) {
    this.continuing = true;
    sessionStorage.setItem("contactTypeSelected", this.contactType);
    if (this.contactType === '0' || this.contactType === '1') {
      if (this.frmGroupChoiceInfo.valid) {
        this.isContactSelected = true;
        if (this.contactType === '1') {
          this.contactInfo.contactType = this.contactType;
          let payeefullName = this.persistPayeeInfo.payeeName.split(' ');
          this.contactInfo.firstName = payeefullName[0];
          this.contactInfo.lastName = payeefullName[1];
          this.contactInfo.workNumber = (this.persistPayeeInfo.phoneNumber && this.persistPayeeInfo.phoneNumber.trim().length > 1) ? this.persistPayeeInfo.phoneNumber.replace(/\./g, '') : "";
          this.contactInfo.emailAddress1 = (this.persistPayeeInfo.email && this.persistPayeeInfo.email.trim().length > 1) ? this.persistPayeeInfo.email : "";
          this.contactInfo.insured = (this.persistPayeeInfo.payeeNamel && this.persistPayeeInfo.payeeNamel.trim().length > 1) ? this.persistPayeeInfo.payeeNamel : "";
          this.contactInfo.streetAddress1 = (this.persistPayeeInfo.addressLine1 && this.persistPayeeInfo.addressLine1.trim().length > 1) ? this.persistPayeeInfo.addressLine1 : "";
          this.contactInfo.streetAddress2 = (this.persistPayeeInfo.addressLine2 && this.persistPayeeInfo.addressLine2.trim().length > 1) ? this.persistPayeeInfo.addressLine2 : "";
          this.contactInfo.city = this.persistPayeeInfo.city;
          this.contactInfo.state = this.persistPayeeInfo.state;
          this.contactInfo.zip = this.persistPayeeInfo.zip;
          this.contactInfo.country = this.persistPayeeInfo.country;
        }
        else {
          this.contactInfo.contactType = this.contactType;
          let payeefullName = this.policy.insured.split(' ');
          this.contactInfo.firstName = payeefullName[0];
          this.contactInfo.lastName = payeefullName[1];
          this.contactInfo.workNumber = this.userDetails.contactPhone;
          this.contactInfo.emailAddress1 = this.userDetails.emailAddress;
          this.contactInfo.insured = this.policy.insured;
          this.contactInfo.streetAddress1 = this.policy.addressLine1_Ext;
          this.contactInfo.streetAddress2 = this.policy.addressLine2_Ext;
          this.contactInfo.city = this.policy.city;
          this.contactInfo.state = this.policy.state;
          this.contactInfo.zip = this.policy.zip;
          this.contactInfo.country = this.policy.policyCountry_Ext;
        }
        sessionStorage.setItem("contactInfo", JSON.stringify(this.contactInfo));
        this.selectedContact.emit(this.contactInfo);
      }

      this.continuing = false;
      this.validateAllFormFields(this.frmGroupChoiceInfo);
      return false;
    }
    else  if (this.contactType === '2') {
      if (this.frmGroupContactInfo.valid) {
        this.isContactSelected = true;
        this.contactInfo.contactType = this.contactType;
        this.contactInfo.firstName = this.frmGroupContactInfo.value.firstName.trim();
        this.contactInfo.lastName = this.frmGroupContactInfo.value.lastName.trim();
        //this.contactInfo.workNumber = (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? "+" + this.frmGroupContactInfo.value.countryCode + " " + this.frmGroupContactInfo.value.phoneNumber.replace(/\./g, '') : this.frmGroupContactInfo.value.phoneNumber.replace(/\./g, '');
        this.contactInfo.emailAddress1 = this.frmGroupContactInfo.value.emailAddress;
        this.contactInfo.insured = this.policy.insured;
        this.contactInfo.streetAddress1 = this.policy.addressLine1_Ext;
        this.contactInfo.streetAddress2 = this.policy.addressLine2_Ext;
        this.contactInfo.city = this.policy.city;
        this.contactInfo.state = this.policy.state;
        this.contactInfo.zip = this.policy.zip;

        if (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) {
          this.contactInfo.countryCode = this.phoneCode;
          this.contactInfo.workNumber =  this.phoneNumber.replace(/\./g, '');
        }
        else{
          this.contactInfo.workNumber = this.phoneNumber.replace(/\./g, '');
        }

        sessionStorage.setItem("contactInfo", JSON.stringify(this.contactInfo));
        this.selectedContact.emit(this.contactInfo);
      }

      this.continuing = false;
      this.validateAllFormFields(this.frmGroupContactInfo);
      return false;
    }
    else
    {
      this.continuing = false;
      this.isContactSelected = false;
      this.validateAllFormFields(this.frmGroupChoiceInfo);
      return false;
    }
  }

  openDraftConfirmation() {
    sessionStorage.setItem("contactTypeSelected", this.contactType);
    if (this.contactType === '0' || this.contactType === '1') {
      if (this.frmGroupChoiceInfo.valid) {
        if (this.contactType === '1') {
          this.contactInfo.contactType = this.contactType;
          let payeefullName = this.persistPayeeInfo.payeeName.split(' ');
          this.contactInfo.firstName = payeefullName[0];
          this.contactInfo.lastName = payeefullName[1];
          this.contactInfo.workNumber = (this.persistPayeeInfo.phoneNumber && this.persistPayeeInfo.phoneNumber.trim().length > 1) ? this.persistPayeeInfo.phoneNumber.replace(/\./g, '') : "";
          this.contactInfo.emailAddress1 = (this.persistPayeeInfo.email && this.persistPayeeInfo.email.trim().length > 1) ? this.persistPayeeInfo.email : "";
          this.contactInfo.insured = (this.persistPayeeInfo.payeeNamel && this.persistPayeeInfo.payeeNamel.trim().length > 1) ? this.persistPayeeInfo.payeeNamel : "";
          this.contactInfo.streetAddress1 = (this.persistPayeeInfo.addressLine1l && this.persistPayeeInfo.addressLine1l.trim().length > 1) ? this.persistPayeeInfo.addressLine1l : "";
          this.contactInfo.streetAddress2 = (this.persistPayeeInfo.addressLine2l && this.persistPayeeInfo.addressLine2l.trim().length > 1) ? this.persistPayeeInfo.addressLine12 : "";
          this.contactInfo.city = this.persistPayeeInfo.city;
          this.contactInfo.state = this.persistPayeeInfo.state;
          this.contactInfo.zip = this.persistPayeeInfo.zip;
          this.contactInfo.country = this.persistPayeeInfo.country;
        }
        else {
          this.contactInfo.contactType = this.contactType;
          let payeefullName = this.policy.insured.split(' ');
          this.contactInfo.firstName = payeefullName[0];
          this.contactInfo.lastName = payeefullName[1];
          this.contactInfo.workNumber = this.userDetails.contactPhone;
          this.contactInfo.emailAddress1 = this.userDetails.emailAddress;
          this.contactInfo.insured = this.policy.insured;
          this.contactInfo.streetAddress1 = this.policy.addressLine1_Ext;
          this.contactInfo.streetAddress2 = this.policy.addressLine2_Ext;
          this.contactInfo.city = this.policy.city;
          this.contactInfo.state = this.policy.state;
          this.contactInfo.zip = this.policy.zip;
          this.contactInfo.country = this.policy.policyCountry_Ext;
        }
        sessionStorage.setItem("contactInfo", JSON.stringify(this.contactInfo));
        this.saveDraft.emit(this.contactInfo);
      }
      return false;
    }

    if (this.contactType === '2') {
      if (this.frmGroupContactInfo.valid) {
        this.contactInfo.contactType = this.contactType;
        this.contactInfo.firstName = this.frmGroupContactInfo.value.firstName.trim();
        this.contactInfo.lastName = this.frmGroupContactInfo.value.lastName.trim();
        this.contactInfo.workNumber = (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? "+" + this.frmGroupContactInfo.value.countryCode + " " + this.frmGroupContactInfo.value.phoneNumber.replace(/\./g, '') : this.frmGroupContactInfo.value.phoneNumber.replace(/\./g, '');
        this.contactInfo.emailAddress1 = this.frmGroupContactInfo.value.emailAddress;
        this.contactInfo.insured = this.policy.insured;
        this.contactInfo.streetAddress1 = this.policy.addressLine1_Ext;
        this.contactInfo.streetAddress2 = this.policy.addressLine2_Ext;
        this.contactInfo.city = this.policy.city;
        this.contactInfo.state = this.policy.state;
        this.contactInfo.zip = this.policy.zip;
        sessionStorage.setItem("contactInfo", JSON.stringify(this.contactInfo));
        this.saveDraft.emit(this.contactInfo);
      }
      return false;
    }
  }

  back(event) {
    this.goBack.emit(true);
  }

  cancel(event) {
    this.cancelClaim.emit(true);
  }
}
