import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClaimService } from 'src/app/services/claim.service';
import { PayeeService } from 'src/app/services/payee.service';
import { TaggingService } from 'src/app/tags/tagging.service';

@Component({
  selector: 'upsc-address-validation',
  templateUrl: './address-validation.component.html',
  styleUrls: ['./address-validation.component.scss']
})
export class AddressValidationComponent implements OnInit {
  @Input() addressListFromProspect = null;
  @Input() currentAddressFromProspect = null;
  addressForm: UntypedFormGroup;
  addressList;
  currentEnteredAddress;
  loading = true;
  claimPayeeEditButton = false;

  @Output() userInput: EventEmitter<any> = new EventEmitter<string|number>();

  constructor(
    private fb: UntypedFormBuilder,
    private claimService: ClaimService,
    private payeeService: PayeeService,
    private taggingService: TaggingService
  ) {
    this.addressForm = this.fb.group({
      selection: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    if(this.currentAddressFromProspect) {
      this.taggingService.view({
        page_id: 'validate_address',
        step: 'validate_address',
        journey_step_number: '2'
      });
    }

    if(this.payeeService.recipientAddFlow.addressValidationFlow == "1")
    {
      this.claimPayeeEditButton = true;
    }
    this.claimService.getAddValData().subscribe(
      data => {
        this.addressList = data;
      }
    );
    this.claimService.getTupssAddress().subscribe(
      data => {
        this.currentEnteredAddress = data;
      }
    );
    this.claimService.getLoading().subscribe(
      data => {
        this.loading = data;
      }
    );
    if (this.addressListFromProspect) {
      this.addressList = this.addressListFromProspect;
    }
    if (this.currentAddressFromProspect) {
      this.currentEnteredAddress = this.currentAddressFromProspect;
    }
  }

  //Sends new address if user selected one. Otherwise sends undefined.
  onSubmit() {
    if(this.currentAddressFromProspect) {
      this.taggingService.link({
        link_name: 'Validate',
        link_page_id: 'validate_address'
      });
    }
    this.userInput.emit(this.addressList[this.selection.value]);
  }

  //User wanted to edit the address they already entered. essentially go back.
  edit() {
    if(this.currentAddressFromProspect) {
      this.taggingService.link({
        link_name: 'Back',
        link_page_id: 'validate_address'
      });
    }
    this.userInput.emit('edit');
  }

  get selection() { return this.addressForm.controls.selection }

}
