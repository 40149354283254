import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchPolicyNotifyComponent } from './switch-policy-notify/switch-policy-notify.component';



@NgModule({
  declarations: [
    SwitchPolicyNotifyComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [SwitchPolicyNotifyComponent]
})
export class SwitchPolicyNotifyModule { }
