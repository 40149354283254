<div class="wrap popup-global-layout p-3">
  <div class="title-block">
    <mat-icon class="icon" (click)="close()">close</mat-icon>
    <br>
    <div class="global-title" *ngIf="page===1">{{ 'support.dialog.title' | translate }}</div>
    <div class="global-green-bar" *ngIf="page===1"></div>
  </div>

  <div class="page1" *ngIf="page===1">
    <div class="d-flex flex-column px-0">
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div class="block">
          <!-- <div class="subtitle">{{ 'support.dialog.subtitle1' | translate }}</div> -->
          <mat-form-field required>
            <mat-select formControlName="topic" placeholder="{{ 'support.dialog.subtitle1' | translate }}">
              <mat-option *ngFor="let topic of topicList" [value]="topic.key">{{topic.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="block claim" *ngIf="contactForm.get('topic').value===1">
          <!-- <div class="subtitle">{{ 'support.dialog.optSubtitle1' | translate }}</div> -->
          <mat-form-field>
            <input matInput formControlName="claim" upscClaimMask (blur)="getClaimDetails()" placeholder="{{ 'support.dialog.optSubtitle1' | translate }}">
          </mat-form-field>
        </div>
        <div class="block align-self-center">
          <!-- <div class="subtitle">{{ 'support.dialog.subtitle2' | translate }}</div> -->
          <mat-form-field>
            <textarea matInput formControlName="description" matTextareaAutosize matAutosizeMinRows=5
              matAutosizeMaxRows=5 maxlength="250" placeholder="{{ 'support.dialog.subtitle2' | translate }}"></textarea>
          </mat-form-field>
        </div>
        <div class="block align-self-center">
          <!-- <div class="subtitle">{{ 'support.dialog.subtitle3' | translate }}</div> -->
          <mat-form-field required>
            <input matInput formControlName="name" placeholder="{{ 'support.dialog.subtitle3' | translate }}">
          </mat-form-field>
        </div>
        <div class="block align-self-center">
          <div class="subtitle mb-2">{{ 'support.dialog.subtitle4' | translate }}</div>
          <mat-radio-group required formControlName="contact">
            <div class="radio-group-wrapper d-flex flex-column flex-md-row">
              <div class="radio-button-wrapper">
                <mat-radio-button class="mb-1" value="phone">
                  {{ 'support.dialog.radio1' | translate }}
                </mat-radio-button>
                <div class="method-wrapper">
                  <mat-form-field *ngIf="contactForm.get('contact').value==='phone'">
                    <input *ngIf="isUSAUser || isCaUser || isGuestUser" matInput required upscPhoneNumber formControlName="phone" mask="000.000.0000" placeholder="{{ 'support.dialog.radio1' | translate }}">
                    <input *ngIf="isGermanyUser || isFranceUser || isItalyUser || isUkUser" matInput required formControlName="phone" maxlength="16" (keypress)="numberOnly($event)" placeholder="{{ 'support.dialog.radio1' | translate }}">
                  </mat-form-field>
                </div>
              </div>
              <div class="radio-button-wrapper">
                <mat-radio-button value="email">{{ 'support.dialog.radio2' | translate }}</mat-radio-button>
                <div class="method-wrapper">
                  <mat-form-field *ngIf="contactForm.get('contact').value==='email'">
                    <input matInput required formControlName="email" placeholder="{{ 'support.dialog.radio2' | translate }}">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- <mat-form-field class="font-format" *ngIf="contactForm.get('contact').value==='phone'">
              <input *ngIf="isUSAUser || isCaUser || isGuestUser" matInput required upscPhoneNumber formControlName="phone" mask="000.000.0000">
              <input *ngIf="isGermanyUser || isFranceUser || isItalyUser || isUkUser" matInput required formControlName="phone" maxlength="16" (keypress)="numberOnly($event)">
            </mat-form-field>
            <mat-form-field class="font-format" *ngIf="contactForm.get('contact').value==='email'">
              <input matInput required formControlName="email">
            </mat-form-field> -->
          </mat-radio-group>
        </div>
      </form>
    </div>  
    <div class="global-button-format">
      <button type="submit" class="upsc-white-button2" (click)="close()"><mat-icon class="icon-key">keyboard_arrow_left</mat-icon>{{ 'support.dialog.cancel' | translate }}</button>
      <button type="submit" class="upsc-green-popup-button" (click)="onSubmit(); tagging()" [disabled]="!contactForm.valid || processing">{{ 'support.dialog.submit' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
    </div>
   
  </div>

  <div class="page2" *ngIf="page===2">
    <div class="global-title">{{ 'support.dialog.confirm.thanks' | translate }}</div>
    <div class="global-green-bar"></div>
    <div class="global-text">{{ 'support.dialog.confirm.1' | translate }} {{ 'support.dialog.confirm.2' | translate }} {{ 'support.dialog.confirm.3' | translate }}</div>
    <div class="global-button-format">
      <button type="button" class="upsc-green-popup-button button-format" (click)="close()">{{ 'support.dialog.close' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
    </div>
  </div>
</div>