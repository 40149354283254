import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IdentityLayoutComponent } from '../../../shared/components/identity-layout/identity-layout.component';
import { UpdatesuccessComponent } from './updatesuccess.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: IdentityLayoutComponent,
      children: [
      {
      path: '',
      component: UpdatesuccessComponent
    }
  ]
}
])],
  exports: [RouterModule]
})
export class UpdateSuccessRoutingModule { }
