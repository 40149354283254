<div class="card">
  <div class="icon-image-container" [class.noImg]="!img">
      <div class="icon">
          <img *ngIf="icon=='folder'" src="assets/images/folder-icon.svg">
          <img *ngIf="icon=='wrench'" src="assets/images/wrench-icon.svg">
          <img *ngIf="icon=='progress'" src="assets/images/progress-icon.svg">
          <img *ngIf="icon=='shield'" src="assets/images/shield-icon.svg">
          <img *ngIf="icon=='star'" src="assets/images/star-icon.svg">
      </div>
      <div class="number" *ngIf="number">
          {{ number }}
      </div>
      <img *ngIf="img; else noImage" [src]="img" class="card-image">
      <ng-template #noImage>
          <div></div>
      </ng-template>
  </div>
  <div class="number" *ngIf="money">
    <div *ngIf="isUsaUser">{{ money == '-' ? money : money | currency: 'USD' }}</div>
    <div *ngIf="isCanadaUser">{{ money == '-' ? money : money | currency: 'CAD' + ' ':'code' }}</div>
    <div *ngIf="isUkUser">{{ money == '-' ? money : money | currency: 'GBP' }}</div>
    <div *ngIf="isGermanyUser || isFranceUser || isItalyUser">{{ money == '-' ? money : money | currency: 'EUR' }}</div>
  </div>
  <div class="text">
    {{ text }}
  </div>
</div>