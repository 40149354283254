<div ngbDropdown class="d-flex flex-column flex-md-row m-2 dropdown" [autoClose]="autoClose">
  <button ngbDropdownToggle [ngClass]="applied == true ? 'upsc-rectangle-button' : 'upsc-white-rectangle'" #dateSearch>
    <span class="selectedValues" *ngIf="!applied"> {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.title' | translate }}</span>
    <span class="selectedValues" *ngIf="applied">{{ searchTypeD }} - {{  searchByValueD }}</span>
    <img *ngIf="applied" class="ml-2" (click)="reset()" src="../../../../assets/images/cross.svg">
  </button>
  <div ngbDropdownMenu class="drop-wrap" #dropwrap>
    <form [formGroup]="dateSearchForm" class="claimSearchForm" #claimSearchForm>
      <mat-radio-group formControlName="searchType" (change)="dateChange($event)"
        class="typeWrap d-flex flex-column flex-sm-row justify-content-start">
        <mat-radio-button value="fileDate" class="small mr-5">
          {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.fileDate' | translate }}</mat-radio-button>
        <mat-radio-button value="issueDate" class="small">
          {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.checkACHDate' | translate }}</mat-radio-button>
      </mat-radio-group>
      <mat-radio-group (change)="dateChange($event)" formControlName="searchByValue" class="d-flex flex-column flex-sm-column justify-content-start mt-3">
        <mat-radio-button value="Last 7 days" class="small mr-3">
          {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.week' | translate }}</mat-radio-button>
        <mat-radio-button value="Last 15 days" class="small mr-3">
          {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.fifteen' | translate }}</mat-radio-button>
        <mat-radio-button value="Last 30 days" class="small mr-3">
          {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.month' | translate }}</mat-radio-button>
        <!-- <mat-radio-button value="Last 1 year" class="small mr-3">
          {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.year' | translate }}</mat-radio-button> -->
        <mat-radio-button value="Custom Date Range" class="small mr-3">
          {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.customRange' | translate }}</mat-radio-button>
      </mat-radio-group>
      <div class="dateWrap d-flex justify-content-between flex-column flex-sm-row">
        <div class="d-flex flex-column">
          <div class="input-wrap">
            <div class="date-wrap">
              <div class="subsubsection left">
                <mat-form-field tabindex="23" (click)="picker.open()">
                  <input matInput [matDatepicker]="picker" [max]="maxDate" (dateChange)="handleMinDateChange($event)" required readonly formControlName="startDate" placeholder="{{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.startDate' | translate }}" >
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
                      width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
                      <path id="Path_219" data-name="Path 219"
                        d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                        transform="translate(0.964 0.643)" fill="#737373" />
                      <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                    </svg>
                  </mat-datepicker-toggle>
                  <mat-datepicker [touchUi]="isMobile" #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="input-wrap">
            <div class="date-wrap">
              <div class="subsubsection left">
                <mat-form-field tabindex="24" (click)="picker1.open()">
                  <input matInput [matDatepicker]="picker1" [min]="minDate" (dateChange)="handleMaxDateChange($event)" required readonly formControlName="endDate" placeholder="{{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.endDate' | translate }}">
                  <mat-datepicker-toggle matSuffix [for]="picker1">
                    <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
                      width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
                      <path id="Path_219" data-name="Path 219"
                        d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                        transform="translate(0.964 0.643)" fill="#737373" />
                      <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                    </svg>
                  </mat-datepicker-toggle>
                  <mat-datepicker [touchUi]="isMobile" #picker1></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="upsc-errors ml-3" *ngIf="valueErrors && checkValue">
        {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.customReq1' | translate }}
      </div>
      <div class="upsc-errors ml-3" *ngIf="valueErrors && checkbothDates">
        {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.customReq2' | translate }}
      </div>
      <div class="upsc-errors ml-3" *ngIf="valueErrors && isSelected">
        {{ 'claims.list.claimsSearch.advanceSearch.dateRangeSearch.customReq3' | translate }}
      </div>
      <div class="upsc-errors ml-3" *ngIf="valueErrors && cutoffError">
        Date range cannot be greater than 30 days.
      </div>
      <div class="head-padding d-flex align-items-center mt-1 ml-3">
        <button class="upsc-button mr-3" (click)="apply()">{{ 'claims.list.claimsSearch.advanceSearch.done' | translate }}</button>
        <div class="cancel" (click)="close()">{{ 'claims.list.claimsSearch.advanceSearch.cancel' | translate }}</div>
      </div>
    </form>
  </div>
</div>