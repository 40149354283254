import { Component, Input } from '@angular/core';

@Component({
  selector: 'upsc-new-notification-box',
  templateUrl: './new-notification-box.component.html',
  styleUrls: ['./new-notification-box.component.scss']
})
export class NewNotificationBoxComponent {

  @Input() public isSuccess = false;
  @Input() public isSuccess2 = false;
  @Input() public isWarning = false;
  @Input() public isDanger = false;
  @Input() public isInfo = false;
  @Input() public marginBottom = false;
  @Input() public marginTop = false;
  
}

