import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMAIL_PATTERN } from 'src/app/shared/regex-patterns';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ClaimService } from 'src/app/services/claim.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'upsc-claims-landing',
  templateUrl: './claims-landing.component.html',
  styleUrls: ['./claims-landing.component.scss']
})

export class ClaimsLandingComponent implements OnInit {

  customerForm: UntypedFormGroup;
  loading: boolean = false;

  //Error messages
  systemError: boolean = false;
  validationError: boolean = false;
  duplicateTrackingNumberError: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private claimService: ClaimService,
    private authService: AuthService
  ) { 
    this.customerForm = this.fb.group({
      trackingNumber: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.pattern(EMAIL_PATTERN), Validators.required])],
      confirmationNumber: ['', Validators.compose([Validators.required])],
      zipCode: ['', Validators.compose([Validators.required])],
      recaptchaControl: [null, Validators.required],
      checklist1: ['', Validators.requiredTrue],
      checklist2: ['', Validators.requiredTrue],
      checklist3: ['', Validators.requiredTrue],
    });
  }

  ngOnInit(): void {
    this.readParamsAndAssign();
  }

  getStarted() {
    this.dataService.deleteTrack3ShipmentDetails(); //Basically ensures that we start with fresh data everytime we call this API.
    this.loading = true;
    this.systemError = false;
    this.validationError = false;
    this.checkDuplicateTrackingNumberClaims();
  }

  validate() {
    let request = {
      trackingNumber: this.trackingNumber.value,
      zip: this.zipCode.value,
      appIndicate: environment.baseUrl.urlType == 'prod' ? '' : 'my-choice' //To validate against TESTING ENV Track3, we send 'my-choice' indicator. To validate against PROD ENV Track3, send empty string.
    }
    this.claimService.myChoiceZipValidate(request).subscribe(
      data => {
        if (data.message && data.message.toLowerCase() == 'zip doesn\'t match') {
          this.validationError = true;
          this.loading = false;
        } else {
          sessionStorage.setItem('access_token', data.data.token);
          this.getPolicyInfoAndNavigate();
        }
      }, error => {
        this.loading = false;
        this.systemError = true;
      }
    );
  }

  //Fires every time page loads - Is responsible for putting the preloaded info into the form
  readParamsAndAssign() {
    this.route.queryParams.subscribe(
      parameters => {
        if (Object.keys(parameters).length != 0) {  //Get params from the url, set them to session storage, and remove the params from the URL
          sessionStorage.setItem('my-choice-claim-landing', JSON.stringify(parameters));
          if (parameters?.policy) { //getPayeeDetails now requires a policy number in the payload. Since for my-choice there is no policy number present in userDetails, we must add it here now so it can be used later on.
            let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
            userDetails.policyNumber = parameters.policy;
            sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
          }
          this.router.navigate(['ups-my-choice/claim-portal'])
        } else if (sessionStorage.getItem('my-choice-claim-landing')) { //Get the params from the session storage and populate the form.
          let parameters = JSON.parse(sessionStorage.getItem('my-choice-claim-landing'));
          this.email.setValue(parameters.email);
          this.confirmationNumber.setValue(parameters.policy);
          this.trackingNumber.setValue(parameters.tracking);
          if (parameters?.policy) { //getPayeeDetails now requires a policy number in the payload. Since for my-choice there is no policy number present in userDetails, we must add it here now so it can be used later on.
            let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
            userDetails.policyNumber = parameters.policy;
            sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
          }
        } else {
          //Session storage is empty - and there were no query parameters. Most likely they just tried to go directly to this page without any query params. So send them back to login page.
          this.router.navigate(['/login']);
        }
      }
    );
  }

  //They entered a correct zip - so gather policy info and navigate the user.
  getPolicyInfoAndNavigate() {
    let parameters = JSON.parse(sessionStorage.getItem('my-choice-claim-landing'));
    this.userService.getPolicySearch(parameters.policy).subscribe(
      data => {
        this.loading = false;
        if (data?.data && data?.message?.toLowerCase() == 'succeeded') {
          this.systemError = false;
          this.validationError = false;
          sessionStorage.setItem('policyDetails', JSON.stringify(data.data));
          this.userService.updateUserInfo({ policyNumber: parameters.policy }); //Stored this so that user could continue to claim detail page
          this.dataService.gatherTrack3ShipmentDetails(this.trackingNumber.value);
  
          this.dataService.getTrack3ShipmentDetails().subscribe(
            track3 => {
              if (track3 == null || (track3.shipmentPackage?.shipmentIdentificationNumber == "" && track3.shipDate == "" && track3.shipmentType?.code == "")) {
                return;
              }
              if (track3.noInfoReturned) { //Empty track3 object - probably API call returned with no information
                return;
              } else if (track3) {
                this.router.navigate(['/ups-my-choice/claim-portal/file']);
              }
            }
          );
        } else {
          this.systemError = true;
          this.validationError = false;
        }
      }, error => {
        this.loading = false;
        this.systemError = true;
        this.validationError = false;
      }
    );
  }

  //Check for duplicate claims
  checkDuplicateTrackingNumberClaims() {
    this.claimService.checkDuplicateClaims(this.trackingNumber.value).subscribe(
      data => {
        if (data.IsDuplicateTrackingNumber) {
          //Set an error message
          this.loading = false;
          this.duplicateTrackingNumberError = true;
        } else {
          //No errors for now. Continue to zip code validation.
          this.duplicateTrackingNumberError = false;
          this.validate();
        }
      },
      error => {
        this.loading = false;
        //Business WANTS the user to be able to continue if the check for duplicate claims fails.
        this.duplicateTrackingNumberError = false;
        this.validate();
      }
    );
  }

  get trackingNumber() { return this.customerForm.controls.trackingNumber }
  get email() { return this.customerForm.controls.email }
  get confirmationNumber() { return this.customerForm.controls.confirmationNumber }
  get zipCode() { return this.customerForm.controls.zipCode }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
}

