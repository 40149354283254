import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { IdentityLayoutComponent } from './identity-layout.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { ProductComponent } from '../product/product.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FooterModule } from '../footer/footer.module';

@NgModule({
    imports: [SharedModule, MatDialogModule, FooterModule],
    declarations: [IdentityLayoutComponent],
    providers: []
})
export class IdentityLayoutModule { }
