import { Component, Input, OnInit } from '@angular/core';
import { PayeeService } from 'src/app/services/payee.service';

@Component({
  selector: 'upsc-ach',
  templateUrl: './ach.component.html',
  styleUrls: ['./ach.component.scss']
})
export class AchComponent implements OnInit {
  @Input() payeeDetails;
  @Input() showACHError;
  achurl = this.payeeService.achURL;  //iFrame srcURL

  constructor(
    private payeeService: PayeeService
  ) { }

  ngOnInit(): void { }

}
