import { NgModule } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { CommonModule } from '@angular/common';
import { FooterModule } from '../../shared/components/footer/footer.module';
import { SharedModule } from '../../shared/shared.module';
import { HpCoiRoutingModule } from './hp-coi-routing.module';
import { CoiZipComponent } from './coi-zip.component';
import { NotificationBoxModule } from 'src/app/shared/components/notification-box/notification-box.module';
import { IdentityLayoutModule } from 'src/app/shared/components/identity-layout/identity-layout.module';

@NgModule({
  declarations: [
    CoiZipComponent,
  ],
  imports: [
    MatCheckboxModule,
    CommonModule,
    SharedModule,
    FooterModule,
    NotificationBoxModule,
    HpCoiRoutingModule,
    IdentityLayoutModule
  ]
})
export class HpCoiModule { }
