<div class="wrapper">
    <div class="heading d-flex justify-content-end"><mat-icon (click)="close()">close</mat-icon></div>
    <div class="body">
      <div class="title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.reOpenDialogTitle' | translate }}</div>
      <hr class="green-hr">     
      <div class="content">
        {{ 'claims.edit.review.reviewAndSubmit.reviewList.reOpenDialogContent' | translate }}
      </div>
    </div>
    <div class="navi d-flex justify-content-start align-items-center">
      <button class="upsc-button"(click)="reOpen()">{{ 'claims.edit.review.reviewAndSubmit.reviewList.reOpenClaim' | translate }}</button>      
      <div class="cancel ml-3" (click)="close()">{{ 'claims.edit.review.reviewAndSubmit.reviewList.reOpenClaimCancel' | translate }}</div>
    </div>
  </div>