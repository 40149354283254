<div class="main">
    <div class="icon">
      <em class="material-icons upsgreen600 md-72">check_circle</em>
    </div>
    <div class="password">
      {{ 'loginHelp.password.successContent1' | translate }}
      {{ 'loginHelp.password.successContent2' | translate }}  
    </div>
    <div class="button-align">
        <button class="upsc-button" role="button" type="button" routerLink='/login'>
            <div class="button-content-wrapper"><mat-icon>lock_outline</mat-icon><div class="text-only">{{ 'resetPasswordSuccess.login' | translate }}</div></div>
        </button>
        </div>
    <div class="contact">{{ 'loginHelp.contactUs' | translate }}
        <div class="contact-bold">{{ 'loginHelp.contactUsBold' | translate }}</div>
    </div>      
  </div>
