<div class="wrapper">
  <div mat-dialog-title class="closeIcon d-flex">
    <mat-icon class="ml-auto" (click)="closeDialog()">close</mat-icon>
  </div>
  <!-- Content for export selection-->
  <div *ngIf="!loading" class="export-content">
    <div class="title d-flex justify-content-center">{{ 'claims.list.exportDialog.title' | translate }}</div>
    <div class="file-type d-flex justify-content-center">
      <div class="file-type-contents d-flex justify-content-around">
        <span class="mr-3">{{ 'claims.list.exportDialog.select' | translate }}</span>
        <div class="export-icons d-flex justify-content-around">
          <div class="excel-icon-wrapper" (click)="pdfSelected=false; excelSelected=true">
            <svg *ngIf="!excelSelected" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <g fill="none" fill-rule="evenodd">
                <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M20.625 29.375h-20V.625h28.75v20z" />
                  <path d="M20.625 29.375v-8.75h8.75" />
                </g>
                <text fill="#595555" font-family="Roboto-Bold, Roboto" font-size="14" font-weight="bold"
                  letter-spacing=".4">
                  <tspan x="7.063" y="17">{{ 'claims.list.exportDialog.x' | translate }}</tspan>
                  <tspan x="16.356" y="17">{{ 'claims.list.exportDialog.l' | translate }}</tspan>
                </text>
              </g>
            </svg>

            <svg *ngIf="excelSelected" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <g fill="none" fill-rule="evenodd">
                <g fill="#00857D" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="#00857D" d="M20.625 29.375h-20V.625h28.75v20z" />
                  <path stroke="#FFF" d="M20.625 29.375v-8.75h8.75" />
                </g>
                <text fill="#FFF" font-family="Roboto-Bold, Roboto" font-size="14" font-weight="bold" letter-spacing=".6">
                  <tspan x="6.963" y="17">{{ 'claims.list.exportDialog.x' | translate }}</tspan>
                  <tspan x="16.456" y="17">{{ 'claims.list.exportDialog.l' | translate }}</tspan>
                </text>
              </g>
            </svg>
          </div>
          <div class="separator mx-2"></div>
          <div class="pdf-icon-wrapper" (click)="excelSelected=false; pdfSelected=true">
            <svg *ngIf="!pdfSelected" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <g fill="none" fill-rule="evenodd">
                <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M20.625 29.375h-20V.625h28.75v20z" />
                  <path d="M20.625 29.375v-8.75h8.75" />
                </g>
                <text fill="#595555" font-family="Roboto-Bold, Roboto" font-size="13" font-weight="bold">
                  <tspan x="3.022" y="18">{{ 'claims.list.exportDialog.pdf' | translate }}</tspan>
                </text>
              </g>
            </svg>
            <svg *ngIf="pdfSelected" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <g fill="none" fill-rule="evenodd">
                <g fill="#00645E" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M20.625 29.375h-20V.625h28.75v20z" />
                  <path d="M20.625 29.375v-8.75h8.75" />
                </g>
                <text fill="#FFF" font-family="Roboto-Bold, Roboto" font-size="13" font-weight="bold">
                  <tspan x="3.022" y="18">{{ 'claims.list.exportDialog.pdf' | translate }}</tspan>
                </text>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions d-flex justify-content-center" *ngIf="!loading">
    <div class="dialog-buttons d-flex justify-content-around flex-wrap">
      <a role="button" class="cancel mb-3" (click)="closeDialog()">{{ 'claims.list.exportDialog.cancel' | translate }}</a>
      <a role="button" (click)="download()">{{ 'claims.list.exportDialog.download' | translate }}</a>
    </div>
  </div>
  <div class="helper mb-1" *ngIf="!loading">{{ 'claims.list.exportDialog.tip' | translate }}</div>
  
  <!-- Content during loading -->
  <div *ngIf="loading" class="export-content d-flex flex-column align-items-center loading">
    <div class="spinner-border text-secondary" role="status">
      <span class="sr-only">{{ 'claims.list.exportDialog.loading2' | translate }}</span>
    </div>
    <div class="loading-text">{{ 'claims.list.exportDialog.loading' | translate }}</div>
  </div>
  <div *ngIf="loading" class="d-flex flex-column align-items-center loading actions">
    <a role="button" class="cancel" (click)="closeDialog()">{{ 'claims.list.exportDialog.cancel' | translate }}</a>
  </div>
</div>
