import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilityService } from '../../services/utility.service';
import { Subscription, combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PrivacyComponent } from '../../shared/components/privacy/privacy.component';
import { ProductComponent } from '../../shared/components/product/product.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IEmail, emptyIEmail } from 'src/app/shared/models/email.interface';
import { AccountService } from 'src/app/services/account.service';
import { environment as ENV } from '../../../environments/environment';
import { EMAIL_PATTERN } from 'src/app/shared/regex-patterns';
import { UserService } from 'src/app/services/user.service';
import en from 'src/assets/i18n/en.json';
import { IVerificationInterface, emptyVerificationInterface  } from 'src/app/shared/models/verification.interface';

@Component({
  selector: 'upsc-coi-zip',
  templateUrl: './coi-zip.component.html',
  styleUrls: ['./coi-zip.component.scss']
})
export class CoiZipComponent implements OnInit, OnDestroy {

  accountFormGroup: UntypedFormGroup;

  coiNotFound: boolean = false;
  codeNotMatched: boolean = false;
  systemError: boolean = false;

  codeSent = false;

  readyForVerify: boolean;
  apiInProgress = false;

  tooltipPosition$: any;
  showiPhoneTips: boolean = false;

  private emailModel: IEmail = emptyIEmail;

  user = {
    "firstName": "",
    "lastName": "",
    "zipCode": "",
    "contactPhone": "",
    "policyNumber": "",
    "emailAddress": "",
    "dateRegistered": "",
    "contactName": "",
    "policyHoldersName": "",
    "policyState": ""
  }

  privacyLink = ENV.links.privacyNotice;

  verificationInterface = { emailId: "" };

  private saveRegisterSubscription: Subscription;

  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;

  verifyCodeInterface = { emailId: "", toVerify: "" };

  constructor(public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private utilityService: UtilityService,
    private accountService: AccountService,
    private userService: UserService,
    private router: Router
  ) {
    this.accountFormGroup = this.formBuilder.group({
      policyNumberFormControl: ['', [Validators.required, Validators.pattern(/^15914024-OD\d{9}$/)]],
      zipFormControl: ['', [Validators.required]],
      emailFormControl: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])],
      codeFormControl: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
      techAgreement: ['', [Validators.required]]
    });
    this.readyForVerify = this.accountFormGroup.controls.policyNumberFormControl.valid 
      && this.accountFormGroup.controls.zipFormControl.valid
      && this.accountFormGroup.controls.emailFormControl.valid;
    combineLatest<any, [string]>(this.accountFormGroup.controls.policyNumberFormControl.statusChanges,
      this.accountFormGroup.controls.zipFormControl.statusChanges,
      this.accountFormGroup.controls.emailFormControl.statusChanges).subscribe(
        (statuses) => {
          this.readyForVerify = !statuses.includes('INVALID');
          // console.log("control status", statuses);
        }
      );
    this.tooltipPosition$ = this.utilityService.tooltipPositionWatch();
  }

  ngOnInit() {
    this.titleService.setTitle(en.coiPolicy.setTitle);
  }
  
  ngOnDestroy() {
    this.utilityService.clearSubscriptions([
      this.saveRegisterSubscription
    ]);
  }

/*   checkiPhoneTips(inp: string) {
    if (window.navigator.platform === 'iPhone' && inp === 'on') {
        this.showiPhoneTips = true;
    } 
    else {
      this.showiPhoneTips = false;
    }
  } */

  searchPolicyNumber(event) {
    this.resetError();
    this.user.policyNumber = this.accountFormGroup.get('policyNumberFormControl').value.trim();
    this.user.zipCode = this.accountFormGroup.get('zipFormControl').value.trim();
    this.user.emailAddress = this.accountFormGroup.get('emailFormControl').value.trim();
    this.accountService.policyCheck(this.user.policyNumber, this.user.zipCode).subscribe(
      data => {
        if (data) {
          this.coiNotFound = false;
          this.saveVerificationCode(event);
        }
        else {
          this.coiNotFound = true;
          this.apiInProgress = false;
        }
      },
      error => {
        this.coiNotFound = true;
        this.apiInProgress = false;
      }
    );
  }

  private saveVerificationCode(event) {    
    //New verification code changes 
    this.createVerificationInterface.data.emailAddress = this.user.emailAddress;
    this.accountService.createVerificationCode(this.createVerificationInterface).subscribe(
      res => {
        if ((res.responseMessage).toLowerCase() === 'new verification code created' ||
            (res.responseMessage).toLowerCase() === 'code not yet expired') {
              this.codeSent = true;
              this.apiInProgress = false;
          }          
      }, 
      err => {
        this.handleError();
      }
    );
  }

  //Send an email to user-entered email with a verification code
  public sendVerificationCode(event) {
    this.emailModel.data.email = this.user.emailAddress;
    this.emailModel.emailFor = "COIVerification";
    this.accountService.sendVerificationCodeEmail(this.emailModel).subscribe(
      data => {
        this.codeSent = true;
        this.apiInProgress = false;
      },
      error => {
        this.codeSent = false;
        this.handleError();
      }
    );
  }

  public checkVerificationCode(event) {
    this.codeNotMatched = false;
    this.resetError();
    //Concat all the digits together and then compare to the string
    let userInput = this.accountFormGroup.get('codeFormControl').value;
    
    //Use the retrieveVerificationCode function here to compare userInput
    this.verifyCodeInterface.emailId = this.user.emailAddress;
    this.verifyCodeInterface.toVerify = userInput;

    //New verify code changes
    this.accountService.verifyCode(this.verifyCodeInterface).subscribe(
      data => {
        if ((data.message).toLowerCase() === 'verification code matched') {
          this.codeNotMatched = false;
          this.userService.getTokensForGuestUser(ENV.hpGuestUser).subscribe(
            res => this.userService.loginForGuestUser('/dashboard', this.user),
            error => this.handleError()
          )
        } 
        else {
          this.codeNotMatched = true;
          this.apiInProgress = false;
        }
      }, error => {
        //get verification code error
        this.handleError();
      }
    )
  }

  resendCode(event) {
    this.codeNotMatched = false;
    this.resetError();
    this.accountService.sendVerificationCodeEmail(this.emailModel).subscribe(
      data => {
        this.codeSent = true;
        this.apiInProgress = false;
      },
      error => {
        this.codeSent = false;
        this.handleError();
      }
    );
  }

  private resetError() {
    this.systemError = false;
    this.apiInProgress = true;
  }

  private handleError() {
    this.systemError = true;
    this.apiInProgress = false;

    this.coiNotFound = false;
    this.codeNotMatched = false;
  }

  public openPrivacyDialog() {
    let dialogRef = this.dialog.open(PrivacyComponent);
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
  public openProductDialog() {
    let dialogRef = this.dialog.open(ProductComponent);

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
}
