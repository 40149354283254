import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/services/auth-guard.service';
import { IdentityLayoutComponent } from '../../shared/components/identity-layout/identity-layout.component';
import { GuestVerificationComponent } from './guest-verification.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: IdentityLayoutComponent,
      
      children: [
        {
          path: '',
          component: GuestVerificationComponent,
          // canActivate:[AuthGuardService],
        },        
      ]
    }
  ])],
  exports: [RouterModule]
})
export class GuestVerificationRoutingModule { }
