import { Component, OnInit } from '@angular/core';
import { PolicyService } from 'src/app/services/policy.service';
import { IPolicies } from 'src/app/shared/models/policies.interface';
import { HostListener } from '@angular/core';

@Component({
  selector: 'upsc-insights-container',
  templateUrl: './insights-container.component.html',
  styleUrls: ['./insights-container.component.scss']
})
export class InsightsContainerComponent implements OnInit {
  policy: IPolicies;
  constructor() { }

  ngOnInit(): void {}

  // All code below is to ensure that the background gradient image stays perfectly aligned no matter the screen size
  //Everything below is for new DD Visibility item
  ngAfterViewInit() {
    this.detectScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.detectScreenSize();
  }
  detectScreenSize(event?) {
    if (window?.innerWidth && window.innerWidth > 1448) {
      if (((window.innerWidth - 1438) / -2 ) > -15) {
        let page = document.getElementById('background') as HTMLLinkElement;
        page.style.marginLeft = "-15px";
        page.style.marginRight = "-15px";
      } else {
        let val = (window.innerWidth - 1438) / -2;
        let val2 = val + "px";
        let page = document.getElementById('background') as HTMLLinkElement;
        page.style.marginLeft = val2;
        page.style.marginRight = val2;
      }   
    } else {
      let page = document.getElementById('background') as HTMLLinkElement;
      page.style.marginLeft = "-15px";
      page.style.marginRight = "-15px";
    }
  }
}
