import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-network-change',
  templateUrl: './network-change.component.html',
  styleUrls: ['./network-change.component.scss']
})
export class NetworkChangeComponent {

  constructor(private dialogRef: MatDialogRef<NetworkChangeComponent>) { }

  

  close(): void {
    this.dialogRef.close();
  }

}
