<div class="gray-wrap">
  <div class="policy-main d-flex">
    <div class="yellowbar"></div>
    <div class="info">
      <div class="title" *ngIf="policyType != 'traditional_cargo' && policyType != 'transactional_cargo' && !isCanadaUser">{{ basicPolicy.policyType.value }}</div>
      <div class="title" *ngIf="policyType == 'transactional_parcel' && isCanadaUser">{{ 'policies.transactionalParcel' | translate }}</div>
      <div class="title" *ngIf="(policyType == 'traditional_cargo' || policyType == 'transactional_cargo') && !isCanadaUser">{{ 'policies.cargo' | translate }}<sup>®</sup></div>
      <div class="title" *ngIf="(policyType == 'traditional_cargo' || policyType == 'transactional_cargo') && isCanadaUser">{{ 'policies.cargo' | translate }}</div>
      <div class="subtitle">{{ 'policies.polNum' | translate }}</div>
      <div class="subtitle-info">{{ basicPolicy.policyNumber }}</div>
      <div class="subtitle">{{ 'policies.effective' | translate }}</div>
      <div *ngIf="isUsaUser" class="subtitle-info">{{ basicPolicy?.effectiveDate | date: 'MM/dd/yyyy'}} - {{ basicPolicy?.expirationDate | date: 'MM/dd/yyyy' }}</div>
      <div *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser" class="subtitle-info">{{ basicPolicy?.effectiveDate | date: 'dd/MM/yy'}} - {{ basicPolicy?.expirationDate | date: 'dd/MM/yy' }}</div>
      <div *ngIf="isUsaUser" class="help">{{ 'policies.help1' | translate }}<a href="mailto:capitalus@ups.com" class="link">{{ 'policies.email' | translate }}</a>{{ 'policies.help2' | translate }}</div>
      <div *ngIf="isCanadaUser" class="help">{{ 'policies.help1' | translate }}<a href="mailto:insureshieldca@ups.com" class="link">{{ 'policies.email' | translate }}</a>{{ 'policies.help2' | translate }}</div>
      <div *ngIf="isUkUser" class="help">{{ 'policies.help1' | translate }}<a href="mailto:risksolutionsuk@ups.com" class="link">{{ 'policies.email' | translate }}</a>{{ 'policies.help2' | translate }}</div>
      <div *ngIf="isGermanyUser" class="help">{{ 'policies.help1' | translate }}<a href="mailto:risksolutionsde@ups.com" class="link">{{ 'policies.email' | translate }}</a>{{ 'policies.help2' | translate }}</div>
      <div *ngIf="isFranceUser" class="help">{{ 'policies.help1' | translate }}<a href="mailto:risksolutionsfr@ups.com" class="link">{{ 'policies.email' | translate }}</a>{{ 'policies.help2' | translate }}</div>
      <div *ngIf="isItalyUser" class="help">{{ 'policies.help1' | translate }}<a href="mailto:risksolutionsit@ups.com" class="link">{{ 'policies.email' | translate }}</a>{{ 'policies.help2' | translate }}</div>
    </div>
  </div>
  <upsc-collapsible-panel class="upsc-custom-policies" [title]="'policies.polDet' | translate" [isCollapsed]="true" *ngIf="extendedPolicy?.policy">
    <hr>
    <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
      <div class="left mr-md-1">
        <div class="box mb-2 mb-md-3">
          <div class="title">{{ 'policies.insured' | translate }}</div>
          <div class="text">{{ extendedPolicy?.account?.company?.companyName }}</div>
        </div>
        <div class="box mb-2 mb-md-0"> 
          <div class="title">{{ 'policies.address' | translate }}</div>
          <div class="text">{{ extendedPolicy?.account?.company?.address?.streetAddress1 }}</div>
          <div class="text" *ngIf="extendedPolicy?.account?.company?.address?.streetAddress2">{{ extendedPolicy?.account?.company?.address?.streetAddress2 }}</div>
          <div class="text">{{ extendedPolicy?.account?.company?.address?.city }}</div>
          <div class="text">{{ extendedPolicy?.account?.company?.address?.stateProvince }} {{ extendedPolicy?.account?.company?.address?.zip }}</div>
          <div class="text">{{ extendedPolicy?.account?.company?.address?.country }}</div>
        </div>
      </div>
      <div class="right ml-md-1">
        <div class="box mb-2 mb-md-3">
          <div class="title">{{ 'policies.type' | translate }}</div>
          <div class="text" *ngFor="let coverages of extendedPolicy?.policy?.coverageParts">{{ coverages }}</div>
        </div>
        <div class="box">
          <div class="title">{{ 'policies.active' | translate }}</div>
          <div *ngIf="isUsaUser || isCanadaUser" class="text">{{ extendedPolicy?.policy.periodStartDate }} - {{ extendedPolicy?.policy.policyEndDate }}</div>
          <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser" class="text">{{ extendedPolicy?.policy.periodStartDate  }} - {{ extendedPolicy?.policy.policyEndDate  }}</div>
        </div>
      </div>
    </div>
  </upsc-collapsible-panel>
  <div class="showDocs" *ngIf="isCanadaUser && showDocuments">
    <div class="contents">
      <div class="panel-header">{{'policies.docHeader' | translate}}</div>
      <hr>
      <div class="panel-content mb-3">{{'policies.docDescr' | translate}}</div>
      <div class="panel-content mb-3">{{'policies.docDescr2' | translate}}</div>
      <a href="{{ principlesofConduct }}" target="_blank">{{'policies.principlesofConduct' | translate}}</a>
      <mat-icon class="mr-3">launch</mat-icon>
      <a href="{{ factSheet }}" target="_blank">{{'policies.factSheet' | translate}}</a>
      <mat-icon>launch</mat-icon>
    </div>
  </div>
  <upsc-collapsible-panel class="upsc-custom-policies" [title]="'policies.commodity.title' | translate" [isCollapsed]="true" *ngIf="extendedPolicy?.policy">
    <hr>
    <table class="desktop-table regular">
      <thead>
        <tr>
          <th scope="col" class="type">{{ 'policies.commodity.type' | translate }}</th>
          <th scope="col" class="percent">{{ 'policies.commodity.percent' | translate }}</th>
          <th scope="col" class="descr">{{ 'policies.commodity.descr' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bottomborder" *ngFor="let commodity of extendedPolicy?.policy.commodities">
          <td>{{ commodity.commodityType }}</td>
          <td>{{ commodity.percentage }}%</td>
          <td *ngIf="commodity.commodityDescription">{{ commodity.commodityDescription }}</td>
          <td *ngIf="!commodity.commodityDescription">{{ 'policies.np' | translate }}</td>
        </tr>
      </tbody>
    </table>
    <div class="mobile-table">
      <div class="box commodity" *ngFor="let commodity of extendedPolicy?.policy.commodities">
        <div class="title">{{ 'policies.commodity.type' | translate }}</div>
        <div class="subtitle pb-2">{{ commodity.commodityType }}</div>
        <div class="title">{{ 'policies.commodity.percent' | translate }}</div>
        <div class="subtitle pb-2">{{ commodity.percentage }}%</div>
        <div class="title">{{ 'policies.commodity.descr' | translate }}</div>
        <div class="subtitle" *ngIf="commodity.commodityDescription">{{ commodity.commodityDescription }}</div>
        <div class="subtitle" *ngIf="!commodity.commodityDescription">{{ 'policies.np' | translate }}</div>
      </div>
    </div>
  </upsc-collapsible-panel>
  <upsc-collapsible-panel class="upsc-custom-policies" [title]="'policies.coverage.title' | translate" [isCollapsed]="true" *ngIf="extendedPolicy?.policy && isCanadaUser || isUsaUser">
    <hr>
    <ng-container *ngFor="let coverage of coveragesAndDeductibles">
      <table class="desktop-table regular mb-3" *ngIf="coverage.coverage.length>0">
        <thead>
          <tr>
            <th colspan="2" rowspan="2" class="rightborder big" id="one">{{ 'policies.coverage.limType' | translate }}</th>
            <th colspan="2" class="bottomborder text-center big" id="two">{{ coverage.coveragePartName }}</th>
          </tr>
          <tr>
            <th class="rightborder w-25" id="three">{{ 'policies.coverage.liability' | translate }}</th>
            <th class="w-25" id="four">{{ 'policies.coverage.deductible' | translate }}</th>
          </tr>
        </thead>
        <tbody class="bottomborder topborder" *ngFor="let coverages of coverage.coverage">
          <tr class="bottomborder">
            <th [attr.rowspan]="coverages.geographicMovement.length+1" scope="rowgroup" class="rightborder" id="five">{{ coverages.coverageName }}</th>
          </tr>
          <tr class="bottomborder" *ngFor="let geoMoves of coverages.geographicMovement">
            <th scope="row" class="rightborder nobold geo-name" *ngIf="(geoMoves.limitVal && geoMoves.deductibleVal) && geoMoves.name != 'Within US(Domestic)'" id="six">{{ geoMoves.name }}</th>
            <th scope="row" class="rightborder nobold geo-name" *ngIf="(geoMoves.limitVal && geoMoves.deductibleVal) && geoMoves.name == 'Within US(Domestic) || Within Canada'" id="seven">{{ 'policies.coverage.within' | translate }}</th>
            <td class="rightborder" *ngIf="geoMoves.limitVal && geoMoves.deductibleVal">
              <span *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'USD'">$</span>
              <span *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'CAD'">CAD</span>
              <span *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'GBP'">£</span>
              <span *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'EUR'">€</span>
              {{ geoMoves.limitVal }}</td>
            <td *ngIf="geoMoves.limitVal && geoMoves.deductibleVal">
              <span *ngIf="geoMoves.deductibleType=='Flat' && extendedPolicy?.policy.premium.totalPremiumCurrency == 'USD'">$</span>
              <span *ngIf="geoMoves.deductibleType=='Flat' && extendedPolicy?.policy.premium.totalPremiumCurrency == 'CAD'">CAD</span>
              <span *ngIf="geoMoves.deductibleType=='Flat' && extendedPolicy?.policy.premium.totalPremiumCurrency == 'GBP'">£</span>
              <span *ngIf="geoMoves.deductibleType=='Flat' && extendedPolicy?.policy.premium.totalPremiumCurrency == 'EUR'">€</span>
              {{ geoMoves.deductibleVal }}<span *ngIf="geoMoves.deductibleType=='Percentage'">%</span></td>
          </tr>
        </tbody>
        <tbody class="bottomborder topborder">
          <tr>
            <th rowspan="2" class="bottomborder rightborder" id="eight">{{ 'policies.coverage.premium' | translate }}</th>
          </tr>
          <tr>
            <th colspan="3" class="bottomborder nobold specific-height-1 text-right" id="nine">
              <span *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'USD'">$</span>
              <span *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'CAD'">CAD</span>
              <span *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'GBP'">£</span>
              <span *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'EUR'">€</span>
              {{ extendedPolicy?.policy.premium.totalPremiumAmount }}</th>
          </tr>
          <tr>
            <th rowspan="2" class="rightborder" id="ten">{{ 'policies.coverage.plan' | translate }}</th>
          </tr>
          <tr>
            <th colspan="3" class="nobold specific-height-2 text-right" id="ele">{{ extendedPolicy?.policy.premium.paymentPlan }}</th>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container *ngFor="let coverage of coveragesAndDeductibles">
      <div class="mobile title" *ngIf="coverage.coverage.length>0">{{ coverage.coveragePartName }}</div>
      <div class="mobile-table mb-3" *ngIf="coverage.coverage.length>0">
        <div class="box" *ngFor="let coverages of coverage.coverage">
          <div class="header">
            <div class="title">{{ 'policies.coverage.limType' | translate }}</div>
            <div class="subtitle">{{ coverages.coverageName }}</div>
          </div>
          <div class="subbox bottomborder" *ngFor="let geoMoves of coverages.geographicMovement">
            <div class="textgroup nopadbot">
              <div class="title">{{ 'policies.coverage.geo' | translate }}</div>
              <div class="subtitle">{{ geoMoves.name }}</div>
            </div>
            <div class="textgroup nopadbot">
              <div class="title">{{ 'policies.coverage.liability' | translate }}</div>
              <div class="subtitle" *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'USD'">${{ geoMoves.limitVal }}</div>
              <div class="subtitle" *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'GBP'">£{{ geoMoves.limitVal }}</div>
              <div class="subtitle" *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'EUR'">€{{ geoMoves.limitVal }}</div>
              <div class="subtitle" *ngIf="extendedPolicy?.policy.premium.totalPremiumCurrency == 'CAD'">CAD {{ geoMoves.limitVal }}</div>
            </div>
            <div class="textgroup">
              <div class="title">{{ 'policies.coverage.deductible' | translate }}</div>
              <div class="subtitle">
                <span *ngIf="geoMoves.deductibleType=='Flat' && extendedPolicy?.policy.premium.totalPremiumCurrency == 'USD'">$</span>
                <span *ngIf="geoMoves.deductibleType=='Flat' && extendedPolicy?.policy.premium.totalPremiumCurrency == 'CAD'">CAD</span>
                <span *ngIf="geoMoves.deductibleType=='Flat' && extendedPolicy?.policy.premium.totalPremiumCurrency == 'GBP'">£</span>
                <span *ngIf="geoMoves.deductibleType=='Flat' && extendedPolicy?.policy.premium.totalPremiumCurrency == 'EUR'">€</span>
                {{ geoMoves.deductibleVal }}<span *ngIf="geoMoves.deductibleType=='Percentage'">%</span></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="mobile-premiums">
      <div class="title">{{ 'policies.coverage.premium' | translate }}</div>
      <div class="info mb-3">
        <span *ngIf="extendedPolicy?.policy?.premium?.totalPremiumCurrency == 'USD'">$</span>
        <span *ngIf="extendedPolicy?.policy?.premium?.totalPremiumCurrency == 'CAD'">CAD</span>
        <span *ngIf="extendedPolicy?.policy?.premium?.totalPremiumCurrency == 'GBP'">£</span>
        <span *ngIf="extendedPolicy?.policy?.premium?.totalPremiumCurrency == 'EUR'">€</span>
        {{ extendedPolicy?.policy?.premium?.totalPremiumAmount }}</div>
      <div class="title">{{ 'policies.coverage.plan' | translate }}</div>
      <div class="info">{{ extendedPolicy?.policy?.premium?.paymentPlan }}</div>
    </div>
  </upsc-collapsible-panel>
  <upsc-collapsible-panel class="upsc-custom-policies" [title]="'policies.endorsements.title' | translate" [isCollapsed]="true" *ngIf="extendedPolicy?.policy">
    <div class="subtitle" *ngIf="!isCanadaUser">
      {{ 'policies.endorsements.subtitle' | translate }}
    </div>
    <hr class="mt-3">
    <div class="mini-table">
      <div class="header">
        <div class="title">{{ 'policies.endorsements.standard' | translate }}</div>
      </div>
      <div class="content d-flex flex-column flex-md-row">
        <div class="left mr-md-2">
          <div *ngFor="let endorsement of secondHalfEndorsements">
            <div class="line">{{ endorsement.formDesc }}</div>
          </div>
        </div>
        <div class="right ml-md-2">
          <div *ngFor="let endorsement of firstHalfEndorsements">
            <div class="line">{{ endorsement.formDesc }}</div>
          </div>
        </div>
      </div>
    </div>
  </upsc-collapsible-panel>
  <upsc-collapsible-panel class="upsc-custom-policies" [title]="'policies.documents.title' | translate" [isCollapsed]="true" *ngIf="extendedPolicy?.policy">
    <hr>
    <table class="docs">
      <tbody>
        <tr *ngFor="let document of extendedPolicy?.policy?.documents">
          <td *ngIf="isUsaUser" class="date">{{ document.createTime | date: 'MM/dd/yyyy' }}</td>
          <td *ngIf="isCanadaUser || isUkUser || isGermanyUser ||isFranceUser || isItalyUser" class="date">{{ document.createTime | date: 'dd/MM/yy' }}</td>
          <td class="type">{{ document.docType | titlecase }}</td>
          <td class="name"><span (click)="downloadDocument(document)">{{ document.name }}</span></td>
        </tr>
      </tbody>
    </table>
  </upsc-collapsible-panel>
</div>