import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'upsc-selection-card',
  templateUrl: './selection-card.component.html',
  styleUrls: ['./selection-card.component.scss']
})
export class SelectionCardComponent implements  OnChanges {
  @Input() radiobutton: boolean; //if true, render a radio button
  @Input() radiobuttonValue: string; //used in combination with radiobutton
  @Input() public text = "";
  @Input() checkbox: boolean; //if true, render acheckbox
  @Input() isSelected: boolean;

  @Input() defaultBackground: boolean; //if true, set a  default background color
  @Input() selectionBackground: boolean; //if true, set a background color when selected
  
  @Input() selected: boolean; //mainly used for radio button
  @Input() showIcon: boolean; //if showing the yellow icon
  @Output() selectionChange = new EventEmitter<boolean>();

  checkboxControl: UntypedFormControl;

  constructor() {
    //this is for checkbox
    this.checkboxControl = new UntypedFormControl();
    this.checkboxControl.valueChanges.subscribe(
      value => {
        if(value) {
          this.selected = true;
        }
        else {
          this.selected = false;
        }
        this.selectionChange.emit(this.selected);
      }
    );
  }

  //this is for both check box and radio button
  ngOnChanges(changes: any) {
    if(changes.selected) {
      this.selected = changes.selected.currentValue;
      this.checkboxControl.setValue(this.selected);
    }
  }
}
