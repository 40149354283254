import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResetpasswordRoutingModule } from './resetpassword-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ResetpasswordComponent } from './resetpassword.component';
import { SharedModule } from '../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ResetpasswordSuccessComponent } from './component/resetpassword-success/resetpassword-success.component';
import { IdentityLayoutModule } from '../../shared/components/identity-layout/identity-layout.module';

@NgModule({
  declarations: [
    ResetpasswordComponent,
    ResetpasswordSuccessComponent
  ],
  imports: [
    CommonModule,
    ResetpasswordRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatInputModule,
    MatIconModule,
    IdentityLayoutModule
  ],
  providers:[]
})
export class ResetpasswordModule { }
