<ul class="navigation-items" [hidden]="!showRLSNav">
  <li class="navigation-item" *ngIf="!isAdjuster && !isDdUser && (!isGuestClaim || isHPGuestUser)">
    <a href="#" routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
      (click)="tagging('dashboard')">{{
      'app.navigation.dashboard' | translate }}</a>
  </li>
  <li class="navigation-item" *ngIf="!isAdjuster && !isDdUser && (!isGuestClaim || isHPGuestUser)">
    <a href="#" routerLink="/policies" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
      (click)="tagging('policies')">{{
      'app.navigation.policies' | translate }}</a>
  </li>
  <li class="navigation-item d-flex align-items-center"
    *ngIf="(userPermission.isClaimView) || (userPermission.isFileClaim) && !isAdjuster && !isDdUser && (!isGuestClaim || isHPGuestUser)">
    <span class="h-100">
      <a href="#" routerLink="/claims" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
        (click)="tagging('claims')">
        {{'app.navigation.claims' | translate }}
      </a>
    </span>
    <span *ngIf="showStats && (userPermission.isFileClaim)" (click)="gotoMessages($event)" class="message-badge"
      ngbTooltip="You have {{claimStats?.claimsWithNewMessagesCnt}} new messages!" placement="right"
      tooltipClass="ngb-tooltip-class">
      {{ claimStats?.claimsWithNewMessagesCnt }}
    </span>
  </li>
  <li class="navigation-item"
    *ngIf="(userPermission.isBillingView) && !isAdjuster && !isGuestClaim && showBilling">
    <a href="#" routerLink="/billing" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
      (click)="tagging('billing')">{{
      'app.navigation.billing' | translate }}</a>
  </li>

  <li class="navigation-item" *ngIf="isGuestUser && !isDdUser">
    <a href="#" routerLink="/claims/new" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      {{ 'claims.claimMenus.fileNewClaim' | translate }}
    </a>
  </li>
  <li class="navigation-item" *ngIf="isGuestUser && !isDdUser">
    <a href="#" routerLink="/claims/help" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      {{ 'claims.claimMenus.claimsHelp' | translate }}
    </a>
  </li>
  <li class="navigation-item"
    *ngIf="!isGuestClaim && !isAdjuster && hasSmartUpload && (userPermission.isSmartUpload) && !isDdUser">
    <a (click)="startSmartUpload($event)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
      {{'smartUpload.title' | translate}}
    </a>
  </li>
  <!-- <li class="navigation-item"
    *ngIf="!isGuestClaim && hasShipVision">
    <a (click)="openShipVison($event)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
      {{'app.navigation.deliveryDefenseShipVision' | translate }}
    </a>
  </li> -->
  <li class="navigation-item" *ngIf="(!isGuestClaim && !isAdjuster &&
   hasInsights && !isDdUser && (userPermission.isAnalytics)) || (!isGuestClaim && hasShipVision)">
    <a (click)="openShipVision($event)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
      {{'insights.dashboard.title' | translate}}
    </a>
  </li>
  <li class="navigation-item"
    *ngIf="!isGuestClaim && !isAdjuster && isUsaUser && !isDdUser">
    <a (click)="validateDeliveryDefense($event)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
      {{'app.navigation.deliveryDefense' | translate }}
    </a>
  </li>
  <li class="navigation-item" *ngIf="!isAdjuster && !isDdUser">
    <a [ngbPopover]="(isCanadaUser && isGuestUser) == true ?  contactusPopover : isCanadaUser == true ? contactusPopoverCanada : contactusPopover"
      placement="{{popoverPosition$ | async}}" popoverTitle="" popoverClass="upsc-popover">
      <span class="value align-self-center d-none d-sm-block">{{ 'app.navigation.contactUs' | translate }}</span>
      <span class="key align-self-center link d-block d-sm-none">
        <img src="../../../../assets/images/contact_onl.svg" alt="{{ 'billing.method.support' | translate }}">
      </span>
    </a>
  </li>
  <li class="navigation-item" *ngIf="isAdjuster && !isDdUser">
    <a href="#" routerLink="/adjusterDashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{
      'app.navigation.adjuster.dashboard' | translate }}</a>
  </li>
</ul>

<!-- HTML for popover content -->
<ng-template #contactusPopover>
  <div class="supports">
    <div class="speak-with-a-license">{{ 'support.subtitle' | translate }}</div>
    <hr class="d-block d-md-none">
    <div class="contact-block d-flex flex-column">
      <!-- Telephone block mobile -->
      <a href="tel:18772638772" class="wrap tele d-flex flex-column align-self-center d-block d-md-none">
        <img src="../../../../assets/images/Phone.svg" alt="{{ 'billing.method.phone' | translate }}">
        <span class="key align-self-center link d-block d-md-none">{{ 'support.speak' | translate }}</span>
        <span class="key align-self-center d-none d-md-block">{{ 'support.speak:' | translate }}</span>
        <span class="key align-self-center normal d-none d-md-block">{{ 'support.phone' | translate
          }}</span>
        <span class="value align-self-center link">{{ 'support.time' | translate }}</span>
      </a>
      <!-- Telephone block desktop -->
      <div class="wrap tele flex-column align-self-center d-none d-md-block">
        <img src="../../../../assets/images/Phone.svg" alt="{{ 'billing.method.phone' | translate }}">
        <span class="key align-self-center link d-block d-md-none">{{ 'support.speak' | translate }}</span>
        <span class="key align-self-center d-none d-md-block">{{ 'support.speak:' | translate }}</span>

        <span class="key align-self-center normal d-none d-md-block">{{ 'support.phone' | translate
          }}</span>
        <span class="value align-self-center link">{{ 'support.time' | translate }}</span>
      </div>
    </div>
    <hr class="d-block d-md-none">
    <div class="contact-block d-flex flex-column align-items-center">
      <a href="{{ isCanadaUser ? 'mailto:insureshieldca@ups.com' : isUkUser ? 'mailto:risksolutionsuk@ups.com' : isGermanyUser ? 'mailto:risksolutionsde@ups.com' : isFranceUser ? 'mailto:risksolutionsfr@ups.com' : isItalyUser ? 'mailto:risksolutionsit@ups.com' : 'mailto:capitalus@ups.com' }}"
        class="wrap d-flex flex-column align-items-center">
        <img src="../../../../assets/images/mail.svg" alt="{{ 'billing.method.email' | translate }}">
        <span class="key align-self-center link d-block d-md-none">{{ 'support.email' | translate }}</span>
        <span class="value align-self-center bold d-none d-md-block">{{ 'support.email:' | translate }}</span>
        <span class="key align-self-center link1 d-none d-md-block">{{ 'support.emailValue' |
          translate }}</span>
      </a>
    </div>
    <hr class="d-block d-md-none">

    <div class="contact-block d-flex flex-column">
      <div class="wrap d-flex flex-column align-self-center" (click)="openContactUsDialog()">
        <img src="../../../../assets/images/message.svg" alt="{{ 'billing.method.contactUs' | translate }}">
        <span class="key align-self-center link d-block d-md-none">{{ 'support.contact' | translate }}</span>
        <span class="value align-self-center bold d-none d-md-block">{{ 'support.contact:' | translate }}</span>
        <span class="key align-self-center link1 d-none d-md-block">{{ 'support.online' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>

<!-- HTML for popover content -->
<ng-template #contactusPopoverCanada>
  <div class="supports">
    <div class="speak-with-a-license">{{ 'support.subtitle' | translate }}</div>
    <div class="contact-block d-flex flex-column">
      <div class="wrap d-flex flex-column align-self-center" (click)="openContactUsDialog()">
        <img src="../../../../assets/images/message.svg" alt="{{ 'billing.method.contactUs' | translate }}">
        <span class="key align-self-center normal mb-2">{{ 'support.claimInfo' | translate }}</span>
        <span class="key align-self-center normal">{{ 'support.phone:' | translate }}</span>
      </div>
    </div>
    <hr class="d-block d-md-none">
    <div class="contact-block d-flex flex-column mt-2">
      <div class="wrap d-flex flex-column align-self-center">
        <img src="../../../../assets/images/Phone.svg" alt="{{ 'billing.method.phone' | translate }}">
        <span class="key align-self-center mb-2">{{ 'support.policyInfo' | translate }}</span>
        <a href="mailto:insureshieldca@ups.com" class="wrap tele d-flex flex-column align-self-center">
          <span class="key align-self-center link1">{{ 'support.emailValue' | translate }}</span>
        </a>
        <span class="key align-self-center normal">{{ 'support.call' | translate }}</span>
      </div>
    </div>
    <hr class="d-block d-md-none">
    <div class="contact-block d-flex flex-column  mt-2">
      <div class="wrap d-flex flex-column">
        <span class="key align-self-center  mb-2">{{ 'support.feedback' | translate }}</span>
        <a href="mailto:insureshieldcacomplaint@ups.com" class="wrap tele d-flex flex-column">
          <span class="key align-self-center link1">{{ 'support.feedbackemailvalue' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</ng-template>