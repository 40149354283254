import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { IdentityLayoutModule } from '../../shared/components/identity-layout/identity-layout.module';
import { GuestVerificationComponent } from './guest-verification.component';
import { GuestVerificationRoutingModule } from './guest-verification-routing.module';
import { NotificationBoxModule } from 'src/app/shared/components/notification-box/notification-box.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

@NgModule({
  declarations: [
    GuestVerificationComponent
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    GuestVerificationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatInputModule,
    MatIconModule,
    IdentityLayoutModule,
    NotificationBoxModule
  ],
  providers:[]
})
export class GuestVerificationModule { }
