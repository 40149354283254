<div class="d-flex flex-column flex-md-row align-items-md-center">
  <div ngbDropdown class="d-flex flex-column flex-md-row m-2">
    <button ngbDropdownToggle [ngClass]="applied == true ? 'upsc-rectangle-button' : 'upsc-white-rectangle'">
      <span class="selectedValues" *ngIf="!applied"> {{ 'claims.list.claimsSearch.advanceSearch.claimStatusesSearch.title' | translate }}</span>
      <span class="selectedValues" *ngIf="applied">{{ displayStatus.join(', ') }} </span>
      <img *ngIf="applied" class="ml-2" (click)="reset()" src="../../../../assets/images/cross.svg">
    </button>

    <div ngbDropdownMenu class="drop-wrap" x-placement="top-left">
      <form [formGroup]="claimStatusForm">
        <div class="claimLists d-flex flex-column">
          <mat-list-item *ngFor="let category of claimStatus">
            <mat-checkbox class="green-background-checkbox advanced-search-layout" formControlName="{{category}}" (change)="check($event,category)">{{category | translate}}</mat-checkbox>
            <div *ngIf="claimStatusForm.invalid && (claimStatusForm.dirty || claimStatusForm.touched)" class="upsc-errors">
              <div *ngIf="status.errors.required" class="d-flex align-items-center errors">
                {{ 'claims.list.claimsSearch.advanceSearch.claimStatusesSearch.claimSubStatusReq' | translate }}
              </div>
            </div>
          </mat-list-item>
        </div>
        <div class="upsc-errors" *ngIf="valueErrors">
          {{ 'claims.list.claimsSearch.advanceSearch.claimStatusesSearch.claimSubStatusValidation' | translate }}
        </div>
        <div class="d-flex flex-row flex-md-row justify-content-start mt-3 align-items-center">
          <button class="upsc-button mr-3" (click)="apply($event)">{{ 'claims.list.claimsSearch.advanceSearch.done' | translate }}</button>
          <div class="cancel" (click)="close()">{{ 'claims.list.claimsSearch.advanceSearch.cancel' | translate }}</div>
        </div>
      </form>
    </div>
  </div>
</div>