import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { TupssService } from '../../../services/tupss.service';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'upsc-delivery-status',
  templateUrl: './delivery-status.component.html',
  styleUrls: ['./delivery-status.component.scss']
})
export class DeliveryStatusComponent implements OnInit {
  showSpinner = true;
  systemError: boolean;
  showEmailVerification: boolean;
  constructor(
    private route: ActivatedRoute,
    private tupssService: TupssService,
    private accountService: AccountService,
  ) { }

  ngOnInit(): void {
    //let urlParmas = this.route.snapshot.fragment?.match(/^policyNumber=([A-Za-z0-9\-]+)&claimNumber=([0-9\-]+)&email=(.+?)&emailDate=(\d{4}-\d{2}-\d{2})/);
    this.route.queryParams.subscribe(
      params => {
        if(params.policyNumber && params.claimNumber && params.email && params.emailDate) {
          this.tupssService.userDetails = {
            policyNumber: params.policyNumber,
            claimNumber: params.claimNumber,
            emailAddress: params.email,
            emailDate: params.emailDate,
            deliveryScanIndicator: params.deliveryConfirmation,
            type: 'tccp',
            stage: 'post',
            roleId: '6'
          }

            this.accountService.createVerificationCode({...emptyVerificationInterface, ...{
              emailFor: 'TCCPClaimSuccessVerificationCode',
              data: {
                emailAddress: params.email,
                companyName: '',
                streetAddress: '',
                city: '',
                state: '',
                zip:'',
                firstName: '',
                lastName: ''
              }
            }}).subscribe(
              data => {
                this.showSpinner = false;
                this.showEmailVerification = true;
              },
              error => {
                this.showSpinner = false;
                this.systemError = true;
              }
            )
        }
        else {
          this.showSpinner = false;
          this.systemError = true;
        }
      }
    )
  }
}
