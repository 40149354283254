import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AccountInfo } from '../../../shared/models/account-info.interface';
import { UtilityService } from '../../../services/utility.service';
import { ClaimService } from 'src/app/services/claim.service';
import { IClaimsDetails } from '../../../shared/models/claim.interface';
import { TranslateService } from '@ngx-translate/core';
import { IPolicies, POLICYTYPES } from '../../../shared/models/policies.interface';
import { IEmail, emptyIEmail } from '../../models/email.interface';
import { PhoneNumberPipe } from '../../pipes/phone-number.pipe';
import { UserService } from 'src/app/services/user.service';
import { LoginService } from 'src/app/services/login.service';
import { TaggingService } from 'src/app/tags/tagging.service';
import { PolicyService } from 'src/app/services/policy.service';


@Component({
  selector: 'upsc-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent  {

  //Data
  userDetails: AccountInfo;
  claimDetails: IClaimsDetails;
  policyDetails: IPolicies;
  private emailModel: IEmail = emptyIEmail;
  policyType: POLICYTYPES;

  //Form
  public contactForm: UntypedFormGroup;

  //Display helpers
  page = 1;
  adjusterEmail: string = "";
  processing: boolean = false;

  //Scenarios
  isUkUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isUSAUser: boolean;
  isCaUser: boolean;
  isGuestUser: boolean;

  public i18 = require('../../../../assets/i18n/'+this.translateService.getDefaultLang()+'.json');

  public topicList = [
    { key: 1, value: this.i18.support.dialog.topicList.topic1 },
    { key: 2, value: this.i18.support.dialog.topicList.topic2 },
    { key: 3, value: this.i18.support.dialog.topicList.topic3 },
    { key: 4, value: this.i18.support.dialog.topicList.topic4 },
    { key: 5, value: this.i18.support.dialog.topicList.topic5 }
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private utilityService: UtilityService,
    private claimService: ClaimService,
    private loginService: LoginService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ContactUsComponent>,
    private phonePipe: PhoneNumberPipe,
    private taggingService: TaggingService,
    private userService: UserService,
    private policyService: PolicyService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    //Get First Name + Last Name + Email
    this.userDetails = this.userService.getUserInfo();
    this.policyDetails = this.loginService.getPolicyDetails();
    this.isUkUser = this.userService.isUkUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.isUSAUser = this.userService.isUsaUser();
    this.isCaUser = this.userService.isCanadaUser();
    this.isGuestUser = this.userService.isGuestUser();
    this.policyType = this.policyService.determinePolicyType(this.policyDetails);
    this.contactForm = this.fb.group({
      topic: ['', Validators.compose([Validators.required])],
      claim: [''],
      description: [''],
      name: [this.userDetails.firstName + " " + this.userDetails.lastName, Validators.compose([Validators.required])],
      contact: ['', Validators.compose([Validators.required])],
      // phone: [this.phonePipe.transform(this.userDetails.contactPhone)],
      phone: [this.userDetails.contactPhone],
      email: [this.userDetails.emailAddress]
    });

    //If user chooses phone, make it required and remove email requirement.
    //If opposite is chosen, opposite is required.
    this.contactForm.controls.contact.valueChanges.subscribe(
      value => {
        if (value === 'phone') {
          this.contactForm.get('phone').setValidators(Validators.compose([Validators.required]));
          this.contactForm.get('email').clearValidators();
          this.contactForm.get('phone').updateValueAndValidity();
          this.contactForm.get('email').updateValueAndValidity();
        } else if (value === 'email') {
          this.contactForm.get('email').setValidators(Validators.compose([Validators.required,Validators.email]));
          var atIndex = value.indexOf('@');
          if (atIndex == -1) {
          } else {
            //Check if there is a period after the @
            var dotIndex = value.substring(atIndex).indexOf('.');
            if (dotIndex == -1) {
              this.contactForm.get('email').setErrors({ 'invalid': true });
            }
          }
          this.contactForm.get('phone').clearValidators();
          this.contactForm.get('email').updateValueAndValidity();
          this.contactForm.get('phone').updateValueAndValidity();
        } else {
          //Shouldn't be able to reach this
        }
      }
    );
  }

  

  //Call claim detail API to grab phone number associated with claim
  public getClaimDetails() {
    this.claimService.getClaimDetails(this.contactForm.get('claim').value, this.userService.getUserInfo().policyNumber).subscribe(
      data => {
        if (data) {
          this.contactForm.get('phone').setValue(data.mainContact.workNumber);
          this.adjusterEmail = data.adjuster.email;
        }
      },
      error => {
        //Error calling API
      }
    )
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && charCode != 43 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
  //Submit data to email service & change page to confirmation page
  public onSubmit() {
    let topicKey = this.contactForm.get('topic').value;
    let claimVal = this.contactForm.get('claim').value ? this.contactForm.get('claim').value : "None";
    let email = "";

    //If user enters a claim #, try to get the adjuster email.
    //If no adjuster email found, default to the policy email.
    //If user doesn't enter a claim #, send to the policy email.
    if(claimVal) {
      if(this.adjusterEmail) {
        email = this.adjusterEmail;
      } else {
        email = this.policyType == 'transactional_parcel' ? 'upscapitalflexclaims@ups.com' : (this.policyType == 'transactional_cargo' || this.policyType == 'traditional_cargo') ? 'upscapitalcargoclaims@ups.com' : 'none';
      }
    } else {
      email = this.policyType == 'transactional_parcel' ? 'upscapitalflexclaims@ups.com' : (this.policyType == 'transactional_cargo' || this.policyType == 'traditional_cargo') ? 'upscapitalcargoclaims@ups.com' : 'none';
    }

    if(this.isUkUser) {
      this.emailModel.emailFor = "ContactUsEmailGBTo";
    }
    else if(this.isGermanyUser) {
      this.emailModel.emailFor = "ContactUsEmailDETo";
    }
    else if(this.isItalyUser) {
      this.emailModel.emailFor = "ContactUsEmailITTo";
    }
    else if(this.isFranceUser) {
      this.emailModel.emailFor = "ContactUsEmailFRTo";
    }
    else {
      this.emailModel.emailFor = "ContactUs";
    }
    this.emailModel.data.email = email;
    this.emailModel.data.emailAbout = topicKey === 1 ? 'MyClaim' : topicKey === 2 ? 'MyPolicy' : topicKey === 3 ? 'MyProfile' : topicKey === 4 ? 'TechnicalIssue' : topicKey === 5 ? 'Other' : 'Error';
    this.emailModel.data.policyNumber = this.userDetails.policyNumber;
    this.emailModel.data.fName = this.userDetails.firstName;
    this.emailModel.data.lName = this.userDetails.lastName;
    this.emailModel.data.methodOfContact = this.contactForm.get('contact').value === 'phone' ? this.contactForm.get('phone').value : this.contactForm.get('email').value;
    this.emailModel.data.contactInfo = this.contactForm.get('contact').value;
    this.emailModel.data.description = this.contactForm.get('description').value ? this.contactForm.get('description').value : "None";
    this.emailModel.data.claimNumber = this.contactForm.get('claim').value ? this.contactForm.get('claim').value : "None";

    this.processing = true; //Prevents button from being clicked multiple times
    //If the user selects an issue that deals with their claim (topicKey1), send an email
    //to either the adjustor, or the flex/cargo mailbox as described by "email" above
    if(topicKey === 1){
      this.utilityService.sendContactEmail(this.emailModel).subscribe(
        data => { this.processing = false; this.page = 2; }
      );
    //Otherwise, we set the email field to 'none', and the backend will have logic in place
    //to send it to "capitalus@ups.com"
    } else if(topicKey >= 2 && topicKey <= 5){
      this.emailModel.data.email = 'none';
      this.utilityService.sendContactEmail(this.emailModel).subscribe(
        data => { this.processing = false; this.page = 2; }
      );
    }
  }

  //Close dialog
  public close() {
    this.dialogRef.close();
  }

  tagging() {
    this.taggingService.link({ link_name: 'contact_us_online_form_submit' });
  }
}
