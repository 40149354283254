import { Directive, HostListener, AfterContentInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[appDecimalFieldMask]',
})
export class CurrencyMaskingDirective implements AfterContentInit {
  constructor(public ngControl: NgControl, private number: DecimalPipe) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  ngAfterContentInit(): void {
    if(this.ngControl.value) {
      this.onInputChange(this.ngControl.value, false);
    }
  }


  onInputChange(event, backspace) {
    if(!event || (typeof event === 'object')) {
      return;
    }
    var plainNumber = event.replace(/[^-+0-9]/g, '');
    //use angular internal 'number' filter
    plainNumber = this.number.transform(plainNumber / 100, '1.2-2').replace(/,/g, '');
    this.ngControl.valueAccessor.writeValue(plainNumber);
  }
}
