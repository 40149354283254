import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { FooterModule } from '../footer/footer.module';
import { LoginHeaderModule } from '../login-header/login-header.module';


@NgModule({
  declarations: [
    LoginLayoutComponent
  ],
  imports: [CommonModule, SharedModule, FooterModule, LoginHeaderModule],
  providers: [],
  exports: [LoginLayoutComponent]
})
export class LoginLayoutModule { }
