<div class="dashboard-section">
  <div class="title">
    <h3 class="page-title d-flex align-items-center justify-content-between">{{policies.insured}} {{
      'dashboard.dashboardtitle' | translate }}
      <button *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser" class="upsc-button"
        routerLink="/claims/new">{{ 'claims.claimMenus.fileNewClaim' | translate }}</button>
    </h3>
  </div>

  <div class="blocks">
    <div class="one-block">
      <img src="../../../assets/images/policies_pic.png">
      <div class="text">{{ 'dashboard.Policies.textPolicies' | translate }}</div>
      <button class="upsc-white-button" routerLink="/policies">
        {{ 'dashboard.Policies.textPoliciesLink' | translate }} <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
    <div class="one-block">
      <img src="../../../assets/images/ups-insurance-shield.png">
      <div class="text">{{ 'dashboard.Claims.textClaims' | translate }}</div>
      <button class="upsc-white-button" routerLink="/claims/new" *ngIf="userPermission.isFileClaim">
        {{ 'dashboard.Claims.textClaims1' | translate }} <mat-icon>chevron_right</mat-icon>
      </button>
      <div *ngIf="!userPermission.isFileClaim" ngbTooltip="{{ 'dashboard.disableText.title' | translate }}"
        tooltipClass="ngb-tooltip-class" placement="top">
        <button class="upsc-white-button" *ngIf="!userPermission.isFileClaim" routerLink="/claims/new"
          [disabled]="!userPermission.isFileClaim">
          {{ 'dashboard.Claims.textClaims1' | translate }} <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="one-block" *ngIf="showBilling && !isHPGuestUser">
      <img src="../../../assets/images/ups-pay-bill.png">
      <div class="text">{{ 'dashboard.billing.subtitle' | translate }}</div>
      <button class="upsc-white-button" routerLink="/billing" *ngIf="userPermission.isBillingView">
        {{ 'dashboard.billing.button' | translate }} <mat-icon>chevron_right</mat-icon>
      </button>
      <div *ngIf="!userPermission.isBillingView" ngbTooltip="{{ 'dashboard.disableText.title' | translate }}"
        tooltipClass="ngb-tooltip-class" placement="top">
        <button class="upsc-white-button" *ngIf="!userPermission.isBillingView" routerLink="/billing"
          [disabled]="!userPermission.isBillingView">
          {{ 'dashboard.billing.button' | translate }} <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="one-block" *ngIf="showInsights && !isHPGuestUser">
      <img src="../../../assets/images/ups-electronic-data-interchange.png">
      <div class="text">{{ 'dashboard.analytics.title' | translate }}</div>
      <button class="upsc-white-button" routerLink="/visibility" *ngIf="userPermission.isAnalytics">
        {{ 'dashboard.analytics.button' | translate }} <mat-icon>chevron_right</mat-icon>
      </button>
      <div *ngIf="!userPermission.isAnalytics" ngbTooltip="{{ 'dashboard.disableText.title' | translate }}"
        tooltipClass="ngb-tooltip-class" placement="top">
        <button class="upsc-white-button" *ngIf="!userPermission.isAnalytics" routerLink="/visibility"
          [disabled]="!userPermission.isAnalytics">
          {{ 'dashboard.analytics.button' | translate }} <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="claims-section" *ngIf="userPermission.isClaimView || userPermission.isFileClaim">
    <mat-divider class="divider"></mat-divider>
    <div class="header d-flex justify-content-between align-items-center">
      <div class="left">
        <div class="title">{{ 'dashboard.ClaimsActivity.title' | translate }}</div>
        <div class="subtitle">
          {{ 'dashboard.ClaimsActivity.PolicyType' | translate }}
          <span *ngIf="!isGermanyUser && !isInsureShield">{{policyType}}</span>
          <span *ngIf="!isGermanyUser && isInsureShield">{{policyType}}<sup>®</sup></span>
          {{'dashboard.ClaimsActivity.PolicyNo' | translate }}{{PolicyNo}}
        </div>
      </div>
      <!--  <div class="right">
        <div class="button">
          <a [routerLink]="['/claims']" (click)="tagging('viewAllClaims')">{{ 'dashboard.ClaimsActivity.SeeAllClaims' | translate }}</a>
        </div>
      </div> -->
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="margin-class">
      <upsc-claim-list [isPagination]="false" [isDraft]="false" [isLayout]="false"
        (showDocNotification)="toggleDocumentNotification($event)"></upsc-claim-list>
    </div>
  </div>

  <div class="btnCenter">
    <!--  <button class="upsc-button" *ngIf="userPermission.isClaimView || userPermission.isFileClaim"
      [routerLink]="['/claims']" (click)="tagging('viewAllClaims')">
      <span class="btn-text">{{ 'dashboard.ClaimsActivity.SeeAllClaims' | translate
      }}</span><mat-icon>chevron_right</mat-icon>
    </button> -->

    <button class="upsc-button" *ngIf="userPermission.isClaimView || userPermission.isFileClaim"
      [routerLink]="['/claims']" (click)="tagging('viewAllClaims')">
      <div class="btn-text-icon">
        <div class="text">{{ 'dashboard.ClaimsActivity.SeeAllClaims' | translate
          }}</div><mat-icon>chevron_right</mat-icon>
      </div>
    </button>
  </div>

  <div *ngIf="showBilling && !isHPGuestUser && userPermission.isBillingView" class="claims-section pb-5">
    <mat-divider class="divider"></mat-divider>
    <div class="header d-flex justify-content-between align-items-center">
      <div class="left">
        <div class="title">{{ 'dashboard.invoice.title' | translate }}</div>
        <div class="subtitle" *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
          {{'dashboard.invoice.insurance' | translate }}{{policyType}}
          {{'dashboard.ClaimsActivity.PolicyNo' | translate }}{{PolicyNo}}</div>
      </div>
      <!--   <div class="right">
        <div class="button">
          <a [routerLink]="['/billing']" (click)="tagging('viewAllInvoices')">{{ 'dashboard.invoice.button' | translate }}</a>
        </div>
      </div> -->
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="margin-class">
      <upsc-accordion-and-table [dashboardRecentInvoices]="true"></upsc-accordion-and-table>
    </div>
  </div>

  <div class="btnCenter">
    <!--  <button class="upsc-button" *ngIf="showBilling && !isHPGuestUser && userPermission.isBillingView"
      [routerLink]="['/billing']" (click)="tagging('viewAllInvoices')">
      <span class="btn-text">
      {{ 'dashboard.invoice.button' | translate
      }}</span><mat-icon>chevron_right</mat-icon>
    </button>
 -->
    <button class="upsc-button" *ngIf="showBilling && !isHPGuestUser && userPermission.isBillingView"
      [routerLink]="['/billing']" (click)="tagging('viewAllInvoices')">
      <div class="btn-text-icon">
        <div class="text"> {{ 'dashboard.invoice.button' | translate
          }}</div><mat-icon>chevron_right</mat-icon>
      </div>
    </button>
  </div>
</div>