import { Component, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})


export class UserFilterComponent {
  @Output() notifySearch = new EventEmitter<any>();
  @Output() notifyReset = new EventEmitter<any>();
  @Output() simplePolicySelect = new EventEmitter<any>(); //Used when policy is being switched from parent view

  //search form
  searchFormGroup;
  isParentPolicy;
  showPolicyFilter;
  childPolicyList = [];

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private userService: UserService
  ) {
    this.searchFormGroup = this.formBuilder.group({
      search: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      searchType: [{ value: '', disabled: false }, Validators.required],
      policy: ['', Validators.required]
    });

    //If parent policy is trying to view shipper accounts list
    this.isParentPolicy = this.userService.isParentPolicy();
    if (this.isParentPolicy) {
      if (this.router.url == '/manage-users/shipper-accounts') {
        this.showPolicyFilter = true;
      } else {
        this.showPolicyFilter = false;
      }
      this.getChildPolicyList();
    }
  }

  notifyData = {
    searchType: '',
    searchValue: ''
  };

  valueErrors: boolean = false;
  searchByValue: any;
  isEnabled: boolean = false;
  public searchQuery: any = "";
  selected = ' ';
  srchText = '';

  addUser() {
    this.router.navigate(['/manage-users/add-user']);
  }

  resetSearch() {
    this.searchQuery = "";
    this.searchFormGroup.reset();
    this.getChildPolicyList();
    this.notifyReset.emit(true);
  }

  submitSearch() {
    if (!this.searchFormGroup.valid) {
      this.searchFormGroup.markAllAsTouched();
      this.valueErrors = true;
    } else {
      //Start searching
      this.valueErrors = false;
      this.notifyData.searchType = this.searchType.value;
      this.notifyData.searchValue = this.search.value;
      this.notifySearch.emit(this.notifyData);
    }
  }

  ngAfterViewInit() {
    this.searchType.valueChanges.subscribe(
      val => {
        this.searchQuery = "";
        this.search.reset();
        this.searchByValue = val;

        this.valueErrors = false;
        this.search.markAsUntouched({ onlySelf: true });
        if (val && val.length > 0) {
          this.search.enable();

          this.isEnabled = true;
          if (val == 'email' && this.searchQuery == "") {
            this.valueErrors = true;
          }
          else if (val == 'name' && this.searchQuery == "") {
            this.valueErrors = true;
          }
        }
        else {
          this.search.disable();
          this.isEnabled = false;
        }
        this.ref.detectChanges();
      }
    );

    this.policy.valueChanges.subscribe(
      val => {
        this.simplePolicySelect.emit(val);  //Session storage variable is used for keeping track of what policy to actually add the shipper on, when adding from a parent policy view
      }
    );
  }

  //This method is only really used on page-load, and if search gets reset. Returns to default state of childPolicyList[0]
  getChildPolicyList(selectedPolicy?) {
    //Parent policies should retrieve child policies at login. Can be cases where the API failed though.
    let childPolicies = JSON.parse(sessionStorage.getItem('childPolicies'));
    if (childPolicies) {
      this.childPolicyList = childPolicies;
      if (this.childPolicyList.length > 0) { 
        this.policy.setValue(this.childPolicyList[0]);
      }
    } else {
      //Call the child policy api?
    }
  }

  get search() { return this.searchFormGroup.controls.search }
  get searchType() { return this.searchFormGroup.controls.searchType }
  get policy() { return this.searchFormGroup.controls.policy; }
}
