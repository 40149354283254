import { Component } from '@angular/core';
import { SwitchPolicyEventService } from 'src/app/services/switch-policy-event.service';
import { SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'upsc-switch-policy-notify',
  templateUrl: './switch-policy-notify.component.html',
  styleUrls: ['./switch-policy-notify.component.scss']
})
export class SwitchPolicyNotifyComponent {

  isTogglePolicy: boolean = false;
  currentPolicy: string = '';

  constructor(public switchPolicyService: SwitchPolicyEventService) {

  }

  public ngOnInit() {
    this.switchPolicyService.telecast.subscribe(message => {
      if (message.length > 1) {
        this.isTogglePolicy = true;
        this.currentPolicy = message;
        this.hideBanner();
      }
    });
  }

  closeBanner() {
    this.isTogglePolicy = false;
    this.switchPolicyService.resetPolicySwitch();
  }

  hideBanner() {
    setTimeout(() => {
        this. closeBanner();
    }, 10000);
  }
}
