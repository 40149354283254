import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../../shared/components/layout/layout.component';
import { InternalUserComponent } from './internal-user.component';
// import { UserResolver } from '../../resolvers/user.resolver';


@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: LayoutComponent,
      children: [
      {
        path: '',
        component: InternalUserComponent
      }
      ]/* ,
      resolve: [UserResolver] */
    }])],
  exports: [RouterModule]
})
export class InternalUserRoutingModule { }
