<div class="navigation-wrap">
    <a href="https://upscapital.com/" *ngIf="!isCanadaUser" target="_blank" rel="noopener noreferrer">
        <img class="logo" src="../../../../assets/images/logos/ca_us/InsureShield_Logo_226x79_larger_tagline-01.png"
            alt="{{ 'billing.method.altUps' | translate }}">
    </a>
    <a href="https://upscapital.com/ca-en/" *ngIf="isCanadaUser" target="_blank" rel="noopener noreferrer">
        <img class="logo"
            src="../../../../assets/images/logos/ca_us/UPSC_InsureShield_Horizontal CANADA_226x79_72dpi.png"
            alt="{{ 'billing.method.altUps' | translate }}">
    </a>

    <div class="nav-welcome-desktop">
        <span>
            <img class="logo" routerLink="/login" src="../../../../assets/images/login-avatar.svg" alt="Login">
        </span>
    </div>
    <div class="nav-welcome-mobile">
        <span>
            <img class="logo" routerLink="/login" src="../../../../assets/images/login-avatar.svg" alt="Login">
        </span>
    </div>
</div>