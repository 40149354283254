<div class="clickwrap" id="agreement" *ngIf="showBannerOnVisibilityPageOnly && !pageLoading">
  <!-- User has not signed up yet, display sign up page -->
  <div class="banner" *ngIf="signupPage">
    <div class="d-flex flex-column">
      <div class="title pb-3">Sign Up Here for Free!</div>
      <div class="d-flex">
        <div><input type="checkbox" class="upsc-green-background-checkbox size-22" (change)="updateCheckbox($event.target.checked)"></div>
        <div class="ml-2">I have read and agree to the <a class="upsc-link" href="../../../../assets/docs/UPS Capital - DeliveryDefense Visibility - Terms and Conditions.pdf" target="_blank">DeliveryDefense<sup>™</sup> Visibility Terms & Conditions</a>, including the limitations imposed with respect to my use of data provided by UPS Capital pursuant to this Agreement, particularly those set out in Section [5,3]</div>
      </div>
      <div class="subtext">*After agreeing to the Terms and Conditions, it will take up to <span class="bold">24 hours</span> for your tracking data to process. We appreciate your patience. Please contact <a href="mailto:DeliveryDefenseCustomerService@ups.com">{{'deliveryDefense.errorPopup.text2' | translate}}</a> if you are unable to access the tool after 24 hours.</div>
      <div class="buttons">
        <button class="upsc-white-button" (click)="goToDashboard()"><mat-icon>keyboard_arrow_left</mat-icon>Go Back</button>
        <button class="upsc-button" (click)="signUp()" [disabled]="!termsAndConditionsIsChecked" *ngIf="!loading">Continue<mat-icon>keyboard_arrow_right</mat-icon></button>
        <button class="next-step-isop loading d-flex align-items-center justify-content-between mr-2" *ngIf="loading">
          <div>{{ 'claims.edit.continue' | translate }}</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
      </div>
      <div class="error" *ngIf="systemError">
        {{'deliveryDefense.errorPopup.text1' | translate}}
        <a href="mailto:DeliveryDefenseCustomerService@ups.com">
          {{'deliveryDefense.errorPopup.text2' | translate}}
        </a>
        {{'deliveryDefense.errorPopup.text3' | translate}}
      </div>
    </div>
  </div>
  <!-- User just agreed to T&Cs and signed up, show them the wait 24 hours page -->
  <div class="banner" *ngIf="wait24HoursPage">
    <div class="notification-box">
      <upsc-new-notification-box [isSuccess2]="true" class="width100">
        <div class="box-format">
          <img src="../../../../assets/images/person_celebrate.png">
          <div class="text">Welcome to DeliveryDefense<sup>™</sup> Visibility!</div>
        </div>
      </upsc-new-notification-box>
    </div>
    <div class="mb-3">Your customized dashboards are being built. Please allow up to <span class="bold">24 hours</span> for your shipping data to be fully available. We appreciate your patience. Please contact <a href="mailto:DeliveryDefenseCustomerService@ups.com">{{'deliveryDefense.errorPopup.text2' | translate}}</a> if you are unable to access the tool after 24 hours.</div>
    <button class="upsc-button" (click)="goToDashboard()">Back to Dashboard<mat-icon>keyboard_arrow_right</mat-icon></button>
  </div>
  <!-- User has already signed up before this session, but their URL is not ready yet, show wait page -->
  <div class="banner" *ngIf="waitPage">
    <div class="notification-box">
      <upsc-new-notification-box [isSuccess2]="true" class="width100">
        <div class="box-format">
          <img src="../../../../assets/images/person_celebrate.png">
          <div class="text">Welcome to DeliveryDefense<sup>™</sup> Visibility!</div>
        </div>
      </upsc-new-notification-box>
    </div>
    <div class="mb-3">Your customized dashboards are still being built. We appreciate your patience. Please contact <a href="mailto:DeliveryDefenseCustomerService@ups.com">{{'deliveryDefense.errorPopup.text2' | translate}}</a> if you are unable to access the tool after 24 hours.</div>
    <button class="upsc-button" (click)="goToDashboard()">Back to Dashboard<mat-icon>keyboard_arrow_right</mat-icon></button>
  </div>
  <!-- Error page (we couldn't tell if they signed up or not due to API failure) -->
  <div class="banner" *ngIf="errorPage">
    <div class="notification-box">
      <upsc-new-notification-box [isDanger]="true" class="width100">
        <div class="box-format">
          <div class="text2">Something went wrong loading your content! Please try again later.</div>
        </div>
      </upsc-new-notification-box>
    </div>
    <div class="mb-3">If you've already signed up for DeliveryDefense<sup>™</sup> Visibility and this issue persists, please email 
      <a href="mailto:DeliveryDefenseCustomerService@ups.com">
        {{'deliveryDefense.errorPopup.text2' | translate}}
      </a>
      {{'deliveryDefense.errorPopup.text3' | translate}}
    </div>
    <button class="upsc-button" (click)="goToDashboard()">Back to Dashboard<mat-icon>keyboard_arrow_right</mat-icon></button>
  </div>
</div>
<div class="extraspace" id="filler" *ngIf="showBannerOnVisibilityPageOnly && !pageLoading"></div>