<div class="ccp-landing-wrapper">
  <div *ngIf="currentStep == 0">
    <ng-container *ngTemplateOutlet="welcomeTemplate"></ng-container>
  </div>
  <div *ngIf="currentStep == 1">
    <ng-container *ngTemplateOutlet="verificationTemplate"></ng-container>
  </div>
</div>

<ng-template #welcomeTemplate>
  <form [formGroup]="customerForm">
    <div class="landing-top-block">
      <div class="custom-format" *ngIf="flowType !== 'pirateship'">
        <div class="landing-header">How to File a Claim</div>
        <hr *ngIf="!isMobile">
        <div class="divider-bar custom" *ngIf="isMobile"></div>
        <div class="landing-text">Gather your claim details and let's get started. Please make sure to complete the
          following four steps to file your claim:</div>
        <div>
          <img class="custom-width" src="../../../assets/images/ccp-blue-static-stepper.PNG" *ngIf="!isMobile">
        </div>
      </div>
      <img *ngIf="flowType === 'pirateship'" src="../../../../assets/images/Insurehsield-Logo.png">
    </div>
    <div class="mobile-image-format" *ngIf="flowType !== 'pirateship'">
      <img class="custom-width2" src="../../../assets/images/ccp-blue-static-stepper-vertical.PNG" *ngIf="isMobile">
    </div>
    <div class="landing-bottom-block">
      <div class="form-box">
        <div class="title" *ngIf="flowType !== 'pirateship'">Start a Claim</div>
        <div class="divider-bar" *ngIf="flowType !== 'pirateship'"></div>
        <div class="login-form d-flex pt-3" [ngClass]="{'greyBackground': !directUrl && flowType === 'pirateship'}">
          <ng-container *ngIf="!isLoading; else loadingSpinner">
            <div class="form-inputs" [ngClass]="{'pirateInput': flowType === 'pirateship' && !directUrl}">
              <mat-form-field class="width-400" [ngClass]="{'pirateship-background': flowType === 'pirateship'}">
                <mat-label *ngIf="flowType !== 'pirateship'">{{'myChoice.howToFileAClaim.tracking' | translate
                  }}</mat-label>
                <mat-label *ngIf="flowType === 'pirateship' && !directUrl"
                  [ngClass]="{'headerText': !directUrl && flowType === 'pirateship'}">{{'myChoice.howToFileAClaim.pirateTracking'
                  |
                  translate}}</mat-label>
                <mat-label *ngIf="flowType === 'pirateship' && directUrl">{{'myChoice.howToFileAClaim.pirateTracking1' |
                  translate}}</mat-label>
                <input matInput formControlName="trackingNumber" [readonly]="flowType === 'pirateship' && !directUrl"
                  [disabled]="flowType === 'pirateship' && !directUrl">
                <mat-error *ngIf="customerForm.controls['trackingNumber'].errors">
                  <div *ngIf="customerForm.get('trackingNumber').errors?.required">
                    Tracking Number is required.
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-inputs" [ngClass]="{'pirateInput': flowType === 'pirateship' && !directUrl}">
              <mat-form-field class="width-400">
                <mat-label [ngClass]="{'headerText': !directUrl && flowType === 'pirateship'}"> {{ flowType ===
                  'pirateship' && !directUrl ? 'Email Address (Pre-filled from Pirate Ship)' : 'Email Address'
                  }}</mat-label>
                <input matInput formControlName="email" [readonly]="flowType === 'pirateship' && !directUrl"
                  [disabled]="flowType === 'pirateship' && !directUrl"
                  [ngClass]="{'pirateInput': !directUrl && flowType === 'pirateship'}">
                <mat-error *ngIf="customerForm.controls['email'].errors">
                  <div *ngIf="customerForm.get('email').errors?.required">
                    Email Address is required.
                  </div>
                  <div *ngIf="customerForm.get('email').errors?.email">
                    Invalid Email Address.
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <br>
            <div><re-captcha formControlName="recaptchaControl"></re-captcha></div>
          </ng-container>
          <ng-template #loadingSpinner>
            <div class="spinner d-flex justify-content-center">
              <div class="spinner-border">
              </div>
            </div>
          </ng-template>
          <div class="mt-4 terms-width" *ngIf="flowType !== 'pirateship'">
            <div class="d-flex flex-row checklist-format">
              <mat-checkbox class="blue-background-checkbox size-20" formControlName="terms"></mat-checkbox>
              <div class="ml-2 text">
                By clicking "Get Started" below, you acknowledge that you have read and agree to the
                <a href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank"
                  rel="noopener noreferrer" class="upsc-link">UPS Capital Technology Agreement,</a>
                and you acknowledge the
                <a href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank"
                  rel="noopener noreferrer" class="upsc-link">UPS Capital Privacy Notice.</a>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button class="upsc-primary-button icon-right" [disabled]="customerForm.invalid" (click)="getStarted()" *ngIf="!loading"
              [ngClass]="{'pirateShipButton' : flowType === 'pirateship' && !customerForm.invalid}">
              Get Started
              <mat-icon class="mobile-format">keyboard_arrow_right</mat-icon>
            </button>
            <button class="upsc-primary-button icon-right" disabled *ngIf="loading"
            [ngClass]="{'pirateShipButton' : flowType === 'pirateship' && !loading}">
              Get Started<span class="spinner-border"></span>
            </button>
            <!-- <button class="next-step loading d-flex align-items-center justify-content-between" *ngIf="loading"
              [ngClass]="{'pirateShipButton' : flowType === 'pirateship' && !loading}">
              <div>Get Started</div>
              <div class="spinner-border" role="status">
                <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
              </div>
            </button> -->
          </div>
          <div class="error mt-2" *ngIf="systemError">
            {{'common.systemError' | translate }}
          </div>
          <div class="error mt-2" *ngIf="duplicateTrackingNumber">
            A claim has already been filed using this tracking number.
          </div>
          <div class="error mt-2" *ngIf="policyMatchError">
            This shipment is not covered under {{channelPartnerDisplayName}} policies.
          </div>
          <div class="error mt-2" *ngIf="shipmentInformationError">
            Shipment information was incomplete. We are unable to process a claim on this tracking number.
          </div>
          <div class="error mt-2" *ngIf="isInsuredValueZero && flowType !== 'pirateship'">
            Oops! Shipment is not covered under your shipping protection solution, please file claim directly with
            Carrier.
          </div>
          <div class="error mt-2" *ngIf="liabilityError && flowType !== 'pirateship'">
            Oops! Shipment is not covered under your shipping protection solution, please file claim directly with
            Carrier.
          </div>
          <div class="error mt-2" *ngIf="liabilityError && flowType === 'pirateship'">
            Oops! There was an unexpected error filing your claim. Please contact insureshieldsupport@ups.com with the
            relevant tracking number to troubleshoot your issue or claim.
          </div>
          <div class="error mt-2" *ngIf="upsHealthCareDeclaredAmountError">
            Oops! This shipment is not covered under your shipping protection solution, please file claim directly
            with
            UPS at <a href="https://www.ups.com" target="_blank" class="upsc-link">www.ups.com</a>
          </div>
          <div class="error mt-2" *ngIf="trackingNumberNotFound && flowType !== 'pirateship'">
            Oops! Shipment is not covered under your shipping protection solution, please file claim directly with
            Carrier.
          </div>
          <div class="error mt-2" *ngIf="trackingNumberNotFound && flowType === 'pirateship'">
            Oops! There was an unexpected error filing your claim. Please contact insureshieldsupport@ups.com with the
            relevant tracking number to troubleshoot your issue or claim.
          </div>
          <div class="error mt-2" *ngIf="shipDateError && flowType === 'pirateship'">
            Oops! This package was shipped prior to the Policy Coverage Date. Please contact support@pirateship.com to proceed with your claim.
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #verificationTemplate>
  <form [formGroup]="confirmFormGroup">
    <div class="verification-wrapper">
      <div class="d-flex justify-content-center align-items-center flex-column">
        <div class="header" *ngIf="flowType !== 'pirateship'">Enter the unique code sent to your email.</div>
        <div class="header" *ngIf="flowType === 'pirateship'">Enter Email Verification Code</div>
        <div class="description">Please enter the one-time verification code that was emailed to you to confirm your
          request. If you can't find the email, check your spam folder for a message from UPS Capital Customer Support.
        </div>
      </div>
      <div class="verification-code-block">
        <div class="container-wrapper">
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" autofocus class="center-text" #name1 (keyup)="focusThis(2, $event)"
                (input)="pasted()" formControlName="firstDigit" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name2 (keyup)="focusThis(3, $event)"
                formControlName="secondDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name3 (keyup)="focusThis(4, $event)"
                formControlName="thirdDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name4 (keyup)="focusThis(5, $event)"
                formControlName="fourthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name5 (keyup)="focusThis(6, $event)"
                formControlName="fifthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name6 (keyup)="focusThis(7, $event)"
                formControlName="sixthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
        </div>
        <div class="error2" *ngIf="verifyCodeError">
          Verification failed. Please try again.
        </div>
        <div class="verification-buttons" *ngIf="flowType !== 'pirateship'">
          <button type="button" class="upsc-white-button mr-3"
            (click)="cancel()"><mat-icon>keyboard_arrow_left</mat-icon>Cancel</button>
          <button type="button" class="upsc-button" *ngIf="!loading2" (click)="checkVerificationCode()"
            [disabled]="confirmFormGroup.invalid">Next<mat-icon>keyboard_arrow_right</mat-icon></button>
          <button type="button" class="next-step loading d-flex align-items-center justify-content-between"
            *ngIf="loading2">
            <div>Next</div>
            <div class="spinner-border" role="status">
              <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
            </div>
          </button>
        </div>

        <div class="verification-buttons" *ngIf="flowType === 'pirateship'">
          <button type="button" class="upsc-white-button mr-3"
            (click)="cancel()"><mat-icon>keyboard_arrow_left</mat-icon>Cancel</button>
          <button type="button" class="upsc-button" *ngIf="!loading2" (click)="checkVerificationCode()"
            [disabled]="confirmFormGroup.invalid"
            style="background-color: #00857D; color: #FFFFFF;">Next<mat-icon>keyboard_arrow_right</mat-icon></button>
          <button type="button" class="next-step loading d-flex align-items-center justify-content-between"
            *ngIf="loading2">
            <div>Next</div>
            <div class="spinner-border" role="status">
              <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>