export const SiteConfig = {
  storageKey: {
    accessToken: 'access_token',
  },
  terms: {
    ca: {
      link: '',
      TermID: '1',
    },
    gb: {
      link: '',
      TermID: '1',
    },
    de: {
      link: '',
      TermID: '1',
    },
    fr: {
      link: '',
      TermID: '1',
    },
    it: {
      link: '',
      TermID: '1',
    }
  }
};
