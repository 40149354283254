<div class="wrapper">
    <div class="heading d-flex justify-content-end">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="body">
        <div class="title">
           {{ errorData.title }}
        </div>
        <hr class="green-hr">
        <div class="content">
            {{ errorData.content }}
        </div>
    </div>    
</div>