<div class="popup-global-layout width-650 p-4 p-md-5">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">{{ 'claims.edit.draft.title' | translate }}</div>
  <div class="global-green-bar"></div>
  <div class="error mb-3" *ngIf="systemError">{{ 'common.systemError' | translate }}</div>
  <div class="global-text">{{ 'claims.edit.draft.descr' | translate }}</div>
  <div class="global-button-format">
      <button type="button" class="upsc-white-button2" (click)="close()"><mat-icon class="icon-key">keyboard_arrow_left</mat-icon>Cancel</button>
      <button class="upsc-green-popup-button width-224" (click)="saveAndExit()" *ngIf="!loading">{{ 'claims.edit.draft.save' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
      <button class="loading d-flex align-items-center justify-content-between" *ngIf="loading">
        <div>{{ 'claims.edit.draft.save' | translate }}</div>
        <div class="spinner-border" role="status">
          <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
        </div>
      </button>
  </div>
</div>