import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { Stepper4Component } from './stepper4.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  declarations: [Stepper4Component],
  providers: [],
  exports: [Stepper4Component]
})
export class Stepper4Module { }
