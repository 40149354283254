
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ICategory } from 'src/app/shared/models/claim.interface';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ClaimService } from 'src/app/services/claim.service';
import { States, limitedCarriers, countries, productCategory, carriers } from 'src/config/metadata-config';
import { TupssService } from '../../../services/tupss.service';
import { IClaim, emptyClaim, IClaimsDetails } from 'src/app/shared/models/claim.interface';
import { LoginService } from 'src/app/services/login.service';
import { ActivatedRoute } from '@angular/router';
import { IShippingDetailsAutofill } from 'src/app/shared/models/shippingDetails.interface';
import { ReopenClaim } from 'src/app/shared/models/reopen-claim.interface';
import { BreakpointObserver } from '@angular/cdk/layout';
import localMetaData from 'src/assets/full-metadata.json';

@Component({
  selector: 'upsc-damage-missing-parts',
  templateUrl: './damage-missing-parts.component.html',
  styleUrls: ['./damage-missing-parts.component.scss']
})
export class DamageMissingPartsComponent implements OnInit {

  claimNumber = '';
  @Output() isClaimSubmit = new EventEmitter<string>();

  claimDetails: IClaimsDetails = {};
  //claimResubmit: IClaim = emptyClaim;

  claim: IClaim = emptyClaim;
  public reOpenClaim: ReopenClaim = {};

  whatHappenedFormGroup: UntypedFormGroup;

  isGuestUser: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;

  public maxDate = new Date();
  public categoryList = [];

  public countryList = [];
  public stateList: any[] = States; //We never pull list of states from metadata API, only comes from config file
  public currencyList = {};
  public carrierList: [] | {} = [];

  public repairables: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.reason.yes') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.reason.no') },
  ];
  public damagedLocations: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.one') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.two') },
    { id: 2, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.three') }
  ];

  public listCurrency: any[] = [
    { key: 'usd', value: 'USD' }
  ];


  //Persisted Data used throughout claims process
  persistIShipData;
  public persistClaimNumber: string = "tempClaimNumber";
  public persistPublicId: string = "";
  public persistExpirationDate: Date; //These dates handle what dates a user is allowed to select from based on their policy number
  public persistEffectiveDate: Date;
  persistPayeeInfo;
  mailingInfo = {
    "insured": "",
    "streetAddress1": "",
    "streetAddress2": "",
    "city": "",
    "state": "",
    "zip": "",
    "country": ""
  }
  //Default values (Remove this? [(value)] not supported in Angular 7+ ?)
  defaultCurrency = 'USD';

  //Notification booleans
  errorNotification: boolean = false;
  hideAdditionalCoverageWarning: boolean = false;
  hideDeclaredWarning = true;
  hideNoDeclaredWarning = true;
  showACHError = false;
  isPageLoad: boolean = false;

  tupssType: string; //will be tfcp or tccp

  constructor(private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private claimService: ClaimService,
    private tupssService: TupssService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver) {


    this.route.queryParams.subscribe(params => {
      this.claimNumber = params.claimNumber;
    });


    this.userDetails = this.userService.getUserInfo();

    this.isGuestUser = this.userService.isGuestUser();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();

    this.maxDate.setDate(this.maxDate.getDate());

    // this.getCurrency();

    this.isPageLoad = true;

    if (this.tupssService.userDetails && this.tupssService.userDetails.type) {
      this.tupssType = this.tupssService.userDetails.type;
    } else {
      this.tupssType = 'tccp'; //temp hardcode
    }

  }

  currencyType: string;
  sgDocuments = []; //System-generated documents
  uuDocuments = []; //User-uploaded documents
  statusDetails = '';
  claimPaymentRecipientShow: boolean = true;
  shippingDetailsDto: any = {};
  mainContact: any = {};

  ngOnInit(): void {
    this.getMetaData();
    this.getClaimDetails();
    this.whatHappenedFormGroup = this.formBuilder.group({
      reason: ['', Validators.compose([Validators.required])],
      category: [{ value: this.claim.productCategory_Ext, disabled: true }, Validators.compose([Validators.required])],
      quantity: [{ value: this.claim.quantity, disabled: true }, Validators.compose([Validators.required, Validators.pattern(/^[1-9]\d*$/)])],
      description: [this.claim.shippingDetailsDTO.merchandiseDescription, Validators.compose([Validators.required, Validators.maxLength(250)])],
      currency: [{ value: 'USD', disabled: true }, Validators.compose([Validators.required])],
      merchandise: [this.claim.shippingDetailsDTO.merchandiseValue, Validators.compose([Validators.required])],
      shipping: [{ value: this.claimService.formatAmount(this.claim.shippingDetailsDTO.verifiedShippingCharge, false), disabled: true }, Validators.compose([Validators.required])],
      amount: [{ value: this.claimService.formatAmount(this.claim.claimAmount_Ext, false), disabled: true }, Validators.compose([Validators.required])],
      // lossCare: ['',],
      // lossReship: ['',],
      // lossRefund: ['',],
      damageDescription: ['', Validators.maxLength(800)],
      damageDate: [''],
      damageRepairable: [''],
      damageLocations: [''],
      damageOther: ['', Validators.maxLength(35)],
      missingDescription: ['', Validators.maxLength(800)],
      missingDate: [''],
      missingLocations: [''],
      missingOther: ['', Validators.maxLength(35)],
      // lateCare: ['',],
      // lateReship: ['',],
      // lateRefund: ['',],
      documents: ['',],
      additionalComments: [this.claim.customerNotes, Validators.maxLength(250)],
      // purpose: [this.claim.purposeOfShipment, Validators.compose([Validators.required])],
      // purposeOther: [''],
      // originalTrackingNumber: ['']
    });

    if (this.claim.lossCause == 'damage_Ext') {
      this.damage = true;
      this.whatHappenedFormGroup.controls.reason.setValue(1);
      this.whatHappenedFormGroup.controls.damageDate.setValue('this.claim.lossDate');
    }
    else if (this.claim.lossCause == 'fallout_Ext') {
      this.missing = true;
      this.whatHappenedFormGroup.controls.reason.setValue(2);
      this.whatHappenedFormGroup.controls.missingDate.setValue('this.claim.lossDate');
    }

    //Customs validations based on valuechanges
    /* this.whatHappenedFormGroup.controls['category'].valueChanges.subscribe(val => {
      this.whatHappenedFormGroup.controls['category'].setErrors({ 'invalid': true });
      if (typeof val === 'object') { //selected from the list
        this.whatHappenedFormGroup.controls['category'].setErrors(null);
      }
      else { //typed in
        for (let cat of this.categoryList) {
          if (cat.value.toLowerCase() === val.toLowerCase()) {
            if (cat.value !== val) {
              this.whatHappenedFormGroup.controls['category'].setValue(cat);
            }
            this.whatHappenedFormGroup.controls['category'].setErrors(null);
            break;
          }
        }
      }
    }); */
    this.monitorValueChanged();
    //When user starts typing, filteredCategories will populate.
    /* this.filteredCategories = this.whatHappenedFormGroup.get('category').valueChanges
      .pipe(
        startWith<string | ICategory>(''),
        map(value => typeof value === 'string' ? value : value.key),
        map(name => name ? this.filter(name) : this.categoryList.slice())
      );
    this.checkLists();*/
    this.persistIShipData = JSON.parse(sessionStorage.getItem('persistIShipData'));
  }

  getClaimDetails() {
    this.claim = JSON.parse(sessionStorage.getItem('claimDetails'));
    this.shippingDetailsDto = this.claim.shippingDetailsDTO;

    // let data = JSON.parse(sessionStorage.getItem('claimDetails'));
    /*     var policyNumber = this.userService.getUserInfo().policyNumber;
    
        this.claimService.getClaimDetails(claimNumber, policyNumber).subscribe(
          data => {
            if (data) { */

    /* this.persistClaimNumber = data.claimNumber;
    this.persistPublicId = data.publicID;

    this.claimService.setTupssClaimData(data);
    this.claimDetails.claimNumber = this.claimNumber;
    this.claimDetails.tracking = data.shippingDetailsDTO.trackingNumber ? data.shippingDetailsDTO.trackingNumber : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.shipDate = data.shippingDetailsDTO.shipDate ? data.shippingDetailsDTO.shipDate : this.translateService.instant('claimsDetail.notProvided');

    if (data.lossCause == "loss_Ext") {
      this.claimDetails.reason = this.translateService.instant('claimsDetail.loss');
    } else if (data.lossCause == "damage_Ext") {
      this.claimDetails.reason = this.translateService.instant('claimsDetail.damage');
    } else if (data.lossCause == "fallout_Ext") {
      this.claimDetails.reason = this.translateService.instant('claimsDetail.missingContents');
    } else if (data.lossCause == "delay") {
      this.claimDetails.reason = this.translateService.instant('claimsDetail.lateDelivery');
    } else {
      this.claimDetails.reason = this.translateService.instant('claimsDetail.notProvided');
    }

    this.shippingDetailsDto = data.shippingDetailsDTO;
    this.mainContact = data.mainContact;

    this.claimDetails.checkNumber = data.checks.length == 0 ? this.translateService.instant('claimsDetail.notProvided') : data.checks[data.checks.length - 1].checkNumber;
    this.claimDetails.checkIssueDate = data.checks.length == 0 ? "null" : data.checks[data.checks.length - 1].issueDate;
    let sliceTrack = data.shippingDetailsDTO.trackingNumber.slice(0, 2);
    if (sliceTrack.toUpperCase() == '1Z') {
      this.claimDetails.upsAccountNumber = data.shippingDetailsDTO.trackingNumber.slice(2, 8).toUpperCase();
    }
    else { this.claimDetails.upsAccountNumber = this.translateService.instant('claimsDetail.notProvided'); }
    this.claimDetails.reference = data.shippingDetailsDTO.referenceNumber ? data.shippingDetailsDTO.referenceNumber : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.shipperCompany = data.shippingDetailsDTO.senderDetails?.companyName ? data.shippingDetailsDTO.senderDetails.companyName : this.translateService.instant('claimsDetail.notProvided'); this.claimDetails.shipperAddress1 = data.shippingDetailsDTO.senderDetails.address1 ? data.shippingDetailsDTO.senderDetails.address1 : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.shipperAddress1 = data.shippingDetailsDTO.senderDetails.address1 ? data.shippingDetailsDTO.senderDetails.address1 : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.shipperCity = data.shippingDetailsDTO.senderDetails.city ? `${data.shippingDetailsDTO.senderDetails.city}` : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.shipperState = data.shippingDetailsDTO.senderDetails.state ? ` ${data.shippingDetailsDTO.senderDetails.state}` : "";
    this.claimDetails.shipperCountry = data.shippingDetailsDTO.senderDetails.country ? data.shippingDetailsDTO.senderDetails.country : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.recipientCompany = data.shippingDetailsDTO.receiverDetails.companyName ? data.shippingDetailsDTO.receiverDetails.companyName : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.recipientCity = data.shippingDetailsDTO.receiverDetails.city ? `${data.shippingDetailsDTO.receiverDetails.city}` : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.recipientState = data.shippingDetailsDTO.receiverDetails.state ? ` ${data.shippingDetailsDTO.receiverDetails.state}` : "";
    this.claimDetails.recipientCountry = data.shippingDetailsDTO.receiverDetails.country ? data.shippingDetailsDTO.receiverDetails.country : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.fName = data.mainContact.firstName ? data.mainContact.firstName : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.lName = data.mainContact.lastName ? data.mainContact.lastName : '';
    this.claimDetails.email = data.mainContact.emailAddress1 ? data.mainContact.emailAddress1 : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.phoneNumber = data.mainContact.workNumber ? data.mainContact.workNumber : this.translateService.instant('claimsDetail.notProvided');
    this.claim.description = data.shippingDetailsDTO.merchandiseDescription ? data.shippingDetailsDTO.merchandiseDescription : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.fileDate = data.reportedDate ? data.reportedDate : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.adjusterName = data.adjuster.displayName ? data.adjuster.displayName : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.adjusterEmail = data.adjuster.email ? data.adjuster.email : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.adjusterPhone = data.adjuster.phoneNumber ? data.adjuster.phoneNumber : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.productCategory = data.productCategory_Ext ? data.productCategory_Ext : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.claimState = data.claimState ? data.claimState : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.claimSubStatus = data.claimSubStatus ? data.claimSubStatus : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.customerDisplayStatus = data.customerDisplayStatus == 'Closed' && data.closedOutCome == 'incomplete_documentation_Ext' ? this.translateService.instant('metadata.claimStatusMapping.incomplete_documentation_Ext') : data.customerDisplayStatus ? data.customerDisplayStatus : this.translateService.instant('claimsDetail.notProvided'); //If there is a CLOSED claim with INCOMPLETE_DOCUMENTATION_EXT, then we remap it to DOCUMENTS PENDING
    this.claimDetails.claimedAmount = data.claimAmount_Ext ? data.claimAmount_Ext.replace(' usd', '').replace(' cad', '') : 0.0;
    this.claimDetails.totalPaymentAmount = data.totalPayments.amount ? data.totalPayments.amount : 0;
    this.claimDetails.currency = data.totalPayments.currency ? data.totalPayments.currency : 'usd';
    this.claim.customerNotes = data.customerNotes ? data.customerNotes : this.translateService.instant('claimsDetail.notProvided');
    this.claimDetails.idrdocList = data.idrdocList ? data.idrdocList : null;
    this.claimDetails.closedOutCome = data.closedOutCome;
    this.claimDetails.payeeID = data.payeeID;
    this.claim.quantity = data.quantity;
    this.currencyType = data.claimAmount_Ext.split(' ')[1];
    this.claimDetails.documents = (data.documents && data.documents.length > 0) ? data.documents : this.translateService.instant('claimsDetail.notProvided');
    if (this.claimDetails.documents != 'Not Provided') {
      for (let i = 0; i < Object.keys(this.claimDetails.documents).length; i++) {
        if (this.claimDetails.documents[i].documentType == 'letter_received' || this.claimDetails.documents[i].documentType == 'onl_document_Ext') {
          this.uuDocuments.push(this.claimDetails.documents[i]);
        } else {
          this.sgDocuments.push(this.claimDetails.documents[i]);
        }
      }
    }
    if (this.claimDetails.customerDisplayStatus == 'Denied' || (this.claimDetails.customerDisplayStatus && this.claimDetails.customerDisplayStatus.includes('Closed'))) {
      this.statusDetails = this.translateService.instant(`metadata.claimStatusMapping.${data.closedOutCome}`); //undefined if no status details are found. only need to check for denied or closed claims.
    }
    if (this.claimDetails.customerDisplayStatus == 'Paid' || this.claimDetails.customerDisplayStatus == 'Submitted for Payment' || this.claimDetails.customerDisplayStatus == 'Denied') {
      this.claimPaymentRecipientShow = false;
    }
    // for (let i = 0; i < data.claimContacts.length; i++) {
    //   if (data.claimContacts[i].contactRoles[0] === "transportation_carrier_Ext") {
    //     this.claim.carrier = data.claimContacts[i].contactDTO.displayName;
    //   }
    // }
    for (let claimContactsData of data.claimContacts) {
      if (claimContactsData.contactRoles[0] === "transportation_carrier_Ext") {
        this.claimDetails.carrier = claimContactsData.contactDTO.displayName;
      }
      if (claimContactsData.contactRoles[0] === "receiver_Ext") {
        this.claimDetails.recipientZipcode = claimContactsData.contactDTO?.primaryAddress?.postalCode;
      }
      if (claimContactsData.contactRoles[0] === "shipper_Ext") {
        this.claimDetails.shipperZipcode = claimContactsData.contactDTO?.primaryAddress?.postalCode;
      }
      if (claimContactsData.contactRoles.includes("policy_payee_Ext") || claimContactsData.contactRoles.includes("checkpayee")) {
        this.claimDetails.payeeDetails = {
          fullName: claimContactsData.contactDTO.displayName,
          email: claimContactsData.contactDTO.emailAddress1,
          phone: claimContactsData.contactDTO.workNumber,
          addressLine1: claimContactsData.contactDTO.primaryAddress?.addressLine1,
          addressLine2: claimContactsData.contactDTO.primaryAddress?.addressLine2,
          city: claimContactsData.contactDTO.primaryAddress?.city,
          state: this.stateList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.state)?.value ? this.stateList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.state).value : claimContactsData.contactDTO.primaryAddress?.state,
          country: this.countryList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.country)?.value ? this.countryList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.country).value : claimContactsData.contactDTO.primaryAddress?.country,
          zip: claimContactsData.contactDTO.primaryAddress?.postalCode,
          stateKey: claimContactsData.contactDTO.primaryAddress?.state
        }
      }
    }
    if (this.claimDetails.payeeID) {
      let request;
      if (this.userService.isGuestUser()) {
        request = {
          "policyNumber": this.userService.getUserInfo().policyNumber,
          "emailAddress": sessionStorage.getItem('guestUserEmail') //guest email goes here
        }
      } else {
        request = {
          "policyNumber": this.userService.getUserInfo().policyNumber
        }
      }
      let req = {
        payeeID: this.claimDetails.payeeID,
        status: "success"
      }
      this.claimService.getPayeeDetails(req).subscribe(
        data => {
          this.claimDetails.payeeDetails = {
            fullName: data.data.payeeName,
            email: data.data.email,
            phone: data.data.phoneNumber,
            addressLine1: data.data.addressLine1,
            addressLine2: data.data.addressLine2,
            city: data.data.city,
            state: data.data.state,
            country: this.countryList.find(x => x.key == data.data.country).value ? this.countryList.find(x => x.key == data.data.country).value : data.data.country,
            zip: data.data.zip
          };
          if (data.data.paymentMethod == "ACH") {
            this.claimDetails.ach = "**" + data.data.accountNumber;
          } else {
            this.claimDetails.ach = this.translateService.instant('payee.table.check');
          }
        }, error => {
          this.claimDetails.ach = this.translateService.instant('payee.table.check');
        }
      );
    }
    if (!this.claimDetails.payeeID) {
      this.claimDetails.ach = this.translateService.instant('payee.table.check');
    }
    if (this.claimDetails.carrier) {
      //Do nothing, already good to go
    } else {
      this.claimDetails.carrier = this.translateService.instant('claimsDetail.notProvided');
    } */
    /*}
    this.checkQueryParamsForPrint();
/*       }, error => { }
); */
  }


  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  //Handles the "Other" text field in damage and missing flows
  public toggleOther(type: boolean) {
    this.other = false;
    if (type) {
      this.other = true;
    }
  }

  userDetails: any;

  //If Merchandise or Shipping inputs are updated, total amount must be too
  private monitorValueChanged() {
    this.whatHappenedFormGroup.get('merchandise').valueChanges.subscribe(
      (value) => {
        if (!value) {
          return;
        }
        if (isNaN(value)) {
          this.whatHappenedFormGroup.get('amount').setValue(0);
          return;
        }
        this.whatHappenedFormGroup.get('amount').setValue((+this.claimService.formatAmount(this.whatHappenedFormGroup.get('merchandise').value, false) + +this.claimService.formatAmount(this.whatHappenedFormGroup.get('shipping').value, false)).toFixed(2));
      }
    );

    this.whatHappenedFormGroup.get('shipping').valueChanges.subscribe(
      (value) => {
        if (!value) {
          return;
        }
        if (isNaN(value)) {
          this.whatHappenedFormGroup.get('amount').setValue(0);
          return;
        }
        this.whatHappenedFormGroup.get('amount').setValue((+this.claimService.formatAmount(this.whatHappenedFormGroup.get('shipping').value, false) + +this.claimService.formatAmount(this.whatHappenedFormGroup.get('merchandise').value, false)).toFixed(2));
      }
    );
  }

  //Handles notification appearing near the merchandise and shipping boxes
  checkDV() {

    let merchandise: Number = 0;
    let declaredValue: Number = 0;
    try {
      // merchandise = parseFloat(this.claimService.formatAmount(this.claim.shippingDetailsDTO.merchandiseValue, false));
      merchandise = parseFloat(this.claimService.formatAmount(this.whatHappenedFormGroup.get('merchandise').value, false));
      declaredValue = parseFloat(this.claimService.formatAmount(this.claim.shippingDetailsDTO.declaredValueMonetaryValue, false));
    }
    catch {
      declaredValue = 0;
    }

    if (!declaredValue || declaredValue == 0) {
      this.hideNoDeclaredWarning = false;
    } else {
      this.hideNoDeclaredWarning = true;
    }
    if (merchandise > declaredValue) {
      this.hideDeclaredWarning = false;
    } else {
      this.hideDeclaredWarning = true;
    }
  }

  loopCounter = 0;

  //For some reason, categoryList and stateList would randomly return empty, OR with the correct data,
  //So, I am making a loop to keep checking until all lists are populated with the correct data, and only
  //then will I attempt to autofill the fields.
  //Will terminate itself after 10 tries.
  /*   checkLists() {
      if (this.loopCounter > 10) {
        return;
      } else {
        if (this.categoryList.length === 0) { //Lists not yet populated
          setTimeout(() => this.checkLists(), 500);
        } else {  //Lists are populated, continue as normal
          //We need to find the OBJECT reference for the key that the API Returned
          // if (this.userDetails.userPreference.productCategory != "" && this.userDetails.userPreference.productCategory != undefined && this.userDetails.userPreference.productCategory != null) {
          //   let cat = this.categoryList.find(x => x.key === this.userDetails.userPreference.productCategory);
          //   this.whatHappenedFormGroup.get('category').setValue(cat); //This value must be an object, not a string
          // }
          // if (this.userDetails.userPreference.address.state != "" && this.userDetails.userPreference.address.state != undefined && this.userDetails.userPreference.address.state != null) {
          //   let stat = this.stateList.find(x => x.state === this.userDetails.userPreference.address.state);
          //   this.startFormGroup.get('sender').get('state').setValue(stat.id); //Value must be the ID of the state
          // }
        }
      }
    } */

  //Used to filter categories based on what user is typing.
  //Works as a "contains" instead of "startsWith" due to !== -1
  /*   public filter(name: string): ICategory[] {
      return this.categoryList.filter(option =>
        option.value.toLowerCase().indexOf(name.toLowerCase()) !== -1);
    } */

  //Handles how the data gets displayed vs how the value is represented in the database.
  /* public displayCategories(obj?: ICategory): string | undefined {
    return obj ? obj.value : undefined;
  } */



  //Scenarios
  public loss: boolean = false;
  public damage: boolean = false;
  public missing: boolean = false;
  public late: boolean = false;
  public other: boolean = false;
  public cargo: boolean = false;
  public flex: boolean = false;
  public ups: boolean = false;
  public istnt: boolean = false;


  //contact selections
  public policyHolder: boolean = false;
  public recipient: boolean = false;
  public custom: boolean = false;
  public filteredCategories: Observable<ICategory[]>;

  //Switch through the different flows
  public toggleFlow(type: string) {
    this.loss = false;
    this.damage = false;
    this.missing = false;
    this.late = false;
    this.policyHolder = false;
    this.custom = false;
    this.recipient = false;
    if (type == "loss") {
      this.loss = true;
    } else if (type == "damage") {
      this.damage = true;
      this.whatHappenedFormGroup.controls.damageDescription.setValidators(Validators.required);
      this.whatHappenedFormGroup.controls.damageDate.setValidators(Validators.required);
      this.whatHappenedFormGroup.controls.damageRepairable.setValidators(Validators.required);
      this.whatHappenedFormGroup.controls.damageLocations.setValidators(Validators.required);
      this.whatHappenedFormGroup.controls.damageDate.setValue(this.claim.lossDate);

      this.whatHappenedFormGroup.controls.missingDescription.clearValidators();
      this.whatHappenedFormGroup.controls.missingDescription.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.missingDate.clearValidators();
      this.whatHappenedFormGroup.controls.missingDate.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.missingLocations.clearValidators();
      this.whatHappenedFormGroup.controls.missingLocations.updateValueAndValidity();
    } else if (type == "missing") {
      this.missing = true;
      this.whatHappenedFormGroup.controls.missingDescription.setValidators(Validators.required);
      this.whatHappenedFormGroup.controls.missingDate.setValidators(Validators.required);
      this.whatHappenedFormGroup.controls.missingLocations.setValidators(Validators.required);
      this.whatHappenedFormGroup.controls.missingDate.setValue(this.claim.lossDate);

      this.whatHappenedFormGroup.controls.damageDescription.clearValidators();
      this.whatHappenedFormGroup.controls.damageDescription.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageDate.clearValidators();
      this.whatHappenedFormGroup.controls.damageDate.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageRepairable.clearValidators();
      this.whatHappenedFormGroup.controls.damageRepairable.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageLocations.clearValidators();
      this.whatHappenedFormGroup.controls.damageLocations.updateValueAndValidity();
    } else if (type == "late") {
      this.late = true;
    }
  }

  //Sorts an array alphabetically
  //If needing case insensitive, add .toUpperCase()
  //To use: this.someArray.sort(this.compare);
  public compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }


  /* public getCurrency() {
    if (this.isCanadaUser) {
      var item = { key: 'cad', value: 'CAD' }
      this.listCurrency.push(item);
    }

    if (this.isUsaUser) {
      var item = { key: 'usd', value: 'USD' }
    }
    else if (this.isUkUser) {

      var itemGBP = { key: 'gbp', value: 'GBP' }
      this.listCurrency.push(itemGBP);

      var itemEur = { key: 'eur', value: 'EUR' }
      this.listCurrency.push(itemEur);
    }
    else if (this.isGermanyUser || this.isFranceUser || this.isItalyUser) {

      var itemGBP = { key: 'gbp', value: 'GBP' }
      this.listCurrency.push(itemGBP);

      var itemEur = { key: 'eur', value: 'EUR' }
      this.listCurrency.push(itemEur);
    }
  } */


  //Loads all MetaData for claims flow
  public getMetaData() {
    let data = localMetaData;
    // this.claimService.getMetaData().subscribe(
    //  data => { 
    try {
      this.countryList = Object.entries(data.metaData.country).map(([key, value]) => ({ key, value }));
      this.countryList.sort(this.compare);

      //Remove Miscellaneous data from productCategory
      if (Object.keys(data.metaData.productCategory).find(x => x === 'miscellaneous_other')) {
        delete data.metaData.productCategory['miscellaneous_other'];
      }

      if (Object.keys(data.metaData.productCategory).find(x => x === 'miscellaneous_personal')) {
        delete data.metaData.productCategory['miscellaneous_personal'];
      }

      this.categoryList = Object.entries(data.metaData.productCategory).map(([key, value]) => ({ key, value }));

      if (Object.keys(data.metaData.transportationid_Ext).find(x => x === 'UPS Freight')) { //UPS Freight needs to be removed from the carrier list
        delete data.metaData.transportationid_Ext['UPS Freight'];
      }
      //Load certain carriers based on if the policy is Cargo or Flex
      if (this.flex) {
        this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
      } else {
        this.carrierList = data.metaData.transportationid_Ext;
      }
    } catch (e) {
      //Load local metadata
      this.loadLocalMetaData();
    }
    /*  }, error => {
       // Load local metadata
       this.loadLocalMetaData();
     }
   ); */
  }

  loadLocalMetaData() {
    this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });//translated countries
    this.countryList.sort(this.compare);
    //this.currencyList = { 'usd': 'USD', 'cad': 'CAD', 'eur': 'EUR', 'gbp': 'GBP' }
    this.categoryList = Object.entries(productCategory).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.productCategory.${key}`) } }); //translated categories
    //Load certain carriers based on if the policy is Cargo or Flex
    if (this.flex) {
      this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    } else {
      this.carrierList = Object.fromEntries(Object.entries(carriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    }
  }

  cancel() {
    this.isClaimSubmit.emit('0');
  }

  continuing: boolean = false;
  isError: boolean = false;

  //Everytime user clicks Continue button, saveClaim is called
  public saveClaim() {
    
    var reason = this.whatHappenedFormGroup.get('reason').value;    
    if(reason === 1){
      this.whatHappenedFormGroup.controls.missingDate.clearValidators();
      this.whatHappenedFormGroup.controls.missingDate.setValidators([Validators.nullValidator]);
      this.whatHappenedFormGroup.controls.missingDate.updateValueAndValidity();
    }

    if(reason === 2){
      this.whatHappenedFormGroup.controls.damageDate.clearValidators();
      this.whatHappenedFormGroup.controls.damageDate.setValidators([Validators.nullValidator]);
      this.whatHappenedFormGroup.controls.damageDate.updateValueAndValidity();
    }

    if (this.whatHappenedFormGroup.valid) {

      this.isError = false;
      this.reOpenClaim.policyNumber = this.userService.getUserInfo().policyNumber;
      this.reOpenClaim.claimNumber = this.claim.claimNumber;
      this.reOpenClaim.claimPublicId = this.claim.publicID;
      this.reOpenClaim.customerNote = "Reopen a claim";
      this.reOpenClaim.reason = "newinfo";

      /********************************************/
      this.claimService.reopenClaim(this.reOpenClaim).subscribe(
        reopenClaimResponse => {
          /********************************************/
          if (reopenClaimResponse.code === "500" || reopenClaimResponse.code === "200") {
            this.continuing = false;
          }
          else {

            this.continuing = true;
            this.errorNotification = false;
            //ent.preventDefault();
            //Need these 4 values prepopulated for the save claim API.
            // this.claim.claimNumber = this.persistClaimNumber;
            // this.claim.publicID = this.persistPublicId;
            this.claim.lossType = "PR";
            this.claim.lossCause = "loss_Ext";
            // this.claim.shippingDetailsDTO.trackingNumber = this.claimDetails.tracking;

            // let policy = this.loginService.getPolicyDetails();
            // this.claim.policy.policyNumber = policy.policyNumber;
            // this.claim.policy.effectiveDate = policy.effectiveDate;
            // this.claim.policy.expirationDate = policy.expirationDate;
            // this.claim.policy.insured = policy.insured;
            // this.claim.policy.policyType = policy.policyType.key;

            if (this.claimDetails.carrier == 'UPS') {
              this.ups = true;
            } else {
              this.ups = false;
            }
            this.gatherFormValues();
            this.claimService.saveClaim(this.claim).subscribe(
              saveClaimResponse => {
                if (saveClaimResponse.code === "500" || saveClaimResponse.code === "200") {
                  this.continuing = false;
                }
                else {
                  this.isClaimSubmit.emit('1');
                }
              }, error => {
                this.continuing = false;
                this.isClaimSubmit.emit('0');
              }
            );
          }
          /********************************************/
        }, errorReopen => {
          this.isClaimSubmit.emit('0');
        }
      );
      /********************************************/
      return true;
    } else {
      this.validateAllFormFields(this.whatHappenedFormGroup);
      this.isError = true;
      this.errorNotification = true;
      return false;
    }
  }

  //Used to manually check all fields & show errors
  validateAllFormFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  public track3ShipmentDetails: IShippingDetailsAutofill = {};
  consigneeAddress: any = '';
  // trackingNumber = this.userService.getUserInfo().trackingNumber.toUpperCase();


  get reason() { return this.whatHappenedFormGroup.controls.reason };
  get lossCare() { return this.whatHappenedFormGroup.controls.lossCare };
  get lossReship() { return this.whatHappenedFormGroup.controls.lossReship };
  get lossRefund() { return this.whatHappenedFormGroup.controls.lossRefund };
  get damageDescription() { return this.whatHappenedFormGroup.controls.damageDescription };
  get damageDate() { return this.whatHappenedFormGroup.controls.damageDate };
  get damageRepairable() { return this.whatHappenedFormGroup.controls.damageRepairable };
  get damageLocations() { return this.whatHappenedFormGroup.controls.damageLocations };
  get damageOther() { return this.whatHappenedFormGroup.controls.damageOther };
  get missingDescription() { return this.whatHappenedFormGroup.controls.missingDescription };
  get missingDate() { return this.whatHappenedFormGroup.controls.missingDate };
  get missingLocations() { return this.whatHappenedFormGroup.controls.missingLocations };
  get missingOther() { return this.whatHappenedFormGroup.controls.missingOther };
  get lateCare() { return this.whatHappenedFormGroup.controls.lateCare };
  get lateReship() { return this.whatHappenedFormGroup.controls.lateRership };
  get lateRefund() { return this.whatHappenedFormGroup.controls.lateRefund };

  formLossDescription() {
    let lossDescription = null;
    lossDescription = {
      shipmentRole: '',
      reason: this.reason.value
    }
    //Fill up dynamic data
    if (this.reason.value == 0) {
      //loss
      lossDescription = {
        ...lossDescription,
        care: this.lossCare.value,
        careDetails: this.lossCare.value == '0' ? this.lossReship.value : this.lossCare.value == '1' ? this.lossRefund.value : ''
      }
    } else if (this.reason.value == 1) {
      //damage
      lossDescription = {
        ...lossDescription,
        damageDescription: this.damageDescription.value,
        damageDate: this.damageDate.value,
        repairable: this.damageRepairable.value,
        packageLocation: this.damageLocations.value,
        packageLocationDetails: this.damageOther.value
      }
    } else if (this.reason.value == 2) {
      //missing
      lossDescription = {
        ...lossDescription,
        missingDescription: this.missingDescription.value,
        missingDate: this.missingDate.value,
        packageLocation: this.missingLocations.value,
        packageLocationDetails: this.missingOther.value
      }
    } else if (this.reason.value == 3) {
      //late
      lossDescription = {
        ...lossDescription,
        care: this.lateCare.value,
        careDetails: this.lateCare.value == '0' ? this.lateReship.value : this.lateCare.value == '1' ? this.lateRefund.value : ''
      }
    }
    return JSON.stringify(lossDescription);
  }


  senderStateDisplay = '';
  recipientStateDisplay = '';
  public currencyKey = 'usd';
  repairableDisplay = '';
  locationDisplay = '';

  gatherFormValues() {
    //Payee Data
    //this.claim.payeeId = this.persistPayeeInfo ? this.persistPayeeInfo.payeeID : '';

    //StartFormGroup
    /* if (this.whatHappenedFormGroup.valid) {
      this.claim.trackingNumber_Ext = this.shippingDetailsDto.trackingNumber ? this.shippingDetailsDto.trackingNumber : "";
      this.claim.shippingDetailsDTO.shipmentIdentificationNumber = this.shippingDetailsDto.trackingNumber ? this.shippingDetailsDto.trackingNumber : "";


      this.claim.transportationCarrierId_Ext = this.claimDetails.carrier ? this.claimDetails.carrier : "";
      //this.claim.shippingDetailsDTO.senderDetails.companyName = this.shippingDetailsDto.senderDetails.companyName ? this.shippingDetailsDto.senderDetails.companyName : "";
      //this.claim.shippingDetailsDTO.senderDetails.city = this.shippingDetailsDto.senderDetails.city ? this.shippingDetailsDto.senderDetails.city : "";
      //this.claim.shippingDetailsDTO.senderDetails.state = this.shippingDetailsDto.senderDetails.state ? this.shippingDetailsDto.senderDetails.state : "NA";
      //this.claim.shippingDetailsDTO.senderDetails.zipCode = this.shippingDetailsDto.senderDetails.zipCode ? this.shippingDetailsDto.senderDetails.zipCode : "";
      //this.claim.shippingDetailsDTO.shipperPostalCode = this.shippingDetailsDto.senderDetails.zipCode ? this.shippingDetailsDto.senderDetails.zipCode : "";
      //this.claim.shippingDetailsDTO.senderDetails.country = this.shippingDetailsDto.senderDetails.country ? this.shippingDetailsDto.senderDetails.country : "";
      //this.claim.shippingDetailsDTO.receiverDetails.companyName = this.shippingDetailsDto.receiverDetails.company ? this.shippingDetailsDto.receiverDetails.company : "";
      //this.claim.shippingDetailsDTO.receiverDetails.city = this.shippingDetailsDto.receiverDetails.city ? this.shippingDetailsDto.receiverDetails.city : "";
      //this.claim.shippingDetailsDTO.receiverDetails.state = this.shippingDetailsDto.receiverDetails.state ? this.shippingDetailsDto.receiverDetails.state : "NA";
      //this.claim.shippingDetailsDTO.receiverDetails.zipCode = this.shippingDetailsDto.receiverDetails.zipCode ? this.shippingDetailsDto.receiverDetails.zipCode : "";
      //this.claim.shippingDetailsDTO.consigneePostalCode = this.shippingDetailsDto.receiverDetails.zipCode ? this.shippingDetailsDto.receiverDetails.zipCode : "";
      //this.claim.shippingDetailsDTO.receiverDetails.country = this.shippingDetailsDto.receiverDetails.country ? this.shippingDetailsDto.receiverDetails.country : "";
      //this.claim.shippingDetailsDTO.shipDate = this.shippingDetailsDto.shipDate ? this.shippingDetailsDto.shipDate : "";
      //this.claim.lossDate = this.shippingDetailsDto.shipDate ? this.shippingDetailsDto.shipDate : "";
      //this.claim.shippingDetailsDTO.referenceNumber = this.shippingDetailsDto.referenceNumber ? this.shippingDetailsDto.referenceNumber : "";
      this.senderStateDisplay = this.shippingDetailsDto.senderDetails.state ? this.shippingDetailsDto.senderDetails.state : "";
      this.recipientStateDisplay = this.shippingDetailsDto.receiverDetails.state ? this.shippingDetailsDto.receiverDetails.state : "";
    } */
    //WhatHappenedFormGroup
    if (this.whatHappenedFormGroup.valid) {
      if (this.whatHappenedFormGroup != null) {
        if (this.whatHappenedFormGroup.get('reason').value == 0) {
          this.claim.lossCause = "loss_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 1) {
          this.claim.lossCause = "damage_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 2) {
          this.claim.lossCause = "fallout_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 3) {
          this.claim.lossCause = "delay";
        }
      }
      // this.claim.purposeOfShipment = this.whatHappenedFormGroup.controls.purpose.value;
      this.claim.productCategory_Ext = this.categoryList.find(c => c.value == this.claim.productCategory_Ext)?.key;
      this.claim.shippingDetailsDTO.merchandiseDescription = this.whatHappenedFormGroup.value.description;
      this.claim.upsmerchandise_Ext = "Description of merchandise in detail = " + this.whatHappenedFormGroup.value.description;
      this.claim.shippingDetailsDTO.insuredValueCurrency = this.currencyKey;
      this.claim.shippingDetailsDTO.merchandiseValue = parseFloat(this.claimService.formatAmount(this.whatHappenedFormGroup.value.merchandise, false)).toFixed(2);
      this.claim.shippingDetailsDTO.verifiedShippingCharge = parseFloat(this.claimService.formatAmount(this.whatHappenedFormGroup.controls.shipping.value, false)).toFixed(2);
      this.claim.shippingDetailsDTO.insuredAmount = parseFloat(this.whatHappenedFormGroup.controls.amount.value).toFixed(2);
      this.claim.claimAmount_Ext = parseFloat(this.whatHappenedFormGroup.controls.amount.value).toFixed(2);
      this.claim.additionalNotes = this.whatHappenedFormGroup.value.additionalComments;
      this.claim.customerNotes = this.whatHappenedFormGroup.value.additionalComments;
      //this.claim.quantity = this.whatHappenedFormGroup.value.quantity;
      if (this.loss) {
        this.claim.lossDescription = this.formLossDescription();
        this.claim.reshipTrackingNumber = this.whatHappenedFormGroup.value.lossReship;
        this.claim.creditMemoRefundNo = this.whatHappenedFormGroup.value.lossRefund;
        this.claim.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
      } else if (this.damage) {
        this.claim.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
        this.claim.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
        this.claim.lossDescription = this.formLossDescription();
        this.repairableDisplay = this.repairables[this.whatHappenedFormGroup.value.damageRepairable].text;
        if (this.whatHappenedFormGroup.value.damageLocations < 3) {
          if (this.whatHappenedFormGroup.value.damageLocations != '') {
            this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text;
            this.claim.shipmentStatus = this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text;
          }
        } else if (this.whatHappenedFormGroup.value.damageLocations == 3) {
          this.claim.shipmentStatus = this.whatHappenedFormGroup.value.damageOther;
        }
      } else if (this.missing) {
        this.claim.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
        this.claim.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
        this.claim.lossDescription = this.formLossDescription();
        if (this.whatHappenedFormGroup.value.missingLocations < 3) {
          if (this.whatHappenedFormGroup.value.missingLocations != '') {
            this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text;
            this.claim.shipmentStatus = this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text;
          }
        } else if (this.whatHappenedFormGroup.value.missingLocations == 3) {
          this.claim.shipmentStatus = this.whatHappenedFormGroup.value.missingOther;
        }
      } else if (this.late) {
        this.claim.lossDescription = this.formLossDescription();
        this.claim.reshipTrackingNumber = this.whatHappenedFormGroup.value.lateReship;
        this.claim.creditMemoRefundNo = this.whatHappenedFormGroup.value.lateRefund;
        this.claim.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
      }
    }

    //Contact Info Form Group
    /* if (!this.whatHappenedFormGroup.invalid) {
      this.claim.mainContact.firstName = this.mainContact.firstName;
      this.claim.mainContact.workNumber = this.mainContact.workNumber;
      this.claim.mainContact.emailAddress1 = this.mainContact.emailAddress1;
    } */
  }


}
