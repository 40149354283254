<div class="collapsible-panel" [class.collapsed]="isCollapsed">
    <div class="panel-header" (click)="isCollapsed = !isCollapsed">
        <span class="margin-bold" *ngIf="!isCollapsed">{{ title }}</span>
        <span class="margin" *ngIf="isCollapsed">{{ title }}</span>
        <div class="toggle">
            <mat-icon *ngIf="isCollapsed">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!isCollapsed">keyboard_arrow_up</mat-icon>
        </div>
    </div>
    <div class="panel-content">
        <ng-content></ng-content>
    </div>
</div>