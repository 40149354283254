import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject , throwError } from 'rxjs';
import { LoginService } from '../../services/login.service';
import { catchError, filter, take, retry, exhaustMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { UtilityService } from 'src/app/services/utility.service';

//Using code for reference:
//https://angular-academy.com/angular-jwt/

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private errorCountRefreshing = 0;

  constructor(private loginService: LoginService,
    private authService: AuthService,
    private utilityService: UtilityService,
    private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getAccessToken()) {
      //Add an access token to our API call
      req = this.addToken(req, this.authService.getAccessToken());
    }

    req = req.clone(
      {
        'withCredentials': true
      }
    )

    //Start the new request (with the proper headers/tokens)
    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.error && error.error.text && error.error.text.includes('Authorization Failed!')) {
        this.utilityService.networkChangeDetected();
      }

      //Check if the error message contains a token expiration phrase
      if (error instanceof HttpErrorResponse && error.error && error.error.text && error.error.text.includes('The token is expired') || error.status === 401) {
        //The token expired message was found - get a new token
        return this.handleAuthError(req, next);
      }
      else if(req.url.endsWith('favicon.ico') || req.url.endsWith('en.json')) {
        return throwError(error);
      }
      else {
        //specific error handling.
        if (error.status === 403 || error.status === 408 || error.status === 400 || error.status === 503) {
          if (error.status === 400) {
            return throwError(error);
          } else {
            this.loginService.errorDialog();
          }
          return throwError(error);
        } else if (error.status === 500 || error.status === 502 || error.status === 0) {
          if (this.isRefreshing) {
            //Don't show a maintenance message just yet
            this.errorCountRefreshing++;
            if (this.errorCountRefreshing>1) {
              //Now show the error message. Too many service outtage errors.
              this.loginService.errorDialog();
              this.errorCountRefreshing = 0;
            }
          } else {
            //Show the maintenance message if the API is not get-fresh-token
            //Also do not show the maintenance message for the payee and shipment/details APIs during the claim flow
            if (this.router.url == '/claims/new' && (req.url.includes('/payee') || (req.url.includes('shipments/details')))) {
              return throwError(error);
            } else if (req.url.includes('/change-password') && error.status === 500) {
              return throwError(error);
            }
            else if(error.url.indexOf('/uilog') > 0 || error.url.endsWith('uilog')) {
              return throwError(error);
            }
            else if(error.url.endsWith('documents/generate-upload-token') || error.url.endsWith('document/upload')) {
              return throwError(error);
            }
            else {
              this.loginService.errorDialog();
            }
          }
        } else {
          return throwError(error);
        }
        return throwError(error);
      }
    }));
  }

  addToken(req: HttpRequest<any>, token: string) {
    //Some requests don't need a token... This part SHOULD be handled already due to
    //sessionstorage not persisting across tabs, along with the
    //getAccessToken() check in the intercept function,
    //But I added these additional checks here JUST in case.
    if (req.url.includes('/get-fresh-token') || req.url.includes('/check-user')
     || req.url.includes('/policy/validate') || req.url.includes('/save/verification')
     || req.url.includes('/email/createAccount') || req.url.includes('/verification')
     || req.url.includes('/verify-username') || req.url.includes('/create-user')
     || req.url.includes('/email/registrationComplete') || req.url.includes('/forgot-username')
     || req.url.includes('/email/resetPassword') || req.url.includes('/reset-password')
     || req.url.includes('/logIn') || req.url.includes('/verify-email' )|| req.url.includes('/guestVerify' )) {
      //Not adding token to these api
      return req;
    }
    //Add token
    return req.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  handleAuthError(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      //Set these values because we don't want to interrupt the get-fresh-token API call
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        exhaustMap((token: any) => {
          //Stops previous API calls, and starts the get-fresh-token API call
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.accessToken);
          return next.handle(this.addToken(req, token.accessToken));
        }),
        retry(3), //Retries the get-fresh-token endpoint if it fails
        catchError(error => {
          let noLogin = !!JSON.parse(sessionStorage.getItem('userDetails'))?.type;
          this.loginService.removeLocalStorage();
          if(!noLogin) {
            this.router.navigate(['/login']);
          }
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null), //Emit values that are not null
        take(1), //Take the first value from the source
        exhaustMap(accessToken => { //Stops previous API calls, and sends the refreshTokenSubject's value as the accessToken parameter
          return next.handle(this.addToken(req, accessToken))
        })
      );
    }
  }
}
