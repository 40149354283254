import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { TuppsStepperComponent } from './stepper.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
  ],
  declarations: [TuppsStepperComponent],
  providers: [],
  exports: [TuppsStepperComponent],
})
export class TuppsStepperModule {}
