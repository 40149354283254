import { UserService } from '../../../services/user.service';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { AccountInfo } from '../../../shared/models/account-info.interface';
import { TaggingService } from 'src/app/tags/tagging.service';


@Component({
  selector: 'upsc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class') public hostClass = 'header';

  //Data holders
  firstName: string;
  userDetails: AccountInfo;
  Username: string;

  //UserType
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isAdjuster: boolean;
  isGuestClaim: boolean;
  isCanadaUser: boolean;
  isHPGuestUser: boolean;
  isShopifyUser: boolean;
  canManageUsers: boolean = false;
  isProfileManager: boolean = false;
  showRLSMenuItems: boolean;
  canChangePolicy: boolean;
  isDdUser: boolean = false;

  constructor(
    public router: Router,
    private loginServive: LoginService,
    private userService: UserService,
    private taggingService: TaggingService
  ) { }

  public ngOnInit() {
    this.userDetails = this.userService.getUserInfo();
    this.Username = sessionStorage.getItem('userid');
    //Error handling for when user opens more than one tab
    if (this.userDetails) {
      this.firstName = this.userDetails.firstName ? this.userDetails.firstName : this.userDetails.contactName;
    } else {
      this.loginServive.removeLocalStorage(this.Username, this.userDetails?.objectUID).subscribe(result => { });
      this.router.navigate(['/login']);
      window.location.reload();
    }

    this.isAdjuster = this.userService.isUserAdjuster();
    this.isGuestClaim = this.userService.isGuestClaim();
    this.isHPGuestUser = this.userService.isHPGuestUser();
    this.isShopifyUser = this.userService.isShopifyUser();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.canManageUsers = this.userService.getUserPermissions()?.isUserManagement;
    this.isProfileManager = this.userService.getUserPermissions()?.isPolicyUpdate;
    this.showRLSMenuItems = !this.userService.isInternalUser() || !!this.loginServive.getPolicyDetails()?.policyNumber;
    this.canChangePolicy = this.userService.isInternalUser();

    if (this.userService.getUserInfo().userRoleID === '7') {
      this.isDdUser = true;
    }
  }

  logout(event) {
    this.taggingService.link({ link_name: 'log_out' });
    this.loginServive.removeLocalStorage(this.Username, this.userDetails.objectUID).subscribe(result => { });
    this.router.navigate(['/login']);
  }

  tagging(type) {
    if (type == 'dashboard') {
      this.taggingService.link({ link_name: 'dashboard_my_account_tab' });
    } else if (type == 'profile') {
      this.taggingService.link({ link_name: 'profile_my_account' });
    }
  }
  
  resetPolicy() {
    sessionStorage.removeItem('policyDetails');
    this.router.navigate(['internal']);
  }
}
