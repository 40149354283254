import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'src/app/shared/components/layout/layout.component';
import { InsightsContainerComponent } from './insights-container.component';
const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: LayoutComponent,
      children:[
      {
      path: '',
      component: InsightsContainerComponent
      }]
    }])],
  exports: [RouterModule]
})
export class InsightsContainerRoutingModule { }
