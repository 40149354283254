import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AccountInfo } from 'src/app/shared/models/account-info.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { States } from '../../../../config/metadata-config';
import { countries } from '../../../../config/metadata-config';
import { ClaimService } from '../../../services/claim.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/login.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AccountService } from 'src/app/services/account.service';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { IPolicies } from 'src/app/shared/models/policies.interface';
import { Observable, Subscription } from 'rxjs';
import { EmailChangeConfirmationComponent } from '../dialog/address-change-confirmation/email-change-confirmation/email-change-confirmation.component';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { Router } from '@angular/router';
import { BillingService } from 'src/app/services/billing.service';

@Component({
  selector: 'upsc-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  userDetails: AccountInfo;
  policyDetails: any;
  dwDetails;
  addressForm: UntypedFormGroup;
  PolicyHolderEmail: any = {};

  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isProfileManager: boolean = false;
  isInternalUser: boolean = false;

  canEarnDWPoints: boolean = false;
  showReminderMessage: boolean = false;
  showSuccessMessage: boolean = false;

  countryList = [];
  fullStateList = States; //USA + Canadian states/provinces

  subscriptions: Subscription[] = [];
  verificationInterface = { emailId: "" };
  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;
  verifyCodeInterface = { emailId: "", toVerify: "" };
  updateContactRequest: any;

  loading: boolean = false;
  scriptError: boolean = false;
  systemError: boolean = false;

  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private claimService: ClaimService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private accountService: AccountService,
    public dialog: MatDialog,
    private phonePipe: PhoneNumberPipe,
    private router: Router,
    private billingService: BillingService
  ) {
    this.userDetails = this.userService.getUserInfo();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.policyDetails = this.loginService.getPolicyDetails();
    this.isInternalUser = this.userService.isInternalUser();
    this.dwDetails = this.loginService.getDWDetails();

    this.addressForm = this.fb.group({
      addressLine1: [{value: this.policyDetails.addressLine1_Ext, disabled: !this.isUsaUser}, Validators.compose([Validators.required])],
      addressLine2: [{value: this.policyDetails.addressLine2_Ext, disabled: !this.isUsaUser}],
      city: [{value: this.policyDetails.city, disabled: !this.isUsaUser}, Validators.compose([Validators.required])],
      zip: [{value: this.policyDetails.zip, disabled: !this.isUsaUser}, Validators.compose([Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)])],
      country: [{ value: 'US', disabled: true }],
      state: [{value: this.policyDetails.state, disabled: !this.isUsaUser}, Validators.compose([Validators.required])],
      policyHolderEmail: [{value: this.policyDetails.policyHolderEmail, disabled: !this.isUsaUser}, Validators.compose([Validators.required, Validators.email])],
      companyName: [{value: this.policyDetails.insured, disabled: true}],
      workPhone: [{value: '', disabled: !this.isUsaUser}, Validators.compose([Validators.required])],
      billingEmail: [{value: this.policyDetails.billingEmail, disabled: !this.isUsaUser}, Validators.compose([Validators.required, Validators.email])]
    });
  }

  goToManageUsers() {
    this.router.navigate(['/manage-users']);
  }

  ngOnDestroy() {
    localStorage.removeItem('profilePopupStatus');
  }

  ngOnInit(): void {
    this.determinePolicyUpdatability();
    this.loadMetaData();
    this.getIndicators();

    // Currently phone numbers should be 10 numbers long exactly, but there is a bug when setting the value directly thru ts
    // Where the mask doesn't apply, and the input field thinks that the phone number is too short. This is a workaround
    // to set the value with the mask periods in place already so the validation error doesn't occur
    try {
      let phoneNumber = this.policyDetails.policyHolderPhone;
      let maskedPhoneNumber;
      if (phoneNumber?.length === 10) {
        maskedPhoneNumber = phoneNumber.slice(0, 3) + '.' + phoneNumber.slice(3, 6) + '.' + phoneNumber.slice(6);
      }

      this.addressForm.controls.workPhone.setValue(maskedPhoneNumber);
    } catch {}
  }

  determinePolicyUpdatability() {
    this.isProfileManager = this.userService.getUserPermissions()?.isPolicyUpdate;
    if (this.policyDetails?.policySource == 'legacy') {  //This overrides whatever the user's permissions is. "CBP" or "legacy" policies should not have ability to update policy.
      this.isProfileManager = false;
    }
  }

  onSubmit() {
    // Send the user a verification code based off policy holder email, and then if code is sent successfully, open pop-up to complete update process
    this.sendVerificationCode();
  }
  
  sendVerificationCode() {
    this.loading = true;
    this.systemError = false;
    this.scriptError = false;
    this.createVerificationInterface.data.emailAddress = this.addressForm.get('policyHolderEmail').value;
    let subscription3 = this.accountService.createVerificationCode(this.createVerificationInterface).subscribe(
      data => {
        if ((data.responseMessage).toLowerCase() === 'new verification code created' || (data.responseMessage).toLowerCase() === 'code not yet expired') {
          this.openEmailVerificationPopup();
          this.loading = false;
          this.scriptError = false;
          this.systemError = false;
        } else {
          this.loading = false;
          this.scriptError = false;
          this.systemError = true;
        }
      }, error => {
        this.loading = false;
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.systemError = false;
        } else {
          this.scriptError = false;
          this.systemError = true;
        }
      }
    );
    this.subscriptions.push(subscription3);
  }

  openEmailVerificationPopup() {
    let phoneNumber = this.addressForm.controls.workPhone.value;
    let cleanedPhoneNumber = phoneNumber.replace(/[.-]/g, '');
    let currentTime = new Date();
    let UTCTime = currentTime.toISOString();
    let formattedDateString = UTCTime.replace("T", " ").replace("Z", ""); // Format: "2021-06-01 15:00:00.000", GW will throw validation error elsewise

    let updateContactRequest = {
      policyNumber: this.policyDetails.policyNumber,
      primaryNamedInsured: {
        name: (this.userDetails.firstName && this.userDetails.lastName) ? this.userDetails.firstName + ' ' + this.userDetails.lastName : "",
        address: {
          addressLine1: this.addressForm?.controls?.addressLine1?.value,
          addressLine2: this.addressForm?.controls?.addressLine2?.value,
          city: this.addressForm?.controls?.city?.value,
          postalCode: this.addressForm?.controls?.zip?.value,
          state: {
            code: this.addressForm?.controls?.state?.value,
          }
        },
        emailAddress1: this.addressForm?.controls?.policyHolderEmail?.value, // New wallet field for 'Policy Holder Email'
        workPhone: cleanedPhoneNumber, // New wallet field for 'Policy Holder Phone Number'
      },
      billingContact: {
        emailAddress1: this.addressForm?.controls?.billingEmail?.value, // New wallet field for 'Billing Email'
      },
      apiDate: formattedDateString
    };

    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '651px';
    config.data = {
      title: "",
      message: "",
      updateContactRequest: updateContactRequest,
      canEarnDWPoints: this.canEarnDWPoints // Determines which success page to display (normal success message vs success message that includes mention of earning points)
    };
    
    localStorage.setItem('profilePopupStatus', JSON.stringify(0)); // 0: They haven't entered in email verification code yet to finish updating profile info. 1: They've finished updated profile info fully
    this.showReminderMessage = false;
    this.showSuccessMessage = false;
    let dialogRef = this.dialog.open(EmailChangeConfirmationComponent, config);

    dialogRef.afterClosed().subscribe(result => 
      {
        this.canEarnDWPoints = false; // After one profile update, no users are allowed to earn points anymore
        if (JSON.parse(localStorage.getItem('profilePopupStatus')) == 0) {
          this.showReminderMessage = true;
          this.showSuccessMessage = false;
        } else if (JSON.parse(localStorage.getItem('profilePopupStatus')) == 1) {
          this.showReminderMessage = false;
          this.showSuccessMessage = true;
          this.addressForm.markAsPristine();
        } else {
          this.showReminderMessage = false;
          this.showSuccessMessage = false;
        }
      }
    );
  }

  getIndicators() {
    let request = { policy_Number: this.policyDetails?.policyNumber }
    this.billingService.DWgetIndicators(request).subscribe(
      data => {
        // These conditions must be met for the user to earn points from this profile validation event:
        // Is US user, has not updated profile already (indicator = false), has a walletID, and is rewards eligible.
        // If they don't meet ALL the conditions, don't show earn points flow.
        if (this.isUsaUser && !data?.data?.dW_Profile && this.dwDetails?.walletID && this.dwDetails?.rewardEligible == 'True') {
          this.canEarnDWPoints = true;
        } else { 
          this.canEarnDWPoints = false;
        }
      }, error => {
        this.canEarnDWPoints = false;
      }
    );
  }

  //Sorts an array alphabetically
  //If needing case insensitive, add .toUpperCase()
  //To use: this.someArray.sort(this.compare);
  public compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }

  loadMetaData() {
    this.claimService.getMetaData().subscribe(
      data => {
        this.scriptError = false;
        try {
          this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });
          this.countryList.sort(this.compare);
        } catch (e) {
          this.loadLocalMetaData();
        }
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
        }
        this.loadLocalMetaData();
      }
    );
  }

  loadLocalMetaData() {
    this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });//translated countries
    this.countryList.sort(this.compare);
  }
}
