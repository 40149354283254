import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-delivery-defense-error',
  templateUrl: './delivery-defense-error.component.html',
  styleUrls: ['./delivery-defense-error.component.scss']
})
export class DeliveryDefenseErrorComponent {

  constructor(public dialogRef: MatDialogRef<DeliveryDefenseErrorComponent>) { }
  
  public cancel(){
    this.dialogRef.close();
  }
}
