import { Component, Input } from '@angular/core';

@Component({
  selector: 'upsc-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss']
})
export class NotificationBoxComponent {

  @Input() public isSuccess = false;
  @Input() public isWarning = false;
  @Input() public isDanger = false;
  @Input() public isInfo = false;
  @Input() public isMessage = false;
  @Input() public isSuccessInfo = false;
  @Input() public isDangerInfo = false;

}
