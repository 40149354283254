import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, NEVER, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TupssService } from './tupss.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver  {
  constructor(private router: Router,
    private tupssService: TupssService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if(!this.tupssService.userData && this.tupssService?.getItem('type') == 'tfcp') {
      return this.tupssService.tfcpAuthentication(this.tupssService.getTfcpIdToken()).pipe(
        switchMap(data => of(true)),
        catchError(err => {
          this.router.navigate['tfcp'];
          return EMPTY;
        })
      );
    }
    else {
      return of(true);
    }
  }
}
