import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { IMessageSummary, MessageClaimIdsModel } from 'src/app/shared/models/claim.interface';
import { IPolicies } from 'src/app/shared/models/policies.interface';
import { ClaimService } from 'src/app/services/claim.service';
import { retry } from 'rxjs/operators';
import { PagerService } from '../../../../services/pager.service';
import { listOfMessages } from 'src/app/shared/models/claim.interface';
import { DeleteConfirmationComponent } from '../../dialogs/delete-confirmation/delete-confirmation.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'upsc-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit {

  @Input() isPagination: boolean = true;

  systemError: boolean = false;
  public searchQuery: any = "";
  //public searchValue: string = "";


  public summary: IMessageSummary = {};
  //public summaryResponse: IMessageClaimIdResponse = {};

  public policy: IPolicies;
  allClaimNumbers: string[];

  newPageIds: string[] = [];
  lengthPager: number = 0;

  emptySearchResult: boolean = false;
  emptyClaimResult: boolean = false;
  pager: any = {};
  public currentClaim;
  searchClaim: string = "";

  //Pagination
  pageSize: number = 5;
  pageNumber: number = 1;
  totalPages: any;
  pagedItems: any[] = [];
  pageTotals: any[] = new Array();
  isLastPage: boolean = false;
  isFirstPage: boolean = true;
  isData: boolean = false;

  constructor(
    private router: Router,
    public loginservice: LoginService,
    public claimservice: ClaimService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    public translateService: TranslateService,
    private pagerService: PagerService,
    private userService: UserService
  ) { }

  searchTypeControl: UntypedFormControl =
    new UntypedFormControl({ value: '', disabled: false }, Validators.required);

  searchByClaimControl: UntypedFormControl =
    new UntypedFormControl({ searchClaim: '', disabled: true }, Validators.compose([]));

  ngOnInit(): void {
    this.getClaimSummary();
  }

  markedAsTouched() {
    this.searchByClaimControl.markAsTouched();
  }

  //removeMessage
  removeMessage(row: any) {
    var postRequest: any = {};
    postRequest.policyNumber = this.policy.policyNumber;
    postRequest.message = "creating multiple messages";
    postRequest.claimNumber = row.claimNumber;

    var listOfMessages: listOfMessages[] = [];
    var listOfMessage: listOfMessages = {};
    listOfMessage.author = row.author;
    listOfMessage.body = row.body;
    listOfMessage.claimId = row.claimId;
    listOfMessage.claimNumber = row.claimNumber;
    listOfMessage.isAdjuster = row.isAdjuster;
    listOfMessage.claimNumber = row.claimNumber;
    listOfMessage.isAdjuster = row.isAdjuster;
    listOfMessage.isAutoMessage = row.isAutoMessage;
    listOfMessage.isMessageRead = row.isMessageRead;
    listOfMessage.isMessageSoftDeleted = true;
    listOfMessage.messageId = row.messageId;
    listOfMessage.time = row.time;
    listOfMessage.topic = row.topic;
    listOfMessages.push(listOfMessage);

    postRequest.messages = listOfMessages;
    postRequest.totalCount = 1;

    if (postRequest) {
      this.claimservice.postMessage(postRequest).subscribe(
        response => {
          this.systemError = false;
        }, error => {
          this.systemError = true;
        }
      );
      this.getClaimSummary();
    }
  }

  //Used to open the claim detail page
  claimDetail(claimNumber) {
    if (this.router.url == '/dashboard') {
      sessionStorage.removeItem('retainMessageState');
    } else {
      sessionStorage.setItem('retainMessageState', 'true');
      sessionStorage.setItem('messagePageId', JSON.stringify(this.pager.currentPage));
    }
    this.router.navigate(['/claims/detail'], { queryParams: { claimNumber: claimNumber, isMessage: true } });
  }


  dialogRef: any;
  messageData = {
    title: this.translateService.instant('claims.edit.messageDelete.title'),
    message: this.translateService.instant('claims.edit.messageDelete.descr'),
    isCopyrightPresent: false
  };

  delete(row: any) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '515px';
    config.data = this.messageData;
    this.dialogRef = this.dialog
      .open(DeleteConfirmationComponent, config)
      .afterClosed().subscribe(result => {
        if (result === "Delete") {
          this.removeMessage(row);
        }
      });
  }

  search() {
    this.searchByQuery();
  }

  searchByQuery() {
    if ((this.searchByClaimControl.value).length > 0) {
      this.searchQuery = this.searchByClaimControl.value
    }
    else {
      this.searchQuery = "";
    }
  }

  trim(event) {
    if (this.searchByClaimControl.value) {
      this.searchByClaimControl.setValue(this.searchByClaimControl.value.trim());
    }
  }

  handlNoResults(search?: boolean) {
    //this.emptySearchResult = true;
    if (search) {
      this.emptyClaimResult = false;
    } else {
      this.emptyClaimResult = true;
    }
    if (this.isPagination) {
      this.pagedItems = new Array(10);
    } else {
      this.pagedItems = new Array(5);
    }
    this.pagedItems.fill({});
  }

  isSearchClick: boolean = false;
  getSummaryByClaimNumber() {
    this.isSearchClick = true;
    var searchClaimNumber: string[] = [];
    if (this.searchClaim.length > 0) {
      searchClaimNumber.push(this.searchClaim);
      this.newPageIds = searchClaimNumber;
      this.lengthPager = 1;
      this.setNewPage(1);
    }
    else {
      this.getClaimSummary();
    }
  }

  clearSearch() {
    this.searchClaim = "";
    this.emptyClaimResult = false;
    this.emptySearchResult = false;
    this.isSearchClick = false;
    this.getClaimSummary();
  }

  getClaimSummary(filter = {}, search?: boolean): void {
    this.newPageIds = [];
    this.lengthPager = 0;
    this.policy = this.loginservice.getPolicyDetails();
    this.summary.claimStates = ["open", "closed"];
    this.summary.policyNumber = this.policy.policyNumber;
    this.summary.message = "give me all claims with message";
    if (this.userService.isParentPolicy()) {
      this.summary.childPolicyNumberList = JSON.parse(sessionStorage.getItem('childPolicies'));
    }
    if (this.userService.getUserInfo().groupID) {
      this.summary.groupID = this.userService.getUserInfo().groupID;
    }
    localStorage.removeItem('messageParams');
    localStorage.setItem('messageParams', JSON.stringify({ ...this.summary, ...filter }));
    this.claimservice.getAllMessageClaimIDs({ ...this.summary, ...filter }).pipe(
      retry(3)
    ).subscribe(data => {
      if (data) {
        if (data.claimNumbers.length > 0) {
          this.allClaimNumbers = data.claimNumbers;
          if (this.allClaimNumbers && this.allClaimNumbers.length > 0) {
            this.emptySearchResult = false;
            this.setPagerData();
          } else {
            this.handlNoResults(search);
          }
          this.ref.markForCheck();
          this.emptyClaimResult = false;
        }
        else {
          this.emptyClaimResult = true;
        }

      }
      else {
        this.emptyClaimResult = true;
      }

    },
      error => this.handlNoResults(search)
    );
  }

  removedMessages: any = [];
  setPagerData() {
    this.removedMessages.splice(0);
    this.claimservice.getMessageSummaryByIds(this.policy.policyNumber, this.allClaimNumbers, this.userService.isParentPolicy())
      .subscribe(
        data => {
          if (data.totalCount > 0) {
            var newData = data.messages.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
            newData.map(item => {
              if (item.isMessageSoftDeleted === false) {
                this.newPageIds.push(item.claimNumber);
                this.lengthPager = this.lengthPager + 1;
                this.removedMessages.push(item);
              }
            });
            this.emptyClaimResult = false;
          }
          else {
            this.emptyClaimResult = true;
          }
          let setPageNumber = JSON.parse(sessionStorage.getItem('messagePageId'));
          if (setPageNumber) {
            this.setPage(setPageNumber);
          } else {
            this.setPage(1);
          }
        },
        error => this.newPageIds = []
      );
  }



  setNewPage(page: number) {
    this.pager = this.pagerService.getPager(this.lengthPager, page);
    let pageIds = [];
    if (this.isPagination) {
      pageIds = this.newPageIds.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
    else {
      pageIds = this.newPageIds.slice(this.pager.startIndex, (this.pager.endIndex > 4 ? 4 : this.pager.endIndex) + 1);
    }
    this.claimservice.getMessageSummaryByIds(this.policy.policyNumber, pageIds)
      .subscribe(
        data => {
          if (data.totalCount > 0) {
            let messageList: listOfMessages[] = [];
            data.messages.map(item => {
              messageList.push(item);
            });
            messageList = messageList.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
            this.pagedItems = messageList;
            this.emptyClaimResult = false;
            this.isData = true;
          }
          else {
            this.emptyClaimResult = true;
            this.isData = false;
            this.pagedItems = [];
          }
        },
        error => this.pagedItems = []
      );
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.lengthPager, page);
    let pageIds = [];
    if (this.isPagination) {
      pageIds = this.newPageIds.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
    else {
      pageIds = this.newPageIds.slice(this.pager.startIndex, (this.pager.endIndex > 4 ? 4 : this.pager.endIndex) + 1);
    }
    this.removedMessages = this.removedMessages.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
    this.pagedItems = this.removedMessages;
    this.emptyClaimResult = false;
    this.isData = true;
  }

}
