export interface IPolicy{
    policyNumber?: string;
    effectiveDate?: Date;
    expirationDate?: Date;
    insured?: string;
    policyType?: string;
    properties?: IPolicyProperties[];
}

export interface IPolicies {
  addressLine1_Ext?: string;
  addressLine2_Ext?: string;
  address?: string;
  city?: string;
  effectiveDate?: Date;
  expirationDate?: Date;
  insured?: string;
  policyNumber?: string;
  policyType?: PolicyType;
  state?: string;
  zip?: string;
  policyCountry_Ext?: string; 
  policySource?: string;
  accountNumber?: string;
  dashboardInfo?: any;
  tntindicator?: string;
  ref1?: string;
  ref2?: string;
  subPolicyType?: string;
  coverageType?: string;
  productName?: string;
  dW_Banner?: any;
  dW_Profile?: any;
  hasTCCoverage?: boolean;
}

export interface PolicyType {
  key?: string;
  value?: string;
}

export interface IPolicyProperties{
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface CoverageDeductibleInterface {
  coveragePartName?: string;
  coverage?: {
    coverageName?: string;
    geographicMovement?: {
      name?: string;
      limitVal?: string;
      deductibleVal?: string;
      deductibleType?: string;
    }[];
  }[];
}

export enum POLICYTYPES {
  TRANSACTIONAL_PARCEL = 'transactional_parcel',
  ONDEMAND = 'ondemand',
  TRADITIONAL_CARGO = 'traditional_cargo',
  TRANSACTIONAL_CARGO = 'transactional_cargo',
  UNKNOWN = 'unknown'
}
