import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { Stepper2Component } from './stepper2.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
  ],
  declarations: [Stepper2Component],
  providers: [],
  exports: [Stepper2Component],
})
export class Stepper2Module {}
