import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SmartUploadRoutingModule } from './smart-upload-routing.module';
import { SmartUploadComponent } from './smart-upload.component';


@NgModule({
  declarations: [
    SmartUploadComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SmartUploadRoutingModule
  ]
})
export class SmartUploadModule { }
