import { Injectable } from '@angular/core';
import { EnvironmentService } from './core/services/environment.service';
import { ApiEndpointService } from './core/services/api-endpoint.service';

@Injectable()
export class PreLoadService {

    constructor(private environmentService: EnvironmentService,
        private apiEndpointService: ApiEndpointService, ) {


    }

    load() {

        return new Promise((resolve, reject) => {
            this.environmentService.initEnvironment()
                .subscribe(
                    (env) => {
                        if (!env) {
                            return;
                        }                        
                        this.apiEndpointService.constructEndpoints(env);
                        resolve(true);
                    },
                    (err) => {
                        console.error('Failed initializing environment.', err);
                    },
            );
        });


    }

}