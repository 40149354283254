import { Component, Input } from '@angular/core';

@Component({
  selector: 'upsc-rectangle-progress-bar',
  templateUrl: './rectangle-progress-bar.component.html',
  styleUrls: ['./rectangle-progress-bar.component.scss']
})
export class RectangleProgressBarComponent {
  @Input() percent: number;
  @Input() text: string;
  @Input() congrats: boolean;
  @Input() totalwidth: string;

}
