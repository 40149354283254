import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[upscZipcode]'
})
export class ZipcodeDirective {

  constructor(
    private ngControl: NgControl
  ) { }
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  onInputChange(event) {
    let newVal = event;
    let cleaned = ('' + newVal).replace(/\D/g, '');
    if (cleaned.length > 5) {
      cleaned = cleaned.substr(0, 5) + '-' + cleaned.substr(5);
    }
    this.ngControl.valueAccessor.writeValue(cleaned);
  }
}
