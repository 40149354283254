import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDropdown, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { ClaimService } from 'src/app/services/claim.service';
import { UserService } from 'src/app/services/user.service';
export interface amountData {
  amountType: string;
  startRange: string;
  endRange: string;
  currency: string;
}
@Component({
  selector: 'upsc-amount-search-form',
  templateUrl: './amount-search-form.component.html',
  styleUrls: ['./amount-search-form.component.scss']
})
export class AmountSearchFormComponent implements OnInit {
  @ViewChild(NgbDropdownMenu) drop: NgbDropdownMenu;
  autoClose: NgbDropdown['autoClose'] = false;
  @ViewChild('mySelect') mySelect;
  subscription: Subscription;
  amountForm: UntypedFormGroup;
  applied = false;
  valueErrors = false;
  isRangeSelected: boolean = false;
  israngeValid: boolean = false
  isCompleteRange: boolean = false;
  startRangeD: any;
  endRangeD: any;
  public currencyList = {};
  defaultCurrency = 'cad';
  Object = Object;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  defaultFormValues: any = {
    selection: 'paidAmount',
    currency: 'CAD',
    minimum: null,
    maximum: null
  };
  public currencyType;
  isCAD : boolean = true;
  isGBP : boolean = false;
  isEUR : boolean = false;
  isOpen;
  @Output() amountSearchResult = new EventEmitter<amountData>();
  constructor(public claimservice: ClaimService, 
    private eRef: ElementRef,
    public userservice: UserService, private fb: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    ) {
    this.isCanadaUser = this.userservice.isCanadaUser();
    this.isUkUser = this.userservice.isUkUser();
    this.isGermanyUser = this.userservice.isGermanyUser();
    this.isFranceUser = this.userservice.isFranceUser();
    this.isItalyUser = this.userservice.isItalyUser();
    this.isUsaUser = this.userservice.isUsaUser();
    if(this.isCanadaUser){
      this.currencyType = 'CAD';
    }
    else if(this.isUkUser){
      this.currencyType = '£';
      this.isGBP = true;
      this.isCAD = false;
      this.defaultFormValues.currency = 'GBP';
      this.defaultCurrency = 'gbp';
    }
    else if(this.isGermanyUser || this.isFranceUser || this.isItalyUser){
      this.currencyType = '€';
      this.isEUR = true;
      this.isCAD = false;
      this.defaultFormValues.currency = 'EUR';
      this.defaultCurrency = 'eur';
    }
    else {
      this.currencyType = '$';
      this.isCAD = false;
      this.defaultFormValues.currency = 'USD';
      this.defaultCurrency = 'usd';
    }
    this.amountForm = this.fb.group({
      selection: ['paidAmount', Validators.required],
      currency: [this.isCanadaUser ? 'CAD': this.isUkUser ? 'GBP': (this.isGermanyUser ||  this.isItalyUser || this.isFranceUser)? 'EUR': 'USD'],
      minimum: [, Validators.required],
      maximum: [, Validators.required],
    });
    this.subscription = this.claimservice.getResetValue().subscribe(data => {
      if (data) {
        this.reset();
      }
    });
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('retainState')){
      if(sessionStorage.getItem('isadvanced') && sessionStorage.getItem('isSearchMade')){
        let amountdata =  JSON.parse(sessionStorage.getItem('amountSearch'));
        if(amountdata){
          this.applied = true;
          this.selection.setValue(amountdata.amountType);
          this.minimum.setValue(amountdata.startRange);
          this.maximum.setValue(amountdata.endRange);
          this.startRangeD = amountdata.startRange;
          this.endRangeD = amountdata.endRange;
          const amountSearch = {
          amountType: this.selection.value,
          currency: (this.isCanadaUser || this.isGermanyUser || this.isFranceUser || this.isItalyUser) ? this.currency.value : 'usd',
          startRange: this.minimum.value,
          endRange: this.maximum.value
        }      
        sessionStorage.setItem('amountSearch', JSON.stringify(amountSearch));
        this.amountSearchResult.emit(amountSearch);
        }

      }
    }
    if(this.isCanadaUser){
    this.currencyList = {  'cad': 'CAD', 'usd': 'USD' }
    }
    else if(this.isUkUser){
      this.currencyList = {  'gbp': 'GBP', 'usd': 'USD' }
      }
    else if(this.isGermanyUser || this.isFranceUser || this.isItalyUser){
      this.currencyList = {  'eur': 'EUR', 'usd': 'USD' }
      }   
  }

  @HostListener('document:click', ['$event'])
  clickout(event) { 
    if(this.isCanadaUser){   
    this.mySelect.openedChange.subscribe(opened => {
      if (opened) {
       this.isOpen = true;
      }
      else{ this.isOpen = false;}
    })   
    if (this.eRef.nativeElement.contains(event.target)) {
    } 
    else if (event.target.outerText == "USD" || event.target.outerText == "CAD") {
    }
    else if (event.target.outerText == "GBP") {
    }
    else if (event.target.outerText == "EUR") {
    }
    else if(this.isOpen){}
    else {
      this.drop.dropdown.close();
     }
    }
    else{
      this.autoClose = true;
    }
  }
  apply() {
    const amountSearch = {
      amountType: "",
      currency: "",
      startRange: "",
      endRange: ""
    }
    
    if (this.checkVals()) {
      this.applied = true;
      this.startRangeD = this.minimum.value;
      this.endRangeD = this.maximum.value;
      const amountSearch = {
        amountType: this.selection.value,
        currency: (this.isCanadaUser || this.isUkUser || this.isGermanyUser || this.isItalyUser || this.isFranceUser) ? this.currency.value : 'usd',
        startRange: this.minimum.value,
        endRange: this.maximum.value
      }
      sessionStorage.setItem('amountSearch', JSON.stringify(amountSearch))

      this.amountSearchResult.emit(amountSearch);
      this.valueErrors = false;
      this.drop.dropdown.close();
    } else {
      this.valueErrors = true;
    }
  }
  
  ngAfterViewInit() {
    this.selection.valueChanges.subscribe(
      val => {
        if (val && val.length > 0) {
          const amountSearch = {
            amountType: this.selection.value,
            currency: (this.isCanadaUser || this.isUkUser || this.isGermanyUser || this.isItalyUser || this.isFranceUser) ? this.currency.value : 'usd',
            startRange: this.minimum.value,
            endRange: this.maximum.value
          }      
          sessionStorage.setItem('amountSearch', JSON.stringify(amountSearch));
          this.amountSearchResult.emit(amountSearch);
        }
        else {
         
        }
        this.ref.detectChanges();
      }
    );
    this.currency.valueChanges.subscribe(
      val => {
        if (val && val.length > 0) {
          if(val == 'CAD'){
            this.isCAD = true;
            this.currencyType = 'CAD';            
          }
          else if(val == 'GBP'){
            this.isGBP = true;
            this.currencyType = '£';            
          }
          else if(val == 'EUR'){
            this.isEUR = true;
            this.currencyType = '€';            
          }
          else{
            this.isEUR = false;
            this.isGBP = false;
            this.isCAD = false;
            this.currencyType = '$';
          }
          const amountSearch = {
            amountType: this.selection.value,
            currency: (this.isCanadaUser || this.isUkUser || this.isGermanyUser || this.isItalyUser || this.isFranceUser) ? this.currency.value : 'usd',
            startRange: this.minimum.value,
            endRange: this.maximum.value
          }      
          sessionStorage.setItem('amountSearch', JSON.stringify(amountSearch));
          this.amountSearchResult.emit(amountSearch);
        }
        else {
         
        }
        this.ref.detectChanges();
      }
    );
  }
  checkMinimum($event){
    if(this.maximum.touched){
      if (this.checkVals()) {
        this.startRangeD = this.minimum.value;
        this.endRangeD = this.maximum.value;
        const amountSearch = {
          amountType: this.selection.value,
          currency: (this.isCanadaUser || this.isUkUser || this.isGermanyUser || this.isItalyUser || this.isFranceUser) ? this.currency.value : 'usd',
          startRange: this.minimum.value,
          endRange: this.maximum.value
        }      
        sessionStorage.setItem('amountSearch', JSON.stringify(amountSearch));
        this.amountSearchResult.emit(amountSearch);
      }
      else{
        this.valueErrors = true;
      }
    }    
  }
  checkMaximum($event){
    if (this.checkVals()) {
      this.applied = true;
      this.startRangeD = this.minimum.value;
      this.endRangeD = this.maximum.value;
      const amountSearch = {
        amountType: this.selection.value,
        currency: (this.isCanadaUser || this.isUkUser || this.isGermanyUser || this.isItalyUser || this.isFranceUser) ? this.currency.value : 'usd',
        startRange: this.minimum.value,
        endRange: this.maximum.value
      }      
      sessionStorage.setItem('amountSearch', JSON.stringify(amountSearch));
      this.amountSearchResult.emit(amountSearch);
      this.valueErrors = false;
    } else {
      this.valueErrors = true;
    }
  }
  checkVals() {
      this.isRangeSelected = false;
      this.israngeValid = false;
      this.isCompleteRange = false;
    if(this.minimum.value == null && this.maximum.value == null){      
      this.isRangeSelected = true;
      return false;
    
    } else if(this.minimum.value == null || this.maximum.value == null){
      this.isCompleteRange = true;
      return false;

    }
    else if (this.minimum.value > this.maximum.value) {
      this.israngeValid = true;
      return false;
    }
      else {
      this.isRangeSelected = false;
      this.israngeValid = false;
      this.isCompleteRange = false;
      return true;
    }
  }

  reset() {
    this.applied = false;
    this.isRangeSelected = false;
    this.israngeValid = false;
    this.isCompleteRange = false;
    this.amountForm.reset(this.defaultFormValues);
    this.amountSearchResult.emit();
    sessionStorage.removeItem('amountSearch');
  }

  close() {
    this.valueErrors = false;
      this.isRangeSelected = false;
      this.israngeValid = false;
      this.isCompleteRange = false;
    this.drop.dropdown.close();
    this.reset();
  }

  get selection() { return this.amountForm.controls.selection; }
  get currency() { return this.amountForm.controls.currency; }
  get minimum() { return this.amountForm.controls.minimum; }
  get maximum() { return this.amountForm.controls.maximum; }
}


