
export interface Ibilling_Table {
  //Defaults at the time of original billing
  dueDate: boolean;
  invoiceNumber: boolean;
  status: boolean;
  amount: boolean;
  amountCur: boolean;
  dueAmount: boolean;
  dueAmountCur: boolean;
  invoiceBillDate: boolean;
  date: boolean;
  paymentMethod: boolean;
  paymentType: boolean;
  refNum: boolean;
  columns: number
}
export const defaultBillingTable: Ibilling_Table = {
  dueDate: true,
  invoiceNumber: true,
  status: true,
  amount: true,
  amountCur: true,
  dueAmount: true,
  dueAmountCur: true,
  invoiceBillDate: true,
  date: true,
  paymentMethod: true,
  paymentType: true,
  refNum: true,
  columns: 11
}
export interface IBillingSummary {
  policyNum?: string;
  isAsc?: boolean;
  sortColumn?: string;
  orderNum?: string;
  isInvoiced?: boolean;
  sort?: {
    dateRange?: {
      minDate?: string;
      maxDate?: string;
    },
    amountRange?: {
      isInsuredValue: boolean;
      isPremium: boolean;
      minValue?: string;
      maxValue?: string;
    },
    searchShipment?: {
      invoiceNum?: string;
      dataSource?: {
        isApi: boolean;
        isSmart: boolean;
        isWH: boolean;
      },
      claimStatus?: {
        claimStatusName: any[],
        claimSubstatusName: any[],
        resolution: any[]
      }
    }
  }
}