import { UserService } from '../../../services/user.service';
import { Component, HostBinding, OnInit } from '@angular/core';
@Component({
  selector: 'upsc-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @HostBinding('class') public hostClass = 'layout';
  isAdjuster: boolean;
  constructor(private userService: UserService) { }

  public ngOnInit() {
    this.isAdjuster = this.userService.isUserAdjuster();
  }

}
