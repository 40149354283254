<div class="backGroundHeader">
    <upsc-flex-header></upsc-flex-header>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div class="footer">
  <upsc-flex-footer></upsc-flex-footer>
</div>