<div class="wrap address-validation">
  <div class="title mb-3">
    <div>{{ 'addressvalidation.labels.title' | translate }} {{ 'addressvalidation.labels.entered' | translate }}: </div>
  </div>
  <div class="mb-3 current-address">
    <div>{{ currentEnteredAddress.address1 }}</div>
    <div>{{ currentEnteredAddress.address2 }}</div>
    <div>{{ currentEnteredAddress.city }}, {{ currentEnteredAddress.state }} {{ currentEnteredAddress.zip }}</div>
  </div>
  <div class="mb-4">
    {{ 'addressvalidation.labels.match' | translate }}
  </div>
  <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
    <div class="address-list mb-3">
      <mat-radio-group formControlName="selection">
        <div class="address" *ngFor="let address of addressList.slice(0,3)" [class.selected-border]="selection.value == addressList.indexOf(address)">
          <mat-radio-button value="{{addressList.indexOf(address)}}">
            <div>{{address.AddressLine[0]}} {{address.AddressLine[1]}}</div>
            <div>{{address.AddressLine2}}</div>
            <div>{{address.PoliticalDivision2}}, {{address.PoliticalDivision1}} {{address.PostcodePrimaryLow}}-{{address.PostcodeExtendedLow}}</div>
          </mat-radio-button>
        </div>
        <div class="address" [class.selected-border]="selection.value == '-1'">
          <mat-radio-button value="-1">
            {{ 'addressvalidation.labels.continue' | translate }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </form>
  <div class="mb-3">
    <span class="bold">{{ 'addressvalidation.labels.note' | translate }}</span>
    {{ 'addressvalidation.labels.standardized' | translate }}
  </div>
  <div class="mb-3">{{ 'addressvalidation.labels.ups' | translate }}</div>
</div>
<div class="mt-auto d-flex py-md-3 justify-content-end align-items-center">
  <a class="previous-step mr-3" (click)="edit()">{{ 'claims.edit.back' | translate }}</a>
  <button *ngIf="!loading" class="upsc-button" [ngClass]="{'disabled': !addressForm.valid}" (click)="onSubmit()">{{ 'claims.edit.continue' | translate }}</button>

  <button *ngIf="loading" class="upsc-button loading d-flex align-items-center justify-content-between">
    <div>{{ 'claims.edit.continue' | translate }}</div>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
    </div>
  </button>
</div>
