<div class="navigation-wrapper">
    <div class="isop-navigation d-flex align-items-center justify-content-between" #parent
        (mousedown)="startDragging($event, false)" (mouseup)="stopDragging($event, false)"
        (mousemove)="moveEvent($event)">
        <ul class="navigation-items">
            <li class="navigation-item">
                <a [ngClass]="{active: currentStep === 1}" (click)="togglePage('policyDetails')">{{ 'policies.polDet' |
                    translate }}</a>
            </li>
            <li class="navigation-item">
                <a [ngClass]="{active: currentStep === 2}" (click)="togglePage('commodities')">{{
                    'policies.commodity.title'
                    |
                    translate }}</a>
            </li>
            <li class="navigation-item" *ngIf="isCanadaUser || isUsaUser">
                <a [ngClass]="{active: currentStep === 3}" (click)="togglePage('coverage')">{{ 'policies.coverage.title'
                    |
                    translate }}</a>
            </li>
            <li class="navigation-item">
                <a [ngClass]="{active: currentStep === 4}" (click)="togglePage('endorsements')">{{
                    'policies.endorsements.title'
                    | translate }}</a>
            </li>
            <li class="navigation-item">
                <a [ngClass]="{active: currentStep === 5}" (click)="togglePage('documents')">{{
                    'policies.documents.title' |
                    translate }}</a>
            </li>
        </ul>
    </div>
</div>