import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, interval,BehaviorSubject, Observable, timer } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { ApiEndpoints as API } from '../../config/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class FlexAppService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private http: HttpClient) { } 

  public retrieveCbpPolicy(request: any): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CBP_RETRIEVE_POLICY}`, request, this.options)
  }

}