import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDropdown, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { TupssService } from 'src/app/pages/claims-custom/tupss/services/tupss.service';
import { ClaimLayoutsService } from 'src/app/services/claim-layouts.service';
import { ClaimService } from 'src/app/services/claim.service';
export interface claimStatusResult {
  claimSubStatus: string[];
  claimClosedOutcome: string[];
}

@Component({
  selector: 'upsc-policy-select',
  templateUrl: './policy-select.component.html',
  styleUrls: ['./policy-select.component.scss']
})
export class PolicySelectComponent {
  @ViewChild(NgbDropdownMenu) drop: NgbDropdownMenu;
  applied = false;
  valueErrors = false;
  layoutlist;
  layoutsname = new Array();
  selectedPolicyNumber;
  constructor(
    public claimservice: ClaimService,
    private tupssService: TupssService,
    private layoutService: ClaimLayoutsService
  ) {}
  ngOnInit(): void {
  }
  getUserLayouts() {
    try {
      this.layoutlist = this.tupssService.userData['contractData'];
      if (this.layoutlist) {
        this.layoutsname = [];
        for (let list of this.layoutlist) {
          this.layoutsname.push(list.contractID);
        }
      }
    } catch (e) { this.layoutsname = []; }
  }

  checkDropDown(open: boolean) {
    if (open) {
      this.getUserLayouts();
    }
  }

  //Used when user clicks
  applyThisPolicy(policyId) {
    this.selectedPolicyNumber = policyId;
    this.layoutService.policySelect = policyId;
    this.tupssService.setContractId(policyId);
  }

}
