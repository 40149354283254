import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ICategory } from 'src/app/shared/models/claim.interface';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ClaimService } from 'src/app/services/claim.service';
import { TupssService } from '../../../services/tupss.service';
import { IClaim, emptyClaim, IClaimsDetails } from 'src/app/shared/models/claim.interface';
import { LoginService } from 'src/app/services/login.service';
import { ActivatedRoute } from '@angular/router';
import { IShippingDetailsAutofill } from 'src/app/shared/models/shippingDetails.interface';
import { ReopenClaim } from 'src/app/shared/models/reopen-claim.interface';
import { BreakpointObserver } from '@angular/cdk/layout';
import { States, carriers, countries, limitedCarriers, productCategory } from 'src/config/metadata-config';
import localMetaData from 'src/assets/full-metadata.json';

@Component({
  selector: 'upsc-edit-continue-search-package',
  templateUrl: './edit-continue-search-package.component.html',
  styleUrls: ['./edit-continue-search-package.component.scss']
})
export class EditContinueSearchPackageComponent implements OnInit {
  claimNumber = '';
  @Output() isClaimSubmit = new EventEmitter<string>();

  claimDetails: IClaimsDetails = {};
  //claimResubmit: IClaim = emptyClaim;

  claim: IClaim = emptyClaim;
  public reOpenClaim: ReopenClaim = {};

  whatHappenedFormGroup: UntypedFormGroup;

  isGuestUser: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;

  public maxDate = new Date();
  public categoryList = [];

  public countryList = [];
  public stateList: any[] = States; //We never pull list of states from metadata API, only comes from config file
  public currencyList = {};
  public carrierList: [] | {} = [];

  public repairables: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.reason.yes') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.reason.no') },
  ];
  public damagedLocations: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.one') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.two') },
    { id: 2, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.three') }
  ];

  public listCurrency: any[] = [
    { key: 'usd', value: 'USD' }
  ];



  //Persisted Data used throughout claims process
  persistIShipData;
  public persistClaimNumber: string = "tempClaimNumber";
  public persistPublicId: string = "";
  public persistExpirationDate: Date; //These dates handle what dates a user is allowed to select from based on their policy number
  public persistEffectiveDate: Date;
  persistPayeeInfo;
  mailingInfo = {
    "insured": "",
    "streetAddress1": "",
    "streetAddress2": "",
    "city": "",
    "state": "",
    "zip": "",
    "country": ""
  }
  //Default values (Remove this? [(value)] not supported in Angular 7+ ?)
  defaultCurrency = 'USD';

  //Notification booleans
  errorNotification: boolean = false;
  hideAdditionalCoverageWarning: boolean = false;
  hideDeclaredWarning = true;
  hideNoDeclaredWarning = true;
  showACHError = false;
  isPageLoad: boolean = false;

  tupssType: string; //will be tfcp or tccp

  constructor(private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private claimService: ClaimService,
    private tupssService: TupssService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver) {


    this.route.queryParams.subscribe(params => {
      this.claimNumber = params.claimNumber;
    });



    this.userDetails = this.userService.getUserInfo();

    this.isGuestUser = this.userService.isGuestUser();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();

    this.maxDate.setDate(this.maxDate.getDate());

    // this.getCurrency();

    this.isPageLoad = true;

    if (this.tupssService.userDetails && this.tupssService.userDetails.type) {
      this.tupssType = this.tupssService.userDetails.type;
    } else {
      this.tupssType = 'tccp'; //temp hardcode
    }

  }

  currencyType: string;
  sgDocuments = []; //System-generated documents
  uuDocuments = []; //User-uploaded documents
  statusDetails = '';
  claimPaymentRecipientShow: boolean = true;
  shippingDetailsDto: any = {};
  mainContact: any = {};

  ngOnInit(): void {
    this.getMetaData();
    this.getClaimDetails();
    this.whatHappenedFormGroup = this.formBuilder.group({
      // reason: ['', Validators.compose([Validators.required])],
      // category: [{ value: this.claim.productCategory_Ext, disabled: true }, Validators.compose([Validators.required])],
      // quantity: [{ value: this.claim.quantity, disabled: true }, Validators.compose([Validators.required, Validators.pattern(/^[1-9]\d*$/)])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(250)])],
      // currency: [{ value: 'USD', disabled: true }, Validators.compose([Validators.required])],
      // merchandise: [this.claim.shippingDetailsDTO.merchandiseValue, Validators.compose([Validators.required])],
      // shipping: [{ value: this.claimService.formatAmount(this.claim.shippingDetailsDTO.verifiedShippingCharge, false), disabled: true }, Validators.compose([Validators.required])],
      // amount: [{ value: this.claimService.formatAmount(this.claim.claimAmount_Ext, false), disabled: true }, Validators.compose([Validators.required])],
      // lossCare: ['',],
      // lossReship: ['',],
      // lossRefund: ['',],
      // damageDescription: ['', Validators.maxLength(800)],
      // damageDate: [''],
      // damageRepairable: [''],
      // damageLocations: [''],
      // damageOther: ['', Validators.maxLength(35)],
      // missingDescription: ['', Validators.maxLength(800)],
      // missingDate: [''],
      // missingLocations: [''],
      // missingOther: ['', Validators.maxLength(35)],
      // lateCare: ['',],
      // lateReship: ['',],
      // lateRefund: ['',],
      // documents: ['',],
      // additionalComments: [this.claim.customerNotes, Validators.maxLength(250)],
      // purpose: [this.claim.purposeOfShipment, Validators.compose([Validators.required])],
      // purposeOther: [''],
      // originalTrackingNumber: ['']
    });

    if (this.claim.lossCause == 'damage_Ext') {
      this.damage = true;
      this.whatHappenedFormGroup.controls.reason.setValue(1);
      this.whatHappenedFormGroup.controls.damageDate.setValue('this.claim.lossDate');
    }
    else if (this.claim.lossCause == 'fallout_Ext') {
      this.missing = true;
      this.whatHappenedFormGroup.controls.reason.setValue(2);
      this.whatHappenedFormGroup.controls.missingDate.setValue('this.claim.lossDate');
    }

    //Customs validations based on valuechanges
    /* this.whatHappenedFormGroup.controls['category'].valueChanges.subscribe(val => {
      this.whatHappenedFormGroup.controls['category'].setErrors({ 'invalid': true });
      if (typeof val === 'object') { //selected from the list
        this.whatHappenedFormGroup.controls['category'].setErrors(null);
      }
      else { //typed in
        for (let cat of this.categoryList) {
          if (cat.value.toLowerCase() === val.toLowerCase()) {
            if (cat.value !== val) {
              this.whatHappenedFormGroup.controls['category'].setValue(cat);
            }
            this.whatHappenedFormGroup.controls['category'].setErrors(null);
            break;
          }
        }
      }
    }); */
    //this.monitorValueChanged();
    //When user starts typing, filteredCategories will populate.
    /* this.filteredCategories = this.whatHappenedFormGroup.get('category').valueChanges
      .pipe(
        startWith<string | ICategory>(''),
        map(value => typeof value === 'string' ? value : value.key),
        map(name => name ? this.filter(name) : this.categoryList.slice())
      );
    this.checkLists();*/
    this.persistIShipData = JSON.parse(sessionStorage.getItem('persistIShipData'));
  }

  getClaimDetails() {
    this.claim = JSON.parse(sessionStorage.getItem('claimDetails'));
    this.shippingDetailsDto = this.claim.shippingDetailsDTO;
  }


  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  //Handles the "Other" text field in damage and missing flows
  // public toggleOther(type: boolean) {
  //   this.other = false;
  //   if (type) {
  //     this.other = true;
  //   }
  // }

  userDetails: any;

  //If Merchandise or Shipping inputs are updated, total amount must be too
  // private monitorValueChanged() {
  //   this.whatHappenedFormGroup.get('merchandise').valueChanges.subscribe(
  //     (value) => {
  //       if (!value) {
  //         return;
  //       }
  //       if (isNaN(value)) {
  //         this.whatHappenedFormGroup.get('amount').setValue(0);
  //         return;
  //       }
  //       this.whatHappenedFormGroup.get('amount').setValue((+this.claimService.formatAmount(this.whatHappenedFormGroup.get('merchandise').value, false) + +this.claimService.formatAmount(this.whatHappenedFormGroup.get('shipping').value, false)).toFixed(2));
  //     }
  //   );

  //   this.whatHappenedFormGroup.get('shipping').valueChanges.subscribe(
  //     (value) => {
  //       if (!value) {
  //         return;
  //       }
  //       if (isNaN(value)) {
  //         this.whatHappenedFormGroup.get('amount').setValue(0);
  //         return;
  //       }
  //       this.whatHappenedFormGroup.get('amount').setValue((+this.claimService.formatAmount(this.whatHappenedFormGroup.get('shipping').value, false) + +this.claimService.formatAmount(this.whatHappenedFormGroup.get('merchandise').value, false)).toFixed(2));
  //     }
  //   );
  // }

  //Handles notification appearing near the merchandise and shipping boxes
  // checkDV() {

  //   let merchandise: Number = 0;
  //   let declaredValue: Number = 0;
  //   try {
  //     // merchandise = parseFloat(this.claimService.formatAmount(this.claim.shippingDetailsDTO.merchandiseValue, false));
  //     merchandise = parseFloat(this.claimService.formatAmount(this.whatHappenedFormGroup.get('merchandise').value, false));
  //     declaredValue = parseFloat(this.claimService.formatAmount(this.claim.shippingDetailsDTO.declaredValueMonetaryValue, false));
  //   }
  //   catch {
  //     declaredValue = 0;
  //   }

  //   if (!declaredValue || declaredValue == 0) {
  //     this.hideNoDeclaredWarning = false;
  //   } else {
  //     this.hideNoDeclaredWarning = true;
  //   }
  //   if (merchandise > declaredValue) {
  //     this.hideDeclaredWarning = false;
  //   } else {
  //     this.hideDeclaredWarning = true;
  //   }
  // }

  loopCounter = 0;

  //Scenarios
  public loss: boolean = false;
  public damage: boolean = false;
  public missing: boolean = false;
  public late: boolean = false;
  public other: boolean = false;
  public cargo: boolean = false;
  public flex: boolean = false;
  public ups: boolean = false;
  public istnt: boolean = false;


  //contact selections
  public policyHolder: boolean = false;
  public recipient: boolean = false;
  public custom: boolean = false;
  public filteredCategories: Observable<ICategory[]>;

  //Switch through the different flows
  // public toggleFlow(type: string) {
  //   this.loss = false;
  //   this.damage = false;
  //   this.missing = false;
  //   this.late = false;
  //   this.policyHolder = false;
  //   this.custom = false;
  //   this.recipient = false;
  //   if (type == "loss") {
  //     this.loss = true;
  //   } else if (type == "damage") {
  //     this.damage = true;
  //     this.whatHappenedFormGroup.controls.damageDescription.setValidators(Validators.required);
  //     this.whatHappenedFormGroup.controls.damageDate.setValidators(Validators.required);
  //     this.whatHappenedFormGroup.controls.damageRepairable.setValidators(Validators.required);
  //     this.whatHappenedFormGroup.controls.damageLocations.setValidators(Validators.required);
  //     this.whatHappenedFormGroup.controls.damageDate.setValue(this.claim.lossDate);

  //     this.whatHappenedFormGroup.controls.missingDescription.clearValidators();
  //     this.whatHappenedFormGroup.controls.missingDescription.updateValueAndValidity();
  //     this.whatHappenedFormGroup.controls.missingDate.clearValidators();
  //     this.whatHappenedFormGroup.controls.missingDate.updateValueAndValidity();
  //     this.whatHappenedFormGroup.controls.missingLocations.clearValidators();
  //     this.whatHappenedFormGroup.controls.missingLocations.updateValueAndValidity();
  //   } else if (type == "missing") {
  //     this.missing = true;
  //     this.whatHappenedFormGroup.controls.missingDescription.setValidators(Validators.required);
  //     this.whatHappenedFormGroup.controls.missingDate.setValidators(Validators.required);
  //     this.whatHappenedFormGroup.controls.missingLocations.setValidators(Validators.required);
  //     this.whatHappenedFormGroup.controls.missingDate.setValue(this.claim.lossDate);

  //     this.whatHappenedFormGroup.controls.damageDescription.clearValidators();
  //     this.whatHappenedFormGroup.controls.damageDescription.updateValueAndValidity();
  //     this.whatHappenedFormGroup.controls.damageDate.clearValidators();
  //     this.whatHappenedFormGroup.controls.damageDate.updateValueAndValidity();
  //     this.whatHappenedFormGroup.controls.damageRepairable.clearValidators();
  //     this.whatHappenedFormGroup.controls.damageRepairable.updateValueAndValidity();
  //     this.whatHappenedFormGroup.controls.damageLocations.clearValidators();
  //     this.whatHappenedFormGroup.controls.damageLocations.updateValueAndValidity();
  //   } else if (type == "late") {
  //     this.late = true;
  //   }
  // }

  //Sorts an array alphabetically
  //If needing case insensitive, add .toUpperCase()
  //To use: this.someArray.sort(this.compare);
  public compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }

  //Loads all MetaData for claims flow
  public getMetaData() {
    let data = localMetaData;
    // this.claimService.getMetaData().subscribe(
    //  data => { 
    try {
      this.countryList = Object.entries(data.metaData.country).map(([key, value]) => ({ key, value }));
      this.countryList.sort(this.compare);

      //Remove Miscellaneous data from productCategory
      if (Object.keys(data.metaData.productCategory).find(x => x === 'miscellaneous_other')) {
        delete data.metaData.productCategory['miscellaneous_other'];
      }

      if (Object.keys(data.metaData.productCategory).find(x => x === 'miscellaneous_personal')) {
        delete data.metaData.productCategory['miscellaneous_personal'];
      }

      this.categoryList = Object.entries(data.metaData.productCategory).map(([key, value]) => ({ key, value }));

      if (Object.keys(data.metaData.transportationid_Ext).find(x => x === 'UPS Freight')) { //UPS Freight needs to be removed from the carrier list
        delete data.metaData.transportationid_Ext['UPS Freight'];
      }
      //Load certain carriers based on if the policy is Cargo or Flex
      if (this.flex) {
        this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
      } else {
        this.carrierList = data.metaData.transportationid_Ext;
      }
    } catch (e) {
      //Load local metadata
      this.loadLocalMetaData();
    }
    /*  }, error => {
       // Load local metadata
       this.loadLocalMetaData();
     }
   ); */
  }

  loadLocalMetaData() {
    this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });//translated countries
    this.countryList.sort(this.compare);
    //this.currencyList = { 'usd': 'USD', 'cad': 'CAD', 'eur': 'EUR', 'gbp': 'GBP' }
    this.categoryList = Object.entries(productCategory).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.productCategory.${key}`) } }); //translated categories
    //Load certain carriers based on if the policy is Cargo or Flex
    if (this.flex) {
      this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    } else {
      this.carrierList = Object.fromEntries(Object.entries(carriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    }
  }
  cancel() {
    this.isClaimSubmit.emit('0');
  }

  continuing: boolean = false;
  isError: boolean = false;

  //Everytime user clicks Continue button, saveClaim is called
  public saveClaim() {
    if (this.whatHappenedFormGroup.valid) {
      this.isError = false;
      this.reOpenClaim.policyNumber = this.userService.getUserInfo().policyNumber;
      this.reOpenClaim.claimNumber = this.claim.claimNumber;
      this.reOpenClaim.claimPublicId = this.claim.publicID;
      this.reOpenClaim.customerNote = "Reopen a claim";
      this.reOpenClaim.reason = "newinfo";

      /********************************************/
      this.claimService.reopenClaim(this.reOpenClaim).subscribe(
        reopenClaimResponse => {
          /********************************************/
          if (reopenClaimResponse.code === "500" || reopenClaimResponse.code === "200") {
            this.continuing = false;
          }
          else {

            this.continuing = true;
            this.errorNotification = false;

            this.claim.lossType = "PR";
            this.claim.lossCause = "loss_Ext";
            if (this.claimDetails.carrier == 'UPS') {
              this.ups = true;
            } else {
              this.ups = false;
            }
            this.gatherFormValues();
            this.claimService.saveClaim(this.claim).subscribe(
              saveClaimResponse => {
                if (saveClaimResponse.code === "500" || saveClaimResponse.code === "200") {
                  this.continuing = false;
                }
                else {
                  this.isClaimSubmit.emit('1');
                }
              }, error => {
                this.continuing = false;
                this.isClaimSubmit.emit('0');
              }
            );
          }
          /********************************************/
        }, errorReopen => {
          this.isClaimSubmit.emit('0');
        }
      );
      /********************************************/
      return true;
    } else {
      this.validateAllFormFields(this.whatHappenedFormGroup);
      this.isError = true;
      this.errorNotification = true;
      return false;
    }
  }

  //Used to manually check all fields & show errors
  validateAllFormFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  public track3ShipmentDetails: IShippingDetailsAutofill = {};
  consigneeAddress: any = '';
  // trackingNumber = this.userService.getUserInfo().trackingNumber.toUpperCase();
  get damageDescription() { return this.whatHappenedFormGroup.controls.damageDescription };
  get missingDescription() { return this.whatHappenedFormGroup.controls.missingDescription };

  senderStateDisplay = '';
  recipientStateDisplay = '';
  public currencyKey = 'usd';
  repairableDisplay = '';
  locationDisplay = '';
  gatherFormValues() {
    //WhatHappenedFormGroup
    if (this.whatHappenedFormGroup.valid) {
      // if (this.whatHappenedFormGroup != null) {
      //   if (this.whatHappenedFormGroup.get('reason').value == 0) {
      //     this.claim.lossCause = "loss_Ext";
      //   } else if (this.whatHappenedFormGroup.get('reason').value == 1) {
      //     this.claim.lossCause = "damage_Ext";
      //   } else if (this.whatHappenedFormGroup.get('reason').value == 2) {
      //     this.claim.lossCause = "fallout_Ext";
      //   } else if (this.whatHappenedFormGroup.get('reason').value == 3) {
      //     this.claim.lossCause = "delay";
      //   }
      // }
      this.claim.lossCause = "loss_Ext";
      // this.claim.purposeOfShipment = this.whatHappenedFormGroup.controls.purpose.value;
      this.claim.productCategory_Ext = this.claim.productCategory_Ext;
      this.claim.shippingDetailsDTO.merchandiseDescription = "Description of merchandise in detail = " + this.whatHappenedFormGroup.value.description;
      this.claim.upsmerchandise_Ext = " ";
      this.claim.shippingDetailsDTO.insuredValueCurrency = this.currencyKey;
      this.claim.shippingDetailsDTO.merchandiseValue = this.claimService.formatAmount(this.claim.shippingDetailsDTO.merchandiseValue, false);
      this.claim.shippingDetailsDTO.verifiedShippingCharge = this.claimService.formatAmount(this.claim.shippingDetailsDTO.verifiedShippingCharge, false);
      this.claim.shippingDetailsDTO.insuredAmount = this.claimService.formatAmount(this.claim.claimAmount_Ext, false);
      this.claim.claimAmount_Ext = this.claimService.formatAmount(this.claim.claimAmount_Ext, false);
      this.claim.additionalNotes = "";
      this.claim.customerNotes = " ";
      //this.claim.quantity = this.whatHappenedFormGroup.value.quantity;
      this.claim.lossDescription = "";
      this.claim.reshipTrackingNumber = "";
      this.claim.creditMemoRefundNo = "";
      this.claim.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
    }
  }
}
