<div class="main d-flex flex-column">
      <div class="d-flex flex-row-reverse"><mat-icon (click)="cancel()">close</mat-icon></div>
      <div class="content">
        <h3 mat-dialog-title>
          {{ 'app.dialog.jpmc_error_message1' | translate }}
        </h3>
        <div>
            {{ 'app.dialog.jpmc_error_message2' | translate }}
        </div>
      </div>
    </div>