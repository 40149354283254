import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
//import { IdentityLayoutComponent } from '../../shared/components/identity-layout/identity-layout.component';
import { LoginLayoutComponent } from 'src/app/shared/components/login-layout/login-layout/login-layout.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: LoginLayoutComponent,
      children: [
        {
          path: '',
          component: LoginComponent
        }
      ]
    },
  ])],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
