import { Type } from "@angular/core";

export interface IPolicy {
  account: Account
  validationStatus: string
  emailRequest: EmailRequest

}

export interface Account {
  contactPerson: string
  accountHolderContact: AccountHolderContact
  policy: Policy
  producerCode: string
  uid: string
}

export interface AccountHolderContact {
  address: Address
  name: string
  workPhone: string
  emailAddress1: string
}

export interface Address {
  addressLine1: string
  addressLine2: string
  city: string
  state: State
  postalCode: string
  country: Country
}

export interface State {
  code: string
}

export interface Country {
  code: string
}

export interface Policy {
  recipientName: string
  masterCustomerID: string
  technologyAgreement: boolean
  informationDeclaration: boolean
  usedAgreement: boolean
  policyNumber: string
  onlineQuoteRequestUID: string
  merchantOrderID: string
  premium: number
  tax: number
  onDemand: OnDemand
  requestedPaymentPlan: string
  uwcompany: string
  paymentAmount: number
  termType: TermType
  roleAssignments: RoleAssignment[]
}

export interface OnDemand {
  oneTimeShipments: OneTimeShipment[]
  coverages: Coverage[]
}

export interface OneTimeShipment {
  dateOfShipment: string
  typeOfShipment: TypeOfShipment
  shipmentMode: ShipmentMode
  commodityCategory: CommodityCategory
  transportationCarrier: TransportationCarrier
  goodsBeingShipped: string
  specialConditions: string
  originCompany: string
  originAddress: OriginAddress
  destinationCompany: string
  destinationAddress: DestinationAddress
  conditionofCommodity: ConditionofCommodity
  valueOfShipment: number
  premium: number
  deductible: number
}

export interface TypeOfShipment {
  code: string
}

export interface ShipmentMode {
  code: string
}

export interface CommodityCategory {
  code: string
}

export interface TransportationCarrier {
  code: string
}

export interface OriginAddress {
  addressLine1: string
  addressLine2: string
  city: string
  state: State
  postalCode: string
  country: Country
}

export interface DestinationAddress {
  addressLine1: string
  addressLine2: string
  city: string
  state: State
  postalCode: string
  country: Country
}

export interface ConditionofCommodity {
  code: string
}

export interface Coverage {
  codeIdentifier: string
  terms: Term[]
}

export interface Term {
  codeIdentifier: string
  value: string
}

export interface TermType {
  code: string
}

export interface RoleAssignment {
  assignedRole: string
  user: User
}

export interface User {
  userName: string
  employeeNumber: string
}

export interface EmailRequest {
  applicationName: string
  emailFor: string
  environment: string
  data: Data
}

export interface Data {
  contactInfo: ContactInfo
  insuredAmtInWord: string
  insuredAmt: string
  totalPremium: string
  coiNumber: string
  transactionDate: string
  companyName: string
  policyNumber: string
  commodity: string
  shipmentType: string
  shipmentMode: string
  shipDate: string
  deductible: string
  merchandiseDesc: string
  shipperAddress: ShipperAddress
  shipToAddress: ShipToAddress
  uID: string
}

export interface ContactInfo {
  contactName: string
  phoneNumber: string
  email: string
}

export interface ShipperAddress {
  addressLine1: string
  addressLine2: string
  city: string
  zipCode: string
  state: string
  country: string
}

export interface ShipToAddress {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  country: string
  zipCode: string
}


export const policyData = {
  account: {
    contactPerson: "Siva",
    accountHolderContact: {
      address: {
        addressLine1: "12323 RINGLE RD",
        addressLine2: "",
        city: "ATLANTA",
        state: {
          "code": "GA"
        },
        postalCode: "30341-4714",
        country: {
          "code": "US"
        }
      },
      name: "Cognizant Technology Solution",
      workPhone: "902-382-3012",
      emailAddress1: "sannathurai@ups.com"
    },
    policy: {
      recipientName: "QA",
      masterCustomerID: "",
      technologyAgreement: true,
      informationDeclaration: true,
      usedAgreement: false,
      policyNumber: "15914024-OD200682643",
      onlineQuoteRequestUID: "15914024-OD200682643",
      merchantOrderID: "200682622",
      premium: 45,
      tax: 0,
      onDemand: {
        oneTimeShipments: [
          {
            commodityCategory: {
              "code": "GeneralMerchandise"
            },
            conditionofCommodity: {
              code: "new"
            },
            dateOfShipment: "",
            deductible: 0,
            typeOfShipment: {
              "code": "Freight"
            },
            shipmentMode: {
              "code": "Air"
            },
            
            transportationCarrier: {
              code: "ups"
            },
            goodsBeingShipped: "",
            specialConditions: "",
            originCompany: "Cognizant Technology Solution",
            originAddress: {
              addressLine1: "12323 RINGLE RD",
              addressLine2: "",
              city: "ATLANTA",
              state: {
                code: "GA"
              },
              postalCode: "30341-4714",
              country: {
                code: "US"
              }
            },
            destinationCompany: "QA",
            destinationAddress: {
              addressLine1: "add1",
              addressLine2: "",
              city: "Seattle",
              state: {
                code: "AR"
              },
              postalCode: "13233",
              country: {
                code: "US"
              }
            },
            valueOfShipment: 900,
            premium: 0
          }
        ],
        coverages: [
          {
            codeIdentifier: "UPSOnDemandCov",
            terms: [
              {
                codeIdentifier: "OnDemandLimit",
                value: "900"
              },
              {
                codeIdentifier: "OnDemandProvision",
                value: "AllRisks"
              },
              {
                codeIdentifier: "OnDemandDeductible",
                value: "500.00"
              }
            ]
          }
        ]
      },
      requestedPaymentPlan: "Annual Installments",
      uwcompany: "",
      paymentAmount: 45,
      termType: {
        code: "Annual"
      },
      roleAssignments: [
        {
          assignedRole: "Underwriter",
          user: {
            userName: "RJC5VHG",
            employeeNumber: "5938334"
          }
        }
      ]
    },
    producerCode: "OnDemand",
    uid: "76E513CFA36998CA93C155DE7F692BB2"
  },
  validationStatus: "Validated",
  emailRequest: {
    applicationName: "HousePolicy",
    emailFor: "COIEmail",
    environment: "QA",
    data: {
      contactInfo: {
        contactName: "Siva",
        phoneNumber: "902-382-3012",
        email: "sannathurai@ups.com"
      },
      insuredAmtInWord: "Nine Hundred 00 / 100",
      insuredAmt: "900",
      totalPremium: "45",
      coiNumber: "200682622",
      transactionDate: "2023-09-18",
      companyName: "Cognizant Technology Solution",
      policyNumber: "15914024-OD200682642",
      commodity: "GeneralMerchandise",
      shipmentType: "Freight",
      shipmentMode: "Air",
      shipDate: "2023-09-18",
      deductible: "$500.00",
      merchandiseDesc: " ",
      shipperAddress: {
        addressLine1: "12323 RINGLE RD",
        addressLine2: "",
        city: "ATLANTA",
        zipCode: "30341-4714",
        state: "GA",
        country: "United States of America"
      },
      shipToAddress: {
        addressLine1: '',
        addressLine2: '',
        city: "Seattle",
        state: "AR",
        country: "United States of America",
        zipCode: "13233"
      },
      uID: "76E513CFA36998CA93C155DE7F692BB2"
    }
  }
}


export const emptyPolicyData = {
  account: {
    contactPerson: "",
    accountHolderContact: {
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: {
          code: ""
        },
        postalCode: "",
        country: {
          code: ""
        }
      },
      name: "",
      workPhone: "",
      emailAddress1: ""
    },
    policy: {
      recipientName: "",
      masterCustomerID: "",
      technologyAgreement: true,
      informationDeclaration: true,
      usedAgreement: false,
      policyNumber: "",
      onlineQuoteRequestUID: "",
      merchantOrderID: "",
      premium: 0,
      tax: 0,
      onDemand: {
        oneTimeShipments: [
          {
            dateOfShipment: "",
            typeOfShipment: {
              code: "Small Package"
            },
            shipmentMode: {
              code: "Ground"
            },
            commodityCategory: {
              code: "GeneralMerchandise"
            },
            transportationCarrier: {
              code: "ups"
            },
            conditionofCommodity: {
              code: "new"
            },            
            goodsBeingShipped: "",
            specialConditions: "",
            originCompany: "",
            originAddress: {
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: {
                code: ""
              },
              postalCode: "",
              country: {
                code: ""
              }
            },
            destinationCompany: "",
            destinationAddress: {
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: {
                code: ""
              },
              postalCode: "",
              country: {
                code: ""
              }
            },
            valueOfShipment: 900,
            premium: 0,
            deductible: 0
          }
        ],
        coverages: [
          {
            codeIdentifier: "UPSOnDemandCov",
            terms: [
              {
                codeIdentifier: "OnDemandLimit",
                value: "500"
              },
              {
                codeIdentifier: "OnDemandProvision",
                value: "AllRisks"
              },
              {
                codeIdentifier: "OnDemandDeductible",
                value: "0"
              }
            ]
          }
        ]
      },
      requestedPaymentPlan: "",
      uwcompany: "",
      paymentAmount: 0,
      policyPeriodSource: {
        code: "MyChoice_Ext"
      },
      termType: {
        code: ""
      },
      roleAssignments: [
        {
          assignedRole: "",
          user: {
            userName: "",
            employeeNumber: ""
          }
        }
      ]
    },
    producerCode: "",
    uid: ""
  },
  validationStatus: "",
  emailRequest: {
    applicationName: "",
    emailFor: "",
    environment: "QA",
    data: {
      contactInfo: {
        contactName: "",
        phoneNumber: "",
        email: "s"
      },
      insuredAmtInWord: "",
      insuredAmt: "",
      totalPremium: "",
      coiNumber: "",
      transactionDate: "",
      companyName: "",
      policyNumber: "",
      commodity: "",
      shipmentType: "",
      shipmentMode: "",
      shipDate: "",
      deductible: "",
      merchandiseDesc: " ",
      shipperAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipCode: "",
        state: "",
        country: ""
      },
      shipToAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        zipCode: ""
      },
      uID: ""
    }
  }
}


