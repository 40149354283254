import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProspectPortalComponent } from './prospect-portal.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { ShipperComponent } from './shipper/shipper.component';
import { UnderReviewComponent } from './under-review/under-review.component';
import { LoginLayoutComponent } from 'src/app/shared/components/login-layout/login-layout/login-layout.component';
import { ReviewComponent } from './submit/submit.component';
import { StartComponent } from './start/start.component';
import { QuoteComponent } from './quote/quote.component';
import { SuccessComponent } from './success/success.component';
import { DeclineComponent } from './decline/decline.component';


const routes: Routes = [
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        component: StartComponent
      },
      {
        path: 'under-review',
        component: UnderReviewComponent
      },
      {
        path: 'shipping',
        component: ShipperComponent
      },
      {
        path: 'products',
        component: ProductSelectionComponent
      },
      {
        path: 'review',
        component: ReviewComponent
      },
      {
        path: 'quote',
        component: QuoteComponent
      },
      {
        path: 'success',
        component: SuccessComponent
      },
      {
        path: 'decline',
        component: DeclineComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProspectPortalRoutingModule { }
