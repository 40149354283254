import { Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'upsc-adjustor-dashboard',
  templateUrl: './adjuster-dashboard.component.html',
  styleUrls: ['./adjuster-dashboard.component.scss']
})
export class AdjusterDashboardComponent implements OnInit {
  constructor(private titleService: Title,
    private translateService: TranslateService) {

  }
  public ngOnInit() {
    this.titleService.setTitle(this.translateService.instant('app.Settitle.ups_capital_adjuster'));
  }
}

