import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../../shared/components/layout/layout.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { StepperModule } from '../../shared/components/stepper/stepper.module';
import { ProfileComponent } from './profile.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ProfileRoutingModule } from './profile.routing.module';
import { AuthService } from '../../core/services/auth.service';
import { AuthGuardService } from '../../core/services/auth-guard.service';
import { SecuritySettingsComponent } from './security-settings/security-settings.component';
import { CompanyComponent } from './company/company.component';
import { UpdateComponent } from './update/update.component';
import { CollapsiblePanelModule } from 'src/app/shared/components/collapsible-panel/collapsible-panel.module';
import { CollapsiblePanelDarkModule } from 'src/app/shared/components/collapsible-panel-dark/collapsible-panel-dark.module';
import { NotificationBoxModule } from 'src/app/shared/components/notification-box/notification-box.module';
import { ContactComponent } from './contact/contact.component';
import { AddressChangeConfirmationComponent } from './dialog/address-change-confirmation/address-change-confirmation.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    MatStepperModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    StepperModule,
    ProfileRoutingModule,
    CollapsiblePanelModule,
    CollapsiblePanelDarkModule,
    NotificationBoxModule,
    MatDialogModule,
  ],
  declarations: [ProfileComponent, UserProfileComponent, SecuritySettingsComponent, CompanyComponent, UpdateComponent, ContactComponent, AddressChangeConfirmationComponent],
  providers: [AuthGuardService, AuthService ],
})
export class ProfileModule { }
