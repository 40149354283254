<div class="claim-detail-update-payee-popup d-flex flex-column">
  <mat-icon class="close" (click)="close()">close</mat-icon>
  <form [formGroup]="contactInfoFormGroup" *ngIf="showUpdatePayeeInfo" class="p4">
    <div class="white-on-gray">
      <div class="title-block">
        <div class="title">{{ 'claims.edit.tupssPayee.paymentMethod' | translate }}
          <br />
        </div>
      </div>

      <div class="title-block">
        <div class="subtitle mb-3 format">
          {{ 'claims.edit.tupssPayee.how' | translate }}
          <hr />
          <mat-radio-group formControlName="ach" class="d-flex flex-column">
            <mat-radio-button [value]="'ach'">{{ 'claims.edit.tupssPayee.direct' | translate }}</mat-radio-button>
            <mat-radio-button [value]="'check'">{{ 'claims.edit.tupssPayee.check' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="title-block">
        <div class="title">{{ 'payee.table.update' | translate }}</div>
        <div class="subtitle mt-3">{{ 'claims.edit.subtitleStep1' | translate }}</div>
        <hr />
      </div>

      <div class="panel-section" [formGroup]="contactInfoFormGroup">
        <div class="section">
          <div class="subsection d-flex flex-wrap justify-content-between">
            <div class="cluster full">
              <div class="title">{{ 'claims.edit.claimrecipient.company' | translate }}</div>
              <mat-form-field appearance="outline">
                <input matInput formControlName="name" maxlength="60">
                <mat-error *ngIf="contactInfoFormGroup.get('name').errors">
                  <div *ngIf="contactInfoFormGroup.get('name').errors['required']">
                    {{ 'claims.edit.claimrecipient.errors.name' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="cluster">
              <div class="title">{{ 'claims.edit.claimrecipient.email' | translate }}</div>
              <mat-form-field class="width-100" appearance="outline">
                <input matInput formControlName="email">
                <mat-error *ngIf="contactInfoFormGroup.get('email').errors">
                  <div *ngIf="contactInfoFormGroup.get('email').errors['required']">
                    {{ 'claims.edit.claimrecipient.errors.email' | translate }}
                  </div>
                  <div *ngIf="contactInfoFormGroup.get('email').errors['email']">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valid' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="cluster">
              <div class="title">{{ 'claims.edit.claimrecipient.phoneNum' | translate }}</div>
              <mat-form-field class="width-100" appearance="outline">
                <input matInput formControlName="phone" upscPhoneNumber maxlength="12" minlength="12">
                <mat-error *ngIf="contactInfoFormGroup.get('phone').errors">
                  <div *ngIf="contactInfoFormGroup.get('phone').errors['required']">
                    {{ 'claims.edit.claimrecipient.errors.phn' | translate }}
                  </div>
                  <div
                    *ngIf="contactInfoFormGroup.get('phone').errors['pattern'] || contactInfoFormGroup.get('phone').errors['minlength']">
                    {{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.invalid' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="cluster">
              <div class="title">{{ 'claims.edit.claimrecipient.address1' | translate }}</div>
              <mat-form-field class="width-100" appearance="outline">
                <input matInput formControlName="addressLine1" maxlength="60">
                <mat-error *ngIf="contactInfoFormGroup.get('addressLine1').errors">
                  <div *ngIf="contactInfoFormGroup.get('addressLine1').errors['required']">
                    {{ 'claims.edit.claimrecipient.errors.addr1' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="cluster">
              <div class="title">{{ 'claims.edit.claimrecipient.address2' | translate }}</div>
              <mat-form-field class="width-100" appearance="outline">
                <input matInput formControlName="addressLine2" maxlength="60">
              </mat-form-field>
            </div>
            <div class="cluster">
              <div class="title">{{ 'claims.edit.claimrecipient.city' | translate }}</div>
              <mat-form-field class="width-100" appearance="outline">
                <input matInput formControlName="city" maxlength="60">
                <mat-error *ngIf="contactInfoFormGroup.get('city').errors">
                  <div *ngIf="contactInfoFormGroup.get('city').errors['required']">
                    {{ 'claims.edit.claimrecipient.errors.city' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="cluster">
              <div class="title">{{ 'claims.edit.claimrecipient.state' | translate }}</div>
              <mat-form-field class="width-100" appearance="outline">
                <mat-select formControlName="state">
                  <mat-option *ngFor="let state of stateList" [value]="state.state">{{state.state}}</mat-option>
                </mat-select>
                <mat-error *ngIf="contactInfoFormGroup.get('state').errors">
                  <div *ngIf="contactInfoFormGroup.get('state').errors['required']">
                    {{ 'claims.edit.claimrecipient.errors.state' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="cluster">
              <div class="title">{{ 'claims.edit.claimrecipient.zip' | translate }}</div>
              <mat-form-field class="width-100" appearance="outline">
                <input matInput upscZipcode formControlName="zip" maxlength="10">
                <mat-error *ngIf="contactInfoFormGroup.get('zip').errors">
                  <div *ngIf="contactInfoFormGroup.get('zip').errors['required']">
                    {{ 'claims.edit.claimrecipient.errors.zip' | translate }}
                  </div>
                  <div *ngIf="contactInfoFormGroup.get('zip').errors['pattern']">
                    {{ 'claims.edit.claimrecipient.errors.zipValid' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="step-controls">
        <div>
          <a class="reset-step" (click)="close()">
            {{ 'claims.edit.cancel' | translate }}
          </a>
        </div>
        <div>
          <button class="next-step" role="button" type="button" (click)="addressValidation($event)" *ngIf="!continuing">
            {{ 'claims.edit.continue' | translate }}
          </button>
          <button class="next-step loading d-flex align-items-center justify-content-between" role="button"
            type="button" *ngIf="continuing">
            <div>{{ 'claims.edit.continue' | translate }}</div>
            <div class="spinner-border" role="status">
              <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="showAddressValidation">
    <upsc-address-validation (userInput)="updateAddress($event)">
    </upsc-address-validation>
  </div>
  <div *ngIf="showSuccessMessage" class="success d-flex flex-column align-items-center justify-content-center">
    <div class="text">{{ 'app.dialog.succssMessage' | translate }}</div>
    <button class="upsc-button" (click)="close()" role="button">
      {{ 'claims.edit.tupssPayee.returnCD' | translate }}
    </button>
  </div>
  <div *ngIf="showACH">
    <upsc-ach [payeeDetails]="persistPayeeInfo" [showACHError]="showACHError"></upsc-ach>
    <div class="step-controls">
      <div>
        <a class="previous-step" (click)="achBack()">
          {{ 'claims.edit.back' | translate }}
        </a>
        <a class="reset-step" (click)="close()">
          {{ 'claims.edit.cancel' | translate }}
        </a>
      </div>
      <div>
        <button class="next-step" role="button" type="button" (click)="achContinue($event)" *ngIf="!continuing">
          {{ 'claims.edit.continue' | translate }}
        </button>
        <button class="next-step loading d-flex align-items-center justify-content-between" role="button" type="button"
          *ngIf="continuing">
          <div>{{ 'claims.edit.continue' | translate }}</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>