import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdjusterDashboardComponent } from './adjuster-dashboard.component';
import { LayoutModule } from '../../shared/components/layout/layout.module';
import { AdjusterDashboardRoutingModule } from './adjuster-dashboard.routing.module';
import { SharedModule } from '../../shared/shared.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PayeeComponent } from './payee/payee.component';
@NgModule({
  declarations: [
    AdjusterDashboardComponent,
    PayeeComponent
  ],
  imports: [
    CommonModule,
    AdjusterDashboardRoutingModule,
    LayoutModule,
    SharedModule,
    MatIconModule, 
    MatInputModule,
     MatSelectModule, 
     MatNativeDateModule, 
     MatAutocompleteModule, 
     MatMenuModule, 
     MatButtonModule, 
     MatFormFieldModule,
     MatDividerModule
  ]
})
export class AdjusterDashboardModule { }
