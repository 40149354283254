<div class="page d-flex justify-content-center align-items-center">
  <div *ngIf="!errorOccurred"  class="w-75 align-items-center">
    <mat-progress-bar
              [mode]="'indeterminate'">
    </mat-progress-bar>
  </div>
  <div *ngIf="errorOccurred" class="messageBox d-flex flex-column align-item-center">
    <p class="error">{{'autoAuth.error' | translate}}</p>
    <p class="message">
      <span>{{'autoAuth.tryAgain1' | translate}}</span>
      <a *ngIf="workflow!='DD'" href="mailto:InsureShieldsupport@ups.com?subject={{ 'autoAuth.subject' | translate }}" class=""><u> {{'autoAuth.email' | translate}} </u></a>
      <a *ngIf="workflow=='DD'" href="mailto:deliverydefensecustomerservice@ups.com?subject=DeliveryDefense Authentication" class=""><u> {{'autoAuth.email' | translate}} </u></a>
      <span>{{'autoAuth.tryAgain2' | translate}}</span></p>
    <div class="message">{{'autoAuth.hours' | translate}}</div>
    <div class="message">{{'autoAuth.days' | translate}}</div>
    <div class="message">{{'support.time' | translate}}</div>
  </div>
</div>
