import { Component, HostBinding, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'upsc-tupss-layout',
  templateUrl: './tupss-layout.component.html',
  styleUrls: ['./tupss-layout.component.scss']
})
export class TupssLayoutComponent implements OnInit {
  @HostBinding('class') public hostClass = 'tupss-layout';
  isAdjuster: boolean;
  currentUrl: string;
  showInsureshieldFooter: boolean = false;
  showONLFooter: boolean = false;
  pirateShipFlow;
  constructor(private userService: UserService,
    private router: Router,) { }

  public ngOnInit(): void {
    this.isAdjuster = this.userService.isUserAdjuster();
    this.currentUrl = this.router.url;

    // not 100% sure which mychoice pages they want the insureshield footer for
    if(this.currentUrl && this.currentUrl.includes('ups-my-choice')) {
      this.showInsureshieldFooter = true;
      this.showONLFooter = false;
    } else {
      this.showInsureshieldFooter = false;
      this.showONLFooter = true;
    }

    this.pirateShipFlow = this.router.url.includes('pirate-ship');
  }

}

