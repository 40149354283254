import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ScriptService } from '../services/script.service';

@Injectable({
  providedIn: 'root'
})
export class TaggingService {

  constructor(
    private scriptService: ScriptService,
    private metaService: Meta
  ) { }

  view(data?: any) {
    if (!data) {
      this.setPageId();
    }
    let udo = this.getUDO();
    if (data) {
      udo = { ...udo, ...data };
    }
    this.scriptService.view(udo);
  }

  link(data?: any) {
    let udo = this.getUDO();
    this.scriptService.link({
      ...udo,
      ...data
    });
  }

  load(pageId) {
    this.scriptService.view({
      ...this.getUDO(),
      'application_id': 'upsc-onl',
      'app_page_id': pageId
    });
  }

  click(linkName, pageName, eventDesc, moreParams = {}) {
    let udo = {
      'link_name': [linkName],
      'link_page_name': [pageName],
      'event_desc': eventDesc,
    }
    if (moreParams) {
      udo = { ...udo, ...moreParams };
    }
    this.scriptService.link(udo);
  }

  setProducts(revenue) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'products';
    script.innerText = `var s.prodcuts=";;;${revenue}"; var net_revenue="${revenue}";`;
    document.head.appendChild(script);
  }

  getPageId() {
    return window.location.href.split('?')[0];
  }

  setPageId() {
    this.metaService.updateTag({ name: 'DCSext.pPID', content: this.getPageId() });
  }

  getUDO() {
    return {
      page_id: this.getPageId(),
      page_language: this.getPageLanguage(),
      page_country_code: this.getPageCountry()
    }
  }

  getPageLanguage() {
    let url = window?.location?.href?.toLowerCase();
    let sessionLocale = sessionStorage.getItem('locale');
    if (sessionLocale) {
      sessionLocale = sessionLocale.toLowerCase();
    }
    if (url.includes('ca-en') || url.includes('en-ca')
    || url.includes('en-gb') || url.includes('gb-en')
    || url.includes('en-fr') || url.includes('fr-en') || url.includes('fr-fr')
    || url.includes('en-de') || url.includes('de-en') || url.includes('de-de')
    || url.includes('en-it') || url.includes('it-en') || url.includes('it-it')
    ) {
      if (url.includes('/en')) {
        return 'en';
      } else if (url.includes('fr-fr')) {
        return 'fr';
      } else if (url.includes('de-de')) {
        return 'de';
      } else if (url.includes('it-it')) {
        return 'it';
      }
    } else if (sessionLocale) {
      if (sessionLocale.includes('ca-en') || sessionLocale.includes('en-ca')
      || sessionLocale.includes('en-gb') || sessionLocale.includes('gb-en')
      || sessionLocale.includes('en-fr') || sessionLocale.includes('fr-en') || sessionLocale.includes('fr-fr')
      || sessionLocale.includes('en-de') || sessionLocale.includes('de-en') || sessionLocale.includes('de-de')
      || sessionLocale.includes('en-it') || sessionLocale.includes('it-en') || sessionLocale.includes('it-it')
      ) {
        if (sessionLocale.includes('en')) {
          return 'en';
        } else if (url.includes('fr')) {
          return 'fr';
        } else if (url.includes('de')) {
          return 'de';
        } else if (url.includes('it')) {
          return 'it';
        }
      } else {
        return 'en';
      }
    } else {
      return 'en';
    }
  }

  getPageCountry() {
    let url = window.location.href.toLowerCase();
    let sessionLocale = sessionStorage.getItem('locale');
    if (sessionLocale) {
      sessionLocale = sessionLocale.toLowerCase();
    }
    if (url.includes('ca-en') || url.includes('en-ca')
    || url.includes('en-gb') || url.includes('gb-en')
    || url.includes('en-fr') || url.includes('fr-en') || url.includes('fr-fr')
    || url.includes('en-de') || url.includes('de-en') || url.includes('de-de')
    || url.includes('en-it') || url.includes('it-en') || url.includes('it-it')
    ) {
      if (url.includes('en-ca') || url.includes('ca-en')) {
        return 'CA';
      } else if (url.includes('en-fr') || url.includes('fr-en') || url.includes('fr-fr')) {
        return 'FR';
      } else if (url.includes('en-de') || url.includes('de-en') || url.includes('de-de')) {
        return 'DE';
      } else if (url.includes('en-it') || url.includes('it-en') || url.includes('it-it')) {
        return 'IT';
      }
    } else if (sessionLocale) {
      if (sessionLocale.includes('ca-en') || sessionLocale.includes('en-ca')
      || sessionLocale.includes('en-gb') || sessionLocale.includes('gb-en')
      || sessionLocale.includes('en-fr') || sessionLocale.includes('fr-en') || sessionLocale.includes('fr-fr')
      || sessionLocale.includes('en-de') || sessionLocale.includes('de-en') || sessionLocale.includes('de-de')
      || sessionLocale.includes('en-it') || sessionLocale.includes('it-en') || sessionLocale.includes('it-it')
      ) {
        if (sessionLocale.includes('ca')) {
          return 'CA';
        } else if (sessionLocale.includes('gb')) {
          return 'GB';
        } else if (sessionLocale.includes('fr')) {
          return 'FR';
        } else if (sessionLocale.includes('de')) {
          return 'DE';
        } else if (sessionLocale.includes('it')) {
          return 'IT';
        }
      } else {
        return 'US';
      }
    } else {
      return 'US';
    }
  }
}
