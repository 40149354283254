import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { GuestLinkPreview } from 'src/app/shared/models/guest-link-preview';
import { DynamicFlowService } from 'src/app/services/dynamic-flow.service';

@Component({
  selector: 'upsc-self-faq',
  templateUrl: './self-faq.component.html',
  styleUrls: ['./self-faq.component.scss']
})
export class SelfFaqComponent {

  flowType = '';
  previewModel: GuestLinkPreview = {};
  color: string = '';

  constructor(
    private userService: UserService,
    private dynamicFlowService: DynamicFlowService
  ) {
    this.flowType = sessionStorage.getItem("flowType");
    var previewModelString = sessionStorage.getItem("previewModelString");
    if (previewModelString !== null && previewModelString.length > 3) {
      var previewModel = JSON.parse(previewModelString);
      if (previewModel !== null) {
        this.previewModel = previewModel;
        if (previewModel && previewModel.theme === 'light') {
          document.documentElement.style.setProperty('--background-color', 'white');
          document.documentElement.style.setProperty('--color', 'black');
        } else {
          document.documentElement.style.setProperty('--background-color', 'black');
          document.documentElement.style.setProperty('--color', 'white');
        }
      }
    }
  }

  ngOnInit() {
    this.userService.setLocale('US');
  }

}
