import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})

export class ManageUsersComponent implements OnInit {
  isParentPolicy: boolean = false;
  policyInfo;
  isEAUser: boolean = false;

  constructor (
    private userService: UserService,
    private loginService: LoginService
  ) {
    this.isParentPolicy = this.userService.isParentPolicy();
    this.policyInfo = this.loginService.getPolicyDetails();
    this.isEAUser = this.userService.isEAUserorNonEA();
  }
 
  ngOnInit(): void { }
}
