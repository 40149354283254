import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Directive({
  selector: '[upscPhoneNumber]'
})
export class PhoneNumberDirective {
  isUsaUser: boolean;

  constructor(public ngControl: NgControl, userService: UserService) {
    this.isUsaUser = userService.isUsaUser();
   }
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  onInputChange(event){
    if(this.isUsaUser){
    let newVal = event.replace(/\D/g, '');
    if(newVal.length === 0){
      newVal = '';
    } else if(newVal.length<=3){
      newVal = newVal;
    } else if(newVal.length<=6){
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1.$2');
    } else if(newVal.length<=10){
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3');
    }
    if(event == newVal){
      //Same value, don't do anything
    } else {
      //Modifying value
      this.ngControl.control.setValue(newVal);
      this.ngControl.valueAccessor.writeValue(newVal);
    }
  }
  else{
    let newVal = event.replace(/\D/g, '');
    if(newVal.length === 0){
      newVal = '';
    } else if(newVal.length<=3){
      newVal = newVal;
    } else if(newVal.length<=6){
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
    } else if(newVal.length<=10){
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    } else if (newVal.length > 10) {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3-$4');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    }
    if(event == newVal){
      //Same value, don't do anything
    } else {
      //Modifying value
      this.ngControl.control.setValue(newVal);
      this.ngControl.valueAccessor.writeValue(newVal);
    }
  }
  }
}
