import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, interval,BehaviorSubject, Observable, timer } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { ApiEndpoints as API } from '../../config/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class GuestLinkService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private http: HttpClient) { }

  //Acting as a source of CCP SSP check;
  private ccpSelfServicecheck: BehaviorSubject<string> = new BehaviorSubject<string>('');

  //Allows internal user component to subscribe and listen for updates.
  ccpSSPCheck$ = this.ccpSelfServicecheck.asObservable();


  ccpSSPChecked(check: string) {
    //updating the latest value using the next() method. 
    console.log(check);
    this.ccpSelfServicecheck.next(check);
  }

  public uploadFile(formData: any): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.UPLOAD_LOGO}`, formData)
  }

  public updateCcpInfo(guestLinkInfo: any): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.UPDATE_CCP_INFO}`, guestLinkInfo, this.options)
  }

  public getCcpInfo(guestLinkInfo: any): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_SSP_INFO}`, guestLinkInfo, this.options)
  }


  public sendWelcomeEmail(emailInfo: any): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SSP_WELCOME_EMAIL}`, emailInfo, this.options)
  }

  public setThemeStyles(previewModel: any) {
    document.documentElement.style.setProperty('--primary-color', previewModel.accentColor1);
    document.documentElement.style.setProperty('--secondary-color', previewModel.accentColor2);
    document.documentElement.style.setProperty('--secondary-color-light', previewModel.accentColor3);
    document.documentElement.style.setProperty('--backgroundColor', previewModel.backgroundColor);
    document.documentElement.style.setProperty('--foregroundColor', previewModel.foregroundColor);
    if (previewModel.buttonShape === 'round') {
      document.documentElement.style.setProperty('--border-radius', '23px');
    } else if (previewModel.buttonShape === 'classic') {
      document.documentElement.style.setProperty('--border-radius', '7px');
    } else {
      document.documentElement.style.setProperty('--border-radius', '0px');
    }

    if (previewModel.theme === 'light') {
      document.documentElement.style.setProperty('--box-shadow-one', '#00000080');
      document.documentElement.style.setProperty('--box-shadow-two', '#00000024');
    } else {
      document.documentElement.style.setProperty('--box-shadow-one', 'grey');
      document.documentElement.style.setProperty('--box-shadow-two', 'white');
    }
  }

  public setThemeDefaultStyles() {
    document.documentElement.style.setProperty('--primary-color', '#00857d');
    document.documentElement.style.setProperty('--secondary-color','#00857d');
    document.documentElement.style.setProperty('--secondary-color-light', '#4db5ac');
    document.documentElement.style.setProperty('--backgroundColor', 'white');
    document.documentElement.style.setProperty('--foregroundColor', 'black');
    
    document.documentElement.style.setProperty('--border-radius', '23px');
    document.documentElement.style.setProperty('--box-shadow-one', '#00000080');
    document.documentElement.style.setProperty('--box-shadow-two', '#00000024');
  }

}