export const ApiEndpoints = {
  // Authentication + Account
  login: `logIn`, //No token needed
  logout: `/auth/logout`, // POST
  passwordRecovery: `/auth/recovery`,
  passwordReset: `/reset-password`, //No token needed
  userIDRecovery: `forgot-username`, //No token needed
  register: `create-user`, //No token needed
  decodeToken: `decode-token`, //No token needed
  saveVerificationCode: `/save/verification`, //No token needed
  createVerificationCode: '/verification/create',
  retrieveVerificationCode: `/verification`, //No token needed
  verifyCode: 'verification/verify',
  verifyTupssCode: 'verification/verify-tupss',
  verifyemail: `verify-email`, //No token needed
  verifyUserID: `verify-username`, //No token needed
  checkUser: `check-user`, //No token needed
  updateUser: `update-user`, //No token needed
  updateUserName: `updateUserName`, //No token needed
  getFreshToken: `get-fresh-token`,
  changePassword: `change-password`,
  checkRoleUsers: `policy/getNumberOfUsersForPolicy`, //No token needed
  getTempUserDetails: `users/getTempUsersDetails`, //No token needed

  // Logout and Session Expired
  logouts:`logOut`,

  // Claims
  getClaims: `claim`,
  getClaim: `/mock/claim/:id`,
  createClaim: `claim`,
  saveClaim: `claim/save`,
  submitClaim: `claim/submit`,
  getClaimsDetails: `claim/details`,
  getClaimSummary: `claim/summary`,
  getAllClaimIds: `claim/claimids`,
  ADDRESS_VALIDATION: `addressValidation`,
  withdrawClaim: 'claim/withdraw',
  reopenClaim: 'claim/reopen',
  deleteDraft: 'claim/DeleteDraft',

  // Claims Messaging
  POST_MESSAGE: `claim/postCustomerMessage`,
  getAllMessageClaimIds: 'claim/MessageClaimID',
  getMessageSummary: 'claim/MessageSummary',
  getAllMessageIds: 'claim/MessageID',
  getMessageDetails: 'claim/MessageDetails',

  // Documents
  uploadDocument: `/document/upload`,
  generateUploadToken: `/documents/generate-upload-token`,
  removeDocument: `/documents/remove`,
  downloadDocument: `documents/downloadDocument`,
  DOWNLOAD_PC_DOCUMENT: `documents/PCDownloadDocument`,
  DOWNLOAD_BC_DOCUMENT: `documents/BCDownloadDocument`,
  DOWNLOAD_PDF_DOCUMENT: `documents/DownloadInvoice`, //PDF download for CBP PDF2024

  // Shipment Details
  getShipmentDetails: `shipments/`, //Check for duplicate tracking numbers
  getShipmentDetailsAutofill: `shipments/details/`, //tracking number full details

  // getUserLayouts Details
  getUserLayouts: `AzureSQL/getUserLayouts`,
  getLayoutDetails: `AzureSQL/getLayout`,
  removeUserLayouts: `AzureSQL/deleteLayout`,
  getUserLayoutcolumns: `AzureSQL/getLayout`,
  submitUserLayoutcolumns: `AzureSQL/layout`,

  //DPAT
  getRedirect: `/DPAT/getRedirect`,
  getPayeeDetails: `/DPAT/getPayeeDetails`,
  DPATTokenProcessing: `/billing/TokenProcessing`,

  //MetaData
  getMetaData: `/data/metadata`,

  //Policies
  getPolicyDetails: `databricks/policySearch`,
  getPolicyDetailsNoAuth: `databricks/policyValidate`, //No token needed
  POLICY_CENTER_SEARCH: `pc/search`,
  getPermissions: `RLS/getPerms`,
  ADD_POLICIES: `policy/save`,  //no token needed

  //Billing
  BILLING_CENTER_SEARCH: `billing/BillingCenterDetails`,
  RETRIEVE_PAYMENT_PROFILE: 'bc/merchantProcessing/retrievePayment',
  SAVE_PAYMENT_PROFILE: 'bc/merchantProcessing',
  GET_ALL_PAYMENT_TOKENS: `billing/billingRetrieveTokens`,
  REMOVE_PAYMENT_PROFILE: 'bc/merchantProcessing',
  SAVE_PAYMENT_TOKEN: `bc/saveToken`,
  BILLING_TOKEN_PROCESSING: `billing/TokenProcessing`,

  //User
  saveUser: `/users`,
  getUser: `/users/:id`,
  getUserById: `/users`,
  UPDATE_USER_PREFERENCES: `updateUser/details`,
  GET_USER_IDS_FOR_POLICY: `users/getUserIDs`,
  GET_USER_INFO_LIST_FOR_POLICY: `users/getUsersForPolicy`,
  adminUpdateUser: 'admin/updateUser',
  adminDeleteUser: '/admin/deleteUser',
  inviteUser: 'users/invite',
  deleteInvitedUser: 'admin/deleteTempUser',
  RLS_Auto_Authentication: `RLSAutoAuthentication`,
  UPDATE_USER_DETAILS: `users/updateUser`,
  adminUserCount: `users/getAdminsForPolicy`,
  POLICY_UPDATE_CONTACT: `policy/UpdateContact`,
  GET_USERS_BY_USERID: `users/GetUserbyUserId`,
  DataBricks_POLICY_SEARCH: `databricks/policySearch`,
  DWpostIndicators: `digitalWallet/postIndicators`,
  DWgetIndicators: `digitalWallet/getIndicators`,
  DW_GetSummary: `digitalWallet/getSummary`,
  DWstartSession: `digitalWallet/startSession`,
  //Logging
  uilog: `uilog`,
  LOGIN_LOG: `uilog/login`,
  // TFCP_LOG: `uilog/tfcp`,  //decommissioned as of 4/4/2023

  //Email Queue Endpoints
  RESET_PASSWORD: `email/resetPassword`, //No token needed
  CREATE_ACCOUNT: `email/createAccount`, //No token needed
  TUPSS_VERIFICATION: `email/tccpClaimVerification`, // new updated API
  REGISTRATION_COMPLETE: `email/registrationComplete`, //No token needed
  CONTACT_US: `email/contactUs`,
  GUEST_FILING_CONFIRMATION: `email/claimDetails`,

  //Profile endpoints
  GET_PAYEES: `payee/payeeDetails`,
  INITIATE_PUBLIC_SESSION_TRANSFER: `payee/initiateSessionTransfer`,
  CREATE_PAYEE: `payee/createPayee`,
  GET_CARD_DIGITS: `payee/getUserPaymentAccount`,
  UPDATE_ACH_INDICATOR: `payee/updateACHIndicator`,
  CHECK_DENIED_PARTY: 'payee/deniedParty',
  CHECK_DENIED_PARTY_V2: 'payee/v2/deniedParty',
  ORDER_ABSTRACTION: 'payee/orderAbstraction',

  //Terms & Conditions
  POST_TERMS: `azurePostTerm`, //No token needed
  GET_TERM: `getTerm`, //No token needed

  GUEST_ACCESS: `users/guestAccess`, //No token needed
  AUTO_AUTH_PROFILE: `retriveUserProfile`,  //No token needed
  REFRESH_POWERBI_TOKEN: `powerbi/newembedparams`,
  SMART_UPLOAD: `smartupload`,
  HVCM: `HVCMSession`,
  DELIVERY_DEFENSE: `DeliveryDefense/startSession`,
  DD_CONTRACT_NUMBER: `policy/CreateContract`,
  UPDATE_THIRD_PARTY_INDICATOR: `policy/upsert-third-party-policies`,
  //Tupss endpoint
  ISHIP: `iShip/getShipmentData`,
  tupssVerify: `iShip/verification`,
  CHECK_SHIPMENT: `iShip/checkShipment`,
  CHECK_CLAIMS: `check-claims/`,
  FRANCHISE_AUTH: `iShip/franchiseeautoauthentication`,
  GET_CONTRACT_ID: `iShip/getContractID`,
  TFCP_CLAIM_SUBMISSION: `email/tfcpClaimSubmission`,
  TCCP_CLAIM_SUBMISSION: `email/tccpClaimSubmission`,
  TFCP_CONTACT_US: `email/tfcpContactUs`,
  TCCP_CONTACT_US: `email/tccpContactUs`,
  CLAIM_DETAIL_VERIFICATION_CODE: `email/tccpClaimSuccessVerification`,

  //Custmizable dashboard
  CLAIM_STATS: `claim/GetStats`,

  //Delivery Status
  DELIVERY_STATS: `claim/deliveryStatus`,

  //My Choice endpoints
  MYCHOICE_CLAIM_SUBMISSION_EMAIL: `email/mcClaimSubmission`,
  MYCHOICE_PAYMENT_RECEIPT_EMAIL: `email/mcPaymentReceiptDPAT`,
  DESCRPT_PARAMS: `myChoice/decryptUserDetails`,
  GET_ACCESS_TOKEN: `myChoice/getToken`,
  GET_MC_INFO: `myChoice/getEnrollment`,
  CREATE_POLICY: `policy/myChoice/create`,
  SUBMIT_POLICY: `policy/myChoice/submit`,
  MYCHOICE_ZIP_VALIDATION: `myChoice/validateZip`,
  CHECK_COVERAGE: `myChoice/check-coverage`,
  DPAT_ORDER_ABSTRACTION: `DPAT/orderAbstraction`,
  DPAT_RETRIEVE: `DPAT/retrievePaymentMethod`,

  //Customized Claims Portals (Channel Partners)
  CCP_CLAIM_SUBMISSION_EMAIL: `email/ccpClaimConfirmation`,
  DATABRICKS_SHIPMENT_DETAILS: `databricks/getShipmentDetails`,
  CCP_VERIFICATION_CODE_EMAIL: `verification/ccp-verification-email`,
  CHECK_CCP_POLICY: `policy/get-cp-policy`,

  //Prospect
  BIND_POLICY: `smb-policy`,
  CHECK_EMAIL_VALIDITY: `getSMBCampaignEmail`,
  SMB_CONTACT_US: `email/smbContactUs`,

  //Confirmation of Coverage
  GET_COC_AGGREGATE: `confirmationCoverage/getAggregate`,
  GET_COC_LIST_OF_TRACKING_NUMBERS: `confirmationCoverage/getTrackingNumbers`,
  GET_COC_TRACKING_NUMBER_FULL_INFO: `confirmationCoverage/getTrackingDetail`,

  //Enterprise Affiliate
  POLICY_DETAILS: `policy/userPolicyDetails`,
  UPDATE_NICKNAME: `policy/updatePolicyNickname`,
  CHILD_POLICY_INFO: `policy/getChildAccountInfo`,
  SHIPPER_ACCOUNTS: `policy/getShipperAccountInfo`,
  SHIPPER_AND_GROUP_LIST: `policy/getShipperAccounts`,
  CHILD_POLICY_LIST: `policy/getChildPolicyList`,

  // IS4UPS
  UPSERT_ACQUISITION: `IS4UPS/upsert-acquisition`,
  GET_ACQUISITION: `IS4UPS/get-acquisition`,
  GET_SHIPPER_NUMBERS: `IS4UPS/get-shipper-numbers`,
  VALIDATE_SHIPPER_NUMBER: `shipments/validate`,
  VALIDATE_MULTIPLE_SHIPPER_NUMBER: `shipments/validate-shipper-numbers`,
  CONVERSION_EMAIL: `email/IS4UPSConversionEmail`,

  //DeliveryDefense Visibility
  CREATE_VISIBILITY_CONTRACT: `DeliveryDefense-CreateVisibilityContract`,
  DD_VISIBILITY_EMAIL: `email/ddVisibility`,

  //Prospect Portal
  GET_CRM_DATA: `CRM/getData`,
  VERIFY_CRM_PROSPECT: `CRM/verify-prospect`,
  SAVE_ARMOR_SESSION: `policy/save-CRM-session`,
  GET_ARMOR_SESSION: `policy/get-CRM-session`,
  DELETE_ARMOR_SESSION: `policy/delete-CRM-session`,
  GET_QUOTE: `CRM/get-quote`,
  SEND_DECLINE_EMAIL: `email/ppDeclineCoverageEmail`,
  SEND_LEXUS_REJECTION_EMAIL: `email/ppApplicationEmails`,
  SEND_UNDER_REVIEW_EMAIL: `email/ppApplicationEmails`,
  CRM_POLICY_BIND: `CRM/policy-binding`,

  //reCAPTCHA
  RECAPTCHA_CHECK: `reCaptcha/verification`,

  //Self Service
  UPLOAD_LOGO: `policy/upload-cpp-image`,
  UPDATE_CCP_INFO: `policy/upsert-cpp-info `,
  GET_SSP_INFO: `policy/partner-customization`,
  CHECK_SSP_POLICYEXIST: `policy/check-ssp-policyexist`,
  SSP_WELCOME_EMAIL: `email/ccpWelcomeEmail`,

  //Flex App Service
  CBP_RETRIEVE_POLICY: `cbp-retrieve-policy`
};
