import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'upsc-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {

  //deleteMessage: string = "";
  messageData = {
    title: "",
    message: "",
    isCopyrightPresent: false
  };

  copyrightMsgFirst: string = "";
  copyrightMsgLast: string = "";

  constructor(
    private dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.messageData = this.data;
    if (this.messageData.isCopyrightPresent) {
      var copyRightMsg = this.messageData.message.split('|');
      if (copyRightMsg.length > 1) {
        this.copyrightMsgFirst = copyRightMsg[0].toString();
        this.copyrightMsgLast = copyRightMsg[1].toString();
      }
      else{
        this.messageData.isCopyrightPresent = false;
        this.messageData.title = this.translateService.instant('defaultDelete.title'),
        this.messageData.message = this.translateService.instant('defaultDelete.message')
      }
    }
  }

  close() {
    this.dialogRef.close("Cancel");
  }

  delete() {
    this.dialogRef.close("Delete");
  }

}
