import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { FooterComponent } from './footer.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { ProductComponent } from '../product/product.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
    imports: [
        SharedModule,
        MatDialogModule,
    ],
    declarations: [FooterComponent],
    providers: [],
    exports: [FooterComponent]
})
export class FooterModule {}
