import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiEndpoints as API } from '../../config/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {
  emailVerificationEvent$ = new BehaviorSubject<boolean>(null);
  _emailVerificationEvent: boolean;

  private options = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  }

  constructor(
    private http: HttpClient
  ) { }

  setEmailVerEvent(bool: boolean) {
    this._emailVerificationEvent = bool;
    this.emailVerificationEvent$.next(bool);
  }

  deleteEmailVerEvent() {
    this.setEmailVerEvent(null);
  }

  getEmailVerEvent() {
    return this.emailVerificationEvent$;
  }

  bindPolicy(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.BIND_POLICY}`, request, this.options);
  }

  checkEmailValidity(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CHECK_EMAIL_VALIDITY}`, request, this.options);
  }

  contact(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SMB_CONTACT_US}`, request, this.options);
  }

  saveProspectPortalSession(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SAVE_ARMOR_SESSION}`, request, this.options);
  }

  getProspectPortalSession(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_ARMOR_SESSION}`, request, this.options);
  }

  deleteProspectPortalSession(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DELETE_ARMOR_SESSION}`, request, this.options);
  }

  getQuote(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_QUOTE}`, request, this.options);
  }

  sendDeclineEmail(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SEND_DECLINE_EMAIL}`, request, this.options);
  }

  sendLexusRejectionEmail(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SEND_LEXUS_REJECTION_EMAIL}`, request, this.options);
  }

  bindPolicyCRM(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CRM_POLICY_BIND}`, request, this.options);
  }

  sendCRMReviewEmail(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SEND_UNDER_REVIEW_EMAIL}`, request, this.options);
  }
}
