import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PrivacyComponent } from '../privacy/privacy.component';
import { ProductComponent } from '../product/product.component';
import objectFitImages from 'object-fit-images';  //IE Polyfill
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-identity',
  templateUrl: './identity-layout.component.html',
  styleUrls: ['./identity-layout.component.scss']
})
export class IdentityLayoutComponent implements OnInit {
  public thisYear = (new Date()).getFullYear();
  public isIE : boolean = false;
  isCanadaUser: boolean;
  constructor(public dialog: MatDialog, public userService: UserService) {
    //Detect if browser is IE so we can load preferred logo image
    this.isIE = /*@cc_on!@*/false || !!document['documentMode'];
    this.isCanadaUser = this.userService.isCanadaUser();
  }

  ngOnInit() {
    objectFitImages('img.bg');  //Used with IE polyfill for image cover
  }

  public openPrivacyDialog() {
    let dialogRef = this.dialog.open(PrivacyComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  public openProductDialog() {
    let dialogRef = this.dialog.open(ProductComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
