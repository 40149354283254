import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClaimService } from '../../../../services/claim.service';

@Injectable()
export class ClaimEditResolver  {
  constructor(private claimService: ClaimService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const claimId = route.paramMap.get('id');
    if (!claimId) {
      return of({
        claim: null,
      });
    }

    const requests = [
      this.claimService.getClaim(claimId),
    ];

    return forkJoin(requests).pipe(
      map(
        (responses) => ({
          claim: responses[0],
        }),
      ),
    );
  }
}
