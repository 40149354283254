import { Component, OnInit, HostBinding } from '@angular/core';
import { IPolicies } from '../../shared/models/policies.interface';
import { LoginService } from '../../services/login.service';
import { UserService } from 'src/app/services/user.service';
import { TaggingService } from 'src/app/tags/tagging.service';
import { AccountInfo } from 'src/app/shared/models/account-info.interface';

@Component({
  selector: 'upsc-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  @HostBinding('class') public hostClass = 'profile';

  public policy: IPolicies;
  userDetails: AccountInfo;
  public securityActive: boolean = false;
  isCanadaUser: boolean;
  isInternalUser: boolean = false

  //Roles
  isClaimViewer: boolean = false;
  isFileClaim: boolean = false;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private taggingService: TaggingService
  ) {
    this.policy = this.loginService.getPolicyDetails();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.userDetails = this.userService.getUserInfo();
    this.isClaimViewer = this.userService.getUserPermissions()?.isClaimView;  //ClaimViewer cannot click file claim button
    this.isFileClaim = this.userService.getUserPermissions()?.isFileClaim;
  }  

  ngOnInit() {
    this.taggingService.view();

    let isInternal = this.userDetails.internalUser;
    if (isInternal) {
      this.isInternalUser = true;
    } else {
      this.isInternalUser = false;
    }
  }

  tagging() {
    this.taggingService.link({ link_name: 'file_a_claim' });
  }
}
