<div class="d-flex align-items-center">
  <div class="text-color">
    Policy Number
  </div>
  <div ngbDropdown (openChange)="checkDropDown($event)" class="d-flex flex-column flex-md-row m-2">
    <button ngbDropdownToggle [ngClass]="applied == true ? 'upsc-rectangle-button' : 'upsc-white-rectangle'">
      <span class="selectedValues"> {{ 'claims.list.claimsSearch.selectPolicy' | translate
        }}</span>
    </button>
    <div ngbDropdownMenu class="drop-wrap" x-placement="top-left">
      <div class="text-format">Policy Number</div>
      <li *ngFor="let layouts of layoutsname" [ngClass]="{'active': selectedPolicyNumber == layouts}">
        <div class="d-flex" (click)="applyThisPolicy(layouts)">
          <div>{{ layouts | translate }}</div>
          <img src="../../../../assets/images/select-icon.png" class="ml-auto img-format" *ngIf="selectedPolicyNumber === layouts">
        </div>
      </li>
    </div>
  </div>
</div>