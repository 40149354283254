import { environment } from '../../../environments/environment';

export interface IEmail {
    applicationName: string;
    emailFor: string;
    environment: string;
    CountryLocale: string;
    data: {
        email?: string;
        ccEmail?: string;
        userID?: string;
        verificationCode?: string;
        fName?: string;
        lName?: string;
        emailAbout?: string;
        policyNumber?: string;
        methodOfContact?: string;
        contactInfo?: string;
        description?: string;
        claimNumber?: string;
        claimDetailLink?: string;
        companyName?: string;
        streetAddress?: string;
        city?: string;
        state?: string;
        zip?: string;
        firstName?: string;
        lastName?: string;
        trackingNumber?: string;
        storeNumber?: string;
    };
}

export const emptyIEmail = {
    applicationName: "OnlinePortal",
    emailFor: "",
    environment: environment.webjob.env,
    CountryLocale: "",
    data: {}
}