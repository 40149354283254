import { Injectable } from '@angular/core';
import { HttpClientService } from './http/http-client.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class EnvironmentService {
  private _environment: any;

  constructor(private httpService: HttpClientService) {
    this._environment = environment;
  }

  public get environment(): any {
    return this._environment;
  }

  public initEnvironment(): BehaviorSubject<any> {
    const environmentSubject = new BehaviorSubject<any>(null);

    this.httpService.get('/assets/environment.json')
      .subscribe(
        (env) => {
          // Key-values from environment.json will be merged into the original environment key-values.
          // Any existing value in an environment file wil be overwritten by the value with the same key in environment.json.
          this._environment = Object.assign(this._environment, env);
          environmentSubject.next(this._environment);
        },
        (err) => {
          console.warn('Failed to get environment file. Fallback to default.', err);
          environmentSubject.next(this._environment);
        },
      );

    return environmentSubject;
  }
}
