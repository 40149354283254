<div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
  <div class="spinner-border">
    <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>

<div class="prospect-landing-wrapper" *ngIf="!showSpinner">
  <!-- <upsc-rectangle-progress-bar [totalwidth]="'firstpage'" [percent]="50" [text]="'You\'re 50% done with your application. Halfway there!'"></upsc-rectangle-progress-bar> -->
  <div class="title mb-5"  *ngIf="!showEmailVerification && !showAddressValidation">
    Let's Get Started
    <div class="green-line"></div>
  </div>
  <upsc-email-verification [flow]="'prospectPortal'" (canceled)="cancelEmailVerification()" (resend)="sendVerificationCode($event)" (verified)="onVerified()"
    [email]="email.value" *ngIf="showEmailVerification">
  </upsc-email-verification>

  <div class="form-wrapper mt-5" *ngIf="!showEmailVerification && !showAddressValidation">
    <form [formGroup]="formGroup" (ngSubmit)="submitForm()">
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>First and Last Name</mat-label>
        <input matInput required formControlName="name" readonly>
        <mat-error>
          <div class="error">
            <div class="error-text">First and Last Name is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Email</mat-label>
        <input matInput required formControlName="email" readonly>
        <mat-error>
          <div class="error">
            <div class="error-text">Email is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Company Name</mat-label>
        <input matInput required formControlName="company" readonly>
        <mat-error>
          <div class="error">
            <div class="error-text">Company Name is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Phone Number</mat-label>
        <input matInput mask="000-000-0000" required formControlName="phone">
        <mat-error>
          <div class="error">
            <div class="error-text">Phone Number is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Business Address</mat-label>
        <input matInput required formControlName="address1">
        <mat-error>
          <div class="error">
            <div class="error-text">Business Address is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <div class="city-state-zip">
        <mat-form-field [floatLabel]="'auto'">
          <mat-label>City</mat-label>
          <input matInput required formControlName="city">
          <mat-error>
            <div class="error">
              <div class="error-text">City is required</div>
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'auto'">
          <mat-label>State *</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let state of fullStateList" [value]="state.state">{{state.state}}</mat-option>
          </mat-select>
          <mat-error>
            <div class="error">
              <div class="error-text">State is required</div>
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'auto'">
          <mat-label>Zip Code</mat-label>
          <input matInput required formControlName="zip" maxlength="5">
          <mat-error>
            <div class="error">
              <div class="error-text">Zip Code is required</div>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Business Tax ID/Employer Identification Number (EIN)</mat-label>
        <input matInput formControlName="taxID">
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Company Website (Optional)</mat-label>
        <input matInput formControlName="webSite">
      </mat-form-field>

      <div class="link" (click)="expandTaxInfo()">Where can I find my TAX ID?<mat-icon *ngIf="showTaxInfo">expand_less</mat-icon> </div>
      <div class="tax-info" *ngIf="showTaxInfo">
        Try looking up your TAX ID on the following documents, most of which require your business tax ID number:
        <ul>
          <li>TAX ID confirmation notice.</li>
          <li>Federal tax returns.</li>
          <li>Business licenses and permits.</li>
          <li>Business bank account statements.</li>
          <li>Business loan applications.</li>
          <li>Payroll paperwork, such as 1099s you've received or issued.</li>
        </ul>
      </div>

      <div class="recaptcha mt-3 mb-3">
        <re-captcha formControlName="recaptcha"></re-captcha>
        <mat-error *ngIf="recaptcha.invalid && recaptcha.touched">
          <div class="error">
            <div class="error-text">Recaptcha is required</div>
          </div>
        </mat-error>
      </div>

      <div class="submit-wrapper">
        <button class="upsc-button" type="submit" *ngIf="!loading">
          Confirm Details <mat-icon>chevron_right</mat-icon>
        </button>
        <button type="button" class="upsc-button continuing" *ngIf="loading">
          <div>Confirm Details</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
      </div>
      <div class="error" *ngIf="verificationCodeError">
        Verification Code did not match
      </div>
      <div class="error" *ngIf="systemError">
        System error occurred. Please try again later.
      </div>
      <div class="error" *ngIf="denied">
        Unfortunately at this time we are unable to process your request.
      </div>
    </form>
  </div>
  <div class="addressValidation" *ngIf="showAddressValidation">
    <upsc-address-validation [currentAddressFromProspect]="currentAddress" [addressListFromProspect]="addressList" (userInput)="updateAddress($event)">
    </upsc-address-validation>
  </div>
</div>
