import { Component, OnInit } from '@angular/core';
import { TaggingService } from 'src/app/tags/tagging.service';

//Some of the sorting logic taken from https://material.angular.io/components/sort/overview

@Component({
  selector: 'upsc-payee',
  templateUrl: './payee.component.html',
  styleUrls: ['./payee.component.scss']
})
export class PayeeComponent {


  constructor( 
    private taggingService: TaggingService
  ) { }

  ngOnInit() {
    this.taggingService.view();
  }
}
