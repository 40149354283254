import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { InsureshieldFooterComponent } from './insureshield-footer.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [InsureshieldFooterComponent],
  imports: [
    SharedModule,
    MatDialogModule
  ],
  providers: [],
  exports: [InsureshieldFooterComponent]
})
export class InsureshieldFooterModule { }