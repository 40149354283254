import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string): string {
    return this.formatPhoneNumber(value);
  }
  isUsaUser: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  constructor(userService: UserService){
    this.isUsaUser = userService.isUsaUser();
    this.isUkUser = userService.isUkUser();
    this.isCanadaUser = userService.isCanadaUser();
    this.isFranceUser = userService.isFranceUser();
    this.isItalyUser = userService.isItalyUser();
    this.isGermanyUser = userService.isGermanyUser();
  }
  formatPhoneNumber(val) {
    if (val) {
      //let newStr = "";
      let newVal = val.replace(/\D/g, '');
      if(this.isUsaUser){
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 3) {
          newVal = newVal;
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1.$2');
        } else if (newVal.length <= 10) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3');
        } else {
          newVal = newVal.substring(0, 10);
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3');
        }
        return newVal;
      }
      else if(this.isCanadaUser){
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length <= 3) {
        newVal = newVal;
      } else if (newVal.length <= 6) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
      } else if (newVal.length <= 10) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
      }else if (newVal.length > 10) {
        newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3-$4');
      } else {
        newVal = newVal.substring(0, 10);
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
      }
      return newVal;
    }
    else if(this.isUkUser || this.isItalyUser || this.isFranceUser || this.isGermanyUser) {
      if (newVal.length === 0) {
        newVal = '';
      }
      return val;
    }
  }
}
}
