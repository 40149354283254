import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BillingService } from 'src/app/services/billing.service';
import { LoginService } from 'src/app/services/login.service';
import { PolicyService } from 'src/app/services/policy.service';
import { UserService } from 'src/app/services/user.service';
import { UserPermission } from '../../models/user-permission';

@Component({
  selector: 'upsc-digital-wallet-banner',
  templateUrl: './digital-wallet-banner.component.html',
  styleUrls: ['./digital-wallet-banner.component.scss']
})
export class DigitalWalletBannerComponent {

  showComingSoonBanner: boolean = false;
  showEarnPointsBanner: boolean = false;
  showIS4UBanner: boolean = false;
  dwDetails: any;
  policyDetails: any;
  permissions: UserPermission;
  isUsaUser: boolean;
  canAccessDigitalWallet: boolean;
  isChannelPartner: boolean;

  constructor(
    private router: Router,
    public loginService: LoginService,
    public userService: UserService,
    public policyService: PolicyService,
    public billingService: BillingService
    ) {
      this.isUsaUser = this.userService.isUsaUser();
      this.dwDetails = this.loginService.getDWDetails();
      this.policyDetails = this.loginService.getPolicyDetails();
      this.permissions = this.userService.getUserPermissions();
      this.canAccessDigitalWallet = this.userService.canAccessDigitalWallet();
      this.isChannelPartner = this.policyService.isChannelPartnerPolicy();
      
      if (this.isUsaUser) {
        this.displayBanner();
      }
  }

  displayBanner() {
    // --------------------------------------------------------------------------------------------------------------------------------------------
    // 6/30 Banner Conditions
    // We will show the "Earn Points" banner under these conditions:
    // 1. They are a US user
    // 2. They have a wallet with walletID field not null (they are part of the 50 pilot users that have a wallet)
    // 3. Their rewardseligible field is true (they are not IS4U. If they are, they aren't allowed to earn points)
    // 4. We are on the dashboard page (url == /dashboard)
    // 5. They are NOT a channel partner policy
    // 6. See below getIndicators call for last condition (if they've already updated profile, don't show the banner anymore)
    if (this.router?.url == '/dashboard' && this.isUsaUser && this.dwDetails?.walletID && this.dwDetails?.rewardEligible == "True" && !this.isChannelPartner) {
      // Split into two scenarios. Check indicators to determine if we should actually show the banner
      let request = { policy_Number: this.policyDetails?.policyNumber }
      this.billingService.DWgetIndicators(request).subscribe(
        data => {
          if (data?.data?.dW_Profile && data?.data?.dW_Banner) {
            // Scenario #1: Indicator says they've already updated their profile and earned points, so don't show the banner.
            this.showComingSoonBanner = false;
            this.showEarnPointsBanner = false;
            this.showIS4UBanner = false;
          } else {
            // Scenario #2: Indicators are false, means they haven't updated their profile yet to earn points, so show the banner.
            this.showComingSoonBanner = false;
            this.showEarnPointsBanner = true;
            this.showIS4UBanner = false;
          }
        }, error => {
          // Default to not showing the banner if API fails as a safeguard (use scenario #1)
          this.showComingSoonBanner = false;
          this.showEarnPointsBanner = false;
          this.showIS4UBanner = false;
        }
      );
    }
    // We will show the "IS4U" banner under these conditions
    // 1. They are a US user
    // 2. They have a wallet with walletID field not null (they are part of the 50 pilot users that have a wallet)
    // 3. Their rewardseligible field is false (they are IS4U and not allowed to earn reward points)
    // 4. They are NOT a channel partner policy
    // 5. We are on the dashboard page (url == /dashboard)
    else if (this.router?.url == '/dashboard' && this.isUsaUser && this.dwDetails?.walletID && this.dwDetails?.rewardEligible == "False" && !this.isChannelPartner) {
      this.showComingSoonBanner = false;
      this.showEarnPointsBanner = false;
      this.showIS4UBanner = true;
    }
    // We will show the "Coming Soon" banner under these conditions
    // 1. They are a US user
    // 2. We are on the dashboard page (url == /dashboard)
    // 3. They don't have a wallet (they didn't see one of the two banners above)
    // 4. They are NOT a channel partner policy
    else if (this.router?.url == '/dashboard' && this.isUsaUser && !this.isChannelPartner) {
      this.showComingSoonBanner = true;
      this.showEarnPointsBanner = false;
      this.showIS4UBanner = false;
    }
    // We will not show either banner if they do not meet the condition criteria for either scenario.
    // CA/EU and Channel Partners will not see any banner.
    else {
      this.showComingSoonBanner = false;
      this.showEarnPointsBanner = false;
      this.showIS4UBanner = false;
    }

    // If they've already closed the banner at least once in this login session, let's not show the banner to them again
    // This overrides any logic from above
    if (JSON.parse(sessionStorage.getItem('hasClosedComingSoonBanner'))) {
      this.showComingSoonBanner = false;
    }
    if (JSON.parse(sessionStorage.getItem('hasClosedEarnPointsBanner'))) {
      this.showEarnPointsBanner = false;
    }
    if (JSON.parse(sessionStorage.getItem('hasClosedIS4UBanner'))) {
      this.showIS4UBanner = false;
    }
  }

  navigateToInfoPage() {
    window.open("https://www.insureshield.com/us/en/resources/help-and-support/digital-wallet.html", '_blank');
  }

  closeBanner(source) {
    if (source == 'coming-soon') {
      this.showComingSoonBanner = false;
      sessionStorage.setItem('hasClosedComingSoonBanner', JSON.stringify('true'));
    } else if (source == 'earn-points') {
      this.showEarnPointsBanner = false;
      sessionStorage.setItem('hasClosedEarnPointsBanner', JSON.stringify("true"));
    } else if (source == 'is4u') {
      this.showIS4UBanner = false;
      sessionStorage.setItem('hasClosedIS4UBanner', JSON.stringify("true"));
    }
  }

  earnPointsBanner() {
    this.showEarnPointsBanner = false;
    sessionStorage.setItem('hasClosedEarnPointsBanner', JSON.stringify("true"));
    this.router.navigate(['/profile']); 
  }
}
