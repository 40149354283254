import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClaimService } from 'src/app/services/claim.service';
import { UserService } from 'src/app/services/user.service';
import { LoginService } from 'src/app/services/login.service';
import { IPolicies } from 'src/app/shared/models/policies.interface';
import { IMsgSummary } from 'src/app/shared/models/claim.interface';
import { retry } from 'rxjs/operators';
import { listOfMessages } from 'src/app/shared/models/claim.interface';


@Component({
  selector: 'upsc-message-adjuster',
  templateUrl: './message-adjuster.component.html',
  styleUrls: ['./message-adjuster.component.scss']
})
export class MessageAdjusterComponent implements OnInit {
  @ViewChild('messageInp') messageInp: ElementRef;
  @Input() claimData;
  @Input() customAuthor;  //This is currently only used for My Choice. We have to take the author name from the claim data rather than any user object in session.
  @Input() canMessageAdjuster: boolean;
  adjuster: string = "Michael Hayden";
  claimNumber: string = "";
  isData: boolean = false;

  //Pagination
  pageSize: number = 5;
  pageNumber: number = 1;

  pagedItems: any[];

  isLastPage: boolean = false;
  isFirstPage: boolean = true;

  visibleRangeLength = 4;
  visiblePages: number[];

  //Form
  messageForm: UntypedFormGroup;

  //Display
  showButtons: boolean = false;
  systemError: boolean = false;
  scriptError: boolean = false;
  policy: IPolicies;
  summary: IMsgSummary = {};

  allClaimNumbers: string[];
  emptySearchResult: boolean = false;
  emptyClaimResult: boolean = false;
  pager: any = {};
  public currentClaim;
  searchClaim: string = "";

  messageDetails: listOfMessages[] = [];
  pageTotals: any[] = [];
  totalPages: number = 0;
  totalCount: number = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private claimService: ClaimService,
    private userService: UserService,
    public loginService: LoginService
  ) {
    this.policy = this.loginService.getPolicyDetails();

    this.messageForm = this.fb.group({
      message: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getMsgIds();
  }

  paginate(pageNumber: number) {
    try {
      this.isData = true;
      var result = Object.values(this.messageDetails).slice((pageNumber - 1) * this.pageSize, pageNumber * this.pageSize);
      return result;
    }
    catch (e) {
      this.isData = false;
    }
    return null;
  }

  loadPage(row: any) {
    this.pageNumber = row;
    this.pagedItems = this.paginate(row);
    this.updateVisiblePages();
  }

  next(pageNumber) {
    this.pageNumber = pageNumber + 1;
    if (this.pageNumber > this.totalPages) {
      this.pageNumber = this.totalPages;
    }
    this.loadPage(this.pageNumber);
  }

  previous(pageNumber) {
    this.pageNumber = pageNumber - 1;
    if (this.pageNumber < 1) {
      this.pageNumber = 1;
    }
    this.loadPage(this.pageNumber);
  }

  sendMessage(defaultMessage?: string) {
    this.systemError = false;
    this.message.value.trim();
    if (this.messageForm.valid || defaultMessage) {
      let request = {
        policyNumber: this.policy.policyNumber,
        claimNumber: this.claimData,
        message: defaultMessage ? defaultMessage : this.message.value,
        messages: [{
          author: this.customAuthor ? this.customAuthor : this.userService.getUserInfo()?.contactName,
          body: defaultMessage ? defaultMessage : this.message.value,
          claimId: "",
          claimNumber: this.claimData,
          isAdjuster: false,
          isAutoMessage: defaultMessage && defaultMessage == "Nope, that's all thank you!" ? true : false,
          isMessageRead: true,
          isMessageSoftDeleted: false,
          time: new Date().toISOString(),
          topic: "general" //hardcode
        }],
        totalCount: 1
      }

      this.claimService.postMessage(request).subscribe(
        response => {
          this.scriptError = false;
          if (response.message == 'Messages created/updated successfully') {
            this.messageForm.reset();
            this.getMsgIds();
            //window.location.reload();
          } else {
            this.systemError = true;
          }
        }, error => {
          if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
            this.scriptError = true;
          }
          else {
            this.systemError = true;
          }
        }
      );
    } else {
      this.messageInp.nativeElement.focus();
    }
  }

  //get msg() { return this.messageForm.controls.search }

  //Sort messages by date. Newest messages appear first in the array
  sortMessages() {
    this.messageDetails.sort((a, b) => {
      return <any>new Date(b.time) - <any>new Date(a.time);
    });
  }

  //determines whether or not to display buttons vs input textbox
  determineButtonDisplay() {
    let firstMessage = this.messageDetails.length >= 1 ? this.messageDetails[0] : null;
    if (firstMessage?.isAdjuster && firstMessage?.isAutoMessage == true && firstMessage.body.toLowerCase() != 'thank you!') {
      return true;
    } else {
      return false;
    }
  }

  //getters
  get message() { return this.messageForm.controls.message; }

  getMessageDetails(messageIds: Array<string>) {
    this.claimService.getMsgDetailsByIds(this.policy.policyNumber, "give me all message ids",
      this.claimData, messageIds).pipe(
        retry(3)
      ).subscribe(msgDetails => {

        this.isData = true;
        this.messageDetails = []; //everytime we get message details, clear the array so duplicate messages don't appear
        msgDetails.messages.map(msg => {

          try {

            if (msg.isAdjuster === null) {
              msg.isAdjuster = false;
            }

            var authorInitials = msg.author.split(" ");
            if (authorInitials.length > 2) {
              msg.authorInitial = authorInitials[0].substring(0, 1) + authorInitials[2].substring(0, 1);
            }
            else if (authorInitials.length === 1) {
              msg.authorInitial = authorInitials[0].substring(0, 1);
            }
            else {
              msg.authorInitial = authorInitials[0].substring(0, 1) + authorInitials[1].substring(0, 1);
            }
          }
          catch (e) {
            msg.authorInitial = "";
          }

          this.messageDetails.push(msg);
          this.totalCount = msgDetails.totalCount;
        });

        var tPages = Math.ceil(this.totalCount / this.pageSize);
        this.totalPages = tPages;
        for (let i = 1; i <= tPages; i++) {
          this.pageTotals.push(i);
        }
        this.updateVisiblePages();

        this.sortMessages();
        this.pagedItems = this.paginate(this.pageNumber);
        this.showButtons = this.determineButtonDisplay(); //Needs to happen after messages are instantiated
        this.markMessagesAsRead();
      },
        error => this.handlNoResults()
      );
  }

  private updateVisiblePages(): void {

    const length = Math.min(this.totalPages, this.visibleRangeLength);

    const startIndex = Math.max(
      Math.min(
        this.pageNumber - Math.ceil(length / 2),
        this.totalPages - length
      ),
      0
    );

    this.visiblePages = Array.from(
      new Array(length).keys(),
      (item) => item + startIndex + 1
    );
  }

  getMsgIds(): void {
    this.summary.policyNumber = this.policy.policyNumber;
    this.summary.message = "give me all message ids";
    this.summary.claimNumber = this.claimData;

    this.claimService.getAllMessageIDs(this.summary).pipe(
      retry(3)
    ).subscribe(data => {
      if (data.messageIDs.length > 0) {
        this.getMessageDetails(data.messageIDs);
      }
    },
      error => this.handlNoResults()
    );

  }

  handlNoResults() {
    this.isData = false;
  }

  markMessagesAsRead() {
    let unreadMessageList = this.messageDetails.filter(x => x.isMessageRead == false);
    let request = {
      policyNumber: this.policy.policyNumber,
      claimNumber: this.claimData,
      message: 'Marking messages as read',
      messages: [],
      totalCount: unreadMessageList.length
    }

    for (let i = 0; i < unreadMessageList.length; i++) {
      request.messages.push({
        author: unreadMessageList[i].author,
        body: unreadMessageList[i].body,
        claimNumber: this.claimData,
        isAdjuster: unreadMessageList[i].isAdjuster,
        isAutoMessage: unreadMessageList[i].isAutoMessage,
        isMessageRead: true,
        isMessageSoftDeleted: false,
        messageId: unreadMessageList[i].messageId,
        time: new Date().toISOString(),
        topic: "general" // hardcode
      });
    }

    if (request.messages && request.messages.length > 0) {
      this.claimService.postMessage(request).subscribe(
        response => {
          //don't think anything needs to happen here.
        }, error => {
          //Nothing needs to happen if fails. User can still use application
        }
      );
    }
  }
}
