import { Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PayeeService } from 'src/app/services/payee.service';
import { States } from 'src/config/metadata-config';
import { AccountInfo } from '../../models/account-info.interface';
import { IClaim, emptyClaim, IDocument, IContact } from '../../models/claim.interface';
import { ILogger } from '../../models/logger.interface';
import { IStep } from '../stepper/models/step.interface';
import * as REGEX from '../../../shared/regex-patterns'
import { TranslateService } from '@ngx-translate/core';
import { ClaimService } from 'src/app/services/claim.service';
import { LoginService } from 'src/app/services/login.service';
import { IPolicies } from '../../models/policies.interface';
import { environment as ENV } from '../../../../environments/environment';
import { UserService } from 'src/app/services/user.service';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PhoneNumberPipe } from '../../pipes/phone-number.pipe';

@Component({
  selector: 'upsc-update-claim-payment-recipient-popup',
  templateUrl: './update-claim-payment-recipient-popup.component.html',
  styleUrls: ['./update-claim-payment-recipient-popup.component.scss']
})
export class UpdateClaimPaymentRecipientPopupComponent implements OnInit {
  @Output() refreshBankNumbers = new EventEmitter<any>();
  
  @HostListener('window:resize', ['$event'])
  //Prevents Internet Explorer from navigating backwards when the Backspace is pressed.
  @HostListener('document:keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    if (
      evt.keyCode === 8 || evt.which === 8
    ) {
      let doPrevent = true;
      const types = ['text', 'password', 'file', 'search', 'email', 'number', 'date', 'color', 'datetime', 'datetime-local', 'month', 'range', 'search', 'tel', 'time', 'url', 'week'];
      const target = (<HTMLInputElement>evt.target);

      const disabled = target.disabled || (<HTMLInputElement>event.target).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }
  }
  payee;
  loading = false;
  contactInfoFormGroup: UntypedFormGroup;
  tupssType; //will be tfcp or tccp
  showACH = false;
  showACHError = false;
  showUpdatePayeeInfo = true;
  systemError = false;
  systemAPIError = false;
  show = false;
  continuing = false; //Used with the continue button's progress
  payeeSelectionValid: boolean = false; //Let user continue from payee page. data received from payee-table component
  mobilePayeeSelectionValid: boolean = false;
  windowSize: any = window.innerWidth;
  subscription: Subscription;
  showAddressValidation = false;
  showSuccessMessage = false;
  //Interfaces
  public claim: IClaim = emptyClaim;
  public steps: IStep[];
  public logInterface: ILogger = {};
  public docRequest: IDocument = {};
  public userDetails: AccountInfo = {};
  public mainContact: IContact = {};
  persistPayeeInfo;
  mailingInfo = {
    "insured": "",
    "streetAddress1": "",
    "streetAddress2": "",
    "city": "",
    "state": "",
    "zip": "",
    "country": ""
  }
  //Notification booleans
  payeeNotification: boolean = false;
  errorNotification: boolean = false;
  //documents
  public policy: IPolicies = {};
  //Metadata / Data Lists
  public countryList = [];
  public stateList: any[] = States; //We never pull list of states from metadata API, only comes from config file
  constructor(public dialogRef: MatDialogRef<UpdateClaimPaymentRecipientPopupComponent>,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private claimService: ClaimService,
    private loginService: LoginService,
    private payeeService: PayeeService,
    private userService: UserService,
    private phonePipe: PhoneNumberPipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    fromEvent(window, 'resize').pipe(
      debounceTime(500)).subscribe(
        data => {
          if (data.target['innerWidth'] >= 768 && this.windowSize < 768) {
            this.mobilePayeeSelectionValid = false;
          } else if (data.target['innerWidth'] < 768 && this.windowSize >= 768) {
            this.payeeSelectionValid = false;
          }
          this.windowSize = data.target['innerWidth'];
        }
      )
    this.payee = data.payee;
    // this.tupssType = JSON.parse(sessionStorage.getItem('tupssUserDetails')).type;
    //Temporary code to continue development
    this.tupssType = 'tccp';
    this.userDetails = this.userService.getUserInfo();
    this.contactInfoFormGroup = this.formBuilder.group({
      name: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])],
      email: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required, Validators.email])],
      phone: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required, Validators.pattern(/\d{1,3}/)])],
      addressLine1: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])],
      addressLine2: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }],
      city: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])],
      state: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])],
      zip: [{ value: '', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required, Validators.pattern(REGEX.TUPSS_FLOW_ZIP_PATTERN)])],
      ach: [{ value: data.payee?.ach?.startsWith('**') ? 'ach' : 'check', disabled: this.tupssType == 'tfcp' ? true : false }, Validators.compose([Validators.required])]
    });

    this.contactInfoFormGroup.get('email').valueChanges.subscribe(val => {
      var atIndex = val.indexOf('@');
      if (atIndex == -1) {
      } else {
        //Check if there is a period after the @
        var dotIndex = val.substring(atIndex).indexOf('.');
        if (dotIndex == -1) {
          this.contactInfoFormGroup.get('email').setErrors({ 'invalid': true });
        }
      }
    });
  }

  //Loads all MetaData for claims flow
  public getMetaData() {
    this.claimService.getMetaData().subscribe(
      data => {
        try {
          this.countryList = Object.entries(data.metaData.country).map(([key, value]) => ({ key, value }));
          this.countryList.sort(this.compare);

        } catch (e) {
          //Load local metadata
          //this.loadLocalMetaData();
        }
      }, error => {
        // Load local metadata
        ///this.loadLocalMetaData();
      }
    );
  }
  //Sorts an array alphabetically
  //If needing case insensitive, add .toUpperCase()
  //To use: this.someArray.sort(this.compare);
  public compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }
  //Used to manually check all fields & show errors
  validateAllFormFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //This function just does addressValidation(), and then it will subsequently call DPS
  public addressValidation(event) {
    this.payeeService.recipientAddFlow.addressValidationFlow = "1";
    this.continuing = true;
    this.errorNotification = false;
    if (this.contactInfoFormGroup.invalid) {
      //Some form was invalid.
      this.validateAllFormFields(this.contactInfoFormGroup);
      this.errorNotification = true;
      this.continuing = false;
      window.scrollTo(0, 0);
    } else {
      //Forms were valid, continue with address validation
      this.gatherFormValues();
      this.claimService.addressValidation({
        customerName: this.contactInfoFormGroup.controls.name.value,
        addressLine1: this.contactInfoFormGroup.controls.addressLine1.value,
        addressLine2: this.contactInfoFormGroup.controls.addressLine2.value,
        city: this.contactInfoFormGroup.controls.city.value,
        countrycode: 'US',
        zipcode: this.contactInfoFormGroup.controls.zip.value,
        state: this.contactInfoFormGroup.controls.state.value
      }).subscribe(
        data => {
          if (data.candidateList.length > 0) {
            let hasMatch = false;
            for (let a of data.candidateList) {
              if (
                a.AddressLine[0] == this.contactInfoFormGroup.controls.addressLine1.value &&
                a.AddressLine2 == this.contactInfoFormGroup.controls.addressLine2.value &&
                a.PoliticalDivision2 == this.contactInfoFormGroup.controls.city.value &&
                a.PoliticalDivision1 == this.contactInfoFormGroup.controls.state.value &&
                `${a.PostcodePrimaryLow}-${a.PostcodeExtendedLow}` == this.contactInfoFormGroup.controls.zip.value &&
                a.CountryCode == 'US'
              ) {
                hasMatch = true;
                break;
              }
            }
            if (hasMatch) {
              //Address was perfectly entered. Continue to next part
              this.deniedPartyAndCreatePayee();
            } else {
              //Let user pick from address validation
              this.continuing = false;
              this.claimService.setTupssAddress({
                address1: this.contactInfoFormGroup.controls.addressLine1.value,
                address2: this.contactInfoFormGroup.controls.addressLine2.value,
                city: this.contactInfoFormGroup.controls.city.value,
                state: this.contactInfoFormGroup.controls.state.value,
                zip: this.contactInfoFormGroup.controls.zip.value,
              });
              this.claimService.setAddValData(data.candidateList);
              this.showAddressValidation = true;
              this.showUpdatePayeeInfo = false;
            }
          } else {
            //If address validation returns nothing... Just continue?
            this.deniedPartyAndCreatePayee();
          }
        }, error => {
          this.deniedPartyAndCreatePayee();
          //If address validation fails... Just continue?
        }
      );
    }
  }
  // get isMobile() {
  //   return this.breakpointObserver.isMatched('(max-width: 767px)');
  // }
  gatherFormValues() {
    //Payee Data
    this.claim.payeeId = this.persistPayeeInfo ? this.persistPayeeInfo.payeeID : '';
    //Contact Info Form Group
    if (!this.contactInfoFormGroup.invalid) {
      this.claim.mainContact.firstName = this.contactInfoFormGroup.controls.name.value;
      this.claim.mainContact.workNumber = this.contactInfoFormGroup.controls.phone.value;
      this.claim.mainContact.emailAddress1 = this.contactInfoFormGroup.controls.email.value;
      this.mailingInfo.insured = this.contactInfoFormGroup.controls.name.value;
      this.mailingInfo.streetAddress1 = this.contactInfoFormGroup.controls.addressLine1.value;
      this.mailingInfo.streetAddress2 = this.contactInfoFormGroup.controls.addressLine2.value;
      this.mailingInfo.city = this.contactInfoFormGroup.controls.city.value;
      this.mailingInfo.state = this.contactInfoFormGroup.controls.state.value;
      this.mailingInfo.zip = this.contactInfoFormGroup.controls.zip.value;
      this.mailingInfo.country = 'US';

    }

  }
  addBankingInfo() {
    this.payeeService.getCardInfo(this.persistPayeeInfo.payeeID, this.userDetails.policyNumber).subscribe(
      data => {
        if (data.accountNumberLast4) {
          this.showSuccessMessage = true;
        } else {
          this.payeeService.initiatePublicSessionTransfer(
            {
              "payeeId": this.persistPayeeInfo.payeeID,
              "returnSessionId": this.persistPayeeInfo.payeeID,  //This will be the same as payeeId. Used to update ACH indicator after adding bank info
              "returnUrl": `${ENV.baseUrl.web}claims/bankSuccess`
            }
          ).subscribe(
            data => {
              //Check if result.code == S031 for success
              if (data.result.code == 'S031') {
                this.showACHError = false;
                this.payeeService.achURL = data.sessionTransferUrl;
                this.payeeService.recentPayeeSubmission = { ...this.persistPayeeInfo, payeeID: this.persistPayeeInfo.payeeID };
                this.showACH = true;
              } else {
                //Send error notification to service, display error message instead of iframe
                this.showACHError = true;
                this.showACH = true;
              }
            },
            error => {
              this.showACHError = true;
              this.showACH = true;
            }
          );
        }
      }
    );
  }
  createPayee() {
    if (!this.contactInfoFormGroup.invalid) {
      this.continuing = true;
      this.payeeService.createPayee({
        userId: '-987654321',
        createdByUserId: '-987654321',
        policyNumber: this.userDetails.policyNumber,
        policyCountry: 'US',
        emailAddress: this.contactInfoFormGroup.controls.email.value,
        payeeName: this.contactInfoFormGroup.controls.name.value,
        domesticPhoneNumber: this.contactInfoFormGroup.controls.phone.value.replaceAll('.', ''),
        guestAccessIndicator: 'F',
        domesticMailingAddress: {
          streetAddress1: this.contactInfoFormGroup.controls.addressLine1.value,
          streetAddress2: this.contactInfoFormGroup.controls.addressLine2.value == null ? '' : this.contactInfoFormGroup.controls.addressLine2.value,
          city: this.contactInfoFormGroup.controls.city.value,
          stateProvince: this.contactInfoFormGroup.controls.state.value,
          zip: this.contactInfoFormGroup.controls.zip.value.split("-")[0] ? this.contactInfoFormGroup.controls.zip.value.split("-")[0] : this.contactInfoFormGroup.controls.zip.value,
          country: 'US'
        },
        payeeId: this.payee ? this.payee.payeeID : ''
      }).subscribe(
        data => {
          this.errorNotification = false;
          //Attach payeeID to claim requests
          this.claim.payeeId = data.payeeID;
          this.persistPayeeInfo = {
            payeeID: data.payeeID,
            payeeName: this.contactInfoFormGroup.controls.name.value,
            payeeEmail: this.contactInfoFormGroup.controls.email.value,
            payeePhone: this.contactInfoFormGroup.controls.phone.value,
            address: {
              streetAddress1: this.contactInfoFormGroup.controls.addressLine1.value,
              streetAddress2: this.contactInfoFormGroup.controls.addressLine2.value,
              city: this.contactInfoFormGroup.controls.city.value,
              stateProvince: this.contactInfoFormGroup.controls.state.value,
              zip: this.contactInfoFormGroup.controls.zip.value,
              country: 'US'
            },
            achIndicator: 'F'
          };
          // this.saveClaim3();
          if (this.contactInfoFormGroup.controls.ach.value == 'ach') {
            this.addBankingInfo();
            this.claimService.setLoading(false);
            this.continuing = false;
            this.showAddressValidation = false;
          } else {
            //this.setStep(event, 4);
            this.claimService.setLoading(false);
            this.continuing = false;
            this.showAddressValidation = false;
            this.showSuccessMessage = true;
          }
        }, error => {
          this.continuing = false;
          this.errorNotification = true;
          this.systemAPIError = true;
        }
      );
    } else {
      //this.validateAllFormFields(this.submitClaimFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }
  deniedPartyAndCreatePayee() {
    this.userService.deniedPartyCheckV2({
      company: this.contactInfoFormGroup.controls.name.value,
      address: this.contactInfoFormGroup.controls.addressLine1.value,
      city: this.contactInfoFormGroup.controls.city.value,
      state: this.contactInfoFormGroup.controls.state.value,
      country: 'US',
      zip: this.contactInfoFormGroup.controls.zip.value
    }).subscribe(
      deniedPartyFound => {
        this.errorNotification = false;
        this.systemAPIError = false;
        if (!deniedPartyFound) {
          this.createPayee()
          // this.saveClaim3();
        } else {
          this.payeeService.dpsUnavailable = false;
          this.continuing = false;
          this.claimService.setLoading(false);
          // this.router.navigate(['/claims/payee/unable-to-fulfill']);
        }
      }, error => {
        // this.saveClaim3();
        window.scrollTo(0, 0);
        this.errorNotification = true;
        this.systemAPIError = true;
        this.payeeService.dpsUnavailable = true;
        this.continuing = false;
        this.claimService.setLoading(false);
        // this.router.navigate(['/claims/payee/unable-to-fulfill']);
      }
    );
  }
  updateAddress(newAddress?) {
    this.claimService.setLoading(true);
    this.errorNotification = false;
    if (newAddress && !(newAddress == 'edit')) {
      //Update new address for contact info.. then call deniedPartyAndCreatePayee();
      this.contactInfoFormGroup.controls.addressLine1.setValue(newAddress.AddressLine[0]);
      this.contactInfoFormGroup.controls.addressLine2.setValue(newAddress.AddressLine2);
      this.contactInfoFormGroup.controls.city.setValue(newAddress.PoliticalDivision2);
      this.contactInfoFormGroup.controls.state.setValue(newAddress.PoliticalDivision1);
      this.contactInfoFormGroup.controls.zip.setValue(`${newAddress.PostcodePrimaryLow}-${newAddress.PostcodeExtendedLow}`);
      this.deniedPartyAndCreatePayee();
    } else if (newAddress && newAddress == 'edit') {  //They wanted to go back
      this.showAddressValidation = false;
      this.showUpdatePayeeInfo = true;
      this.claimService.setLoading(false);
    } else if (!newAddress) {  //Continue with what was originally entered
      this.claimService.getTupssAddress().subscribe(
        data => {
          this.contactInfoFormGroup.controls.addressLine1.setValue(data.address1);
          this.contactInfoFormGroup.controls.addressLine2.setValue(data.address2);
          this.contactInfoFormGroup.controls.city.setValue(data.city);
          this.contactInfoFormGroup.controls.state.setValue(data.state);
          this.contactInfoFormGroup.controls.zip.setValue(data.zip);
          this.deniedPartyAndCreatePayee();
        }
      );
    }
  }
  close() {
    this.refreshBankNumbers.emit(true);
    this.dialogRef.close();
  }
  loadContactInfoData() {
    this.contactInfoFormGroup.controls.name.setValue(this.payee.payeeDetails.fullName);
    this.contactInfoFormGroup.controls.email.setValue(this.payee.payeeDetails.email);
    this.contactInfoFormGroup.controls.phone.setValue(this.payee.payeeDetails.phone ? this.phonePipe.transform(this.payee.payeeDetails.phone) : '');
    this.contactInfoFormGroup.controls.addressLine1.setValue(this.payee.payeeDetails.addressLine1);
    this.contactInfoFormGroup.controls.addressLine2.setValue(this.payee.payeeDetails.addressLine2);
    this.contactInfoFormGroup.controls.state.setValue(this.payee.payeeDetails.stateKey);
    this.contactInfoFormGroup.controls.city.setValue(this.payee.payeeDetails.city);
    this.contactInfoFormGroup.controls.zip.setValue(this.payee.payeeDetails.zip);
  }
  public ngOnInit() {
    this.getMetaData();
    this.loadContactInfoData();
  } //End of ngOnInit

  achBack() {
    this.showACH = false;
    this.showUpdatePayeeInfo = true;
  }

  achContinue(event) {
    this.continuing = true;
    this.payeeService.getCardInfo(this.persistPayeeInfo.payeeID, this.userDetails.policyNumber).subscribe(
      data => {
        if (data.accountNumberLast4) {
          //ACH was saved. Update the ACH info indicator & continue to next page.
          // this.accountNumberLast4 = data.accountNumberLast4;
          this.payeeService.updateACHIndicator({
            payeeID: this.persistPayeeInfo.payeeID,
            achIndicator: 'T'
          }).subscribe(
            data => {
              this.showACH = false;
              this.showACHError = false;
              this.continuing = false;
              this.showSuccessMessage = true;
              // this.setStep(event, 4);
            },
            error => {
              this.showACH = false;
              this.showACHError = false;
              this.continuing = false;
              this.showSuccessMessage = true;
              // this.setStep(event, 4);
            }
          );
        } else {
          this.continuing = false;
          this.showACHError = true;
        }
      }
    );
  }
}

