import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProspectService } from 'src/app/services/prospect.service';
import { COMMODITY_LISTS } from 'src/app/shared/models/prospect-portal';
import { TaggingService } from 'src/app/tags/tagging.service';

@Component({
  selector: 'upsc-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  //Page logic
  submitting: boolean = false;
  pageLoading: boolean = true;
  upcomingSunday;
  expirationDate;

  //Errors
  systemError: boolean = false;
  submissionError: boolean = false;

  //Data
  prospectPortalData; //Data object that holds all data for prospect portal

  constructor (
    private prospectService: ProspectService,
    private router: Router,
    private taggingService: TaggingService
  ) {
    this.upcomingSunday = this.determineUpcomingSunday();
    this.expirationDate = this.determineExpirationDate();
    this.gatherSessionData();
  }

  ngOnInit() {
    this.taggingService.view({
      page_id: "quote_page",
      page_name: "Quote Page"
    });
  }

  decline() {
    this.taggingService.link({
      link_name: "Decline",
      link_page_id: "quote_page"
    });
    this.router.navigate(['/prospect-portal/decline']);
    window.scrollTo(0,0);
  }

  submit() {
    this.taggingService.link({
      link_name: "Accept",
      link_page_id: "quote_page"
    });
    this.submitting = true;
    this.submissionError = false;
    //Bind Quote API:
    let request = this.formBindPolicyRequest();

    this.prospectService.bindPolicyCRM(request).subscribe(
      data => {
        this.submitting = false;
        if (data && data.status?.toLowerCase() == 'success' && data.policyNumber) {
          this.prospectPortalData = { ...this.prospectPortalData,
            accountNumber: data.accountNumber,
            jobNumber: data.jobNumber,
            upcomingSunday: this.upcomingSunday,
            expirationDate: this.expirationDate,
            policyNumber: data.policyNumber,
            currentPage: '/prospect-portal/success'
          }
          sessionStorage.setItem('prospectPortalData', JSON.stringify(this.prospectPortalData));
          this.saveSession();
          this.router.navigate(['prospect-portal/success']);
          window.scrollTo(0,0);
        } else {
          this.submissionError = true;
        }
      },
      error => {
        this.submitting = false;
        this.submissionError = true;
      }
    );
  }

  gatherSessionData() {
    this.prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData'));
    
    if (this.prospectPortalData && this.prospectPortalData.rate) {
      this.pageLoading = false;
      this.systemError = false;
    } else {
      this.pageLoading = false;
      this.systemError = true;
    }
  }

  determineShippers() {
    let carrierArray = [];
    let hasUPS: boolean = false;
    let shipperNumbers = this.prospectPortalData.shipperNumbers;
    hasUPS = this.prospectPortalData.selectedCarriers.filter(x => x.toLowerCase() == 'ups').length > 0 ? true : false;

    if (hasUPS) {
      for (let i = 0; i < shipperNumbers.length; i++) {
        carrierArray.push({
          shipperCarrier: {
            code: 'ups'
          },
          shipperNumber: shipperNumbers[i],
          startDate: `${this.upcomingSunday?.getFullYear()}-${this.upcomingSunday?.getMonth()+1}-${this.upcomingSunday?.getDate()}`
        });
      }
    }

    //Working testing data
    // carrierArray = [{
    //   shipperCarrier: {
    //       code: "ups"
    //   }, 
    //   shipperNumber: "TestShipper1", 
    //   startDate: "2027-09-22"
    // }]

    return carrierArray;
  }

  determineUpcomingSunday(): Date {
    let now = new Date();
    let result = new Date();
    if (now.getDay() == 0) {  //today is Sunday
      result.setDate(now.getDate() + 7);
    } else if (now.getDay() == 1) {  //today is monday
      result.setDate(now.getDate() + 6);
    } else if (now.getDay() == 2) {  //today is tuesday
      result.setDate(now.getDate() + 5);
    } else if (now.getDay() == 3) {  //today is wednesday
      result.setDate(now.getDate() + 4);
    } else if (now.getDay() == 4) {  //today is thursday
      if (now.getHours() > 12) {  //today is after 12PM
        result.setDate(now.getDate() + 10);
      } else {  //today is before 12PM
        result.setDate(now.getDate() + 3);
      }
    } else if (now.getDay() == 5) {  //today is friday
      result.setDate(now.getDate() + 9);
    } else if (now.getDay() == 6) {  //today is saturday
      result.setDate(now.getDate() + 8);
    }
    return result;
  }

  determineCommodities() {
    let commodities;
    if (this.prospectPortalData.selectedProductsKeysOnly.length == 1) {
      commodities = [
        {
          percentageOfInsuredValue: 100,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[0].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[0].key
        }
      ]
    } else if (this.prospectPortalData.selectedProductsKeysOnly.length == 2) {
      commodities = [
        {
          percentageOfInsuredValue: 50,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[0].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[0].key
        },
        {
          percentageOfInsuredValue: 50,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[1].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[1].key
        }
      ]
    } else if (this.prospectPortalData.selectedProductsKeysOnly.length == 3) {
      commodities = [
        {
          percentageOfInsuredValue: 33,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[0].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[0].key
        },
        {
          percentageOfInsuredValue: 33,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[1].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[1].key
        },
        {
          percentageOfInsuredValue: 34,
          packagingDescription: this.prospectPortalData.selectedProductsKeysOnly[2].value,
          code: this.prospectPortalData.selectedProductsKeysOnly[2].key
        }
      ]
    }
    // commodities = [
    //   {
    //     percentageOfInsuredValue: 100,
    //     packagingDescription: this.prospectPortalData.selectedProducts[0],
    //     code: '110'
    //   }
    // ]
    return commodities;
  }

  formBindPolicyRequest() {
    let request = {
      domain: 'upsprospectpolicy',
      companyName: this.prospectPortalData.customerCompany,
      fullName: this.prospectPortalData.customerName,
      addressLine1: this.prospectPortalData.billingStreet,
      city: this.prospectPortalData.billingCity,
      state: this.prospectPortalData.billingStateCode,
      zipCode: this.prospectPortalData.billingPostalCode,
      country: 'US',
      phoneNumber: this.prospectPortalData.customerPhone,
      email: this.prospectPortalData.customerEmail,
      accountHolderContact: {
        customerNumber: this.prospectPortalData.numberofParent,
        taxID: this.formatTaxId(),
        companyWebsite: this.prospectPortalData.webSite
      },
      billingContact: {
        firstName: this.getFirstName(),
        lastName: this.getLastName()
      },
      shipperDetails: this.determineShippers(),
      policy: {
        effectiveDate: `${this.upcomingSunday?.getFullYear()}-${this.upcomingSunday?.getMonth()+1}-${this.upcomingSunday?.getDate()}`,
        onlineQuoteRequestUID: this.prospectPortalData.quote_ID.toString(),
        transportationCarrier: {
          code: this.determineCarrierCode()
        }
      },
      commodityShipment: {
        commodities: this.determineCommodities()
      },
      tcrates: this.formTCRates(this.prospectPortalData.rate),
      isProspectPortalQuoteAccepted: true,
      rejectionReason: '',
      opportunityID: `${this.prospectPortalData.opportunityID}`,
      producerCode: this.prospectPortalData.accountOwner
    }

    return request;
  }

  determineCarrierCode() {
    let hasOtherCarriers: boolean = false;
    let hasUPS: boolean = false;

    hasUPS = this.prospectPortalData.selectedCarriers.filter(x => x.toLowerCase() == 'ups').length > 0 ? true : false;
    hasOtherCarriers = this.prospectPortalData.selectedCarriers.filter(x => x.toLowerCase() != 'ups').length > 0 ? true : false;

    if (hasUPS && hasOtherCarriers) {
      return 'Both';
    } else if (!hasUPS && hasOtherCarriers) {
      return 'Non-UPS';
    } else if (hasUPS && !hasOtherCarriers) {
      return 'UPS';
    } else {
      return 'error';
    }
  }

  saveSession() {
    this.prospectService.saveProspectPortalSession({
      opportunityID: this.prospectPortalData.opportunityID,
      emailAddress: this.prospectPortalData.customerEmail,
      data: JSON.stringify(this.prospectPortalData)
    }).subscribe(() => {});
  }

  determineExpirationDate(): Date {
    let upcomingSunday = this.determineUpcomingSunday();  //Determine the upcoming sunday
    let oneYearFromUpcomingSunday = new Date(upcomingSunday.setFullYear(this.upcomingSunday.getFullYear() + 1));  //Get one year into the future from that Sunday
    let dayOfWeek = oneYearFromUpcomingSunday.getUTCDay();  //Get the day of the week (2 = tuesday, 3 = wednesday, 4 = thursday, etc)
    let x = new Date(oneYearFromUpcomingSunday.setDate(oneYearFromUpcomingSunday.getDate() - dayOfWeek)); //0 will always be Sunday. So do that date minus whatever # day of the week it is to get 0 everytime. They DO always want to round down the Sundays. Never future Sunday.
    return x;
  }

  formatTaxId() {
    let taxId = this.prospectPortalData.taxID;
    if (!taxId) {
      return;
    }
    let firstPart = taxId.substring(0, 2);
    let secondPart = taxId.substring(2);
    if (taxId.includes('-')) {
      return taxId;
    } else {
      taxId = firstPart + '-' + secondPart;
      return taxId
    }
  }

  getFirstName() {
    if (this.prospectPortalData.customerName.includes(' ')) {
      //There are spaces in the name. Take the first part of the name.
      return this.prospectPortalData.customerName.split(' ')[0];
    } else {
      //There are no spaces in the name. Return the entire string.
      return this.prospectPortalData.customerName;
    }
  }

  getLastName() {
    if (this.prospectPortalData.customerName.includes(' ')) {
      //There are spaces in the name. Take everything after the first space.
      return this.prospectPortalData.customerName.substring(this.prospectPortalData.customerName.indexOf(' ')+1);
    } else {
      //There are no space in the name. Return nothing.
      return '';
    }
  }

  formTCRates(rate) {
    let tcrates = [
      {
        mode: 'Small Package',
        adjRate: rate,
        limit: 50000.00
      },
      {
        mode: 'Mail or Parcel Post',
        adjRate: rate,
        limit: 1000.00
      },
      {
        mode: 'Truck or Train',
        adjRate: rate,
        limit: 150000.00
      },
      {
        mode: 'Ocean',
        adjRate: rate,
        limit: 250000.00
      },
      {
        mode: 'Air Freight',
        adjRate: rate,
        limit: 150000.00
      }
    ];

    return tcrates;
  }
}
