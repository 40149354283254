<div *ngIf="pageError" class="page-Error">
  {{ 'claims.edit.shippingStatus.errors.somethingWentWrong' | translate }}
</div>
<div class="wrapper">
  <div class="content">
    <div class="subtitle" style="margin-left: 3px;">{{'app.armor.progress' | translate}}</div>
    <div class="progress-bar">
      <div class="progress-bar-filled" style="width: 60%;"></div>
    </div>
    <div class="title">
      <div class="header">{{'app.armor.productsTitle' | translate}}</div>
      <div class="bluebar"></div>
    </div>
    <div class="boxes">
      <div class="text d-inline-flex flex-row justify-content-between" style="margin-top: 26px;">{{'app.armor.productsText' |
        translate}}<div class="count">({{selectedProducts.length}}/3)</div>
      </div>
      <div class="box d-flex flex-column" *ngFor="let product of products; let index = index" (click)="toggleDropdown(index)" [ngClass]="{'selected-box': !product.hasDropdown && product.selected || product.hasDropdown && product.selectedOptions?.length > 0 }" (click)="toggleSelection(product)">
        <div class="box-header d-flex flex-row" style="gap: 20px;">
          <img class="img" [src]="product.image">
          <div class="text">{{product.text | translate}}</div>
        </div>
        <div class="dropdown" *ngIf="product.selected && product.hasDropdown" (click)="$event.stopPropagation()">
          <div class="option" *ngFor="let option of product.options">
            <input type="checkbox" [id]="option.code" (change)="onOptionChange(option.code, index)" [disabled]="selectedProducts.length >= 3 && !selectedProducts.includes(option.code)" [checked]="product.selectedOptions.includes(option.code)">
            <label [for]="option.code">{{option.text | translate}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row" style="gap:20px; margin-top: 26px;">
      <button class="back" (click)="back()" style="margin-bottom: 20px;"> <mat-icon class="back-icon">chevron_left</mat-icon>{{'app.armor.back' | translate}}</button>
      <button class="continue" [disabled]="!isBoxSelected()" (click)="onSubmit($event)" style="margin-bottom: 20px;">{{'app.armor.continue' | translate}} <mat-icon class="back-icon">chevron_right</mat-icon></button>
    </div>
    <div class="page-Error mb-3" *ngIf="selectionError">
      Please select a product.
    </div>
  </div>
</div>