<!-- <div *ngIf="isUsaUser">
  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question1' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer1' | translate}}
    <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' | translate}}</a>
    <span *ngIf="isCanadaUser">{{'claimshelp.answers.phoneInfo' | translate}}</span>
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question2' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer2' | translate}}
    <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' | translate}}</a>
    <span *ngIf="isCanadaUser">{{'claimshelp.answers.phoneInfo' | translate}}</span>
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question9' | translate"
    [isCollapsed]="true">
    <span>
      {{'claimshelp.answers.answer9' | translate}}
      <a href="../../../../../assets/docs/ONL 219 Notice to Carrier Template.pdf" *ngIf="!isCanadaUser" target="_blank">
        {{'claimshelp.answers.answer9.2' | translate}}
      </a>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.answer9.3' | translate}}</span>
    </span>
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question3' | translate"
    [isCollapsed]="true">
    <span class="phone-wrap">
      <span>{{'claimshelp.answers.answer3' | translate}}</span>
      <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' |
        translate}}</a>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.phone' | translate}}</span>
    </span>
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question4' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer4' | translate}}
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question5' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer5' | translate}}
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question8' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer8' | translate}}
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="(isCanadaUser || isUsaUser) && !isGuestUser"
    [title]="'claimshelp.questions.question6' | translate" [isCollapsed]="true">
    <span>
      {{'claimshelp.answers.answer6' | translate}}
      <a routerLink="/claims">{{'claimshelp.answers.answer6.2' | translate}}</a>
      {{'claimshelp.answers.answer6.3' | translate}}
    </span>
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question7' | translate"
    [isCollapsed]="true">
    <span class="phone-wrap">
      <span>{{'claimshelp.answers.answer7' | translate}}</span>
      <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' |
        translate}}</a>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.phone' | translate}}</span><br>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.answer7.1' | translate}}</span>
      <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' |
        translate}}</a>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.answer7.2' | translate}}</span>
    </span>
  </upsc-collapsible-panel-light>
</div> -->


<div>
  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question1' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer1' | translate}}
    <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' | translate}}</a>
    <span *ngIf="isCanadaUser">{{'claimshelp.answers.phoneInfo' | translate}}</span>
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question2' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer2' | translate}}
    <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' | translate}}</a>
    <span *ngIf="isCanadaUser">{{'claimshelp.answers.phoneInfo' | translate}}</span>
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question9' | translate"
    [isCollapsed]="true">
    <span>
      {{'claimshelp.answers.answer9' | translate}}
      <a href="../../../../../assets/docs/ONL 219 Notice to Carrier Template.pdf" *ngIf="!isCanadaUser" target="_blank">
        {{'claimshelp.answers.answer9.2' | translate}}
      </a>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.answer9.3' | translate}}</span>
    </span>
  </upsc-collapsible-panel-light>
  
  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question3' | translate"
    [isCollapsed]="true">
    <span class="phone-wrap">
      <span>{{'claimshelp.answers.answer3' | translate}}</span>
      <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' |
        translate}}</a>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.phone' | translate}}</span>
    </span>
  </upsc-collapsible-panel-light>
  
  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question4' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer4' | translate}}
  </upsc-collapsible-panel-light>
  
  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question5' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer5' | translate}}
  </upsc-collapsible-panel-light>
  
  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question8' | translate"
    [isCollapsed]="true">
    {{'claimshelp.answers.answer8' | translate}}
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="(isCanadaUser || isUsaUser) && !isGuestUser"
    [title]="'claimshelp.questions.question6' | translate" [isCollapsed]="true">
    <span>
      {{'claimshelp.answers.answer6' | translate}}
      <a routerLink="/claims">{{'claimshelp.answers.answer6.2' | translate}}</a>
      {{'claimshelp.answers.answer6.3' | translate}}
    </span>
  </upsc-collapsible-panel-light>

  <upsc-collapsible-panel-light *ngIf="isCanadaUser || isUsaUser" [title]="'claimshelp.questions.question7' | translate"
    [isCollapsed]="true">
    <span class="phone-wrap">
      <span>{{'claimshelp.answers.answer7' | translate}}</span>
      <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' |
        translate}}</a>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.phone' | translate}}</span><br>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.answer7.1' | translate}}</span>
      <a href='mailto:insureshield@us.crawco.com' *ngIf="isCanadaUser">{{'claimshelp.answers.emailValue' |
        translate}}</a>
      <span *ngIf="isCanadaUser">{{'claimshelp.answers.answer7.2' | translate}}</span>
    </span>
  </upsc-collapsible-panel-light>

  <!-- Europe Faq -->
  <div *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">
    <upsc-collapsible-panel-light [title]="'claimshelp.questions.question4' | translate" [isCollapsed]="true">
      {{'claimshelp.answers.answer4' | translate}}
    </upsc-collapsible-panel-light>

    <upsc-collapsible-panel-light [title]="'claimshelp.questions.question2' | translate" [isCollapsed]="true">
      {{'claimshelp.answers.answer2' | translate}}
    </upsc-collapsible-panel-light>

    <upsc-collapsible-panel-light [title]="'claimshelp.questions.questionEur1' | translate" [isCollapsed]="true">
      <div [innerHTML]="'claimshelp.answers.answerEur1' | translate"></div>
    </upsc-collapsible-panel-light>

    <upsc-collapsible-panel-light [title]="'claimshelp.questions.questionEur2' | translate" [isCollapsed]="true">
      <div [innerHTML]="'claimshelp.answers.answerEur2.1' | translate"></div>
      <div [innerHTML]="'claimshelp.answers.answerEur2.2' | translate"></div>
      <div [innerHTML]="'claimshelp.answers.answerEur2.3' | translate"></div>
      <div [innerHTML]="'claimshelp.answers.answerEur2.4' | translate"></div>
      <div [innerHTML]="'claimshelp.answers.answerEur2.5' | translate"></div>
      <div [innerHTML]="'claimshelp.answers.answerEur2.6' | translate"></div>
    </upsc-collapsible-panel-light>

    <upsc-collapsible-panel-light [title]="'claimshelp.questions.questionEur3' | translate" [isCollapsed]="true">
      <div [innerHTML]="'claimshelp.answers.answerEur3' | translate"></div>
    </upsc-collapsible-panel-light>
    
    <upsc-collapsible-panel-light [title]="'claimshelp.questions.question7' | translate" [isCollapsed]="true">
      <span class="phone-wrap">
        <span>{{'claimshelp.answers.answer7' | translate}}</span>
      </span>
    </upsc-collapsible-panel-light>
  </div>
</div>