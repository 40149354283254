import { Component } from '@angular/core';

@Component({
  selector: 'upsc-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

}
