<div class="tccp-emailwrapper__otp">
  <div class="title-wrapper d-flex flex-column align-items-center">
    <div *ngIf="flow != 'prospect'">
      <div *ngIf="flow !== 'add-policy' && flow !== 'prospectPortal'">

        <div class="otp-mobile__content">
          {{'createAccount.confirm.otp-mobile-content1' | translate}} {{'createAccount.confirm.otp-mobile-content2' | translate}}
        </div>
      </div>
      <div *ngIf="flow == 'prospectPortal'">
        <div class="title mb-4">Enter Email Verification Code</div>
      </div>
    </div>
    <div class="title prospect" *ngIf="flow == 'prospect'">
      You’re just a few steps away from taking advantage of your pre-approved InsureShield<sup>®</sup> rates for small
      packages.
    </div>
    <div class="title prospect" *ngIf="flow == 'prospect'">
      To start, enter the verification code we sent to {{maskedEmail}} and then confirm your contact details.
    </div>
    <div class="title prospect" *ngIf="flow == 'prospectPortal'">
      Nice to meet you, {{name}}! Please verify who you are.
    </div>
  </div>
  <div class="center-verify" *ngIf="flow === 'add-policy'">
    <div class="title-verify">
      <div class="login-title">{{'policies.policy.enterEmailVerification' | translate}}
        <span *ngIf="flow == 'myChoice'">at {{maskedEmail}}</span>
      </div>
    </div>
    <div class="bar-verify">
      <div class="login-bar"></div>
    </div>
    <div class="title-sub-verify">
      <div class="title">{{'policies.policy.emailVerificationSubTitle' | translate}}</div>
    </div>
  </div>
  <form [formGroup]="confirmFormGroup">
    <div class="w-md-75">
      <div *ngIf="flow == 'myChoice'" class="name-wrapper">
        <mat-form-field class="input-container mr-lg-4">
          <input class="" matInput required formControlName="firstName"
            placeholder="{{ 'createAccount.review.form.firstName' | translate }}">
          <!-- <mat-error *ngIf="reviewFormGroup.get('firstNameFormControl').errors">
              <div *ngIf="reviewFormGroup.get('firstNameFormControl').errors['required']">
                {{ 'createAccount.review.form.firstNameError' | translate }}
              </div>
              <div
                *ngIf="reviewFormGroup.get('firstNameFormControl').errors && (!reviewFormGroup.get('firstNameFormControl').errors['required'])">
                {{ 'createAccount.review.form.firstNamePattern' | translate }}
              </div>
            </mat-error> -->
        </mat-form-field>
        <mat-form-field class="input-container">
          <input matInput required formControlName="lastName"
            placeholder="{{ 'createAccount.review.form.lastName' | translate }}">
          <!-- <mat-error *ngIf="reviewFormGroup.get('lastNameFormControl').errors">
              <div *ngIf="reviewFormGroup.get('lastNameFormControl').errors['required']">
                {{ 'createAccount.review.form.lastNameError' | translate }}
              </div>
              <div
                *ngIf="reviewFormGroup.get('lastNameFormControl').errors && (!reviewFormGroup.get('lastNameFormControl').errors['required'])">
                {{ 'createAccount.review.form.lastNamePattern' | translate }}
              </div>
            </mat-error> -->
        </mat-form-field>
      </div>

      <div class="container-wrapper" [class.prospect]="flow == 'prospect'">
        <div class="container-inner-wrapper">
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" autofocus class="center-text" #name1 (keyup)="focusThis(2, $event)"
                (input)="pasted()" formControlName="firstDigit" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name2 (keyup)="focusThis(3, $event)"
                formControlName="secondDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name3 (keyup)="focusThis(4, $event)"
                formControlName="thirdDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name4 (keyup)="focusThis(5, $event)"
                formControlName="fourthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name5 (keyup)="focusThis(6, $event)"
                formControlName="fifthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="plain-form-field">
              <input matInput inputmode="numeric" class="center-text" #name6 (keyup)="focusThis(7, $event)"
                formControlName="sixthDigit" maxlength="1" pattern="([0-9])">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="error-returned" *ngIf="codeErrorReturned">
        {{ 'customerclaimsportal.emailVerification.errorReturned' | translate }}
      </div>
      <div class="error-returned" *ngIf="policySearchError">
        {{ 'common.systemError' | translate }}
      </div>
    </div>
    <div *ngIf="flow !== 'prospect'">
      <div *ngIf="flow !== 'add-policy' && flow !== 'general'"
        class="step-controls d-flex flex-column align-items-center justify-content-center flex-sm-row  mt-5 mb-3">
        <button class="upsc-button" *ngIf="!loading" (click)="checkVerificationCode()"
          [disabled]="!confirmFormGroup.valid">
          {{ 'common.continue' | translate }}
        </button>
        <button class="upsc-button loading" *ngIf="loading">
          {{ 'common.continue' | translate }}
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
        <div class="cancel mt-3 mt-sm-0 ml-sm-5" (click)="cancel()" *ngIf="!claimDetailFlow">
          {{ 'common.cancel' | translate }}
        </div>
      </div>
      <div *ngIf="flow === 'add-policy' || flow === 'general'" class="policy-buttons">
        <div class="policy-buttons-center">
          <div class="btnVerify">
            <div class="codeContainer" *ngIf="!hideCodeResend">
              <div class="resendCode">
                <span (click)="resendCode()">{{'guestVerification.form.resendCode' | translate}}</span>
              </div>
            </div>
            <div class="btnContainer"><button type="button" (click)="resetStep($event)"
                class="upsc-white-button"><mat-icon
                  class="icon-key">keyboard_arrow_left</mat-icon>{{'policies.policy.back' | translate}}</button>
              <button type="submit" (click)="verifyCode()" *ngIf="flow === 'general'"
                class="upsc-green-popup-button">{{'common.continue' |
                translate}}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
              <button type="submit" (click)="verifyCode()" *ngIf="flow === 'add-policy'"
                class="upsc-green-popup-button">{{'policies.policy.addNewPolicy' |
                translate}}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>