export class ResubmitClaim {
    claimNumber?: string;
    merchandiseAmount?: string;
    resubmissionReason?: number;
    shippingAmount?: string;
    reopenNotes?: string;
    claimFileDate?: string;
    damageDescription?: string;
    damageDate?: string;
    damageRepairable?: string;
    damageLocations?: string;
    damageLocationDetails?: string;
    missingDescription?: string;
    missingDate?: string;
    missingLocations?: string;
    missingLocationDetails?: string;
    lateCare?: string;
    lateReship?: string;
    lateRefund?: string;
    isReturnShipment?: string;
    originalTrackNumber?: string;
    merchandiseDescription?: string;
}