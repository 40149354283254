import { Component, OnInit } from '@angular/core';
import { TaggingService } from 'src/app/tags/tagging.service';
@Component({
  selector: 'upsc-security-settings',
  templateUrl: './security-settings.component.html',
  styleUrls: ['./security-settings.component.scss']
})
export class SecuritySettingsComponent implements OnInit {

  public update: boolean = false;
  public content: boolean = true;
  public disabledField: boolean = true;
  public hide: boolean = true;

  constructor(private taggingService: TaggingService) {

  }

  getDisabledValue() {
    //your condition, in this case textarea will be disbaled.
    return true; 
  }


  ngOnInit() {
    this.taggingService.view();
    this.update = false;
    this.content = true;
  }
  get userid(): any {
    return sessionStorage.getItem('userid');
  }
  public showUpdate() {
    this.update = true;
    this.content = false;
  }
  public hideChangePassword(iscancel: boolean) {
    if (iscancel) {
      this.content = true;
    }
  }
}


