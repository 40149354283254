import { Component, HostBinding } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { PrivacyComponent } from '../privacy/privacy.component';
import { ProductComponent } from '../product/product.component';
import { environment as ENV } from '../../../../environments/environment';
import { UserService } from 'src/app/services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'upsc-flex-footer',
  templateUrl: './flex-footer.component.html',
  styleUrls: ['./flex-footer.component.scss']
})
export class FlexFooterComponent {
  @HostBinding('class') public hostClass = 'footer';

  thisYear = (new Date()).getFullYear();
  techAgreementLink = ENV.links.techAgreement;
  privacyLink = ENV.links.privacyNotice;
  canadaprivacyLink = ENV.links.canadaprivacyNotice;
  canadaproductLink = ENV.links.canadaProductDisclosures;
  //Europe Footer links
  ukComplints = ENV.links.ukComplints;
  ukprivacyNotice = ENV.links.ukprivacyNotice;
  ukproductDisclosures = ENV.links.ukproductDisclosures;

}
