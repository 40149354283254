<div class="upsc-custom-dialog-content">
<div class="main">
    <mat-icon class="close" (click)="cancel()">close</mat-icon>
    <div class="content">
    <h2 mat-dialog-title class="title">{{ 'claims.edit.whatHappened.documents.dialog.title' | translate }}<br>{{ 'claims.edit.whatHappened.documents.dialog.title2' | translate }}</h2>

    <mat-dialog-actions>
        <div class="buttons">
            <button class="cancel" (click)="cancel()">{{ 'claims.edit.whatHappened.documents.dialog.buttons.cancel' |
                translate }}</button>
            <button class="confirm" (click)="confirm()">{{ 'claims.edit.whatHappened.documents.dialog.buttons.confirm'
                | translate }}</button>
        </div>
    </mat-dialog-actions>
    </div>
</div>
</div>