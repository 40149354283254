import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../../shared/components/layout/layout.component';
import { AdjusterDashboardComponent } from './adjuster-dashboard.component';
import { AuthGuardService } from '../../core/services/auth-guard.service';
import { PayeeComponent } from './payee/payee.component';
import { BankingComponent } from '../claims/components/payee/components/banking/banking.component';
import { UnableToFulfillComponent } from '../../shared/components/unable-to-fulfill/unable-to-fulfill.component';
@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: LayoutComponent,
      children:[
      {
        path: '',
        component: AdjusterDashboardComponent,
        canActivate:[AuthGuardService],
        children:[
          {
            path: '',
            component: PayeeComponent,
          },
          {
            path: 'bankInfo',
            component: BankingComponent
          },
          {
            path: 'unable-to-fulfill',
            component: UnableToFulfillComponent
          }
        ]
      }]
    }])
],
exports: [RouterModule]
})
export class AdjusterDashboardRoutingModule { }
