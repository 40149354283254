<div class="supports" *ngIf="contactusPopover==='true'">
    <mat-icon (click)="close()">close</mat-icon>
    <div class="speak-with-a-license">{{ 'support.subtitle' | translate }}</div>

    <!-- <hr class="d-block d-md-none"> -->
    <div class="contact-block d-flex flex-column">
        <div class="wrap d-flex flex-column align-self-center d-none d-block">
            <img src="../../../../../assets/images/agent.PNG" alt="{{ 'billing.method.phone' | translate }}">
            <span class="key align-self-center d-none d-block">{{ 'support.speakAgent' | translate }}</span>

            <span class="key-value align-self-center link2 d-none d-block">
                {{ 'support.phone' | translate }}<mat-icon>keyboard_arrow_right</mat-icon>
            </span>
            <span class="value align-self-center link">{{ 'support.time' | translate }}</span>
        </div>
    </div>
    <div class="contact-block d-flex flex-column align-items-center">
        <a (click)="close()"
            href="{{ isCanadaUser ? 'mailto:insureshieldca@ups.com' : isUkUser ? 'mailto:risksolutionsuk@ups.com' : isGermanyUser ? 'mailto:risksolutionsde@ups.com' : isFranceUser ? 'mailto:risksolutionsfr@ups.com' : isItalyUser ? 'mailto:risksolutionsit@ups.com' : 'mailto:capitalus@ups.com' }}"
            class="wrap d-flex flex-column align-items-center">
            <img src="../../../../../assets/images/email.PNG" alt="{{ 'billing.method.email' | translate }}">
            <span class="key align-self-center d-none d-block">{{ 'support.email:' | translate }}</span>
            <span class="key-value align-self-center link2 d-none d-block">
                {{ 'support.emailValue' | translate }}<mat-icon>keyboard_arrow_right</mat-icon>
            </span>
        </a>
    </div>

    <div class="contact-block d-flex flex-column">
        <div class="wrap d-flex flex-column align-self-center" (click)="openContactUsDialog()">
            <img src="../../../../../assets/images/contact.PNG" alt="{{ 'billing.method.contactUs' | translate }}">
            <span class="key align-self-center d-none d-block">{{ 'support.contact:' | translate }}</span>
            <span class="key-value align-self-center link2 d-none d-block">
                {{ 'support.online' | translate }}<mat-icon>keyboard_arrow_right</mat-icon>
            </span>
        </div>
    </div>
</div>

<div class="supports" *ngIf="contactusPopoverCanada==='true'">
    <div class="ca-spacing">
        <mat-icon (click)="close()">close</mat-icon>
        <div class="speak-with-a-license">{{ 'support.subtitle' | translate }}</div>
        <div class="contact-block d-flex flex-column">
            <div class="wrap d-flex flex-column align-self-center" (click)="openContactUsDialog()">
                <img src="../../../../../assets/images/contact.PNG" alt="{{ 'billing.method.contactUs' | translate }}">
                <span class="key align-self-center normal mb-2">{{ 'support.claimInfo' | translate }}</span>
                <span class="key-value align-self-center link2 d-none d-block">
                    {{ 'support.phoneNew' | translate }}<mat-icon>keyboard_arrow_right</mat-icon>
                </span>
            </div>
        </div>
        <hr class="d-block d-md-none">
        <div class="contact-block d-flex flex-column mt-2">
            <div class="wrap d-flex flex-column align-self-center">
                <img src="../../../../../assets/images/agent.PNG" alt="{{ 'billing.method.phone' | translate }}">
                <span class="key align-self-center mb-2">{{ 'support.policyInfo' | translate }}</span>
                <a href="mailto:insureshieldca@ups.com" class="wrap tele d-flex flex-column align-self-center">
                    <span class="key-value align-self-center link2 d-none d-block">
                        {{ 'support.emailValue' | translate }}<mat-icon>keyboard_arrow_right</mat-icon>
                    </span>
                </a>
                <span class="key-value align-self-center link2 d-none d-block">
                    {{ 'support.callNew' | translate }}<mat-icon>keyboard_arrow_right</mat-icon>
                </span>
            </div>
        </div>
        <hr class="d-block d-md-none">
        <div class="contact-block d-flex flex-column  mt-2">
            <div class="wrap d-flex flex-column">
                <span class="key align-self-center  mb-2">{{ 'support.feedback' | translate }}</span>
                <a href="mailto:insureshieldcacomplaint@ups.com" class="wrap tele d-flex flex-column">
                    <span class="key-value align-self-center link2 d-none d-block">
                        {{ 'support.feedbackemailvalue' | translate }}<mat-icon>keyboard_arrow_right</mat-icon>
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>