<div ngbDropdown class="d-flex flex-column flex-md-row m-2 dropdown" [autoClose]="autoClose">
  <button ngbDropdownToggle [ngClass]="applied == true ? 'upsc-rectangle-button' : 'upsc-white-rectangle'">
    <span class="selectedValues" *ngIf="applied && isUsaUser">{{ startRangeD | currency:'USD' }} - {{ endRangeD | currency:'USD'}}</span>
    <span class="selectedValues" *ngIf="applied && (isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser)">{{ currencyType }}{{ startRangeD }} - {{ currencyType }}{{ endRangeD }}</span>
    <span class="selectedValues" *ngIf="!applied">{{ 'claims.list.claimsSearch.advanceSearch.amountRangeSearch.title' | translate }}</span>
    <img *ngIf="applied" class="ml-2" (click)="reset()" src="../../../../assets/images/cross.svg">
  </button>
  <div ngbDropdownMenu class="drop-wrap">
    <form [formGroup]="amountForm">
      <mat-radio-group formControlName="selection"
        class="d-flex flex-column flex-sm-row justify-content-start">
        <mat-radio-button value="paidAmount" class="small mr-3">
          {{ 'claims.list.claimsSearch.advanceSearch.amountRangeSearch.paidDate' | translate }}</mat-radio-button>
        <mat-radio-button value="submittedAmount" class="small">
          {{ 'claims.list.claimsSearch.advanceSearch.amountRangeSearch.submittedDate' | translate }}</mat-radio-button>
      </mat-radio-group>
      <div class="claim-amount-boxes pr-xl-2 currency" *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
        <div class="label">{{ 'claims.edit.whatHappened.value.form.currency.hint' | translate }}</div>
        <mat-form-field>
          <mat-select  #mySelect class="currency-select" required formControlName="currency" [(value)]="defaultCurrency">
            <mat-option *ngFor="let key of Object.keys(currencyList)" [value]="currencyList[key]">
              {{currencyList[key]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="subheading mb-2">{{ 'claims.list.claimsSearch.advanceSearch.amountRangeSearch.subheading' | translate
        }}</div>
      <div class="d-flex justify-content-between flex-column flex-sm-row">
        <div class="d-flex flex-column">
           <div class="input-wrap">
            <input type="text" formControlName="minimum" (blur)="checkMinimum($event)" placeholder="{{currencyType}}" currencyMask
            [options]="{prefix: isCAD ? 'CAD ' : isGBP ? '£ ' : isEUR ? '€ ' : '$ ', align: 'left', allowNegative: false, precision: 2, inputMode: 'FINANCIAL'}">
            <div *ngIf="minimum.invalid && (minimum.dirty || minimum.touched)" class="upsc-errors">
              <div *ngIf="minimum.errors.required" class="d-flex align-items-center upsc-errors">
                {{ 'claims.list.claimsSearch.advanceSearch.amountRangeSearch.minimunReq' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="px-2 mt-2 align-self-center align-self-sm-start">-</div>
        <div class="d-flex flex-column">
          <input type="text" formControlName="maximum" (blur)="checkMaximum($event)" placeholder="{{currencyType}}" currencyMask
          [options]="{prefix: isCAD ? 'CAD ' : isGBP ? '£ ' : isEUR ? '€ ' : '$ ', align: 'left', allowNegative: false, precision: 2, inputMode: 'FINANCIAL'}">
          <div *ngIf="maximum.invalid && (maximum.dirty || maximum.touched)" class="upsc-errors">
            <div *ngIf="maximum.errors.required" class="d-flex align-items-center errors">
              {{ 'claims.list.claimsSearch.advanceSearch.amountRangeSearch.maximumReq' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="upsc-errors" *ngIf="valueErrors && israngeValid">
        {{ 'claims.list.claimsSearch.advanceSearch.amountRangeSearch.rangeValidation' | translate }}
      </div>
      <div class="upsc-errors" *ngIf="valueErrors && isRangeSelected">
        {{ 'claims.list.claimsSearch.advanceSearch.amountRangeSearch.selectionValid' | translate }}
      </div>
      <div class="upsc-errors" *ngIf="valueErrors && isCompleteRange">
        {{ 'claims.list.claimsSearch.advanceSearch.amountRangeSearch.rangeSelected' | translate }}
      </div>
      <div class="d-flex justify-content-start mt-3 align-items-center">
        <button class="upsc-button mr-3" (click)="apply()">{{ 'claims.list.claimsSearch.advanceSearch.done' | translate
          }}</button>
        <div class="cancel" (click)="close()">{{ 'claims.list.claimsSearch.advanceSearch.cancel' | translate }}</div>
      </div>
    </form>
  </div>
</div>