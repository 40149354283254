import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../../shared/components/layout/layout.component';
import { ProfileComponent } from './profile.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AuthGuardService } from '../../core/services/auth-guard.service';
import { SecuritySettingsComponent } from './security-settings/security-settings.component';
import { UpdateComponent } from './update/update.component';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: LayoutComponent,
      children: [
        {
          path: '',
          component: ProfileComponent,
          children: [
            {
              path: '',
              component: UserProfileComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: 'security',
              component: SecuritySettingsComponent
            },
            {
              path: 'update',
              component: UpdateComponent
            },
          ]
        },
      ],
    }])],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
