<div class="overview">
    <div class="layout">
        <div class="nav">{{'support.isop' | translate}}</div>
        <div class="header">
            <div class="title">
                <div class="name">{{'support.review' | translate}}</div>
                <div class="bluebar"></div>
            </div>
            <div class="text">{{'support.message' | translate}} {{'support.message2' | translate}}</div>
            <div class="wrapper">
                <img class="img" src="../../../assets/images/applicationUnderReview.png">
            </div>
        </div>
    </div>
</div>