import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-access-pending',
  templateUrl: './access-pending.component.html',
  styleUrls: ['./access-pending.component.scss']
})
export class AccessPendingComponent  {

  constructor(private dialogRef: MatDialogRef<AccessPendingComponent>) { }

  close(): void {
    this.dialogRef.close();
  }

}
