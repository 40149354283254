import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ReOpenMessage } from 'src/app/shared/models/reopen-message';

@Component({
  selector: 'upsc-reopen-message',
  templateUrl: './reopen-message.component.html',
  styleUrls: ['./reopen-message.component.scss']
})
export class ReopenMessageComponent implements OnInit {

  errorData: ReOpenMessage = {};

  constructor(
    private dialogRef: MatDialogRef<ReopenMessageComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.errorData = this.data;
  }

  close() {
    this.dialogRef.close("Cancel");
  }

  submit() {
    this.dialogRef.close("reopen");
  }

}
