import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FlexappComponent } from './flexapp/flexapp.component';
import { FlexLayoutComponent } from 'src/app/shared/components/flex-layout/flex-layout.component';

const routes: Routes = [
  {
    path: '',
    component: FlexLayoutComponent,
    children: [
      {
        path: '',
        component: FlexappComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FlexAppRoutingModule { }
