<div class="popup-global-layout width-600 p-4 p-md-5">
    <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
    <div class="global-title">{{ 'userProfile.contact.profileAddressChangeConfirmationTitle' | translate }}</div>
    <div class="global-green-bar"></div>
    <div class="global-text">{{ 'userProfile.contact.companyAddressPopupText' | translate }}</div>
    <div class="global-button-format">
        <button type="button" class="upsc-white-button2" (click)="close()"><mat-icon class="icon-key">keyboard_arrow_left</mat-icon>{{ 'userProfile.contact.profileAddressUpdateCancel' | translate }}</button>
        <button type="button" class="upsc-green-popup-button" (click)="update()">{{ 'userProfile.contact.profileAddressUpdate' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
    </div>
  </div>
