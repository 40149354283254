import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ClaimService } from 'src/app/services/claim.service';
import { emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { usStates } from 'src/config/metadata-config';
import { EmailVerificationComponent } from '../claims-custom/tupss/tupss-edit/components/email-verification/email-verification.component';
import { ProspectService } from 'src/app/services/prospect.service';
import { UserService } from 'src/app/services/user.service';
import { TaggingService } from 'src/app/tags/tagging.service';

@Component({
  selector: 'upsc-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.scss']
})
export class ProspectComponent implements OnInit {
  @ViewChild(EmailVerificationComponent) verificationComp: EmailVerificationComponent;
  
  //Data holders
  URLemail;
  fullStateList = usStates;
  addressList = [];
  currentAddress = null;
  prospectSession;
  rate;

  //Conditional views
  showSpinner: boolean = false;
  loading: boolean = false;
  showEmailVerification: boolean = false;
  showAddressValidation: boolean = false;
  verificationCodeError: boolean = false;
  systemError: boolean = false;
  denied: boolean = false;
  fatalError: boolean = false;
  showInvalidCampaign: boolean = false;
  internalUser: boolean = false;

  //Form
  formGroup: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private claimService: ClaimService,
    private prospectService: ProspectService,
    private userService: UserService,
    private taggingService: TaggingService,
    private router: Router
  ) {
    this.showSpinner = true;
    this.readParams();
    this.prospectSession = JSON.parse(sessionStorage.getItem('prospect'));

    this.formGroup = this.formBuilder.group({
      name: [this.prospectSession?.name, Validators.compose([Validators.required])],
      email: [{ value: this.URLemail, disabled: this.internalUser ? false : true }, Validators.compose([Validators.required, Validators.email])],
      company: [this.prospectSession?.company, Validators.compose([Validators.required])],
      phone: [this.prospectSession?.phone, Validators.compose([Validators.required])],
      country: ['US', Validators.compose([Validators.required])],
      city: [this.prospectSession?.city, Validators.compose([Validators.required])],
      state: [this.prospectSession?.state, Validators.compose([Validators.required])],
      zip: [this.prospectSession?.zip, Validators.compose([Validators.required])],
      address1: [this.prospectSession?.address1, Validators.compose([Validators.required])],
      shipperNumbers: [this.prospectSession?.shipperNumbers ? this.prospectSession.shipperNumbers.toString() : '', Validators.compose([Validators.required])],
      recaptcha: [null, !this.internalUser ? Validators.required : null]
    });
  }

  ngOnInit() {
    this.taggingService.view({
      page_id: 'signup_start',
      step: 'signup_start',
      journey_step_number: '1'
    });
    //Form was filled out correctly, and event was triggered to check the verification code.
    //(2)
    this.prospectService.getEmailVerEvent().subscribe(
      data => {
        if (data) {
          this.verificationCodeError = false;
          this.addressValidation();
        } else if (data == false) {
          this.verificationCodeError = true;
          this.loading = false;
        }
      }, error => {
        this.loading = false;
      }
    );
  }

  readParams() {
    let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    if (userDetails && userDetails.smbIndicator) {
      this.startInternalUser();
      return;
    }
    this.systemError = false;
    this.route.queryParams.subscribe(
      params => {
        if (Object.keys(params).length != 0) {
          if (params) {
            let email = atob(params.param);
            this.URLemail = email;
            this.checkValidityOfEmail(email);
          }
        } else {
          //error
          this.systemError = true;
          this.showSpinner = false;
        }
      }
    );
  }

  startInternalUser() {
    this.internalUser = true;
    this.scrollToTop();
    this.systemError = false;
    this.showEmailVerification = true;
    this.showSpinner = false;
  }

  //Check if the email has passed the 2 weeks or not
  checkValidityOfEmail(email: string): boolean {
    let request = {
      email: email
    }
    this.prospectService.checkEmailValidity(request).subscribe(
      response => {
        this.systemError = false;
        this.fatalError = false;
        if (response && response?.message?.toLowerCase() == "invalidcampaignemail") {
          this.showSpinner = false;
          this.showInvalidCampaign = true;
        } else if (response && response?.message?.toLowerCase() == "validcampaignemail") {
          try {
            if (response?.data?.rate) {
              this.rate = Number(response.data.rate);
            } else {
              this.rate = 0.70;
            }
            this.sendVerificationCode(email, response.data);
          } catch (e) {
            this.showSpinner = false;
            this.systemError = true;
          }
        } else {
          this.showSpinner = false;
          this.systemError = true;
        }
      }, error => {
        this.fatalError = true;
        this.showSpinner = false;
      }
    );
    return false;
  }

  sendVerificationCode(emailId: string, prepopData?) {
    let emailModel = {
      ...emptyVerificationInterface,
      data: {
        emailAddress: emailId,
        phoneNumber: '877-242-7930'
      },
      EmailFor: 'CCPVerificationEmail'
    }

    this.claimService.sendCCPVerificationCodeEmail(emailModel).subscribe(
      data => {
        this.scrollToTop();
        this.systemError = false;
        this.showEmailVerification = true;
        this.prepopulate(prepopData);
        this.showSpinner = false;
      }, error => {
        this.systemError = true;
      }
    );
  }

  onSpeakClick(evt: any) {
    this.taggingService.link({
      link_name: 'speak_with_a_representative_now',
      link_page_id: 'signup_start'
    });
  }

  prepopulate(data) {
    this.name.setValue(data?.contact_name);
    this.company.setValue(data?.company_name);
    this.phone.setValue(data?.phone_number);
    this.city.setValue(data?.city);
    this.state.setValue(data?.state ? data?.state.toUpperCase() : '');
    this.zip.setValue(data?.postal_code ? data?.postal_code.substring(0,5) : '');
    this.address1.setValue(data?.addressline1);
    this.shipperNumbers.setValue(data?.shipper_numbers);
  }

  //User filled out the form and verification code and pressed "Confirm Details"
  //(1)
  submitForm() {
    this.loading = true;

    if (this.formGroup.valid) {
      this.taggingService.link({
        link_name: 'Confirm Details',
        link_page_id: 'signup_start'
      });

      if (this.internalUser) {  //Skip the verification code stuff if user is internal
        this.addressValidation();
      } else {
        this.verificationComp.checkVerificationCode();
        //Go to //(2) to see the next step
      }
    } else {
      this.loading = false;
      this.formGroup.markAllAsTouched();
    }
  }

  //Check the address the user entered vs the address API
  addressValidation() {
    this.claimService.addressValidation({
      customerName: this.name.value,
      addressLine1: this.address1.value,
      addressLine2: '',
      city: this.city.value,
      countrycode: 'US',
      zipcode: this.zip.value,
      state: this.state.value
    }).subscribe(
      data => {
        if (data.status == 'Valid') {
          let hasMatch = false;
          for(let a of data.candidateList) {
            if(
              a?.AddressLine[0]?.toLowerCase() == this.address1?.value.toLowerCase() &&
              a?.PoliticalDivision2?.toLowerCase() == this.city?.value.toLowerCase() &&
              a?.PoliticalDivision1?.toLowerCase() == this.state?.value.toLowerCase() &&
              a.PostcodePrimaryLow == this.zip.value &&
              a.CountryCode == 'US'
            ) {
              hasMatch = true;
              break;
            }
          }
          if(hasMatch) {
            //User address matched perfectly. just go straight to denied party.
            this.deniedPartyScreening();
          }
          else {
            //Need to show the user an address
            this.addressList = data.candidateList;
            this.currentAddress = {
              address1: this.address1.value,
              address2: '',
              city: this.city.value,
              state: this.state.value,
              zip: this.zip.value
            }
            this.showAddressValidation = true;
            this.scrollToTop();
            this.loading = false;
          }
        }
        else {
          this.deniedPartyScreening();
        }
      }, error => {
        this.deniedPartyScreening();
        this.loading = false;
      }
    );
  }

  updateAddress(address) {
    // this.showAddressValidation = false;
    if (address) {
      this.address1.setValue(address.AddressLine[0]);
      this.state.setValue(address.PoliticalDivision1);
      this.city.setValue(address.PoliticalDivision2);
      this.zip.setValue(address.PostcodePrimaryLow);
    }

    this.deniedPartyScreening();
  }

  deniedPartyScreening() {
    this.loading = true;
    this.denied = false;
    this.userService.deniedPartyCheckV2({
      company: this.company.value,
      address: this.address1.value,
      city: this.city.value,
      state: this.state.value,
      country: 'US',
      zip: this.zip.value
    }).subscribe(
      deniedPartyFound => {
        this.loading = false;
        if (deniedPartyFound == false) {
          this.continueToNextPage();
        } else {
          this.showAddressValidation = false;
          this.denied = true;
          this.scrollToBottom();
        }
      }, error => {
        this.showAddressValidation = false;
        this.loading = false;
        this.systemError = true;
      }
    );
  }

  parseShipperNumbers(): [] {
    let a = this.shipperNumbers.value.replace(/\s/g,'');  //Remove any extra whitespaces in the string
    let b = a.split(','); //split the string up into an array
    let c = b.filter(x => x); //Remove any empty strings, undefined, and null values from the array
    return c;
  }

  continueToNextPage() {
    this.prospectService.deleteEmailVerEvent();
    this.storeData();
    this.router.navigate(['/prospect/quote']);
  }

  storeData() {
    let obj = {
      name: this.name.value,
      email: this.email.value,
      company: this.company.value,
      phone: this.phone.value,
      country: this.country.value,
      city: this.city.value,
      state: this.state.value,
      zip: this.zip.value,
      address1: this.address1.value,
      shipperNumbers: this.parseShipperNumbers(),
      rate: this.rate
    }

    sessionStorage.setItem('prospect', JSON.stringify(obj));
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  get name() { return this.formGroup.controls.name }
  get company() { return this.formGroup.controls.company }
  get email() { return this.formGroup.controls.email }
  get phone() { return this.formGroup.controls.phone }
  get country() { return this.formGroup.controls.country }
  get zip() { return this.formGroup.controls.zip }
  get city() { return this.formGroup.controls.city }
  get state() { return this.formGroup.controls.state }
  get address1() { return this.formGroup.controls.address1 }
  get shipperNumbers() { return this.formGroup.controls.shipperNumbers }
  get recaptcha() { return this.formGroup.controls.recaptcha }

}
