import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment'
import { TupssService } from './tupss.service';

@Injectable({
  providedIn: 'root'
})
export class TupssGuard  {
  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService,
    private tupssService: TupssService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let idTokenString = route.fragment ? route.fragment : this.tupssService.getTfcpTokenString();
    let hasToken: boolean = !!idTokenString;
    let token = hasToken ? idTokenString.match(/id_token=([\w_.\-]+)/i) : null;
    hasToken = token != null && token.length > 1 && token[1] != null;
    if (hasToken && !this.jwtHelper.isTokenExpired(token[1])) {
    // if(hasToken) {
      sessionStorage.setItem('URLFragment', idTokenString);
      return true;
    } else {
      if (state.url.startsWith('/tupss')) {
        if (sessionStorage.getItem('userDetails') || this.tupssService.userDetails) {
          return true;
        } else {
          this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
          return false;
        }
      } else if (state.url.startsWith('/tfcp')) {
        sessionStorage.clear();
        window.location.href = environment.tupss.signin;
      }
    }
  }
}
