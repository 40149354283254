import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-remove-payment-method-popup',
  templateUrl: './remove-payment-method-popup.component.html',
  styleUrls: ['./remove-payment-method-popup.component.scss']
})
export class RemovePaymentMethodPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RemovePaymentMethodPopupComponent>) { }

  ngOnInit(): void {
  }

  @Output() refreshContinueStatus = new EventEmitter<any>();

  public close(){
    this.dialogRef.close();
  }
  
  public continue() {
    this.refreshContinueStatus.emit(true);
    this.dialogRef.close();
  }

}
