import { Component, HostBinding, OnInit } from '@angular/core';
import { IPolicies } from '../../shared/models/policies.interface';
import { LoginService } from '../../services/login.service';
import { Title } from '@angular/platform-browser';
import { AccountInfo } from '../../shared/models/account-info.interface';
import { UserService } from 'src/app/services/user.service';
import { PolicyService } from 'src/app/services/policy.service';
import en from 'src/assets/i18n/en.json';
import { TaggingService } from 'src/app/tags/tagging.service';
import { UserPermission } from 'src/app/shared/models/user-permission';
import { DeliveryDefenseAgreementComponent } from 'src/app/shared/components/delivery-defense-agreement/delivery-defense-agreement.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @HostBinding('class') public hostClass = 'dashboard';
  policies: IPolicies;
  userDetails: AccountInfo;
  insured: string;
  PolicyNo: string;
  policyType: string;
  showDucumentNotification: boolean = false;
  showBilling: boolean;
  showInsights: boolean;
  isHPGuestUser: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isUsaUser: boolean;
  isInsureShield: boolean = false;

  public userPermission: UserPermission = new UserPermission();

  constructor(
    private loginService: LoginService,
    private titleService: Title,
    private userService: UserService,
    private policyService: PolicyService,
    private taggingService: TaggingService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.taggingService.view();
    this.titleService.setTitle(en.dashboard.setTitle);
    this.insured = localStorage.getItem("insured");
    this.policies = this.loginService.getPolicyDetails();
    this.userDetails = this.userService.getUserInfo();
    this.PolicyNo = this.policies.policyNumber;
    this.policyType = this.policyService.determinePolicyType(this.policies);
    this.showBilling = this.policyService.isGWPolicy();
    this.showInsights = this.policyService.hasInsights();
    this.isHPGuestUser = this.userService.isHPGuestUser();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.isUsaUser = this.userService.isUsaUser();

    this.userPermission = this.userService.getUserPermissions();

    if (this.policyType == 'transactional_parcel' || this.policyType == 'transactional_cargo' || this.policyType == 'traditional_cargo') {
      this.policyType = "InsureShield";
      this.isInsureShield = true;
    } else {
      this.policyType = `${this.policies.productName} ${this.policies.subPolicyType}`;
      this.isInsureShield = false;
    }
  }

  toggleDocumentNotification(toggle: any) {
    this.showDucumentNotification = toggle;
  }

  tagging(type) {
    if (type == 'viewPolicies') {
      this.taggingService.link({ link_name: 'view_your_policies_dashboard' });
    } else if (type == 'viewClaims') {
      this.taggingService.link({ link_name: 'file_a_claim_dashboard' });
    } else if (type == 'billing') {
      this.taggingService.link({ link_name: 'view_and_pay_invoices_dashboard' });
    } else if (type == 'viewAllClaims') {
      this.taggingService.link({ link_name: 'view_all_claims_dashboard' });
    } else if (type == 'viewAllInvoices') {
      this.taggingService.link({ link_name: 'view_all_invoices_dashboard' });
    }

  }
}
