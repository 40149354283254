import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable()
export class ApiEndpointService {
  private apiEndpoints = null;

  constructor(private environmentService: EnvironmentService) {
  }

  public getEndpoint(key: string): string {
    if (!this.apiEndpoints) {
      let cachedEndpointsText = sessionStorage.getItem('api-endpoints');
      if (!cachedEndpointsText) {
        this.constructEndpoints(this.environmentService.environment);
        cachedEndpointsText = sessionStorage.getItem('api-endpoints');
      }

      this.apiEndpoints = JSON.parse(cachedEndpointsText);
    }

    if (!this.apiEndpoints.hasOwnProperty(key)) {
      console.error(`Cannot find the endpoint named ${key}`);
      return null;
    }

    return this.apiEndpoints[key];
  }

  public constructEndpoints(env: any) {
    this.apiEndpoints = {
      login: `${env.jwtLoginBaseApiUrl}/login`,
      resetPassword: `${env.v3BaseApiUrl}/employees/:id/reset-password`,
      passwordReset: `${env.v3BaseApiUrl}/api/users/reset-password`, // ?userName={userName}&email={email}

      // user
      getUser: `${env.v3BaseApiUrl}/user`,
      getRMAUser: `${env.v3BaseApiUrl}RMAclone/:encryptToken`,
      saveUser: `${env.v3BaseApiUrl}/user`,
      saveUserTerms: `${env.v3BaseApiUrl}/user/terms/:ipAddress`,
      getCustomer: `${env.v3BaseApiUrl}/customers/:customerId`,
      getCustomerBillToAccounts: `${env.v3BaseApiUrl}/api/customers/bill-to-accounts`,
      getUsers: `${env.v3BaseApiUrl}/customer-users`,
      addUser: `${env.v3BaseApiUrl}/users/add`,
      disableUser: `${env.v3BaseApiUrl}/user/delete`, // ?userId={userId}
      updateUser: `${env.v3BaseApiUrl}/user/edit`, // ?userId={userId}

      // reset password
      changePassword: `${env.v3BaseApiUrl}/users/password/reset`, // ?userId={userId}
      validateToken:`${env.v3BaseApiUrl}/api/validate-token/:token`,
      changePasswordByToken:`${env.v3BaseApiUrl}/api/change-password/:token`, 

      // terms and conditions
      getTerms: `${env.v3BaseApiUrl}/terms`,
      getTermsSigned: `${env.v3BaseApiUrl}/terms/signed`,
      getTermsRma: `${env.v3BaseApiUrl}/terms/rma/:customerId`,
      saveTerms: `${env.v3BaseApiUrl}/terms`,
      saveTermsTemp: `${env.v3BaseApiUrl}/terms/tempuser`,

      // dashboard
      getAnnouncements: `${env.v3BaseApiUrl}/api/marketing/announcements`, // ?countryCode=all
      getEvents: `${env.v3BaseApiUrl}/api/marketing/events`, // ?countryCode=all
      getNews: `${env.v3BaseApiUrl}/api/marketing/news`, // ?countryCode=all
      getDashboardBanners: `${env.v3BaseApiUrl}/api/marketing/banners`, // ?stationID={stationID}&device={device}
      getSiteAnnouncements: `${env.v3BaseApiUrl}/api/marketing/announcements/web/:shippingStationId`,

      // contacts
      getApContact: `${env.v3BaseApiUrl}/contacts/ap`, // ?Except1row={Except1row}
      getCorporateContact: `${env.v3BaseApiUrl}/contacts/corporate`,
      getMailingContact: `${env.v3BaseApiUrl}/contacts/mailing`,
      saveApContact: `${env.v3BaseApiUrl}/contacts/insertap`,
      deleteApContact: `${env.v3BaseApiUrl}/contacts/delete/ap/:contactId`,
      updateApContact: `${env.v3BaseApiUrl}/contacts/update/ap`, // ?contactId={contactId}
      updateCorporateContact: `${env.v3BaseApiUrl}/contacts/update/corporate`, // ?contactId={contactId}
      updateMailingContact: `${env.v3BaseApiUrl}/contacts/update/mailing`, // ?contactId={contactId}

      // address book
      getAddressBookUri: `${env.v3BaseApiUrl}/address-book`, // ?contactid={contactid}
      getContactAddress: `${env.v3BaseApiUrl}/address-book/:contactId`,
      getAddressBookPage: `${env.v3BaseApiUrl}/address-book`, // ?page={page}&per_page={per_page}
      getCarrierAddressBook: `${env.v3BaseApiUrl}/address-book/express/ship-to`, // ?carriercode={carriercode}
      // searchAddressBook: `${env.v3BaseApiUrl}/address-book/search`, // ?carriercode={carriercode}&query={query}&column={column}
      searchCarrierAddressBook: `${env.v3BaseApiUrl}/address-book-search`, // ?carriercode={carriercode}&query={query}&column={column}
      saveAddressBook: `${env.v3BaseApiUrl}/address-book`,
      deleteAddressBook: `${env.v3BaseApiUrl}/address-book/:contactId`,
      shareAddressBook: `${env.v3BaseApiUrl}/address-book/share/:contactId/is-shared/:isShared`,
      expressAddressBook: `${env.v3BaseApiUrl}/address-book/express/:contactId/is-express/:isExpress`,
      uploadAddressBook: `${env.v3BaseApiUrl}/address-book/import`,

      // locations
      getLocations: `${env.v3BaseApiUrl}/locations`,
      getLocation: `${env.v3BaseApiUrl}/locations/:locationId`,
      checkDefaultLocation: `${env.v3BaseApiUrl}/locations/check-default/:locationId`,
      checkGuestLocation: `${env.v3BaseApiUrl}/locations/check-guest/:locationId`,
      deleteLocation: `${env.v3BaseApiUrl}/locations/:locationId`,
      saveLocation: `${env.v3BaseApiUrl}/locations`,
      saveDefaultLocation: `${env.v3BaseApiUrl}/locations/default/:contactId`,

      // countries
      getCountry: `${env.v3BaseApiUrl}/countries/:countryCode`,
      getCountryRma: `${env.v3BaseApiUrl}/countries/rma`,
      getCarrierCountries: `${env.v3BaseApiUrl}/countries`, // ?carriercode={carriercode}&isEU={isEU}&selectiontype={selectiontype}

      // claims
      getClaimContact: `${env.v3BaseApiUrl}/claims/contact`,
      saveClaim: `${env.v3BaseApiUrl}/claims`,

      // shipments
      getShipment: `${env.v3BaseApiUrl}/shipments/:shipmentId`,
      getShipmentPage: `${env.v3BaseApiUrl}/shipments`, // ?page={page}&per_page={per_page}
      getHighValueShipments: `${env.v3BaseApiUrl}/shipments/high-value`,
      getRecentShipments: `${env.v3BaseApiUrl}/shipments/recent`,
      getUserLatestShipments: `${env.v3BaseApiUrl}/api/shipments/top-5-shipment-by-user`,
      searchShipment: `${env.v3BaseApiUrl}/shipments/search`, // ?querystring={querystring}
      searchShipments: `${env.v3BaseApiUrl}/api/shipments/search`, // ?q={keyword}
      deleteShipment: `${env.v3BaseApiUrl}/shipments/:shipmentId`,
      saveWorldShipShipments: `${env.v3BaseApiUrl}/api/shipments/worldship`,
      saveShipments: `${env.v3BaseApiUrl}/api/shipments`,
      confirmShipment: `${env.v3BaseApiUrl}/shipments/:quoteId`,
      getEmailNotificationTemplates: `${env.v3BaseApiUrl}/api/notify/templates`,
      checkDoNotShip: `${env.v3BaseApiUrl}/api/shipments/check-do-not-ship`, // ?zip={zip}&carrierCode={carrierCode}&countryCode={countryCode}
      checkRestricted: `${env.v3BaseApiUrl}/address/check-restricted`, // ?zip={zip}&insuredvalue={insuredvalue}&carrier={carrier}&servicecode={servicecode}
      checkSchedulePickup: `${env.v3BaseApiUrl}/Users/pickup/today`, // ?shipfromid={shipfromid}&scheduleddate={scheduleddate}&carriercode={carriercode}&serviceCode={serviceCode}
      getFedExLocations: `${env.v3BaseApiUrl}/shipments/fedex-locator`, // ?zip={zip}&phonenumber={phonenumber}
      getPowerOfAttorneyContent: `${env.v3BaseApiUrl}/api/shipments-power-of-attorney-content`,
      createHighValueShipment: `${env.v3BaseApiUrl}/HighValue`, // ?quoteid={quoteid}
      getShipmentLabel: `${env.v3BaseApiUrl}/shipments/label`, // ?shipmentId={shipmentId}
      getCommercialInvoice: `${env.v3BaseApiUrl}/shipments/commercial-invoice/:shipmentId`,
      getHandheldSignature: `${env.v3BaseApiUrl}/api/tracking/handheld-signature`, // ?shipmentId={shipmentId}&whatImage={whatImage}

      // quotes
      getQuotes: `${env.v3BaseApiUrl}/quotes`, // ?page={page}&per_page={per_page}
      getQuote: `${env.v3BaseApiUrl}/quotes/:quoteId`,
      addQuotes: `${env.v3BaseApiUrl}/quotes`,
      saveQuote: `${env.v3BaseApiUrl}/save-quote/:quoteId`,
      updateQuotes: `${env.v3BaseApiUrl}/quote/update`,
      deleteQuote: `${env.v3BaseApiUrl}/quotes/:quoteId`,
      submitToACE: `${env.v3BaseApiUrl}/shipments/ace/:quoteId`, // this is for a GET request.
      getSavedQuotes: `${env.v3BaseApiUrl}/save-quotes`, // ?page={page}&per_page={per_page}

      // commodities
      getCommodities: `${env.v3BaseApiUrl}/commodities`,
      getHarmonizedCommodities: `${env.v3BaseApiUrl}/commodities/country/:countryCode`,

      // reported shipments
      getReportedShipments: `${env.v3BaseApiUrl}/reported-shipments`, // ?daysOld={daysOld}&page={page}&per_page={per_page}
      deleteReportedShipment: `${env.v3BaseApiUrl}/reported-shipments/:shipmentId`,
      reportShipmentUS: `${env.v3BaseApiUrl}/api/shipments/report/us`,
      reportShipmentEU: `${env.v3BaseApiUrl}/api/shipments/report/eu`,
      validateTrackingNumber: `${env.v3BaseApiUrl}/api/validate/shipment/reported/tracking-number-exists/:trackingNumber`,
      getReportServices: `${env.v3BaseApiUrl}/api/report-services`,

      // tracking
      getTrackingDetails: `${env.v3BaseApiUrl}/tracking-results`, // ?shipmentid={shipmentid}&shipmenttype={shipmenttype}

      // invoices
      getBillingHistory: `${env.v3BaseApiUrl}/invoices`, // ?page={page}&per_page={per_page}
      getInvoice: `${env.v3BaseApiUrl}/invoices/:invoiceId`,

      // scheduled pickup
      getScheduledPickups: `${env.v3BaseApiUrl}/scheduled-pickups`,
      deleteScheduledPickupQuote: `${env.v3BaseApiUrl}/scheduled-pickups/:quoteId`,

      // carrier
      getCarrierKey: `${env.v3BaseApiUrl}/api/carriers/keys/:carrierCode`,
      getShippingCarrierServices: `${env.v3BaseApiUrl}/api/carriers/services`, // ?carrierCode=1&isDomestic=true&shipFromCountryCode=US
      getShippingCarrierPackageTypes: `${env.v3BaseApiUrl}/carriers/package-types`, // ?carrierservicecode=01&carriercode=01
      getPackageWeight: `${env.v3BaseApiUrl}/carriers/weight`, // ?length=1&width=2&height=3

      // lookup
      getCountries: `${env.v3BaseApiUrl}/api/lookup/countries`,
      getCarrierServices: `${env.v3BaseApiUrl}/api/lookup/carrier-services/:carrierCode`,
      getCustomerCarriers: `${env.v3BaseApiUrl}/api/lookup/carriers/customer/:customerId`,

      // address
      getAddressByZipCode: `${env.v3BaseApiUrl}/validate/postal-code/:postalCode`,
      validateAddress: `${env.v3BaseApiUrl}/validate/address`,

      // payments
      getPaymentPage: `${env.v3BaseApiUrl}/payments`, // ?page={page}&per_page={per_page}

      // payment receipt
      getPaymentReceipt: `${env.v3BaseApiUrl}/api/payment/receipt/:paymentId`,

      // credit cards
      getCreditCards: `${env.v3BaseApiUrl}/credit-cards`,
      chargeCreditCard: `${env.v3BaseApiUrl}/credit-cards`,
      changePrimaryCreditCard: `${env.v3BaseApiUrl}/credit-cards/primary`,
      removeCreditCard: `${env.v3BaseApiUrl}/credit-cards/:usercreditcardid`,
      createAnetUser: `${env.v3BaseApiUrl}/credit-cards/AnetProfile`,
      getCreditCardForms: `${env.v3BaseApiUrl}/credit-cards/CCForm`,
      addPaymentProfile: `${env.v3BaseApiUrl}/credit-cards/AddPaymentProfile`,
      editPaymentProfile: `${env.v3BaseApiUrl}/credit-cards/EditPaymentProfile`,
      getCreditCardTerms: `${env.v3BaseApiUrl}/Terms/credit-card`,
      acceptCreditCardTerms: `${env.v3BaseApiUrl}/Terms/credit-card`,
      getPrimaryCreditCard: `${env.v3BaseApiUrl}/credit-cards/primary`,
      // bir
      getShipmentExceptions: `${env.v3BaseApiUrl}/api/shipments/exceptions`,
      updateShipmentDate: `${env.v3BaseApiUrl}/api/shipment-date`,
      voidShipment: `${env.v3BaseApiUrl}/shipments/:shipmentId`,

      // cost estimator
      getRates: `${env.v3BaseApiUrl}/estimator`,
      getCustomerServices: `${env.v3BaseApiUrl}/api/customer-services`, //?countryCode=US,

      // upload files
      uploadFiles: `${env.v3BaseApiUrl}/api/files/customer-assets`,

      // drop shipping label
      dropShipmentLabel: `${env.v3BaseApiUrl}/shipments/drop`,

      // reports
      getEndOfDayReport: `${env.v3BaseApiUrl}/shipments/reports/end-of-day`, // ?reportdate={2016-02-15}
      getEndOfDayReportByLocation: `${env.v3BaseApiUrl}/shipments/reports/end-of-day-by-location`, // ?reportdate={2016-02-15}&carrier={fedex}&locationid={4B7C4C97-310F-4ED2-8D4E-0F908A10C680}
      getEndOfDayReportByUser: `${env.v3BaseApiUrl}/shipments/reports/end-of-day-by-user`, // ?reportdate={2016-02-15}&carrier={fedex}&userId={10618}
      getEndOfDayReportLocations: `${env.v3BaseApiUrl}/api/customers/end-of-day-report-locations`,
      getEndOfDayReportUsers: `${env.v3BaseApiUrl}/api/customers/end-of-day-report-users`,
      getEndOfDayHighValueReport: `${env.v3BaseApiUrl}/shipments/reports/end-of-day-high-value`,

      // rewardship
      getRewardsHistory: `${env.v3BaseApiUrl}/api/customers/rewards-history`, // ?page={page}&per_page={per_page}
      redeemRewards: `${env.v3BaseApiUrl}/api/customers/redeem-rewards`,
      getRewardsHistoryHeader: `${env.v3BaseApiUrl}/api/customers/rewards-history-header`,


      // notify templates
      getNotifyTemplates: `${env.v3BaseApiUrl}/api/notify/templates`,
      getNotifyTemplate: `${env.v3BaseApiUrl}/api/notify/templates/:templateId`,
      addNotifyTemplate: `${env.v3BaseApiUrl}/api/marketing/save-notifyemailtemplate`,
      setPrimaryNotifyTemplate: `${env.v3BaseApiUrl}/shipments/manage-email-templates/default/:templateId`,
      deleteNotifyTemplate: `${env.v3BaseApiUrl}/shipments/manage-email-templates/:templateId`,

      // supply-order
      sendSupplyOrder: `${env.v3BaseApiUrl}/supplies`,
      getSupplyOrderHistory: `${env.v3BaseApiUrl}/api/supplies/order-history`, // ?page={page}&per_page={per_page}
      getSupplyOrderDetails: `${env.v3BaseApiUrl}/api/supplies/:RequestId`,

      // e-invoice
      getContactCorporate: `${env.v3BaseApiUrl}/contacts/corporate`,
      updateEInvoiceSubscription: `${env.v3BaseApiUrl}/customers/setting/e-invoice`, // payload: EInvoiceSubscription

      // send email
      // correct these endpoints!
      sendEmail: `http://169.46.65.71/node/parcelpro-server/sendemail`,
      sendClaimEmail: `${env.v3BaseApiUrl}/claim-email`,

      // supply-order
      // correct these endpoints!
      confirmSupplyOrder: `${env.v3BaseApiUrl}/confirm-supply-order`,

      // configuration
      getUserCarrierConfiguration: `${env.v3BaseApiUrl}/api/shipments/configuration/:carrierCode`,

      // upload
      uploadPhoto: `${env.v3BaseApiUrl}/Upload/uploadPhoto`, // ?fileName={fileName}&description={description}

      // mobile view
      getMobileToken: `${env.v3BaseApiUrl}/api/mobile-ship-token/:userId`,
      tokenLogin: `${env.jwtLoginBaseApiUrl}/mobile-ship-jwt/:loginToken`,

      // s3
      downloadFileFromS3: `${env.v3BaseApiUrl}/files/s3/:bucketName/:fileName/`,
    };

    sessionStorage.setItem('api-endpoints', JSON.stringify(this.apiEndpoints));
  }
}
