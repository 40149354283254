import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProspectPortalRoutingModule } from './prospect-portal-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UnderReviewComponent } from '../prospect-portal/under-review/under-review.component';
import { ProductSelectionComponent } from '../prospect-portal/product-selection/product-selection.component';
import { ShipperComponent } from '../prospect-portal/shipper/shipper.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReviewComponent } from './submit/submit.component';
import { StartComponent } from './start/start.component';
import { QuoteComponent } from './quote/quote.component';
import { SuccessComponent } from './success/success.component';
import { DeclineComponent } from './decline/decline.component';

@NgModule({
  declarations: [
    StartComponent,
    UnderReviewComponent,
    ProductSelectionComponent,
    ShipperComponent,
    ReviewComponent,
    QuoteComponent,
    SuccessComponent,
    DeclineComponent
  ],
  imports: [
    CommonModule,
    ProspectPortalRoutingModule,
    SharedModule,
    TranslateModule
  ]
})
export class ProspectPortalModule { }
