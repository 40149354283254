import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent  {

  constructor(private dialogRef: MatDialogRef<AccessDeniedComponent>) { }

  close(): void {
    this.dialogRef.close();
  }

}
