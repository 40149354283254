<div class="claim-section">
  <div class="policy-info-box" *ngIf="showHeader && !isGuestUser">
    <div class="light-green-box">
      <div *ngIf="!isDDClaimsFlow" class="claims-for">
        {{ 'dashboard.ClaimsActivity.PolicyType' | translate }}
        <span *ngIf="!isGermanyUser && !isInsureShield">{{ policyType }}</span>
        <span *ngIf="!isGermanyUser && isInsureShield">{{ policyType }}<sup>®</sup></span>
        {{'dashboard.ClaimsActivity.Policy' | translate }}
        <div class="policy-number">{{'dashboard.ClaimsActivity.PolicyNo' | translate }}{{policyNo}}</div>
      </div>
      <div *ngIf="isDDClaimsFlow" class="claims-for">
        {{ 'dashboard.ClaimsActivity.PolicyType' | translate }}
        <span>{{ 'dashboard.ClaimsActivity.DDScoreProtection' | translate }}</span>
        <div class="policy-number">{{'dashboard.ClaimsActivity.contractNo' | translate }}{{policyNo}}</div>
      </div>
      <button *ngIf="!displayStartClaimButton && isFileClaim" class="claim-button upsc-button" routerLink="/claims/new"><span class="file-text">{{ 'claims.claimMenus.fileNewClaim' | translate }}</span><mat-icon>keyboard_arrow_right</mat-icon></button>
      <button *ngIf="!displayStartClaimButton && !isFileClaim" class="claim-button upsc-white-button" [disabled]="true"><span class="file-text">{{ 'claims.claimMenus.fileNewClaim' | translate }}</span><mat-icon>keyboard_arrow_right</mat-icon></button>
      <button *ngIf="displayStartClaimButton" class="claim-button upsc-button" (click)="fileAClaim(); tagging('start')" href="#"><span class="text5">{{ 'claims.claimMenus.startClaim' | translate }}</span><mat-icon>keyboard_arrow_right</mat-icon></button>
      <div *ngIf="showManagePayee && (userPermission.isClaimPayee) && canViewDPATPayees">
        <img src="../../../assets/images/add_card_header.png">
        <a class="manage-payee upsc-link" (click)="checkBeforeRedirectToManagePayees()">{{ 'claims.list.claimsSearch.manage' | translate }}<mat-icon class="icon-key">open_in_new</mat-icon></a>
      </div>
      <div class="d-flex error" *ngIf="systemError">
        {{ 'common.systemError' | translate }}
      </div>
    </div>
  </div>

  <div #parent (mousedown)="startDragging($event, false)" (mouseup)="stopDragging($event, false)" (mousemove)="moveEvent($event)" class="isop-navigation d-flex align-items-center justify-content-between" *ngIf="showHeader">
    <ul class="navigation-items">
      <li class="navigation-item" *ngIf="(!isGuestUser)">
        <a href="#" routerLink="/claims" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [ngClass]="{'active': isClaimsActive}" (click)="tagging('all')">{{ 'claims.claimMenus.allClaims' | translate }}</a>
      </li>
      <li class="navigation-item" *ngIf="!isGuestUser && (userPermission.isFileClaim)">
        <a href="#" routerLink="/claims/new" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="tagging('file'); redirect()">{{ 'claims.claimMenus.fileNewClaim' | translate }}</a>
      </li>
      <li class="navigation-item" *ngIf="!isGuestUser && hasHVCM && (userPermission.isHVCM)">
        <a href="#" routerLink='/claims/MultiClaim' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{ 'claims.claimMenus.fileMultiClaim' | translate }}</a>
      </li>
      <li class="navigation-item" *ngIf="isUsaUser && !isDDClaimsFlow && !isGuestUser && (userPermission.isFileClaim)"
        [ngClass]="{'message-bubble': showStats && (userPermission.isFileClaim) }">
        <a href="#" routerLink="/claims/message-list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{ 'claims.claimMenus.Messages' | translate }}</a>
        <div *ngIf="showStats && (userPermission.isFileClaim)" (click)="gotoMessages($event)" class="message-badge"
        ngbTooltip="You have {{claimStats?.claimsWithNewMessagesCnt}} new messages!" placement="right"
        tooltipClass="ngb-tooltip-class">
        {{ claimStats?.claimsWithNewMessagesCnt }}
        </div>
      </li>
      <li class="navigation-item" *ngIf="!isGuestUser && !isDDClaimsFlow">
        <a href="#" routerLink="/claims/help" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="tagging('faq')">{{ 'claims.claimMenus.claimsHelp' | translate }}</a>
      </li>
    </ul>
  </div>
  <router-outlet></router-outlet>
</div>
