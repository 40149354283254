import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProspectService } from '../../../services/prospect.service';
import  * as _ from 'lodash';
import { TaggingService } from 'src/app/tags/tagging.service';

@Component({
  selector: 'upsc-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss']
})

export class ProductSelectionComponent implements OnInit {
  selectedProducts: any[] = [];
  prospectPortalData;
  pageError: boolean = false;
  selectionError: boolean = false;

  constructor(
    private prospectService: ProspectService,
    private router: Router,
    private taggingService: TaggingService
  ) {
    this.prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData'));
    if (this.prospectPortalData) {
      this.selectedProducts = this.prospectPortalData.selectedProducts || [];
      if(this.selectedProducts.length > 0) {
        this.products.forEach(p => {
          if(p.options) {
            let optionsBeenbSelected = []
            p.options.forEach(o => {
              if(this.selectedProducts.includes(o.code)) {
                optionsBeenbSelected.push(o.code);
              }
            });
            p.selectedOptions = optionsBeenbSelected;
          }
          p.selected = this.selectedProducts.includes(p.code) || p.selectedOptions?.length > 0;
        })
      }
    } else {
      this.pageError = true;
    }
  }

  back() {
    this.taggingService.link({
      link_name: "commodity_selection_page",
      link_page_id: "Back"
    });
    this.router.navigate(['prospect-portal/shipping']);
  }

  ngOnInit() {
    this.taggingService.view({
      page_id: "commodity_selection_page",
      page_name: "Commodity Selection"
    });
  }

  products = [
    { image: '../../../../assets/images/apparel.png', text: 'app.armor.apparel', selected: false, hasDropdown: true, options: [{ text: 'app.armor.dropdown.apparel', code: '006' }, { text: 'app.armor.dropdown.textile', code: '161' }], selectedOptions: [], value: 0 },
    { image: '../../../../assets/images/category.png', text: 'app.armor.artwork', code: '011', selected: false, hasDropdown: false, value: 0 },
    { image: '../../../../assets/images/airport_shuttle.png', text: 'app.armor.auto', code: '019', selected: false, hasDropdown: false, value: 0 },
    { image: '../../../../assets/images/cable.png', text: 'app.armor.electronics', selected: false, hasDropdown: true, options: [{ text: 'app.armor.dropdown.electronic', code: '070' }, { text: 'app.armor.dropdown.consumer', code: '062' }, { text: 'app.armor.dropdown.computers', code: '059' }, { text: 'app.armor.dropdown.commercial', code: '051' }, { text: 'app.armor.dropdown.cell', code: '043' }], selectedOptions: [], value: 0 },
    { image: '../../../../assets/images/chair.png', text: 'app.armor.furniture', code: '078', selected: false, hasDropdown: false, value: 0 },
    { image: '../../../../assets/images/precision_manufacturing.png', text: 'app.armor.equipment', selected: false, hasDropdown: true, options: [{ text: 'app.armor.dropdown.hardware', code: '091' }, { text: 'app.armor.dropdown.machinery', code: '018' }, { text: 'app.armor.dropdown.metal', code: '107' }, { text: 'app.armor.dropdown.construction', code: '060' }, { text: 'app.armor.dropdown.pipes', code: '133' }, { text: 'app.armor.dropdown.oil', code: '118' }, { text: 'app.armor.dropdown.aircraft', code: '002' }], selectedOptions: [], value: 0 },
    { image: '../../../../assets/images/aod_watch.png', text: 'app.armor.jewelry', code: '096', selected: false, hasDropdown: false, value: 0 },
    { image: '../../../../assets/images/lab_profile.png', text: 'app.armor.supplies', code: '116', selected: false, hasDropdown: false, value: 0 },
    { image: '../../../../assets/images/medication.png', text: 'app.armor.healthcare', selected: false, hasDropdown: true, options: [{ text: 'app.armor.dropdown.instruments', code: '104' }, { text: 'app.armor.dropdown.medical', code: '105' }], selectedOptions: [], value: 0 },
    { image: '../../../../assets/images/outdoor_grill.png', text: 'app.armor.sports', code: '165', selected: false, hasDropdown: false, value: 0 },
  ];


  onBoxClick(index: number) {
    const selectedProducts = this.products.filter(product => product.selected).length;
    if (!this.products[index].selected && selectedProducts >= 3) {
      return;
    }

    this.products[index] = { ...this.products[index], selected: !this.products[index].selected };
    if (!this.products[index].selected) {
      this.products[index].selectedOptions = [];
    }
    this.updateBoxValues();
  }

  updateBoxValues() {
    const selectedProducts = this.products.filter(product => product.selected);
    const count = selectedProducts.length;
    if (count === 1) {
      selectedProducts[0].value = 100;
    } else if (count === 2) {
      selectedProducts[0].value = 50;
      selectedProducts[1].value = 50;
    } else if (count === 3) {
      selectedProducts[0].value = 33;
      selectedProducts[1].value = 33;
      selectedProducts[2].value = 34;
    }
  }

  toggleDropdown(index: number): void {
    if (this.products[index].selected) {
      this.products[index].selected = false;
      if (this.products[index].hasDropdown && this.products[index].selectedOptions.length > 0) {
        this.selectedProducts = _.pullAll(this.selectedProducts, this.products[index].selectedOptions);
        this.products[index].selectedOptions = [];
      }
    } else {
      if (this.selectedProducts.length < 3) {
        this.products[index].selected = true;
      }
    }
  }

  toggleSelection(box: any) {
    if(!box.hasDropdown) {
      const index = this.selectedProducts.findIndex(p => p == box.code);
      if (index > -1) {
        this.selectedProducts.splice(index, 1);
      } else if (this.selectedProducts.length < 3) {
        this.selectedProducts.push(box.code);
      }
   }
  }

  onOptionChange(option: string, index: number) {
    const product = this.products[index];
    const optionIndex = product.selectedOptions.indexOf(option);
    // let optionObj = product.options.find(o => o.code == option);
    if (optionIndex > -1) {
      product.selectedOptions.splice(optionIndex, 1);
      let indexInSelectedProducts = this.selectedProducts.findIndex(p => p == option);
      if(indexInSelectedProducts > -1) {
        this.selectedProducts.splice(indexInSelectedProducts, 1);
      }
    } else if(this.selectedProducts.length < 3) {
      product.selectedOptions.push(option);
      this.selectedProducts.push(option)
    }
  }

  isBoxSelected(): boolean {
    return this.products.some(product => product.selected);
  }

  onSubmit(event: Event) {
    this.taggingService.link({
      link_name: "Continue",
      link_page_id: "commodity_selection_page"
    });
    event.preventDefault();
    /* const selectedProducts = this.products.filter(product => product.selected).map(product => ({
      text: product.text,
      selectedOptions: [product.selectedOptions ? product.selectedOptions : { text: product.text, code: product.code }]
    })); */

    if (this.selectedProducts.length == 0) {
      this.selectionError = true;
      return;
    } else {
      this.selectionError = false;
    }

    const obj = {
      selectedProducts: this.selectedProducts,
      currentPage: '/prospect-portal/review'
    };

    const prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData')) || {};
    const data = { ...prospectPortalData, ...obj };
    this.prospectService.saveProspectPortalSession({
      opportunityID: this.prospectPortalData.opportunityID,
      emailAddress: this.prospectPortalData.customerEmail,
      data: JSON.stringify(data)
    }).subscribe({
      next: (response) => {
        sessionStorage.setItem('prospectPortalData', JSON.stringify(data));
        this.router.navigate(['prospect-portal/review']);
      },
      error: (err) => {
        console.log('Error saving session');
      }
    });
  }
}
