import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-ccp-terms',
  templateUrl: './ccp-terms.component.html',
  styleUrls: ['./ccp-terms.component.scss']
})
export class CcpTermsComponent {

  constructor(
    public dialogRef: MatDialogRef<CcpTermsComponent>
  ) {

  }


  close(type: 'decline' | 'accept') {
    this.dialogRef.close(type);
  }
}
