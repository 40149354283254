import { Component, HostBinding, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-tupss-home-footer',
  templateUrl: './tupss-home-footer.component.html',
  styleUrls: ['./tupss-home-footer.component.scss']
})
export class TupssHomeFooterComponent implements OnInit {

  @HostBinding('class') public hostClass = 'tupss-layout';
  isAdjuster: boolean;
  constructor(private userService: UserService) { }

  public ngOnInit(): void {
    this.isAdjuster = this.userService.isUserAdjuster();
  }

}
