<div class="layout-wrapper" *ngIf="!pageLoading">
  <div class="wrapper">
    <div class="title">Welcome to InsureShield Shipping Insurance!</div>
    <div class="green-bar"></div>
    <div class="card">
      <div class="card-title">Your New Policy Details</div>
      <div class="d-flex">
        <div class="mr-4">
          <div class="sub-title">Policy Number</div>
          <div class="text">{{ prospectPortalData?.policyNumber }}</div>
        </div>
        <div>
          <div class="sub-title">Policy Period</div>
          <div class="text">{{ prospectPortalData?.upcomingSunday | date:'MM/dd/yyyy' }} - {{ prospectPortalData?.expirationDate | date:'MM/dd/yyyy' }}</div>
        </div>
      </div>
    </div>
    <div class="paragraph">
      Copy and paste your policy number to create your login for the InsureShield online portal, your <span class="bold">one stop shop for all things</span> claims and billing.
    </div>
    <div class="paragraph">
      <span class="bold">It's more than just insurance</span>. As a valued insurance customer you also get <span class="bold">complementary access to risk mitigation tools</span> such as DeliveryDefense Address Confidence in the portal, too!
    </div>
    <div class="paragraph">
      We're glad you're here, and happy shipping!
    </div>
    <div class="title middle">Access Your InsureShield Account</div>
    <div class="green-bar middle"></div>
    <div class="steps">
      <div class="step">
        <div class="number">1</div>
        <div class="text-and-pic">
          <div class="">Create Your Username and Password</div>
          <img src="assets/images/ups-dashboard-1.png">
        </div>
      </div>
      <div class="step">
        <div class="number">2</div>
        <div class="text-and-pic">
          <div class="">Set Up Your Payment Methods</div>
          <img src="assets/images/ups-pay-bill-1.png">
        </div>
      </div>
      <div class="step">
        <div class="number">3</div>
        <div class="text-and-pic">
          <div class="">Manage Your</div>
          <div>Claims</div>
          <img src="assets/images/ups-articles-1.png">
        </div>
      </div>
    </div>
    <div class="policy">
      Policy Number: <span class="bold">{{ prospectPortalData?.policyNumber }}</span>
    </div>
    <button class="upsc-primary-button icon-right mb-5" (click)="navigate()">
      Create Your InsureShield Login<mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>

<div class="spinner d-flex justify-content-center" *ngIf="pageLoading">
  <div class="spinner-border">
      <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>