<upsc-user-filter (simplePolicySelect)="simplePolicySelect($event)" (notifySearch)="notifySearch($event)" (notifyReset)="notifyReset($event)"></upsc-user-filter>
<div class="claims-container">
  <div *ngIf="isLoading" class="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading Policies...</span>
    </div>
  </div>

  <div class="desktop-claim-table" #table>
    <div class="overflow-format">
      <table class="upsc-table-isop" *ngIf="!isLoading">
        <tbody>
          <tr>
            <th><div class="table-header"><span class="text">Name</span></div></th>
            <th><div class="table-header"><span class="text">Email</span></div></th>
            <th><div class="table-header"><span class="text">Shipper Name</span></div></th>
            <th><div class="table-header"><span class="text">Shipper Number</span></div></th>
            <th><div class="table-header"><span class="text">Role</span></div></th>
            <th><div class="table-header"><span class="text">Notifications</span></div></th>
            <th><div class="table-header"><span class="text">Status</span></div></th>
          </tr>
          <tr *ngFor="let page of pagedItems" class="data">
            <td><div class="customer" (click)="editUser(page)"><span class="text">{{ page.name }} </span><mat-icon>chevron_right</mat-icon></div></td>
            <td>{{ page.email }}</td>
            <td>{{ page.shipperName }}</td>
            <td>{{ page.shipperNumber }}</td>
            <td>{{ page.role }}</td>
            <td>{{ page.notification }}</td>
            <td><div class="action" (click)="delete(page)"><span class="text">{{ page.status }}</span><mat-icon>delete_outline</mat-icon></div></td>
          </tr>
          <tr *ngIf="pagedItems.length===0">
            <td colspan="6">No Records Found</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="pager.currentPage>0 && pageSize>0" class="desktop-claim-table">
    <upsc-paginate-list [currentPage]="pager.currentPage" [pager]="pager" [pagePerAsset]="pageSize"
      (pagerNotification)="setPager($event)" (assetNotification)="loadAssetPerPage($event)"></upsc-paginate-list>
  </div>
</div>

<div class="table-small">
  <div class="heading">
    Shipper Accounts
  </div>
  <div *ngIf="!isLoading">
    <div *ngFor="let page of pagedItems" class="card">
      <div class="title"><span class="text"> {{ page.shipperNumber }}</span> <mat-icon (click)="delete(page)">delete_outline</mat-icon></div>
     
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="label">Email</div>
        <div class="text">{{ page.email }}</div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="label">Role</div>
        <div class="text">{{ page.role }}</div>
      </div>   
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="label">Notification</div>
        <div class="text">{{ page.notification }}</div>
      </div> 
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="label">Status</div>
        <div class="text">{{ page.status }}</div>
      </div>    
      <div class="edit">
        <div class="edit-content" (click)="editUser(page.userId)">
          <span class="text">{{ page.name }}</span>
          <mat-icon>edit</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="pager.currentPage>0 && pageSize>0" class="paginate-mobile">
    <upsc-paginate-list [currentPage]="pager.currentPage" [pager]="pager" [pagePerAsset]="pageSize"
    (pagerNotification)="setPager($event)" (assetNotification)="loadAssetPerPage($event)"></upsc-paginate-list>
  </div>
</div> 