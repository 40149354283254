<div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
  <div class="spinner-border">
    <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>
<div *ngIf="systemError" class="red">
  {{ 'common.systemError' | translate }}
</div>
<div *ngIf="showEmailVerification" class="mb-5">
  <upsc-email-verification [claimDetailFlow]="true"></upsc-email-verification>
</div>