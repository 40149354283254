import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service'; 

@Component({
  selector: 'upsc-flex-header',
  templateUrl: './flex-header.component.html',
  styleUrls: ['./flex-header.component.scss']
})
export class FlexHeaderComponent {
  isCanadaUser: boolean;
  constructor(private userService: UserService) {
    
  }

  public ngOnInit() {
    this.isCanadaUser = this.userService.isCanadaUser();
  }

  isPageInit: boolean = false;
  ngAfterContentInit() {
    this.isPageInit = true;   
  }

  public ngOnDestroy() {
    
  }
}
