
export class MyPolicy {

  account: Account
  validationStatus: string
  emailRequest: EmailRequest

  setData(data: any,uID: string) {
    if (data) {
      this.account = data.policyRequest.account;
      this.account.uid = uID;
      this.emailRequest = data.policyRequest.emailRequest;
      this.emailRequest.data.uID = uID;
      this.validationStatus = data.policyRequest.validationStatus;
    }
    return this;
  }  

  constructor() {

  }
}

export class Account {
  contactPerson: string
  accountHolderContact: AccountHolderContact
  policy: Policy
  producerCode: string
  uid: string
}

export class AccountHolderContact {
  address: Address
  name: string
  workPhone: string
  emailAddress1: string
}

export class Address {
  addressLine1: string
  addressLine2: string
  city: string
  state: State
  postalCode: string
  country: Country
}

export class State {
  code: string
}

export class Country {
  code: string
}

export class Policy {
  recipientName: string
  masterCustomerID: string
  technologyAgreement: boolean
  informationDeclaration: boolean
  usedAgreement: boolean
  policyNumber: string
  onlineQuoteRequestUID: string
  merchantOrderID: string
  premium: number
  tax: number
  onDemand: OnDemand
  requestedPaymentPlan: string
  uwcompany: string
  paymentAmount: number
  termType: TermType
  roleAssignments: RoleAssignment[]
}

export class OnDemand {
  oneTimeShipments: OneTimeShipment[]
  coverages: Coverage[]
}

export class OneTimeShipment {
  dateOfShipment: string
  typeOfShipment: TypeOfShipment
  shipmentMode: ShipmentMode
  commodityCategory: CommodityCategory
  transportationCarrier: TransportationCarrier
  goodsBeingShipped: string
  specialConditions: string
  originCompany: string
  originAddress: OriginAddress
  destinationCompany: string
  destinationAddress: DestinationAddress
  conditionofCommodity: ConditionofCommodity
  valueOfShipment: number
  premium: number
  deductible: number
}

export class TypeOfShipment {
  code: string
}

export class ShipmentMode {
  code: string
}

export class CommodityCategory {
  code: string
}

export class TransportationCarrier {
  code: string
}

export class OriginAddress {
  addressLine1: string
  addressLine2: string
  city: string
  state: State
  postalCode: string
  country: Country
}



export class DestinationAddress {
  addressLine1: string
  addressLine2: string
  city: string
  state: State
  postalCode: string
  country: Country
}



export class ConditionofCommodity {
  code: string
}

export class Coverage {
  codeIdentifier: string
  terms: Term[]
}

export class Term {
  codeIdentifier: string
  value: string
}

export class TermType {
  code: string
}

export class RoleAssignment {
  assignedRole: string
  user: User
}

export class User {
  userName: string
  employeeNumber: string
}

export class EmailRequest {
  applicationName: string
  emailFor: string
  environment: string
  data: Data
}

export class Data {
  contactInfo: ContactInfo
  insuredAmtInWord: string
  insuredAmt: string
  totalPremium: string
  coiNumber: string
  transactionDate: string
  companyName: string
  policyNumber: string
  commodity: string
  shipmentType: string
  shipmentMode: string
  shipDate: string
  deductible: string
  merchandiseDesc: string
  shipperAddress: ShipperAddress
  shipToAddress: ShipToAddress
  uID: string
}

export class ContactInfo {
  contactName: string
  phoneNumber: string
  email: string
}

export class ShipperAddress {
  addressLine1: string
  addressLine2: string
  city: string
  zipCode: string
  state: string
  country: string
}

export class ShipToAddress {
  city: string
  state: string
  country: string
  zipCode: string
}
