<div class="d-flex flex-column-reverse mb-3 mb-lg-4">
  <div id="divFooter">
    <div class="footer-wrap-left mt-3">
      <!-- desktop -->
      <div class="footer-align">
        <div class="break"></div>
        <div class="footer-link">
          <a href="https://www.ups.com/us/en/Home.page" target="_blank" rel="noopener noreferrer">
            UPS.com<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link">
          <a href="https://about.ups.com/us/en/newsroom.html" target="_blank" rel="noopener noreferrer">
            UPS Press Room<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link">
          <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
            {{ 'app.footer.cookies' | translate }}
          </a>
        </div>
        <div class="footer-link">
          <a href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank">
            {{'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link">
          <a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link">
          <a href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link">
          <a href="https://www.insureshield.com/us/en/legal/product-disclosure.html" target="_blank">
            {{ 'app.footer.links.flexproduct' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link">
          <a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/california-privacy-rights.page"
            target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.privacyRights' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link">
          <a href="https://upscapital.com/opt-out/" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.flexdonotsell' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
      </div>
      <!-- mobile -->
      <div class="footer-align-mobile">
        <!-- mobile footer start -->

        <div class="footer-link-mobile">
          <a href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);">
            {{ 'app.footer.cookies' | translate }}
          </a>
        </div>
        <div class="footer-link-mobile">
          <a href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank">UPS Capital 
            {{'app.footer.links.techAgreement' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link-mobile">
          <a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.termsOfUse' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link-mobile">
          <a href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.footer.links.privacyNotice' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link-mobile">
          <a href="https://www.insureshield.com/us/en/legal/product-disclosure.html" target="_blank">
            {{ 'app.footer.links.flexproduct' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link-mobile">
          <a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/california-privacy-rights.page"
            target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.privacyRights' | translate }}<mat-icon>launch</mat-icon>
          </a>
        </div>
        <div class="footer-link-mobile">
          <a href="https://upscapital.com/opt-out/" target="_blank" rel="noopener noreferrer">
            {{ 'app.footer.links.flexdonotsell' | translate }}
          </a>
        </div>
      </div>
      <div class="copyright-left">
        {{ 'app.footer.flexappcopyright' | translate:{ year: thisYear } }}
      </div>
      <div class="copyright-mobile">
        {{ 'app.footer.flexappcopyright' | translate:{ year: thisYear } }}
      </div>
    </div>
  </div>
</div>