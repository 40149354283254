import { Component, OnInit, ViewChild, ElementRef, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { AccountService } from 'src/app/services/account.service';
import { IStep } from 'src/app/shared/components/stepper/models/step.interface';
// import {MatDialogRef } from '@angular/material/dialog';
import { PolicyService } from 'src/app/services/policy.service';
import { LoginService } from 'src/app/services/login.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserService } from 'src/app/services/user.service';
import { BillingService } from 'src/app/services/billing.service';

@Component({
  selector: 'upsc-email-change-confirmation',
  templateUrl: './email-change-confirmation.component.html',
  styleUrls: ['./email-change-confirmation.component.scss']
})
export class EmailChangeConfirmationComponent {
  userDetails;
  policyDetails: any;
  public confirmFormGroup: UntypedFormGroup;

  scriptError: boolean = false;
  systemError: boolean = false;
  loading: boolean = false;
  codeResent: boolean = false;  
  updateError: boolean = false;

  hasDigitalWallet: boolean = false;
  canEarnDWPoints: boolean = false;
  currentTemplate: any = 0;
  updateContactRequest;
  // verificationCodeEmail;

  verificationInterface = { emailId: "" };
  verificationError: boolean = false;
  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;
  verifyCodeInterface = { emailId: "", toVerify: "" };
  subscriptions: Subscription[] = [];

  isUsaUser;

  @ViewChild("name1") field1: ElementRef;
  @ViewChild("name2") field2: ElementRef;
  @ViewChild("name3") field3: ElementRef;
  @ViewChild("name4") field4: ElementRef;
  @ViewChild("name5") field5: ElementRef;
  @ViewChild("name6") field6: ElementRef;

  constructor(public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private policyService: PolicyService,
    private loginService: LoginService,
    private userService: UserService,
    private billingService: BillingService,
    private dialogRef: MatDialogRef<EmailChangeConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
  ) {
    this.policyDetails = this.loginService.getPolicyDetails();
    this.userDetails = this.userService.getUserInfo();
    this.isUsaUser = this.userService.isUsaUser();
    this.confirmFormGroup = this.formBuilder.group({
      firstDigit: ['', Validators.required],
      secondDigit: ['', Validators.required],
      thirdDigit: ['', Validators.required],
      fourthDigit: ['', Validators.required],
      fifthDigit: ['', Validators.required],
      sixthDigit: ['', Validators.required]
    });

    this.updateContactRequest = data?.updateContactRequest;
    this.canEarnDWPoints = data?.canEarnDWPoints;
  } //End of constructor

  ngOnInit() {

  }

  resendVerificationCode() {
    this.createVerificationInterface.data.emailAddress = this.updateContactRequest?.primaryNamedInsured?.emailAddress1;
    let subscription3 = this.accountService.createVerificationCode(this.createVerificationInterface).subscribe(
      data => {
        this.loading = false;
        if ((data.responseMessage).toLowerCase() === 'new verification code created' || (data.responseMessage).toLowerCase() === 'code not yet expired') {
          this.codeResent = true;
        } else {
          this.codeResent = false;
          // error = true here probably
        }
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.loading = false;
        } else {
          this.scriptError = false;
          this.loading = false;
        }
      }
    );
    this.subscriptions.push(subscription3);
  }

  //Check verification code that user enters with the code that was sent, then update their info afterwards
  checkVerificationCodeAndUpdateInfo() {
    let userInput = this.confirmFormGroup.get('firstDigit').value + this.confirmFormGroup.get('secondDigit').value + this.confirmFormGroup.get('thirdDigit').value + this.confirmFormGroup.get('fourthDigit').value + this.confirmFormGroup.get('fifthDigit').value + this.confirmFormGroup.get('sixthDigit').value;
    //Use the retrieveVerificationCode function here to compare userInput
    this.verifyCodeInterface.emailId = this.updateContactRequest?.primaryNamedInsured?.emailAddress1;
    this.verifyCodeInterface.toVerify = userInput;

    this.loading = true;
    let subscription6 = this.accountService.verifyCode(this.verifyCodeInterface).subscribe(
      data => {
        this.systemError = false;
        if ((data?.message).toLowerCase() === 'verification code matched') {
          this.verificationError = false;
          this.updateContact();
        } else {
          this.loading = false;
          this.verificationError = true;
        }
      }, error => {
        //get verification code error
        this.loading = false;
        this.systemError = false;
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.verificationError = false;
        } else {
          this.scriptError = false;
          this.verificationError = true;
        }
      }
    );
    this.subscriptions.push(subscription6);
  }

  close(): void {
    this.dialogRef.close();
  }

  updateContact() {
    this.scriptError = false;
    this.systemError = false;
    this.updateError = false;
    this.accountService.updateContact(this.updateContactRequest).subscribe(
      data => {
        if (data.status === "failed") {
          this.updateError = true;
          this.loading = false;
        } else {
          this.loading = false;
          if (this.canEarnDWPoints) {
            this.currentTemplate = 1;
            this.updateIndicators();
            localStorage.setItem('profilePopupStatus', JSON.stringify(1));
          } else {
            this.currentTemplate = 2;
            localStorage.setItem('profilePopupStatus', JSON.stringify(1));
          }
        }
      }, error => {
        this.loading = false;
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
        } else {
          this.updateError = true;
        }
      }
    );
  }

  goToWallet() {
    this.systemError = false;
    let request = {
      action: "accesswallet", //Hardcoded
      navigatingSystem: "onl", //Hardcoded
      navigatingSystemRole: "admin", //Hardcoded (assume admin)
      productSystem: "gw", //Hardcoded (not coming from cbp/dd)
      productSystemIdentifier1: this.policyDetails?.policyNumber,
      productSystemIdentifier1Name: "policyno",
      productSystemIdentifier2: this.policyDetails?.accountNumber,
      productSystemIdentifier2Name: "pcAccountNumber",
      productType: "iscomplete", //Hardcoded (unless we want to change later based on databricks policytype value)
      country: "us", //Harcoded (wallet is US only)
      locale: "en", //Hardcoded (wallet is US only)
      userIdentifier1Name: "createdbyuserid",
      userIdentifier1: this.userDetails?.userId?.toString(),
      displayName: this.userDetails?.contactName, //QUESTION: Use user's name or policy holder's name?
      userEmail: this.userDetails?.emailAddress, //QUESTION: Do we want to use user's info here or policy's email?
      objectUID: this.userDetails?.objectUID,
      callBackURL: "https://online.upscapital.com", //Hardcoded (we don't need this field, remove if possible)
      returnURL: "https://online.upscapital.com" //Hardcoded (we don't need this field, remove if possible)
    };
    this.billingService.DWstartSession(request).subscribe(
      data => {
        if (data?.data?.url) {
          window.open(data?.data?.url, '_blank');
        } else {
          this.systemError = true;
        }
      }, error => {
        this.systemError = true;
      }
    );
  }

  updateIndicators() {
    // Now that they've earned points for profile validation flow, update their indicator so they don't see the earned points message in the future
    let request = {
      policy_Number: this.policyDetails?.policyNumber,
      dW_Banner: true,
      dW_Profile: true
    }
    this.billingService.DWpostIndicators(request).subscribe(); //We don't really care too much if this API succeeded or failed
  }

  //Handles paste event for verification code
  pasted() {
    if (this.field1.nativeElement.value.length > 1) {
      let digits = this.splitText();
      this.focusThis(digits)
    }
  }

  //Determines where the next focus should be for verification code
  focusThis(nextIndex: number, event?) {
    if (nextIndex == 2) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.field1.nativeElement.focus();
      }
      else {
        this.field2.nativeElement.focus();
      }
    } else if (nextIndex == 3) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('secondDigit').setValue(' ');
        this.field1.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('secondDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('secondDigit').setValue(event.key);
        }
        this.field3.nativeElement.focus();
      }

    } else if (nextIndex == 4) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('thirdDigit').setValue(' ');
        this.field2.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('thirdDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('thirdDigit').setValue(event.key);
        }
        this.field4.nativeElement.focus();
      }
    } else if (nextIndex == 5) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('fourthDigit').setValue(' ');
        this.field3.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fourthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fourthDigit').setValue(event.key);
        }
        this.field5.nativeElement.focus();
      }
    } else if (nextIndex == 6) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('fifthDigit').setValue(' ');
        this.field4.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fifthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fifthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
    else if (nextIndex >= 7) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('sixthDigit').setValue(' ');
        this.field5.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('sixthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('sixthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
  }

  //Splits up verification code if user tries to paste
  splitText() {
    var str = this.field1.nativeElement.value;
    var splitted = str.split("");
    this.confirmFormGroup.setValue({
      firstDigit: splitted[0],
      secondDigit: splitted[1] || '',
      thirdDigit: splitted[2] || '',
      fourthDigit: splitted[3] || '',
      fifthDigit: splitted[4] || '',
      sixthDigit: splitted[5] || ''
    })
    return splitted.length;
  }
}
