import { IShippingDetails } from "./shippingDetails.interface";

//Main claim interface
export interface IClaim {
  trackingNumber_Ext: string;
  transportationCarrierId_Ext: string;
  lossDate: Date | string;
  lossType: string;
  lossCause: string;
  lossDescription: string;
  shippingDetailsDTO: IShippingDetails;
  publicID: string;
  claimNumber: string;
  productCategory_Ext: string;
  upsmerchandise_Ext: string;
  claimAmount_Ext: string;
  reshipTrackingNumber: string;
  creditMemoRefundNo: string;
  shipmentStatus: string;
  additionalNotes: string;
  payeeId: string;
  userRole: string;
  policy: {
    policyNumber: string;
    effectiveDate: Date | string;
    expirationDate: Date | string;
    insured: string;
    policyType: string;
  }
  mainContact: {
    firstName: string;
    lastName: string;
    workNumber: string;
    emailAddress1: string;
  }
  totalPayments: {
    currency: string;
    amount: string;
  },
  specialityProgram?: string,
  purposeOfShipment?: string,
  customerNotes?: string,
  retailShipping?: string,
  retailPackaging?: string,
  tupssDeclaredValue?: string,
  tupssDiscounts?: string,
  deliveryDate?: string,
  scheduledDeliveryDate?: string,
  packageStatus?: string,
  shipmentType?: string,
  shipmentModeType?: string,
  shipDate?: string,
  serviceID?: string,
  multiPieceSamePackaging?: string,
  transId?: string,
  FMCPolicy?: string,
  totalPkgs?: string,
  movement?: string,
  carrierID?: string,
  shipmentID?: string,
  expectedDelivery?: string,
  chargeShipNet_Total?: string,
  chargeShipRetail_Total?: string,
  chargeTransNet_Total?: string,
  chargeTransRetail_Total?: string,
  chargeDVNet_Total?: string,
  chargeDVRetail_Total?: string,
  chargeCurrency?: string,
  dimUOM?: string,
  actualWeight_Total?: string,
  billedWeight_Total?: string,
  billingType?: string,
  storeNumber?: string,
  city?: string,
  stateProv?: string,
  postal?: string,
  country?: string,
  telephone?: string,
  email?: string,
  siteAccount?: string,
  packageNumber?: string,
  trackNo?: string,
  coverageType?: string,
  packedBy?: string,
  dimensions?: string,
  packagingNumber?: string,
  packagingName?: string,
  packagingDiscount?: string,
  totalCharge?: string,
  packingServiceNumber?: string,
  packingServiceName?: string,
  packingServiceDiscount?: string,
  packingServiceTotalCharge?: string,
  howReported?: string,
  weightUOM?: string,
  reportedBy?: {
    name?: string,
    contactName?: string,
    phoneNumber?: string,
    email?: string
  },
  originScan?: string,
  deliveryScanDate?: string,
  claimSource?: string,
  quantity?: string,
  billingAccount?: string,
  description?: string,
  groupID?: number,
  shipperNumber?: string,
  shipperName?: string
}

//Empty model of IClaim
export const emptyClaim = {
  trackingNumber_Ext: "",
  transportationCarrierId_Ext: "",
  lossDate: "",
  lossType: "",
  lossCause: "",
  lossDescription: "",
  shippingDetailsDTO: {
    senderDetails: {
      companyName: "",
      city: "",
      state: "",
      country: "",
      zipCode: ""
    },
    receiverDetails: {
      companyName: "",
      city: "",
      state: "",
      country: "",
      zipCode: ""
    },
    trackingNumber: "",
    weight: "",
    insuredAmount: "",
    serviceLevel: "",
    invoiceNumber: "",
    referenceNumber: "",
    merchandiseDescription: "",
    insuredValueCurrency: "",
    shipDate: "",
    merchandiseValue: "",
    verifiedShippingCharge: "",
    shipperNumber: "",
    shipperCompanyName: "",
    consigneeAttentionName: "",
    shipperAddressCode: "",
    shipperAddressLine: "",
    shipperCity: "",
    shipperStateProvinceCode: "",
    shipperPostalCode: "",
    shipperCountryCode: "",
    consigneeAddressCode: "",
    consigneeAddressLine: "",
    consigneeCity: "",
    consigneeStateProvinceCode: "",
    consigneePostalCode: "",
    consigneeCountryCode: "",
    serviceCode: "",
    serviceDescription: "",
    referenceFieldsValue: "",
    shipmentStatusDescription: "",
    deliveryDetailsCode: "",
    deliveryDetailsDescription: "",
    deliveryDetailsDate: "",
    deliveryDetailsTime: "",
    declaredValueCurrencyCode: "",
    declaredValueMonetaryValue: "",
    currentStatusDescription: "",
    serviceOptionsDescription: "",
    modeOfTransportationCode: "",
    modeOfTransportationDescription: "",
    shipmentIdentificationNumber: ""
  },
  publicID: "",
  claimNumber: "",
  productCategory_Ext: "",
  upsmerchandise_Ext: "",
  claimAmount_Ext: "",
  reshipTrackingNumber: "",
  creditMemoRefundNo: "",
  shipmentStatus: "",
  additionalNotes: "",
  payeeId: "",
  userRole: "",
  policy: {
    policyNumber: "",
    effectiveDate: "",
    expirationDate: "",
    insured: "",
    policyType: ""
  },
  mainContact: {
    firstName: "",
    lastName: "",
    workNumber: "",
    emailAddress1: ""
  },
  totalPayments: {
    currency: "",
    amount: ""
  },
  claimSource: 'ONL - Online'
}


//Claim Detail
export interface IClaimsDetails {
  claimNumber?: string;
  tracking?: string;
  shipDate?: string;
  productCategory?: string;
  totalPaymentAmount?: string;
  currency?: string;
  reason?: string;
  reference?: string
  fName?: string;
  lName?: string;
  email?: string;
  phoneNumber?: string;
  shipperCompany?: string;
  shipperAddress1?: string;
  shipperAddress?: string;
  shipperZipcode?: string;
  shipperCity?: string;
  shipperState?: string;
  shipperCountry?: string;
  recipientCompany?: string;
  recipientAddress1?: string;
  recipientAddress?: string;
  recipientCity?: string;
  recipientState?: string;
  recipientCountry?: string;
  recipientZipcode?: string;
  carrier?: string;
  description?: string;
  fileDate?: string;
  adjusterName?: string;
  adjusterEmail?: string;
  adjusterPhone?: string;
  //status?: string;
  customerDisplayStatus?: string;
  customerDisplayStatusTitle?: string;
  claimState?: string;
  closedOutCome?: string;
  claimSubStatus?: string;
  claimedAmount?: string;
  documents?: [] | string | {};
  idrdocList?: [] | string;
  customerNotes?: string;
  checkNumber?: string;
  checkIssueDate?: string;
  filedClaimAmount?: string;
  upsAccountNumber?: string;
  ach?: string;
  payeeID?: string,
  quantity?: string,
  payeeDetails?: {
    fullName?: string,
    email?: string,
    phone?: string,
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    state?: string,
    zip?: string,
    country?: string,
    stateKey?: string,
    province?: string,
    walletIndicator?: string
  }
}

//Key-Value pairs
export interface ICategory {
  key?: string;
  value?: string;
}

export interface listOfMessages {
  author?: string;
  body?: string;
  claimId?: string;
  claimNumber?: string;
  isAdjuster?: boolean;
  isAutoMessage?: boolean;
  isMessageRead?: boolean;
  isMessageSoftDeleted?: boolean;
  messageId?: string;
  time?: string;
  topic?: string;
  authorInitial?: string;
}

//used in rootModel
export interface listOfClaims {
  claimNumber?: string;
  fileDate?: string;
  savedTime?: string;
  lossCause?: string;
  checkNumber?: string;
  checkIssueDate?: string;
  claimAmount_Ext?: string;
  upsAccount?: string;
  submissionNumber?: string;
  shipperName?: string;
  shipperNumber?: string;
  shippingDetails?: {
      senderDetails?: {
          companyName?: string;
          city?: string;
          state?: string;
          country?: string;
      }
      receiverDetails?: {
          companyName?: string;
          city?: string;
          state?: string;
          country?: string;
      }
      trackingNumber?: string;
      referenceNumber?: string;
      shipDate?: string;
      merchandiseDescription: string;
  },
  adjuster?: {
      displayName?: string;
      email?: string;
      phoneNumner?: string;
  },
  reporter?: {
      displayName?: string;
      emailAddress1?: string;
      workNumber?: string;
  },
  claimStatus?: {
      claimState?: string;
      claimSubStatus?: string;
      closedOutCome?: string;
  },
  idrdocReqStatus?: string;
  totalPayments?: {
      currency?: string;
      amount?: string;
  },
  claimSource?: string;
  customerDisplayStatus?: string;
  declaredValue?: string;
  lossDate?: string;
  payeeName?: string;
  policyNumber?: string;
}

export interface ClaimSummary {
  claimNumber: string;
  fileDate: string;
  claimStatus: string;
  amount: string;
  tracking: string;
  reference: string;
  receiver: string;
  currency: string;
}

export interface ClaimSummaryFormatted {
  claimNumber: string;
  fileDate: Date;
  claimStatus: string;
  amount: string;
  tracking: string;
  reference: string;
  receiver: string;
  currency: string;
}

//This is how the claim summary is given to us
export interface rootModel {
  maxNumberOfResults: number;
  items: listOfClaims[];
}

export interface ClaimIdsModel {
  maxNumberOfResults: number;
  claimIds: string[];
}

export interface ClaimListModel {
  maxNumberOfResults: number;
  items: listOfClaims[];
}

export interface MessageListModel {
  totalCount: number;
  messages: listOfMessages[];
}

export interface ISummary {
  dateRange?: any;
  claimStates?: string[];
  policyType?: string;
  policyNumber?: string;
  claimSubStatus?: string[];
  claimClosedOutCome?: string[];
  objectUID?: any;
  orderParams?: {
      orderByDescending?: string;
      offsetStart?: string;
      offsetEnd?: string;
  }
  groupID?: number;
  shipperNumber?: string;
  shipperName?: string;
  childPolicyNumberList?: any;
}


export interface IMsgSummary {
  policyNumber?: string;
  message?: string;
  claimNumber?: string;
}


export interface MessageIdsModel {
  totalCount?: any;
  messageIDs?: string[];
  message?: string;
  code?: string;
}

export interface MessageClaimIdsModel {
  totalCount?: any;
  claimNumbers?: string[];
  claimStates?: string[];
}

export interface IMessageSummary {
  claimStates?: string[];
  policyType?: string;
  policyNumber?: string;
  objectUID?: any;
  orderParams?: {
      orderByDescending?: string;
      offsetStart?: string;
      offsetEnd?: string;
  };
  message?: string;
  childPolicyNumberList?: string[];
  groupID?: number;
}

//contact info on 3rd pag eof claim flow
export interface IContact {
  firstName?: string;
  lastName?: string;
  workNumber?: string;
  emailAddress1?: string;
}

//Documents inside claim flow
export interface IDocument {
  sessionID?: string;
  name?: string;
  claimNumber?: string;
  mimeType?: string;
  docUID?: string;
  documentType?: string;
  securityType?: string;
  status?: string;
  postFnolDoc?: string;
}

//When displaying documents
export interface IDocumentDisplay {
  file?: File;
  publicID?: string;
}
//layout Detail
export interface ILayoutDetails {
  userId?: string;
  email?: string;
  layoutId?: string;
  layoutName?: string;
  columnSequences?: columnSequences[];
}
export interface GetUserLayout {
  email?: string;
  userId?: number;
}
export interface GetUserLayoutDetails {
  email?: string;
  userId?: number;
  layoutId?: number;
}
export interface removeUserLayout {
  userId?: number;
  layoutId?: number;
}
export interface columnSequences {
  columnId?: number,
  sequenceNumber?: number
};
export interface layoutdata {
  Id?: number,
  name?: string,
  checked?: boolean,
  disabled?: boolean
};

export interface RedirectDetails {
  action?: string,
  paymentDirection?: string,
  country?: string,
  paymentMode?: string,
  policyIdentifier?: string,
  email?: string,
  callBackURL?: string,
  scenario?: string,
  createdByUserId?: string,
  guestUserIndicator?: string,
  locale?: string,
  tupssFlow?: string,
  iShipData?: {
    companyName?: string,
    email?: string,
    phone?: string,
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    state?: string,
    zipCode?: string
  }
}
export interface ClaimStats {
  claimsCnt: number;
  claimsInProgCnt: number;
  claimsWithNewMessagesCnt: number;
  claimsWithPendingDocCnt: number;
  draftClaimsCnt: number;
  closedClaimsCnt: number;
  unpaidClaimsCnt: number;
  newMessagesCnt: number;
  paidClaimsCnt: number;
}
