import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { IPolicies } from '../../../shared/models/policies.interface';

@Component({
  selector: 'upsc-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  public policy: IPolicies;
  constructor(private loginService: LoginService) { }

  ngOnInit() {
    this.policy = this.loginService.getPolicyDetails();    
    this.policy.addressLine1_Ext=this.policy.addressLine1_Ext.replace(/[,]/g, "");
  }
}
