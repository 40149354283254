import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpClientService {
  constructor(private httpClient: HttpClient) {
  }

  public get<T>(url): Observable<T> {
    return this.httpClient.get<T>(url);
  }
}
