
<div class="wrapper">  
  <div class="user-title">{{ 'userManagement.buttons.manage' | translate }}</div>
  <div class="isop-navigation d-flex align-items-center justify-content-between">
    <ul class="navigation-items">
      <li class="navigation-item"> 
        <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/manage-users">
          <span *ngIf="isParentPolicy">{{ 'userManagement.buttons.masterPolicy' | translate }}</span>
          <span *ngIf="!isParentPolicy">{{ policyInfo?.insured }}</span>
        </a>
      </li>
      <li class="navigation-item" *ngIf="isParentPolicy && isEAUser">
        <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/manage-users/sub-policies">
          <span>{{ 'userManagement.buttons.subPolicy' | translate }}</span>
        </a>
      </li>
      <li class="navigation-item" *ngIf="isEAUser">
        <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/manage-users/shipper-accounts">
         <span>Shipper Accounts</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<router-outlet></router-outlet>