import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class LogoutResolver  {
  constructor(private auth: AuthService) {
  }

  public resolve(): Observable<any> {
    //MarkTodo
    return;
  }
}
