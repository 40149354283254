<div class="my-choice-wrapper">
  <form [formGroup]="customerForm">
    <div class="gradient-box">
      <div class="title" *ngIf="!isMobile">{{'myChoice.howToFileAClaim.title' | translate }}</div>
      <div class="title" *ngIf="isMobile">{{'myChoice.howToFileAClaim.mobileTitle' | translate }}</div>
      <div class="green-bar"></div>
      <div class="text" *ngIf="!isMobile">{{'myChoice.howToFileAClaim.desc' | translate }}</div>
      <div class="text" *ngIf="isMobile">{{'myChoice.howToFileAClaim.mobileDesc' | translate }}</div>
    </div>
    <div class="white-box">
      <div class="stepper-box" *ngIf="!isMobile">
        <div><img class="custom-width" src="../../../assets/images/static-stepper.PNG"></div>
      </div>
      <div class="stepper-box" *ngIf="isMobile">
        <div><img class="custom-width2" src="../../../assets/images/static-stepper-vertical.PNG"></div>
      </div>
      <div class="form-box">
        <div class="title" *ngIf="!isMobile">{{'myChoice.howToFileAClaim.start' | translate }}</div>
        <div class="green-bar" *ngIf="!isMobile"></div>
        <div class="text-bold">{{'myChoice.howToFileAClaim.checklistTitle' | translate }}</div>
        <div class="text mobile-center">{{'myChoice.howToFileAClaim.checklistDesc' | translate }}</div>
        <div class="d-flex flex-row checklist-format">
          <mat-checkbox class="green-background-checkbox size-20" formControlName="checklist1"></mat-checkbox>
          <div class="ml-2 text">{{'myChoice.howToFileAClaim.checklist1' | translate }}</div>
        </div>
        <div class="d-flex flex-row checklist-format">
          <mat-checkbox class="green-background-checkbox size-20" formControlName="checklist2"></mat-checkbox>
          <div class="ml-2 text">{{'myChoice.howToFileAClaim.checklist2' | translate }}</div>
        </div>
        <div class="d-flex flex-row checklist-format">
          <mat-checkbox class="green-background-checkbox size-20" formControlName="checklist3"></mat-checkbox>
          <div class="ml-2 text">
            {{'myChoice.howToFileAClaim.checklist3a' | translate }}
            <a href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank" rel="noopener noreferrer"class="upsc-link">{{'myChoice.howToFileAClaim.checklist3b' | translate }}</a>
            {{'myChoice.howToFileAClaim.checklist3c' | translate }}
            <a href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank" rel="noopener noreferrer" class="upsc-link">{{'myChoice.howToFileAClaim.checklist3d' | translate }}</a>
            {{'myChoice.howToFileAClaim.checklist3e' | translate }}</div>
        </div>
        <div class="login-form d-flex pt-3">
          <div class="form-inputs">
            <mat-form-field class="width-400 readonly">
              <mat-label>{{'myChoice.howToFileAClaim.email' | translate }}</mat-label>
              <input readonly matInput formControlName="email">
              <mat-error *ngIf="customerForm.controls['email'].errors">
                <div *ngIf="customerForm.get('email').errors?.required">
                  {{'myChoice.howToFileAClaim.emailError1' | translate }}
                </div>
                <div *ngIf="customerForm.get('email').errors?.pattern">
                  {{'myChoice.howToFileAClaim.emailError2' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-inputs">
            <mat-form-field class="width-400 readonly">
              <mat-label>{{'myChoice.howToFileAClaim.tracking' | translate }}</mat-label>
              <input readonly matInput formControlName="trackingNumber">
              <mat-error *ngIf="customerForm.controls['trackingNumber'].errors">
                <div *ngIf="customerForm.get('trackingNumber').errors?.required">
                  {{'myChoice.howToFileAClaim.trackingError' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-inputs">
          <mat-form-field class="width-400 readonly">
            <mat-label>{{'myChoice.howToFileAClaim.confirmation' | translate }}</mat-label>
            <input readonly matInput formControlName="confirmationNumber">
            <mat-error *ngIf="customerForm.controls['confirmationNumber'].errors">
              <div *ngIf="customerForm.get('confirmationNumber').errors?.required">
                {{'myChoice.howToFileAClaim.confirmationError' | translate }}
              </div>
            </mat-error>
          </mat-form-field> 
          </div>
          <div class="form-inputs">
            <mat-form-field class="width-400">
              <mat-label>{{'myChoice.howToFileAClaim.zip' | translate }}</mat-label>
              <input matInput formControlName="zipCode" maxLength="5">
              <mat-error *ngIf="customerForm.controls['zipCode'].errors">
                <div *ngIf="customerForm.get('zipCode').errors?.required">
                  {{'myChoice.howToFileAClaim.zipError' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <br>
          <div class="captcha-format"><re-captcha formControlName="recaptchaControl"></re-captcha></div>
          <div class="buttons">
            <button class="upsc-button" [disabled]="customerForm.invalid" (click)="getStarted()" *ngIf="!loading">
              {{'myChoice.howToFileAClaim.getStarted' | translate }}
              <mat-icon class="mobile-format">keyboard_arrow_right</mat-icon>
            </button>
            <button class="next-step loading d-flex align-items-center justify-content-between" *ngIf="loading">
              <div>{{'myChoice.howToFileAClaim.getStarted' | translate }}</div>
              <div class="spinner-border" role="status">
                <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
              </div>
            </button>
          </div>
          <div class="error mt-2" *ngIf="validationError">
            {{'myChoice.howToFileAClaim.zipValid' | translate }}
          </div>
          <div class="error mt-2" *ngIf="systemError">
            {{'common.systemError' | translate }}
          </div>
          <div class="error mt-2" *ngIf="duplicateTrackingNumberError">
            {{'myChoice.howToFileAClaim.duplicateTN' | translate }}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
  