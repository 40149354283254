<div class="Rectangle d-flex flex-column mx-auto p-5">
  <div class="main">
    <div mat-dialog-title>
      <mat-icon (click)='cancel()'>close</mat-icon>
      <h1>{{ 'app.footer.disclosures.title' | translate }}</h1>
    </div>

    <mat-dialog-content>
      <h2>{{ 'app.footer.disclosures.h2-1' | translate }}</h2>
      <p>{{ 'app.footer.disclosures.p1' | translate }}</p>
      <h2>{{ 'app.footer.disclosures.h2-2' | translate }}</h2>
      <p>{{ 'app.footer.disclosures.p2' | translate }}</p>
      <h2>{{ 'app.footer.disclosures.h2-3' | translate }}</h2>
      <p>{{ 'app.footer.disclosures.p3' | translate }}</p>
      <h2>{{ 'app.footer.disclosures.h2-4' | translate }}</h2>
      <p>{{ 'app.footer.disclosures.p4' | translate }}</p>
      <h2>{{ 'app.footer.disclosures.h2-5' | translate }}</h2>
      <p>{{ 'app.footer.disclosures.p5' | translate }}</p>
      <h2>{{ 'app.footer.disclosures.h2-6' | translate }}</h2>
      <p>{{ 'app.footer.disclosures.p6' | translate }}</p>
      <h2>{{ 'app.footer.disclosures.h2-7' | translate }}</h2>
      <p>{{ 'app.footer.disclosures.p7' | translate }}</p>
      <h2>{{ 'app.footer.disclosures.h2-8' | translate }}</h2>
      <p>{{ 'app.footer.disclosures.p8' | translate }}</p>
    </mat-dialog-content>
  </div>
</div>