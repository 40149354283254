<!-- Enter verification code screen -->
<div class="p-3 p-md-4" *ngIf="currentTemplate == 0">
  <div *ngIf="scriptError" class="response-error">Invalid Input</div>
  <form [formGroup]="confirmFormGroup" class="confirmFormGroup">
    <div class="verification-code-title">{{ 'createAccount.confirm.emailTitle' | translate }}</div>
    <div class="verification-code-title-description">
      {{ 'createAccount.confirm.emailDescription' | translate }}
    </div>
    <div class="container-wrapper">
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" autofocus class="center-text" #name1 (keyup)="focusThis(2, $event)"
            (input)="pasted()" formControlName="firstDigit" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #name2 (keyup)="focusThis(3, $event)"
          (input)="pasted()" formControlName="secondDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #name3 (keyup)="focusThis(4, $event)"
          (input)="pasted()" formControlName="thirdDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #name4 (keyup)="focusThis(5, $event)"
          (input)="pasted()" formControlName="fourthDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #name5 (keyup)="focusThis(6, $event)"
          (input)="pasted()" formControlName="fifthDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #name6 (keyup)="focusThis(7, $event)"
          (input)="pasted()" formControlName="sixthDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
    </div>
    <div class="verification-error" *ngIf="verificationError">
      {{ 'createAccount.confirm.form.errorReturned' | translate}}
    </div>
    <div class="update-info-error" *ngIf="systemError">
      {{ 'common.systemError' | translate }}
    </div>
    <div class="update-info-error" *ngIf="updateError">
      Something went wrong when attempting to update your policy. Please try again later.
    </div>
    <div class="resend-code flex-column mb-1">
      <a (click)="resendVerificationCode()">Resend Code</a>
      <div *ngIf="codeResent">Code sent.</div>
    </div>
    <div class="btn-containers">
      <button class="upsc-white-button" (click)="close()">
        <mat-icon>chevron_left</mat-icon> {{ 'createAccount.confirm.form.cancel' | translate }}
      </button>
      <button *ngIf="!loading" (click)="checkVerificationCodeAndUpdateInfo()" class="upsc-button" type="button" [disabled]="!confirmFormGroup.valid">
        {{ 'createAccount.confirm.form.verifyEmail' | translate }}<mat-icon>chevron_right</mat-icon>
      </button>
      <button *ngIf="loading" class="next-step-isop loading d-flex align-items-center justify-content-between">
        {{ 'createAccount.confirm.form.verifyEmail' | translate }}
        <div class="spinner-border" role="status">
          <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
        </div>
      </button>
    </div>
  </form>
</div>

<!-- Special success message (user earned 1,000 DW Points)  -->
<div class="popup-global-layout width-200 p-3 p-md-4" *ngIf="currentTemplate == 1">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">{{ 'createAccount.confirm.dwPointstitle' | translate }}</div>
  <div class="global-green-bar"></div>
  <div class="global-text">{{ 'createAccount.confirm.dwPointsdescription1' | translate }}<span class="green">1,000</span>{{ 'createAccount.confirm.dwPointsdescription2' | translate }}</div>
  <div class="global-button-format">
    <button type="button" class="upsc-green-popup-button" (click)="close()">
      {{ 'createAccount.confirm.form.okay' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon>
    </button>
    <button type="button" class="upsc-white-button2" (click)="goToWallet()">
      {{ 'createAccount.confirm.form.gotoUPSWallet' | translate }}<mat-icon class="ml-2">launch</mat-icon>
    </button>
  </div>
</div>

<!-- Regular success message (no mention of points) -->
<div class="popup-global-layout width-200 p-3 p-md-4" *ngIf="currentTemplate == 2">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">{{ 'createAccount.confirm.dwUpdateSuccessNote' | translate }}</div>
  <div class="global-green-bar"></div>
  <div class="global-text">{{ 'createAccount.confirm.dwUpdateDescription' | translate }}</div>
  <div class="global-button-format">
    <button type="button" class="upsc-green-popup-button" (click)="close()">
      Thank You<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>