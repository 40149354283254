<div class="collapsible-panel" [class.collapsed]="isCollapsed">
    <div class="panel-header"
         (click)="isCollapsed = !isCollapsed">
      <span class="margin">{{ title }}</span>
      <div class="toggle">
        <mat-icon *ngIf="isCollapsed">add</mat-icon>
        <mat-icon *ngIf="!isCollapsed">remove</mat-icon>
      </div>
    </div>
    <div class="panel-content">
      <ng-content></ng-content>
    </div>
  </div>
  