<div class="wrapper">
  <div (click)="isCollapsed = !isCollapsed;">
    <div class="header d-flex align-items-center justify-content-between" (click)="collapsedUncollapsed()">
      <div class="header-content" (click)="collapsedUncollapsed()">
        <div>{{ title }}</div>
        <div>{{ subtitle }}</div>
      </div>
      <div class="header-suffix" (click)="collapsedUncollapsed()">
        <mat-icon *ngIf="isCollapsed" (click)="collapsed()">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="!isCollapsed" (click)="unCollapsed()">keyboard_arrow_up</mat-icon>
      </div>
    </div>
  </div>
  <div class="content" *ngIf="!isCollapsed">
    <ng-content></ng-content>
  </div>
</div>