import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { IPolicies } from '../../shared/models/policies.interface';
import { LoginService } from '../../services/login.service';
import { UserService } from './../../services/user.service';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ClaimService } from 'src/app/services/claim.service';
import { ClaimStats } from 'src/app/shared/models/claim.interface';
import { BreakpointObserver } from '@angular/cdk/layout';
import { environment as ENV } from 'src/environments/environment';
import { TaggingService } from 'src/app/tags/tagging.service';
import { UserPermission } from 'src/app/shared/models/user-permission';
import { PolicyService } from 'src/app/services/policy.service';
import { BillingService } from 'src/app/services/billing.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import { CheckFeePopupComponent } from 'src/app/shared/components/check-fee-popup/check-fee-popup.component';
import { DigitalWalletTcsPopupComponent } from 'src/app/shared/components/digital-wallet-tcs-popup/digital-wallet-tcs-popup.component';

@Component({
  selector: 'upsc-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss'],
})
export class ClaimsComponent implements OnInit {
  @HostBinding('class') public hostClass = 'claims';

  public formGroup: UntypedFormGroup;
  public policy: IPolicies;

  allClaims: boolean = false;
  isGuestUser: boolean;
  isCanadaUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isDDClaimsFlow: boolean;
  showClaimbtn: boolean;
  hasHVCM: boolean;
  claimStats: ClaimStats;
  showStats: boolean;
  userDetails;
  policyDetails;
  dwDetails;
  systemError: boolean = false;
  showManagePayee: boolean = true;
  redirectToGetStarted: boolean;
  currentURL: any;
  public userPermission: UserPermission = new UserPermission();

  public policyType: string;
  public policyNo: string;
  isInsureShield: boolean = false;
  displayStartClaimButton: boolean = false;
  showHeader: boolean = true;
  canViewDPATPayees: boolean = true;

  isFileClaim: boolean = false;

  constructor(private route: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private userService: UserService,
    private claimService: ClaimService,
    private translateService: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private taggingService: TaggingService,
    private policyService: PolicyService,
    private billingService: BillingService,
    private dialog: MatDialog
  ) {

    this.policy = this.loginService.getPolicyDetails();
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.formGroup = this.formBuilder.group({
      sampleInput: ['', Validators.compose([Validators.required])],
    });

    this.isCanadaUser =  this.userService.isCanadaUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.userPermission = this.userService.getUserPermissions();
    this.policyDetails = this.loginService.getPolicyDetails();
    this.dwDetails = this.loginService.getDWDetails();
    //Controls the disclaimer message
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(
      (e: NavigationEnd) => {
        this.allClaims = (e.url == "/claims" || e.url == "/claims/draft-list") ? true : false;
        this.currentURL = e.url;
        if(e.url == "/claims" || e.url.startsWith("/claims/detail")){
          if(this.isCanadaUser){
            this.showClaimbtn = true;
          }
          else{this.showClaimbtn = false;}

        }
        else{
          this.showClaimbtn = false;
          sessionStorage.removeItem('pageId');
          sessionStorage.removeItem('isadvanced');
        }

        if (e.url == "/claims/message-list" || e.url == "/claims/help") {
          this.showManagePayee = false;
        } else if (e.url.includes("/claims/new")) {
          this.showManagePayee = true;
          this.redirectToGetStarted = true;
        } else {
          this.showManagePayee = true;
          this.redirectToGetStarted = false;
        }

        if (e.url.includes("/claims/new")) {
          this.displayStartClaimButton = true;
        } else {
          this.displayStartClaimButton = false;
        }
      }
    );

    // If the user has a digital wallet and has accepted terms and conditions, hide the 'manage claim payment recipients' option from them
    if (this.dwDetails?.walletID && this.dwDetails?.tcAcceptance == '1') {
      this.canViewDPATPayees = false;
    } else {
      this.canViewDPATPayees = true;
    }

    this.isGuestUser = this.userService.isGuestUser();
    this.isDDClaimsFlow = this.userService.isDDClaimsFlow();
    this.hasHVCM = this.userService.hasHVCM();
    this.isFileClaim = this.userPermission.isFileClaim;
  }

  public ngOnInit() {
    this.taggingService.view();
    this.titleService.setTitle(this.translateService.instant('app.Settitle.ups_capital_Activity'));
    if(!this.isDDClaimsFlow) {
      this.claimService.getClaimStats(this.loginService.getPolicyDetails().policyNumber).subscribe(
        data => {
          this.claimStats = data;
          this.showStats = this.claimStats.newMessagesCnt > 0;
        }
      )
    }
    this.systemError = false;

    this.policy = this.loginService.getPolicyDetails();
    this.policyNo = this.policy.policyNumber;
    this.policyType = this.policyService.determinePolicyType(this.policy);
    if (this.policyType == 'transactional_parcel' || this.policyType == 'transactional_cargo' || this.policyType == 'traditional_cargo') {
      this.policyType = "InsureShield";
      this.isInsureShield = true;
    } else {
      this.policyType = `${this.policy.productName} ${this.policy.subPolicyType}`;
      this.isInsureShield = false;
    }

    this.route.queryParamMap.subscribe(
      params => {
        if (params.get('status') && (params.get('status') == 'success')) {
          if (this.router?.url?.includes('/claims/MultiClaim')) { // coming back from hvcm, since the claim file process is in new tab, we can show the header without issue.
            this.showHeader = true;
          } else {
            this.showHeader = false;
          }
        } 
        // This is to account for a scenario where the user refreshes the page, we need to ensure
        // that if they're still in the claims filing process, to NOT show the header still
        // If they do leave via back or cancel button and stay on /claims/new URL, there is logic
        // to add 'back' or 'cancel' to the route params, at which point this subscription will trigger and
        // we can update the header to what it should be.
        else if (sessionStorage.getItem('claimFileStatus') == 'wallet' && this.router?.url == '/claims/new') {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }
      }
    )

    if (window.location.href.includes('/claims/new')) {
      this.displayStartClaimButton = true;
    } else {
      this.displayStartClaimButton = false;
    }

    if (window.location.href.includes("/claims/message-list") || window.location.href.includes("/claims/help")) {
      this.showManagePayee = false;
    }
  }

  gotoMessages(event: any) {
    this.router.navigate(['claims', 'message-list']);
  }

  get isScreenMedium() {
    return this.breakpointObserver.isMatched('(max-width: 992px)');
  }

  public onSubmit(event, value) {
    event.preventDefault();
  }

  //If user is in the claims filing process and clicks on the header, we want to refresh the page
  //and bring them back to the get started page. but if they click on the header and are currently
  //on any other page not named /claims/new, we do not need to refresh the page.
  redirect() {
    if(window.location.href.includes('/claims/new')) {
      window.location.replace(`${ENV.baseUrl.web}claims/new`);
    }
  }

  fileAClaim() {
    this.systemError = false;
    let walletUser = this.userService.isWalletUser();
    let acceptedTCs = this.userService.isWalletTCsAccepted();
    // There are 5 possible flows
    if (walletUser && acceptedTCs) { // Flow #1: Wallet + TCs: No check fee pop-up, let them begin claim immediately with walletID as payeeId.
      this.startClaimWithWalletAsPayee();
    } else if (this.isUsaUser) { // Flow #2-5: Must verify latest wallet details first
      this.checkDWGetSummary();
    } else { // CA/EU will NEVER be charged a check fee for the time being.
      this.redirectToDPAT();
    }
  }

  checkDWGetSummary() {
    let request = {
      productSourceSystem: "gw",
      sourceSystemIdentifierName: "PolicyNumber",
      sourceSystemIdentifier: this.policyDetails?.policyNumber,
      country: "US",
      roleType: "string",
      productType: "string",
    };

    this.billingService.DWgetSummary(request).subscribe(
      data => {
        this.navigateToCorrectFlow();
      }, error => {
        // 7/20 Release
        // this.systemError = true;
        // 6/27 Release - Do not add error handling or prevent the user from continuing here, the API is failing too often
        this.navigateToCorrectFlow();
      }
    );
  }

  navigateToCorrectFlow() {
    let walletUser = this.userService.isWalletUser();
    let acceptedTCs = this.userService.isWalletTCsAccepted();
    let checkFeeAmount = this.userService.getCheckFeeAmount();
    if (walletUser && acceptedTCs) { // Flow #1: Wallet + TCs: No check fee pop-up, let them begin claim immediately with walletID as payeeId.
      this.startClaimWithWalletAsPayee();
    } else if (walletUser && !acceptedTCs) {
      // Flow #2: Wallet + no T&Cs + check fee: Display pop-up with two options: proceed to DPAT w/check and be charged a fee, or enroll in wallet for free ach options
      // Flow #3: Wallet + no T&Cs + no check fee: Display pop-up with two options: proceed to DPAT with only check payees showing (no mention of fee needed), or enroll in wallet for ach options
      this.openDWTCsPopup();
    } else if (!walletUser && checkFeeAmount > 0) {
      // Flow #4: No wallet + check fee: Display pop-up with one option: proceed to DPAT with both check and ach payees, acknowledging that if a check fee is chosen, a fee will be charged.
      this.openCheckFeePopup();
    } else {
      // Flow #5: No wallet + no check fee: No change to functionality, let them begin claim.
      this.redirectToDPAT();
    }
  }

  // Note: If check fee logic is not implemented by the 7/20 release, change any mentions of openCheckFeePopup() to redirectToDPAT()
  openCheckFeePopup() {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    // All logic to determine what content to display in the pop-up is located within the pop-up component ts file
    let dialogRef = this.dialog.open(CheckFeePopupComponent, config);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'continueToDPAT') {
          this.redirectToDPAT();
        } else if (data == 'error') {
          this.systemError = true;
        }
      }
    );
  }

  openDWTCsPopup() {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    // All logic to determine what content to display in the pop-up is located within the pop-up component ts file
    let dialogRef = this.dialog.open(DigitalWalletTcsPopupComponent, config);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'continueToDPAT') {
          this.redirectToDPAT();
        } else if (data == 'error') {
          this.systemError = true;
        }
      }
    );
  }

  // User starts claim. In claim edit, we will check this sessionStorage value to put them on the wallet flow
  startClaimWithWalletAsPayee() {
    sessionStorage.setItem('claimFileStatus', 'wallet');
    this.router.navigate(['/claims/new'])
      .then(() => {
        window.location.reload();
      });
  }

  redirectToDPAT() {
    sessionStorage.removeItem('isHvcm');
    sessionStorage.removeItem('policyNumber');
    sessionStorage.removeItem('hvcmTabOpened');
    let checkCountry = sessionStorage.getItem('locale').slice(3, 5);
    let paymentMode = sessionStorage.getItem('paymentMode'); // If paymentMode = 'check', DPAT will only show the user their check payees on the DPAT screens
    sessionStorage.removeItem('paymentMode');
    let request = {
      action: "Get Started",
      paymentDirection: "Claims",
      country: checkCountry ? checkCountry : "US",
      paymentMode: paymentMode ? "check" : "",
      policyIdentifier: this.policy.policyNumber ? this.policy.policyNumber : "",
      email: this.isGuestUser ? sessionStorage.getItem('guestUserEmail') : this.userDetails.emailAddress,
      callBackURL: `${ENV.baseUrl.web}claims/new`,
      scenario: "US",
      policyDetails: "insured",
      createdByUserId: this.userDetails.userId ? this.userDetails.userId : "",
      guestUserIndicator: this.isGuestUser ? "Y" : "N",
      locale: sessionStorage.getItem('locale'),
      tupssFlow: "",
      policyData: {
        insured: this.loginService.getPolicyDetails().insured
      },
      cudAccess: this.userPermission.isClaimPayee ? true : false
    }

    this.claimService.getRedirect(request).subscribe(
      data => {
        window.location.replace(data.data.url); //dpat
      }, error => {
        this.systemError = true;
      }
    );
  }

  checkBeforeRedirectToManagePayees() {
    // Before going to DPAT, let's check to see if they've accepted T&Cs already for the wallet (if so, they can't go to DPAT)
    let request = {
      productSourceSystem: "gw",
      sourceSystemIdentifierName: "PolicyNumber",
      sourceSystemIdentifier: this.policyDetails?.policyNumber,
      country: "US",
      roleType: "string",
      productType: "string",
    };

    this.billingService.DWgetSummary(request).subscribe(
      data => {
        let walletUser = this.userService.isWalletUser();
        let acceptedTCs = this.userService.isWalletTCsAccepted();
        if (walletUser && acceptedTCs) {
          // They are trying to access manage claim payment recipients but they've accepted T&Cs already, if we reload the page the option will vanish for them
          window.location.reload();
        } else {
          this.dpatManage();
        }
      }, error => {
        // 7/20 Release
        // this.systemError = true;
        // 6/27 Release
        let walletUser = this.userService.isWalletUser();
        let acceptedTCs = this.userService.isWalletTCsAccepted();
        if (walletUser && acceptedTCs) {
          // They are trying to access manage claim payment recipients but they've accepted T&Cs already, if we reload the page the option will vanish for them
          window.location.reload();
        } else {
          this.dpatManage();
        }
      }
    );
  }

  dpatManage() {
    let walletUser = this.userService.isWalletUser();
    let acceptedTCs = this.userService.isWalletTCsAccepted();
    let paymentMode = false;
    if (walletUser && !acceptedTCs) { // All wallet + no T&Cs users cannot view ach/zelle payees when they go to manage their payees
      paymentMode = true;
    }

    let checkCountry = sessionStorage.getItem('locale').slice(3,5);
    let request = {
        action: "Manage Payee",
        paymentDirection: "Claims",
        country: checkCountry ? checkCountry : "US",
        paymentMode: paymentMode ? "check" : "",
        policyIdentifier: this.policy.policyNumber ? this.policy.policyNumber : "",
        email: this.userDetails.emailAddress,
        callBackURL: (this.isGuestUser || this.redirectToGetStarted) ? `${ENV.baseUrl.web}claims/new` : `${ENV.baseUrl.web}claims`,
        scenario: "US",
        createdByUserId: this.userDetails.userId ? this.userDetails.userId : "",
        guestUserIndicator: "N",
        tupssFlow: "",
        locale: sessionStorage.getItem('locale'),
        policyData: {
          insured: this.loginService.getPolicyDetails().insured
        },
        cudAccess: this.userPermission.isClaimPayee ? true : false
    }
    this.claimService.getRedirect(request).subscribe(
      data => {
        window.location.replace(data.data.url);
      }, error => {
        this.systemError = true;
      }
    );
  }

  tagging(type) {
    if (type == 'all') {
      this.taggingService.link({ link_name: 'all_claims' });
    } else if (type == 'file') {
      this.taggingService.link({ link_name: 'file_a_claim' });
    } else if (type == 'payee') {
      this.taggingService.link({ link_name: 'payment_receipt details' });
    } else if (type == 'faq') {
      this.taggingService.link({ link_name: 'claim_faq' });
    }
  }

  // for sub-header horizontal scroll
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  @ViewChild('parent') slider: ElementRef;
  startDragging(e, flag) {
    this.mouseDown = true;
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
  }
  stopDragging(e, flag) {
    this.mouseDown = false;
  }
  moveEvent(e) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const scroll = x - this.startX;
    this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
  }

  // to resolve bug where when user goes to dpat manage claim payment recipient from /claims, when they come back, the all claims tab needs to remain
  get isClaimsActive() {
    if (window.location.href.includes("/claims?payeeID=null&status=back")) {
      return true;
    } else {
      return false;
    }
  }
}
