<div class="d-flex flex-column flex-md-row align-items-md-center">
  <div ngbDropdown (openChange)="checkDropDown($event)" class="d-flex flex-column flex-md-row m-2">
    <button ngbDropdownToggle [ngClass]="applied == true ? 'upsc-rectangle-button' : 'upsc-white-rectangle'">
      <span class="selectedValues"> {{ 'claims.list.claimsSearch.customizabledata' | translate
        }}</span>
    </button>

    <div ngbDropdownMenu class="drop-wrap" x-placement="top-left">
      <form [formGroup]="layoutForm">
        <div class="claimLists d-flex flex-column">
          <mat-list>
            <div>
              <mat-list-item (click)="addData()">
                <h6 matListItemTitle>{{ 'claims.list.claimsSearch.customizeLayout.newLayout.addNewLayout' | translate }}</h6>
                <p matListItemLine>
                  <mat-icon matListIcon>add</mat-icon>
                </p>
              </mat-list-item>
              <mat-divider></mat-divider>
            </div>
            <mat-list-item *ngFor="let layouts of layoutsname">
              <div class="layout-title width100" (click)="applyThisLayout(layouts)"> {{ layouts.item2 | translate}}</div>
              <p matListItemLine>
                <mat-icon matListIcon (click)="editData(layouts)">edit</mat-icon>
                <mat-icon matListIcon (click)="removeData(layouts)">delete</mat-icon>
              </p>
              <mat-divider></mat-divider>
            </mat-list-item>
            <div>
              <mat-list-item (click)="applyThisLayout(0)">
                <h6 matListItemTitle>{{ 'claims.list.claimsSearch.customizeLayout.newLayout.reset' | translate }}</h6>
                <p matListItemLine>
                  <mat-icon matListIcon>loop</mat-icon>
                </p>
              </mat-list-item>
            </div>
          </mat-list>
        </div>
      </form>
    </div>
  </div>
</div>