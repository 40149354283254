import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { emptyIEmail } from 'src/app/shared/models/email.interface';
import { TupssService } from '../../../services/tupss.service';

@Component({
  selector: 'upsc-detail-verification',
  templateUrl: './detail-verification.component.html',
  styleUrls: ['./detail-verification.component.scss']
})
export class DetailVerificationComponent implements OnInit {

  showSpinner = false;
  showEmailVerification = false;
  userDetails;
  systemError = false;
  iShipCheckShipmentData = null;

  constructor(
    private accountService: AccountService,
    private tupssService: TupssService
  ) { }

  ngOnInit(): void {
    sessionStorage.setItem('userDetails', JSON.stringify({ ...JSON.parse(sessionStorage.getItem('userDetails')), stage: 'post' }));
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.startVerificationCodeProcess();
  }

  startVerificationCodeProcess() {
    this.showSpinner = true;
    //First get iship data from trackNo. Data is used in email template.
    this.tupssService.getIShipData({ transactionSrc: "Capital_TFCP", trackNo: this.userDetails.trackingNumber }).subscribe(
      data => {
        this.iShipCheckShipmentData = data.data;
        this.createAndSendVerificationCode();
      }, error => {
        this.createAndSendVerificationCode();
      }
    );
  }

  createAndSendVerificationCode() {
    let request;
    if (this.iShipCheckShipmentData) {
      request = {
        ...emptyIEmail,
        emailFor: 'TCCPClaimSuccessVerificationCode',
        data: {
          emailAddress: this.userDetails.emailAddress,
          firstName: this.iShipCheckShipmentData.name ? this.iShipCheckShipmentData.name : this.iShipCheckShipmentData.companyName ? this.iShipCheckShipmentData.companyName : '',
          lastName: '',
          companyName: this.iShipCheckShipmentData.companyName ? this.iShipCheckShipmentData.companyName : '',
          city: this.iShipCheckShipmentData.city ? this.iShipCheckShipmentData.city : '',
          zip: this.iShipCheckShipmentData.zip ? this.iShipCheckShipmentData.zip : '',
          state: this.iShipCheckShipmentData.state ? this.iShipCheckShipmentData.state : '',
          streetAddress: this.iShipCheckShipmentData.address1 ? this.iShipCheckShipmentData.address1 : ''
        }
      }
    } else {
      request = {
        ...emptyIEmail,
        emailFor: 'TCCPClaimSuccessVerificationCode',
        data: {
          emailAddress: this.userDetails.emailAddress,
          firstName: '',
          lastName: '',
          companyName: '',
          city: '',
          zip: '',
          state: '',
          streetAddress: ''
        }
      }
    }

    this.accountService.createVerificationCode(request).subscribe(
      data => {
        this.showSpinner = false;
        if (data && data.responseMessage) {
          this.showEmailVerification = true;
        } else {
          this.showEmailVerification = false;
          this.systemError = true;
        }
      }, error => {
        this.showSpinner = false;
        this.showEmailVerification = false;
        this.systemError = true;
      }
    );
  }

}
