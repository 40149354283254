import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { PagerService } from 'src/app/services/pager.service';
import { ShipperAccountList } from './models/shipper-account-list';
import { PolicyService } from 'src/app/services/policy.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { RemoveUserConfirmationComponent } from 'src/app/pages/manage-users/components/remove-user-confirmation/remove-user-confirmation.component';

@Component({
  selector: 'upsc-accounts-shipper',
  templateUrl: './accounts-shipper.component.html',
  styleUrls: ['./accounts-shipper.component.scss']
})
export class AccountsShipperComponent {
  @Input() childPolicyFromParentAccount;

  //Display helpers
  pageLoading = true;
  systemError = false;

  //Data holders
  userDetails;
  userIDs;
  isParentPolicy;

  allUserIds: string[];
  pageSize: number = 5;
  pager: any = {};
  pageIds: string[] = [];
  pagedItems: any[] = [];

  shipperAccounts: ShipperAccountList[] = [];
  shipperAccountsList: ShipperAccountList[] = [];
  shipperAccount: ShipperAccountList;

  isDataPresent: boolean = false;
  isLoading: boolean = false;
  dialogRef: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private pagerService: PagerService,
    private policySerice: PolicyService,
    public dialog: MatDialog
  ) {
    this.userDetails = this.userService.getUserInfo();
    this.isParentPolicy = this.userService.isParentPolicy();
  }

  ngOnInit(): void {
    this.getShipperAccounts();
  }

  getShipperAccounts(childPolicyFromSearch?) {
    this.isLoading = true;
    this.systemError = false;
    let policyNumber;
    if (this.childPolicyFromParentAccount) {
      policyNumber = this.childPolicyFromParentAccount;
    } else if (childPolicyFromSearch) {   //Used when parent is switching between policies in their view
      policyNumber = childPolicyFromSearch;
      sessionStorage.setItem('level2policy', childPolicyFromSearch);  //Session storage variable is used for keeping track of what policy to actually add the shipper on, when adding from a parent policy view
    } else if (this.isParentPolicy && this.router.url == '/manage-users/shipper-accounts') {  //Only called on page load and search reset. Returns the page to default policy state.
      policyNumber = JSON.parse(sessionStorage.getItem('childPolicies'))?.length > 0 ? JSON.parse(sessionStorage.getItem('childPolicies'))[0] : '';
      sessionStorage.setItem('level2policy', policyNumber);  //Session storage variable is used for keeping track of what policy to actually add the shipper on, when adding from a parent policy view
    } else {
      var policyInfo = JSON.parse(sessionStorage.getItem('policyDetails'));
      policyNumber = policyInfo.policyNumber
    }

    if (policyNumber) {
      let payload = {
        childPolicyNumber: policyNumber,
      };
      this.policySerice.getPolicyShipperAccounts(payload).
        subscribe(data => {
          let srno = 1;
          let idsPage: string[] = [];
          data.data.forEach(element => {
            let shipperAccount = new ShipperAccountList();
            shipperAccount.srno = srno;
            shipperAccount.userId = element.userId;
            shipperAccount.name = element.name;
            shipperAccount.email = element.email;
            shipperAccount.shipperName = element.shipperName;
            shipperAccount.shipperNumber = element.shipperNumber;
            shipperAccount.role = element.role;
            shipperAccount.notification = element.notification == true ? 'Claims': 'None';
            shipperAccount.status = element.status == true ? 'Active' : 'Awaiting User Confirmation';
            shipperAccount.objectUID = element.objectUID !== undefined ? element.objectUID : null;
            this.shipperAccounts.push(shipperAccount);
            idsPage.push(srno.toString());
            srno = srno + 1;
          });
          this.setNewPage(1, idsPage);
          this.isLoading = false;
        }, error => {
          this.pagedItems = []; //Set back to default state if switching from a policy that does have items to one that does not
          this.isLoading = false;
        });
    }
  }

  setNewPage(page: number, idsPage: any) {
    this.pagedItems.splice(0, this.pagedItems.length);
    this.pager = this.pagerService.getPager(idsPage.length, page, this.pageSize);
    let pageIds = [];
    pageIds = idsPage.slice(this.pager.startIndex, this.pager.endIndex + 1);
    if (pageIds.length > 0) {
      pageIds.forEach(pageId => {
        var pageItem = this.shipperAccounts.find(x => x.srno == pageId);
        if (pageItem) {
          this.pagedItems.push(pageItem);
        }
      });
    }
    this.pageIds = idsPage;
  }

  setPager(event) {
    this.isLoading = true;
    var currentPage = Number(event.toString());
    this.setNewPage(currentPage, this.pageIds);
    this.isLoading = false;
  }

  loadAssetPerPage(event) {
    this.pageSize = Number(event.toString());
    this.setNewPage(1, this.pageIds);
  }

  setSearchPage(page: number, idsPage: any) {
    this.pagedItems.splice(0, this.pagedItems.length);
    this.pager = this.pagerService.getPager(idsPage.length, page, this.pageSize);
    let pageIds = [];
    pageIds = idsPage.slice(this.pager.startIndex, this.pager.endIndex + 1);
    if (pageIds.length > 0) {
      pageIds.forEach(pageId => {
        var pageItem = this.shipperAccountsList.find(x => x.srno == pageId);
        if (pageItem) {
          this.pagedItems.push(pageItem);
        }
      });
    }
    this.pageIds = idsPage;
  }

  simplePolicySelect(policy) {
    this.getShipperAccounts(policy);
  }

  notifySearch(event) {
    this.shipperAccountsList = [];
    let searchRecords = [];
    if (event.searchType === "email") {
      searchRecords = this.shipperAccounts.filter(it => {
        return it.email.toLocaleLowerCase().includes(event.searchValue);
      });
    } else {
      searchRecords = this.shipperAccounts.filter(it => {
        return it.name.toLocaleLowerCase().includes(event.searchValue);
      });
    }

    this.filterSearchRecords(searchRecords);
  }

  filterSearchRecords(searchRecords: any) {
    let idsPage: string[] = [];
    let srno = 1;
    if (searchRecords !== undefined && searchRecords.length > 0) {
      searchRecords.forEach(element => {
        let shipperAccount = new ShipperAccountList();
        shipperAccount.srno = srno;
        shipperAccount.userId = element.userId;
        shipperAccount.name = element.name;
        shipperAccount.email = element.email;
        shipperAccount.shipperName = element.shipperName;
        shipperAccount.shipperNumber = element.shipperNumber;
        shipperAccount.role = element.role;
        shipperAccount.notification = element.notification;
        shipperAccount.status = element.status;
        shipperAccount.objectUID = element.objectUID !== undefined ? element.objectUID : null;
        this.shipperAccountsList.push(shipperAccount);
        idsPage.push(srno.toString());
        srno = srno + 1;
      });
      this.setSearchPage(1, idsPage);
      this.isLoading = false;
    }
    else {
      this.pagedItems = [];
      this.setSearchPage(1, idsPage);
    }
  }

  notifyReset(event) {
    this.shipperAccounts.splice(0, this.shipperAccounts.length);
    this.getShipperAccounts();
  }

  editUser(user) {
    sessionStorage.setItem('edit-shipper', JSON.stringify(user));
    this.router.navigate(['/manage-users/add-shipper'], { queryParams: { userId: user.userId, policy: this.userDetails.policyNumber } });
  }

  deleteRegularUser(row: any) {
    let deleteUserRequest = {
      userID: row.userId,
      userEmailID: row.email,
      adminsEmail: this.userService.getUserInfo().emailAddress,
      countryLocale: sessionStorage.getItem('locale'),
      policyNumber: this.userDetails.policyNumber
    };

    this.userService.deleteUser(deleteUserRequest).subscribe(
      data => {
        if (data && data.success === 'true') {
          var loggedInUserId = this.userService.getUserInfo().userId;
          var deletedUserId = deleteUserRequest.userID;
          if (loggedInUserId === deletedUserId) {
            //this.logout();
          } else {
            this.getShipperAccounts();
          }
        } else {
          //check for not allowed
          this.systemError = true;
          this.getShipperAccounts();
        }
      },
      error => {
        this.pageLoading = false;
        this.systemError = true;
      }
    );
  }

  delete(row: any) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '715px';

    this.dialogRef = this.dialog.open(RemoveUserConfirmationComponent, config).afterClosed()
      .subscribe(
        result => {
          if (result === "Delete") {
            this.deleteUser(row);
          }
        }
      );
  }


  deleteUser(row: any) {
    if (row.objectUID !== undefined && row.objectUID !== null && row.objectUID.length > 1) {
      this.deleteRegularUser(row);
    } else {
      let request = {
        tempUserId: row.userId,
        policyNumber: this.userDetails.policyNumber,
        email: row.email
      }
      this.userService.deleteInvitedUser(request).subscribe(
        data => {
          if (data && data.message === 'User Deleted') {
            this.getShipperAccounts();
          } else {
            //check for not allowed
            this.systemError = true;
            this.getShipperAccounts();
          }
        },
        error => {
          this.pageLoading = false;
          this.systemError = true;
        }
      );
    }
  }

}
