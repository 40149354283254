import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NgbDropdown, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { TupssService } from 'src/app/pages/claims-custom/tupss/services/tupss.service';
import { ClaimLayoutsService } from 'src/app/services/claim-layouts.service';
import { ClaimService } from 'src/app/services/claim.service';
import { AddCustomizableDataPopupComponent } from '../add-customizable-data-popup/add-customizable-data-popup.component';
import { RemoveCustomizableDataPopupComponent } from '../remove-customizable-data-popup/remove-customizable-data-popup.component';
export interface claimStatusResult {
  claimSubStatus: string[];
  claimClosedOutcome: string[];
}

@Component({
  selector: 'upsc-customizable-data-form',
  templateUrl: './customizable-data-form.component.html',
  styleUrls: ['./customizable-data-form.component.scss']
})
export class CustomizableDataFormComponent implements OnInit {

  @ViewChild(NgbDropdownMenu) drop: NgbDropdownMenu;
  layoutForm: UntypedFormGroup;
  applied = false;
  valueErrors = false;
  layoutlist;
  layoutsname = new Array();
  addNewLayout;

  constructor(
    public claimservice: ClaimService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private tupssService: TupssService,
    private layoutService: ClaimLayoutsService
  ) {
    this.layoutForm = this.fb.group({
    });
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }
  claimStates = new Array();
  userDetails;

  ngOnInit(): void {
  }
  getUserLayouts() {
    let request = {
      "email": this.userDetails.emailAddress ? this.userDetails.emailAddress : this.tupssService.userData && this.tupssService.userData.userPrincipalName ? this.tupssService.userData.userPrincipalName : sessionStorage.getItem('guestUserEmail') ? sessionStorage.getItem('guestUserEmail') : '',
      "userId": this.userDetails.userId ? this.userDetails.userId : '',
    }
    this.claimservice.getUserLayout(request).subscribe(
      data => {
        try {
          this.layoutlist = data;
          this.addNewLayout = true;
          if (this.layoutlist) {
            this.layoutsname = [];
            if (this.layoutlist.data.userLayouts.length >= 3) {
              this.addNewLayout = false;
            }
            for (let i = 0; i < this.layoutlist.data.userLayouts.length; i++) {
              this.layoutsname.push(this.layoutlist.data.userLayouts[i]);
            }
          }
        } catch (e) { this.layoutsname = []; }
      }, error => {
        this.layoutsname = [];
      }
    );
  }

  checkDropDown(open: boolean) {
    if (open) {
      this.getUserLayouts();
    }
  }

  removeData(layoutid) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '500px';
    config.data = {
      layoutsid: layoutid
    };
    this.dialogRef = this.dialog
      .open(RemoveCustomizableDataPopupComponent, config)
      .afterClosed().subscribe(result => {
      });
    this.drop.dropdown.close();
  }

  dialogRef: any;
  addData() {
    let layoutid = null;
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '700px';
    config.data = {
      layoutsid: layoutid,
      isMaxLimit: this.addNewLayout
    };

    this.dialogRef = this.dialog
      .open(AddCustomizableDataPopupComponent, config)
      .afterClosed().subscribe(result => {
      });

    this.drop.dropdown.close();
  }
  editData(layoutid) {

    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '700px';
    config.data = {
      layoutsid: layoutid,
      isMaxLimit: true
    };

    this.dialogRef = this.dialog
      .open(AddCustomizableDataPopupComponent, config)
      .afterClosed().subscribe(result => {

      });

    this.drop.dropdown.close();
  }

  //Used when user clicks the name of their layout
  applyThisLayout(layout) {
    this.layoutService.currentLayout = layout;
    this.drop.dropdown.close();
  }
}
