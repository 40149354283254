<div class="popup-global-layout width-column-settings p-4 p-md-5 column-wrapper">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title" *ngIf="currentStep != 3">{{ 'columnSettings.title' | translate }}</div>
  <div class="global-title" *ngIf="currentStep == 3">{{ 'columnSettings.name' | translate }}</div>
  <div class="global-green-bar"></div>

  <!-- select layout page -->
  <div class="page-1" *ngIf="currentStep == 1">
    <div *ngIf="showSuccessfulEditMessage">{{ 'columnSettings.select.success' | translate }}</div>
    <div class="global-text page1">{{ 'columnSettings.select.choose' | translate }}</div>
    <mat-radio-group [formControl]="layoutOptionsControl" class="d-flex flex-column justify-content-start radio-format">
      <mat-radio-button *ngFor="let option of options" [value]="option.item1">
        <div class="d-flex">
          <div class="mr-auto">{{ option.item2 }}</div>
          <mat-icon *ngIf="option.item1 != 'Default'" (click)="editLayout(option)">edit</mat-icon>
          <mat-icon *ngIf="option.item1 != 'Default'" (click)="deleteLayout(option)">delete_outline</mat-icon>
        </div>
      </mat-radio-button>
    </mat-radio-group>
    <div class="mt-1 global-button-format">
      <button type="button" class="upsc-white-button2 format" (click)="createNewLayout()">{{ 'columnSettings.select.create' | translate }}<mat-icon class="icon-key">keyboard_arrow_right</mat-icon></button>
      <button type="button" class="upsc-green-popup-button format" (click)="applyExistingLayout()">{{ 'columnSettings.select.apply' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
      <a class="link ml-auto" (click)="resetCurrentLayout()">{{ 'columnSettings.select.reset' | translate }}<mat-icon>keyboard_arrow_right</mat-icon></a>
    </div>
    <div class="error mt-1" *ngIf="tooManyLayoutsError">
      {{ 'columnSettings.select.tooManyLayoutsError' | translate }}
    </div>
    <div class="error mt-1" *ngIf="deleteLayoutError">
      {{ 'columnSettings.select.deleteError' | translate }}
    </div>
    <div class="error mt-1" *ngIf="systemError">
      {{ 'common.systemError' | translate }}
    </div>
  </div>

  <!-- create new layout page -->
  <div class="create-layout-page" *ngIf="currentStep == 2">
    <div *ngIf="editFilterFlow">
      {{ 'columnSettings.create.editing' | translate }}
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div>{{ 'columnSettings.create.select' | translate }} {{totalColumnCount}} {{ 'columnSettings.create.select2' | translate }}</div>
      <a class="link" (click)="resetFilters()">{{ 'columnSettings.create.reset' | translate }}</a>
    </div>
    <div>
      ({{filtersSelected}}/{{totalColumnCount}})
    </div>
    <div class="selection" *ngFor="let filter of filters" [ngClass]="{'disabled' : filter.disabled === true, 'hidden' : filter.display === false }" (click)="select(filter)">
      <!-- pointer events of checkbox set to none. selecting/deselecting logic handled by select(filter) -->
      <mat-checkbox [(ngModel)]="filter.selected" class="green-background-checkbox"
      [ngClass]="{'column-settings-unselectable' : filter.value === 1 || filter.value === 2 || filter.value === 16}">
        <div class="format-text" [ngClass]="{'fade-text' : filter.disabled === true, 'default' : filter.value === 1 || filter.value === 2 || filter.value === 16}">
          {{ filter.label }}
        </div>
      </mat-checkbox>
    </div>
    <div>
      ({{filtersSelected}}/{{totalColumnCount}})
    </div>
    <div class="global-button-format align-items-center">
      <button type="button" class="upsc-green-popup-button format" (click)="applyLayoutWithoutSaving()">{{ 'columnSettings.create.apply' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
      <button type="button" class="upsc-white-button2 format" (click)="nameLayout()">{{ 'columnSettings.create.save' | translate }}<mat-icon class="icon-key">keyboard_arrow_right</mat-icon></button>
      <a class="link ml-auto" (click)="goBackToLandingPage()"><mat-icon>keyboard_arrow_left</mat-icon>{{ 'columnSettings.create.back' | translate }}</a>
    </div>
    <div class="error mt-1" *ngIf="systemError">
      {{ 'common.systemError' | translate }}
    </div>
  </div>

  <!-- save and name new layout page -->
  <div class="save-page" *ngIf="currentStep == 3">
    <br>
    <mat-form-field>
      <mat-label>{{ 'columnSettings.save.label' | translate }}</mat-label>
      <input matInput required [formControl]="nameControl">
      <mat-error>
        <div *ngIf="nameControl.invalid && nameControl.touched" class="mat-error">
          <div *ngIf="nameControl.errors.required" class="d-flex align-items-center errors">
            <div>
              {{ 'columnSettings.save.layoutRequired' | translate }}
            </div>
          </div>
        </div>
      </mat-error>
    </mat-form-field>
    <div class="global-button-format">
      <button type="button" class="upsc-white-button2" (click)="goBack()"><mat-icon class="icon-key">keyboard_arrow_left</mat-icon>{{ 'columnSettings.save.back' | translate }}</button>
      <button type="button" class="upsc-green-popup-button" (click)="saveCreatedLayout()" [disabled]="nameControl.invalid">{{ 'columnSettings.save.submit' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
    </div>
    <div class="error mt-1" *ngIf="systemError">
      {{ 'common.systemError' | translate }}
    </div>
  </div>
</div>