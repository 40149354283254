import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { PolicyService } from 'src/app/services/policy.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  tokenError: boolean;
  errorOccurred: boolean;
  idData: boolean = false;
  workflow: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private loginService: LoginService
  ) {
    let returnURL = "login";
    let queryParams = this.route.snapshot.queryParams;
    if (queryParams.UID && queryParams.page) {
      this.userService.getAutoAuthProfile(queryParams.UID).subscribe(
        profileData => {
          if (profileData) {
            this.idData = true;
            sessionStorage.setItem('access_token', profileData.accessToken);
            sessionStorage.setItem('userid', profileData.userId);
            delete profileData.accessToken;
            sessionStorage.setItem('userDetails', JSON.stringify(profileData));
            this.userService.setUserPermissions(profileData.permissions);
            if(profileData.userRoleID == '7') {
              this.workflow='DD';
              sessionStorage.setItem('DDFlow', queryParams.page);
            }
            this.userService.getUserInfo();
            // if (profileData.userRoleID !== '7') {
            sessionStorage.setItem('refresh_token', profileData.refreshToken);
            this.userService.getPolicySearch(profileData.policyNumber).subscribe(
              policyData => {
                if (policyData?.data && policyData?.message?.toLowerCase() == 'succeeded') {
                  sessionStorage.setItem('policyDetails', JSON.stringify(policyData.data));
                  returnURL = queryParams.page;
                  this.router.navigateByUrl(returnURL);
                } else {
                  sessionStorage.clear();
                  this.errorOccurred = true;
                }
              },
              error => {
                sessionStorage.clear();
                this.errorOccurred = true;
              }
            );
            // }
            // else {
            // if(profileData.policyNumber) {
            // sessionStorage.setItem('userDetails', JSON.stringify({...profileData, userId: profileData.policyNumber.split('-')[1]}));
            // sessionStorage.setItem('policyDetails', JSON.stringify({policyNumber: profileData.policyNumber, policyType: {value: ''}}));
            // returnURL = `/${queryParams.page}`;
            // sessionStorage.setItem('DDFlow', queryParams.page);
            // this.router.navigateByUrl(returnURL);
            // }
            // else {
            // this.errorOccurred = true;
            // }
            // }
          }
          else {
            this.tokenError = true;
            this.errorOccurred = true;
          }
        },
        err => {
          this.errorOccurred = true;
        }
      );
    }
    else {
      this.router.navigateByUrl(returnURL)
    }
  }

  ngOnInit(): void {
  }

}




const tempProfileData = {
  "objectUID": "12347",
  "userId": "6166",
  "zipCode": "30318",
  "contactPhone": "16261151817",
  "policyNumber": "15914024-P5001089",
  "emailAddress": "seth@merchant.com",
  "dateRegistered": "10/12/2023 2:39:55 PM",
  "contactName": "Nancy",
  "policyHoldersName": "Seth",
  "policyState": "GA",
  "userRoleID": "7",
  "userPreference": {
    "userPreferenceID": null,
    "preferenceName": null,
    "productCategory": null,
    "carrier": null,
    "address": {
      "addressID": null,
      "addressLine1": "123 Main St",
      "addressLine2": "",
      "addressLine3": null,
      "city": "Atlanta",
      "state": "GA",
      "zip": "30318",
      "country": "US"
    }
  },
  "accessToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiIyYjRhNDcxMC1iZDU2LTRkNzAtOGEyNC0xNThkYzFjMzY1ODciLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vZjkwMzJmMWYtMmJmNi00NmJjLWE0MGQtNThkNmRlMGRkMjlhL3YyLjAiLCJpYXQiOjE2OTcxMjEyOTYsIm5iZiI6MTY5NzEyMTI5NiwiZXhwIjoxNjk3MTI1MTk2LCJhaW8iOiJFMkZnWUZDWE9YQktiSU9NWDRhR2d1RE1oSjhoMjVicVhaTzl3UFRad09uSzJyZEw1YVVCIiwiYXpwIjoiYmZkNDFjZmEtZTgyNS00MTczLTkxNzQtY2Q5MzY2YmY3OWZkIiwiYXpwYWNyIjoiMiIsIm9pZCI6IjgwOTUwMThiLTZkM2YtNGY5Ni1iMDZiLTViMDMzNTg1Y2M0YSIsInJoIjoiMC5BUllBSHk4RC1mWXJ2RWFrRFZqVzNnM1NtaEJIU2l0V3ZYQk5paVFWamNIRFpZY1dBQUEuIiwic3ViIjoiODA5NTAxOGItNmQzZi00Zjk2LWIwNmItNWIwMzM1ODVjYzRhIiwidGlkIjoiZjkwMzJmMWYtMmJmNi00NmJjLWE0MGQtNThkNmRlMGRkMjlhIiwidXRpIjoiX3FJR1VpZTd6a0dvRmZ1SlluQW9BQSIsInZlciI6IjIuMCJ9.Wt_mEr9k2lqfnlbO1f2dEIBlR18uHKyk9qcr-XpY-E3nbn1n8fpVl8TkyoRMoklF9rSClIG2RRcs_g-CiZeTKe63MR9YLl7zohkpB5vmR-_TV7Xw2ny7y_duhYqichOmmyPHA6Qxl0RaopF5rFiam_PiqWgq2lr4YH790gqNqpmmMLi7HF25dFPupSk4QU5V5a4m8wCIGk6aHx2u3ifO0hlUYjFlhNIOm8Iw6IsF0J6FtS8kwGfcUH0oyydgVDfsGzXkdvdodctjxIAWBbYWxWXTTtnwDXek_ZbCbYRnQrQj3wWH3qnKpOE1BUqBoC2zNgzVDX-JhtLfCh_kjInQfQ",
  "permissions": [
    "Analytics",
    "BillingClaimNotification",
    "BillingPayments",
    "BillingView",
    "ClaimFile",
    "ClaimPayee",
    "HVCM",
    "ManagePaymentMethods",
    "PolicyUpdate",
    "PolicyView",
    "SmartUpload",
    "UserManagement"
  ]
}

