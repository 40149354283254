import { iCreatePayee } from './../../../shared/models/payee.interface';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { States } from '../../../../config/metadata-config';
import { UserService } from '../../../services/user.service';
import { environment as ENV } from '../../../../environments/environment';
import * as PATTERNS from '../../../shared/regex-patterns';
import { ClaimService } from 'src/app/services/claim.service';
import { AccountInfo } from 'src/app/shared/models/account-info.interface';

@Component({
  selector: 'upsc-payee',
  templateUrl: './payee.component.html',
  styleUrls: ['./payee.component.scss']
})
export class PayeeComponent implements OnInit {
  payeeForm: UntypedFormGroup;
  states = States;
  payeeDetails: iCreatePayee;
  payeeID: string;
  hasPayeeError = false;
  hasBankingError = false;
  canSavePayee = false;
  canAddbankingInfo = false;
  systemError = false;
  public userDetails: AccountInfo = {};
  
  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private claimService: ClaimService,
    private route: ActivatedRoute
  ) { 
    this.userDetails = this.userService.getUserInfo();
  }

  ngOnInit() {
    this.payeeForm = this.fb.group({
      policy: ['', [Validators.required, Validators.pattern(PATTERNS.POLICY_PATTERN)]],
    });

    this.payeeForm.statusChanges.subscribe(
      status => this.canSavePayee = this.payeeForm.valid
    );

    this.route.queryParams.subscribe(
      params => {
        if (params) {
          //Original functionality of this page did not have any success message.
          //Keeping it that way, since DPAT shows the success message anyway.
          if (params.status && params.status == 'back') {

          } else if (params.status && params.status == 'cancel') {

          } else if (params.status && params.status == 'success' && params.payeeID && params.payeeID != 'null') {

          }
        }
      }
    );
  }

  startOver(event: any) {
    this.payeeForm.reset();
    this.hasPayeeError = false;
    this.hasBankingError = false;
    this.payeeID = undefined;
    this.canSavePayee = false;
    this.canAddbankingInfo = false;
  }

  openDPAT() {
    this.userService.getPolicySearch(this.policy.value).subscribe(
      data =>  {
        let checkCountry = sessionStorage.getItem('locale').slice(3,5);
        let request = {
          action: 'Manage Payee',
          paymentDirection: 'Payment',
          country: checkCountry ? checkCountry : "US",
          paymentMode: '',
          policyIdentifier: this.policy.value,
          email: this.userService.getUserInfo().emailAddress,
          callBackURL: `${ENV.baseUrl.web}adjusterDashboard`,
          scenario: 'US',
          locale: sessionStorage.getItem('locale'),
          guestUserIndicator: 'N',
          tupssFlow: '',
          createdByUserId: this.userService.getUserInfo().userId,
          policyData: {
            insured: data.data.insured
          },
          cudAccess: this.userService.getUserPermissions()?.isClaimPayee ? true : false
        }

        this.claimService.getRedirect(request).subscribe(
          data => {
            window.location.replace(data.data.url);
          }, error => {
            this.systemError = true;
          }
        );},
        error => {
          this.systemError = true;
        }
    )
  }

  get policy() { return this.payeeForm.controls.policy }
}
