<form [formGroup]="whatHappenedFormGroup" class="form-content" (ngSubmit)="saveClaim()" >
      <div class="white-on-gray">
        <div class="header">
          <div class="title">
            <div class="text">
              <div class="title-bold"> {{ 'claims.edit.whatHappened.merchandise.title' | translate }}</div>
            </div>
          </div>
        </div>
        <hr>
        <div class="section mb-3 mb-lg-5">
          <div class="subsection">
            <div class="subtitle">{{ 'claims.edit.whatHappened.merchandise.description.title' | translate }}<span
                class="red">*</span></div>
            <div class="subtitle squeeze">{{ 'claims.edit.whatHappened.merchandise.description.subtitle' | translate }}
            </div>
            <mat-form-field class="full-width">
              <textarea matInput #descr maxlength="250" [readonly]="continuing" formControlName="description"></textarea>
              <mat-hint align="end">
                {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{250 - descr.value.length}}
              </mat-hint>
              <mat-error *ngIf="whatHappenedFormGroup.get('description').errors">
                <div *ngIf="whatHappenedFormGroup.get('description').errors['required']">
                  {{ 'claims.edit.whatHappened.merchandise.description.required' | translate }}
                </div>
                <div *ngIf="whatHappenedFormGroup.get('description').errors['maxlength']">
                  {{ 'claims.edit.whatHappened.merchandise.description.limit' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- comment -->
        <div class="notifications mb-3">
          <upsc-notification-box [isWarning]="true" class="warning-box"
            *ngIf="tupssType == 'tccp' && !hideDeclaredWarning">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">Reminder: At the time of shipment, this package had additional coverage purchased in the
                  amount of ${{ persistIShipData?.data.response.cdsresponse?.shipmentInfo.insuredValue_Total }}.</div>
              </div>
              <div class="close-icon" (click)="hideDeclaredWarning = true">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
          <upsc-notification-box [isWarning]="true" class="warning-box"
            *ngIf="tupssType == 'tccp' && !hideNoDeclaredWarning">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="icon-warning-wrapper p-1 d-flex">
                  <mat-icon>info_outline</mat-icon>
                </div>
                <div class="text">Reminder: At the time of shipment, additional coverage for this package was not
                  purchased. Fortunately, your claim may still be eligible for a maximum of up to $100 plus shipping
                  costs.</div>
              </div>
              <div class="close-icon" (click)="hideNoDeclaredWarning= true">
                <mat-icon>cancel</mat-icon>
              </div>
            </div>
          </upsc-notification-box>
        </div>
        <div class="step-controls">
          <div class="btnAlign">
            <button class="upsc-button" role="cancel" [disabled]="continuing" type="button" (click)="cancel()">
              <div>{{ 'claims.edit.cancel' | translate }}</div>
            </button>
          </div>
          <div class="btnAlign">
            <button class="upsc-button" role="button" [disabled]="continuing" type="submit">
              <div>{{ 'claims.edit.submit' | translate }}</div>
            </button>
          </div>
        </div>
      </div>
    </form>