import { Component, OnInit } from '@angular/core';
import { PayeeService } from 'src/app/services/payee.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-unable-to-fulfill',
  templateUrl: './unable-to-fulfill.component.html',
  styleUrls: ['./unable-to-fulfill.component.scss']
})
export class UnableToFulfillComponent implements OnInit {
  dpsUnavailable = false;
  isAdjuster: boolean;
  dangerNotification: boolean;

  constructor(
    private payeeService: PayeeService,
    private userService: UserService
  ) { 
    this.isAdjuster = this.userService.isUserAdjuster();
  }

  ngOnInit() {
    this.dpsUnavailable = this.payeeService.dpsUnavailable;
  }

}
