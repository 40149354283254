import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetpasswordComponent } from './resetpassword.component';
import { ResetpasswordSuccessComponent } from './component/resetpassword-success/resetpassword-success.component';
import { IdentityLayoutComponent } from '../../shared/components/identity-layout/identity-layout.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: IdentityLayoutComponent,
      children: [
        {
          path: '',
          component: ResetpasswordComponent
        },
        {
          path: 'success',
          component: ResetpasswordSuccessComponent
        }
      ]
    }
  ])],
  exports: [RouterModule]
})
export class ResetpasswordRoutingModule { }
