
export interface IClaims_Table {
  //Defaults at the time of original claim table
  claimNumber: true;  //Can never be false
  fileDate: true; //Can never be false
  filedAmount: boolean;
  paidAmount: boolean;
  checkACHNumber: boolean;
  checkACHIssueDate: boolean;
  trackingNumber: boolean;
  referenceNumber: boolean;
  accountNumber: boolean;
  receiver: boolean;
  status: true; //Can never be false
  //New columns at the time of custom table layouts
  paymentRecipient: boolean;
  adjuster: boolean;
  lossDate: boolean;
  declaredValue: boolean;
  submissionNumber: boolean;
  claimSource: boolean;
  policyNumber: boolean;
  shipperName: boolean;
  shipperNumber: boolean;
  //values that help with displaying tables
  columns: number //3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;
}

export const columnDictionary = {
  1: 'claimNumber',
  2: 'claimStatus',
  3: 'receiver',
  4: 'paymentRecipient',
  5: 'paidAmount',
  6: 'trackingNumber',
  7: 'adjuster',
  8: 'checkACHNumber',
  9: 'checkACHIssueDate',
  10: 'lossDate',
  11: 'filedAmount',
  12: 'accountNumber',
  13: 'declaredValue',
  14: 'referenceNumber',
  15: 'submissionNumber',
  16: 'fileDate',
  17: 'policyNumber',
  18: 'shipperName',
  19: 'shipperNumber'
}

export const defaultClaimsTable: IClaims_Table = {
  claimNumber: true,
  fileDate: true,
  filedAmount: true,
  paidAmount: true,
  checkACHNumber: true,
  checkACHIssueDate: true,
  trackingNumber: true,
  referenceNumber: true,
  accountNumber: true,
  receiver: true,
  status: true,
  paymentRecipient: false,
  adjuster: false,
  lossDate: false,
  declaredValue: false,
  submissionNumber: false,
  claimSource: false,
  policyNumber: false,
  shipperName: false,
  shipperNumber: false,
  columns: 14
}

export const EXCEL_COLUMN_MAPPING = {
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G',
  8: 'H',
  9: 'I',
  10: 'J',
  11: 'K',
  12: 'L',
  13: 'M',
  14: 'N'
}

export const CLAIM_CLOSED_OUTCOME = [
  'paymentscomplete',
  'payments_complete_Ext',
  'withdrawn_Ext',
  'incomplete_documentation_Ext',
  'mistake',
  'duplicate',
  'fraud',
  'other_Ext',
  'denied_Ext',
  'denied_alcohol_Ext',
  'denied_asd_pickup_Ext',
  'denied_credit_card_Ext',
  'denied_firearms_Ext',
  'denied_gift_card_certificate_checks_Ext',
  'denied_improper_pack_Ext',
  'denied_improper_packaging_Ext',
  'denied_late_report_Ext',
  'denied_no_damage_Ext',
  'denied_no_damage_intransit_Ext',
  'denied_no_declared_value_of_101_Ext',
  'denied_no_premium_Ext',
  'denied_no_record_of_pickup_Ext',
  'denied_no_salvage_Ext',
  'denied_no_signature_letter_Ext',
  'denied_original_packing_Ext',
  'denied_perishables_Ext',
  'denied_phonecard_gift_card_certificate_checks_Ext',
  'denied_pp_approved_commodity_Ext',
  'denied_pp_asd_pickup_Ext',
  'denied_pp_customs_seizure_Ext',
  'denied_pp_duration_Ext',
  'denied_pp_exception_to_carrier_Ext',
  'denied_pp_false_information_Ext',
  'denied_pp_fraud_Ext',
  'denied_pp_good_and_valid_delivery_Ext',
  'denied_pp_high_value_receipt_Ext',
  'denied_pp_inadequate_pack_Ext',
  'denied_pp_ncti_Ext',
  'denied_pp_no_evidence_of_tampering_Ext',
  'denied_pp_no_physical_scan_Ext',
  'denied_pp_no_signature_Ext',
  'denied_pp_out_of_liability_Ext',
  'denied_pp_reveal_contents_Ext',
  'denied_prohibited_from_shipping_Ext',
  'denied_prohibited_from_shipping_aouv_Ext',
  'denied_unusual_value_claim_Ext',
  'completed',
  'denied_concealed_dmg_Ext',
  'denied_delay_Ext',
  'denied_goods_insured_Ext',
  'denied_infidelity_Ext',
  'denied_inherent_vice_Ext',
  'denied_pkg_warranty_Ext',
  'denied_temperature_Ext',
  'denied_used_goods_Ext',
  'denied_no_insured_value_of_101_ups_Ext',
  'tc_denied_Ext',
  'tc_denied_late_report_Ext',
  'tc_denied_no_insured_value_Ext',
  'outside_market_carrier_handling_Ext',
  'denied_eur_exceeded_storage_period_Ext',
  'denied_eur_excluded_goods_Ext',
  'denied_deductible_is_larger_than_claimedamount_Ext',
  'denied_delayed_due_to_weather_Ext',
  'denied_expired_coverage_Ext',
  'denied_no_coverage_3rd_party_freight_collect_Ext',
  'denied_non_commercial_shipment_Ext',
  'denied_on_time_delivery_Ext',
  'denied_package_abondoned',
  'denied_policy_doesnt_cover_shipping_charges_Ext',
  'denied_pp_late_report_Ext',
  'denied_alcohol_tupss_Ext',
  'denied_correct_address_tupss_Ext',
  'denied_firearms_tupss_Ext',
  'denied_late_report_tupss_Ext',
  'denied_no_record_of_pickup_tupss_Ext',
  'denied_original_packing_tupss_Ext',
  'denied_perishables_tupss_Ext'
]