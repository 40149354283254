import { Component } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { PolicyService } from 'src/app/services/policy.service';
import { UserService } from 'src/app/services/user.service';
import { switchMap } from 'rxjs/operators';
import { environment as ENV } from 'src/environments/environment';
@Component({
  selector: 'upsc-visibility-agreement-flyout',
  templateUrl: './visibility-agreement-flyout.component.html',
  styleUrls: ['./visibility-agreement-flyout.component.scss']
})
export class VisibilityAgreementFlyoutComponent {
  showBannerOnVisibilityPageOnly: boolean = false;
  signupPage: boolean = false; // User still needs to sign up for the Visibility product
  wait24HoursPage: boolean = false; // User just signed up, we show 24 hours message to them
  waitPage: boolean = false; // User has already signed up, navigated back to this tab before the 24 hours, show different wait message to them

  errorPage: boolean = false;
  systemError: boolean = false;
  pageLoading: boolean = true;
  loading: boolean = false;
  termsAndConditionsIsChecked: boolean = false;

  policyDetails;
  constructor(
    private router: Router,
    private policyService: PolicyService,
    private loginService: LoginService,
    private userService: UserService
  ) {
    this.policyDetails = this.loginService.getPolicyDetails();
    if (this.router?.url == '/visibility') {
      this.showBannerOnVisibilityPageOnly = true;
    } else {
      this.showBannerOnVisibilityPageOnly = false;
    }
  }

  ngOnInit() {
    // Only call the APIs if we are on the visibility tab
    if (this.router?.url == '/visibility') {
      let request = {
        policyNumber: this.policyDetails?.policyNumber,
        action: "query"
      }
      this.pageLoading = true;
      this.policyService.ddVisibilityContract(request).subscribe(
        data => {
          // Scenario #1: They have a customerNumber, redirect them to visibility directly using their customerNumber as part of URL. Show no banner
          // Scenario #2: They don't have a customerNumber, but have a contractNumber. They've already signed up then, so show the wait message banner.
          // Scenario #3: They don't have either value, means they haven't signed up yet, so show the sign up banner.
          // Scenario #4: The API returned a completely null object entirely (no data whatsoever), display generic error banner message in that case
          this.pageLoading = false;
          if (data?.customerNo) {
            this.showBannerOnVisibilityPageOnly = false;
            // let visibilityURL = `https://skipum-${data.customerNo}-ddv-skipum-approuter.cfapps.${ENV.visibility.contaxEnvironment}.hana.ondemand.com/cp.portal/site#Visibility-OVP_DDVIS?sap-ui-app-id-hint=visibility-ovp-ddvis`;
            window.open(`https://skipum-${data.customerNo}-ddv-skipum-approuter.cfapps.${ENV.visibility.contaxEnvironment}.hana.ondemand.com/cp.portal/site#Visibility-OVP_DDVIS?sap-ui-app-id-hint=visibility-ovp-ddvis`, "_blank");
          } else if (data?.contractNo) {
            this.waitPage = true;
          } else if (data) {
            this.signupPage = true;
          } else {
            this.errorPage = true;
          }
          // Scenario #5: API straight up fails or times out, we have no idea if they've signed up or not. Show them a generic error banner message
        }, error => {
          this.pageLoading = false;
          this.errorPage = true;
        }
      );
    }
  }

  updateCheckbox(checked) {
    this.termsAndConditionsIsChecked = checked;
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
    window.scrollTo(0, 0);
  }

  signUp() {
    this.loading = true;
    this.systemError = false;
    if (this.termsAndConditionsIsChecked) {
      let request = {
        policyNumber: this.policyDetails?.policyNumber,
        action: "create"
      }

      //get existing admins and pass them for action 'create'
      let adminUsers = [];
      this.userService.getUserAdminCount({
        policyNumber: this.policyDetails?.policyNumber
      }).pipe(
        switchMap(resp => {
          if (resp.data?.adminDetails?.length > 0) {
              resp.data.adminDetails.forEach(admin => {
              let names = admin.contactName.split(/\s+/);
              adminUsers.push({
                email: admin.adminEmail,
                firstName: names[0],
                lastName: names[names.length - 1]
              });
            });
          }
          return this.policyService.ddVisibilityContract({...request, users: adminUsers});
        })
      ).subscribe(
      // this.policyService.ddVisibilityContract(request).subscribe(
        data => {
          this.loading = false;
          // Scenario #1: A contractNumber is returned, which means we were able to sign the user up for the product, show 24 hour wait message
          // Scenario #2: No contractNumber is returned, something failed so show error message.
          if (data?.contractNo) {
            this.signupPage = false;
            this.wait24HoursPage = true;
            this.policyService.ddVisibilityEmail(adminUsers);
          } else {
            this.systemError = true;
          }
          // Scenario #3: API timed out or failed completely, show error message.
        }, error => {
          this.loading = false;
          this.systemError = true;
        }
      );
    }
  }

  // Everything below is to make sure the banner stays in place
  @HostListener('window:scroll', ['$event'])
  ngAfterViewInit() {
    if (this.router?.url == 'visibility') {
      this.detectScrollPosition();
    }
  }

  detectScrollPosition(event?) {
    let windowHeight = document.body.scrollHeight; // Height of entire window currently
    let currentScrollHeight = window.scrollY + window.innerHeight; // Current scroll position
    let banner = document.getElementById('agreement') as HTMLLinkElement; // The actual agreement banner div
    let filler = document.getElementById('filler') as HTMLLinkElement; // Extra space- when the banner is fixed on top of other components, we need a 'fake' component of same height to occupy the bottom of the screen so the onScrollChange logic works

    if (currentScrollHeight < windowHeight) {
      banner.style.position = "fixed";
      filler.style.height = "248px";
    } else {
      banner.style.position = "relative";
      filler.style.height = "0px";
    }
  }
}
