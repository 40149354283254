import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClaimService } from 'src/app/services/claim.service';
import { UserService } from 'src/app/services/user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PayeeService } from 'src/app/services/payee.service';
import { TaggingService } from 'src/app/tags/tagging.service';

@Component({
  selector: 'upsc-flex-address-validator',
  templateUrl: './flex-address-validator.component.html',
  styleUrls: ['./flex-address-validator.component.scss']
})
export class FlexAddressValidatorComponent {


  addressList = [];
  currentEnteredAddress = {};
  addressForm: UntypedFormGroup;

  loading: boolean = false;
  denied: boolean = false;
  showAddressValidation: boolean = false;
  systemError: boolean = false;
  SelectedValue: number = 0;
  disableButton: boolean = true;

  @Output() userInput: EventEmitter<any> = new EventEmitter<string | number>();

  constructor(
    private dialogRef: MatDialogRef<FlexAddressValidatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private claimService: ClaimService,
    private payeeService: PayeeService,
    private taggingService: TaggingService
  ) {
    this.addressForm = this.fb.group({
      selection: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.addressValidation();
    if (this.currentEnteredAddress) {
      this.taggingService.view({
        page_id: 'validate_address',
        step: 'validate_address',
        journey_step_number: '2'
      });
    }    
  }

  isAddressValid: boolean = false;
  //Check the address the user entered vs the address API
  addressValidation() {
    this.claimService.addressValidation({
      customerName: this.data.customerName,
      addressLine1: this.data.addressLine1,
      addressLine2: this.data.addressLine2,
      city: this.data.city,
      countrycode: 'US',
      zipcode: this.data.zipcode,
      state: this.data.state,
      companyName: this.data.companyName
    }).subscribe(
      data => {
        if (data !== undefined && data.status === 'Valid') {
          let hasMatch = false;
          this.isAddressValid = true;
          for (let a of data.candidateList) {
            if (
              a?.AddressLine[0]?.toLowerCase() == this.data.addressLine1.toString().toLowerCase() &&
              a?.PoliticalDivision2?.toLowerCase() == this.data.city.toString().toLowerCase() &&
              a?.PoliticalDivision1?.toLowerCase() == this.data.state.toString().toLowerCase() &&
              a.PostcodePrimaryLow == this.data.zipcode.toString() &&
              a.CountryCode == 'US'
            ) {
              hasMatch = true;
              break;
            }
          }
          if (hasMatch) {
            //User address matched perfectly. just go straight to denied party.
            this.deniedPartyScreening();
          }
          else {
            //Need to show the user an address
            this.addressList = data.candidateList;
            this.currentEnteredAddress = {
              address1: this.data.addressLine1,
              address2: this.data.addressLine2,
              city: this.data.city,
              state: this.data.state,
              zip: this.data.zip
            }
            this.showAddressValidation = true;
            this.loading = false;
          }
        }
        else {
          this.isAddressValid = false;
          this.deniedPartyScreening();
        }
      }, error => {
        this.isAddressValid = false;
        this.deniedPartyScreening();
        this.loading = false;
      }
    );
  }

  deniedPartyScreening() {
    this.loading = true;
    this.denied = false;
    this.userService.deniedPartyCheckV2({
      company: 'Ups23',
      address: this.data.addressLine1,
      city: this.data.city,
      state: this.data.state,
      country: 'US',
      zip: this.data.zip
    }).subscribe(      
      deniedPartyFound => {
        this.loading = false;
        if (deniedPartyFound == false) {
            //this.isAddressValid = true;
        } else {
          this.showAddressValidation = false;
          this.denied = true;
          //this.isAddressValid = false;
        }
      }, error => {
        this.showAddressValidation = false;
        this.loading = false;
        this.systemError = true;
      }
    );
  }

  radioChange($event) {
    this.isAddressValid = true;
  }

  //Sends new address if user selected one. Otherwise sends undefined.
  onSubmit() {
    if (this.currentEnteredAddress) {
      this.taggingService.link({
        link_name: 'Validate',
        link_page_id: 'validate_address'
      });
    }
    if (this.SelectedValue === 0) {
      sessionStorage.setItem("updatedVerifiedAddress", JSON.stringify(this.addressList[this.selection.value]));
    }
    else {
      sessionStorage.setItem("updatedVerifiedAddress", "-1");
      this.dialogRef.close('-1');
    }
  }

  //User wanted to edit the address they already entered. essentially go back.
  edit() {
   this.dialogRef.close('0');
  }

  get selection() { return this.addressForm.controls.selection }

}
