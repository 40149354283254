<div class="container" *ngIf="isData">
  <div *ngFor="let row of pagedItems.slice().reverse(); let i = index">
    <div *ngIf="row.isAdjuster === true" class="timeline-block timeline-block-right">
      <div class="marker">{{ row.authorInitial }}</div>
      <div class="timeline-content">
        <h3>{{ row.author }}</h3>
        <span>{{ row.time | date }} {{ row.time | date:'shortTime' }}</span>
        <p>{{ row.body }}</p>
      </div>
    </div>
    <div *ngIf="row.isAdjuster === false" class="timeline-block timeline-block-left">
      <div class="marker">{{ row.authorInitial }}</div>
      <div class="timeline-content">
        <h3>{{ row.author }}</h3>
        <span>{{ row.time | date }} {{ row.time | date:'shortTime' }}</span>
        <p>{{ row.body }}</p>
      </div>
    </div>
  </div>
</div>
<div class="bottom-wrapper">
  <div class="pagination-wrapper" *ngIf="isData">
    <nav aria-label="Message navigation">
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" (click)="previous(pageNumber)">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">{{ 'claims.list.component.pager.previous' | translate }}</span>
          </a>
        </li>
        <li class="page-item" *ngFor="let page of visiblePages">
          <a class="page-link" [ngClass]="pageNumber === page && 'selected'" (click)="loadPage(page);">{{ page }} </a>
        </li>
        <li class="page-item">
          <a class="page-link" (click)="next(pageNumber)">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">{{ 'claims.list.component.pager.next' | translate }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="post-message-wrapper-desktop" *ngIf="!showButtons && canMessageAdjuster">
  <form [formGroup]="messageForm">
    <!--  <div class="msg-container">
        <textarea formControlName="message" [ngClass]="{ 'form-control': true, 'error': message.errors && message.touched }"
          class="form-control" placeholder="{{ 'claims.MessageList.placeholder' | translate }}" aria-label="message"
          aria-describedby="basic-addon2" #messageInp>
                            </textarea>
      </div>
      <div class="msg-container">
        <div class="red" *ngIf="message.errors && message.touched">
          {{ 'claims.MessageList.error' | translate }}
        </div>
        <div *ngIf="scriptError" class="red">Invalid Input</div> 
      </div>  -->  
      <div class="err-container">
        <mat-form-field class="full-width">
          <textarea matInput #descr formControlName="message"
          placeholder="{{ 'claims.MessageList.placeholder' | translate }}" #messageInp></textarea>
          <mat-error *ngIf="message.errors && message.touched">
            <div class="error-input">
              <mat-icon>report_problem</mat-icon>
              <div class="error-text"> {{ 'claims.MessageList.error' | translate }}</div>
            </div>           
          </mat-error>
        </mat-form-field>       
      </div>    
      
      <div class="msg-container">
        <div class="button-container">
          <button class="upsc-button" type="button" (click)="sendMessage()">{{ 'claims.MessageList.send' | translate
            }}<mat-icon>keyboard_arrow_right</mat-icon></button>            
        </div>
      </div>  

    </form>    
  </div>
  <div class="post-message-wrapper" *ngIf="showButtons && canMessageAdjuster">
    <form [formGroup]="messageForm">
      <div class="select-buttons">
        <button (click)="sendMessage('Nope, that\'s all thank you!')">{{ 'claims.MessageList.nope' | translate
          }}</button>
        <button (click)="sendMessage('I still need help.')">{{ 'claims.MessageList.still' | translate }}</button>
      </div>
    </form>
  </div>
</div>
<div class="red right" *ngIf="systemError">
  {{ 'common.systemError' | translate }}
</div>