<div class="wrapper">
      <div class="heading d-flex justify-content-end">
            <mat-icon (click)="close()">close</mat-icon>
      </div>
      <div class="body">
            <div class="title">
                  {{ 'claims.list.claimsSearch.customizeLayout.newLayout.buttons.delete' | translate }}
            </div>
            <hr class="green-hr">
            <div class="content">
                  {{ 'claims.list.claimsSearch.customizeLayout.newLayout.removeTitle' | translate }}
            </div>
      </div>
      <div class="navi d-flex justify-content-start align-items-center">
            <button class="upsc-button" (click)="removelayout(layoutdata)">
                  {{ 'claims.list.claimsSearch.customizeLayout.newLayout.buttons.delete' | translate | translate }}
            </button>
            <div class="cancel ml-3" (click)="close()">
                  {{ 'claims.list.claimsSearch.customizeLayout.newLayout.buttons.cancel' | translate }}
            </div>
      </div>
</div>