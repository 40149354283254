import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'upsc-wallet-recipient',
  templateUrl: './wallet-recipient.component.html',
  styleUrls: ['./wallet-recipient.component.scss']
})
export class WalletRecipientComponent {
  
  @Output() cpRecipient = new EventEmitter<any>();


  continue() {
    this.cpRecipient.emit("continue");
  }

  cancel() {
    this.cpRecipient.emit("cancel");
  }
}
