import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-errordialog',
  templateUrl: './errordialog.component.html',
  styleUrls: ['./errordialog.component.scss']
})
export class ErrordialogComponent {

  constructor(public dialogRef: MatDialogRef<ErrordialogComponent>) { }

  public cancel(){
    this.dialogRef.close();
  }

}
