<div class="card-box" [ngClass]="{'default-background': defaultBackground, 'selected': selected, 'selection-background': selectionBackground}">
  <label class="select">
    <div class="selection-top pr-3">
      <div *ngIf="radiobutton" class="selection-indicator">
        <mat-radio-button *ngIf="isSelected" [value]="radiobuttonValue" [checked]="true"></mat-radio-button>
        <mat-radio-button *ngIf="!isSelected" [value]="radiobuttonValue" [checked]="false"></mat-radio-button>
      </div>
      <div class="title">{{text}}</div>
    </div>
  </label>
  <div class="selection-content">
    <ng-content></ng-content>
  </div>
</div>