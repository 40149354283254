<!-- tccpPre questions -->


<div *ngIf="contactType=='tccp' && stage=='pre'">
    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question1' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer1' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question2' | translate" [isCollapsed]="true">

        <div innerHTML="{{'tccpPre.answers.answer2' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question3' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer3' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question4' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer4' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question5' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer5' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question6' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer6' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question7' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer7' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question8' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer8' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question9' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer9' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question10' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer10' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question11' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer11' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question12' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer12' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question13' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer13' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question15' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer15' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question16' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer16' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPre.questions.question17' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPre.answers.answer17' | translate}}"></div>
    </upsc-collapsible-panel2>

</div>

<div id='targetq14'>
    <div *ngIf="contactType=='tccp' && stage=='pre'">
        <upsc-collapsible-panel2 [title]="'tccpPre.questions.question14' | translate" [isCollapsed]="isQ14Collasped">
            <div innerHTML="{{'tccpPre.answers.answer14' | translate}}"></div>
        </upsc-collapsible-panel2>
    </div>
</div>

<!-- tccpPost questions -->
<div *ngIf="contactType=='tccp' && stage=='post'">
    <upsc-collapsible-panel2 [title]="'tccpPost.questions.question1' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPost.answers.answer1' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPost.questions.question2' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPost.answers.answer2' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPost.questions.question3' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPost.answers.answer3' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPost.questions.question4' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPost.answers.answer4' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPost.questions.question5' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPost.answers.answer5' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPost.questions.question6' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPost.answers.answer6' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tccpPost.questions.question7' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tccpPost.answers.answer7' | translate}}"></div>
    </upsc-collapsible-panel2>
</div>


<!-- tfcpNonFmc questions -->
<div *ngIf="contactType=='tfcp'">
    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question1' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer1' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question2' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer2' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question3' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer3' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question4' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer4' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question5' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer5' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question6' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer6' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question7' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer7' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question8' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer8' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question9' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer9' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 [title]="'tfcpNonFmc.questions.question10' | translate" [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer10' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 *ngIf="coverageType==3" [title]="'tfcpNonFmc.questions.question11' | translate"
        [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer11' | translate}}"></div>
    </upsc-collapsible-panel2>

    <upsc-collapsible-panel2 *ngIf="coverageType==3" [title]="'tfcpNonFmc.questions.question12' | translate"
        [isCollapsed]="true">
        <div innerHTML="{{'tfcpNonFmc.answers.answer12' | translate}}"></div>
    </upsc-collapsible-panel2>

</div>