import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { VisibilityAgreementFlyoutComponent } from './visibility-agreement-flyout.component';
import { NewNotificationBoxModule } from '../new-notification-box/new-notification-box.module';
@NgModule({
  declarations: [VisibilityAgreementFlyoutComponent],
  imports: [
    CommonModule,
    SharedModule,
    NewNotificationBoxModule
  ],
  exports: [VisibilityAgreementFlyoutComponent]
})
export class VisibilityAgreementFlyoutModule { }
