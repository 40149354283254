<div class="content p-3" [class.height-change]="codeSent">
  <a href="https://upscapital.com/" target="_blank" rel="noopener noreferrer">
    <!-- <img alt="UPS Capital Logo" *ngIf="!isCanadaUser" src="../../../assets/images/web-logo@2x.png">
    <img alt="UPS Capital Logo" *ngIf="isCanadaUser" src="../../../assets/images/UPSC_InsureShield_wTag_LinearVersion CANADA_LOGO.png"> -->
    <img alt="UPS Capital Logo" *ngIf="!isCanadaUser" src="../../../assets/logos/ca_us/InsureShield_Logo_226x79_larger_tagline-01.png">
    <img alt="UPS Capital Logo" *ngIf="isCanadaUser" src="../../../assets/logos/ca_us/UPSC_InsureShield_Horizontal CANADA_226x79_72dpi.png">
  </a>
  <h1 class="title">{{ 'guestVerification.title' | translate }}</h1>
  <div class="messages mb-3">
    <upsc-notification-box class="success-box" *ngIf="codeSent && !codeNotMatched" [isSuccess]="true">
      <div class="d-flex">
        <div class="icon mr-3 text-success">
          <img alt="check_circle_outline" src="../../../assets/images/check-circle-outline.svg">
        </div>
        <div class="text w-100">
          <div class="d-flex flex-column" *ngIf="codeSent">
            <span>{{ 'guestVerification.messages.codeSent' | translate }}</span>
            <span class="spacer mt-3">{{ 'guestVerification.messages.delayedEmail' | translate }}</span>
          </div>
        </div>
      </div>
    </upsc-notification-box>
    <upsc-notification-box class="error-box" *ngIf=" codeNotMatched || systemError" [isDanger]="true">
      <div class="d-flex">
        <div class="icon mr-3 text-danger">
          <img alt="cancel_outline" src="../../../assets/images/outline-cancel-24px.svg">
        </div>
        <div class="text w-100">
          <div class="d-flex flex-column" *ngIf="codeNotMatched">
            <span>{{ 'guestVerification.messages.emailNotFound' | translate }}</span>
          </div>
          <div *ngIf="systemError">
            <span>{{ 'common.systemError' | translate }}</span>
          </div>
        </div>
      </div>
    </upsc-notification-box>
  </div>
  <form [formGroup]="guestVerificationForm">
    <div class="mb-2">
      <div class="mb-2 text">{{ 'guestVerification.form.email' | translate }}</div>
      <input formControlName="email" type="text" tabindex="5" maxlength="60">
      <div *ngIf="guestVerificationForm.controls['email'].invalid && (guestVerificationForm.controls['email'].dirty || guestVerificationForm.controls['email'].touched)" class="upsc-errors">
        <div *ngIf="guestVerificationForm.controls['email'].errors?.required" class="d-flex align-items-center errors">
          {{ 'guestVerification.form.emailRequired' | translate }}
        </div>
        <div *ngIf="guestVerificationForm.controls['email'].errors?.pattern" class="d-flex align-items-center errors">
          {{ 'common.validEmail' | translate }}
        </div>
      </div>
    </div>
    <div class="mb-2" *ngIf="codeSent">
      <div class="mb-2 text">{{ 'guestVerification.form.code' | translate }}</div>
      <input formControlName="code" type="text" tabindex="5" maxlength="60">
      <div *ngIf="guestVerificationForm.controls['code'].invalid && (guestVerificationForm.controls['code'].dirty || guestVerificationForm.controls['code'].touched)" class="upsc-errors">
        <div *ngIf="guestVerificationForm.controls['code'].errors?.required" class="d-flex align-items-center errors">
          {{ 'guestVerification.form.verificationCodeRequired' | translate }}
        </div>
        <div *ngIf="guestVerificationForm.controls['code'].errors?.pattern" class="d-flex align-items-center errors">
          {{ 'guestVerification.form.validVerificationCode' | translate }}
        </div>
      </div>
    </div>
    <div class="g-agreement custom" *ngIf="codeSent">
      <div class="box">
        <mat-checkbox class="green-background-checkbox agreement-layout" formControlName="agreement" required></mat-checkbox>
      </div>
      <div class="box-text">
        {{ 'guestVerification.agreement.1' | translate }}
        <a href="{{ privacyLink }}" *ngIf="!isCanadaUser" target="_blank" rel="noopener noreferrer" class="tech-agreement">
          {{ 'guestVerification.agreement.1-1' | translate }}
        </a>
        <a href="{{ canadaprivacyLink }}" *ngIf="isCanadaUser" target="_blank" rel="noopener noreferrer" class="tech-agreement">
          {{ 'guestVerification.agreement.1-1' | translate }}
        </a>
        {{ 'guestVerification.agreement.1-2' | translate }}
        <a [routerLink]="['/technology-agreement']" *ngIf="!isCanadaUser" target="_blank" class="tech-agreement">
          {{ 'guestVerification.agreement.2' | translate }}
        </a>
        <a href="{{ techAgreementLink }}" *ngIf="isCanadaUser" target="_blank" class="tech-agreement">
          {{ 'guestVerification.agreement.2' | translate }}
        </a>
        {{ 'guestVerification.agreement.3' | translate }}
      </div>
    </div>
    <button *ngIf="!codeSent" class="upsc-button" type="submit" [disabled]="!guestVerificationForm.controls.email.valid"
      (click)="sendEmail($event)">
      {{ 'common.continue' | translate }}
    </button>
    <div class="actions d-flex align-items-center" *ngIf="codeSent">
      <button class="upsc-button verify" type="submit"
        [disabled]="apiInProgress || guestVerificationForm.controls.code.invalid || guestVerificationForm.controls.agreement.value!==true"
        (click)="checkVerificationCode($event)">
        {{ 'guestVerification.form.verify' | translate }}
      </button>
      <button class="resend" type="button" [disabled]="apiInProgress" (click)="resendCode($event)">
        {{ 'guestVerification.form.resendCode' | translate }}
      </button>
    </div>
  </form>
</div>