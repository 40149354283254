import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent {

  constructor(public dialogRef: MatDialogRef<ProductComponent>) { }

  public cancel(){
    this.dialogRef.close();
  }
}
