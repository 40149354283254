import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimLayoutsService {

  currentLayout$ = new BehaviorSubject<any>(null);  //Used if layout was saved to database
  private _currentLayout: any;
  currentColumnList$ = new BehaviorSubject<any>(null);  //Used if user didn't save their layout to database
  private _currentColumnList: any;

  policySelect$ = new BehaviorSubject<any>(null);  //Used if layout was saved to database
  private _policySelect: any;

  constructor() { }

  get policySelect() {
    return this._policySelect;
  }

  set policySelect(layout) {
    this._policySelect = layout;
    sessionStorage.setItem('policySelected', JSON.stringify(this._policySelect));
    this.policySelect$.next(this._policySelect);
  }

  get currentLayout() {
    return this._currentLayout;
  }

  set currentLayout(layout) {
    this._currentLayout = layout;
    sessionStorage.setItem('claimTableLayout', JSON.stringify(this._currentLayout));
    this.currentLayout$.next(this._currentLayout);
  }

  get currentColumnList() {
    return this._currentColumnList;
  }

  set currentColumnList(columns) {
    this._currentColumnList = columns;
    sessionStorage.setItem('claimTableColumns', JSON.stringify(this._currentColumnList));
    this.currentColumnList$.next(this._currentColumnList);
  }

}
