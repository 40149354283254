<div class="white-on-gray">
  <div class="header">
    <div class="title">{{ 'claims.edit.tupssPayee.paymentDetails' | translate }}</div>
    <hr>
    <div class="subtitle mb-3">{{ 'claims.edit.tupssPayee.theAddress' | translate }}</div>
    <div class="payee mb-4">
      <div>{{payeeDetails.payeeName}}</div>
      <div>{{payeeDetails.payeeEmail}}</div>
      <div>{{payeeDetails.address.streetAddress1}}</div>
      <div>{{payeeDetails.address.streetAddress2}}</div>
      <div>{{payeeDetails.address.city}}, {{payeeDetails.address.stateProvince}} {{payeeDetails.address.zip}}</div>
      <div>{{ 'metadata.country.US' | translate }}</div>
    </div>
  </div>
  <div class="header">
    <div class="title">{{ 'payee.banking.title' | translate }}</div>
    <hr class="mb-4">
    <div *ngIf="showACHError" class="red">{{ 'common.systemError' | translate }}</div>
    <iframe id="iframe" title="" [src]="achurl" height="735px" ></iframe>
  </div>
</div>
