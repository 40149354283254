<div class="red mb-3" *ngIf="showSystemError">{{ 'common.systemError' | translate }}</div>
<div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
  <div class="spinner-border">
    <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>

<div class="pirate-ship-shipment-details-wrapper" *ngIf="flowType === 'pirateship'">
  <div class="group d-flex justify-content-between">
    <div class="cluster">
      <div class="header">
        <img src="../../../assets/images/troubleshoot.svg" class="img">
        <img src="../../../assets/images/troubleshootGreen.svg" class="img-sm">
        <div class="label">{{ 'claims.edit.tupssShipmentDetails.trackNo2' | translate }}</div>
      </div>
      <input readonly value="{{ easyDataDisplayObject?.trackingNumber }}" class="input">
    </div>
    <hr class="break">
    <div class="cluster">
      <div class="header">
        <img src="../../../assets/images/package_2.svg" class="img">
        <img src="../../../assets/images/package_2Green.svg" class="img-sm">
        <div class="label">{{ 'claims.edit.review.reviewAndSubmit.reviewList.tupssShipDate' | translate }}</div>
      </div>
      <input readonly value="{{ easyDataDisplayObject?.date | date: 'MM/dd/yyyy':'UTC' }}" class="input">
    </div>
    <hr class="break">
    <div class="cluster">
      <div class="header">
        <img src="../../../assets/images/markunread_mailbox.svg" class="img">
        <img src="../../../assets/images/markunread_mailboxGreen.svg" class="img-sm">
        <div class="label">{{ 'claimSummaryExport.header.rName' | translate }}</div>
      </div>
      <input readonly [value]="easyDataDisplayObject?.name ? easyDataDisplayObject.name : '—'" class="input">
    </div>
    <hr class="break">
    <div class="cluster">
      <div class="header">
        <img src="../../../assets/images/apartment.svg" class="img">
        <img src="../../../assets/images/apartmentGreen.svg" class="img-sm">
        <div class="label">{{ 'claimSummaryExport.header.rCity' | translate }}</div>
      </div>
      <input readonly value="{{ easyDataDisplayObject?.city }}" class="input">
    </div>
    <hr class="break">
    <div class="cluster">
      <div class="header">
        <img src="../../../assets/images/home.svg" class="img">
        <img src="../../../assets/images/homeGreen.svg" class="img-sm">
        <div class="label">{{ 'claimSummaryExport.header.rState' | translate }}</div>
      </div>
      <input readonly value="{{ easyDataDisplayObject?.state }}" class="input">
    </div>
    <hr class="break">
    <div class="cluster">
      <div class="header">
        <img src="../../../assets/images/attach_money.svg" class="img">
        <img src="../../../assets/images/attach_moneygreen.svg" class="img-sm">
        <div class="label">{{ 'claimSummaryExport.header.insuredValue' | translate }}</div>
      </div>
      <input readonly value="{{ easyDataDisplayObject?.insuredValue }}" class="input">
    </div>
  </div>
</div>

<div class="confirm-shipment-details-wrapper" *ngIf="!showSpinner && flowType !== 'pirateship'">
  <div class="title">{{ 'claims.edit.navi.tupssOne' | translate }}</div>
  <hr>
  <div class="subtitle">{{ 'claims.edit.tupssShipmentDetails.subtitle' | translate }}</div>
  <div class="group d-flex flex-wrap justify-content-between">
    <div class="cluster">
      <div class="label">{{ 'claims.edit.tupssShipmentDetails.trackNo' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.trackingNumber }}">
    </div>
    <div class="cluster">
      <div class="label">{{ 'claimSummaryExport.header.rName' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.name }}">
    </div>
    <div class="cluster">
      <div class="label">{{ 'claimSummaryExport.header.rCity' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.city }}">
    </div>
    <div class="cluster">
      <div class="label">{{ 'claimSummaryExport.header.rState' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.state }}">
    </div>
    <div class="cluster">
      <div class="label">{{ 'claims.edit.review.reviewAndSubmit.reviewList.tupssShipDate' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.date | date: 'MM/dd/yyyy':'UTC' }}">
    </div>
  </div>
  <div class="description" *ngIf="userDetails?.type == 'tfcp'">
    <div class="label">{{ 'claims.edit.tupssShipmentDetails.description' | translate }}</div>
    <textarea readonly value="{{ easyDataDisplayObject?.description }}"></textarea>
  </div>
</div>