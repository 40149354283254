import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment, UrlSerializer } from '@angular/router';
import { AuthComponent } from './pages/auth/auth.component';
import { UnauthorizedComponent } from './pages/errors/unauthorized/unauthorized.component';
import { TechnologyAgreementComponent } from './pages/technology-agreement/technology-agreement.component';
import { UrlHandlerComponent } from './pages/url-handler/url-handler.component';
import { TranslationLoaderResolver } from './resolvers/translation-loader.resolver';
import { RouteGuardService } from './services/routeGuard.service';
import { UserResolver } from './resolvers/user.resolver';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    matcher: (url) => {
      return url.length === 1 && url[0].path.match(/^\w{2}[\-_]\w{2}$/i) ? {
        consumed: url,
        posParams: {
          locale: new UrlSegment(url[0].path, {})
        }
      }
        : null
    },
    redirectTo: 'login/:locale'
  },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'create-account', loadChildren: () => import('./pages/create-account/create-account.module').then(m => m.CreateAccountModule) },
  { path: 'create-account/:locale', loadChildren: () => import('./pages/create-account/create-account.module').then(m => m.CreateAccountModule) },
  // { path: 'ups-my-choice', loadChildren: () => import('./pages/claims-custom/claims-custom.module').then(m => m.CustomClaimsModule)},
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), resolve: { model: TranslationLoaderResolver } },
  { path: 'login/:locale', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), resolve: { model: TranslationLoaderResolver } },
  { path: 'reset-password', loadChildren: () => import('./pages/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule) },
  { path: 'reset-password/:locale', loadChildren: () => import('./pages/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule) },
  { path: 'login-help', loadChildren: () => import('./pages/loginhelp/loginhelp.module').then(m => m.LoginhelpModule), resolve: { model: TranslationLoaderResolver } },
  { path: 'login-help/:locale', loadChildren: () => import('./pages/loginhelp/loginhelp.module').then(m => m.LoginhelpModule), resolve: { model: TranslationLoaderResolver } },
  { path: 'email-sent', loadChildren: () => import('./pages/email-sent/email-sent.module').then(m => m.EmailSentModule) },
  { path: 'email-sent/:locale', loadChildren: () => import('./pages/email-sent/email-sent.module').then(m => m.EmailSentModule) },
  {
    path: 'guestVerify', loadChildren: () => import('./pages/guest-verification/guest-verification.module').then(m => m.GuestVerificationModule),
    canActivate: [RouteGuardService]
  },
  { path: 'reminder-sent', loadChildren: () => import('./pages/email-sent-userid/email-sent-userid.module').then(m => m.EmailSentUseridModule) },
  { path: 'reminder-sent/:locale', loadChildren: () => import('./pages/email-sent-userid/email-sent-userid.module').then(m => m.EmailSentUseridModule) },
  { path: 'expired', loadChildren: () => import('./pages/expired/expired.module').then(m => m.ExpiredModule) },
  {
    path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [RouteGuardService]
  },
  {
    path: 'newpolicy', loadChildren: () => import('./pages/newpolicy/newpolicy.module').then(m => m.NewpolicyModule),
    //canActivate: [RouteGuardService]
  },
  {
    path: 'self-service', loadChildren: () => import('./pages/self-service/self-service.module').then(m => m.SelfServiceModule),
    //canActivate: [RouteGuardService]
  },
  {
    path: 'ssp/:id', loadChildren: () => import('./pages/self-service-portal/self-service-portal.module').then(m => m.SelfServicePortalModule),
    //canActivate: [RouteGuardService]
  },
  {
    path: 'adjusterDashboard', loadChildren: () => import('./pages/adjuster-dashboard/adjuster-dashboard.module').then(m => m.AdjusterDashboardModule),
    canActivate: [RouteGuardService]
  },
  {
    path: 'claims', loadChildren: () => import('./pages/claims/claims.module').then(m => m.ClaimsModule),
    canActivate: [RouteGuardService], resolve: { model: TranslationLoaderResolver }
  },  
  {
    matcher: (url) => {     
      if(url.length >= 1 && url[0].path.match(/^(tupss|tccp|tfcp|ups-my-choice|pirate-ship)$/i)) {
        return { 
          consumed: []
        }
      }
      else if(url.length >= 1 && url[0].path.toLowerCase().match(/^(unishippers|ajg|inxpress|guest)$/i)) {
        url[0].path = url[0].path.toLowerCase();
        return {
          consumed: []
        }
      }
      return null
    },
    loadChildren: () => import('./pages/claims-custom/claims-custom.module').then(m => m.CustomClaimsModule),
    // canActivate: [RouteGuardService]
  },
  {
    path: 'billing', loadChildren: () => import('./pages/billing/billing.module').then(m => m.BillingModule),
    canActivate: [RouteGuardService]
  },
  {
    path: 'policies', loadChildren: () => import('./pages/policies/policies.module').then(m => m.PoliciesModule),
    canActivate: [RouteGuardService]
  },
  {
    path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [RouteGuardService]
  },
  {
    path: 'prospect', loadChildren: () => import('./pages/prospect/prospect.module').then(m => m.ProspectModule),
  },
  {
    path: 'prospect-portal', loadChildren: () => import('./pages/prospect-portal/prospect-portal.module').then(m => m.ProspectPortalModule),
  },
  { 
    path: 'multicarrier', loadChildren: () => import('./pages/is4u-acquisition/is4u-acquisition.module').then(m => m.Is4uAcquisitionModule),
    canActivate: [RouteGuardService]
  },
  {
    path: 'flexapp', loadChildren: () => import('./pages/flex-app/flex-app.module').then(m => m.FlexAppModule)
  },
  { path: 'updatesuccess', loadChildren: () => import('./pages/profile/updatesuccess/updatesuccess.module').then(m => m.UpdateSuccessModule) },
  { path: 'maintenance', loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
  { path: 'technology-agreement', component: TechnologyAgreementComponent },
  { path: '404', loadChildren: () => import('./pages/errors/error404/error404.module').then(m => m.Error404Module) },
  { path: '500', loadChildren: () => import('./pages/errors/error500/error500.module').then(m => m.Error500Module) },
  { path: 'mypolicy', loadChildren: () => import('./pages/hp-coi/hp-coi.module').then(m => m.HpCoiModule) },
  {
    path: 'visibility', loadChildren: () => import('./pages/insights-container/insights-container.module').then(m => m.InsightsContainerModule),
    canActivate: [RouteGuardService]
  },
  { path: 'auth', component: AuthComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'smart-upload', loadChildren: () => import('./pages/smart-upload/smart-upload.module').then(m => m.SmartUploadModule) },
  { path: 'manage-users', loadChildren: () => import('./pages/manage-users/manage-users.module').then(m => m.ManageUsersModule) },  //marktodo - add route guard
  { path: 'internal', loadChildren: () => import('./pages/internal-user/internal-user.module').then(m => m.InternalUserModule), resolve: {RLSAuthentication: UserResolver}},
  { path: '**', component: UrlHandlerComponent }
];

export function malformedUriHandler(error: URIError, urlSerializer: UrlSerializer, url: string) {
  return urlSerializer.parse('/404');
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //Angular7 options:
    //anchorScrolling: 'enabled',
    //malformedUriErrorHandler: malformedUriHandler,
    //scrollPositionRestoration: 'enabled',
    //urlUpdateStrategy: 'deferred',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
