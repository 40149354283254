<div class="popup-global-layout width-600 p-4 p-md-5">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="cancel()">close</mat-icon></div>
  <div class="global-title">{{ 'claims.edit.confirmCancel' | translate }}</div>
  <div class="global-green-bar"></div>
  <div class="global-button-format" [class.my-choice]="mychoice === true">
    <button type="button" class="upsc-white-button2" [class.my-choice-buttons]="mychoice === true" (click)="cancel()">
      <mat-icon class="icon-key">keyboard_arrow_left</mat-icon>
      <span *ngIf="!mychoice">{{ 'claims.edit.whatHappened.documents.dialog.buttons.cancel' | translate }}</span>
      <span *ngIf="mychoice">{{ 'myChoice.customCancelPop.no' | translate }}</span>
    </button>
    <button type="button" class="upsc-green-popup-button" [class.my-choice-buttons]="mychoice === true" (click)="confirm()">
      <span *ngIf="!mychoice">{{ 'claims.edit.whatHappened.documents.dialog.buttons.confirm' | translate }}</span>
      <span *ngIf="mychoice">{{ 'myChoice.customCancelPop.yes' | translate }}</span>
      <mat-icon class="icon-key2">keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>