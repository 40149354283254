<!-- Coming Soon Banner -->
<div class="dashboard-banner" *ngIf="showComingSoonBanner && canAccessDigitalWallet && !isChannelPartner">
  <div class="banner d-flex flex-row">
    <img src="../../../assets/images/notifications.png">
    <div class="banner-contents">
      <div>{{ 'dashboard.walletBanner' | translate}}</div>
      <div class="btnCenter">
        <button class="upsc-button" (click)="navigateToInfoPage()">
          <div class="btn-text-icon">
            <div class="text">{{ 'dashboard.bannerButton' | translate }}</div><mat-icon class="format">launch</mat-icon>
          </div>
        </button>
      </div>
    </div>
    <img src="../../../assets/images/close.png" class="close" (click)="closeBanner('coming-soon')">
  </div>
</div>
  
<!-- Earn Points Banner -->
<div class="dashboard-banner" *ngIf="showEarnPointsBanner && permissions?.isPolicyUpdate && canAccessDigitalWallet && !isChannelPartner">
  <div class="banner d-flex flex-row">
    <img src="../../../assets/images/notifications.png">
    <div class="banner-contents">
      <div>{{ 'dashboard.earnPointsBanner' | translate }}<sup>®</sup>{{ 'dashboard.earnPointsBanner2' | translate }}</div>
      <div class="btnCenter">
        <button class="upsc-button" (click)="earnPointsBanner()">
          <div class="btn-text-icon">
            <div class="text">{{ 'dashboard.earnPointsButton' | translate }}</div><mat-icon class="format">keyboard_arrow_right</mat-icon>
          </div>
        </button>
      </div>
    </div>
    <img src="../../../assets/images/close.png" class="close" (click)="closeBanner('earn-points')">
  </div>
</div>

<!-- IS4U Banner -->
<div class="dashboard-banner" *ngIf="showIS4UBanner && canAccessDigitalWallet && !isChannelPartner">
  <div class="banner d-flex flex-row">
    <img src="../../../assets/images/notifications.png">
    <div class="banner-contents">
      <div>{{ 'dashboard.is4uBanner' | translate }}<sup>®</sup>{{ 'dashboard.is4uBanner2' | translate }}</div>
      <div class="btnCenter">
        <button class="upsc-button" (click)="navigateToInfoPage()">
          <div class="btn-text-icon">
            <div class="text">{{ 'dashboard.is4uButton' | translate }}</div><mat-icon class="format">launch</mat-icon>
          </div>
        </button>
      </div>
    </div>
    <img src="../../../assets/images/close.png" class="close" (click)="closeBanner('is4u')">
  </div>
</div>