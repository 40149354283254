<div class="container-fluid">
    <div class="row">
      <div class="col-md-12 content">
        <router-outlet></router-outlet> 

      </div>
    </div>
</div>
<div class="wrapper format">
    <div class="mt-5 mb-5">
      <div innerHTML="{{'app.footer.tupss.message' | translate}}"></div>
    </div>
    <div class="big-links d-flex flex-row justify-content-md-start">
        <div><a href="https://ups.com" target="_blank" rel="noopener noreferrer">{{ 'app.footer.tupss.ups' | translate }}<mat-icon>open_in_new</mat-icon></a></div>
        <mat-divider [vertical]="true"></mat-divider>
        <div><a href="https://www.ups.com/us/en/business-solutions/business-shipping-tools.page" target="_blank" rel="noopener noreferrer">{{ 'app.footer.tupss.solutions' | translate }}<mat-icon>open_in_new</mat-icon></a></div>
        <mat-divider [vertical]="true"></mat-divider>
        <div><a href="http://pressroom.ups.com" target="_blank" rel="noopener noreferrer">{{ 'app.footer.tupss.pressroom' | translate }}<mat-icon>open_in_new</mat-icon></a></div>
        </div>
    </div>