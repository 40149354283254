<div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
  <div class="spinner-border">
    <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>
<div *ngIf="authError">Authentication error!</div>
<div class="wrapper-tupss" *ngIf="!showSpinner && !authError">
  <div class="d-flex flex-column-reverse flex-md-row">
    <div class="left">
      <div class="title">{{ 'franchiseclaimportal.intro' | translate }}</div>
      <div class="subtitle p-3">{{ 'franchiseclaimportal.intro1' | translate }}</div>
      <form [formGroup]="franchiseForm" (keyup.enter)="franchiseForm.valid">
        <div class="myclaims-info">
          <div class="p-3 width75">
            <div class="field">
              <!-- <div class="pb-2">{{ 'customerclaimsportal.form.tracking' | translate }}</div> -->
              <mat-form-field class="w-100">
                <input matInput formControlName="trackingNumber" placeholder="{{ 'customerclaimsportal.form.tracking' | translate }}">
                <mat-error *ngIf="franchiseForm.controls['trackingNumber'].errors">
                  <div *ngIf="franchiseForm.get('trackingNumber').errors?.required">
                    {{ 'claims.edit.start.tracking.form.trackingNumber.errors.required' | translate }}
                  </div>
                  <div *ngIf="franchiseForm.controls['trackingNumber'].errors?.pattern">
                    {{ 'claims.edit.start.tracking.form.trackingNumber.errors.pattern' | translate }}
                  </div>
                  <div *ngIf="franchiseForm.get('trackingNumber').errors['inuse']">
                    <span>{{ 'claims.edit.start.tracking.form.trackingNumber.errors.inuse1' | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="pl-3 mr-3" *ngIf="errors && errors.length > 0">
            <upsc-notification-box class="error-box" [isDanger]="true">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="icon p-2 d-flex"><mat-icon>cancel_outline</mat-icon></span>
                  <span class="errors">
                    <span [innerHTML]="errors[0] | translate" (click)="processLinks($event)"></span>
                  </span>
                  <span class="icon2 p-2 d-flex" (click)="closeErrorMsg()"><mat-icon>highlight_off</mat-icon></span>
                </div>
              </div>
            </upsc-notification-box>
          </div>
          <div class="error ml-3" *ngIf="systemError">{{ 'common.systemError' | translate }}</div>
          <button class="upsc-button ml-3" [disabled]="!franchiseForm.valid" (click)="nextStep()" href="#">
            {{ 'claims.edit.beginNewClaim' | translate }}
          </button>
        </div>
      </form>
      <div class="mt-3 mr-3" *ngIf="showOutageMessage">
        <upsc-new-notification-box [isDanger]="true" [marginBottom]="true" [marginTop]="true">
          <div class="d-flex flex-column">
            <div>We're currently experiencing a service outage for the UPS Store claims portals. Our team is actively working on resolving the issue. Our teams are working tirelessly to restore service as quickly as possible.</div>
            <div class="mt-3">At UPS Capital, we pride ourselves on providing great service and apologize for any inconvenience this service outage has caused.</div>
          </div>
      </upsc-new-notification-box>
      </div>
    </div>
    <div class="right d-none d-md-block mb-md-0">
        <div class="side-image">
          <img src="assets/images/gettyimages-607975305-2048x2048.png">
        </div>
    </div>
  </div>
</div>