import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'upsc-remove-user-confirmation',
  templateUrl: './remove-user-confirmation.component.html',
  styleUrls: ['./remove-user-confirmation.component.scss']
})
export class RemoveUserConfirmationComponent implements OnInit {

  copyrightMsgFirst: string = "";
  copyrightMsgLast: string = "";
  title = "";
  message = "";
  isCopyrightPresent: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<RemoveUserConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    var copyRightMsg = this.translateService.instant('userManagement.dialog.deleteConfirmationMessage').split('|');
    if (copyRightMsg.length > 1) {
      this.isCopyrightPresent = true;
      this.title = this.translateService.instant('userManagement.dialog.deleteConfirmation');    
      this.copyrightMsgFirst = copyRightMsg[0].toString();
      this.copyrightMsgLast = copyRightMsg[1].toString();
    }
    else {
      this.isCopyrightPresent = false;
      this.title = this.translateService.instant('defaultDelete.title');
      this.message = this.translateService.instant('defaultDelete.message');
    }
  }

  close() {
    this.dialogRef.close("Cancel");
  }

  delete() {
    this.dialogRef.close("Delete");
  }
}
