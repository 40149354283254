import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ClaimService } from 'src/app/services/claim.service';

@Component({
  selector: 'upsc-draft-confirmation',
  templateUrl: './draft-confirmation.component.html',
  styleUrls: ['./draft-confirmation.component.scss']
})
export class DraftConfirmationComponent implements OnInit {
  claimRequest = null;
  systemError = false;
  saveClaimSucceeded = false;
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<DraftConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private claimService: ClaimService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.claimRequest = this.data;
  }

  close() {
    this.dialogRef.close();
  }

  saveAndExit() {
    this.loading = true;
    this.data.claimReq.onlContextInfo_Ext = this.data.step;
    this.claimService.saveClaim(this.data.claimReq).subscribe(
      data => {
        if (data.claimNumber) {
          this.resetVariables();
          this.close();
          this.router.navigate(['/claims']);
        } else {
          this.loading = false;
          this.systemError = true;
        }
      }, error => {
        this.loading = false;
        this.systemError = true;
      }
    );
  }

  resetVariables() {
    this.loading = false;
    this.systemError = false;
  }
}
