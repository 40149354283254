import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { CoverageDeductibleInterface, POLICYTYPES } from 'src/app/shared/models/policies.interface';
import { PolicyService } from 'src/app/services/policy.service';
import * as FileSaver from 'file-saver';
import { environment as ENV } from 'src/environments/environment';

@Component({
  selector: 'upsc-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss']
})
export class PoliciesComponent implements OnInit {

  basicPolicy;
  policyType: POLICYTYPES;
  extendedPolicy;
  firstHalfEndorsements;
  secondHalfEndorsements;
  coveragesAndDeductibles: CoverageDeductibleInterface[] = new Array();
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  principlesofConduct = ENV.links.principlesofConduct;
  factSheet = ENV.links.factSheet;
  showDocuments: boolean;

  constructor(
    private userService: UserService,
    private loginService: LoginService,
    private policyService: PolicyService
    ) {
      this.isCanadaUser = this.userService.isCanadaUser();
      this.isUkUser = this.userService.isUkUser();
      this.isUsaUser = this.userService.isUsaUser();
      this.isGermanyUser = this.userService.isGermanyUser();
      this.isFranceUser = this.userService.isFranceUser();
      this.isItalyUser = this.userService.isItalyUser();
    }

  ngOnInit() {
    this.basicPolicy = this.loginService.getPolicyDetails();
    this.policyType = this.policyService.determinePolicyType(this.basicPolicy);
    this.getPolicyCenterInfo(this.basicPolicy.policyNumber);
  }

  getPolicyCenterInfo(policy) {
    this.userService.policyCenterSearch(policy).subscribe(data => {
      this.extendedPolicy = data;
      if(this.extendedPolicy?.account?.company?.address?.stateProvince == "ON"){
        this.showDocuments = true;
      }
      else{ this.showDocuments = false; }
      if (data.policy) {
        //Split up endorsements to create 2 sections
        this.firstHalfEndorsements = this.extendedPolicy.policy.endorsements.slice(0, Math.floor(this.extendedPolicy.policy.endorsements.length / 2));
        this.secondHalfEndorsements = this.extendedPolicy.policy.endorsements.slice(Math.floor(this.extendedPolicy.policy.endorsements.length / 2), this.extendedPolicy.policy.endorsements.length);

        //First make objects for each coverage part, as this determines how many tables to make in the UI.
        for (let a=0; a<data.policy.coverageParts.length; a++) {
          this.coveragesAndDeductibles.push({
            coveragePartName: data.policy.coverageParts[a]
          });
          this.coveragesAndDeductibles[a].coverage = new Array();
        }

        //Verbiage changes
        for (let v=0; v<data.policy.geographicMovements.length; v++) {
          for (let w=0; w<data.policy.geographicMovements[v].covTerms.length; w++) {
            if (data.policy.geographicMovements[v].covTerms[w].name == 'Any one truck, railroad or train limit') {
              data.policy.geographicMovements[v].covTerms[w].name = 'Any one truck, railroad, or train limit';
            }
            if (data.policy.geographicMovements[v].covTerms[w].name == 'Any  one vessel deductible type') {
              data.policy.geographicMovements[v].covTerms[w].name = 'Any one vessel deductible type';
            }
            if (data.policy.geographicMovements[v].covTerms[w].name == 'Any  one vessel deductible amount') {
              data.policy.geographicMovements[v].covTerms[w].name = 'Any one vessel deductible amount';
            }
          }
        }

        //Re-organize Coverage & Deductibles for our table
        for (let i=0; i<data.policy.geographicMovements.length; i++) {
          for (let j=0; j<data.policy.geographicMovements[i].covTerms.length; j++) {
            //First determine WHICH coverage part we are adding to.
            let coveragePartToSearchFor = data.policy.geographicMovements[i].coveragePart;
            let indexOfCoveragePart = this.coveragesAndDeductibles.findIndex(x => x.coveragePartName == coveragePartToSearchFor);

            //Look to see if we already have a Limit Type for the current object.
            let coverageNameToSearchFor = data.policy.geographicMovements[i].covTerms[j].name.includes("deductible type") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible type", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("deductible amount") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible amount", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("deductible percentage") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible percentage", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("limit") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" limit", "") : data.policy.geographicMovements[i].covTerms[j].name;
            if (this.coveragesAndDeductibles[indexOfCoveragePart].coverage.find(element => element.coverageName == coverageNameToSearchFor) == undefined) {
              //We don't yet have a coverageName for this coveragePart. Create one.
              this.coveragesAndDeductibles[indexOfCoveragePart].coverage.push({
                coverageName: data.policy.geographicMovements[i].covTerms[j].name.includes("deductible type") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible type", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("deductible amount") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible amount", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("deductible percentage") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible percentage", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("limit") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" limit", "") : data.policy.geographicMovements[i].covTerms[j].name
              });

              //Look to see if we already have the geographicMovement that is associated with this coverageName & coveragePart
              let coverageNameToSearchFor = data.policy.geographicMovements[i].covTerms[j].name.includes("deductible type") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible type", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("deductible amount") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible amount", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("deductible percentage") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible percentage", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("limit") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" limit", "") : data.policy.geographicMovements[i].covTerms[j].name;
              let index = this.coveragesAndDeductibles[indexOfCoveragePart].coverage.findIndex(x => x.coverageName == coverageNameToSearchFor);
              if (this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement == undefined) {
                this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement = new Array();
              }
              if (this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement.find(element => element.name == data.policy.geographicMovements[i].geographicMovement)) {
                //We already have this geographic movement.
              } else {
                //We need to add this geographic movement.
                this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement.push({name: data.policy.geographicMovements[i].geographicMovement});
              }

              //Add the limit / deductible to the correct geographic movement, coverage name, and coverage part.
              let indexOfGeoMove = this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement.findIndex(x => x.name == data.policy.geographicMovements[i].geographicMovement);
              if (data.policy.geographicMovements[i].covTerms[j].name.includes("deductible type")) {
                this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement[indexOfGeoMove].deductibleType = data.policy.geographicMovements[i].covTerms[j].value;
              } else if (data.policy.geographicMovements[i].covTerms[j].name.includes("deductible amount") || data.policy.geographicMovements[i].covTerms[j].name.includes("deductible percentage")) {
                this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement[indexOfGeoMove].deductibleVal = data.policy.geographicMovements[i].covTerms[j].value;
              } else {
                this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement[indexOfGeoMove].limitVal = data.policy.geographicMovements[i].covTerms[j].value;
              }

              //reset variables
              coverageNameToSearchFor = null;
              index = null;
              indexOfGeoMove = null;
            } else {
              //We already have a coverageName for this coveragePart. This means we previously added one for a different geoMovement, so now check if it is a new geoMovement and add it if it is.
              let coverageNameToSearchFor = data.policy.geographicMovements[i].covTerms[j].name.includes("deductible type") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible type", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("deductible amount") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible amount", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("deductible percentage") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" deductible percentage", "") : data.policy.geographicMovements[i].covTerms[j].name.includes("limit") ? data.policy.geographicMovements[i].covTerms[j].name.replace(" limit", "") : data.policy.geographicMovements[i].covTerms[j].name;
              let index = this.coveragesAndDeductibles[indexOfCoveragePart].coverage.findIndex(x => x.coverageName == coverageNameToSearchFor);
              if (this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement.find(element => element.name == data.policy.geographicMovements[i].geographicMovement)) {
                //We already have this geographic movement.
              } else {
                //We need to add this geographic movement.
                this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement.push({name: data.policy.geographicMovements[i].geographicMovement});
              }

              //Add the limit / deductible to the correct geographic movement, coverage name, and coverage part.
              let indexOfGeoMove = this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement.findIndex(x => x.name == data.policy.geographicMovements[i].geographicMovement);
              if (data.policy.geographicMovements[i].covTerms[j].name.includes("type")) {
                this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement[indexOfGeoMove].deductibleType = data.policy.geographicMovements[i].covTerms[j].value;
              } else if (data.policy.geographicMovements[i].covTerms[j].name.includes("amount") || data.policy.geographicMovements[i].covTerms[j].name.includes("percentage")) {
                this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement[indexOfGeoMove].deductibleVal = data.policy.geographicMovements[i].covTerms[j].value;
              } else {
                this.coveragesAndDeductibles[indexOfCoveragePart].coverage[index].geographicMovement[indexOfGeoMove].limitVal = data.policy.geographicMovements[i].covTerms[j].value;
              }

              //Reset variables
              coverageNameToSearchFor = null;
              index = null;
              indexOfGeoMove = null;
            }
            coverageNameToSearchFor = null;
          }
        }
        //Now, remove any lines where limitVal and deductibleVal are not populated. We don't want to show these in the table.
        //Loop backwards to avoid skipping indexes when deleting from the array.
        for (let b=0; b<this.coveragesAndDeductibles.length; b++) {
          for (let c=this.coveragesAndDeductibles[b].coverage.length; c>0; c--) {
            for (let d=this.coveragesAndDeductibles[b].coverage[c-1].geographicMovement.length; d>0; d--) {
              if (this.coveragesAndDeductibles[b].coverage[c-1].geographicMovement[d-1].limitVal && this.coveragesAndDeductibles[b].coverage[c-1].geographicMovement[d-1].deductibleVal) {
                //This row has limit & deductible. Don't remove it.
              } else {
                //This row doesn't have the necessary info. Remove it.
                this.coveragesAndDeductibles[b].coverage[c-1].geographicMovement.splice(d-1, 1);
              }
              //Furthermore, if a coverageName doesn't have anything inside its geographicMovement, just remove it. We don't want to show that in the table either.
              if (this.coveragesAndDeductibles[b].coverage[c-1].geographicMovement.length == 0) {
                this.coveragesAndDeductibles[b].coverage.splice(c-1, 1);
              }
            }
          }
        }
      }
    });
  }

  downloadDocument(document) {
    this.policyService.downloadPCDocument(document.publicId.split(":")[1], this.basicPolicy.policyNumber).subscribe(data => {
      if (data.headers.get('Content-Type') == 'application/json') {
        //'file' is coming as json. Probably just an error code, don't download.
      } else {
        let file = new Blob([data.body], {type: data.headers.get('Content-Type')});
        FileSaver.saveAs(file, document.name);
      }
    });
  }

}


