import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ClaimService } from 'src/app/services/claim.service';

@Component({
  selector: 'upsc-remove-customizable-data-popup',
  templateUrl: './remove-customizable-data-popup.component.html',
  styleUrls: ['./remove-customizable-data-popup.component.scss']
})
export class RemoveCustomizableDataPopupComponent implements OnInit {

  layoutdata;
  loading = false;
  userDetails;

  constructor(
    public claimservice: ClaimService,
    public dialogRef: MatDialogRef<RemoveCustomizableDataPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.layoutdata = data.layoutsid;
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close();
  }

  removelayout(layoutdata) {
    this.loading = true;
    let request = {
      "layoutId": layoutdata.item1,
      "userId": this.userDetails.userId ? this.userDetails.userId : '',
    }
    this.claimservice.removeUserLayout(request).subscribe(
      data => {
        this.loading = false;
        if (data.code == 200 && data.message == "LayoutDeleted") {
          //Always reroute
          //Close the popup and trigger a page refresh.
          this.dialogRef.close();
        } else {
          //An error occurred
        }
      }, error => {
        this.loading = false;
      }
    );
  }

}
