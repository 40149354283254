import { Component, Input } from '@angular/core';

@Component({
  selector: 'upsc-collapsible-panel2',
  templateUrl: './collapsible-panel2.component.html',
  styleUrls: ['./collapsible-panel2.component.scss'],
})
export class CollapsiblePanel2Component {

  @Input() public isCollapsed = false;
  @Input() public title: string;

}
