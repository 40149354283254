import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PayeeService } from 'src/app/services/payee.service';

@Component({
  selector: 'upsc-remove-payee-popup',
  templateUrl: './remove-payee-popup.component.html',
  styleUrls: ['./remove-payee-popup.component.scss']
})
export class RemovePayeePopupComponent implements OnInit {

  payee;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<RemovePayeePopupComponent>,
    private payeeService: PayeeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.payee = data.payee;
  }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close();
  }

  removePayee(payee) {
    this.loading = true;
    let request = {
      "payeeID": payee.payeeID,
      "recordActiveStatusIndicator": "F"
    }
    this.payeeService.updateACHIndicator(request).subscribe(
      data => {
        this.loading = false;
        if (data.isUpdated === 'success') {
          //Close the popup and trigger a page refresh.
          this.dialogRef.close();
          window.location.reload();
        } else {
          //An error occurred
        }
      }, error => {
        this.loading = false;
      }
    );
  }
}
