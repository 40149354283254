import { Component, HostBinding } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { PrivacyComponent } from '../privacy/privacy.component';
import { ProductComponent } from '../product/product.component';
import { environment as ENV } from '../../../../environments/environment';
import { UserService } from 'src/app/services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'upsc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @HostBinding('class') public hostClass = 'footer';

  thisYear = (new Date()).getFullYear();
  techAgreementLink = ENV.links.techAgreement;
  privacyLink = ENV.links.privacyNotice;
  canadaprivacyLink = ENV.links.canadaprivacyNotice;
  canadaproductLink = ENV.links.canadaProductDisclosures;
  //Europe Footer links
  ukComplints = ENV.links.ukComplints;
  ukprivacyNotice = ENV.links.ukprivacyNotice;
  ukproductDisclosures = ENV.links.ukproductDisclosures;
  deTechAgreementLink;
  frTechAgreementLink;
  deComplints;
  deprivacyNotice;
  deproductDisclosures;
  deImpressum;
  determsOfuse;
  itTechAgreementLink;
  itComplints;
  itprivacyNotice;
  itproductDisclosures;
  ittermsOfuse;
  frComplints;
  frprivacyNotice;
  frproductDisclosures;
  frtermsOfuse;

  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  showInPayee: boolean;
  disclaimerOnload: boolean;
  claimsHelp: boolean;
  isDeEnglish: boolean;
  pirateShipFlow;
  constructor(public dialog: MatDialog, private _router: Router, private userService: UserService) {
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    
    // if (!this.isCanadaUser && !this.isUkUser && !this.isUsaUser && !this.isGermanyUser && !this.isFranceUser && !this.isItalyUser) {
    //   this.isUsaUser = true;
    // }
    this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(
      (e: NavigationEnd) => {
        if (e.urlAfterRedirects.includes("/login") || e.url.includes("/login") || e.url.includes("/login-help") ||
          e.url.includes("/create-account") || e.urlAfterRedirects.includes("/email-sent") || e.urlAfterRedirects.includes("/reminder-sent")
          || e.urlAfterRedirects.includes("/reset-password") || e.url.includes("/guestVerify")) {
          this.disclaimerOnload = false;
        }
        else {
          this.disclaimerOnload = true;
        }
      }
    );
  }

  ngOnInit() {
    let  locale = sessionStorage.getItem('locale');
    if (locale == 'en-DE') {
     this.deTechAgreementLink = ENV.links.deTechAgreementLink;
     this.deComplints = ENV.links.deComplints;
     this.deImpressum = ENV.links.deImpressum;
     this.deprivacyNotice = ENV.links.deprivacyNotice;
     this.deproductDisclosures = ENV.links.deproductDisclosures;
     this.determsOfuse = ENV.links.determsOfuse;
     this.isDeEnglish = true;
    }
    else if (locale == 'de-DE') {
      this.deTechAgreementLink = ENV.links.deDeTechAgreementLink;
      this.deComplints = ENV.links.deDeComplints;
      this.deImpressum = ENV.links.deDeImpressum;
      this.deprivacyNotice = ENV.links.deDeprivacyNotice;
      this.deproductDisclosures = ENV.links.deDeproductDisclosures;
      this.determsOfuse = ENV.links.deDetermsOfuse;
    }
    else if (locale == 'en-FR') {
      this.frTechAgreementLink = ENV.links.frTechAgreementLink;
      this.frComplints = ENV.links.frComplints;
      this.frprivacyNotice = ENV.links.frprivacyNotice;
      this.frproductDisclosures = ENV.links.frproductDisclosures;
      this.frtermsOfuse = ENV.links.frtermsOfuse;
     }
     else if (locale == 'fr-FR') {
      this.frTechAgreementLink = ENV.links.frFrTechAgreementLink;
      this.frComplints = ENV.links.frFrComplints;
      this.frprivacyNotice = ENV.links.frFrprivacyNotice;
      this.frproductDisclosures = ENV.links.frFrproductDisclosures;
      this.frtermsOfuse = ENV.links.frFrtermsOfuse;
     }
     else if (locale == 'en-IT') {
      this.itTechAgreementLink = ENV.links.itTechAgreementLink;
      this.itComplints = ENV.links.itComplints;
      this.itprivacyNotice = ENV.links.itprivacyNotice;
      this.itproductDisclosures = ENV.links.itproductDisclosures;
      this.ittermsOfuse = ENV.links.ittermsOfuse;
     }
     else if (locale == 'it-IT') {
      this.itTechAgreementLink = ENV.links.itItTechAgreementLink;
      this.itComplints = ENV.links.itItComplints;
      this.itprivacyNotice = ENV.links.itItprivacyNotice;
      this.itproductDisclosures = ENV.links.itItproductDisclosures;
      this.ittermsOfuse = ENV.links.itIttermsOfuse;
     }
     this.pirateShipFlow = this._router.url.includes('pirate-ship');
  }
  openPrivacyDialog() {
    let config = new MatDialogConfig();
    config = {
      panelClass: 'dialogPanel',
      height: '90vh',
      maxWidth: '95vw'
    }
    config.autoFocus = false;
    let dialogRef = this.dialog.open(PrivacyComponent, config);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  // openProductDialog() {
  //   let config = new MatDialogConfig();
  //   config = {
  //     panelClass: 'dialogPanel',
  //     height: '90vh',
  //     maxWidth: '95vw'
  //   }
  //   config.autoFocus = false;
  //   let dialogRef = this.dialog.open(ProductComponent, config);

  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }
}


