<upsc-user-filter (notifySearch)="notifySearch($event)" (notifyReset)="notifyReset($event)"></upsc-user-filter>
<div class="claims-container">
  <div *ngIf="isLoading" class="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading Policies...</span>
    </div>
  </div>

  <div class="desktop-claim-table" #table>    
    <div class="overflow-format">
      <table class="upsc-table-isop" *ngIf="!isLoading">
        <tbody>
          <tr>
            <th><div class="table-header"><span class="text">Customer Name</span></div></th>
            <th><div class="table-header"><span class="text">Policy Number</span></div></th>
            <th><div class="table-header"><span class="text">User Name</span></div></th>
            <th><div class="table-header"><span class="text">Email</span></div></th>
            <th><div class="table-header"><span class="text">Role</span></div></th>
            <th><div class="table-header"><span class="text">Notifications</span></div></th>
            <th><div class="table-header"><span class="text">Status</span></div></th>
          </tr>
          <tr *ngFor="let page of pagedItems" class="data">
            <td>
              <div class="customer" (click)="editUser(page, page.policyNumber)"><span class="text">{{ page.customerName }}
                </span><mat-icon>chevron_right</mat-icon></div>
            </td>
            <td>{{ page.policyNumber }}</td>
            <td>{{ page.userName }}</td>
            <td>{{ page.email }}</td>
            <td>{{ page.role }}</td>
            <td>{{ page.notifications }}</td>
            <td><div class="action"><span class="text" *ngIf="page.status">Active</span>
              <span class="text" *ngIf="!page.status">Inactive</span>
              <mat-icon (click)="delete(page)">delete_outline</mat-icon></div></td>
          </tr>
          <tr *ngIf="pagedItems.length===0">
            <td colspan="7">No Records Found</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="pager.currentPage>0 && pageSize>0" class="desktop-claim-table">
    <upsc-paginate-list [currentPage]="pager.currentPage" [pager]="pager" [pagePerAsset]="pageSize"
      (pagerNotification)="setPager($event)" (assetNotification)="loadAssetPerPage($event)"></upsc-paginate-list>
  </div>
</div>

<div class="table-small">
  <div class="heading">
    Sub Policies
  </div>
  <!-- <div *ngIf="!isDataPresent" class="text-center-mobile">{{ 'userManagement.table.empty' | translate }}</div> -->
  <div>
    <div *ngFor="let page of pagedItems" class="card">
      <div class="title"><span class="text"> {{ page.policyNumber }}</span> <mat-icon (click)="delete(page)">delete_outline</mat-icon>
      </div>
      <!-- <div class="top">
        <div class="left">
          <div class="label">policy Number</div>
          <div class="text">{{ page.policyNumber }}</div>
        </div>
      </div>  -->    
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="label">User Name</div>
        <div class="text">{{ page.userName }}</div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="label">Email</div>
        <div class="text">{{ page.email }}</div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="label">Role</div>
        <div class="text">{{ page.role }}</div>
      </div>
      <div class="edit">
        <div class="edit-content" (click)="editUser(page, page.policyNumber)">
          <span class="text">{{ page.customerName }}</span>
          <mat-icon>edit</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="pager.currentPage>0 && pageSize>0" class="paginate-mobile">
    <upsc-paginate-list [currentPage]="pager.currentPage" [pager]="pager" [pagePerAsset]="pageSize"
      (pagerNotification)="setPager($event)" (assetNotification)="loadAssetPerPage($event)"></upsc-paginate-list>
  </div>
</div>