import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DateAdapter } from '@angular/material/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class MobileSearchComponent {

  policyType: string;
  policyNumber: string;
  searchByValue: string;
  calendarBool: boolean;
  amountBool: boolean;
  searchQuery: string;
  searchable: boolean = false;
  isUsaUser: boolean;

  searchBy = [
    { value: 'claimNumber', label: 'Claim #' },
    { value: 'fileDate', label: 'File Date' },
    { value: 'totalPaidAmount', label: 'Total Paid Amount' },
    { value: 'trackingNumber', label: 'Tracking #/BOL' },
    { value: 'referenceNumber', label: 'Reference #' },
    { value: 'receiver', label: 'Receiver' }
  ];

  constructor(
    public dialogRef: MatDialogRef<MobileSearchComponent>,
    private breakpointObserver: BreakpointObserver, 
    private userService: UserService,
    private dateAdapter: DateAdapter<any>, 
    @Inject(MAT_DIALOG_DATA) data){ 
      this.policyType = data.policyType;
      this.policyNumber = data.policyNo;
      this.isUsaUser = this.userService.isUsaUser();
  }

  get isMobile(){
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
  public ngOnInit() {
    if(!this.isUsaUser){
      this.dateAdapter.setLocale('en-GB');
    }
  }
  checkCategory(){
    this.searchable = false;
    if(this.searchByValue == 'fileDate'){
      this.calendarBool = true;
      this.amountBool = false;
      this.searchQuery = "";
    }else if(this.searchByValue == 'totalPaidAmount'){
      this.amountBool = true;
      this.calendarBool = false;
      this.searchQuery = "";
    }else{
      this.calendarBool = false;
      this.amountBool = false;
      this.searchQuery = "";
    }
  }

  checkQuery(){
    //Check if user can search now
    if(this.searchByValue && this.searchQuery){
      this.searchable = true;
    }
  }

  search(){
    this.dialogRef.close({cat: this.searchByValue, query: this.searchQuery});
  }

  cancel(){
    this.dialogRef.close();
  }

  refreshInputs(){
    this.searchByValue = undefined;
    this.searchQuery = undefined;
    this.searchable = false;
  }

}
