<div class="faqs">
    <div class="center-faqs">
        <div class="page-title">Channel Partners Claims Portal FAQ</div>
        <div class="page-title-description">The Channel Partners Claims Portal enables process efficiencies for partners and improved customer  experiences for end-users.  Shippers can now file claims directly with UPS Capital, the claims processor, for faster claims resolution. Here are some frequently asked questions from shippers when filing a claim.</div>
        <upsc-collapsible-panel-light [title]="'ccp.questions.question1.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ccp.questions.question1.explanation' | translate}}"></div>
            <div class="step" innerHTML="{{'ccp.questions.question1.step1' | translate}}"></div>
            <div class="step-detail" innerHTML="{{'ccp.questions.question1.step1Details' | translate}}"></div>
            <div class="step" innerHTML="{{'ccp.questions.question1.step2' | translate}}"></div>
            <div class="step-detail" innerHTML="{{'ccp.questions.question1.step2Details' | translate}}"></div>
            <div class="step" innerHTML="{{'ccp.questions.question1.step3' | translate}}"></div>
            <div class="step-detail" innerHTML="{{'ccp.questions.question1.step3Details' | translate}}"></div>
            <div class="step" innerHTML="{{'ccp.questions.question1.step4' | translate}}"></div>
            <div class="step-detail" innerHTML="{{'ccp.questions.question1.step4Details' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question2.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ccp.questions.question2.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question3.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ccp.questions.question3.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question4.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ccp.questions.question4.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question5.title' | translate" [isCollapsed]="true">
            <div>{{'ccp.questions.question5.answer1' | translate}} <b>{{'ccp.questions.question5.answer2' | translate}}</b>{{'ccp.questions.question5.answer3' | translate}}</div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question6.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ccp.questions.question6.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question7.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ccp.questions.question7.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question8.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ccp.questions.question8.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question9.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ccp.questions.question9.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question10.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ccp.questions.question10.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ccp.questions.question11.title' | translate" [isCollapsed]="true">
            <div>{{'ccp.questions.question11.answer1' | translate}} <a href="mailto:capitalus@ups.com" class="link">capitalus@ups.com</a>{{'ccp.questions.question11.answer2' | translate}}</div>        
        </upsc-collapsible-panel-light>
            
        <div class="copyright-faq">{{'ccp.copyright' | translate}}</div> 
    </div> 
</div>