import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUps'
})
export class FilterUpsPipe implements PipeTransform {

  transform(carrierList: any[]): any {
    let filter = 'UPS';
    if ( !carrierList || !filter) {
      return carrierList;
    }

    //Basically just remove UPS from the carrierList
    return carrierList.filter(carrier => carrier != filter);
  }

}
