import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WalletRecipientComponent } from './wallet-recipient/wallet-recipient.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    WalletRecipientComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  providers: [],
  exports: [WalletRecipientComponent]
})
export class WalletRecipientModule { }
