<div class="Rectangle d-flex flex-column mx-auto p-5">
  <div class="main">
    <div mat-dialog-title>
      <mat-icon (click)='cancel()'>close</mat-icon>
      <span>{{ 'app.footer.privacy.effective' | translate }}</span>
      <h1>{{ 'app.footer.privacy.title' | translate }}</h1>
    </div>
    <mat-dialog-content>
      <p>
        {{ 'app.footer.privacy.content1' | translate }}<a class="link" target="_blank" rel="noopener noreferrer"
          href="https://www.ups.com/us/en/help-center/legal-terms-conditions/privacy-notice.page">{{ 'app.footer.privacy.link' | translate }}</a>{{ 'app.footer.privacy.content2' | translate }}
      </p>
    </mat-dialog-content>
  </div>
</div>