
export class SubPolicyList {
    userId: number;
    customerName: string;
    policyNumber?: string;
    userName?: string;
    email: string;
    role?: string;
    notifications?: string;
    status?: string;
    srno?: number;  
    objectUID?: any;  
  }

  
  