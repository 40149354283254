import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { caStates, countries, usStates } from 'src/config/metadata-config';
import { iCreatePayee, sessionInfo } from '../../../../../../shared/models/payee.interface';
import { PayeeService } from 'src/app/services/payee.service';
import { Router } from '@angular/router';
import { environment as ENV } from '../../../../../../../environments/environment';
import { UserService } from 'src/app/services/user.service';
import { LoginService } from 'src/app/services/login.service';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import  * as PATTERNS from '../../../../../../shared/regex-patterns';
import { RouteObserverService } from 'src/app/services/route-observer.service';
import { ClaimService } from 'src/app/services/claim.service';
import { TranslateService } from '@ngx-translate/core';
import { TaggingService } from 'src/app/tags/tagging.service';
@Component({
  selector: 'upsc-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  //Form
  payeeForm: UntypedFormGroup;
  submitModel: iCreatePayee = {};
  disableSubmit: boolean = false;

  //Data
  states = usStates;
  castates = caStates;
//Metadata / Data Lists
public countryList = [];
  isGuestUser: boolean;
  isCanadaUser: boolean;
  isUsaUser: boolean;
  isUkUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  //Autofill
  policyInfo;
  userDetails;
  previousUrl = null;
  //Banking
  sessionInfo: sessionInfo = {
    "payeeId": "",
    "returnSessionId": "",  //This will be the same as payeeId. Used to update ACH indicator after adding bank info
    "returnUrl": `${ENV.baseUrl.web}claims/bankSuccess`
  }

  constructor(
    private fb: UntypedFormBuilder,
    private payeeService: PayeeService,
    private userService: UserService,
    private loginService: LoginService,
    private phonePipe: PhoneNumberPipe,
    private router: Router,
    private claimService: ClaimService,
    private routeObserverService: RouteObserverService,
    private translateService: TranslateService,
    private taggingService: TaggingService
  ) {
    this.policyInfo = this.loginService.getPolicyDetails();
    this.userDetails = this.userService.getUserInfo();
    this.isGuestUser = this.userService.isGuestUser();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();


    this.payeeForm = this.fb.group({
      firstName: ['',this.isUsaUser ? [] : (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser)  ? [Validators.required] : [Validators.required, Validators.pattern(PATTERNS.PAYEE_NAME_PATTERN)]],
      lastName: ['',this.isUsaUser ? [] : (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser)  ? [Validators.required] : [Validators.required, Validators.pattern(PATTERNS.PAYEE_NAME_PATTERN)]],
      company: ['', this.isUsaUser ? [Validators.required, Validators.pattern(PATTERNS.PAYEE_NAME_PATTERN)] : []],
      email: [this.isGuestUser ? sessionStorage.getItem("guestUserEmail") : '', [Validators.required, Validators.pattern(PATTERNS.EMAIL_PATTERN)]],
      countryCode: ['', this.isCanadaUser ? [Validators.pattern(/\d{1}/)] : (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required, Validators.pattern(/\d{1,3}/)] : [Validators.pattern(/\d{1}/)]],
      phone: ['', this.isCanadaUser ? [Validators.required, Validators.pattern(PATTERNS.CANADAPHONE_PATTERN)] : (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required, Validators.pattern(/\d{1,12}/)] : [Validators.required, Validators.pattern(PATTERNS.PHONE_PATTERN)]],
      addressLine1: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser)  ? [Validators.required] : [Validators.required, Validators.pattern(PATTERNS.ADDRESS_1_PATTERN)]],
      addressLine2: ['', (this.isUsaUser || this.isCanadaUser) ? Validators.pattern(PATTERNS.ADDRESS_2_PATTERN) : []],
      city: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required] : [Validators.required, Validators.pattern(PATTERNS.CITY_PATTERN)]],
      state: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [] : Validators.required],
      zip: ['', this.isCanadaUser ?  [Validators.required, Validators.pattern(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)] : this.isUkUser ? [Validators.required, Validators.pattern(/^[A-Za-z0-9\s]{2,8}$/)] : [Validators.required, Validators.pattern(PATTERNS.ZIP_PATTERN)]],
      country: [this.isCanadaUser ? 'Canada' : '', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ?  Validators.required : Validators.nullValidator],
    });
  }

  ngOnInit() {
    this.taggingService.view();
    this.routeObserverService.previousUrl$.subscribe(
      data => this.previousUrl = data
    );
    if (this.previousUrl == '/claims/new') {
      this.payeeService.recipientAddFlow.payeeFlow = "1";
    } else {
      this.payeeService.recipientAddFlow.payeeFlow = "2";
    }
    this.getMetaData();
  }
  // numberOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && charCode != 43 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;

  // }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31) {
        if (charCode < 48 || charCode > 57 || (charCode == 43)) {
          return false;
        }
      }
    return true;

  }
  public compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }

  onSubmit() {
    this.submitModel.userId = this.userDetails.objectUID;
    this.submitModel.createdByUserId = this.userDetails.userId;
    this.submitModel.policyNumber = this.userDetails.policyNumber;
    this.submitModel.policyCountry = this.policyInfo.policyCountry_Ext;
    this.submitModel.emailAddress = this.payeeForm.get('email').value;
    this.submitModel.payeeName = (this.isCanadaUser || this.isUkUser || this.isGermanyUser || this.isFranceUser || this.isItalyUser) ? this.payeeForm.get('firstName').value + ' ' + this.payeeForm.get('lastName').value : this.payeeForm.get('company').value;
    this.submitModel.domesticMailingAddress = {};
    this.submitModel.domesticPhoneNumber = (!this.isUsaUser) ? "+" + this.payeeForm.get('countryCode').value + " " + this.payeeForm.get('phone').value : this.payeeForm.get('phone').value.replace(/\./g, '');
    this.submitModel.domesticMailingAddress.streetAddress1 = this.payeeForm.get('addressLine1').value;
    this.submitModel.domesticMailingAddress.streetAddress2 = this.payeeForm.get('addressLine2').value;
    this.submitModel.domesticMailingAddress.city = this.payeeForm.get('city').value;
    this.submitModel.domesticMailingAddress.stateProvince = this.payeeForm.get('state').value ? this.payeeForm.get('state').value : "NA" ;
    this.submitModel.domesticMailingAddress.zip = this.payeeForm.get('zip').value;
    this.submitModel.guestAccessIndicator = this.userService.isGuestUser() ? "T" : "F";
    this.submitModel.domesticMailingAddress.country = this.isCanadaUser ? 'CA' : this.isItalyUser ?  this.payeeForm.get('country').value : this.isUkUser ? this.payeeForm.get('country').value : this.isGermanyUser ? this.payeeForm.get('country').value : this.isFranceUser ? this.payeeForm.get('country').value :'US',

    this.disableSubmit = true;

    this.userService.deniedPartyCheckV2(
      {
        company: this.submitModel.payeeName,
        address: this.submitModel.domesticMailingAddress.streetAddress1,
        city: this.submitModel.domesticMailingAddress.city,
        state: this.submitModel.domesticMailingAddress.stateProvince,
        country: this.isCanadaUser ? 'CA' : this.isItalyUser ? 'IT' : this.isUkUser ? 'GB' : this.isGermanyUser ? 'DE' : this.isFranceUser ? 'FR' :'US',
        zip: this.submitModel.domesticMailingAddress.zip
      }
    ).subscribe(
      deniedPartyFound => {
        if (!deniedPartyFound) {
          this.payeeService.createPayee(this.submitModel).subscribe(
            data => {
              this.payeeService.recentPayeeSubmission = { ...this.submitModel, ...{ payeeID: data.payeeID } }; //Store the recently created payee info so we can display it in the next screen
              this.sessionInfo.payeeId = data.payeeID;
              sessionStorage.setItem('selectedPayee', this.sessionInfo.payeeId);
              this.sessionInfo.returnSessionId = data.payeeID;
              //ONLY FOR US
              if(this.isUsaUser){
              this.payeeService.initiatePublicSessionTransfer(this.sessionInfo).subscribe(
                data => {
                  //Check if result.code is S031 for success
                  if (data.result.code == 'S031') {
                    this.payeeService.achURL = data.sessionTransferUrl;
                  } else {
                    //Send error notification to service, display error message instead of iframe
                    this.payeeService.initiateSessionFailed = true;
                  }
                  this.disableSubmit = false;
                  //Always reroute
                  this.router.navigate(['/claims/payee/banking']);
                }
              );
              }
              else{
                this.router.navigate(['/claims/payee/banking']);
              }
            }, error => {
              this.disableSubmit = false;
            }
          );
        } else {
          this.payeeService.dpsUnavailable = false;
          this.router.navigate(['/claims/payee/unable-to-fulfill']);
        }
      },
      err => {
        this.payeeService.dpsUnavailable = true;
        this.router.navigate(['/claims/payee/unable-to-fulfill']);
      }
    );
  }

  navBack() {   //Logic for 'Cancel' button
    if (this.previousUrl == '/claims/new') {
      sessionStorage.setItem('setCurrentStep', '0');
      this.router.navigate(['/claims/new']);
    } 
    else if(this.previousUrl == '/claims/MultiClaim') {
      sessionStorage.setItem('setCurrentStep', '0');
      this.router.navigate(['/claims/MultiClaim']);
    }
    else {
      this.router.navigate(['/claims/payee']);
    }
  }
  //Loads all MetaData for claims flow
  public getMetaData() {
    this.claimService.getMetaData().subscribe(
      data => {
        try {
          this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });
          this.countryList.sort(this.compare);
        } catch (e) {
          //Load local metadata
          //this.loadLocalMetaData();
        }
      }, error => {
        // Load local metadata
        //this.loadLocalMetaData();
      }
    );
  }

  tagging() {
    this.taggingService.link({ link_name: 'submit' });
  }
}
