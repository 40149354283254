<div class="popup-global-layout width-full p-4 p-md-5">
  <div *ngIf="checkViaMail">
    <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
    <div class="global-title">{{ 'payee.view.check' | translate }}</div>
    <div class="global-green-bar"></div>
    <div class="global-text pb-1">{{ 'payee.view.add' | translate }}</div>
    <div class="upsc-link add-bank" (click)="addBankInfo()">{{ 'payee.view.link' | translate }}</div>
  </div>
  <div *ngIf="!checkViaMail">
    <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
    <div class="global-title">{{ 'payee.view.title' | translate }}</div>
    <div class="global-green-bar"></div>
    <div class="global-text pb-1">{{ payee.payeeName }}</div>
    <div class="global-text">****{{ ccDigits }}</div>
    <div class="red" *ngIf="systemError">{{ 'payee.view.error' | translate }}</div>
  </div>
</div>