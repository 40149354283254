import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][upscClaimMask]'
})
export class ClaimMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  //Claim number mask to add -'s
  onInputChange(event){
    let newVal = event.replace(/\D/g, '');
    if(newVal.length === 0){
      newVal = '';
    } else if(newVal.length <= 3){
      newVal = newVal;
    } else if(newVal.length <= 5){
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
    } else if(newVal.length <=11){
      newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,6})/, '$1-$2-$3');
    } else {
      newVal = newVal.substring(0, 11);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,6})/, '$1-$2-$3');
    }
    this.ngControl.valueAccessor.writeValue(newVal);
  }

}
