<div class="upsc-custom-adjuster-dashboard-payee">
  <div class="main">
    <form [formGroup]="payeeForm">
      <div class="mb-3">
        <span class="heading">{{ 'adjuster.policy.heading' | translate }}</span>
      </div>
      <div class="field-row">
        <div class="field-label">
          <span class="labelstyle">{{'adjuster.policy.number' | translate}}</span><br>
          <span class="labelSubText">{{'adjuster.policy.version' | translate}}</span>
        </div>
        <div>
          <mat-form-field class="form-spacing">
            <input matInput formControlName='policy' maxLength='25'>
            <mat-error *ngIf="payeeForm.controls['policy'].hasError('required')">
              {{'userProfile.payee.errors.required' | translate}}
            </mat-error>
            <mat-error *ngIf="payeeForm.controls['policy'].hasError('pattern')">
              {{'userProfile.payee.errors.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="hasPayeeError || hasBankingError" class="error-message">
        {{'notifications.payee.danger' | translate}}
      </div>
      <div *ngIf="systemError" class="error-message">
        {{ 'common.systemError' | translate }}
      </div>
      <div class="d-flex justify-content-around mt-sm-3">
        <div>
          <button class="next-step" role="button" type="button" [disabled]="false" (click)="startOver($event)">
            {{'userProfile.payee.buttons.restart' | translate}}
          </button>
        </div>
        <div>
          <button role="button" type="button" [disabled]="!canSavePayee" (click)="openDPAT()">
            {{'userProfile.payee.buttons.save' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>