<div class="content popup-global-layout p-4 p-md-5">
    <div class="title-close"><mat-icon class="icon" (click)="decline()">close</mat-icon></div>
    <div class="title-text">Terms Of Agreement</div>
    <div class="global-green-bar"></div>
    <div>
        <div class="inner-text">{{'myChoice.landingPage.termsOfAgreement.para1' | translate }}</div>
    </div>
    <div>
        <div class="inner-text">{{'myChoice.landingPage.termsOfAgreement.para2' | translate }}</div>
    </div>
    <div>
        <div class="inner-text">{{'myChoice.landingPage.termsOfAgreement.para3' | translate }}</div>
    </div>
    <div>
        <div class="inner-text">{{'myChoice.landingPage.termsOfAgreement.para4' | translate }}</div>
    </div>
    <div class="global-button-format">
        <button type="button" class="upsc-white-button2" (click)="decline()"><mat-icon
                class="icon-key">keyboard_arrow_left</mat-icon>Decline</button>
        <button type="button" class="upsc-green-popup-button" (click)="accept()">Accept<mat-icon
                class="icon-key2">keyboard_arrow_right</mat-icon></button>
    </div>
</div>