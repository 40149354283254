import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { LoginResolver } from './services/login.resolver';
import { LogoutResolver } from './services/logout.resolver';
import { EnvironmentService } from './services/environment.service';
import { HttpClientService } from './services/http/http-client.service';
import { ApiEndpointService } from './services/api-endpoint.service';

/**
 * This module is used to import singleton modules/services.
 * This includes HttpModule and other modules providing you services ONLY.
 * If a new instance of an imported module should be initialized every time a corresponding component/directive/pipe is injected,
 * use shared.module.ts instead.
 */
@NgModule({
  imports: [
    BrowserAnimationsModule,
  ],
  declarations: [],
  providers: [
    AuthService,
    AuthGuardService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    LoginResolver,
    LogoutResolver,
    EnvironmentService,
    HttpClientService,
    ApiEndpointService,
  ],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
