<div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
  <div class="spinner-border">
    <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>
<div *ngIf="authError">Authentication error!</div>
<div class="error ml-3" *ngIf="systemError">{{ 'common.systemError' | translate }}</div>
<!-- <div class="wrapper-tupss" *ngIf="!showSpinner && !authError">
  <div class="d-flex flex-column-reverse flex-md-row" *ngIf="tupssType=='tfcp'">
    <div class="left">
      <div class="title">{{ 'franchiseclaimportal.intro' | translate }}</div>
      <div class="subtitle p-3">{{ 'franchiseclaimportal.intro1' | translate }}</div>
      <form [formGroup]="franchiseForm" (keyup.enter)="franchiseForm.valid">
        <div class="myclaims-info">
          <div class="p-3" style="max-width: 75%">
            <div class="field">
              <div class="pb-2">{{ 'customerclaimsportal.form.tracking' | translate }}</div>
              <mat-form-field class="w-100">
                <input matInput formControlName="trackingNumber">
                <mat-error *ngIf="franchiseForm.controls['trackingNumber'].errors">
                  <div *ngIf="franchiseForm.get('trackingNumber').errors?.required">
                    {{ 'claims.edit.start.tracking.form.trackingNumber.errors.required' | translate }}
                  </div>
                  <div *ngIf="franchiseForm.controls['trackingNumber'].errors?.pattern">
                    {{ 'claims.edit.start.tracking.form.trackingNumber.errors.pattern' | translate }}
                  </div>
                  <div *ngIf="franchiseForm.get('trackingNumber').errors['inuse']">
                    <span>{{ 'claims.edit.start.tracking.form.trackingNumber.errors.inuse1' | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="pl-3" *ngIf="errors && errors.length > 0">
            <upsc-notification-box class="error-box" [isDanger]="true">
              <span class="icon"><mat-icon>cancel_outline</mat-icon></span>
              <span class="errors">
                {{ errors[0] | translate }}
              </span>
            </upsc-notification-box>
          </div>
          <div class="error ml-3" *ngIf="systemError">{{ 'common.systemError' | translate }}</div>
          <button class="upsc-button ml-3" [disabled]="!franchiseForm.valid" (click)="nextStep()" href="#">
            {{ 'claims.edit.beginNewClaim' | translate }}
          </button>
        </div>
      </form>
    </div>
    <div class="right d-none d-md-block mb-md-0">
        <div class="side-image">
          <img src="assets/images/gettyimages-607975305-2048x2048.png">
        </div>
        <div class="side-bg d-flex justify-content-center align-items-center">
        <div class="process">
          <div class="intro mb-3">{{ 'customerclaimsportal.claimintro' | translate }}</div>
          <div class="step">
            <div class="step-icon">1</div>
            {{ 'customerclaimsportal.step1' | translate }}
          </div>
          <div class="step">
            <div class="step-icon">2</div>
            {{ 'customerclaimsportal.step2' | translate }}
          </div>
          <div class="step">
            <div class="step-icon">3</div>
            {{ 'customerclaimsportal.step3' | translate }}
          </div>
          <div class="step">
            <div class="step-icon">4</div>
            {{ 'customerclaimsportal.step4' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->