import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TaggingService } from 'src/app/tags/tagging.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'upsc-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  prospectPortalData;
  pageLoading = true;

  constructor(
    private router: Router,
    private taggingService: TaggingService
  ) {
    this.gatherSessionData();
  }

  ngOnInit() {
    this.taggingService.view({
      page_id: "policy_details_page",
      page_name: "Policy Details"
    });
  }

  ngOnDestroy() {
    sessionStorage.removeItem('prospectPortalData');
  }

  gatherSessionData() {
    this.prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData'));
    this.pageLoading = false;
  }

  navigate() {
    this.taggingService.link({
      link_name: "Create your InsureShield Button",
      link_page_id: "policy_details_page"
    });
    window.open(`${environment.baseUrl.web}login`, '_blank');
  }
}
