<div>
  <div class="main claim-list-wrapper">
    <div class="claim-info" *ngIf="!isTupssUser">
      <div class="quick-insights" *ngIf="isUsaUser && isLayout && !isMobile">
        <b class="claim-activity">{{ 'claims.list.component.toolbar.recent' | translate }}</b>
        <div *ngIf="isUsaUser && !isDDUser">
          <a class="insights-link" href="#" (click)="toggleStats($event)">
            <div *ngIf="!showClaimStats" class="insight-format">
              {{ 'claims.list.component.insights.show' | translate }}
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
            <div *ngIf="showClaimStats" class="insight-format">
              {{ 'claims.list.component.insights.hide' | translate }}
              <mat-icon>keyboard_arrow_up</mat-icon>
            </div>
          </a>
        </div>
      </div>
      <div class="insights-slide-toggle" *ngIf="isUsaUser && !isDDUser && isLayout && isMobile">
        <div class="d-flex align-items-center justify-content-center pt-3">
          <div class="pr-2">{{ 'claims.list.component.insights.quick' | translate }}</div>
          <div class="margin"><mat-slide-toggle color="primary" [(ngModel)]="showClaimStats"
              (change)="resetInsights()"></mat-slide-toggle></div>
        </div>
      </div>
      <div class="quick-insights" *ngIf="!isUsaUser && isLayout && !isMobile">
        <b class="claim-activity">{{ 'claims.list.component.toolbar.recent' | translate }}</b>
      </div>
    </div>
    <div class="tupss-header" *ngIf="isTupssUser">
      {{ 'dashboard.ClaimsActivity.PolicyType' | translate }}
      <span [innerHTML]="'franchiseclaimportal.theUpsStore' | translate"></span> #{{tupssSiteId}}
    </div>
    <!-- Quick Insights -->
    <div *ngIf="isUsaUser && !isDDUser" [ngStyle]="{ display: showClaimStats ? 'block' : 'none' }" class="card-spacing">
      <div *ngIf="claimStats | async as stats" class="quick-insights-wrapper pt-1">
        <div class="first-two-cards">
          <!-- Total Claims -->
          <div class="cards total">
            <div class="card-body">
              <img class="img-format" src="../../../assets/images/submit-new-claim.png">
              <div class="number-text">{{stats.claimsCnt}}</div>
              <div class="title-text">Total Claims</div>
            </div>
          </div>
          <!-- Resolved -->
          <div class="cards" [ngClass]="{disableClick : apiInProgress}" (click)="newCardClick('resolved')">
            <div class="flip-card">
              <div class="flip-card-inner" [ngClass]="{flip_animation : resolvedActive}">
                <div class="flip-card-front">
                  <div class="card-body">
                    <img class="img-format" src="../../../assets/images/resolved-claims.png">
                    <div class="number-text">{{stats.closedClaimsCnt}}</div>
                    <div class="title-text">Resolved</div>
                  </div>
                </div>
                <div class="flip-card-back">
                  <div class="card-body resolved">
                    <span class="resolved-text mt-1"><span
                        class="bold mr-1">{{stats.closedClaimsCnt}}</span>Resolved</span>
                    <div class="chart-style">
                      <canvas baseChart
                        [datasets]="[{ data: [stats.paidClaimsCnt, stats.unpaidClaimsCnt], backgroundColor: ['#00857d', '#ededed'] }]"
                        [labels]="insightLabels" [options]="insightOption" [type]="'doughnut'" [height]="100"
                        [width]="250" (chartHover)="chartHovered($event)">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="last-two-cards">
          <!-- In Progress -->
          <div class="cards" [ngClass]="{disableClick : apiInProgress}" (click)="newCardClick('in-progress')">
            <div class="flip-card">
              <div class="flip-card-inner" [ngClass]="{flip_animation : inProgressActive}">
                <div class="flip-card-front">
                  <div class="card-body">
                    <img class="img-format" src="../../../assets/images/in-progress-claims.png">
                    <div class="number-text">{{stats.claimsInProgCnt}}</div>
                    <div class="title-text">In Progress</div>
                  </div>
                </div>
                <div class="flip-card-back">
                  <div class="card-body padding-10">
                    <div class="text">You currently have</div>
                    <div class="number-text center">{{stats.claimsWithPendingDocCnt}}</div>
                    <div class="text">claims in progress.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Documents Pending -->
          <div class="cards" [ngClass]="{disableClick : apiInProgress}" (click)="newCardClick('documents-pending')">
            <div class="flip-card">
              <div class="flip-card-inner" [ngClass]="{flip_animation : docPendingActive}">
                <div class="flip-card-front">
                  <div class="card-body">
                    <img class="img-format" src="../../../assets/images/docpending-insights.png">
                    <div class="number-text">{{stats.claimsWithPendingDocCnt}}</div>
                    <div class="title-text">Additional Documentation Needed</div>
                  </div>
                </div>
                <div class="flip-card-back">
                  <div class="card-body padding-10">
                    <div class="text">{{ 'claims.list.component.insights.pendingDoc1' | translate }}</div>
                    <div class="number-text center">{{stats.claimsWithPendingDocCnt}}</div>
                    <div class="text">{{ 'claims.list.component.insights.pendingDoc2' | translate }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- toolbar desktop start -->
    <div class="toolbar-desktop" *ngIf="isLayout && !isMobile">
      <div class="toolbar-items">
        <div class="search-category">
          <mat-form-field class="custom-width testing2">
            <mat-label>{{ 'claims.list.component.toolbar.category' | translate }}</mat-label>
            <mat-select [formControl]="searchTypeControl" [disableOptionCentering]="true"
              panelClass="search-bar-spacing" (openedChange)="handleOpenChange()">
              <mat-option *ngFor="let option of newClaimsSelect" [value]="option.value">{{ option.label }}</mat-option>
            </mat-select>
            <mat-icon matSuffix *ngIf="!panelIsOpen">keyboard_arrow_down</mat-icon>
            <mat-icon matSuffix *ngIf="panelIsOpen">keyboard_arrow_up</mat-icon>
          </mat-form-field>
        </div>
        <div class="search-bar">
          <div class="err-container">
            <mat-form-field class="custom-width" [floatLabel]="'never'">
              <input matInput (keyup)="trim($event)" [formControl]="searchByValueControl"
                placeholder="{{searchBarPlaceholder}}">
              <mat-icon matPrefix class="custom-margin pointer" #searchButton upscDebounce [debounceTime]="1000"
                (debounceClick)="search('default')" (click)="markedAsTouched()">search</mat-icon>
              <mat-icon matSuffix class="pointer" (click)="reset()" *ngIf="isEnabled">close</mat-icon>
              <mat-error>
                <div *ngIf="searchByValueControl.invalid && searchByValueControl.touched" class="mat-error">
                  <div *ngIf="searchByValueControl.errors.required" class="d-flex align-items-center errors">

                    <div class="error-input" *ngIf="isClaimSearch">
                      <mat-icon>report_problem</mat-icon>
                      <div class="error-text"> {{ 'claims.list.component.toolbar.errors.claimsRequired' | translate }}
                      </div>
                    </div>

                    <div class="error-input" *ngIf="isACHSearch">
                      <mat-icon>report_problem</mat-icon>
                      <div class="error-text">{{ 'claims.list.component.toolbar.errors.achRequired' | translate }}</div>
                    </div>

                    <div class="error-input" *ngIf="isTrackingSearch">
                      <mat-icon>report_problem</mat-icon>
                      <div class="error-text"> {{ 'claims.list.component.toolbar.errors.trackingRequired' | translate }}
                      </div>
                    </div>

                    <div class="error-input" *ngIf="isSubmissionNumberSearch && !isTupssUser">
                      <mat-icon>report_problem</mat-icon>
                      <div class="error-text">{{ 'claims.list.component.toolbar.errors.submissionRequired' | translate
                        }}</div>
                    </div>

                    <!--  <div *ngIf="isClaimSearch">
                    {{ 'claims.list.component.toolbar.errors.claimsRequired' | translate }}
                  </div>
                  <div *ngIf="isACHSearch">
                    {{ 'claims.list.component.toolbar.errors.achRequired' | translate }}
                  </div>
                  <div *ngIf="isTrackingSearch">
                    {{ 'claims.list.component.toolbar.errors.trackingRequired' | translate }}
                  </div>
                  <div *ngIf="isSubmissionNumberSearch && !isTupssUser">
                    {{ 'claims.list.component.toolbar.errors.submissionRequired' | translate }}
                  </div> -->
                  </div>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="search-button">
          <button type="button" class="upsc-button" #searchButton upscDebounce [debounceTime]="1000"
            (debounceClick)="search('default')" (click)="markedAsTouched()">
            {{ 'claims.list.component.toolbar.search' | translate }}<mat-icon
              class="icon-key">keyboard_arrow_right</mat-icon>
          </button>
        </div>
        <div class="reset-button pr-2">
          <a class="upsc-link" (click)="removeFilterBubbles('all')" *ngIf="searchIsActive">{{
            'claims.list.component.toolbar.resetAll' | translate }}</a>
        </div>
        <div class="last-three-items">
          <div class="item-format" (click)="openAdvanced()" *ngIf="!isGuestUser">
            <img src="../../../../assets/images/filter_alt.png">
            <div class="pl-1">{{ 'claims.list.component.toolbar.advanced' | translate }}</div>
          </div>
          <div class="item-format pl-1" *ngIf="isUsaUser && isLayout && !isTupssUser && (userPermission.isFileClaim)"
            (click)="openColumnSettings()">
            <img src="../../../../assets/images/view_comfy.png">
            <div class="pl-2">{{ 'claims.list.component.toolbar.column' | translate }}</div>
          </div>
          <div class="item-format pl-1" *ngIf="isPagination" (click)="openExport()">
            <img src="../../../../assets/images/download_icon.png">
            <div class="pl-1">{{ 'claims.list.component.toolbar.download' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="advanced-filter-bubbles" *ngIf="isLayout && !isMobile">
      <ul class="active-bubbles">
        <li class="d-inline-flex justify-content-start align-items-center" *ngIf="dateFilterEnabled">
          <img src="../../../../assets/images/calendar_month.png" class="mr-2">
          <div>{{dateFilterDisplay}}</div>
          <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('date')">clear</mat-icon>
        </li>
        <li class="d-inline-flex justify-content-start align-items-center" *ngIf="amountFilterEnabled">
          <img src="../../../../assets/images/attach_money.png" class="mr-1">
          <div>{{amountFilterDisplay}}</div>
          <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('amount')">clear</mat-icon>
        </li>
        <li class="d-inline-flex justify-content-start align-items-center" *ngIf="statusFilterEnabled">
          <img src="../../../../assets/images/status_desc.png" class="mr-2">
          <div>{{statusFilterDisplay}}</div>
          <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('status')">clear</mat-icon>
        </li>
        <li class="d-inline-flex justify-content-start align-items-center" *ngIf="receiverFilterEnabled">
          <img src="../../../../assets/images/status_desc.png" class="mr-2">
          <div>{{receiverFilterDisplay}}</div>
          <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('receiver')">clear</mat-icon>
        </li>
        <li class="d-inline-flex justify-content-start align-items-center" *ngIf="accountFilterEnabled">
          <img src="../../../../assets/images/status_desc.png" class="mr-2">
          <div>{{accountFilterDisplay}}</div>
          <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('account')">clear</mat-icon>
        </li>
        <li class="d-inline-flex justify-content-start align-items-center" *ngIf="shipperNameFilterEnabled">
          <img src="../../../../assets/images/status_desc.png" class="mr-2">
          <div>{{shipperNameFilterDisplay}}</div>
          <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('shipperName')">clear</mat-icon>
        </li>
        <li class="d-inline-flex justify-content-start align-items-center" *ngIf="shipperNumberFilterEnabled">
          <img src="../../../../assets/images/status_desc.png" class="mr-2">
          <div>{{shipperNumberFilterDisplay}}</div>
          <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('shipperNumber')">clear</mat-icon>
        </li>
      </ul>
    </div>
    <!-- toolbar desktop end -->
    <!-- toolbar + navigation bar mobile start -->
    <div *ngIf="isLayout && isMobile">
      <div class="isop-navigation d-flex align-items-center justify-content-between" *ngIf="isUsaUser && !isTupssUser">
        <ul class="navigation-items">
          <li class="navigation-item">
            <a [ngClass]="{active : isActive === true}" (click)="toggleActive('active')">{{
              'claims.list.component.toolbar.active' | translate }}</a>
          </li>
          <li class="navigation-item" *ngIf="isUsaUser">
            <a [ngClass]="{active : isActive === false}" (click)="toggleActive('drafts')">{{
              'claims.list.component.toolbar.drafts' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="toolbar-mobile">
        <div class="d-flex justify-content-between custom" *ngIf="isActive"> <!-- active claims -->
          <div class="text">{{ 'claims.list.component.toolbar.recent' | translate }}</div>
          <div class="d-flex">
            <div class="format1 cursor"><mat-icon class="white-icon" (click)="openSearch()">search</mat-icon></div>
            <div class="format2 divider"></div>
            <div class="cursor"><img class="white" (click)="openExport()"
                src="../../../../assets/images/download_icon.png"></div>
          </div>
        </div>
        <div class="d-flex" *ngIf="!isActive"> <!-- draft claims -->
          <div class="text mr-auto">{{ 'claims.list.component.toolbar.drafts' | translate }}</div>
        </div>
      </div>
      <div class="toolbar-mobile-dropdown" *ngIf="isSearch">
        <div class="toolbar-items">
          <div class="search-category">
            <mat-form-field class="custom-width">
              <mat-label>{{ 'claims.list.component.toolbar.category' | translate }}</mat-label>
              <mat-select [formControl]="searchTypeControl" [disableOptionCentering]="true"
                panelClass="search-bar-spacing" (openedChange)="handleOpenChange()">
                <mat-option *ngFor="let option of newClaimsSelect" [value]="option.value">{{ option.label
                  }}</mat-option>
              </mat-select>
              <mat-icon matSuffix *ngIf="!panelIsOpen">keyboard_arrow_down</mat-icon>
              <mat-icon matSuffix *ngIf="panelIsOpen">keyboard_arrow_up</mat-icon>
            </mat-form-field>
          </div>
          <div class="search-bar">
            <div class="err-container">
              <mat-form-field class="custom-width" [floatLabel]="'never'">
                <input matInput (keyup)="trim($event)" name="searchQuery"
                  [formControl]="searchByValueControl" placeholder="{{searchBarPlaceholder}}">
                <mat-icon matPrefix class="custom-margin pointer" #searchButton upscDebounce [debounceTime]="1000"
                  (debounceClick)="search('default')" (click)="markedAsTouched()">search</mat-icon>
                <mat-icon matSuffix class="pointer" (click)="reset()" *ngIf="isEnabled">close</mat-icon>
                <mat-error>
                  <div *ngIf="searchByValueControl.invalid && searchByValueControl.touched" class="mat-error">
                    <div *ngIf="searchByValueControl.errors.required" class="d-flex align-items-center errors">
                      <div class="error-input" *ngIf="isClaimSearch">
                        <mat-icon>report_problem</mat-icon>
                        <div class="error-text"> {{ 'claims.list.component.toolbar.errors.claimsRequired' | translate }}
                        </div>
                      </div>

                      <div class="error-input" *ngIf="isACHSearch">
                        <mat-icon>report_problem</mat-icon>
                        <div class="error-text">{{ 'claims.list.component.toolbar.errors.achRequired' | translate }}
                        </div>
                      </div>

                      <div class="error-input" *ngIf="isTrackingSearch">
                        <mat-icon>report_problem</mat-icon>
                        <div class="error-text"> {{ 'claims.list.component.toolbar.errors.trackingRequired' | translate
                          }}</div>
                      </div>

                      <div class="error-input" *ngIf="isSubmissionNumberSearch && !isTupssUser">
                        <mat-icon>report_problem</mat-icon>
                        <div class="error-text">{{ 'claims.list.component.toolbar.errors.submissionRequired' | translate
                          }}</div>
                      </div>
                    </div>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="search-button pr-1">
            <button type="button" class="upsc-button" #searchButton upscDebounce [debounceTime]="1000"
              (debounceClick)="search('default')" (click)="markedAsTouched()">
              {{ 'claims.list.component.toolbar.search' | translate }}<mat-icon
                class="icon-key">keyboard_arrow_right</mat-icon>
            </button>
          </div>
          <div *ngIf="searchIsActive">
            <a class="upsc-link" (click)="removeFilterBubbles('all')">{{ 'claims.list.component.toolbar.resetAll' |
              translate }}</a>
          </div>
          <div class="item-format2" (click)="openAdvanced()">
            <img src="../../../../assets/images/filter_alt.png">
            <div class="pl-1">{{ 'claims.list.component.toolbar.advanced' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="advanced-filter-bubbles" *ngIf="isLayout && isMobile && isSearch">
        <ul class="active-bubbles">
          <li class="d-inline-flex justify-content-start align-items-center" *ngIf="dateFilterEnabled">
            <img src="../../../../assets/images/calendar_month.png" class="mr-2">
            <div>{{dateFilterDisplay}}</div>
            <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('date')">clear</mat-icon>
          </li>
          <li class="d-inline-flex justify-content-start align-items-center" *ngIf="amountFilterEnabled">
            <img src="../../../../assets/images/attach_money.png" class="mr-1">
            <div>{{amountFilterDisplay}}</div>
            <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('amount')">clear</mat-icon>
          </li>
          <li class="d-inline-flex justify-content-start align-items-center" *ngIf="statusFilterEnabled">
            <img src="../../../../assets/images/status_desc.png" class="mr-2">
            <div>{{statusFilterDisplay}}</div>
            <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('status')">clear</mat-icon>
          </li>
          <li class="d-inline-flex justify-content-start align-items-center" *ngIf="receiverFilterEnabled">
            <img src="../../../../assets/images/status_desc.png" class="mr-2">
            <div>{{receiverFilterDisplay}}</div>
            <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('receiver')">clear</mat-icon>
          </li>
          <li class="d-inline-flex justify-content-start align-items-center" *ngIf="accountFilterEnabled">
            <img src="../../../../assets/images/status_desc.png" class="mr-2">
            <div>{{accountFilterDisplay}}</div>
            <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('account')">clear</mat-icon>
          </li>
          <li class="d-inline-flex justify-content-start align-items-center" *ngIf="shipperNameFilterEnabled">
            <img src="../../../../assets/images/status_desc.png" class="mr-2">
            <div>{{shipperNameFilterDisplay}}</div>
            <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('shipperName')">clear</mat-icon>
          </li>
          <li class="d-inline-flex justify-content-start align-items-center" *ngIf="shipperNumberFilterEnabled">
            <img src="../../../../assets/images/status_desc.png" class="mr-2">
            <div>{{shipperNumberFilterDisplay}}</div>
            <mat-icon class="delete-icon ml-2" (click)="removeFilterBubbles('shipperNumber')">clear</mat-icon>
          </li>
        </ul>
      </div>
    </div>
    <!-- toolbar + navigation bar mobile ends here -->
    <upsc-notification-box *ngIf="!isDDUser && claimStats | async as stats"
      [ngStyle]="{display: (hasDocPending && stats.claimsWithPendingDocCnt > 0) ? 'block' : 'none'}" [isWarning]="true"
      class="mb-3">
      <div class="d-flex">
        <div class="outer-circle">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-exclamation-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
          </svg>
        </div>
        <div class="ml-2 ml-md-3">
          You currently have 1 open claims that require additional documentation.
          <a href="#" (click)="showDocPending($event)" [ngStyle]="{'color': 'inherit', 'text-decoration': 'underline'}">
            Review Claims Pending Documents</a>&nbsp;&gt;
        </div>
      </div>
    </upsc-notification-box>
    <div class="notification-box" *ngIf="emptySearchResult">
      <upsc-notification-box [isWarning]="true" *ngIf="emptyClaimResult == false && isLayout">
        <div class="box-format">
          <mat-icon>warning</mat-icon>
          <div class="text">{{ 'claims.list.component.toolbar.notification.noresults' | translate }}</div>
        </div>
      </upsc-notification-box>
      <upsc-notification-box [isWarning]="true" *ngIf="emptyClaimResult == false && !isLayout">
        <div class="box-format">
          <mat-icon>warning</mat-icon>
          <div class="text">{{ 'claims.list.component.toolbar.notification.noclaims' | translate }}</div>
        </div>
      </upsc-notification-box>
      <upsc-notification-box [isWarning]="true" *ngIf="emptyClaimResult">
        <div class="box-format">
          <mat-icon>warning</mat-icon>
          <div class="text">{{ 'claims.list.component.toolbar.notification.noclaims' | translate }}</div>
        </div>
      </upsc-notification-box>
    </div>
    <div class="error" *ngIf="systemError">{{ 'common.systemError' | translate }}</div>
    <!-- new table designs below -->
    <div *ngIf="loading" class="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="claims-container">
      <!-- Desktop -->
      <div class="desktop-claim-table" #table>
        <div class="overflow-format">
          <table class="upsc-table-isop">
            <tbody>
              <tr>
                <th [ngClass]="{'hidden': !policyNumber}" *ngIf="(isUsaUser && !isTupssUser && parentIndicater && EAffiliateUsers)"><span
                    *ngIf="policyNumber">{{
                    'claims.list.Claimstable.policyNumber' | translate }}</span>
                  <span class="scorll-img" (click)="toggleColumn('policy')"><img
                      src="../../../assets/images/right_panel_open.png"></span>
                </th> <!-- policy number -->
                <th [ngClass]="{'hidden': !shipperName}" *ngIf="(isUsaUser && !isTupssUser  && EAffiliateUsers)"><span
                    *ngIf="shipperName">{{
                    'claims.list.Claimstable.shipperName' | translate }} </span><span class="scorll-img"
                    (click)="toggleColumn('name')"><img src="../../../assets/images/right_panel_open.png"></span>
                </th> <!-- Shipper Name -->
                <th [ngClass]="{'hidden': !shipperNumber}" *ngIf="(isUsaUser && !isTupssUser  && EAffiliateUsers)"> <span
                    *ngIf="shipperNumber">{{
                    'claims.list.Claimstable.shipperNumber' | translate }} </span>
                  <span class="scorll-img" (click)="toggleColumn('number')"><img
                      src="../../../assets/images/right_panel_open.png"></span>
                </th> <!-- Shipper number -->
                <th>{{ 'claims.list.Claimstable.col1' | translate }}</th> <!-- claim number -->
                <th *ngIf="(isUsaUser && !isTupssUser) && tableObj.submissionNumber">{{
                  'claims.list.Claimstable.col12' | translate }}</th> <!-- submission number -->
                <th>{{ 'claims.list.Claimstable.col2' | translate }}</th> <!-- file date -->
                <th *ngIf="isUsaUser && tableObj.lossDate">{{ 'claims.list.Claimstable.lossDate' | translate }}
                </th> <!-- loss date -->
                <th *ngIf="tableObj.filedAmount">{{ 'claims.list.Claimstable.col8' | translate }}</th>
                <!-- filed claim amount -->
                <th *ngIf="tableObj.paidAmount">{{ 'claims.list.Claimstable.col4' | translate }}</th>
                <!-- paid amount -->
                <th *ngIf="isUsaUser && tableObj.declaredValue">{{ 'claims.list.Claimstable.dv' | translate }}
                </th> <!-- declared value -->
                <th *ngIf="tableObj.checkACHNumber">{{ 'claims.list.Claimstable.col9' | translate }}</th>
                <!-- check deposit number -->
                <th *ngIf="tableObj.checkACHIssueDate">{{ 'claims.list.Claimstable.col10' | translate }}</th>
                <!-- check deposit date -->
                <th *ngIf="tableObj.trackingNumber">{{ 'claims.list.Claimstable.col5' | translate }}</th>
                <!-- tracking number -->
                <th *ngIf="tableObj.referenceNumber">{{ 'claims.list.Claimstable.col6' | translate }}</th>
                <!-- reference number -->
                <th *ngIf="(isCanadaUser || isUsaUser) && tableObj.accountNumber">{{
                  'claims.list.Claimstable.col11' | translate }}</th> <!-- ups account number -->
                <th *ngIf="isUsaUser && tableObj.paymentRecipient">{{ 'claims.list.Claimstable.payee' | translate
                  }}</th> <!-- payment recipient -->
                <th *ngIf="isUsaUser && tableObj.adjuster">{{ 'claims.list.Claimstable.adjuster' | translate }}
                </th> <!-- adjuster name -->
                <th *ngIf="tableObj.receiver">{{ 'claims.list.Claimstable.col7' | translate }}</th>
                <!-- receiver -->
                <th *ngIf="isTupssUser">{{ 'claims.list.Claimstable.col13' | translate }}</th>
                <!-- portal location  -->
                <th>{{ 'claims.list.Claimstable.col3' | translate }}</th> <!-- status -->
              </tr>
              <tr *ngFor="let row of pagedItems; let i = index"
                [class.selected]="row.claimNumber && row.claimNumber === currentClaim">
                <ng-template [ngIf]="emptySearchResult && (i == 2 || i == 3)" [ngIfElse]="tds">
                </ng-template>
                <ng-template #tds>
                  <td *ngIf="(isUsaUser && !isTupssUser && parentIndicater && EAffiliateUsers)" [ngClass]="{'hidden': !policyNumber}">
                    {{ row.policyNumber }}
                  </td>
                  <td *ngIf="(isUsaUser && !isTupssUser && EAffiliateUsers)" [ngClass]="{'hidden': !shipperName}">{{row.shiperName }}
                  </td>
                  <td *ngIf="(isUsaUser && !isTupssUser && EAffiliateUsers)" [ngClass]="{'hidden': !shipperNumber}">{{row.shiperNumber | uppercase }}
                  </td>
                  <td>
                    <a class="link" (click)="claimDetail(row.claimNumber)">{{row.claimNumber}}</a>
                  </td>
                  <td *ngIf="(isUsaUser && !isTupssUser) && tableObj.submissionNumber">{{row.submissionNumber |
                    uppercase
                    }}
                  </td>
                  <td *ngIf="isUsaUser">{{row.fileDate | date: 'MM/dd/yy': 'UTC'}}</td>
                  <td *ngIf="(isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                    {{row.fileDate | date: 'dd/MM/yy': 'UTC'}}
                  </td>
                  <td *ngIf="isUsaUser && tableObj.lossDate">{{ row.lossDate | date: 'MM/dd/yy': 'UTC' }}</td>
                  <td *ngIf="row.filedClaimAmount_Currency=='usd' && tableObj.filedAmount">{{ row.filedClaimAmount |
                    currency }}</td>
                  <td *ngIf="row.filedClaimAmount_Currency=='cad' && tableObj.filedAmount">{{ row.filedClaimAmount |
                    currency: 'CAD' + ' ':'code' }}</td>
                  <td *ngIf="row.filedClaimAmount_Currency=='gbp' && tableObj.filedAmount">{{ row.filedClaimAmount |
                    currency:'GBP' }}</td>
                  <td *ngIf="row.filedClaimAmount_Currency=='eur' && tableObj.filedAmount">{{ row.filedClaimAmount |
                    currency:'EUR' }}</td>
                  <td *ngIf="row.filedClaimAmount_Currency!='usd'
                && row.filedClaimAmount_Currency!='cad' && row.filedClaimAmount_Currency!='gbp'
                && row.filedClaimAmount_Currency!='eur' && tableObj.filedAmount"> </td>
                  <td *ngIf="(row.currency=='usd') && (row.amount && row.amount!=0) && tableObj.paidAmount">{{
                    row.amount
                    |
                    currency }}</td>
                  <td *ngIf="(row.currency=='cad') && (row.amount && row.amount!=0) && tableObj.paidAmount">{{
                    row.amount
                    |
                    currency: 'CAD' + ' ':'code' }}</td>
                  <td *ngIf="(row.currency=='gbp') && (row.amount && row.amount!=0) && tableObj.paidAmount">{{
                    row.amount
                    |
                    currency:'GBP' }}</td>
                  <td *ngIf="(row.currency=='eur') && (row.amount && row.amount!=0) && tableObj.paidAmount">{{
                    row.amount
                    |
                    currency:'EUR' }}</td>
                  <td *ngIf="(!row.amount || row.amount==0) && tableObj.paidAmount"> </td>
                  <td *ngIf="isUsaUser && tableObj.declaredValue">{{ row.declaredValue | currency }}</td>
                  <!-- For claims paid out to the wallet, the checkAchNumber field looks something like this: "1-000-04-284516-1394301" where the '000-04-284516' part is the claim number and '1394301' is the payeeID that DPAT used on their end -->
                  <td *ngIf="tableObj.checkACHNumber && !(row?.checkAchNumber?.includes('-') && dwDetails?.walletID)">{{ row.checkAchNumber }}</td>
                  <td *ngIf="tableObj.checkACHNumber && row?.checkAchNumber?.includes('-') && dwDetails?.walletID">
                    <div class="d-flex align-items-center" *ngIf="canAccessDigitalWallet">
                      Paid to <span class="ml-1"><a class="digital-wallet-link" (click)="goToWallet()"><span>UPS<sup>®</sup> Wallet</span><span><mat-icon>launch</mat-icon></span></a></span>
                    </div>
                    <div *ngIf="!canAccessDigitalWallet">Paid to UPS<sup>®</sup> Wallet</div>
                  </td>
                  <td *ngIf="isUsaUser && tableObj.checkACHIssueDate">{{row.checkAchIssueDate | date: 'MM/dd/yy' :
                    'UTC'}}
                  </td>
                  <td
                    *ngIf="(isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser) && tableObj.checkACHIssueDate">
                    {{row.checkAchIssueDate | date: 'dd/MM/yy' : 'UTC'}}
                  </td>
                  <td *ngIf="tableObj.trackingNumber">{{row.tracking | uppercase }}</td>
                  <td *ngIf="tableObj.referenceNumber">{{row.reference | uppercase }}</td>
                  <td *ngIf="(isCanadaUser || isUsaUser) && tableObj.accountNumber">{{row.upsAccount | uppercase}}</td>
                  <td *ngIf="isUsaUser && tableObj.paymentRecipient">{{ row.paymentRecipient | titlecase }}</td>
                  <td *ngIf="isUsaUser && tableObj.adjuster">{{ row.adjuster | titlecase }}</td>
                  <td *ngIf="tableObj.receiver">{{row.receiver | titlecase }}</td>
                  <td *ngIf="isTupssUser && tableObj.claimSource">{{row.claimSource | titlecase }}</td>
                  <td *ngIf="(row.claimStatus!='Documents Pending')">
                    <div class="align-items-center">
                      <div class="pill" [ngClass]="
                  row.claimStatus == 'Claim Received' ? 'blue' :
                  row.claimStatus && row.claimStatus.includes('Closed') ? 'teal' :
                  row.claimStatus == 'Withdrawn' ? 'plum' :
                  row.claimStatus == 'Submitted for Payment' ? 'dark-blue' :
                  row.claimStatus == 'Incomplete Submission' ? 'orange' :
                  row.claimStatus == 'Investigation Pending' ? 'yellow' :
                  row.claimStatus == 'Documents Pending' ? 'red' :
                  row.claimStatus == 'Pending Delivery Confirmation' ? 'red' :
                  row.claimStatus == 'Paid' ? 'olive' :
                  row.claimStatus == 'Denied' ? 'gray' : 'none'">{{ row.claimStatusDisplay | titlecase }}</div>
                      <mat-icon
                        *ngIf="row.claimStatus == 'Denied' || (row.claimStatus && row.claimStatus.includes('Closed'))"
                        class="ml-3 float-right" ngbTooltip="{{row.statusDetails}}" triggers="click" [autoClose]="true"
                        tooltipClass="ngb-tooltip-class">info</mat-icon>
                    </div>
                  </td>
                  <td *ngIf="(row.claimStatus=='Documents Pending')">
                    <div class="wrap-pill">
                      <div class="pill red" (click)="claimDetail(row.claimNumber)">{{row.claimStatusDisplay | titlecase
                        }}
                      </div>
                    </div>
                  </td>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Mobile -->
      <div *ngIf="isActive && !emptySearchResult && !emptyClaimResult">
        <div class="mobile-claim-table" *ngFor="let row of pagedItems">
          <div class="claim-item">
            <div class="item-header m1">
              <div class="title-text mr-auto">{{row.claimNumber}}</div>
              <div class="pill" [ngClass]="
            row.claimStatus == 'Claim Received' ? 'blue' :
            row.claimStatus && row.claimStatus.includes('Closed') ? 'teal' :
            row.claimStatus == 'Withdrawn' ? 'plum' :
            row.claimStatus == 'Submitted for Payment' ? 'dark-blue' :
            row.claimStatus == 'Incomplete Submission' ? 'orange' :
            row.claimStatus == 'Investigation Pending' ? 'yellow' :
            row.claimStatus == 'Documents Pending' ? 'red' :
            row.claimStatus == 'Pending Delivery Confirmation' ? 'red' :
            row.claimStatus == 'Paid' ? 'olive' :
            row.claimStatus == 'Denied' ? 'gray' : 'none'">
                {{ row.claimStatusDisplay | titlecase }}
              </div>
              <mat-icon *ngIf="row.claimStatus == 'Denied' || (row.claimStatus && row.claimStatus.includes('Closed'))"
                class="ml-1 mt-1" ngbTooltip="{{row.statusDetails}}" tooltipClass="ngb-tooltip-class" container="body"
                triggers="click" [autoClose]="true" placement="auto">info</mat-icon>
            </div>
            <div class="item-row m3" *ngIf="(isUsaUser && !isTupssUser && parentIndicater)">
              <div class="item-text left">{{ 'claims.list.Claimstable.policyNumber' | translate }}</div>
              <div class="right">{{ row.policyNumber }} </div>
            </div>
            <div class="item-row m3" *ngIf="(isUsaUser && !isTupssUser)">
              <div class="item-text left">{{ 'claims.list.Claimstable.shipperName' | translate }}</div>
              <div class="right">{{row.shiperName }} </div>
            </div>
            <div class="item-row m3" *ngIf="(isUsaUser && !isTupssUser)">
              <div class="item-text left">{{ 'claims.list.Claimstable.shipperNumber' | translate }}</div>
              <div class="right"> {{row.shiperNumber | uppercase }}</div>
            </div>
            <div class="item-row m3" *ngIf="(isUsaUser && !isTupssUser) && tableObj.submissionNumber">
              <div class="item-text left">{{ 'claims.list.Claimstable.col12' | translate }}</div>
              <div class="right">{{row.submissionNumber | uppercase }}</div>
            </div>
            <div class="item-row m3">
              <div class="item-text left">{{ 'claims.list.Claimstable.col2' | translate }}</div>
              <div class="right" *ngIf="isUsaUser">
                {{ row.fileDate | date: 'MM/dd/yy': 'UTC'}}</div>
              <div class="right" *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                {{ row.fileDate | date: 'dd/MM/yy': 'UTC'}}</div>
            </div>
            <div class="item-row m3" *ngIf="isUsaUser && tableObj.lossDate">
              <div class="item-text left">{{ 'claims.list.Claimstable.lossDate' | translate }}</div>
              <div class="right" *ngIf="tableObj.lossDate">
                {{ row.lossDate | date: 'MM/dd/yy': 'UTC' }}</div>
            </div>
            <div class="item-row m3" *ngIf="tableObj.filedAmount">
              <div class="item-text left">{{ 'claims.list.Claimstable.col8' | translate }}</div>
              <div class="right" *ngIf="row.filedClaimAmount_Currency=='usd'">
                {{ row.filedClaimAmount | currency }}
              </div>
              <div class="right" *ngIf="row.filedClaimAmount_Currency=='cad'">
                {{ row.filedClaimAmount | currency: 'CAD' + ' ':'code' }}
              </div>
              <div class="right" *ngIf="row.filedClaimAmount_Currency=='gbp'">
                {{ row.filedClaimAmount | currency:'GBP' }}
              </div>
              <div class="right" *ngIf="row.filedClaimAmount_Currency=='eur'">
                {{ row.filedClaimAmount | currency:'EUR' }}
              </div>
            </div>
            <div class="item-row m3" *ngIf="tableObj.paidAmount">
              <div class="item-text left">{{ 'claims.list.Claimstable.col4' | translate }}</div>
              <div class="right" *ngIf="row.currency=='usd' && row.amount && row.amount > 0">
                {{ row.amount | currency }}
              </div>
              <div class="right" *ngIf="row.currency=='cad' && row.amount && row.amount > 0">
                {{ row.amount | currency: 'CAD' + ' ':'code' }}
              </div>
              <div class="right" *ngIf="row.currency=='gbp' && row.amount && row.amount > 0">
                {{ row.amount | currency:'GBP' }}
              </div>
              <div class="right" *ngIf="row.currency=='eur' && row.amount && row.amount > 0">
                {{ row.amount | currency:'EUR' }}
              </div>
              <div class="right" *ngIf="!row.amount || row.amount == 0"></div>
            </div>
            <div class="item-row m3" *ngIf="isUsaUser && tableObj.declaredValue">
              <div class="item-text left">{{ 'claims.list.Claimstable.dv' | translate }}</div>
              <div class="right">{{ row.declaredValue | currency }}</div>
            </div>
            <div class="item-row m3" *ngIf="tableObj.checkACHNumber">
              <div class="item-text left">{{ 'claims.list.Claimstable.col9' | translate }}</div>
              <div class="right" *ngIf="row.checkAchNumber && !(row?.checkAchNumber?.includes('-') && dwDetails?.walletID)">{{ row.checkAchNumber }}</div>
              <div class="right" *ngIf="row.checkAchNumber && row?.checkAchNumber?.includes('-') && dwDetails?.walletID">
                <div class="d-flex align-items-center" *ngIf="canAccessDigitalWallet">
                  Paid to <span class="ml-1"><a class="digital-wallet-link" (click)="goToWallet()"><span>UPS<sup>®</sup> Wallet</span><span><mat-icon>launch</mat-icon></span></a></span>
                </div>
                <div *ngIf="!canAccessDigitalWallet">Paid to UPS<sup>®</sup> Wallet</div>
              </div>
            </div>
            <div class="item-row m4" *ngIf="tableObj.checkACHIssueDate">
              <div class="item-text left">{{ 'claims.list.Claimstable.col10' | translate }}</div>
              <div class="right" *ngIf="isUsaUser">
                {{ row.checkAchIssueDate | date: 'MM/dd/yy': 'UTC' }}
              </div>
              <div class="right" *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                {{ row.checkAchIssueDate | date: 'dd/MM/yy': 'UTC' }}
              </div>
            </div>
            <div class="item-row m3" *ngIf="tableObj.trackingNumber">
              <div class="item-text left">{{ 'claims.list.Claimstable.col5' | translate }}</div>
              <div class="right">{{row.tracking | uppercase }}</div>
            </div>
            <div class="item-row m3" *ngIf="tableObj.referenceNumber">
              <div class="item-text left">{{ 'claims.list.Claimstable.col6' | translate }}</div>
              <div class="right">{{row.reference | uppercase }}</div>
            </div>
            <div class="item-row m3" *ngIf="(isCanadaUser || isUsaUser) && tableObj.accountNumber">
              <div class="item-text left">{{ 'claims.list.Claimstable.col11' | translate }}</div>
              <div class="right">{{row.upsAccount | uppercase}}</div>
            </div>
            <div class="item-row m3" *ngIf="isUsaUser && tableObj.paymentRecipient">
              <div class="item-text left">{{ 'claims.list.Claimstable.payee' | translate }}</div>
              <div class="right">{{ row.paymentRecipient | titlecase }}</div>
            </div>
            <div class="item-row m3" *ngIf="isUsaUser && tableObj.adjuster">
              <div class="item-text left">{{ 'claims.list.Claimstable.adjuster' | translate }}</div>
              <div class="right">{{ row.adjuster | titlecase }}</div>
            </div>
            <div class="item-row m3" *ngIf="tableObj.receiver">
              <div class="item-text left">{{ 'claims.list.Claimstable.col7' | translate }}</div>
              <div class="right">{{row.receiver | titlecase }}</div>
            </div>
            <div class="item-row m3" *ngIf="isTupssUser && tableObj.claimSource">
              <div class="item-text left">{{ 'claims.list.Claimstable.col13' | translate }}</div>
              <div class="right">{{ row.claimSource | titlecase }}</div>
            </div>
            <div class="item-footer">
              <a class="claim-link ml-auto" (click)="claimDetail(row.claimNumber)">See Claim Details<mat-icon
                  class="spacing">keyboard_arrow_right</mat-icon></a>
            </div>
          </div>
        </div>
        <div class="customize-button mt-3 mb-3" *ngIf="isMobile && isLayout">
          <button class="upsc-white-button"
            *ngIf="isUsaUser && isLayout && !isTupssUser && (userPermission.isFileClaim)"
            (click)="openColumnSettings()">{{ 'claims.list.component.toolbar.customize' | translate
            }}<mat-icon>keyboard_arrow_right</mat-icon></button>
        </div>
      </div>
      <div *ngIf="pager.currentPage>0 && pageSize>0  && isActive && !isMobile" class="desktop-claim-table">
        <div class="limit-1000 desktop" *ngIf="show1000Limit">{{ 'advancedSearch.claimsLimit' | translate }}</div>
        <upsc-list-pager [currentPage]="pager.currentPage" [pager]="pager" [pagePerAsset]="pageSize"
          (pagerNotification)="setPager($event)" (assetNotification)="loadAssetPerPage($event)"
          *ngIf="isPagination && !emptySearchResult"></upsc-list-pager>
      </div>
    </div>
    <div *ngIf="pager.currentPage>0 && pageSize>0  && isActive && isMobile" class="desktop-claim-table">
      <upsc-list-pager [currentPage]="pager.currentPage" [pager]="pager" [pagePerAsset]="pageSize"
        (pagerNotification)="setPager($event)" (assetNotification)="loadAssetPerPage($event)"
        *ngIf="isPagination && !emptySearchResult"></upsc-list-pager>
      <div class="limit-1000 mobile" *ngIf="show1000Limit">{{ 'advancedSearch.claimsLimit' | translate }}</div>
    </div>

    <upsc-draft-list [isPagination]="false" (onDraftLoad)="loadDraft($event)" (onDelete)="refreshDraft($event)"
      *ngIf="isDraft && isUsaUser && !isTupssUser && !isActive && isMobile"></upsc-draft-list>
    <upsc-draft-list [isPagination]="false" (onDraftLoad)="loadDraft($event)" (onDelete)="refreshDraft($event)"
      *ngIf="isDraft && isUsaUser && !isTupssUser && !isMobile"></upsc-draft-list>
    <div class="btnCenter">
      <button class="upsc-button" *ngIf="isUsaUser && isDraft && !isActive && isMobile && !isTupssUser"
        [routerLink]="['/claims/draft-list']">
        <div class="btn-text-icon">
          <div class="text"> {{ 'dashboard.ClaimsActivity.ViewAllDrafts' | translate
            }}</div><mat-icon>chevron_right</mat-icon>
        </div>
      </button>

      <button class="upsc-button" *ngIf="isUsaUser && isDraft && !isMobile && !isTupssUser"
        [routerLink]="['/claims/draft-list']">
        <div class="btn-text-icon">
          <div class="text"> {{ 'dashboard.ClaimsActivity.ViewAllDrafts' | translate
            }}</div><mat-icon>chevron_right</mat-icon>
        </div>
      </button>
    </div>
  </div>
