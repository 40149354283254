import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { NgbDropdown, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ClaimService } from 'src/app/services/claim.service';
import { UserService } from 'src/app/services/user.service';

export interface dateSearchResult {
  dateType: string;
  // searchValue: string;
  startDate: Date;
  endDate: Date;
}
@Component({
  selector: 'upsc-date-search-form',
  templateUrl: './date-search-form.component.html',
  styleUrls: ['./date-search-form.component.scss']
})
export class DateSearchFormComponent implements OnInit {
  @ViewChild(NgbDropdownMenu) drop: NgbDropdownMenu;
  @ViewChild('picker') startDatePicker: MatDatepicker<Date>
  @ViewChild('picker1') endDatePicker: MatDatepicker<Date>

  subscription: Subscription;
  autoClose: NgbDropdown['autoClose'] = false;

  dateSearchForm: UntypedFormGroup;
  applied = false;
  valueErrors = false;
  checkValue: boolean = false;
  checkbothDates: boolean = false;
  isSelected: boolean = false;
  isCustomRange: boolean = false;
  startdate: any;
  enddate: any;
  searchTypeD: any;
  searchByValueD: any;
  minDate: any;
  maxDate = new Date();
  defaultFormValues: any = {
    searchType: 'fileDate',
    searchByValue: '',
    startDate: '',
    endDate: ''
  };
  @Output() dateSearchResult = new EventEmitter<dateSearchResult>();
  isUsaUser: boolean;

  constructor(
    public claimservice: ClaimService,
    private userService: UserService,
    private dateAdapter: DateAdapter<any>, 
    private eRef: ElementRef,
    private fb: UntypedFormBuilder, private ref: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService

  ) {
    this.subscription = this.claimservice.getResetValue().subscribe(data => {
      if (data) {
        this.reset();
      }
    });
    this.dateSearchForm = this.fb.group({
      searchType: ['fileDate', Validators.required],
      searchByValue: ['', Validators.required],
      startDate: [{ value: '', disabled: true }, Validators.required],
      endDate: [{ value: '', disabled: true }, Validators.required],
    });
    this.isUsaUser = this.userService.isUsaUser();      
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('retainState')){
      if(sessionStorage.getItem('isadvanced') && sessionStorage.getItem('isSearchMade')){
        let dateSearchdata =  JSON.parse(sessionStorage.getItem('dateSearch'));
        if(dateSearchdata){
          this.applied = true;
          this.searchType.setValue(dateSearchdata.dateType ==  "File Date" ? "fileDate"  : "issueDate");
          this.searchByValue.setValue(dateSearchdata.searchCriteria);
          if(this.searchByValue.value == "Custom Date Range"){
            this.startDate.enable();  
            this.endDate.enable(); 
            this.startDate.setValue(dateSearchdata.startDate);
            this.endDate.setValue(dateSearchdata.endDate);
          }   
          else{
            this.startDate.setValue("");
            this.endDate.setValue("");
            this.startDate.disable();  
            this.endDate.disable(); 
          }
          const dateSearchResult = {
            dateType: this.searchType.value,
            startDate: dateSearchdata.startDate,
            endDate: dateSearchdata.endDate
          }
          const dateSearch  ={
            dateType: dateSearchdata.dateType,
            searchCriteria: dateSearchdata.searchCriteria,
            startDate: dateSearchdata.startDate,
            endDate: dateSearchdata.endDate
          }
          this.dateSearchResult.emit(dateSearchResult);
          sessionStorage.setItem('dateSearch', JSON.stringify(dateSearch))   
          this.searchTypeD =  dateSearchdata.dateType;
          this.searchByValueD = dateSearchdata.searchCriteria;
        }

      }
    }
    
      if(!this.isUsaUser){
        this.dateAdapter.setLocale('en-GB');
      }
    
  }

@HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else if (event.target.outerText > 0) {

    } else if (event.target.ariaLabel == "Next month" || event.target.ariaLabel == "Previous month") {

    } else {
      if (!this.startDatePicker.opened && !this.endDatePicker.opened) {
        //Close the dropdown as long as the datepickers aren't open
        this.drop.dropdown.close();
      }
    }
  }
  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
  apply() {
    const dateSearchResult = {
      dateType: null,
      startDate: null,
      endDate: null
    }
    if (this.checkVals()) {
      this.applied = true;
      this.searchTypeD = this.searchType.value == "fileDate" ? this.translateService.instant('metadata.claimStatusMapping.file_date') : this.translateService.instant('metadata.claimStatusMapping.check_ach');
      this.searchByValueD =  this.searchByValue.value == "Last 7 days" ? this.translateService.instant('metadata.claimStatusMapping.last7days') 
      : this.searchByValue.value == "Last 15 days" ? this.translateService.instant('metadata.claimStatusMapping.last15days') :
      this.searchByValue.value == "Last 30 days" ? this.translateService.instant('metadata.claimStatusMapping.last30days') :
      this.searchByValue.value == "Last 1 year" ? this.translateService.instant('metadata.claimStatusMapping.last1year') :
      this.translateService.instant('metadata.claimStatusMapping.customdate');
      const dateSearchResult = {
        dateType: this.searchType.value,
        startDate: this.startdate,
        endDate: this.enddate
      }
      const dateSearch  ={
        dateType: this.searchTypeD,
        searchCriteria: this.searchByValueD,
        startDate: this.startdate,
        endDate: this.enddate
      }
      this.dateSearchResult.emit(dateSearchResult);
      sessionStorage.setItem('dateSearch', JSON.stringify(dateSearch));
      this.valueErrors = false;
      this.isSelected = false;
      this.checkbothDates = false;
      this.checkValue = false;
      this.drop.dropdown.close();
      this.startdate = null;
      this.enddate = null;
    } else {
      this.valueErrors = true;
    }
  }
  
  dateChange($event){
  if (this.checkVals()) {
      this.applied = true;
      this.searchTypeD = this.searchType.value == "fileDate" ? this.translateService.instant('metadata.claimStatusMapping.file_date') : this.translateService.instant('metadata.claimStatusMapping.check_edd');
      this.searchByValueD = this.searchByValue.value == "Last 7 days" ? this.translateService.instant('metadata.claimStatusMapping.last7days') 
      : this.searchByValue.value == "Last 15 days" ? this.translateService.instant('metadata.claimStatusMapping.last15days') :
      this.searchByValue.value == "Last 30 days" ? this.translateService.instant('metadata.claimStatusMapping.last30days') :
      this.searchByValue.value == "Last 1 year" ? this.translateService.instant('metadata.claimStatusMapping.last1year') :
      this.translateService.instant('metadata.claimStatusMapping.customdate');
    const dateSearchResult = {
      dateType: this.searchType.value,
      startDate: this.startdate,
      endDate: this.enddate
    }
    const dateSearch  ={
      dateType: this.searchTypeD,
      searchCriteria: this.searchByValueD,
      startDate: this.startdate,
      endDate: this.enddate
    }
    this.dateSearchResult.emit(dateSearchResult);
    sessionStorage.setItem('dateSearch', JSON.stringify(dateSearch));
    this.valueErrors = false;
    this.isSelected = false;
    this.checkbothDates = false;
    this.checkValue = false;
    this.startdate = null;
    this.enddate = null;
  }
  else{
    this.applied = false;
  }
  }
  ngAfterViewInit() {
    this.isCustomRange = false;
    this.searchByValue.valueChanges.subscribe(
      val => {
        if (val == 'Custom Date Range') {         
            this.isCustomRange = true;   
            this.startDate.enable();  
            this.endDate.enable();     
        }
        else {
          this.startDate.setValue("");
          this.endDate.setValue("");
          this.startDate.disable();  
          this.endDate.disable(); 
          this.isCustomRange = false;
          this.isSelected = false;
          this.checkbothDates = false;
          this.checkValue = false;
        }
        this.ref.detectChanges();
      }
    );
  }
  handleMinDateChange($event){     
      if(this.endDate.touched && this.endDate.value != ""){
        if (this.checkVals()) {
          this.applied = true;
          this.searchTypeD = this.searchType.value == "fileDate" ? this.translateService.instant('metadata.claimStatusMapping.file_date') : this.translateService.instant('metadata.claimStatusMapping.check_edd');;
          this.searchByValueD = this.searchByValue.value == "Last 7 days" ? this.translateService.instant('metadata.claimStatusMapping.last7days') 
          : this.searchByValue.value == "Last 15 days" ? this.translateService.instant('metadata.claimStatusMapping.last15days') :
          this.searchByValue.value == "Last 30 days" ? this.translateService.instant('metadata.claimStatusMapping.last30days') :
          this.searchByValue.value == "Last 1 year" ? this.translateService.instant('metadata.claimStatusMapping.last1year') :
          this.translateService.instant('metadata.claimStatusMapping.customdate');
          const dateSearchResult = {
            dateType: this.searchType.value,
            startDate: this.startdate,
            endDate: this.enddate
          }
          const dateSearch  ={
            dateType: this.searchTypeD,
            searchCriteria: this.searchByValueD,
            startDate: this.startdate,
            endDate: this.enddate
          }
          this.dateSearchResult.emit(dateSearchResult);
          sessionStorage.setItem('dateSearch', JSON.stringify(dateSearch))
        }
        else{
          this.valueErrors = true;
          this.applied = false;

          sessionStorage.removeItem('dateSearch');
          this.dateSearchResult.emit();
        }
      
    }
      else{
        this.startdate = this.startDate.value;
      }  
    
  }
  handleMaxDateChange($event){     
      if (this.checkVals()) {
        this.applied = true;
        this.searchTypeD = this.searchType.value == "fileDate" ? this.translateService.instant('metadata.claimStatusMapping.file_date') : this.translateService.instant('metadata.claimStatusMapping.check_edd');
        this.searchByValueD = this.searchByValue.value == "Last 7 days" ? this.translateService.instant('metadata.claimStatusMapping.last7days') 
        : this.searchByValue.value == "Last 15 days" ? this.translateService.instant('metadata.claimStatusMapping.last15days') :
        this.searchByValue.value == "Last 30 days" ? this.translateService.instant('metadata.claimStatusMapping.last30days') :
        this.searchByValue.value == "Last 1 year" ? this.translateService.instant('metadata.claimStatusMapping.last1year') :
        this.translateService.instant('metadata.claimStatusMapping.customdate');
        const dateSearchResult = {
          dateType: this.searchType.value,
          startDate: this.startdate,
          endDate: this.enddate
        }
        const dateSearch  ={
          dateType: this.searchTypeD,
          searchCriteria: this.searchByValueD,
          startDate: this.startdate,
          endDate: this.enddate
        }
        this.dateSearchResult.emit(dateSearchResult);
        sessionStorage.setItem('dateSearch', JSON.stringify(dateSearch));
      }
      else{
        this.valueErrors = true;
        this.applied = false;
        this.dateSearchResult.emit();
        sessionStorage.removeItem('dateSearch');

      }
    
}

  cutoffDate;
  cutoffError: boolean = false;

  setCutoff() {
    if(this.startDate?.value && this.endDate?.value) {
      this.cutoffDate = new Date(this.endDate.value);
      this.cutoffDate.setDate(this.endDate.value.getDate() - 30)
    } else {
      this.cutoffDate = undefined;
    }
  }

  checkVals() {
    this.isSelected = false;
    this.checkbothDates = false;
    this.checkValue = false;
    this.cutoffError = false;
    if (this.searchByValue.value == 'Last 7 days') {
      this.enddate = new Date();
      this.startdate = new Date(this.enddate);
      this.startdate.setDate(this.startdate.getDate() - 7);
      return true;
    }
    else if (this.searchByValue.value == 'Last 15 days') {
      this.enddate = new Date();
      this.startdate = new Date(this.enddate);
      this.startdate.setDate(this.startdate.getDate() - 15);
      return true;
    }
    else if (this.searchByValue.value == 'Last 30 days') {
      this.enddate = new Date();
      this.startdate = new Date(this.enddate);
      this.startdate.setDate(this.startdate.getDate() - 30);
      return true;
    }
    else if (this.searchByValue.value == 'Last 1 year') {
      this.enddate = new Date();
      this.startdate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      return true;
    }
    else if (this.searchByValue.value == 'Custom Date Range') {
      this.setCutoff();
      if (this.startDate.value == "" && this.endDate.value == "") {
        this.isSelected = true;
        return false;
      }
      else if (this.startDate.value == "" || this.endDate.value == "") {
        this.checkbothDates = true;
        return false;
      }

      else if (this.endDate.value < this.startDate.value) {
        this.checkValue = true;
        return false;
      }

      else if (this.cutoffDate && (this.startDate.value < this.cutoffDate)) {
        this.cutoffError = true;
        return false;
      }
      else {
        this.startdate = this.startDate.value;
        this.enddate = this.endDate.value;
        return true;
      }
    }
    else if (this.searchType.value != null && !this.searchByValue.value != null) {
      return true;
    }
    else {
      return false;
    }
  }

  reset() {
    this.applied = false;
    this.dateSearchForm.reset(this.defaultFormValues);
    this.dateSearchResult.emit();
    this.drop.dropdown.close();
    sessionStorage.removeItem('dateSearch');

  }

  close() {
    this.valueErrors = false;
    this.drop.dropdown.close();
    this.reset();
  }

  get searchType() { return this.dateSearchForm.controls.searchType; }
  get searchByValue() { return this.dateSearchForm.controls.searchByValue; }
  get startDate() { return this.dateSearchForm.controls.startDate; }
  get endDate() { return this.dateSearchForm.controls.endDate; }
}
