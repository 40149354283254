import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { UserService } from 'src/app/services/user.service';
import { emptyIEmail, IEmail } from 'src/app/shared/models/email.interface';
import { EMAIL_PATTERN } from 'src/app/shared/regex-patterns';
import { environment as ENV } from '../../../environments/environment';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';

@Component({
  selector: 'upsc-guest-verification',
  templateUrl: './guest-verification.component.html',
  styleUrls: ['./guest-verification.component.scss']
})
export class GuestVerificationComponent implements OnInit {
  privacyLink = ENV.links.privacyNotice;
  techAgreementLink = ENV.links.techAgreement;
  canadaprivacyLink = ENV.links.canadaprivacyNotice;
  guestVerificationForm: UntypedFormGroup;
  showSuccess: boolean;
  showError: boolean;
  codeSent = false;
  apiInProgress = false;
  codeNotMatched: boolean = false;
  systemError: boolean = false;
  verificationInterface = { emailId: "" };
  private emailModel: IEmail = emptyIEmail;
  isCanadaUser: boolean;
  user = {
    "emailAddress": "",
  }

  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;

  verifyCodeInterface = { emailId: "", toVerify: "" };

  constructor(private router: Router,
    private userService: UserService,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder) {
    this.guestVerificationForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])],
      code: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
      agreement: ['', [Validators.required]]
    });
    this.isCanadaUser = this.userService.isCanadaUser();

  }

  ngOnInit(): void {
    this.showSuccess = false;
    this.showError = false;
  }
  //Send an email to user-entered email with a verification code
  public sendEmail(event) {
    this.user.emailAddress = this.guestVerificationForm.get('email').value.trim();
    this.createVerificationInterface.data.emailAddress = this.user.emailAddress;
    this.accountService.createVerificationCode(this.createVerificationInterface)
      .subscribe(
        res => {
          if ((res.responseMessage).toLowerCase() === 'new verification code created' ||
            (res.responseMessage).toLowerCase() === 'code not yet expired') {
            this.codeSent = true;
            this.apiInProgress = false;
            this.showSuccess = true;
          }          
        },
        err => {
          this.handleError();
        }
      );
  }

  public sendVerificationCode(event) {
    this.emailModel.data.email = this.user.emailAddress;
    this.emailModel.emailFor = "GuestAccessVerification";
    this.accountService.sendVerificationCodeEmail(this.emailModel).subscribe(
      data => {
        this.codeSent = true;
        this.apiInProgress = false;
        this.showSuccess = true;
      },
      error => {
        this.codeSent = false;
        this.handleError();
      }
    );
  }
  public checkVerificationCode(event) {
    this.codeNotMatched = false;
    let userInput = this.guestVerificationForm.get('code').value;
    sessionStorage.setItem('guestUserEmail', this.guestVerificationForm.get('email').value);
    let claimNumber = '';
    if (sessionStorage.getItem('GuestclaimNumber')) {
      claimNumber = sessionStorage.getItem("GuestclaimNumber")
    }

    this.verifyCodeInterface.emailId = this.user.emailAddress;
    this.verifyCodeInterface.toVerify = userInput;

    this.accountService.verifyCode(this.verifyCodeInterface).subscribe(
      data => {
        if ((data.message).toLowerCase() === 'verification code matched') {
          this.userService.guestUserLogin().subscribe(
            data => {
              if (data) {
                this.codeNotMatched = false;
                let landingUrl = '/claims/new';
                if (claimNumber != '') {
                  landingUrl = `/claims/detail?claimNumber=${claimNumber}`;
                }
                this.userService.loginForGuestUser(landingUrl);
              }
            },
            error => {
              //get verification code error
              this.handleError();
            }
          )
        }
        else {
          this.codeNotMatched = true;
          this.apiInProgress = false;
        }
      }, error => {
        //get verification code error
        this.handleError();
      }
    )
  }
  resendCode(event) {
    this.codeNotMatched = false;
    this.resetError();
    this.accountService.sendVerificationCodeEmail(this.emailModel).subscribe(
      data => {
        this.codeSent = true;
        this.apiInProgress = false;
      },
      error => {
        this.codeSent = false;
        this.handleError();
      }
    );
  }
  private resetError() {
    this.systemError = false;
    this.apiInProgress = true;
  }
  private handleError() {
    this.systemError = true;
    this.apiInProgress = false;
    this.codeNotMatched = false;
  }
}
