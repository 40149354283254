<div class="flex-wrapper">
  <div *ngIf="currentStep===1" class="banner">
    <img src="../../../../assets/images/flex-banner.png" />
  </div>
  <!-- MOBILE TOP BANNER CONTENT -->
  <div class="ups-component hero has-breadcrumbs" *ngIf="currentStep===1">
    <div class="ups-container">
      <div class="card ups-card">
        <div class="card-img"><img src="landingPageHeaderPic.png" alt="Woman packaging a box"
            title="Woman packaging a box" class="img-fluid" loading="lazy">
        </div>
        <div class="card-body">
          <div class="card-body-content">
            <h1 class="maj-head0">{{ 'app.flexComponent.landingTitleLine1' | translate }}<br>
              {{ 'app.flexComponent.landingTitleLine2' | translate }}</h1>
            <p class="maj-sub0">{{ 'app.flexComponent.landingSubTitleLine1' | translate }}<sup
                class="heading-sup">®</sup> {{ 'app.flexComponent.landingSubTitleLine2' | translate }}</p>

            <div class="mobile_top-content">
              {{ 'app.flexComponent.landingContentLine1' | translate }}<sup
                class="mobile_top-content__heading-sup">®</sup> {{ 'app.flexComponent.landingContentLine2' | translate
              }}
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- END OF MOBILE BANNER CONTENT -->
  <form [formGroup]="flexibleCoverageFormGroup" *ngIf="currentStep===1">
    <div class="form-container">
      <div class="white-on-gray">
        <div class="title">{{ 'app.flexComponent.formHeading' | translate }}</div>
        <div class="green-bar"></div>
        <div class="sub-title">{{ 'app.flexComponent.formSubHeading' | translate }}</div>
        <div class="required"><span class="star">*</span>{{ 'app.flexComponent.required' | translate }}</div>
        <div class="error-bar" *ngIf="formValidationError">
          <div class="warning"><mat-icon (click)="cancel()">warning</mat-icon></div>
          <div class="text">
            {{ 'app.flexComponent.errorBox' | translate }}
          </div>
          <div class="close-icon"><mat-icon (click)="cancel()">close</mat-icon></div>
        </div>
        <div class="section">
          <div class="subsection left">
            <mat-form-field>
              <input matInput required formControlName="jobTitle"
                placeholder="{{ 'app.flexComponent.jobTitle' | translate }}">
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('jobTitle').errors">
                <div *ngIf="flexibleCoverageFormGroup.get('jobTitle').errors['required']">
                  Job Title Required
                </div>
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="subsection">
            <mat-form-field>
              <input matInput required formControlName="companyName"
                placeholder="{{ 'app.flexComponent.companyName' | translate }}">
            </mat-form-field>
          </div>
        </div>
        <div class="section">
          <div class="subsection left">
            <mat-form-field>
              <input matInput required formControlName="upsAccountNumber"
                placeholder="{{ 'app.flexComponent.accountNumber' | translate }}">
            </mat-form-field>
          </div>
          <div class="subsection">

          </div>
        </div>
        <div class="section">
          <div class="subsection left">
            <mat-form-field>
              <input matInput required formControlName="upsStoreNumber"
                placeholder="{{ 'app.flexComponent.storeNo' | translate }}" maxlength="6"
                (keypress)="numberOnly($event)">
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('upsStoreNumber').errors">
                          <div *ngIf="flexibleCoverageFormGroup.get('upsStoreNumber').errors['required']">
                            Ups Store Number Required
                          </div>
                        </mat-error> -->
            </mat-form-field>
          </div>
          <div class="subsection">
            <mat-form-field>
              <div class="reenter__eye-style">
                <input matInput [type]="showPassword ? 'text' : 'password'" required
                  formControlName="upsStoreNumberReenter"
                  placeholder="{{ 'app.flexComponent.storeNoReEnter' | translate }}" maxlength="6"
                  (keypress)="numberOnly($event)">
                <img src="../../../../assets/images/eye-icon.svg" alt="show" (click)="showPassword = !showPassword"
                  [class.hide]="showPassword">
                <img src="../../../../assets/images/eye_slash-icon.svg" alt="hide"
                  (click)="showPassword = !showPassword" [class.hide]="!showPassword">
              </div>
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('upsStoreNumberReenter').errors">
                          <div *ngIf="flexibleCoverageFormGroup.get('upsStoreNumberReenter').errors['required']">
                            Confirm Ups Store Number
                          </div>
                        </mat-error> -->
            </mat-form-field>
          </div>
        </div>
        <div class="section">
          <div class="subsection left">
            <mat-form-field>
              <input matInput required formControlName="firstName"
                placeholder="{{ 'app.flexComponent.firstName' | translate }}">
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('firstName').errors">
                <div *ngIf="flexibleCoverageFormGroup.get('firstName').errors['required']">
                  First Name Required
                </div>
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="subsection">
            <mat-form-field>
              <input matInput required formControlName="lastName"
                placeholder="{{ 'app.flexComponent.lastName' | translate }}">
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('lastName').errors">
                <div *ngIf="flexibleCoverageFormGroup.get('lastName').errors['required']">
                  Last Name Required
                </div>
              </mat-error> -->
            </mat-form-field>
          </div>
        </div>
        <div class="section">
          <div class="subsection left">
            <mat-form-field>
              <input matInput required formControlName="emailAddress"
                placeholder="{{ 'app.flexComponent.email' | translate }}">
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('emailAddress').errors">
                <div *ngIf="flexibleCoverageFormGroup.get('emailAddress').errors['required']">
                  Email Address Required
                </div>
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="subsection">
            <mat-form-field>
              <input matInput required formControlName="phoneNumber"
                placeholder="{{ 'app.flexComponent.phone' | translate }}" (keypress)="numberOnly($event)" maxlength="12"
                upscPhoneNumber>
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('phoneNumber').errors">
                          <div *ngIf="flexibleCoverageFormGroup.get('phoneNumber').errors['required']">
                            Phone Number Required
                          </div>
                        </mat-error> -->
            </mat-form-field>
          </div>
        </div>
        <div class="section">
          <div class="subsection left">
            <mat-form-field>
              <input matInput required formControlName="addressLineOne"
                placeholder="{{ 'app.flexComponent.addrLine1' | translate }}">
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('addressLineOne').errors">
                <div *ngIf="flexibleCoverageFormGroup.get('addressLineOne').errors['required']">
                  Address Line 1 Required
                </div>
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="subsection">
            <mat-form-field>
              <input matInput formControlName="addressLineTwo"
                placeholder="{{ 'app.flexComponent.addrLine2' | translate }}">
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('addressLineTwo').errors">
                <div *ngIf="flexibleCoverageFormGroup.get('addressLineTwo').errors['required']">
                  Address Line 2 Required
                </div>
              </mat-error> -->
            </mat-form-field>
          </div>
        </div>
        <div class="section">
          <div class="subsection left">
            <mat-form-field>
              <input matInput required formControlName="city" placeholder="{{ 'app.flexComponent.city' | translate }}">
              <!-- <mat-error *ngIf="flexibleCoverageFormGroup.get('city').errors">
                <div *ngIf="flexibleCoverageFormGroup.get('city').errors['required']">
                  City Required
                </div>
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="subsection half">
            <div class="subsubsection left">
              <mat-form-field>
                <mat-select name="state" formControlName="state"
                  placeholder="{{ 'app.flexComponent.state' | translate }}" class="state-select">
                  <mat-option>{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.none' | translate }}
                  </mat-option>
                  <mat-option *ngFor="let state of stateList" [value]="state.state">{{state.state}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="subsubsection">
              <mat-form-field>
                <input matInput required formControlName="zipCode"
                  placeholder="{{ 'app.flexComponent.zipCode' | translate }}" (keypress)="numberOnly($event)"
                  maxlength="5">
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="terms-n-conditions">
          <mat-checkbox formControlName="cbTermsOfServices" class="green-background-checkbox size-20"
            (change)="showChecked($event)">
            {{ 'app.flexComponent.boxCheck1' | translate }} <a href="https://flexapp.upscapital.com/?g=TUPSS216"
              class="link" target="_blank">{{ 'app.flexComponent.boxCheckHyperlink1' | translate }}</a> {{
            'app.flexComponent.boxCheck2' | translate }}
            <a href="https://flexapp.upscapital.com/?g=TUPSS216" class="link">{{ 'app.flexComponent.boxCheckHyperlink2'
              | translate }}</a>.
          </mat-checkbox>
        </div>
        <mat-error class="standalone"
          *ngIf="flexibleCoverageFormGroup.get('cbTermsOfServices').errors?.required && flexibleCoverageFormGroup.controls['cbTermsOfServices']?.touched">
          {{ 'claims.edit.review.reviewAndSubmit.required' | translate }}</mat-error>
        <div class="tria">
          <div class="text">
            <img class="trivia_image" src="../../../../assets/images/health_and_safety.svg" />
            <div class="tria-election">{{ 'app.flexComponent.triaHeader' | translate }}</div>
            <div class="terms-n-conditions">
              <mat-slide-toggle formControlName="purchaseCoverage" class="toggle" color="primary"></mat-slide-toggle>
            </div>
          </div>
          <div class="text-sub">{{ 'app.flexComponent.triaContent' | translate }}
          </div>
        </div>
        <div class="button-container">
          <!-- <button type="button" class="upsc-white-button">Cancel</button> -->
          <button type="submit" class="upsc-button" (click)="validateAddress()">Continue
            <mat-icon>chevron_right</mat-icon></button>
        </div>
      </div>
    </div>
    <div class="red error" *ngIf="systemError">
      {{ 'common.systemError' | translate }}
    </div>
  </form>

  <div class="email-verification" *ngIf="currentStep===2">
    <div class="content">
      <upsc-email-verification class="otp__component" flow="general" [email]="emailAddress"
        (addPolicyResponse)="codeVerifiedResponse($event)"></upsc-email-verification>
      <upsc-otp-flexapp class="otp__Component-mobile" flow="general" [email]="emailAddress"
        (addPolicyResponse)="codeVerifiedResponse($event)"></upsc-otp-flexapp>
    </div>
  </div>

  <div *ngIf="currentStep===3">
    <div class="wrapper">
      <div class="title-bar"> {{ 'app.flexComponent.thankyouHeader' | translate }}</div>
      <div class="green-bar"></div>
      <div class="gray-box">
        <div class="your-contract-details"> {{ 'app.flexComponent.contractDetail' | translate }}</div>
        <div class="contract-number-title"> {{ 'app.flexComponent.contractNo' | translate }}</div>
        <div class="contract-number">{{policyNumber}}</div>
      </div>

      ` <div class="success-msg">{{ 'app.flexComponent.thankyouContent1' | translate }}<br /><br />
        {{ 'app.flexComponent.thankyouContent2' | translate }} <span class="phone">(866)-202-2511.</span><br /><br />
        {{ 'app.flexComponent.thankyouContent3' | translate }} <a class="phone" href="{{ ONLLink }}" target="_blank">{{
          'app.flexComponent.thankyouContent5' | translate }}
        </a> {{ 'app.flexComponent.thankyouContent4' | translate }}
      </div>
      <div class="button-container">
        <button type="submit" class="upsc-button__submit">
          <a href="{{ ONLLink }}" target="_blank" class="text">{{ 'app.flexComponent.createLogin' | translate }}</a>
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="res-error" *ngIf="currentStep===4">
    <div class="noProcess">
      <h2 class="noProcess__comm-head">
        {{ 'app.flexComponent.unableProcessHeader' | translate }}</h2>
    </div>
    <div class="noProcess-para">
      {{ 'app.flexComponent.unableProcessContent' | translate }}
    </div>
    <div class="noProcess-retry__button bus_btn">
      <button type="submit" class="upsc-button__retry" (click)="cancelFromVerification()"> {{
        'app.flexComponent.tryAgain' | translate }} ></button>
    </div>
  </div>
</div>