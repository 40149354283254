<div class="layout-wrapper" *ngIf="!pageLoading && !systemError">
  <div class="wrapper">
    <div class="title">Your Quote is Ready!</div>
    <div class="green-bar"></div>
    <div class="description">
      Based on the details you provided about {{ prospectPortalData.customerCompany }}, we think this policy will best suit your business. Please review and accept the policy below.
    </div>
    <div class="card">
      <div class="header">Your Quote</div>
      <hr>
      <div class="line">
        <div class="left">
          <div class="top">Policy Dates</div>
          <div class="bottom">{{ upcomingSunday | date:'MM/dd/yyyy' }} - {{ expirationDate | date:'MM/dd/yyyy' }}</div>
        </div>
        <div class="right">
          <div class="top">Email</div>
          <div class="bottom">{{ prospectPortalData.customerEmail }}</div>
        </div>
      </div>
      <hr>
      <div class="line">
        <div class="left">
          <div class="top">InsureShield<sup>&reg;</sup> Protection</div>
          <div class="bottom">
            <ul>
              <li>24/7 Claims Portal</li>
              <li>Loss Coverage</li>
              <li>Damage Coverage</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="bottom">{{ prospectPortalData.rate | currency }}</div>
        </div>
      </div>
      <hr>
      <div class="bottom-right">
        {{ prospectPortalData.rate | currency }}
      </div>
    </div>
    <div class="card">
      <div class="header">Insurance Summary</div>
      <hr>
      <div class="line-row">
        <div class="left">Insurance Rate:</div>
        <div class="right">{{ prospectPortalData.rate | currency }} per $100 for shipments</div>
      </div>
      <div class="line-row">
        <div class="left">Coverage:</div>
        <div class="right">The insured may purchase insurance coverage on a per-shipment (transactional) basis for shipments.</div>
      </div>
      <div class="line-row">
        <div class="left">Limit of Liability:</div>
        <div class="right">
          <div class="top">
            $150,000 per any one domestic occurrence.
            You’re also approved for up to $250,000 on any one export or import occurrence.
          </div>
          <div class="bottom mt-3">
            <table class="upsc-table">
              <tr class="header">
                <th>Transportation Mode</th>
                <th>Limits</th>
              </tr>
              <tr>
                <td class="bold">USPS</td>
                <td>$1,000</td>
              </tr>
              <tr>
                <td class="bold">Express Carrier Small Package</td>
                <td>$50,000</td>
              </tr>
              <tr>
                <td class="bold">Ground Freight</td>
                <td>$150,000</td>
              </tr>
              <tr>
                <td class="bold">Air Freight</td>
                <td>$150,000</td>
              </tr>
              <tr>
                <td class="bold">Ocean Freight</td>
                <td>$250,000</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="line-row">
        <div class="left">Deductible:</div>
        <div class="right">10%</div>
      </div>
      <div class="line-row">
        <div class="left">Insurance Company:</div>
        <div class="right">National Union Fire Insurance Company of Pittsburgh, PA.</div>
      </div>
      <div class="line-row">
        <div class="left">Other:</div>
        <div class="right">Subject to all terms, conditions, limitations and exclusions of your issued insurance policy, including as to policy attachment and cancellation. Please review the policy in its entirety upon issuance and contact your UPS Capital representative with any questions.</div>
      </div>
      <div class="bottom-terms">
        *By clicking Accept Policy, I confirm that I have read and agree to the <a href="https://www.insureshield.com/us/en/legal/terms-and-conditions-upscia.html" target="_blank" rel="noopener noreferrer" class="upsc-link">UPS Capital Terms of Service</a> and <a href="https://www.insureshield.com/us/en/legal/technology-agreement.html" target="_blank" rel="noopener noreferrer" class="upsc-link">Technology Agreement</a> and acknowldge the <a href="https://www.insureshield.com/us/en/legal/privacy-notice.html" target="_blank" rel="noopener noreferrer" class="upsc-link">Privacy Notice</a>. I authorize UPS Capital to send communications to me at the email address I provided to UPS Capital.
      </div>
    </div>
    <div class="bottom-nav">
      <button class="upsc-secondary-button icon-left" (click)="decline()">
        <mat-icon>chevron_left</mat-icon>Decline
      </button>
      <button class="upsc-primary-button icon-right ml-3" *ngIf="!submitting" (click)="submit()">
        Accept Quote<mat-icon>chevron_right</mat-icon>
      </button>
      <button class="upsc-primary-button icon-right ml-3" disabled *ngIf="submitting">
        Accept Quote
        <div class="spinner-border" role="status">
          <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
        </div>
      </button>
    </div>
    <div class="system-error" *ngIf="submissionError">
      Something went wrong. Please try again later.
    </div>
  </div>
</div>

<div class="system-error" *ngIf="systemError">
  Something went wrong. Please try again later.
</div>

<div class="spinner d-flex justify-content-center" *ngIf="pageLoading">
  <div class="spinner-border">
      <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>