import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { PagerService } from 'src/app/services/pager.service';
import { PolicyEventService } from 'src/app/services/policy-event.service';
import { PolicyService } from 'src/app/services/policy.service';
import { SubPolicyList } from './model/sub-policy-list';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { RemoveUserConfirmationComponent } from 'src/app/pages/manage-users/components/remove-user-confirmation/remove-user-confirmation.component';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'upsc-subPolicies',
  templateUrl: './sub-policies.component.html',
  styleUrls: ['./sub-policies.component.scss']
})
export class SubPoliciesComponent {

  //Display helpers
  pageLoading = true;
  systemError = false;

  //Data holders
  userDetails;
  userIDs;
  policyInfo;

  allUserIds: string[];
  pageSize: number = 5;
  pager: any = {};
  pageIds: string[] = [];
  pagedItems: any[] = [];
  isDataPresent: boolean = false;
  subPolicyList: SubPolicyList[] = [];
  searchPolicyList: SubPolicyList[] = [];
  isLoading: boolean = false;
  dialogRef: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private pagerService: PagerService,
    private policyEventService: PolicyEventService,
    private policySerice: PolicyService,
    private loginService: LoginService, 
    public dialog: MatDialog
  ) {
      this.userDetails = this.userService.getUserInfo();
      this.policyInfo = this.loginService.getPolicyDetails();
      sessionStorage.removeItem('level2policy'); //just in case there is leftover data
  }

  ngOnInit(): void {
    this.getUserIDs();
  }

  getUserIDs() {
    this.systemError = false;
    this.pageLoading = true;
    this.allUserIds = this.policyEventService.getChildPolicies();
    this.getChildPolicyInfo();
  }

  getChildPolicyInfo() {
    this.isLoading = true;
    this.subPolicyList = [];

    let payload = {
      parentPolicyNumber: this.policyInfo.policyNumber
    }
    this.policySerice.getChildPolicyInfo(payload).subscribe(data => {
      let srno = 1;
      let idsPage: string[] = [];
      data.data.forEach(element => {
        let subPolicy = new SubPolicyList();
        subPolicy.srno = srno;
        subPolicy.userId = element.userID;
        subPolicy.customerName = element.policyHoldersName;
        subPolicy.policyNumber = element.policyNumber;
        subPolicy.notifications = element.claimNotifications == true ? 'Claims' : 'None';
        subPolicy.email = element.emailAddress;
        subPolicy.role = element.role;
        subPolicy.status = element.status;
        subPolicy.userName = element.contactName;
        subPolicy.objectUID = element.objectUID !== undefined ? element.objectUID : null;
        this.subPolicyList.push(subPolicy);
        idsPage.push(srno.toString());
        srno = srno + 1;
      });
      this.setNewPage(1, idsPage);
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  setNewPage(page: number, idsPage: any) {
    this.pagedItems.splice(0, this.pagedItems.length);
    this.pager = this.pagerService.getPager(idsPage.length, page, this.pageSize);
    let pageIds = [];
    pageIds = idsPage.slice(this.pager.startIndex, this.pager.endIndex + 1);
    if (pageIds.length > 0) {
      pageIds.forEach(pageId => {
        var pageItem = this.subPolicyList.find(x => x.srno == pageId);
        if (pageItem) {
          this.pagedItems.push(pageItem);
        }
      });
    }
    this.pageIds = idsPage;
  }

  setPager(event) {
    this.isLoading = true;
    var currentPage = Number(event.toString());
    this.setNewPage(currentPage, this.pageIds);
    this.isLoading = false;
  }

  loadAssetPerPage(event) {
    this.pageSize = Number(event.toString());
    this.setNewPage(1, this.pageIds);
  }

  setSearchPage(page: number, idsPage: any) {
    this.pagedItems.splice(0, this.pagedItems.length);
    this.pager = this.pagerService.getPager(idsPage.length, page, this.pageSize);
    let pageIds = [];
    pageIds = idsPage.slice(this.pager.startIndex, this.pager.endIndex + 1);
    if (pageIds.length > 0) {
      pageIds.forEach(pageId => {
        var pageItem = this.searchPolicyList.find(x => x.srno == pageId);
        if (pageItem) {
          this.pagedItems.push(pageItem);
        }
      });
    }
    this.pageIds = idsPage;
  }

  notifySearch(event) {
    this.searchPolicyList = [];
    let searchRecords = [];
    if (event.searchType === "email") {
      searchRecords = this.subPolicyList.filter(it => {
        return it.email.toLocaleLowerCase().includes(event.searchValue);
      });
    }
    else {
      searchRecords = this.subPolicyList.filter(it => {
        return it.userName.toLocaleLowerCase().includes(event.searchValue);
      });
    }
    this.filterSearchRecords(searchRecords);
  }

  filterSearchRecords(searchRecords: any) {
    let idsPage: string[] = [];
    let srno = 1;
    if (searchRecords !== undefined && searchRecords.length > 0) {
      searchRecords.forEach(element => {
        let subPolicy = new SubPolicyList();
        subPolicy.srno = srno;
        subPolicy.userId = element.userID;
        subPolicy.customerName = element.customerName;
        subPolicy.policyNumber = element.policyNumber;
        subPolicy.notifications = element.notifications;
        subPolicy.email = element.email;
        subPolicy.role = element.role;
        subPolicy.status = element.status;
        subPolicy.userName = element.userName;
        subPolicy.objectUID = element.objectUID !== undefined ? element.objectUID : null;
        this.searchPolicyList.push(subPolicy);
        idsPage.push(srno.toString());
        srno = srno + 1;
      });
      this.setSearchPage(1, idsPage);
      this.isLoading = false;
    }
    else {
      this.pagedItems = [];
      this.setSearchPage(1, idsPage);
    }
  }

  notifyReset(event) {
    this.searchPolicyList.splice(0, this.searchPolicyList.length);
    this.getChildPolicyInfo();
  }

  deleteRegularUser(row: any) {
    let deleteUserRequest = {
      userID: row.userId,
      userEmailID: row.email,
      adminsEmail: this.userService.getUserInfo().emailAddress,
      countryLocale: sessionStorage.getItem('locale'),
      policyNumber: this.userDetails.policyNumber
    };

    this.userService.deleteUser(deleteUserRequest).subscribe(
      data => {
        if (data && data.success === 'true') {
          var loggedInUserId = this.userService.getUserInfo().userId;
          var deletedUserId = deleteUserRequest.userID;
          if (loggedInUserId === deletedUserId) {
            //this.logout();
          } else {
            this.getChildPolicyInfo();
          }
        } else {
          //check for not allowed
          this.systemError = true;
          this.getChildPolicyInfo();
        }
      },
      error => {
        this.pageLoading = false;
        this.systemError = true;
      }
    );
  }

  delete(row: any) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '715px';

    this.dialogRef = this.dialog.open(RemoveUserConfirmationComponent, config).afterClosed()
      .subscribe(
        result => {
          if (result === "Delete") {
            this.deleteUser(row);
          }
        }
      );
  }

  deleteUser(row: any) {
    if (row.objectUID !== undefined && row.objectUID !== null && row.objectUID.length > 1) {
      this.deleteRegularUser(row);
    } else {
      let request = {
        tempUserId: row.userId,
        policyNumber: this.userDetails.policyNumber,
        email: row.email
      }
      this.userService.deleteInvitedUser(request).subscribe(
        data => {
          if (data && data.message === 'User Deleted') {
            this.getChildPolicyInfo();
          } else {
            //check for not allowed
            this.systemError = true;
            this.getChildPolicyInfo();
          }
        },
        error => {
          this.pageLoading = false;
          this.systemError = true;
        }
      );
    }
  }

  editUser(user, policy) {
    sessionStorage.setItem('edit-sub', JSON.stringify(user));
    this.router.navigate(['/manage-users/add-sub-policy'], { queryParams: { userId: user.userId, policy: policy } });
  }

}
