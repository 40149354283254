import { UserService } from '../../../services/user.service';
import { Component, HostBinding, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'upsc-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})

export class LoginHeaderComponent {
  isCanadaUser: boolean;
  constructor(private userService: UserService) {
    
  }

  public ngOnInit() {
    this.isCanadaUser = this.userService.isCanadaUser();
  }

  isPageInit: boolean = false;
  ngAfterContentInit() {
    this.isPageInit = true;   
  }

  public ngOnDestroy() {
    
  }
}
