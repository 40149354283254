import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IUpdate } from '../models/update.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrordialogComponent } from '../../../shared/components/errordialog/errordialog.component';
import { UtilityService } from '../../../services/utility.service';
import { AccountService } from 'src/app/services/account.service';
import { UserService } from 'src/app/services/user.service';
import { TaggingService } from 'src/app/tags/tagging.service';

@Component({
  selector: 'upsc-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  public update: IUpdate = {};
  formGroup: UntypedFormGroup;
  ChangePasswordForm: UntypedFormGroup;
  public worngPassword: boolean = false;
  showErrorMessages: boolean = false;
  passwordNotMatch: boolean = false;
  resetSucceeded: boolean = false;
  public isCanadaUser: boolean;

  constructor(private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private loginServive: LoginService,
    private userService: UserService,
    public dialog: MatDialog,
    private utilService: UtilityService,
    private taggingService: TaggingService
  ) {
    this.tooltipPosition$ = this.utilService.tooltipPositionWatch();
    this.isCanadaUser = this.userService.isCanadaUser();
  }
  @Output() isCancel = new EventEmitter<boolean>();
  public hide: boolean = true;
  public hide2: boolean = true;
  public hide3: boolean = true;
  public tooltipPosition$: any;

  ngOnInit() {
    this.ChangePasswordForm = this.fb.group({
      userId: [{value: sessionStorage.getItem('userid'), disabled: true}, Validators.nullValidator],
      currentPassword: ['', [
        Validators.required,this.isCanadaUser ? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : (this.userService.isUkUser() || this.userService.isFranceUser() || this.userService.isGermanyUser() || this.userService.isItalyUser())? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{12,45}$/) : Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{8,45}$/)
        
      ]],
      newPassword: ['', [
        Validators.required, this.isCanadaUser ? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : (this.userService.isUkUser() || this.userService.isCanadaUser() || this.userService.isFranceUser() || this.userService.isGermanyUser() || this.userService.isItalyUser() || this.userService.isUsaUser())? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/)
        
      ]],
      confirmPassword: ['', [
        Validators.required, this.isCanadaUser ? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : (this.userService.isUkUser() || this.userService.isCanadaUser() || this.userService.isFranceUser() || this.userService.isGermanyUser() || this.userService.isItalyUser() || this.userService.isUsaUser())? 
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/)
        
      ]]
    });

    this.ChangePasswordForm.statusChanges.subscribe(
      status => {
        if(status == "VALID"){
          this.showErrorMessages= false;
        }
      }
    )
  }

  errorDialog() {
    let dialogRef = this.dialog.open(ErrordialogComponent);
    dialogRef.afterClosed().subscribe(result => {});
  }

  /* public passwordMatchValidator(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      } else {
        return passwordConfirmationInput.setErrors({ notEquivalent: null });
      }
    }
  } */

  public passwordMatchValidator(passwordKey: string, passwordConfirmationKey: string) {
      let passwordInput = this.ChangePasswordForm.controls[passwordKey],
        passwordConfirmationInput = this.ChangePasswordForm.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return false;
      } else {
        return true;
      }
  }

  get userid(): any {
    return sessionStorage.getItem('userid');
  }

  ChangePassword() {
    this.taggingService.link({ link_name: 'update'});
    this.ChangePasswordForm.controls.currentPassword.markAsTouched();
    this.ChangePasswordForm.controls.newPassword.markAsTouched();
    this.ChangePasswordForm.controls.confirmPassword.markAsTouched();
    if(!this.ChangePasswordForm.valid) {
      this.showErrorMessages = true;
      return;
    }
    else {
      this.showErrorMessages = false;
    }
    
    if(!this.passwordMatchValidator('newPassword', 'confirmPassword')) {
      this.passwordNotMatch = true;
      return;
    }
    else {
      this.passwordNotMatch = false;
    }

    this.update.Username = sessionStorage.getItem('userid');
    this.update.currentPassword = this.ChangePasswordForm.get('currentPassword').value;
    this.update.newPassword = this.ChangePasswordForm.get('newPassword').value;
    this.update.userRoleID = this.userService.getUserInfo().userRoleID;
    this.accountService.changePassword(this.update).subscribe(
      data => {
        this.loginServive.removeLocalStorage();
        this.worngPassword = false
        this.resetSucceeded = true;
        this.ChangePasswordForm.reset();
      },
      error => {
        this.worngPassword = true;
        this.resetSucceeded = false;
      });
  }
  public cancel() {
    this.isCancel.emit(true);
  }
}
