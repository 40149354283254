import { Component, OnDestroy, OnInit } from '@angular/core';
import { PayeeService } from 'src/app/services/payee.service';
import { sessionInfo, iCreatePayee, payeeInfo } from '../../../../../../shared/models/payee.interface';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from '../../../../../../services/user.service';

@Component({
  selector: 'upsc-banking',
  templateUrl: './banking.component.html',
  styleUrls: ['./banking.component.scss']
})
export class BankingComponent implements OnInit, OnDestroy {

  //API Interfaces
  sessionInfo: sessionInfo = {};
  recentPayeeData;
  defaultPayeeData: payeeInfo = {};
  payeeID: string;

  //HTML Helpers
  showNotification = false;
  dangerNotification = this.payeeService.initiateSessionFailed;
  achurl = this.payeeService.achURL;  //iFrame srcURL
  showDefaultPayeeInfo = false;
  isAdjuster = false;
  isCanadaUser: boolean;
  isEuroUser: boolean;
  hvcmFlow: boolean;
  payeeClaimFlowText = false;

  showWarning = false;

  constructor(
    private payeeService: PayeeService,
    private router: Router,
    private userService: UserService
  ) {
    //Determine which payee details to show. Green notification box vs normal div
    if (Object.keys(this.payeeService.recentPayeeSubmission).length) {
      this.recentPayeeData = this.payeeService.recentPayeeSubmission;
      this.payeeID = this.recentPayeeData.payeeID;
      this.showNotification = true;
    } else if (Object.keys(this.payeeService.displayPreexistingPayee).length) {
      this.defaultPayeeData = this.payeeService.displayPreexistingPayee;
      this.payeeID = this.defaultPayeeData.payeeID;
      this.showNotification = false;
      this.showDefaultPayeeInfo = true;
    } else {
      this.showDefaultPayeeInfo = false;
      this.showNotification = false;
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(data => {
      //Listen for route changes, and reset the displayed payee info when trying to navigate
      if (this.router.url != '/claims/payee/banking') {
        this.payeeService.recentPayeeSubmission = {};
        this.payeeService.displayPreexistingPayee = {};
      }
    });
    this.isAdjuster = this.userService.isUserAdjuster();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isEuroUser = this.userService.isFranceUser() || this.userService.isGermanyUser() || this.userService.isItalyUser() || this.userService.isUkUser();
    this.hvcmFlow = this.userService.isHVCMFlow;
    if(this.payeeService.recipientAddFlow.payeeFlow == "1")
    {
      this.payeeClaimFlowText = true;
    }
  }

  ngOnInit() {
    try{
      let chromeVersion = navigator.appVersion.match(/.?Chrome\/\d+/);
      if(chromeVersion && chromeVersion.length > 0) {
        let versionParts = chromeVersion[0].split('/');
        if(versionParts.length > 1 && versionParts[1] > '85') {
          this.showWarning = true;
        }
      }
    } 
    catch(e) { }
  }
  backtoClaim() {
    if(this.payeeClaimFlowText)
    {
      this.router.navigate(['/claims/new']);
      sessionStorage.setItem('setCurrentStep', '0');
    }
    else
    {
      this.router.navigate(['/claims/payee']);
    }
  }

  backOrCancel(action: string) {
    if(action == 'back')
    {
      sessionStorage.setItem('setCurrentStep', '0');
    }
    else
    {
      sessionStorage.setItem('setCurrentStep', '0');
    }
    this.router.navigate(['/claims/MultiClaim']);
  }

  openHVCM() {
    this.userService.openHVCM(
      {
        policyNo: this.userService.userInfo.policyNumber,
        payeeID: this.payeeID,
        username: this.userService.userInfo.firstName,
        firstName: this.userService.userInfo.firstName,
        lastName: this.userService.userInfo.lastName,
        emailAddress: this.userService.userInfo.emailAddress,
        contactPhone: this.userService.userInfo.contactPhone
      }
    )
  }

  ngOnDestroy() {
    //only triggers an API call for us users
    if(!this.isCanadaUser && !this.isEuroUser) {
      this.payeeService.activePayeeMonitor.next(this.payeeID);
    }
  }
}
