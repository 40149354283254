import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { FlexFooterComponent } from './flex-footer.component';



@NgModule({
  imports: [
    SharedModule,
  ],
    declarations: [FlexFooterComponent],
    providers: [],
    exports: [FlexFooterComponent]
})
export class FlexFooterModule { }
