<!-- <div class="insights-wrapper"><br> -->
  <!-- <br>
  <!-- <div class="title-font">Coming Soon: DeliveryDefense<sup>TM</sup> Visibility</div>
  <div class="green-bar"></div> -->
  <!-- <br>
  <img class="img-format" src="../../../assets/images/dd_coming_soon_graphic.PNG">
  <div class="title-font mt-5 mb-2">DeliveryDefense™ Visibility is now live!</div>
  <br>
  <div class="text-font">We have reimagined InsureShield Analytics and are excited to offer a new, innovative tool – DeliveryDefense™ Visibility. This tool will allow you to track all major carriers and modes in one place giving you analytics for your top shipped carriers, exception reports, and more!</div>
  <div class="text-font mt-3">Please complete the updated user agreement that was sent via DocuSign to your email on file to gain access to the tool. If you did not receive the user agreement, reach out to your Customer Solutions Manager.</div>
</div> -->

<!-- EVERYTHING BELOW IS FOR THE NEW DD VISIBILITY ITEM -->
<div class="gradient" id="background">
  <div class="header-contents">
    <div class="header-text-items">
      <div class="title">DeliveryDefense<sup>™</sup></div>
      <div class="title2">Visibility</div>
      <div class="green-bar"></div>
      <div class="text">Data-driven insights to power your business. Put your shipment data to work with DeliveryDefense<sup>™</sup> Visibility.</div>
    </div>
    <div class="header-image"><img src="../../../assets/images/visibility-image.png" class="img-format"></div>
  </div>
</div>

<!-- This part is responsible for the 4 info icon+texts -->
<div class="summary-contents">
  <div class="first-row">
    <div class="item-one">
      <img src="../../../assets/images/dvr.png" class="summary-image">
      <div class="d-flex flex-column ml-3">
        <div class="summary-title mb-2">Tracking Overview</div>
        <div>Access multi-carrier tracking with real-time shipment visibility all in one place. The dashboard will provide you with insights on weekly volume distribution, carrier usage and in-progress shipments.</div>
      </div>
    </div>
    <div class="item-two">
      <img src="../../../assets/images/overview.png" class="summary-image">
      <div class="d-flex flex-column ml-3">
        <div class="summary-title mb-2">Monitoring</div>
        <div>Quick snapshot of volume distribution across carriers and the status of your packages. Spot delays and potential issues so you can take proactive steps with your customers.</div>
      </div>
    </div>
  </div>
  <div class="second-row">
    <div class="item-one">
      <img src="../../../assets/images/monitoring.png" class="summary-image">
      <div class="d-flex flex-column ml-3">
        <div class="summary-title mb-2">Analytics</div>
        <div>Overview of all tracking numbers with ability to drill down to see how carriers performed when it comes to ahead of schedule, on-time, delayed, and damaged deliveries.</div>
      </div>
    </div>
    <div class="item-two">
      <img src="../../../assets/images/local_shipping.png" class="summary-image">
      <div class="d-flex flex-column ml-3">
        <div class="summary-title mb-2">Exception Overview</div>
        <div>Breakdown between exception types such as mode and carrier.</div>
      </div>
    </div>
  </div>
</div>
