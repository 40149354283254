<form [formGroup]="formGroup" (ngSubmit)="onSubmit($event, formGroup.value)" class="form-content">
    <div class="inside-form no-pad">
        <upsc-self-stepper *ngIf="currentStep > 0 && currentStep < 5" [steps]="steps" [currentStep]="currentStep">
        </upsc-self-stepper>
        <span class="req" *ngIf="currentStep === 2 || currentStep === 3"><span class="red">*</span>
            {{ 'claims.edit.start.tracking.form.trackingNumber.errors.requiredFields' | translate }}</span>
        <div class="outside-sections">
            <div class="claim-sections claim-edit-wrapper">
                <upsc-notification-box class="error-box" *ngIf="errorNotification" [isDanger]="true">
                    <div class="icon">
                        <mat-icon>cancel_outline</mat-icon>
                    </div>
                    <div class="errors" *ngIf="currentStep==1">
                    </div>
                    <div class="errors" *ngIf="currentStep==2">
                        <div *ngIf="whatHappenedFormGroup.get('reason').errors?.required">
                            - {{ 'claims.edit.whatHappened.reason.errors.reqPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('damageDescription').errors?.required">
                            - {{ 'claims.edit.whatHappened.reason.dmgReqPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('missingDescription').errors?.required">
                            - {{ 'claims.edit.whatHappened.reason.msngReqPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('category').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.search.form.category.errors.reqPrefix' |
                            translate }}
                        </div>
                        <div
                            *ngIf="(whatHappenedFormGroup.get('category').errors?.invalid || whatHappenedFormGroup.get('category').errors?.requireMatch) && !whatHappenedFormGroup.get('category').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.search.form.category.errors.valPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('quantity').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.reqPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('quantity').errors?.pattern">
                            - {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.patPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('description').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.reqPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('description').errors?.maxlength">
                            - {{ 'claims.edit.whatHappened.merchandise.description.limPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('damageDate').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.damage.day.reqPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('damageDate').errors?.matDatepickerMin">
                            - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('damageDate').errors?.matDatepickerMax">
                            - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('damageRepairable').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.damage.repair.errors.reqPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('damageLocations').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.reqPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('damageOther').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.damage.where.otherPrefix' | translate
                            }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('missingDate').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.damage.day.reqPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('missingDate').errors?.matDatepickerMin">
                            - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('missingDate').errors?.matDatepickerMax">
                            - {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('missingLocations').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.reqPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('missingOther').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.missing.otherPrefix' | translate }}
                        </div>
                        <div
                            *ngIf="whatHappenedFormGroup.get('lateReship').errors?.required && whatHappenedFormGroup.controls['lateReship']?.touched">
                            - {{ 'claims.edit.whatHappened.merchandise.description.late.how.reship.reqPrefix' |
                            translate }}
                        </div>
                        <div
                            *ngIf="whatHappenedFormGroup.get('lateRefund').errors?.required && whatHappenedFormGroup.controls['lateRefund']?.touched">
                            - {{ 'claims.edit.whatHappened.merchandise.description.late.how.refund.reqPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('lateCare').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.reqPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('lossReship').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.late.how.reship.reqPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('lossRefund').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.late.how.refund.reqPrefix' |
                            translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('lossCare').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.reqPrefix' |
                            translate }}
                        </div>
                        <!-- <div *ngIf="whatHappenedFormGroup.get('return').errors?.required">
                - {{ 'claims.edit.whatHappened.merchandise.description.return.reqPrefix' | translate }}
              </div> -->
                        <div *ngIf="whatHappenedFormGroup.get('purpose').errors?.required">
                            - {{ 'claims.edit.whatHappened.merchandise.description.purpose.errors.reqPrefix' | translate
                            }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('originalTrackingNumber').errors">
                            - {{ 'claims.edit.whatHappened.merchandise.description.return.otn.reqPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('currency').errors?.required">
                            - {{ 'claims.edit.whatHappened.value.form.currency.errors.required' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('merchandise').errors?.required">
                            - {{ 'claims.edit.whatHappened.value.form.merchandise.errors.reqPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('merchandise').errors?.pattern">
                            - {{ 'claims.edit.whatHappened.value.form.merchandise.errors.patPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('shipping').errors?.required">
                            - {{ 'claims.edit.whatHappened.value.form.shipping.errors.reqPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('shipping').errors?.pattern">
                            - {{ 'claims.edit.whatHappened.value.form.shipping.errors.patPrefix' | translate }}
                        </div>
                        <div *ngIf="whatHappenedFormGroup.get('amount').errors?.required">
                            - {{ 'claims.edit.whatHappened.value.form.amount.errors.reqPrefix' | translate }}
                        </div>
                    </div>
                    <div class="errors" *ngIf="currentStep==3">
                        <div *ngIf="contactInfoFormGroup.controls.name.errors?.required">
                            - {{ 'claims.edit.claimrecipient.errors.name' | translate }}
                        </div>
                        <div *ngIf="contactInfoFormGroup.controls.email.errors?.required">
                            - {{ 'claims.edit.claimrecipient.errors.email' | translate }}
                        </div>
                        <div *ngIf="contactInfoFormGroup.get('email').errors?.email">
                            - {{ 'claims.edit.claimrecipient.errors.emailInvalid' | translate }}
                        </div>
                        <div *ngIf="contactInfoFormGroup.controls.phone.errors?.required">
                            - {{ 'claims.edit.claimrecipient.errors.phn' | translate }}
                        </div>
                        <div
                            *ngIf="contactInfoFormGroup.get('phone').errors?.pattern || contactInfoFormGroup.get('phone').errors?.minlength">
                            - {{ 'claims.edit.claimrecipient.errors.phnInvalid' | translate }}
                        </div>
                        <div *ngIf="contactInfoFormGroup.controls.addressLine1.errors?.required">
                            - {{ 'claims.edit.claimrecipient.errors.addr1' | translate }}
                        </div>
                        <div *ngIf="contactInfoFormGroup.controls.city.errors?.required">
                            - {{ 'claims.edit.claimrecipient.errors.city' | translate }}
                        </div>
                        <div *ngIf="contactInfoFormGroup.controls.state.errors?.required">
                            - {{ 'claims.edit.claimrecipient.errors.state' | translate }}
                        </div>
                        <div *ngIf="contactInfoFormGroup.controls.zip.errors?.required">
                            - {{ 'claims.edit.claimrecipient.errors.zip' | translate }}
                        </div>
                        <div *ngIf="whoToContactFormGroup.controls.option.errors?.required">
                            - {{ 'claims.edit.claimrecipient.errors.whoToRequired' | translate }}
                        </div>
                        <div *ngIf="whoToContactFormGroup.controls.option.value == 'different'">
                            <div *ngIf="differentContactFormGroup.controls.name.errors?.required">
                                - {{ 'claims.edit.claimrecipient.errors.diffName' | translate }}
                            </div>
                            <div *ngIf="differentContactFormGroup.controls.email.errors?.required">
                                - {{ 'claims.edit.claimrecipient.errors.diffEmail' | translate }}
                            </div>
                            <div *ngIf="differentContactFormGroup.get('email').errors?.email">
                                - {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valid2'
                                | translate }}
                            </div>
                            <div *ngIf="differentContactFormGroup.controls.phone.errors?.required">
                                - {{ 'claims.edit.claimrecipient.errors.diffPhone' | translate }}
                            </div>
                            <div
                                *ngIf="differentContactFormGroup.get('phone').errors?.pattern || differentContactFormGroup.get('phone').errors?.minlength">
                                - {{
                                'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.invalid2' |
                                translate
                                }}
                            </div>
                        </div>
                        <div *ngIf="systemAPIError">
                            - {{ 'common.systemError' | translate }}
                        </div>
                        <div *ngIf="showDPSUnavailable">
                            {{ 'payee.denied.unavailable' | translate }}
                        </div>
                        <div *ngIf="showDPSDenied">
                            {{ 'payee.denied.denied' | translate }}
                        </div>
                    </div>
                    <div class="errors" *ngIf="currentStep==4">
                        <div *ngIf="submitClaimFormGroup.get('checkboxFormControl').errors?.required">
                            - {{ 'claims.edit.review.reviewAndSubmit.reqPrefix' | translate }}
                        </div>
                    </div>
                </upsc-notification-box>
                <div>
                    <div class="claim-section welcome" *ngIf="currentStep === 0">
                        <upsc-start (nextEvent)="setStep($event, 1)"></upsc-start>
                    </div>
                    <div class="claim-section shipment-details" *ngIf="currentStep === 1">
                        <div class="white-on-gray">
                            <div *ngIf="systemAPIError" class="mb-3 red">{{ 'common.systemError' | translate}}</div>
                            <upsc-shipment-details></upsc-shipment-details>
                            <div class="step-controls">
                                <div>
                                    <a class="previous-step" href="#" (click)="previousStep($event)">
                                        {{ 'claims.edit.back' | translate }}
                                    </a>
                                    <a class="reset-step" (click)="openCancelDialog($event)">
                                        {{ 'claims.edit.cancel' | translate }}
                                    </a>
                                </div>
                                <div>
                                    <button class="next-step" role="button" type="button" (click)="createClaim($event)"
                                        *ngIf="!continuing">
                                        {{ 'claims.edit.continue' | translate }}
                                    </button>
                                    <button class="next-step loading d-flex align-items-center justify-content-between"
                                        role="button" type="button" *ngIf="continuing">
                                        <div>{{ 'claims.edit.continue' | translate }}</div>
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="claim-section what-happened" *ngIf="currentStep === 2">
                        <div *ngIf="systemAPIError" class="mb-3 red">{{ 'common.systemError' | translate}}</div>
                        <ng-container *ngTemplateOutlet="whatHappenedTemplate"></ng-container>
                    </div>
                    <div class="claim-section contact-information" *ngIf="currentStep === 3">
                        <div *ngIf="systemAPIError" class="mb-3 red">{{ 'common.systemError' | translate}}</div>
                        <div *ngIf="!showAddressValidation">
                            <ng-container *ngTemplateOutlet="contactInfoTemplate"></ng-container>
                        </div>
                        <div *ngIf="showAddressValidation">
                            <upsc-address-validation (userInput)="updateAddress($event)"></upsc-address-validation>
                        </div>
                    </div>
                    <div class="claim-section submit-claim" *ngIf="currentStep === 4">
                        <div *ngIf="systemAPIError" class="mb-3 red">{{ 'common.systemError' | translate}}</div>
                        <ng-container *ngTemplateOutlet="submitClaimTemplate"></ng-container>
                    </div>
                    <div class="claim-section success-claim" *ngIf="currentStep === 5">
                        <ng-container *ngTemplateOutlet="successClaimTemplate"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- <ng-template #welcomeTemplate>
    <img class="pic" *ngIf="!isCanadaUser || !isGuestUser" alt="{{ 'claims.edit.altImg' | translate }}" src="../../../../../assets/images/Optimized-FileAClaimPicture-1.jpg">
    <img class="mobile-pic" *ngIf="!isCanadaUser || !isGuestUser" alt="{{ 'claims.edit.altImg' | translate }}" src="../../../../../assets/images/640X407ClaimWelcome.png">
    <img class="pic" *ngIf="isCanadaUser && isGuestUser" alt="{{ 'claims.edit.altImg' | translate }}" src="../../../../../assets/images/file_a_claim_landing_canada_optimised.png">
    <img class="mobile-pic" *ngIf="isCanadaUser && isGuestUser" alt="{{ 'claims.edit.altImg' | translate }}" src="../../../../../assets/images/file_a_claim_landing_canada_optimised640x456.png">
    
    <div class="process">
      <div class="intro">{{ 'claims.edit.intro' | translate }}</div>
      <div class="step">
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
          <g id="Group_436" data-name="Group 436" transform="translate(-758 -514)">
            <g id="Group_435" data-name="Group 435">
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="15.5" cy="15.5" r="15.5" transform="translate(758 514)"
                fill="#01857d" />
            </g>
            <path id="ic_check_24px" d="M8.22,14.694,4.632,11.106,3.41,12.319l4.81,4.81L18.546,6.8,17.332,5.59Z"
              transform="translate(762.776 518.317)" fill="#fff" />
          </g>
        </svg>
        {{ 'claims.edit.step1' | translate }}
      </div>
      <div class="step">
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
          <g id="Group_436" data-name="Group 436" transform="translate(-758 -514)">
            <g id="Group_435" data-name="Group 435">
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="15.5" cy="15.5" r="15.5" transform="translate(758 514)"
                fill="#01857d" />
            </g>
            <path id="ic_check_24px" d="M8.22,14.694,4.632,11.106,3.41,12.319l4.81,4.81L18.546,6.8,17.332,5.59Z"
              transform="translate(762.776 518.317)" fill="#fff" />
          </g>
        </svg>
        {{ 'claims.edit.step2' | translate }}
      </div>
      <div class="step">
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
          <g id="Group_436" data-name="Group 436" transform="translate(-758 -514)">
            <g id="Group_435" data-name="Group 435">
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="15.5" cy="15.5" r="15.5" transform="translate(758 514)"
                fill="#01857d" />
            </g>
            <path id="ic_check_24px" d="M8.22,14.694,4.632,11.106,3.41,12.319l4.81,4.81L18.546,6.8,17.332,5.59Z"
              transform="translate(762.776 518.317)" fill="#fff" />
          </g>
        </svg>
        {{ 'claims.edit.step3' | translate }}
      </div>
      <div class="step">
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
          <g id="Group_436" data-name="Group 436" transform="translate(-758 -514)">
            <g id="Group_435" data-name="Group 435">
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="15.5" cy="15.5" r="15.5" transform="translate(758 514)"
                fill="#01857d" />
            </g>
            <path id="ic_check_24px" d="M8.22,14.694,4.632,11.106,3.41,12.319l4.81,4.81L18.546,6.8,17.332,5.59Z"
              transform="translate(762.776 518.317)" fill="#fff" />
          </g>
        </svg>
        {{ 'claims.edit.step4' | translate }}
      </div>
      <div class="step">
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
          <g id="Group_436" data-name="Group 436" transform="translate(-758 -514)">
            <g id="Group_435" data-name="Group 435">
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="15.5" cy="15.5" r="15.5" transform="translate(758 514)"
                fill="#01857d" />
            </g>
            <path id="ic_check_24px" d="M8.22,14.694,4.632,11.106,3.41,12.319l4.81,4.81L18.546,6.8,17.332,5.59Z"
              transform="translate(762.776 518.317)" fill="#fff" />
          </g>
        </svg>
        {{ 'claims.edit.step5' | translate }}
      </div>
      <button class="upsc-button" (click)="nextStep($event)" href="#">{{ 'claims.edit.beginNewClaim' | translate
        }}</button>
      <upsc-notification-box [isWarning]="true">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="28.423" height="27.506" viewBox="0 0 28.423 27.506">
            <g id="Group_448" data-name="Group 448" transform="translate(-1 -2)">
              <g id="Group_447" data-name="Group 447" transform="translate(1 2)">
                <g id="Group_442" data-name="Group 442" transform="translate(0 0)">
                  <path id="Path_5183" data-name="Path 5183"
                    d="M31.774,36.013a.459.459,0,0,1-.458-.458L31.308,19.75a.459.459,0,0,1,.293-.428l13.611-5.276a.458.458,0,0,1,.624.427V30.3a.458.458,0,0,1-.293.428l-13.6,5.257A.46.46,0,0,1,31.774,36.013Zm.451-15.95.007,14.823,12.687-4.9V15.142Z"
                    transform="translate(-17.414 -8.507)" fill="#e5ad15" />
                  <path id="Path_5184" data-name="Path 5184"
                    d="M14.268,16.2a.466.466,0,0,1-.161-.029l-2.76-1.033a.458.458,0,0,1-.3-.43V11.732a.457.457,0,0,1,.285-.424L24.794,5.8a.458.458,0,0,1,.344,0L27.8,6.863a.458.458,0,0,1,0,.85L14.727,13.073v2.665a.458.458,0,0,1-.458.458Zm-2.3-1.809,1.843.689V12.765a.458.458,0,0,1,.285-.424L26.408,7.294,24.97,6.719l-13,5.321Z"
                    transform="translate(-6.443 -4.04)" fill="#e5ad15" />
                  <path id="Path_5185" data-name="Path 5185"
                    d="M6.074,10.148a.458.458,0,0,1-.164-.03L1.295,8.35A.459.459,0,0,1,1.289,7.5L15.046,2.032a.456.456,0,0,1,.34,0l4.352,1.743a.458.458,0,0,1-.165.884L6.248,10.113A.455.455,0,0,1,6.074,10.148ZM2.72,7.914,6.068,9.2,18.3,4.189,15.214,2.952Z"
                    transform="translate(-1 -2)" fill="#e5ad15" />
                  <path id="Path_5186" data-name="Path 5186"
                    d="M24.056,17a.458.458,0,0,1-.164-.03l-6.527-2.5a.459.459,0,0,1-.295-.428.483.483,0,0,1,.285-.442L30.714,8.123a.457.457,0,0,1,.344,0l6.78,2.716a.458.458,0,0,1-.005.853L24.222,16.967A.466.466,0,0,1,24.056,17Zm-5.306-2.981,5.3,2.031,12.362-4.791L30.889,9.043Z"
                    transform="translate(-9.704 -5.298)" fill="#e5ad15" />
                  <path id="Path_5187" data-name="Path 5187"
                    d="M15.369,35.976a.454.454,0,0,1-.162-.03L1.312,30.689a.458.458,0,0,1-.3-.429V14.4a.459.459,0,0,1,.622-.428l4.576,1.742a.483.483,0,0,1,.312.427.452.452,0,0,1-.266.436.46.46,0,0,1-.378-.01l-3.948-1.5V29.943l12.978,4.91L14.9,20.026l-6.2-2.4a.457.457,0,0,1-.043-.86.478.478,0,0,1,.345-.006l6.52,2.52a.458.458,0,0,1,.293.427l.006,15.806a.458.458,0,0,1-.458.458Z"
                    transform="translate(-1.009 -8.47)" fill="#e5ad15" />
                </g>
                <g id="Group_446" data-name="Group 446" transform="translate(2.956 13.906)">
                  <path id="Path_5192" data-name="Path 5192"
                    d="M15.646,36.179a.459.459,0,0,1-.162-.03L7.745,33.221a.458.458,0,1,1,.324-.857l7.74,2.928a.458.458,0,0,1-.162.887Z"
                    transform="translate(-7.448 -32.334)" fill="#e5ad15" />
                  <path id="Path_5194" data-name="Path 5194"
                    d="M7.907,36.179a.459.459,0,0,0,.162-.03l7.739-2.928a.458.458,0,1,0-.324-.857l-7.74,2.928a.458.458,0,0,0,.162.887Z"
                    transform="translate(6.763 -32.334)" fill="#e5ad15" />
                  <path id="Path_5193" data-name="Path 5193"
                    d="M15.646,43.005a.459.459,0,0,1-.162-.03L7.745,40.047a.458.458,0,1,1,.324-.857l7.74,2.928a.458.458,0,0,1-.162.887Z"
                    transform="translate(-7.448 -36.031)" fill="#e5ad15" />
                  <path id="Path_5195" data-name="Path 5195"
                    d="M7.907,43.005a.459.459,0,0,0,.162-.03l7.739-2.928a.458.458,0,1,0-.324-.857l-7.74,2.928a.458.458,0,0,0,.162.887Z"
                    transform="translate(6.763 -36.031)" fill="#e5ad15" />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="text">{{ 'claims.edit.retain' | translate }}</div>
      </upsc-notification-box>
    </div>
  </ng-template> -->

<!-- <ng-template #selectPayeeTemplate>
    <div class="payee-wrapper">
      <div class="title mb-2">{{ 'claims.edit.step1' | translate }}</div>
      <div class="subtitle">{{ 'claims.edit.subtitleStep1' | translate }}</div>
      <hr class="divider">
      <div class="table">
        <upsc-payee-table (selectionChange)="payeeChanged($event)"></upsc-payee-table>
      </div>
      <button class="upsc-button" (click)="nextStep($event)" href="#"
        [disabled]="!payeeSelectionValid && !mobilePayeeSelectionValid">{{ 'claims.edit.continue' | translate }}</button>
    </div>
  </ng-template> -->

<!-- <ng-template #startTemplate>
    <form [formGroup]="startFormGroup">
      <div class="white-on-gray">
        <div class="header">
          <div class="title">
            <div class="text">{{ 'claims.edit.step2' | translate }}</div>
            <mat-icon ngbTooltip="{{'claims.edit.start.tracking.titleTooltip' | translate}}" [autoClose]="false"
              tooltipClass="ngb-tooltip-class">info</mat-icon>
          </div>
        </div>
        <hr>
        <div class="section">
          <div class="subsection left">
            <div class="title">{{ 'claims.edit.start.tracking.form.carrier.label' | translate }}<span class="red">*</span>
            </div>
            <mat-form-field>
              <input matInput required formControlName="carrier"
                placeholder="{{ 'claims.edit.start.tracking.form.carrier.placeholder' | translate }}" [matAutocomplete]="auto">
              <mat-icon matSuffix *ngIf="!startFormGroup.get('carrier').errors">done</mat-icon>
              <mat-autocomplete #auto autoActiveFirstOption>
                <mat-option value="UPS">{{ 'claims.edit.start.tracking.form.carrier.errors.ups' | translate }}</mat-option>
                <mat-option *ngFor="let key of Object.keys(carrierList) | carrierSearch : queryCarrier" [value]="key">
                  {{carrierList[key]}}</mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="startFormGroup.get('carrier').errors">
                <div *ngIf="startFormGroup.get('carrier').errors['required']">
                  {{ 'claims.edit.start.tracking.form.carrier.errors.required' | translate }}
                </div>
                <div *ngIf="startFormGroup.get('carrier').errors['invalid']">
                  {{ 'claims.edit.start.tracking.form.carrier.errors.invalid' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="subsection">
            <div class="title">{{ 'claims.edit.start.tracking.form.trackingNumber.label' | translate }}<span
                class="red">*</span></div>
            <mat-form-field class="" [floatLabel]="auto">
              <input matInput required formControlName="trackingNumber"
                placeholder="{{ 'claims.edit.start.tracking.form.trackingNumber.label' | translate }}"
                (blur)="getShipmentDetails()">
              <mat-icon matSuffix *ngIf="!startFormGroup.get('trackingNumber').errors">done</mat-icon>
              <mat-error *ngIf="startFormGroup.get('trackingNumber').errors">
                <div *ngIf="startFormGroup.get('trackingNumber').errors['required']">
                  {{ 'claims.edit.start.tracking.form.trackingNumber.errors.required' | translate }}
                </div>
                <div *ngIf="startFormGroup.get('trackingNumber').errors['pattern']">
                  {{ 'claims.edit.start.tracking.form.trackingNumber.errors.pattern' | translate }}
                </div>
                <div *ngIf="startFormGroup.get('trackingNumber').errors['inuse']">
                  <span>{{ 'claims.edit.start.tracking.form.trackingNumber.errors.inuse' | translate }}</span>
                  <span>{{ 'claims.edit.start.tracking.form.trackingNumber.errors.inuse2' | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="section mb-3 mb-lg-5">
          <div class="subsection">
            <div class="title">{{ 'claims.edit.start.role.title' | translate }}<span class="red">*</span></div>
            <mat-radio-group class="radio-group" formControlName="role">
              <mat-radio-button *ngFor="let role of roles" [value]="role.id">{{ role.text }}</mat-radio-button>
            </mat-radio-group>
            <mat-error class="standalone"
              *ngIf="startFormGroup.get('role').errors && startFormGroup.controls['role']?.touched">
              {{ 'claims.edit.start.role.errors.required' | translate }}
            </mat-error>
          </div>
        </div>
        <div class="header">
          <div class="title">
            <div class="text">{{ 'claims.edit.start.shippingInfo.address' | translate }}</div>
          </div>
        </div>
        <hr>
        <div class="section mb-3 mb-lg-5">
          <div class="subsection left">
            <div class="title d-flex align-items-center">
              <mat-icon class="arrow mr-2">arrow_upward</mat-icon>
              <div class="text">{{ 'claims.edit.start.shippingInfo.form.senderInformation.title' | translate }}<span
                  class="red">*</span></div>
            </div>
            <form [formGroup]="startFormGroup.get('sender')" name="sender-address">
              <div class="">
                <div class="text" *ngIf="isCanadaUser">{{ 'claims.edit.start.shippingInfo.form.senderInformation.company.label' | translate }}<span
                  class="red">*</span>
                </div>
                <mat-form-field class="" tabindex="16">
                  <input matInput required formControlName="company"
                    placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.company.label' | translate }}">
                  <mat-error *ngIf="startFormGroup.get('sender').get('company').errors">
                    <div *ngIf="startFormGroup.get('sender').get('company').errors['required']">
                      {{ 'claims.edit.start.shippingInfo.form.senderInformation.company.errors.required' | translate }}
                    </div>
                  </mat-error>
                </mat-form-field>
                <div class="subsection half">
                  <div class="subsubsection left">
                    <div class="text" *ngIf="isCanadaUser">{{ 'claims.edit.start.shippingInfo.form.senderInformation.city.label' | translate }}<span
                      class="red">*</span>
                    </div>
                    <mat-form-field class="" tabindex="17">
                      <input matInput required formControlName="city"
                        placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.city.label' | translate }}">
                      <mat-error *ngIf="startFormGroup.get('sender').get('city').errors">
                        <div *ngIf="startFormGroup.get('sender').get('city').errors['required']">
                          {{ 'claims.edit.start.shippingInfo.form.senderInformation.city.errors.required' | translate }}
                        </div>
                        <div *ngIf="startFormGroup.get('sender').get('city').errors['pattern']">
                          {{ 'claims.edit.start.shippingInfo.form.senderInformation.city.errors.pattern' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="subsubsection">
                    <div class="text" *ngIf="isCanadaUser">{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.label' | translate }}<span
                      class="red">*</span>
                    </div>
                    <mat-form-field class="" tabindex="18" *ngIf="isCanadaUser || isUsaUser">
                      <mat-select name="state" formControlName="state" (selectionChange)="senderAutoCountry()"
                        (click)="onClick($event)"
                        placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.label' | translate }}">
                        <mat-option>{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.none' | translate }}</mat-option>
                        <mat-option *ngFor="let state of stateList" [value]="state.id">{{state.state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="startFormGroup.get('sender').get('state').errors">
                        <div *ngIf="startFormGroup.get('sender').get('state').errors['required']">
                          {{ 'claims.edit.start.shippingInfo.form.senderInformation.state.errors.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                    
                    <mat-form-field class="" tabindex="18" *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                      <input matInput required formControlName="zipCode" minlength="2"
                        placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.senderzipcode.label' | translate }}">
                      <mat-error *ngIf="startFormGroup.get('sender').get('zipCode').errors">
                        <div *ngIf="startFormGroup.get('sender').get('zipCode').errors['required']">
                          {{ 'claims.edit.start.shippingInfo.form.senderInformation.senderzipcode.errors.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="text" *ngIf="isCanadaUser">{{ 'claims.edit.start.shippingInfo.form.senderInformation.country.label' | translate }}<span
                  class="red">*</span>
                </div>
                <mat-form-field class="" tabindex="19">               
                  <mat-select required disableRipple formControlName="country"
                    placeholder="{{ 'claims.edit.start.shippingInfo.form.senderInformation.country.label' | translate }}">
                    <mat-option class="highlighted" value="US">{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.us' | translate }}</mat-option>
                    <mat-option class="highlighted" value="CA">{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.canada' | translate }}</mat-option>
                    <mat-option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="startFormGroup.get('sender').get('country').errors">
                    <div *ngIf="startFormGroup.get('sender').get('country').errors['required']">
                      {{ 'claims.edit.start.shippingInfo.form.senderInformation.country.errors.required' | translate }}
                    </div>
                    <div *ngIf="startFormGroup.get('sender').get('country').errors['mismatch']">
                      {{ 'claims.edit.start.shippingInfo.form.senderInformation.country.errors.mismatch' | translate }}
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </form>
          </div>
          <div class="subsection">
            <div class="title d-flex align-items-center">
              <mat-icon class="arrow mr-2">arrow_downward</mat-icon>
              <div class="text">{{ 'claims.edit.start.shippingInfo.form.recipientInformation.title' | translate }}<span
                  class="red">*</span></div>
            </div>
            <form [formGroup]="startFormGroup.get('recipient')" name="recipient-address">
              <div>
                <div class="text" *ngIf="isCanadaUser">{{ 'claims.edit.start.shippingInfo.form.recipientInformation.company.label' | translate }}<span
                  class="red">*</span>
                </div>
                <mat-form-field tabindex="20">                
                  <input matInput required formControlName="company"
                    placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.company.label' | translate }}">
                  <mat-error *ngIf="startFormGroup.get('recipient').get('company').errors">
                    <div *ngIf="startFormGroup.get('recipient').get('company').errors['required']">
                      {{ 'claims.edit.start.shippingInfo.form.recipientInformation.company.errors.required' | translate }}
                    </div>
                  </mat-error>
                </mat-form-field>
                <div class="subsection half">
                  <div class="subsubsection left">
                    <div class="text" *ngIf="isCanadaUser">{{ 'claims.edit.start.shippingInfo.form.recipientInformation.city.label' | translate }}<span
                      class="red">*</span>
                    </div>
                    <mat-form-field tabindex="21">
                      <input matInput required formControlName="city"
                        placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.city.label' | translate }}">
                      <mat-error *ngIf="startFormGroup.get('recipient').get('city').errors">
                        <div *ngIf="startFormGroup.get('recipient').get('city').errors['required']">
                          {{ 'claims.edit.start.shippingInfo.form.recipientInformation.city.errors.required' | translate
                          }}
                        </div>
                        <div *ngIf="startFormGroup.get('recipient').get('city').errors['pattern']">
                          {{ 'claims.edit.start.shippingInfo.form.recipientInformation.city.errors.pattern' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="subsubsection">
                    <div class="text" *ngIf="isCanadaUser">{{ 'claims.edit.start.shippingInfo.form.recipientInformation.state.label' | translate }}<span
                      class="red">*</span>
                    </div>
                    <mat-form-field tabindex="22" *ngIf="isCanadaUser || isUsaUser">
                      <mat-select name="state" formControlName="state" (selectionChange)="recipientAutoCountry()"
                        (click)="onClick($event)"
                        placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.state.label' | translate }}">
                        <mat-option>{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.none' | translate }}</mat-option>
                        <mat-option *ngFor="let state of stateList" [value]="state.id">{{state.state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="startFormGroup.get('recipient').get('state').errors">
                        <div *ngIf="startFormGroup.get('recipient').get('state').errors['required']">
                          {{ 'claims.edit.start.shippingInfo.form.recipientInformation.state.errors.required' | translate
                          }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field tabindex="22" *ngIf="isUkUser || isGermanyUser || isFranceUser || isItalyUser">                
                        <input matInput required formControlName="zipCode" minlength="2"
                        placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.recipientzipcode.label' | translate }}">
                      <mat-error *ngIf="startFormGroup.get('recipient').get('zipCode').errors">
                        <div *ngIf="startFormGroup.get('recipient').get('zipCode').errors['required']">
                          {{ 'claims.edit.start.shippingInfo.form.recipientInformation.recipientzipcode.errors.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
  
                  </div>
                </div>
                <div class="text" *ngIf="isCanadaUser">{{ 'claims.edit.start.shippingInfo.form.recipientInformation.country.label' | translate }}<span
                  class="red">*</span>
                </div>
                <mat-form-field tabindex="23">                
                  <mat-select required formControlName="country"
                    placeholder="{{ 'claims.edit.start.shippingInfo.form.recipientInformation.country.label' | translate }}">
                    <mat-option class="highlighted" value="US">{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.us' | translate }}</mat-option>
                    <mat-option class="highlighted" value="CA">{{ 'claims.edit.start.shippingInfo.form.senderInformation.state.canada' | translate }}</mat-option>
                    <mat-option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="startFormGroup.get('recipient').get('country').errors">
                    <div *ngIf="startFormGroup.get('recipient').get('country').errors['required']">
                      {{ 'claims.edit.start.shippingInfo.form.recipientInformation.country.errors.required' | translate }}
                    </div>
                    <div *ngIf="startFormGroup.get('recipient').get('country').errors['mismatch']">
                      {{ 'claims.edit.start.shippingInfo.form.recipientInformation.country.errors.mismatch' | translate }}
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </form>
          </div>
        </div>
        <div class="header">
          <div class="title">
            <div class="text">{{ 'claims.edit.start.shippingInfo.title' | translate }}</div>
          </div>
        </div>
        <hr>
        <div class="section">
          <div class="subsection left">
            <div class="title" *ngIf="!isCanadaUser">
              <div class="text">{{ 'claims.edit.start.shippingInfo.title2' | translate }}<span class="red">*</span></div>
            </div>
            <div class="subsection half">
              <div class="subsubsection left">
                <div class="title" *ngIf="isCanadaUser">
                  <div class="text">{{ 'claims.edit.start.shippingInfo.title2' | translate }}<span class="red">*</span></div>
                </div>
                <mat-form-field tabindex="24" (click)="picker.open()">
                  <input matInput [matDatepicker]="picker" [max]="maxDate" required readonly formControlName="shipDate"
                    placeholder="{{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.label' | translate }}">
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
                      width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
                      <path id="Path_219" data-name="Path 219"
                        d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                        transform="translate(0.964 0.643)" fill="#737373" />
                      <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                    </svg>
                  </mat-datepicker-toggle>
                  <mat-datepicker [touchUi]="isMobile" #picker></mat-datepicker>
                  <mat-error *ngIf="startFormGroup.get('shipDate').errors">
                    <div *ngIf="startFormGroup.get('shipDate').errors['required']">
                      {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.required' | translate
                      }}
                    </div>
                    <div *ngIf="startFormGroup.get('shipDate').errors['invalid']">
                      {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.invalid' | translate }}
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="subsubsection">
                <div class="title" *ngIf="isCanadaUser">{{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.trackingNumber.label' | translate }}<span
                  class="red">*</span></div>
                <mat-form-field>
                  <input matInput readonly [value]="startFormGroup.get('trackingNumber').value"
                    placeholder="{{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.trackingNumber.label' | translate }}">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="subsection">
            <div class="title">
              <div class="text">{{ 'claims.edit.start.shippingInfo.form.referenceDetails.optional' | translate }}</div>
            </div>
            <mat-form-field tabindex="25">
              <input matInput #message formControlName="referenceNumber" maxlength="24"
                placeholder="{{ 'claims.edit.start.shippingInfo.form.referenceDetails.form.referenceNumber.label' | translate }}">
              <mat-hint align="end">
                {{ 'claims.edit.start.shippingInfo.form.referenceDetails.form.referenceNumber.hintEnd' | translate }}{{24
                - message.value.length}}
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="step-controls">
        <div>
          <a class="previous-step" href="#" (click)="previousStep($event)">
            {{ 'claims.edit.back' | translate }}
          </a>
          <a class="reset-step" (click)="openCancelDialog($event)">
            {{ 'claims.edit.cancel' | translate }}
          </a>
        </div>
        <button class="next-step" role="button" type="button" [disabled]="currentlyCheckingDupeTrackingNumbers"
          (click)="createClaim($event)" *ngIf="!continuing">
          {{ 'claims.edit.continue' | translate }}
        </button>
        <button class="next-step loading d-flex align-items-center justify-content-between" role="button" type="button"
          *ngIf="continuing">
          <div>{{ 'claims.edit.continue' | translate }}</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
      </div>
    </form>
  </ng-template> -->

<ng-template #whatHappenedTemplate>
    <form [formGroup]="whatHappenedFormGroup">
        <div class="white-on-gray">
            <div class="header">
                <div class="title">
                    <div class="text" *ngIf="isUsaUser">
                        <div class="title-bold">{{ 'claims.edit.step3' | translate }}</div>
                    </div>
                    <div class="text" *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                        {{
                        'claims.edit.step3Title' | translate }}</div>
                    <mat-icon *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser"
                        ngbTooltip="{{'claims.edit.whatHappened.reason.titleTooltip' | translate}}"
                        tooltipClass="ngb-tooltip-class">info</mat-icon>
                </div>
            </div>
            <hr>
            <div class="section mb-3 mb-lg-5">
                <div class="subsection">
                    <div class="title">{{ 'claims.edit.whatHappened.reason.title' | translate }}<span
                            class="red">*</span></div>
                    <mat-radio-group class="radio-group" formControlName="reason">
                        <mat-radio-button class="loss" (click)="toggleFlow('loss')" value="0">
                            {{ 'claims.edit.whatHappened.reason.tupssLoss' | translate }}
                        </mat-radio-button>                        
                        <mat-radio-button class="damage" (click)="toggleFlow('damage')" value="1">
                            {{ 'claims.edit.whatHappened.reason.tupssDamage' | translate }}
                        </mat-radio-button>
                        <div class="damage-description" *ngIf="damage">
                            <!-- <div class="description-label">{{ 'claims.edit.whatHappened.reason.damageDescription' | translate }}</div> -->
                            <mat-form-field class="fill-width">
                                <textarea #dmgdescr matInput maxlength="800" formControlName="damageDescription"
                                    placeholder="{{ 'claims.edit.whatHappened.reason.damageDescription' | translate }}"></textarea>
                                <mat-hint align="end">
                                    {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{800 -
                                    dmgdescr.value.length}}
                                </mat-hint>
                                <mat-error *ngIf="whatHappenedFormGroup.get('damageDescription').errors">
                                    <div *ngIf="whatHappenedFormGroup.get('damageDescription').errors['required']">
                                        {{ 'claims.edit.whatHappened.reason.damageDescriptionRequired' | translate }}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-radio-button class="missing" (click)="toggleFlow('missing')"
                            value="2">
                            {{ 'claims.edit.whatHappened.reason.missing' | translate }}
                        </mat-radio-button>
                        <div class="missing-description" *ngIf="missing">
                            <!-- <div class="description-label">{{ 'claims.edit.whatHappened.reason.missingDescription' | translate }}
                </div> -->
                            <mat-form-field class="fill-width">
                                <textarea #missingdescr matInput maxlength="800" formControlName="missingDescription"
                                    placeholder="{{ 'claims.edit.whatHappened.reason.missingDescription' | translate }}"></textarea>
                                <mat-hint align="end">
                                    {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{800 -
                                    missingdescr.value.length}}
                                </mat-hint>
                                <mat-error *ngIf="whatHappenedFormGroup.get('missingDescription').errors">
                                    <div *ngIf="whatHappenedFormGroup.get('missingDescription').errors['required']">
                                        {{ 'claims.edit.whatHappened.reason.missingDescriptionRequired' | translate }}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>                        
                        <mat-radio-button class="late-delivery"
                            (click)="toggleFlow('unishippers_late_delivery')" value="5">
                            {{ 'claims.edit.whatHappened.reason.late' | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                    <mat-error class="standalone"
                        *ngIf="whatHappenedFormGroup.get('reason').errors && whatHappenedFormGroup.controls['reason']?.touched">
                        {{ 'claims.edit.whatHappened.reason.errors.required' | translate }}
                    </mat-error>
                    <div class="retain d-flex align-items-center justify-content-center mt-3 p-3"
                        *ngIf="damage || missing">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28.423" height="27.506"
                                viewBox="0 0 28.423 27.506">
                                <g id="Group_448" data-name="Group 448" transform="translate(-1 -2)">
                                    <g id="Group_447" data-name="Group 447" transform="translate(1 2)">
                                        <g id="Group_442" data-name="Group 442" transform="translate(0 0)">
                                            <path id="Path_5183" data-name="Path 5183"
                                                d="M31.774,36.013a.459.459,0,0,1-.458-.458L31.308,19.75a.459.459,0,0,1,.293-.428l13.611-5.276a.458.458,0,0,1,.624.427V30.3a.458.458,0,0,1-.293.428l-13.6,5.257A.46.46,0,0,1,31.774,36.013Zm.451-15.95.007,14.823,12.687-4.9V15.142Z"
                                                transform="translate(-17.414 -8.507)" fill="#e5ad15" />
                                            <path id="Path_5184" data-name="Path 5184"
                                                d="M14.268,16.2a.466.466,0,0,1-.161-.029l-2.76-1.033a.458.458,0,0,1-.3-.43V11.732a.457.457,0,0,1,.285-.424L24.794,5.8a.458.458,0,0,1,.344,0L27.8,6.863a.458.458,0,0,1,0,.85L14.727,13.073v2.665a.458.458,0,0,1-.458.458Zm-2.3-1.809,1.843.689V12.765a.458.458,0,0,1,.285-.424L26.408,7.294,24.97,6.719l-13,5.321Z"
                                                transform="translate(-6.443 -4.04)" fill="#e5ad15" />
                                            <path id="Path_5185" data-name="Path 5185"
                                                d="M6.074,10.148a.458.458,0,0,1-.164-.03L1.295,8.35A.459.459,0,0,1,1.289,7.5L15.046,2.032a.456.456,0,0,1,.34,0l4.352,1.743a.458.458,0,0,1-.165.884L6.248,10.113A.455.455,0,0,1,6.074,10.148ZM2.72,7.914,6.068,9.2,18.3,4.189,15.214,2.952Z"
                                                transform="translate(-1 -2)" fill="#e5ad15" />
                                            <path id="Path_5186" data-name="Path 5186"
                                                d="M24.056,17a.458.458,0,0,1-.164-.03l-6.527-2.5a.459.459,0,0,1-.295-.428.483.483,0,0,1,.285-.442L30.714,8.123a.457.457,0,0,1,.344,0l6.78,2.716a.458.458,0,0,1-.005.853L24.222,16.967A.466.466,0,0,1,24.056,17Zm-5.306-2.981,5.3,2.031,12.362-4.791L30.889,9.043Z"
                                                transform="translate(-9.704 -5.298)" fill="#e5ad15" />
                                            <path id="Path_5187" data-name="Path 5187"
                                                d="M15.369,35.976a.454.454,0,0,1-.162-.03L1.312,30.689a.458.458,0,0,1-.3-.429V14.4a.459.459,0,0,1,.622-.428l4.576,1.742a.483.483,0,0,1,.312.427.452.452,0,0,1-.266.436.46.46,0,0,1-.378-.01l-3.948-1.5V29.943l12.978,4.91L14.9,20.026l-6.2-2.4a.457.457,0,0,1-.043-.86.478.478,0,0,1,.345-.006l6.52,2.52a.458.458,0,0,1,.293.427l.006,15.806a.458.458,0,0,1-.458.458Z"
                                                transform="translate(-1.009 -8.47)" fill="#e5ad15" />
                                        </g>
                                        <g id="Group_446" data-name="Group 446" transform="translate(2.956 13.906)">
                                            <path id="Path_5192" data-name="Path 5192"
                                                d="M15.646,36.179a.459.459,0,0,1-.162-.03L7.745,33.221a.458.458,0,1,1,.324-.857l7.74,2.928a.458.458,0,0,1-.162.887Z"
                                                transform="translate(-7.448 -32.334)" fill="#e5ad15" />
                                            <path id="Path_5194" data-name="Path 5194"
                                                d="M7.907,36.179a.459.459,0,0,0,.162-.03l7.739-2.928a.458.458,0,1,0-.324-.857l-7.74,2.928a.458.458,0,0,0,.162.887Z"
                                                transform="translate(6.763 -32.334)" fill="#e5ad15" />
                                            <path id="Path_5193" data-name="Path 5193"
                                                d="M15.646,43.005a.459.459,0,0,1-.162-.03L7.745,40.047a.458.458,0,1,1,.324-.857l7.74,2.928a.458.458,0,0,1-.162.887Z"
                                                transform="translate(-7.448 -36.031)" fill="#e5ad15" />
                                            <path id="Path_5195" data-name="Path 5195"
                                                d="M7.907,43.005a.459.459,0,0,0,.162-.03l7.739-2.928a.458.458,0,1,0-.324-.857l-7.74,2.928a.458.458,0,0,0,.162.887Z"
                                                transform="translate(6.763 -36.031)" fill="#e5ad15" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="text ml-3">
                            {{ 'claims.edit.retain' | translate }}
                        </div>
                    </div>
                    <div class="retain d-flex mt-3 p-3" *ngIf="late_delivery">
                        <div>
                            <mat-icon class="icon-key">error</mat-icon>
                        </div>
                        <div class="flex-row text2">
                            <div>{{ 'claims.edit.lateDelivery' | translate }}</div>
                            <div>Phone: <span><a href="tel:{{lateDeliveryPhone}}" class="link-decoration">{{
                                        lateDeliveryPhoneFormatted }}</a></span></div>
                            <div>Email: <span><a href="mailto:{{lateDeliveryEmail}}" class="link-decoration">{{
                                        lateDeliveryEmail }}</a></span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header">
                <div class="title">
                    <div class="text">
                        <div class="title-bold"> {{ 'claims.edit.whatHappened.merchandise.title' | translate }}</div>
                    </div>
                    <mat-icon *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser"
                        ngbTooltip="{{'claims.edit.whatHappened.merchandise.title' | translate}}"
                        tooltipClass="ngb-tooltip-class">
                        info</mat-icon>
                </div>
            </div>
            <hr>
            <div class="section">
                <div class="subsection">
                    <div class="subtitle">{{ 'claims.edit.whatHappened.merchandise.search.tupssTitle' | translate
                        }}<span class="red">*</span></div>
                    <div class="d-flex flex-column flex-lg-row">
                        <mat-form-field class="width-70 mr-lg-3">
                            <input type="text"
                                placeholder="{{ 'claims.edit.whatHappened.merchandise.search.form.category.label' | translate }}"
                                aria-label="category" matInput formControlName="category"
                                [matAutocomplete]="autoCategory">
                            <mat-icon matSuffix *ngIf="!whatHappenedFormGroup.get('category').errors">done</mat-icon>
                            <mat-icon matSuffix>search</mat-icon>
                            <mat-autocomplete #autoCategory="matAutocomplete" [displayWith]="displayCategories">
                                <mat-option *ngFor="let option of filteredCategories | async" [value]="option">
                                    {{ option.value }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="whatHappenedFormGroup.get('category').errors">
                                <div *ngIf="whatHappenedFormGroup.get('category').errors['required']">
                                    {{ 'claims.edit.whatHappened.merchandise.search.form.category.errors.required' |
                                    translate }}
                                </div>
                                <div
                                    *ngIf="(whatHappenedFormGroup.get('category').errors['invalid'] || whatHappenedFormGroup.get('category').errors['requireMatch']) && !whatHappenedFormGroup.get('category').errors['required']">
                                    {{ 'claims.edit.whatHappened.merchandise.search.form.category.errors.valid' |
                                    translate }}
                                </div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="width-30">
                            <input matInput required formControlName="quantity"
                                placeholder="{{ 'claims.edit.whatHappened.merchandise.search.form.quantity.label' | translate }}">
                            <mat-error *ngIf="whatHappenedFormGroup.get('quantity').errors">
                                <div *ngIf="whatHappenedFormGroup.get('quantity').errors['required']">
                                    {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.required' |
                                    translate }}
                                </div>
                                <div *ngIf="whatHappenedFormGroup.get('quantity').errors['pattern']">
                                    {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.pattern' |
                                    translate }}
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="section mb-3 mb-lg-5">
                <div class="subsection">
                    <div class="subtitle">{{ 'claims.edit.whatHappened.merchandise.description.title' | translate
                        }}<span class="red">*</span></div>
                    <div class="subtitle squeeze">{{ 'claims.edit.whatHappened.merchandise.description.subtitle' |
                        translate }}</div>
                    <mat-form-field class="full-width">
                        <textarea matInput #descr maxlength="250" formControlName="description"></textarea>
                        <mat-hint align="end">
                            {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{250 -
                            descr.value.length}}
                        </mat-hint>
                        <mat-error *ngIf="whatHappenedFormGroup.get('description').errors">
                            <div *ngIf="whatHappenedFormGroup.get('description').errors['required']">
                                {{ 'claims.edit.whatHappened.merchandise.description.required' | translate }}
                            </div>
                            <div *ngIf="whatHappenedFormGroup.get('description').errors['maxlength']">
                                {{ 'claims.edit.whatHappened.merchandise.description.limit' | translate }}
                            </div>
                        </mat-error>
                    </mat-form-field>
                    <div class="merchandise-damage" *ngIf="damage">
                        <div class="panel-section-title">
                            {{ 'claims.edit.whatHappened.merchandise.description.damage.day.title' | translate }}<span
                                class="red">*</span>
                        </div>
                        <div class="mat-form-fields">
                            <mat-form-field class="width-30" (click)="damagePicker.open()">
                                <input matInput [matDatepicker]="damagePicker" [max]="maxDate"
                                    [min]="startFormGroup.get('shipDate').value" required readonly
                                    formControlName="damageDate"
                                    placeholder="{{ 'claims.edit.whatHappened.merchandise.description.damage.day.label' | translate }}">
                                <mat-datepicker-toggle matSuffix [for]="damagePicker">
                                    <svg matDatepickerToggleIcon id="baseline-date_range-24px"
                                        xmlns="http://www.w3.org/2000/svg" width="31.711" height="31.711"
                                        viewBox="0 0 31.711 31.711">
                                        <path id="Path_219" data-name="Path 219"
                                            d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                                            transform="translate(0.964 0.643)" fill="#737373" />
                                        <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z"
                                            fill="none" />
                                    </svg>
                                </mat-datepicker-toggle>
                                <mat-datepicker [touchUi]="isMobile" #damagePicker></mat-datepicker>
                                <mat-error *ngIf="whatHappenedFormGroup.get('damageDate').errors">
                                    <div *ngIf="whatHappenedFormGroup.get('damageDate').errors['required']">
                                        {{ 'claims.edit.whatHappened.merchandise.description.damage.day.required' |
                                        translate }}
                                    </div>
                                    <div *ngIf="whatHappenedFormGroup.get('damageDate').errors['matDatepickerMin']">
                                        {{
                                        'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' |
                                        translate }}
                                    </div>
                                    <div *ngIf="whatHappenedFormGroup.get('damageDate').errors['matDatepickerMax']">
                                        {{
                                        'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' |
                                        translate }}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="panel-section-title damage-repair">
                            {{ 'claims.edit.whatHappened.merchandise.description.damage.repair.title' | translate
                            }}<span class="red">*</span>
                        </div>
                        <div class="mat-form-fields">
                            <mat-radio-group class="radio-group" formControlName="damageRepairable">
                                <mat-radio-button *ngFor="let repairable of repairables" value="{{repairable.id}}">{{
                                    repairable.text }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <mat-error class="standalone"
                            *ngIf="whatHappenedFormGroup.get('damageRepairable').errors && whatHappenedFormGroup.controls['damageRepairable']?.touched">
                            {{ 'claims.edit.whatHappened.merchandise.description.damage.repair.errors.required' |
                            translate }}
                        </mat-error>
                        <div class="panel-section-title damage-locations">
                            {{ 'claims.edit.whatHappened.merchandise.description.damage.where.title' | translate }}<span
                                class="red">*</span>
                        </div>
                        <div class="mat-form-fields">
                            <mat-radio-group class="radio-group" formControlName="damageLocations">
                                <mat-radio-button *ngFor="let locations of damagedLocations" value="{{locations.id}}"
                                    (click)="toggleOther(false)">{{
                                    locations.text }}</mat-radio-button>
                                <mat-radio-button value="3" (click)="toggleOther(true)">{{
                                    'claims.edit.whatHappened.merchandise.description.damage.where.other' |
                                    translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <mat-error class="standalone"
                            *ngIf="whatHappenedFormGroup.get('damageLocations').errors && whatHappenedFormGroup.controls['damageLocations']?.touched">
                            {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.required' |
                            translate }}
                        </mat-error>
                        <div class="mat-form-fields" *ngIf="other">
                            <mat-form-field class="medium-full other-response">
                                <textarea #dmgother maxlength="35" matInput placeholder=""
                                    formControlName="damageOther"></textarea>
                                <mat-hint align="end">
                                    {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{35 -
                                    dmgother.value.length}}
                                </mat-hint>
                                <mat-error *ngIf="whatHappenedFormGroup.get('damageOther').errors">
                                    <div *ngIf="whatHappenedFormGroup.get('damageOther').errors['required']">
                                        {{ 'claims.edit.whatHappened.merchandise.description.damage.where.otherRequired'
                                        | translate }}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="merchandise-missing" *ngIf="missing">
                        <div class="panel-section-title">
                            {{ 'claims.edit.whatHappened.merchandise.description.missing.day.title' | translate }}<span
                                class="red">*</span>
                        </div>
                        <div class="mat-form-fields">
                            <mat-form-field class="width-30" (click)="missingPicker.open()">
                                <input matInput [matDatepicker]="missingPicker" [max]="maxDate"
                                    [min]="startFormGroup.get('shipDate').value" required readonly
                                    formControlName="missingDate" class="date-icon"
                                    placeholder="{{ 'claims.edit.whatHappened.merchandise.description.missing.day.label' | translate }}">
                                <mat-datepicker-toggle matSuffix [for]="missingPicker">
                                    <svg matDatepickerToggleIcon id="baseline-date_range-24px"
                                        xmlns="http://www.w3.org/2000/svg" width="31.711" height="31.711"
                                        viewBox="0 0 31.711 31.711">
                                        <path id="Path_219" data-name="Path 219"
                                            d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                                            transform="translate(0.964 0.643)" fill="#737373" />
                                        <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z"
                                            fill="none" />
                                    </svg>
                                </mat-datepicker-toggle>
                                <mat-datepicker [touchUi]="isMobile" #missingPicker></mat-datepicker>
                                <mat-error *ngIf="whatHappenedFormGroup.get('missingDate').errors">
                                    <div *ngIf="whatHappenedFormGroup.get('missingDate').errors['required']">
                                        {{ 'claims.edit.whatHappened.merchandise.description.damage.day.required' |
                                        translate }}
                                    </div>
                                    <div *ngIf="whatHappenedFormGroup.get('missingDate').errors['matDatepickerMin']">
                                        {{
                                        'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' |
                                        translate }}
                                    </div>
                                    <div *ngIf="whatHappenedFormGroup.get('missingDate').errors['matDatepickerMax']">
                                        {{
                                        'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' |
                                        translate }}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="panel-section-title missing-locations">
                            {{ 'claims.edit.whatHappened.merchandise.description.damage.where.title' | translate }}<span
                                class="red">*</span>
                        </div>
                        <div class="mat-form-fields mb-3">
                            <mat-radio-group class="radio-group" formControlName="missingLocations">
                                <mat-radio-button *ngFor="let locations of damagedLocations" value="{{locations.id}}"
                                    (click)="toggleOther(false)">{{
                                    locations.text
                                    }}</mat-radio-button>
                                <mat-radio-button #other value="3" (click)="toggleOther(true)">{{
                                    'claims.edit.whatHappened.merchandise.description.damage.where.other' |
                                    translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <mat-error class="standalone"
                            *ngIf="whatHappenedFormGroup.get('missingLocations').errors && whatHappenedFormGroup.controls['missingLocations']?.touched">
                            {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.required' |
                            translate }}
                        </mat-error>
                        <div class="mat-form-fields" *ngIf="other.checked">
                            <mat-form-field class="medium-full other-response">
                                <textarea #missingother maxlength="35" matInput placeholder=""
                                    formControlName="missingOther"></textarea>
                                <mat-hint align="end">
                                    {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{35 -
                                    missingother.value.length}}
                                </mat-hint>
                                <mat-error *ngIf="whatHappenedFormGroup.get('missingOther').errors">
                                    <div *ngIf="whatHappenedFormGroup.get('missingOther').errors['required']">
                                        {{ 'claims.edit.whatHappened.merchandise.description.missing.otherRequired' |
                                        translate }}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="merchandise-late" *ngIf="late">
                        <div class="panel-section-title late-care">{{
                            'claims.edit.whatHappened.merchandise.description.late.how.label' | translate }}<span
                                class="red">*</span>
                        </div>
                        <div class="mat-form-fields mb-3">
                            <mat-radio-group class="radio-group" formControlName="lateCare">
                                <mat-radio-button value="0" #reship>
                                    {{'claims.edit.whatHappened.merchandise.description.late.how.reship.label'
                                    | translate }}</mat-radio-button>
                                <div class="reship-wrapper" *ngIf="reship.checked">
                                    <div class="mat-form-fields">
                                        <mat-form-field class="medium-full reship-response">
                                            <textarea matInput maxlength="45" #latereship
                                                placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.reship.placeholder' | translate }}"
                                                formControlName="lateReship"></textarea>
                                            <mat-hint align="end">{{
                                                'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                                                45 - latereship.value.length }}</mat-hint>
                                            <mat-error *ngIf="whatHappenedFormGroup.get('lateReship').errors">
                                                <div *ngIf="whatHappenedFormGroup.get('lateReship').errors['required']">
                                                    {{
                                                    'claims.edit.whatHappened.merchandise.description.late.how.reship.required'
                                                    | translate }}
                                                </div>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <mat-radio-button value="1" #refund>
                                    {{'claims.edit.whatHappened.merchandise.description.late.how.refund.label'
                                    | translate }} <mat-icon
                                        ngbTooltip="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.tooltip' | translate }}"
                                        tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                                </mat-radio-button>
                                <div class="refund-wrapper" *ngIf="refund.checked">
                                    <div class="mat-form-fields">
                                        <mat-form-field class="medium-full refund-response">
                                            <textarea matInput maxlength="45" #laterefund
                                                placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.placeholder' | translate }}"
                                                formControlName="lateRefund"></textarea>
                                            <mat-hint align="end">{{
                                                'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                                                45 - laterefund.value.length }}</mat-hint>
                                            <mat-error *ngIf="whatHappenedFormGroup.get('lateRefund').errors">
                                                <div *ngIf="whatHappenedFormGroup.get('lateRefund').errors['required']">
                                                    {{
                                                    'claims.edit.whatHappened.merchandise.description.late.how.refund.required'
                                                    | translate }}
                                                </div>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                        <mat-error class="standalone"
                            *ngIf="whatHappenedFormGroup.get('lateCare').errors && whatHappenedFormGroup.controls['lateCare']?.touched">
                            {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.required' | translate
                            }}</mat-error>
                    </div>
                    <div class="merchandise-late" *ngIf="loss && cargo && (isUsaUser || isCanadaUser)">
                        <div class="panel-section-title late-care">{{
                            'claims.edit.whatHappened.merchandise.description.late.how.label' | translate }}<span
                                class="red">*</span>
                        </div>
                        <div class="mat-form-fields mb-3">
                            <mat-radio-group class="radio-group" formControlName="lossCare">
                                <mat-radio-button
                                    value="0">{{'claims.edit.whatHappened.merchandise.description.late.how.reship.label'
                                    | translate }}</mat-radio-button>
                                <div class="reship-wrapper" *ngIf="whatHappenedFormGroup.get('lossCare').value==='0'">
                                    <div class="mat-form-fields">
                                        <mat-form-field class="medium-full reship-response">
                                            <textarea matInput maxlength="45" #lossreship
                                                placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.reship.placeholder' | translate }}"
                                                formControlName="lossReship"></textarea>
                                            <mat-hint align="end">{{
                                                'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                                                45 - lossreship.value.length }}</mat-hint>
                                            <mat-error *ngIf="whatHappenedFormGroup.get('lossReship').errors">
                                                <div *ngIf="whatHappenedFormGroup.get('lossReship').errors['required']">
                                                    {{
                                                    'claims.edit.whatHappened.merchandise.description.late.how.reship.required'
                                                    | translate }}
                                                </div>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <mat-radio-button
                                    value="1">{{'claims.edit.whatHappened.merchandise.description.late.how.refund.label'
                                    | translate }} <mat-icon
                                        ngbTooltip="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.tooltip' | translate }}"
                                        tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                                </mat-radio-button>
                                <div class="refund-wrapper" *ngIf="whatHappenedFormGroup.get('lossCare').value==='1'">
                                    <div class="mat-form-fields">
                                        <mat-form-field class="medium-full refund-response">
                                            <textarea matInput maxlength="45" #lossrefund
                                                placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.placeholder' | translate }}"
                                                formControlName="lossRefund"></textarea>
                                            <mat-hint align="end">{{
                                                'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                                                45 - lossrefund.value.length }}</mat-hint>
                                            <mat-error *ngIf="whatHappenedFormGroup.get('lossRefund').errors">
                                                <div *ngIf="whatHappenedFormGroup.get('lossRefund').errors['required']">
                                                    {{
                                                    'claims.edit.whatHappened.merchandise.description.late.how.refund.required'
                                                    | translate }}
                                                </div>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <mat-error class="standalone"
                        *ngIf="whatHappenedFormGroup.get('lossCare').errors && whatHappenedFormGroup.controls['lossCare']?.touched">
                        {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.required' | translate }}
                    </mat-error>
                    <div
                        *ngIf="!upshealthcareFlow && !freightcenterincFlow">
                        <div class="title mb-2 mt-3">
                            {{ 'claims.edit.whatHappened.merchandise.description.purpose.title' | translate }}
                            <span class="red">*</span>
                        </div>
                        <mat-radio-group class="radio-group" formControlName="purpose">
                            <mat-radio-button value="personal">{{
                                'claims.edit.whatHappened.merchandise.description.purpose.personal'
                                | translate }}</mat-radio-button>
                            <mat-radio-button value="sale">{{
                                'claims.edit.whatHappened.merchandise.description.purpose.sale' |
                                translate }}</mat-radio-button>
                            <mat-radio-button value="other">{{
                                'claims.edit.whatHappened.merchandise.description.purpose.other' |
                                translate }}</mat-radio-button>
                            <div class="refund-wrapper" *ngIf="whatHappenedFormGroup.get('purpose').value=='other'">
                                <div class="mat-form-fields">
                                    <mat-form-field class="medium-full refund-response">
                                        <textarea matInput maxlength="45" formControlName="purposeOther"
                                            #purposeOther></textarea>
                                        <mat-hint align="end">
                                            {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}
                                            {{ 45 - purposeOther.value.length }}
                                        </mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-radio-group>
                        <mat-error class="standalone"
                            *ngIf="whatHappenedFormGroup.get('purpose').errors && whatHappenedFormGroup.get('purpose').touched">
                            <div *ngIf="whatHappenedFormGroup.get('purpose').errors['required']">
                                {{ 'claims.edit.whatHappened.merchandise.description.purpose.errors.required' |
                                translate }}
                            </div>
                        </mat-error>
                    </div>
                    <!-- <div *ngIf="flex">
              <div class="title">{{ 'claims.edit.whatHappened.merchandise.description.return.title' | translate }}<span
                  class="red">*</span></div>
              <mat-radio-group class="radio-group" formControlName="return">
                <mat-radio-button value="yes">{{ 'claims.edit.whatHappened.merchandise.description.return.yes' | translate
                  }}</mat-radio-button>
                <mat-radio-button value="no">{{ 'claims.edit.whatHappened.merchandise.description.return.no' | translate
                  }}</mat-radio-button>
              </mat-radio-group>
            </div> -->
                    <!-- <mat-error class="standalone"
              *ngIf="whatHappenedFormGroup.get('return').errors && whatHappenedFormGroup.controls['return']?.touched">
              {{ 'claims.edit.whatHappened.merchandise.description.return.required' | translate }}
            </mat-error> -->
                    <!-- <div class="mt-3" *ngIf="whatHappenedFormGroup.value.return=='yes'">
              <div class="title">{{ 'claims.edit.whatHappened.merchandise.description.return.otn.title' | translate
                }}<span class="red">*</span></div>
              <div class="mat-form-fields">
                <mat-form-field class="width-30">
                  <input #OTN maxlength="100" matInput placeholder="" required formControlName="originalTrackingNumber">
                  <mat-error *ngIf="whatHappenedFormGroup.get('originalTrackingNumber').errors">
                    <div *ngIf="whatHappenedFormGroup.get('originalTrackingNumber').errors">
                      {{ 'claims.edit.whatHappened.merchandise.description.return.otn.required' | translate }}
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div> -->
                </div>
            </div>
            <div class="header">
                <div class="title">
                    <div class="text">
                        <div class="title-bold">{{ 'claims.edit.whatHappened.value.title' | translate }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="section mb-3">
                <div class="subsection d-flex flex-column flex-xl-row justify-content-between">
                    <div class="claim-amount-boxes pr-xl-2 currency">
                        <div class="label">&nbsp;</div>
                        <mat-form-field>
                            <mat-select class="currency-select" required formControlName="currency"
                                [(value)]="defaultCurrency"
                                placeholder="{{ 'claims.edit.whatHappened.value.form.currency.hint' | translate }}">
                                <mat-option *ngFor="let key of Object.keys(currencyList)" [value]="currencyList[key]">
                                    {{currencyList[key]}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="whatHappenedFormGroup.get('currency').errors">
                                <div *ngIf="whatHappenedFormGroup.get('currency').errors['required']">
                                    {{ 'claims.edit.whatHappened.value.form.currency.errors.required' | translate }}
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="claim-amount-boxes pr-xl-2">
                        <div class="label">&nbsp;</div>
                        <mat-form-field>
                            <!-- Because TUPSS and My Choice are USA only - we can use the currencyMask and provide $ easily -->
                            <input matInput required formControlName="merchandise" (blur)="checkDV()" currencyMask
                                [options]="{align: 'left', allowNegative: false, precision: 2, inputMode: 'FINANCIAL'}"
                                class="currency-align"
                                placeholder="{{ 'claims.edit.whatHappened.value.form.merchandise.label' | translate }}">
                            <mat-error *ngIf="whatHappenedFormGroup.get('merchandise').errors">
                                <div *ngIf="whatHappenedFormGroup.get('merchandise').errors['required']">
                                    {{ 'claims.edit.whatHappened.value.form.merchandise.errors.required' | translate }}
                                </div>
                                <div *ngIf="whatHappenedFormGroup.get('merchandise').errors['pattern']">
                                    {{ 'claims.edit.whatHappened.value.form.merchandise.errors.pattern' | translate }}
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <span class="add mr-xl-2">+</span>
                    <div class="claim-amount-boxes pr-xl-2">
                        <div class="label d-flex justify-content-between">
                            <div class="label">&nbsp;</div>
                            <mat-icon *ngIf="tupssType"
                                ngbTooltip="{{ 'claims.edit.whatHappened.value.form.shipping.tooltip' | translate }}"
                                tooltipClass="ngb-tooltip-class">info_outline</mat-icon>
                        </div>
                        <mat-form-field>
                            <input matInput required formControlName="shipping" currencyMask
                                [options]="{align: 'left', allowNegative: false, precision: 2, inputMode: 'FINANCIAL'}"
                                class="currency-align" placeholder="{{ 'claims.edit.whatHappened.value.form.shipping.label' |
                translate }}">
                            <mat-error *ngIf="whatHappenedFormGroup.get('shipping').errors">
                                <div *ngIf="whatHappenedFormGroup.get('shipping').errors['required']">
                                    {{ 'claims.edit.whatHappened.value.form.shipping.errors.required' | translate }}
                                </div>
                                <div *ngIf="whatHappenedFormGroup.get('shipping').errors['pattern']">
                                    {{ 'claims.edit.whatHappened.value.form.shipping.errors.pattern' | translate }}
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <span class="equal mr-xl-2">=</span>
                    <div class="claim-amount-boxes">
                        <div class="label">&nbsp;</div>
                        <mat-form-field>
                            <input matInput required readonly formControlName="amount" currencyMask
                                [options]="{align: 'left', allowNegative: false, precision: 2, inputMode: 'FINANCIAL'}"
                                class="currency-align"
                                placeholder="{{ 'claims.edit.whatHappened.value.form.amount.label' | translate}}">
                            <mat-error *ngIf="whatHappenedFormGroup.get('amount').errors">
                                <div *ngIf="whatHappenedFormGroup.get('amount').errors['required']">
                                    {{ 'claims.edit.whatHappened.value.form.amount.errors.required' | translate }}
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- <div class="retain d-flex mt-3 p-3" *ngIf="showMerchWarning">
          <div>
            <mat-icon class="icon-key">error</mat-icon>
          </div>
          <div class="flex-row text2">
            <div>{{ 'claims.edit.merchWarning' | translate }}</div>
            <div>Phone: <span><a href="tel:{{lateDeliveryPhone}}" class="link-decoration">{{ lateDeliveryPhoneFormatted }}</a></span></div>
            <div>Email: <span><a href="mailto:{{lateDeliveryEmail}}" class="link-decoration">{{ lateDeliveryEmail }}</a></span></div>
          </div>
        </div> -->
            <div class="notifications mb-3">
                <upsc-notification-box [isWarning]="true" class="warning-box" [class.hide]="hideDeclaredWarning"
                    *ngIf="tupssType == 'tccp'">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="icon-warning-wrapper p-1 d-flex">
                                <mat-icon>info_outline</mat-icon>
                            </div>
                            <div class="text">Reminder: At the time of shipment, this package had additional coverage
                                purchased in the
                                amount of ${{ persistIShipData?.cdsresponse.shipmentInfo.insuredValue_Total }}.</div>
                        </div>
                        <div class="close-icon" (click)="hideDeclaredWarning = true">
                            <mat-icon>cancel</mat-icon>
                        </div>
                    </div>
                </upsc-notification-box>
                <upsc-notification-box [isWarning]="true" class="warning-box" [class.hide]="hideNoDeclaredWarning"
                    *ngIf="tupssType == 'tccp'">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="icon-warning-wrapper p-1 d-flex">
                                <mat-icon>info_outline</mat-icon>
                            </div>
                            <div class="text">Reminder: At the time of shipment, additional coverage for this package
                                was not
                                purchased. Fortunately, your claim may still be eligible for a maximum of up to $100
                                plus shipping
                                costs.</div>
                        </div>
                        <div class="close-icon" (click)="hideNoDeclaredWarning= true">
                            <mat-icon>cancel</mat-icon>
                        </div>
                    </div>
                </upsc-notification-box>
                <upsc-notification-box [isWarning]="true" class="warning-box" [class.hide]="hideDeclaredWarning"
                    *ngIf="tupssType == 'tfcp'">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="icon-warning-wrapper p-1 d-flex">
                                <mat-icon>info_outline</mat-icon>
                            </div>
                            <div class="text">Reminder: At the time of shipment, customer declared the value to ${{
                                persistIShipData?.cdsresponse.shipmentInfo.insuredValue_Total }}.</div>
                        </div>
                        <div class="close-icon" (click)="hideDeclaredWarning = true">
                            <mat-icon>cancel</mat-icon>
                        </div>
                    </div>
                </upsc-notification-box>
                <upsc-notification-box [isWarning]="true" class="warning-box" [class.hide]="hideNoDeclaredWarning"
                    *ngIf="tupssType == 'tfcp'">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="icon-warning-wrapper p-1 d-flex">
                                <mat-icon>info_outline</mat-icon>
                            </div>
                            <div class="text">No declared value was placed on this shipment</div>
                        </div>
                        <div class="close-icon" (click)="hideNoDeclaredWarning = true">
                            <mat-icon>cancel</mat-icon>
                        </div>
                    </div>
                </upsc-notification-box>
            </div>
            <div class="header">
                <div class="title">
                    <div class="text">
                        <div class="title-bold">{{ 'claims.edit.whatHappened.documents.title' | translate }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="section mb-3 mb-lg-5">
                <div class="subsection">
                    <div class="subtitle mb-3">{{ 'claims.edit.whatHappened.documents.subtitle' | translate }}</div>
                    <div class="subtitle mb-3" *ngIf="isCanadaUser">
                        <span class="bold">{{ 'claims.edit.whatHappened.documents.subtitle2' | translate }} </span>
                        {{ 'claims.edit.whatHappened.documents.subtitle3' | translate }}
                    </div>
                    <div class="document-upload panel-section">
                        <div class="document-panel-left">
                            <div class="document-needed">
                                <div class="panel-section-title">
                                    {{ 'claims.edit.whatHappened.documents.documentNeeded.title' | translate }}
                                </div>
                                <ul class="document-list" *ngIf="tupssType">
                                    <li>{{ 'claims.edit.whatHappened.documents.documentNeeded.documentList.value' |
                                        translate }}
                                        <mat-icon
                                            ngbTooltip="{{ 'claims.edit.whatHappened.documents.documentNeeded.documentTooltips.value' | translate }}"
                                            tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                                    </li>
                                    <li>
                                        {{ 'claims.edit.whatHappened.documents.documentNeeded.documentList.proof' |
                                        translate }}
                                        <mat-icon
                                            ngbTooltip="{{ 'claims.edit.whatHappened.documents.documentNeeded.documentTooltips.tupssProof' | translate }}"
                                            tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                                    </li>
                                    <li *ngIf="cargo">{{
                                        'claims.edit.whatHappened.documents.documentNeeded.documentList.bol' | translate
                                        }}
                                    </li>
                                    <!-- <li *ngIf="cargo">{{ 'claims.edit.whatHappened.documents.documentNeeded.documentList.carrier' |
                    translate }}
                  </li> -->
                                    <li>
                                        {{ 'claims.edit.whatHappened.documents.documentNeeded.documentList.photos' |
                                        translate }}
                                        <mat-icon
                                            ngbTooltip="{{ 'claims.edit.whatHappened.documents.documentNeeded.documentTooltips.photos' | translate }}"
                                            tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                                    </li>
                                </ul>                                
                                <ul *ngIf="upshealthcareFlow || freightcenterincFlow"
                                    class="document-list">
                                    <li>Proof of loss</li>
                                    <li>Proof of value</li>
                                    <li>Pictures of damage</li>
                                    <li>BOL (Freight Shipments)</li>
                                    <li>Small Package - Freight (FT) / LTL Guidance <a
                                            href="assets/docs/Claim_Documents_ChannelPartner.pdf" target="_blank">(Click
                                            here)</a></li>
                                    <!-- <li>Freight (FT) / LTL Guidance <a href="assets/docs/Guidance_Freight_ChannelPartners.pdf" target="_blank">(Click here)</a></li> -->
                                    <!-- <li>Small Package/Parcel Guidance <a href="assets/docs/Guidance_Small_Package_ChannelPartners.pdf" target="_blank">(Click here)</a></li> -->
                                </ul>
                                <div class="too-many-documents-error" *ngIf="tooManyDocs">
                                    {{ 'claims.edit.whatHappened.documents.tooManyError'| translate }}
                                </div>
                                <div class="file-size-limit-error" *ngIf="fileSizeLimit">
                                    {{ 'claims.edit.whatHappened.documents.fileSizeLimit'| translate }}
                                </div>
                                <div class="file-error" *ngIf="fileTypeError">
                                    {{ 'claims.edit.whatHappened.documents.errors.fileType' | translate }}
                                </div>
                                <div class="file-error" *ngIf="fileNameError">
                                    {{ 'claims.edit.whatHappened.documents.errors.fileName' | translate }}
                                </div>
                                <div class="file-error" *ngIf="systemError">
                                    {{ 'common.systemError' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="document-panel-right" [ngClass]="{'readonly': late_delivery===true}">
                            <div class="uploaded-documents d-flex justify-content-center align-items-center"
                                (click)="fileInput.click()" upscDragNDrop (onFileDropped)="uploadDocument($event)">
                                <input hidden type="file" #fileInput
                                    (change)="uploadDocument($event.target.files, $event)">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <img class="pb-2" src="../../../../../assets/images/upload-file-icon.png">
                                    <div class="attachments-title pb-2">
                                        <span class="upload-underline">{{ 'claimsDetail.upload.message1' | translate
                                            }}</span> {{ 'claimsDetail.upload.message2' | translate }}
                                    </div>
                                    <div class="pb-2">
                                        {{ 'claimsDetail.upload.filesAllowed' | translate }}
                                    </div>
                                    <!-- <div class="file-selector d-flex justify-content-center align-items-center">
                      <input id="fileInput" type="file" #importFile class="file-input"
                       (change)="uploadDocument($event.target.files, $event)">
                      <input type="hidden" name="fileHidden" formControlName="documents"/>
                      <label for="fileInput" class="file-input-button">
                        Browse Files
                      </label>
                    </div> -->
                                </div>
                            </div>
                            <div class="uploaded-attachments">
                                <ul class="your-attachments">
                                    <li class="d-inline-flex justify-content-start align-items-center"
                                        *ngFor="let file of fileList">
                                        <div>{{file.file.name}}</div>
                                        <mat-icon class="delete-icon ml-2"
                                            (click)="removeDocument(file)">clear</mat-icon>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header">
                <div class="title">
                    <div class="text">
                        <div class="title-bold">
                            {{ 'claims.edit.whatHappened.additional.title' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="section">
                <div class="subsection">
                    <div class="subtitle">
                        {{ 'claims.edit.whatHappened.additional.subtitle' | translate }}
                    </div>
                    <mat-form-field>
                        <textarea matInput #comments maxlength="250" formControlName="additionalComments"></textarea>
                        <mat-hint align="end">
                            {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{ 250 -
                            comments.value.length }}
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="step-controls">
            <div>
                <a class="previous-step" href="#" (click)="previousStep($event)">
                    {{ 'claims.edit.back' | translate }}
                </a>
                <a class="reset-step" (click)="openCancelDialog($event)">
                    {{ 'claims.edit.cancel' | translate }}
                </a>
            </div>
            <div>
                <button class="next-step" role="button" type="button" (click)="saveClaim2($event)" *ngIf="!continuing"
                    [disabled]="late_delivery">
                    {{ 'claims.edit.continue' | translate }}
                </button>
                <button class="next-step loading d-flex align-items-center justify-content-between" role="button"
                    type="button" *ngIf="continuing">
                    <div>{{ 'claims.edit.continue' | translate }}</div>
                    <div class="spinner-border" role="status">
                        <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
                    </div>
                </button>
            </div>
        </div>
        <div class="dpaterror" *ngIf="systemErrorDPAT">
            {{ 'common.systemError' | translate }}
        </div>
    </form>
</ng-template>

<ng-template #contactInfoTemplate>
    <form [formGroup]="contactInfoFormGroup" *ngIf="!showACH">
        <div class="white-on-gray">
            <!-- <div class="header" *ngIf="tupssType == 'tccp'">
          <div class="title">
            <div class="text">
              <div class="title-bold">
                {{ 'claims.edit.tupssPayee.paymentMethod' | translate }}
                <button (click)="debug()">debug</button>
              </div>
            </div>          
          </div>
          <hr>
          <div class="subtitle mb-3">{{ 'claims.edit.tupssPayee.how' | translate }}</div>
        </div> -->
            <!-- <div class="section d-flex flex-column mb-4" *ngIf="tupssType == 'tccp'">
          <mat-radio-group formControlName="ach" class="d-flex flex-column">
            <mat-radio-button [value]="'ach'">{{ 'claims.edit.tupssPayee.direct' | translate }} (<span class="italic">{{ 'claims.edit.tupssPayee.recommended' | translate }}</span>)</mat-radio-button>
            <mat-radio-button [value]="'check'">{{ 'claims.edit.tupssPayee.check' | translate }} (<span class="italic">{{ 'claims.edit.tupssPayee.may' | translate }}</span>)</mat-radio-button>
          </mat-radio-group>
        </div> -->
            <!-- <div class="header">
          <div class="title">
            <div class="text">
              <div class="title-bold">
                {{ 'payee.table.add' | translate }}
              </div>
            </div>
          </div>
          <div class="subtitle mt-3">{{ 'claims.edit.subtitleStep1' | translate }}</div>
          <hr>
        </div>
        <div class="section">
          <div class="contact-you">
            <div class="panel-section contact-info" [formGroup]="contactInfoFormGroup">
              <div class="section">
                <div class="subsection d-flex flex-wrap justify-content-between">
                  <div class="cluster full">
                    <div class="title">{{ 'claims.edit.claimrecipient.company' | translate }}</div>
                    <mat-form-field class="width-100" [floatLabel]="'never'">
                      <input matInput formControlName="name" maxlength="30"
                        placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.name' | translate }}">
                      <mat-error *ngIf="contactInfoFormGroup.get('name').errors">
                        <div *ngIf="contactInfoFormGroup.get('name').errors['required']">
                          {{ 'claims.edit.claimrecipient.errors.name' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="cluster">
                    <div class="title">{{ 'claims.edit.claimrecipient.email' | translate }}</div>
                    <mat-form-field class="width-100" [floatLabel]="'never'">
                      <input matInput formControlName="email"
                        placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.email' | translate }}">
                      <mat-error *ngIf="contactInfoFormGroup.get('email').errors">
                        <div *ngIf="contactInfoFormGroup.get('email').errors['required']">
                          {{ 'claims.edit.claimrecipient.errors.email' | translate }}
                        </div>
                        <div *ngIf="contactInfoFormGroup.get('email').errors['email']">
                          {{ 'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valid' |
                          translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="cluster">
                    <div class="title">{{ 'claims.edit.claimrecipient.phoneNum' | translate }}</div>
                    <mat-form-field class="width-100" [floatLabel]="'never'">
                      <input matInput formControlName="phone" upscPhoneNumber maxlength="12" minlength="12"
                        placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.phone' | translate }}">
                      <mat-error *ngIf="contactInfoFormGroup.get('phone').errors">
                        <div *ngIf="contactInfoFormGroup.get('phone').errors['required']">
                          {{ 'claims.edit.claimrecipient.errors.phn' | translate }}
                        </div>
                        <div
                          *ngIf="contactInfoFormGroup.get('phone').errors['pattern'] || contactInfoFormGroup.get('phone').errors['minlength']">
                          {{ 'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.invalid' |
                          translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="cluster">
                    <div class="title">{{ 'claims.edit.claimrecipient.address1' | translate }}</div>
                    <mat-form-field class="width-100" [floatLabel]="'never'">
                      <input matInput formControlName="addressLine1" maxlength="60"
                        placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.address1' | translate }}">
                      <mat-error *ngIf="contactInfoFormGroup.get('addressLine1').errors">
                        <div *ngIf="contactInfoFormGroup.get('addressLine1').errors['required']">
                          {{ 'claims.edit.claimrecipient.errors.addr1' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="cluster">
                    <div class="title">{{ 'claims.edit.claimrecipient.address2' | translate }}</div>
                    <mat-form-field class="width-100" [floatLabel]="'never'">
                      <input matInput formControlName="addressLine2" maxlength="60"
                        placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.address2' | translate }}">
                    </mat-form-field>
                  </div>
                  <div class="cluster">
                    <div class="title">{{ 'claims.edit.claimrecipient.city' | translate }}</div>
                    <mat-form-field class="width-100" [floatLabel]="'never'">
                      <input matInput formControlName="city" maxlength="60"
                        placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.city' | translate }}">
                      <mat-error *ngIf="contactInfoFormGroup.get('city').errors">
                        <div *ngIf="contactInfoFormGroup.get('city').errors['required']">
                          {{ 'claims.edit.claimrecipient.errors.city' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="cluster">
                    <div class="title">{{ 'claims.edit.claimrecipient.state' | translate }}</div>
                    <mat-form-field class="width-100" [floatLabel]="'never'">
                      <mat-select formControlName="state"
                        placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.state' | translate }}">
                        <mat-option *ngFor="let state of stateList" [value]="state.state">{{state.state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="contactInfoFormGroup.get('state').errors">
                        <div *ngIf="contactInfoFormGroup.get('state').errors['required']">
                          {{ 'claims.edit.claimrecipient.errors.state' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="cluster">
                    <div class="label">{{ 'claims.edit.claimrecipient.zip' | translate }}</div>
                    <mat-form-field class="width-100" [floatLabel]="'never'">
                      <input matInput upscZipcode formControlName="zip" maxlength="10"
                        placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.zip' | translate }}">
                      <mat-error *ngIf="contactInfoFormGroup.get('zip').errors">
                        <div *ngIf="contactInfoFormGroup.get('zip').errors['required']">
                          {{ 'claims.edit.claimrecipient.errors.zip' | translate }}
                        </div>
                        <div *ngIf="contactInfoFormGroup.get('zip').errors['pattern']">
                          {{ 'claims.edit.claimrecipient.errors.zipValid' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
            <div class="header">
                <div class="title">
                    <div class="text">
                        <div class="title-bold">
                            {{ 'claims.edit.claimrecipient.verify' | translate }}
                        </div>
                    </div>
                </div>
                <hr>
                <div class="subtitle my-3">{{ 'claims.edit.claimrecipient.subtitle' | translate }}</div>
            </div>
            <form [formGroup]="whoToContactFormGroup">
                <div class="section d-flex flex-column">
                    <mat-radio-group formControlName="option" class="d-flex flex-column">
                        <mat-radio-button [value]="'same'" (change)="contactVerificationChange('same')">{{
                            'claims.edit.claimrecipient.option1' | translate }}</mat-radio-button>
                        <mat-radio-button [value]="'different'" (change)="contactVerificationChange('different')">{{
                            'claims.edit.claimrecipient.option2' | translate }}</mat-radio-button>
                    </mat-radio-group>
                    <mat-error
                        *ngIf="whoToContactFormGroup.controls.option.errors && whoToContactFormGroup.controls.option.touched">
                        <div *ngIf="whoToContactFormGroup.controls.option.errors.required">
                            {{ 'claims.edit.claimrecipient.errors.whoToContact' | translate }}
                        </div>
                    </mat-error>
                </div>
            </form>
            <form [formGroup]="differentContactFormGroup"
                *ngIf="whoToContactFormGroup.get('option').value == 'different'">
                <div class="section mt-3">
                    <div class="subsection d-flex flex-wrap justify-content-between">
                        <div class="cluster full">
                            <!-- <div class="title">{{ 'claims.edit.claimrecipient.company' | translate }}</div> -->
                            <mat-form-field class="width-100">
                                <input matInput formControlName="name" maxlength="30"
                                    placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.name' | translate }}">
                                <mat-error *ngIf="differentContactFormGroup.get('name').errors">
                                    <div *ngIf="differentContactFormGroup.get('name').errors['required']">
                                        {{ 'claims.edit.claimrecipient.errors.name' | translate }}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="cluster">
                            <!-- <div class="title">{{ 'claims.edit.claimrecipient.email' | translate }}</div> -->
                            <mat-form-field class="width-100">
                                <input matInput formControlName="email" maxlength="60"
                                    placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.email' | translate }}">
                                <mat-error *ngIf="differentContactFormGroup.get('email').errors">
                                    <div *ngIf="differentContactFormGroup.get('email').errors['required']">
                                        {{ 'claims.edit.claimrecipient.errors.email' | translate }}
                                    </div>
                                    <div *ngIf="differentContactFormGroup.get('email').errors['email']">
                                        {{
                                        'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valid'
                                        | translate }}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="cluster">
                            <!-- <div class="title">{{ 'claims.edit.claimrecipient.phoneNum' | translate }}</div> -->
                            <mat-form-field class="width-100">
                                <input matInput formControlName="phone" upscPhoneNumber minlength="12" maxlength="12"
                                    placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.phone' | translate }}">
                                <mat-error *ngIf="differentContactFormGroup.get('phone').errors">
                                    <div *ngIf="differentContactFormGroup.get('phone').errors['required']">
                                        {{ 'claims.edit.claimrecipient.errors.phn' | translate }}
                                    </div>
                                    <div
                                        *ngIf="differentContactFormGroup.get('phone').errors['pattern'] || differentContactFormGroup.get('phone').errors['minlength']">
                                        {{
                                        'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.invalid'
                                        | translate
                                        }}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
            <br>
            <div class="header">
                <div class="title">
                    <div class="text">
                        <div class="title-bold">
                            Claim Payment Recipient
                        </div>
                    </div>
                </div>
                <hr>
            </div>
            <div class="recipient">
                <div class="error" *ngIf="getPayeeDetailError">
                    There was an error retrieving your Claim Payment Recipient information.
                </div>
                <div class="subsection-text break">{{ contactInfoFormGroup.controls.name.value }}</div>
                <div class="subsection-text break">{{ contactInfoFormGroup.controls.email.value }}</div>
                <div class="subsection-text break">{{ contactInfoFormGroup.controls.phone.value | phoneNumber}}</div>
                <div class="subsection-text break">{{ contactInfoFormGroup.controls.addressLine1.value }}</div>
                <div class="subsection-text break">{{ contactInfoFormGroup.controls.addressLine2.value }}</div>
                <div class="subsection-text">{{ contactInfoFormGroup.controls.city.value }}, {{
                    contactInfoFormGroup.controls.state.value }}, {{ contactInfoFormGroup.controls.zip.value }}</div>

            </div>
        </div>
        <div class="step-controls">
            <div>
                <a class="previous-step" href="#" (click)="previousStep($event)">
                    {{ 'claims.edit.back' | translate }}
                </a>
                <a class="reset-step" (click)="openCancelDialog($event)">
                    {{ 'claims.edit.cancel' | translate }}
                </a>
            </div>
            <div>
                <button class="next-step" role="button" type="button" (click)="justContinue()" *ngIf="!continuing">
                    {{ 'claims.edit.continue' | translate }}
                </button>
                <button class="next-step loading d-flex align-items-center justify-content-between" role="button"
                    type="button" *ngIf="continuing">
                    <div>{{ 'claims.edit.continue' | translate }}</div>
                    <div class="spinner-border" role="status">
                        <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
                    </div>
                </button>
            </div>
        </div>
    </form>
    <div *ngIf="showACH">
        <!-- <upsc-ach [payeeDetails]="persistPayeeInfo" [showACHError]="showACHError"></upsc-ach> -->
        <div class="step-controls">
            <div>
                <a class="previous-step" href="#" (click)="showACH=false">
                    {{ 'claims.edit.back' | translate }}
                </a>
                <a class="reset-step" (click)="openCancelDialog($event)">
                    {{ 'claims.edit.cancel' | translate }}
                </a>
            </div>
            <div>
                <button class="next-step" role="button" type="button" (click)="achContinue($event)" *ngIf="!continuing">
                    {{ 'claims.edit.continue' | translate }}
                </button>
                <button class="next-step loading d-flex align-items-center justify-content-between" role="button"
                    type="button" *ngIf="continuing">
                    <div>{{ 'claims.edit.continue' | translate }}</div>
                    <div class="spinner-border" role="status">
                        <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #submitClaimTemplate>
    <form [formGroup]="submitClaimFormGroup">
        <div class="review-wrapper">
            <div class="title">{{ 'claims.edit.review.reviewAndSubmit.title' | translate }}</div>
            <div class="section mb-3 mb-lg-5">
                <div class="section-title">
                    {{ 'claims.edit.review.reviewAndSubmit.reviewList.shipmentDetail' | translate }}
                </div>
                <hr>
                <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
                    <div class="column mr-md-2">
                        <div class="subsection">
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.tupssTrackingNumber' | translate }}
                            </div>
                            <div class="subsection-text break">{{ claim.trackingNumber_Ext }}</div>
                        </div>
                        <div class="subsection">
                            <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.carrier' |
                                translate }}
                            </div>
                            <div class="subsection-text">{{ claim.transportationCarrierId_Ext }}</div>
                        </div>
                        <div class="subsection" *ngIf="tupssType == 'tfcp' && coverageType">
                            <div class="subsection-title">
                                Coverage Type
                            </div>
                            <div class="subsection-text break">
                                {{ coverageType }}
                            </div>
                        </div>
                    </div>
                    <div class="column mr-md-2">
                        <div class="subsection" *ngIf="tupssType == 'tfcp'">
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.shipperInformation' | translate }}
                            </div>
                            <div class="subsection-text">
                                <div>{{ claim.shippingDetailsDTO.senderDetails.companyName }},</div>
                                <div
                                    *ngIf="claim.shippingDetailsDTO.senderDetails.address1 !== undefined && claim.shippingDetailsDTO.senderDetails.address1">
                                    {{ claim.shippingDetailsDTO.senderDetails.address1 }},</div>
                                <div
                                    *ngIf="claim.shippingDetailsDTO.senderDetails.address2 !== undefined && claim.shippingDetailsDTO.senderDetails.address2">
                                    {{ claim.shippingDetailsDTO.senderDetails.address2 }},</div>
                                <div>{{ claim.shippingDetailsDTO.senderDetails.city | titlecase }}, {{
                                    senderStateDisplay }}</div>
                                <div>{{ claim.shippingDetailsDTO.senderDetails.zipCode }}</div>
                                <div>{{ claim.shippingDetailsDTO.senderDetails.country }}</div>
                            </div>
                        </div>
                        <div class="subsection">
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.recipientInformation' | translate }}
                            </div>
                            <div class="subsection-text">
                                <div>{{ claim.shippingDetailsDTO.receiverDetails.companyName }},</div>
                                <div
                                    *ngIf="claim.shippingDetailsDTO.receiverDetails.address1 !== undefined && claim.shippingDetailsDTO.receiverDetails.address1">
                                    {{ claim.shippingDetailsDTO.receiverDetails.address1 }},</div>
                                <div
                                    *ngIf="claim.shippingDetailsDTO.receiverDetails.address2 !== undefined && claim.shippingDetailsDTO.receiverDetails.address2">
                                    {{ claim.shippingDetailsDTO.receiverDetails.address2 }},</div>
                                <div>{{ claim.shippingDetailsDTO.receiverDetails.city | titlecase }}, {{
                                    recipientStateDisplay }}
                                </div>
                                <div>{{ claim.shippingDetailsDTO.receiverDetails.zipCode }}</div>
                                <div>{{ claim.shippingDetailsDTO.receiverDetails.country }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="subsection" *ngIf="tupssType == 'tfcp' && packedBy">
                            <div class="subsection-title">Packed By</div>
                            <div class="subsection-text">{{ packedBy }}</div>
                        </div>
                        <div class="subsection">
                            <div class="subsection-title">{{
                                'claims.edit.review.reviewAndSubmit.reviewList.tupssShipDate' | translate
                                }}
                            </div>
                            <div class="subsection-text">{{ claim.shippingDetailsDTO.shipDate | date: 'MM/dd/yyyy':'UTC'
                                }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section mb-3 mb-lg-5">
                <div class="section-title">
                    {{ 'claims.edit.step3' | translate }}
                    <span class="edit" (click)="edit('claim')">
                        <mat-icon>mode_edit</mat-icon>
                    </span>
                </div>
                <hr>
                <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
                    <div class="column mr-md-2">
                        <div class="subsection">
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.reasonForClaim' | translate }}</div>
                            <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('reason').value == 0">
                                {{ 'claims.edit.whatHappened.reason.tupssLoss' | translate }}</div>
                            <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('reason').value == 1">
                                {{ 'claims.edit.whatHappened.reason.tupssDamage' | translate }}</div>
                            <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('reason').value == 2">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.missing' | translate }}</div>
                            <div class="subsection-text"
                                *ngIf="whatHappenedFormGroup.get('reason').value == 3 || whatHappenedFormGroup.get('reason').value == 5">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.late' | translate }}</div>
                            <div class="subsection-more"
                                *ngIf="whatHappenedFormGroup.get('reason').value == 1 && dmgMore">
                                <!--Only appears for Damage-->
                                {{ shortenedDamageDescription }}
                            </div>
                            <div class="subsection-more"
                                *ngIf="whatHappenedFormGroup.get('reason').value == 1 && (dmgMore == false)">
                                <!--Only appears for Damage-->
                                {{ whatHappenedFormGroup.get('damageDescription').value }}
                            </div>
                            <span class="more-less" *ngIf="longDamage && dmgMore"
                                (click)="toggleDescription('damage')">{{
                                'claims.edit.review.reviewAndSubmit.reviewList.more' | translate }}</span>
                            <span class="more-less" *ngIf="longDamage && (dmgMore == false)"
                                (click)="toggleDescription('damage')">{{
                                'claims.edit.review.reviewAndSubmit.reviewList.less' | translate }}</span>
                            <div class="subsection-more"
                                *ngIf="whatHappenedFormGroup.get('reason').value == 2 && missingMore">
                                <!--Only appears for Missing Contents-->
                                {{ shortenedMissingDescription }}
                            </div>
                            <div class="subsection-more"
                                *ngIf="whatHappenedFormGroup.get('reason').value == 2 && (missingMore == false)">
                                {{ whatHappenedFormGroup.get('missingDescription').value }}
                            </div>
                            <span class="more-less" *ngIf="longMissing && missingMore"
                                (click)="toggleDescription('missing')">{{
                                'claims.edit.review.reviewAndSubmit.reviewList.more' | translate }}</span>
                            <span class="more-less" *ngIf="longMissing && (missingMore == false)"
                                (click)="toggleDescription('missing')">{{
                                'claims.edit.review.reviewAndSubmit.reviewList.less' |
                                translate }}</span>
                        </div>
                        <div class="subsection">
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.productCategory' | translate }}</div>
                            <div class="subsection-text">{{ whatHappenedFormGroup.get('category').value.value }}</div>
                        </div>
                        <div class="subsection">
                            <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.quantity' |
                                translate }}
                            </div>
                            <div class="subsection-text">{{ whatHappenedFormGroup.get('quantity').value }}</div>
                        </div>
                    </div>
                    <div class="column mr-md-2">
                        <div class="subsection">
                            <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.amount' |
                                translate }}
                            </div>
                            <div class="subsection-text">{{ whatHappenedFormGroup.get('amount').value | currency}}</div>
                        </div>
                        <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 0">
                            <!--Only appears on Lost-->
                            <div class="subsection-title" *ngIf="cargo">{{
                                'claims.edit.review.reviewAndSubmit.reviewList.howCare' |
                                translate }}
                            </div>
                            <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('lossReship').value">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.reshippedNewOrder' | translate }}
                            </div>
                            <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('lossRefund').value">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.refundedTheirCharges' | translate }}
                            </div>
                            <div class="subsection-text break" *ngIf="whatHappenedFormGroup.get('lossReship').value">
                                {{ whatHappenedFormGroup.get('lossReship').value }}</div>
                            <div class="subsection-text break" *ngIf="whatHappenedFormGroup.get('lossRefund').value">
                                {{ whatHappenedFormGroup.get('lossRefund').value }}</div>
                        </div>
                        <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 3">
                            <!--Only appears on Late Delivery-->
                            <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.howCare' |
                                translate }}
                            </div>
                            <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('lateReship').value">{{
                                'claims.edit.review.reviewAndSubmit.reviewList.reshippedNewOrder' | translate }}
                            </div>
                            <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('lateRefund').value">{{
                                'claims.edit.review.reviewAndSubmit.reviewList.refundedTheirCharges' | translate }}
                            </div>
                            <div class="subsection-text break" *ngIf="whatHappenedFormGroup.get('lateReship').value">
                                {{ whatHappenedFormGroup.get('lateReship').value }}</div>
                            <div class="subsection-text break" *ngIf="whatHappenedFormGroup.get('lateRefund').value">
                                {{ whatHappenedFormGroup.get('lateRefund').value }}</div>
                        </div>
                        <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 1">
                            <!--Only appears on Damage-->
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.damageDiscovered' | translate }}</div>
                            <div *ngIf="isUsaUser" class="subsection-text">{{
                                whatHappenedFormGroup.get('damageDate').value | date:
                                'MM/dd/yyyy' }}
                            </div>
                            <div *ngIf="isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser"
                                class="subsection-text">{{ whatHappenedFormGroup.get('damageDate').value | date:
                                'dd/MM/yyyy' }}
                            </div>
                        </div>
                        <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 2">
                            <!--Only appears on Missing-->
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.issueDiscovered' | translate }}</div>
                            <div class="subsection-text" *ngIf="isUsaUser">{{
                                whatHappenedFormGroup.get('missingDate').value | date:
                                'MM/dd/yyyy' }}
                            </div>
                            <div class="subsection-text"
                                *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">{{
                                whatHappenedFormGroup.get('missingDate').value | date: 'dd/MM/yyyy' }}
                            </div>
                        </div>
                        <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 1">
                            <!--Only appears on Damage-->
                            <div class="subsection-title">{{ 'claims.edit.review.reviewAndSubmit.reviewList.repairable'
                                | translate }}
                            </div>
                            <div class="subsection-text">{{ repairableDisplay }}</div>
                        </div>
                        <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 1">
                            <!--Only appears on Damage-->
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.packageLocation' | translate }}</div>
                            <div class="subsection-text" *ngIf="!whatHappenedFormGroup.get('damageOther').value">{{
                                locationDisplay }}
                            </div>
                            <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('damageOther').value">
                                {{ whatHappenedFormGroup.get('damageOther').value }}</div>
                        </div>
                        <div class="subsection" *ngIf="whatHappenedFormGroup.get('reason').value == 2">
                            <!--Only appears on Missing-->
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.packageLocation' | translate }}</div>
                            <div class="subsection-text" *ngIf="!whatHappenedFormGroup.get('missingOther').value">
                                {{ locationDisplay }}</div>
                            <div class="subsection-text" *ngIf="whatHappenedFormGroup.get('missingOther').value">
                                {{ whatHappenedFormGroup.get('missingOther').value }}</div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="subsection">
                            <div class="subsection-title">
                                {{ 'claims.edit.review.reviewAndSubmit.reviewList.uploadedFiles' | translate }}</div>
                            <div class="subsection-text documents" *ngFor="let file of fileList">
                                {{ file.file.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="additional-contents">
                    <div class="subsection wide">
                        <div class="subsection-title">
                            {{ 'claims.edit.review.reviewAndSubmit.reviewList.merchandiseDescription' | translate }}
                        </div>
                        <div class="subsection-text break">{{ whatHappenedFormGroup.get('description').value }}</div>
                    </div>
                    <div class="subsection wide">
                        <div class="subsection-title">
                            {{ 'claims.edit.review.reviewAndSubmit.reviewList.additionalComments' | translate }}</div>
                        <div class="subsection-text break mb-md-4">{{
                            whatHappenedFormGroup.get('additionalComments').value }}</div>
                    </div>
                </div>
            </div>

            <!-- claim receipent section -->
            <div class="d-flex justify-content-between flex-column flex-md-row">
                <div class="section width-45">
                    <div class="section-title">
                        {{ 'claims.edit.review.reviewAndSubmit.reviewList.claimPaymentReceipent' | translate }}
                        <span class="edit" (click)="edit('contact')">
                            <mat-icon>mode_edit</mat-icon>
                        </span>
                    </div>
                    <hr>
                    <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
                        <div class="column mr-md-2">
                            <div class="subsection mb-0">
                                <div class="subsection-title break" *ngIf="payeePaymentMethod == 'Check'">{{
                                    'claims.edit.review.reviewAndSubmit.reviewList.check' | translate }}</div>
                                <div class="subsection-title break" *ngIf="payeePaymentMethod == 'ACH'">{{
                                    'payee.table.achelectronic' |
                                    translate }}</div>
                                <div *ngIf="payeePaymentMethod == 'ACH'">{{accountNumberLast4}}</div>
                                <div class="subsection-title break" *ngIf="payeePaymentMethod == 'Zelle'">{{
                                    'payee.table.zelle' | translate }}</div>
                                <div *ngIf="payeePaymentMethod == 'Zelle'">{{accountNumberLast4}}</div>
                            </div>
                            <div class="subsection">
                                <div class="subsection-text break">{{ contactInfoFormGroup.controls.addressLine1.value
                                    }}</div>
                                <div class="subsection-text break">{{ contactInfoFormGroup.controls.addressLine2.value
                                    }}</div>
                                <div class="subsection-text">{{ contactInfoFormGroup.controls.city.value }} {{
                                    contactInfoFormGroup.controls.state.value }}</div>
                                <div class="subsection-text">{{ contactInfoFormGroup.controls.zip.value }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section width-45 mb-3 mb-lg-5">
                    <div class="section-title">
                        {{ 'claims.edit.review.reviewAndSubmit.reviewList.contactInfo' | translate }}
                        <span class="edit" (click)="edit('contact')">
                            <mat-icon>mode_edit</mat-icon>
                        </span>
                    </div>
                    <hr>
                    <div class="contents d-flex flex-column flex-md-row justify-content-md-between">
                        <div class="subsection ie mb-0">
                            <div class="subsection-title">{{
                                'claims.edit.review.reviewAndSubmit.reviewList.contactDetails' |
                                translate }}</div>
                            <div class="subsection-text">{{ claim.mainContact.firstName}} {{ claim.mainContact.lastName
                                }}</div>
                            <div class="subsection-text">{{ claim.mainContact.workNumber | phoneNumber }}</div>
                            <div class="subsection-text break">{{ claim.mainContact.emailAddress1 }}
                            </div>
                        </div>
                        <div class="subsection blank"></div>
                    </div>
                </div>
            </div>
            <div class="section" *ngIf="tupssType == 'tccp' || tupssType == 'tfcp'">
                <hr>
            </div>
            <div class="checkbox" *ngIf="tupssType == 'tccp' || tupssType == 'tfcp'">
                <mat-checkbox formControlName="checkboxFormControl"
                    class="d-block blue-background-checkbox-rounded claim-agreement-layout" *ngIf="tupssType=='tccp'">
                    {{ 'claims.edit.review.reviewAndSubmit.tccpLegal' | translate }}
                    <a class="white-link" href="../../../../../assets/docs/TUPSS Claim Portal TCs - Rev. 5.1.24.pdf"
                        target="_blank">{{ 'claims.edit.review.reviewAndSubmit.tccpLegalLink' | translate
                        }}</a>
                    {{ 'claims.edit.review.reviewAndSubmit.tccpLegal2' | translate }}
                </mat-checkbox>
                <mat-checkbox formControlName="checkboxFormControl"
                    class="d-block blue-background-checkbox-rounded claim-agreement-layout" *ngIf="tupssType=='tfcp'">
                    {{ 'claims.edit.review.reviewAndSubmit.tfcpLegal' | translate }}
                </mat-checkbox>
                <!-- <mat-checkbox formControlName="checkboxFormControl" class="d-block blue-background-checkbox-rounded claim-agreement-layout" *ngIf="dataService.flowType == 'my-choice'">
            Accept the terms here.
          </mat-checkbox> -->
            </div>
            <mat-error class="standalone"
                *ngIf="submitClaimFormGroup.get('checkboxFormControl').errors?.required && submitClaimFormGroup.controls['checkboxFormControl']?.touched">
                {{ 'claims.edit.review.reviewAndSubmit.required' | translate }}</mat-error>
        </div>
    </form>
    <div class="step-controls">
        <div>
            <a class="previous-step" href="#" (click)="previousStep($event)">
                {{ 'claims.edit.back' | translate }}
            </a>
            <a class="reset-step" (click)="openCancelDialog($event)">
                {{ 'claims.edit.cancel' | translate }}
            </a>
        </div>
        <div>
            <button class="next-step" role="button" type="button" (click)="submitClaim($event)" *ngIf="!continuing">
                {{ 'claims.edit.submit' | translate }}
            </button>
            <button class="next-step loading d-flex align-items-center justify-content-between" role="button"
                type="button" *ngIf="continuing">
                <div>{{ 'claims.edit.submit' | translate }}</div>
                <div class="spinner-border" role="status">
                    <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
                </div>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #successClaimTemplate>
    <div class="success-wrapper d-flex flex-column align-items-center">
        <!-- Tupss -->
        <div class="content-wrapper" *ngIf="tupssType == 'tfcp' || tupssType == 'tccp'">
            <div class="title">
                {{ 'claims.edit.success.title' | translate }}
            </div>
            <div class="description1">
                {{ 'claims.edit.success.tupssDescr1' | translate }}
                <span class="description1">{{ submittedClaimNumber }}</span>.
            </div>
            <div class="description2" *ngIf="!isGuestUser && tupssType == 'tfcp'">
                {{ 'claims.edit.success.tupssTfcpDescr2' | translate }}
            </div>
            <div class="description2" *ngIf="isGuestUser && isCanadaUser">
                {{ 'claims.edit.success.tupssDescr2' | translate }}
            </div>
            <div class="description2" *ngIf="isGuestUser && !isCanadaUser">
                {{ 'claims.edit.success.tupssGuestDescr2' | translate }}
            </div>
            <div class="description3" *ngIf="!isCanadaUser && tupssType == 'tccp'">
                {{ 'claims.edit.success.tupssTccpDescr2' | translate }}
                <span *ngIf="!isGuestUser">
                    {{ 'claims.edit.success.tupssTccpDescrAllClaims' | translate }}
                    <a (click)="exitToClaimDetails($event)" class="claims-link">{{
                        'claims.edit.success.tupssTccpDescr2Bold1' |
                        translate }}</a>
                    {{ 'claims.edit.success.tupssTccpDescrAllClaimsEnd' | translate }}
                </span>
                {{ 'claims.edit.success.tupssTccpDescr2Continued' | translate }}
                <a [routerLink]="['/tupss/faqs']" class="claims-link">{{ 'claims.edit.success.tupssTccpDescr2Bold2' |
                    translate
                    }}</a>{{ 'claims.edit.success.tupssTccpDescr2dot' | translate }}
            </div>
        </div>
        <div class="start-new-claim-wrapper" *ngIf="tupssType == 'tfcp' || tupssType == 'tccp'">
            <button class="start-new-claim claims mr-3 mb-2" *ngIf="isCanadaUser && !isGuestUser"
                [disabled]="disableCDButtons" (click)="backToClaims($event)">{{ 'claims.edit.success.backtoClaims' |
                translate }}</button>
            <button class="start-new-claim" (click)="printClaim($event)" [disabled]="disableCDButtons">{{
                'claims.edit.success.printClaim' | translate
                }}</button>
            <button class="start-new-claim" (click)="exitToClaimDetails($event)" [disabled]="disableCDButtons">{{
                'claims.edit.success.exitToClaimDetails' |
                translate }}</button>
        </div>

        <!-- My Choice & Customized Claims Portal -->
        <div class="content-wrapper">
            <em class="material-icons upsgreen600 md-96">check_circle</em>
            <div class="title my-choice">
                {{ 'claims.edit.success.titleExclaim' | translate }}
            </div>
            <div class="description1">
                {{ 'claims.edit.success.descr1' | translate }}
                <span class="description1">{{ submittedClaimNumber }}</span>.
            </div>
            <div class="description3">
                {{ 'claims.edit.success.tupssTccpDescr2' | translate }}
                <span>
                    {{ 'claims.edit.success.tupssTccpDescrAllClaims' | translate }}
                    <a (click)="exitToClaimDetails($event)" class="claims-link">{{
                        'claims.edit.success.tupssTccpDescr2Bold1' | translate }}</a>
                    {{ 'claims.edit.success.tupssTccpDescrAllClaimsEnd' | translate }}
                </span>
            </div>
        </div>
        <div class="start-new-claim-wrapper">
            <button class="start-new-claim my-choice" (click)="exitToClaimDetails($event)">
                {{ 'claims.edit.success.exitToClaimDetails' | translate }}
            </button>
        </div>
    </div>
</ng-template>