import { Directive, HostBinding, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {LocationStrategy} from '@angular/common';

@Directive({
  selector: 'a[upscRouterLink]'
})
export class UPSCRouterLink implements OnChanges{
  private commands: any[] = [];
  @HostBinding() href: string;

  constructor(
    private router: Router,
    private locationStrategy: LocationStrategy
  ) {}

  ngOnChanges(changes: SimpleChanges): any {
    /* if(sessionStorage.getItem('localefrmURL')) {
      this.commands.push(sessionStorage.getItem('localefrmURL'));
    }
    this.updateTargetUrlAndHref(); */
  }

  @HostListener(
    'click',
    ['$event', '$event.button', '$event.ctrlKey', '$event.shiftKey', '$event.altKey', '$event.metaKey'])
  onClick(event, button: number, ctrlKey: boolean, shiftKey: boolean, altKey: boolean, metaKey: boolean):boolean {
    if (button !== 0 || ctrlKey || shiftKey || altKey || metaKey) {
      return true;
    }
    event.preventDefault();
    let locale = sessionStorage.getItem('localefrmURL');
    if(locale && locale.trim() != '' && locale.toLowerCase() != 'en') {
      this.commands.push(locale);
    }
    this.router.navigateByUrl(this.urlTree);
  }

  @Input()
  set upscRouterLink(commands: any[]|string|null|undefined) {
    if (commands != null) {
      this.commands = Array.isArray(commands) ? commands : [commands];
    } else {
      this.commands = [];
    }

    this.updateTargetUrlAndHref();
  }

  private updateTargetUrlAndHref(): void {
    this.href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.urlTree));
  }

  get urlTree(): UrlTree {
    return this.router.createUrlTree(this.commands);
  }
}
