<div class="main">

    <div *ngIf="isPagination" class="Claims-for-Flexible d-flex flex-column flex-lg-row justify-content-between">
        <div class="searchBlock">
            <div class="value-wrap d-flex flex-md-row mb-1">
                <div class="searchWrapper">
                    <mat-form-field class="value-wrap">
                        <input matInput placeholder="Enter Claim Number" [(ngModel)]="searchClaim"
                            (keyup)="trim($event)" name="searchClaim"
                            [formControl]="searchByClaimControl">
                        <mat-icon matSuffix *ngIf="searchClaim.length > 0"
                            (click)="clearSearch()">{{'close'}}</mat-icon>
                    </mat-form-field>
                </div>
                <div class="toggleSearch">
                    <button type="button" *ngIf="searchClaim.length > 0" class="upsc-button" upscDebounce
                        [debounceTime]="1000" (debounceClick)="getSummaryByClaimNumber()" (click)="markedAsTouched()">
                        {{'claims.list.claimsSearch.defaultSearch.title' |translate }}
                    </button>
                    <button type="button" *ngIf="searchClaim.length === 0" [disabled]="true" class="upsc-button">
                        {{'claims.list.claimsSearch.defaultSearch.title' |translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="claimInfo d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-1 pt-3">
        <div class="value-wrap2">{{ 'claims.MessageList.ClaimsMessages' | translate }}</div>
    </div>

    <div class="claims-container">
        <div class="claim-table">
            <table class="upsc-table">
                <tbody>
                    <tr>
                        <th class="headerHeightPadded">{{ 'claims.MessageList.MessageTable.claimNumber' | translate }}
                        </th>
                        <!-- claim number -->
                        <th class="headerHeight">{{ 'claims.MessageList.MessageTable.messages' | translate }}</th>
                        <th class="headerHeight">{{ 'claims.MessageList.MessageTable.lastMessageReceived' | translate }}
                        </th>
                        <th class="headerHeight">{{ 'claims.MessageList.MessageTable.time' | translate }}</th>
                        <th class="headerHeight"></th>
                    </tr>

                    <tr *ngIf="emptyClaimResult || pagedItems.length === 0">
                        <td colspan="5" class="no-results-found">
                            &nbsp;
                        </td>
                    </tr>
                    <tr *ngIf="!isSearchClick && pagedItems.length === 0">
                        <td colspan="5" class="noMessages">
                            {{ "claims.MessageList.noMessages" | translate }}
                        </td>
                    </tr>
                    <tr *ngIf="isSearchClick && pagedItems.length === 0">
                        <td colspan="5" class="noMessages">
                            {{ "claims.list.searchModifyCriteria" | translate }}
                        </td>
                    </tr>
                    <tr *ngIf="emptyClaimResult || pagedItems.length == 0">
                        <td colspan="5" class="no-results-found">
                            &nbsp;
                        </td>
                    </tr>
                    <tr *ngIf="emptyClaimResult || pagedItems.length == 0">
                        <td colspan="5" class="no-results-found">
                            &nbsp;
                        </td>
                    </tr>

                    <ng-template [ngIf]="!emptyClaimResult">
                        <tr *ngFor="let row of pagedItems; let i = index"
                            [class.selected]="row.claimNumber && row.claimNumber === currentClaim">
                            <td *ngIf="!row.isMessageRead">
                                <ul class="styleListDisc">
                                    <li><a class="link" (click)="claimDetail(row.claimNumber)">{{row.claimNumber}}</a>
                                    </li>
                                </ul>
                            </td>
                            <td *ngIf="row.isMessageRead">
                                <ul class="styleListNone">
                                    <li><a class="link" (click)="claimDetail(row.claimNumber)">{{row.claimNumber}}</a>
                                    </li>
                                </ul>
                            </td>

                            <td *ngIf="!row.isMessageRead" class="styleBoldFont">{{row.body }}</td>
                            <td *ngIf="row.isMessageRead">{{row.body }}</td>

                            <td>{{ row.time | date }}</td>
                            <td>{{ row.time | date:'shortTime' }}</td>
                            <td>
                                <img *ngIf="!emptyClaimResult" class="pointerImage" (click)="delete(row);"
                                    src="../../../../assets/images/delete_icon.svg">
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>

        <!-- Mobile -->
        <div class="claim-accordion">          

            <mat-accordion class="upsc-accordion">
              <table *ngIf="pagedItems.length===0" class="upsc-table">
                    <tr>
                        <td colspan="5" class="no-results-found">
                            &nbsp;
                        </td>
                    </tr>
                    <tr *ngIf="!isSearchClick && pagedItems.length === 0">
                        <td colspan="5" class="noMessages">
                            {{ "claims.MessageList.noMessages" | translate }}
                        </td>
                    </tr>
                    <tr *ngIf="isSearchClick && pagedItems.length === 0">
                        <td colspan="5" class="noMessages">
                            {{ "claims.list.searchModifyCriteria" | translate }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" class="no-results-found">
                            &nbsp;
                        </td>
                    </tr>
                </table>

                <mat-expansion-panel class="panel" *ngFor="let row of pagedItems; let i = index"
                    [disabled]="emptyClaimResult">
                    <mat-expansion-panel-header class="panel-header">

                        <ng-template [ngIf]="emptyClaimResult" [ngIfElse]="panelHearder">
                            <div *ngIf="pagedItems.length===0" class="centerBolder">
                                {{ "claims.MessageList.noMessages" | translate }}
                            </div>                           
                        </ng-template>

                        <ng-template #panelHearder>
                            <mat-panel-title class="panel-header-title d-flex align-items-center">
                                {{!emptySearchResult ? "#&nbsp;" : ""}}
                                <a class="link" (click)="claimDetail(row.claimNumber)">
                                    <span *ngIf="!row.isMessageRead"><b>{{row.claimNumber}}</b></span>
                                    <span *ngIf="row.isMessageRead">{{row.claimNumber}}</span>
                                </a>
                            </mat-panel-title>
                            <mat-panel-description class="panel-header-desc">
                                <div class="trash-space">
                                    <img *ngIf="!emptySearchResult" class="pointerImage" (click)="delete(row);"
                                        src="../../../../assets/images/delete_icon.svg">
                                </div>
                            </mat-panel-description>
                        </ng-template>
                    </mat-expansion-panel-header>

                    <div class="d-flex panel-content-row">
                        <div class="panel-content-title">
                            {{ 'claims.MessageList.MessageTable.messages' | translate }}
                        </div>
                        <div class="panel-content-desc">
                            <span *ngIf="!row.isMessageRead"><b>{{ row.body }}</b></span>
                            <span *ngIf="row.isMessageRead">{{ row.body }}</span>
                        </div>
                    </div>

                    <div class="d-flex panel-content-row">
                        <div class="panel-content-title">
                            {{ 'claims.MessageList.MessageTable.lastMessageReceived' | translate }}
                        </div>
                        <div class="panel-content-desc">
                            <span>{{ row.time | date }}</span>
                        </div>
                    </div>

                    <div class="d-flex panel-content-row">
                        <div class="panel-content-title">
                            {{ 'claims.MessageList.MessageTable.time' | translate }}
                        </div>
                        <div class="panel-content-desc">
                            <div>{{ row.time | date:'shortTime' }}</div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="paginationAlign" *ngIf="isPagination && !emptyClaimResult">
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                <li [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="compact-link" (click)="setNewPage(1)">{{ 'claims.list.Claimstable.first' | translate }}</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="compact-link" (click)="setNewPage(pager.currentPage - 1)"><em
                            class="material-icons">chevron_left</em></a>
                </li>
                <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                    <a (click)="setNewPage(page)">{{page}}</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="compact-link" (click)="setNewPage(pager.currentPage + 1)"><em
                            class="material-icons">chevron_right</em></a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="compact-link" (click)="setNewPage(pager.totalPages)">{{ 'claims.list.Claimstable.last' |
                        translate
                        }}</a>
                </li>
            </ul>
        </div>

    </div>
</div>