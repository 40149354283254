import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'upsc-address-change-confirmation',
  templateUrl: './address-change-confirmation.component.html',
  styleUrls: ['./address-change-confirmation.component.scss']
})
export class AddressChangeConfirmationComponent implements OnInit {

  messageData = {
    title: "",
    message:""
  };

  constructor(
    private dialogRef: MatDialogRef<AddressChangeConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.messageData = this.data;
  }

  close() {
    this.dialogRef.close("Cancel");
  }

  update() {
    this.dialogRef.close("Update");
  }

}
