import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'upsc-advanced-search-popup',
  templateUrl: './advanced-search-popup.component.html',
  styleUrls: ['./advanced-search-popup.component.scss'] 
})

export class AdvancedSearchPopupComponent {
  // locales
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;

  //EA
  isEAUser: boolean;

  // advanced search form variables
  dateTypeOptions: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }, Validators.required,);
  lastXDaysOptions: UntypedFormControl = new UntypedFormControl({ value: '', disabled: true }, Validators.required,);
  startDateControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: true }, Validators.required,);
  endDateControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: true }, Validators.required,);
  amountRangeOptions: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }, Validators.required,);
  currencyTypeControl: UntypedFormControl;
  minAmountControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: true }, Validators.required,);
  maxAmountControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: true }, Validators.required,);
  statusControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false });
  receiverControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }, Validators.minLength(3)); //not keeping the 3 character minimum requirement...?
  accountControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false });
  invoiceControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false });
  dataSourceControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false });
  shipperName: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }); //EA only
  shipperNumber: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }); //EA only

  // form-related variables
  cutoffDate;
  loading: boolean = false;
  currencyList = [];
  isCAD: boolean = false;
  isGBP: boolean = false;
  isEUR: boolean = false;
  isUSD: boolean = false;
  selectStatusOptions = [];
  selectDataSources = [
    { value: 'api', label: this.translateService.instant('advancedSearch.dataSources.api') },
    { value: 'smart-upload', label: this.translateService.instant('advancedSearch.dataSources.smart') },
    { value: 'wh-shipstation', label: this.translateService.instant('advancedSearch.dataSources.wh') },
    { value: 'ups', label: this.translateService.instant('advancedSearch.dataSources.ups') },
    { value: 'dir', label: this.translateService.instant('advancedSearch.dataSources.dir') }
  ];
  maxDate: any;
  // misc and outputs
  searchSource: any;
  invoices;
  searchFilters: any = {};
  @Output() searchResults = new EventEmitter<any>();
  @Output() removeFilter = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<AdvancedSearchPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver,
    public userService: UserService,
    private translateService: TranslateService
  ) {
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.isEAUser = this.userService.isEAUserorNonEA();

    // define available currency types by locale
    this.currencyTypeControl = new UntypedFormControl({ value: this.isCanadaUser ? 'CAD': this.isUkUser ? 'GBP': (this.isGermanyUser ||  this.isItalyUser || this.isFranceUser)? 'EUR': this.isUsaUser ? 'USD' : '', disabled: true }, Validators.required,);

    if (this.isCanadaUser) {
      this.currencyList = [ {value: 'CAD', label: 'CAD'}];
      this.isCAD = true;
    } else if (this.isUkUser) {
      this.currencyList = [ {value: 'GBP', label: 'GBP'}, {value: 'USD', label: 'USD'}];
      this.isGBP = true;
    } else if (this.isGermanyUser || this.isFranceUser || this.isItalyUser) {
      this.currencyList = [ {value: 'EUR', label: 'EUR'}, {value: 'USD', label: 'USD'}];
      this.isEUR = true;
    } else if (this.isUsaUser) {
      this.currencyList = [ {value: 'USD', label: 'USD'}];
      this.isUSD = true;
    }

    // this is so that when the pop-up opens, by default the min/max inputs are blank, and don't display '0.00'
    this.minAmountControl.reset();
    this.maxAmountControl.reset();
    // when we open this mat dialog, we specified if the search is coming from the claims or billing page
    this.searchSource = data.searchSource; // 'billing' or 'claims'

    if (this.searchSource == 'billing') {
      if (this.isUsaUser || this.isCanadaUser) {
        this.selectStatusOptions = [
          { value: 'Due', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.due') },
          { value: 'Billed', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.billed') },
          { value: 'Paid', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.paid') },
          { value: 'Payment Approved', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.approved') },
          { value: 'Payment Being Drafted', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.being_drafted') },
          { value: 'Payment Declined', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.declined') },
          { value: 'Payment Drafted', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.drafted') },
          { value: 'Payment Received', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.received') },
          { value: 'Payment Reversed', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.reversed') },
          { value: 'Payment Transaction Error', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.transaction') },
          { value: 'Refunded', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.refunded') }
        ];
      } else {
        this.selectStatusOptions = [
          { value: 'Due', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.due') },
          { value: 'Billed', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.billed') },
          { value: 'Paid', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.paid') },
          { value: 'Refunded', label: this.translateService.instant('advancedSearch.selectStatusOptionsBilling.refunded') }
        ];
      }

      this.maxDate = new Date();

      // If user has previously selected filters and hasn't reset them yet, pre-populate form with said filters intact
      if (data.filters) { this.prepopulateFilters(data.filters); }

    } else if (this.searchSource == 'claims') {
      this.selectStatusOptions = [
        { value: 'Paid', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.paid') },
        { value: 'Documents Pending', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.docPending') },
        { value: 'Closed', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.closed') },
        { value: 'Claim Received', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.received') },
        { value: 'Withdrawn', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.withdrawn') },
        { value: 'Submitted for Payment', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.submitted') },
        { value: 'Denied', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.denied') },
        { value: 'Investigation Pending', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.investigation') },
      ];

      this.maxDate = "2094-02-13T22:36:00.551Z"; //hardcode max date to something way in the future

      // If user has previously selected filters and hasn't reset them yet, pre-populate form with said filters intact
      if (data.filters) { 
        this.prepopulateFilters(data.filters);
      }
    } else if (this.searchSource == 't-history') {
      this.selectStatusOptions = [  //Same as claim statuses
        { value: 'Paid', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.paid') },
        { value: 'Documents Pending', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.docPending') },
        { value: 'Closed', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.closed') },
        { value: 'Claim Received', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.received') },
        { value: 'Withdrawn', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.withdrawn') },
        { value: 'Submitted for Payment', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.submitted') },
        { value: 'Denied', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.denied') },
        { value: 'Investigation Pending', label: this.translateService.instant('advancedSearch.selectStatusOptionsClaims.investigation') },
      ];

      if (data?.isRecent) {
        this.invoiceControl.disable();
      }

      //Shipdatesearch should always be enabled for t-history
      this.startDateControl.enable();
      this.endDateControl.enable();
      this.dateTypeOptions.setValue('shipDate');
    }
  }

  prepopulateFilters(presetFilters: any) {
    //date
    if (presetFilters?.dateFilterSelected?.lastXDaysOption) {
      this.dateTypeOptions.setValue(presetFilters.dateFilterSelected.dateType);
      this.lastXDaysOptions.enable();
      let rangeSelected = presetFilters.dateFilterSelected.lastXDaysOption;
      if (rangeSelected == 'last7Days' || rangeSelected == 'last15Days' || rangeSelected == 'last30Days') {
        this.lastXDaysOptions.setValue(rangeSelected);
      } else if (rangeSelected == 'customDateRange') {
        this.lastXDaysOptions.setValue('customDateRange');
        this.startDateControl.enable();
        this.endDateControl.enable();
        this.startDateControl.setValue(presetFilters.dateFilterSelected.startDate);
        this.endDateControl.setValue(presetFilters.dateFilterSelected.endDate);
      }
    }

    //amount
    if (presetFilters?.amountFilterSelected) {
      this.currencyTypeControl.enable();
      this.minAmountControl.enable();
      this.maxAmountControl.enable();
      this.setCurrencyType(presetFilters.amountFilterSelected.currencyType);
      this.amountRangeOptions.setValue(presetFilters.amountFilterSelected.amountType);
      this.minAmountControl.setValue(presetFilters.amountFilterSelected.minAmount);
      this.maxAmountControl.setValue(presetFilters.amountFilterSelected.maxAmount);
    }

    // status, receiver, account
    this.statusControl.setValue(presetFilters?.statusFilterSelected?.status);
    this.receiverControl.setValue(presetFilters?.statusFilterSelected?.receiver);
    this.accountControl.setValue(presetFilters?.statusFilterSelected?.account);

    //EA
    this.shipperName.setValue(presetFilters?.eaFiltersSelected?.shipperName);
    this.shipperNumber.setValue(presetFilters?.eaFiltersSelected?.shipperNumber);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dateTypeOptions.valueChanges.subscribe(
      val => {
        if (val) {
          // Once date type is picked, auto-select the 7 day option for the user if no option has been selected yet
          if (!this.lastXDaysOptions.value) {
            this.lastXDaysOptions.enable();
            this.lastXDaysOptions.setValue('last7Days');
          }
        }
       }
    );

    this.lastXDaysOptions.valueChanges.subscribe(
      val => {
        if (val) {
          if (val == 'customDateRange') {
            this.startDateControl.enable();
            this.endDateControl.enable();
            this.startDateControl.markAsTouched();
            this.endDateControl.markAsTouched();
          } else {
            this.startDateControl.reset();
            this.startDateControl.disable();
            this.endDateControl.reset();
            this.endDateControl.disable();
          }
        }
      }
    )
    
    this.amountRangeOptions.valueChanges.subscribe(
      val => { 
        if (val) {
          this.currencyTypeControl.enable();
          this.minAmountControl.enable();
          this.maxAmountControl.enable();
          this.minAmountControl.markAsTouched();
          this.maxAmountControl.markAsTouched();
        }
      }
    );

    this.startDateControl.valueChanges.subscribe(
      val => { this.checkDateRange('startDateControl'); }
    );

    this.endDateControl.valueChanges.subscribe(
      val => { this.checkDateRange('endDateControl'); }
    );

    this.currencyTypeControl.valueChanges.subscribe(
      val => { this.setCurrencyType(val); }
    );

    this.minAmountControl.valueChanges.subscribe(
      val => { this.checkAmountRange('minAmountControl'); }
    );

    this.maxAmountControl.valueChanges.subscribe(
      val => { this.checkAmountRange('maxAmountControl'); }
    );
  }

  checkDateRange(source: any) {

    if (this.startDateControl.value && this.endDateControl.value) {
      this.startDateControl.setErrors(null);
      this.endDateControl.setErrors(null);

      this.cutoffDate = new Date(this.endDateControl.value);
      this.cutoffDate.setDate(this.endDateControl.value.getDate() - 30)

      if (this.startDateControl.value > this.endDateControl.value) {
        if (source == 'startDateControl') { this.startDateControl.setErrors({ 'invalidRange': true }); }
        if (source == 'endDateControl') { this.endDateControl.setErrors({ 'invalidRange': true }); }
      }
    }
  }

  checkAmountRange(source: any) {
    if (this.minAmountControl.value && this.maxAmountControl.value) {
      this.minAmountControl.setErrors(null);
      this.maxAmountControl.setErrors(null);

      if (this.minAmountControl.value > this.maxAmountControl.value) {
        if (source == 'minAmountControl') { this.minAmountControl.setErrors({ 'invalidRange': true });}
        if (source == 'maxAmountControl') { this.maxAmountControl.setErrors({ 'invalidRange': true }); }
      }
    }
  }

  setCurrencyType(currencyType: any) {
    this.isCAD = false;
    this.isGBP = false;
    this.isEUR = false;
    this.isUSD = false;

    if (currencyType == 'CAD') {
      this.isCAD = true;
    } else if (currencyType == 'GBP') {
      this.isGBP = true;
    } else if (currencyType == 'EUR') {
      this.isEUR = true;
    } else if (currencyType == 'USD') {
      this.isUSD = true;
    }
  }

  reset(source: any) {
    if (source == 'date') {
      this.startDateControl.reset();
      this.endDateControl.reset();
      this.dateTypeOptions.reset();
      this.lastXDaysOptions.reset();
      this.lastXDaysOptions.disable();
    } else if (source == 'amount') {
      this.amountRangeOptions.reset();
      this.currencyTypeControl.disable();
      this.minAmountControl.reset();
      this.maxAmountControl.reset();
      this.minAmountControl.disable();
      this.maxAmountControl.disable();
    } else if (source == 'status') {
      this.statusControl.reset();
      this.receiverControl.reset();
      this.accountControl.reset();
      this.dataSourceControl.reset();
      this.invoiceControl.reset();
      this.shipperName.reset();
      this.shipperNumber.reset();
    } else if (source == 'all') {
      this.startDateControl.reset();
      this.endDateControl.reset();
      this.dateTypeOptions.reset();
      this.lastXDaysOptions.reset();
      this.lastXDaysOptions.disable();
      this.amountRangeOptions.reset();
      this.currencyTypeControl.disable();
      this.minAmountControl.reset();
      this.maxAmountControl.reset();
      this.statusControl.reset();
      this.receiverControl.reset();
      this.accountControl.reset();
      this.shipperName.reset();
      this.shipperNumber.reset();
      this.removeFilter.emit('all');
      this.dialogRef.close();
    }
  }

  continue() {
    this.loading = true;
    this.searchFilters = {};

    // Validation process only needed if that specific filter was selected
    // Date Filter
    if (this.dateTypeOptions?.value) {
      if (this.lastXDaysOptions.value == 'customDateRange') {
        this.searchFilters.dateFilterSelected = {dateType: this.dateTypeOptions.value, lastXDaysOption: this.lastXDaysOptions.value, startDate: this.startDateControl.value, endDate: this.endDateControl.value};
      } else if (this.lastXDaysOptions.value == 'last7Days') {
        let endDate = new Date();
        let startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 7);
        this.searchFilters.dateFilterSelected = {dateType: this.dateTypeOptions.value, lastXDaysOption: this.lastXDaysOptions.value, startDate: startDate, endDate: endDate};
      } else if (this.lastXDaysOptions.value == 'last15Days') {
        let endDate = new Date();
        let startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 15);
        this.searchFilters.dateFilterSelected = {dateType: this.dateTypeOptions.value, lastXDaysOption: this.lastXDaysOptions.value, startDate: startDate, endDate: endDate};
      } else if (this.lastXDaysOptions.value == 'last30Days') {
        let endDate = new Date();
        let startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 30);
        this.searchFilters.dateFilterSelected = {dateType: this.dateTypeOptions.value, lastXDaysOption: this.lastXDaysOptions.value, startDate: startDate, endDate: endDate};
      } else {
        //last X Days Options was not selected. This happens during the transaction-history component. They can search by ship start/end date, but no lastXDays
        this.searchFilters.dateFilterSelected = {dateType: this.dateTypeOptions.value, lastXDaysOption: this.lastXDaysOptions.value, startDate: this.startDateControl.value, endDate: this.endDateControl.value};
      }
    }

    // Amount Filter
    if (this.amountRangeOptions?.value) {
      this.searchFilters.amountFilterSelected = {
        amountType: this.amountRangeOptions.value,
        currencyType: this.currencyTypeControl.value,
        minAmount: this.minAmountControl.value,
        maxAmount: this.maxAmountControl.value
      };
    }

    // Status (Billing) or Search for Account + Status (Claims) Filter or DataSource + Invoice number (transaction history)
    if (this.statusControl?.value || this.receiverControl?.value || this.accountControl?.value || this.dataSourceControl?.value || this.invoiceControl?.value) {
      this.searchFilters.statusFilterSelected = {
        status: this.statusControl.value,
        receiver: this.receiverControl.value,
        account: this.accountControl.value,
        dataSource: this.dataSourceControl.value,
        invoice: this.invoiceControl.value
      };
    }

    if (this.shipperName?.value || this.shipperNumber?.value) {
      this.searchFilters.eaFiltersSelected = {
        shipperName: this.shipperName.value,
        shipperNumber: this.shipperNumber.value
      }
    }
  
    // Emit this.searchFilters back to the original component so that the search can be performed
    setTimeout(()=>  {
      let result = {
        filters: this.searchFilters,
      };
      this.loading = false;
      this.searchResults.emit(result); 
      this.dialogRef.close();
    }, 250);
  }

  close(){
    this.dialogRef.close();
  }

  // logic for custom mat-icon when mat-select page is open
  panelIsOpen: boolean = false;
  panelIsOpen2: boolean = false;
  handleOpenChange() {
    this.panelIsOpen = !this.panelIsOpen;
  }

  handleOpenChange2() {
    this.panelIsOpen2 = !this.panelIsOpen2;
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  get dateFilterSelected() {
    if (this.dateTypeOptions?.value) {
      return true;
    } else {
      return false;
    }
  }

  get amountFilterSelected() {
    if (this.amountRangeOptions?.value) {
      return true;
    } else {
      return false;
    }
  }

  get statusFilterSelected() {
    if (this.statusControl?.value || this.receiverControl?.value || this.accountControl?.value || this.dataSourceControl?.value || this.invoiceControl?.value) {
      return true;
    } else {
      return false;
    }
  }

  get validationErrorsPresent() {
    // Assume there is no error before we start validation process
    let errorExists = false;

    // If date filter is selected, we must validate it
    // Possible Error #1: they haven't chosen an xdays option yet (last 7, last 15, last 30, or custom date range)
    // Possible Error #2: they chose custom date range, but there is a validation error with the start or end date form controls
    if (this.dateFilterSelected && ((this.lastXDaysOptions.invalid) || (this.lastXDaysOptions.value == 'customDateRange' && (this.startDateControl.invalid || this.endDateControl.invalid)))) {
      errorExists = true;
    }

    // If amount filter is selected, we must validate it
    // Possible Error #1: there is a validation error with the minimum or maximum form controls
    if (this.amountFilterSelected && (this.minAmountControl.invalid || this.maxAmountControl.invalid)) {
      errorExists = true;
    }

    // If a value exists for receiver, we must validate it
    // Possible Error #1: there is a validation error with the receiver form control (must be minimum 3 characters)
    if (this.receiverControl?.value && this.receiverControl.invalid) {
      errorExists = true;
    }

    // No validation necessary for status or account

    return errorExists;
  }
}
