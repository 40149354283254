import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { ISelfStep } from '../models/self-stepper-interface';
import { DatePipe } from '@angular/common';
import { SelfEditComponent } from '../../self-edit/self-edit.component';

@Component({
  selector: 'upsc-self-stepper',
  templateUrl: './self-stepper.component.html',
  styleUrls: ['./self-stepper.component.scss'],
  providers: [DatePipe]
})
export class SelfStepperComponent implements OnChanges {
  @HostBinding('class') public hostClass = 'stepper';

  @Input() public steps: ISelfStep[];
  @Input() public currentStep: number;

  public progress: number;

  constructor(private claimComp: SelfEditComponent) {
  }

 

  public ngOnChanges(changes: SimpleChanges) {
    this.onCurrentStepChanged(changes['currentStep']);
  }

  

  private onCurrentStepChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.setProgress();
  }


  public setCurrentStepManual(order: number){
    this.claimComp.setCurrentStepManually(order);
  }

  private setProgress() {
    if (!this.steps || !this.steps.length) {
      this.progress = 0;
      return;
    }

    this.steps.forEach(
      (step) => {
        step.isActive = step.order === this.currentStep;
      },
    );

    this.progress = (this.currentStep - 1) * 100 / (this.steps.length - 1);
  }
}

