import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PolicyInfo } from '../shared/models/policy-info';
import { PolicyService } from './policy.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyEventService {

  //Acting as a source of data, initially initialize to '';
  private policyAddSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  //Allows component to subscribe and listen for updates.
  nameData$ = this.policyAddSubject.asObservable();


  subjectAdded(addedSubject: string) {
    //updating the latest value using the next() method. 
    this.policyAddSubject.next(addedSubject);
  }



  policyInfoList: PolicyInfo[] = [];
  childPolicyList: string[] = [];

  constructor(private policyService: PolicyService) { }

  /*getUserPolicyDetails(userId: any, groupId: any, shipperNumber: any) {
    let request = {
      userId: userId,
      groupId: groupId !== null ? groupId : 0,
      shipperNumber: shipperNumber !== null ? shipperNumber : '',
    };
    this.policyInfoList.splice(0, this.policyInfoList.length);
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    this.policyService.getUserPolicyDetails(request).subscribe(
      response => {
        this.policyInfoList = [];
        this.childPolicyList = [];

        let policyInfo = new PolicyInfo();
        policyInfo.policyNumber = response.data.parentPolicy;
        policyInfo.productType = response.data.parentSubPolicyType !== null ? response.data.parentSubPolicyType : '';
        policyInfo.nickName = response.data.nickname;
        policyInfo.policyHoldersName = response.data.policyHoldersName;
        if (policyDetails.policyNumber === response.data.parentPolicy) {
          policyInfo.isActive = true;
        }
        else {
          policyInfo.isActive = false;
        }
        var parentPolicyExist = this.policyInfoList.find(x => x.policyNumber === response.data.parentPolicy);
        if (!parentPolicyExist) {
          this.policyInfoList.push(policyInfo);
        }

        response.data.childPolicies?.forEach(item => {
          policyInfo = new PolicyInfo();
          policyInfo.policyNumber = item.childPolicyNumber;
          policyInfo.productType = item.childSubPolicyType !== null ? item.childSubPolicyType : '';
          policyInfo.nickName = item.nickname;
          policyInfo.policyHoldersName = item.childHoldersName;
          if (policyDetails.policyNumber === item.childPolicyNumber) {
            policyInfo.isActive = true;
          }
          else {
            policyInfo.isActive = false;
          }
          var isPolicyExist = this.policyInfoList.find(x => x.policyNumber === item.childPolicyNumber);
          if (!isPolicyExist) {
            this.policyInfoList.push(policyInfo);
            this.childPolicyList.push(item.childPolicyNumber);
          }
        });

        sessionStorage.setItem("PolicyCount", this.policyInfoList.length.toString());
        sessionStorage.setItem("UserPolicyDetails", JSON.stringify(this.policyInfoList));
        if (JSON.parse(sessionStorage.getItem('childPolicies')) && JSON.parse(sessionStorage.getItem('childPoliciesEA')).length == 0) {
          sessionStorage.setItem("childPolicies", JSON.stringify(this.childPolicyList));
        }
        return this.policyInfoList;
      },
      error => {
        console.log(error);
      }
    );
    return this.policyInfoList;
  }*/



  getChildPolicies() {
    var isPolicyList = sessionStorage.getItem("childPoliciesEA");
    if (isPolicyList !== null) {
      this.childPolicyList.splice(0, this.policyInfoList.length);
      this.childPolicyList = JSON.parse(sessionStorage.getItem("childPoliciesEA"));
      return this.childPolicyList;
    }
  }
}
