import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PolicyService } from 'src/app/services/policy.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'upsc-delivery-defense-agreement',
  templateUrl: './delivery-defense-agreement.component.html',
  styleUrls: ['./delivery-defense-agreement.component.scss']
})
export class DeliveryDefenseAgreementComponent implements OnInit{

  constructor(public dialogRef: MatDialogRef<DeliveryDefenseAgreementComponent>,
    private policyService: PolicyService,
    private userService: UserService) { }

  ngOnInit(): void {
  }

  @Output() termsWereAccepted = new EventEmitter<any>();
  // isChecked1: boolean = false;
  isChecked: boolean = false;
  loading: boolean = false;
  systemError: boolean = false;
  sessionError: boolean = false;
  
  public close(){
    this.dialogRef.close();
  }

  public continue() {
    let checker = sessionStorage.getItem('policyDetails');

    if (this.isChecked && checker) {
      this.loading = true;
      this.systemError = false;
      this.createDDContractNumber(event);
    } else if (!checker) {
      this.loading = true;
      this.systemError = false;
      this.sessionError = false;
      setTimeout(() => {
        this.loading = false;
        this.sessionError = true;
      }, 400);
    } else {
      // do nothing, as terms were not accepted
    }
  }

  createDDContractNumber(event) {
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    let request = {
      policynumber: policyDetails.policyNumber
    };
    this.policyService.generateDDContractNumber(request).subscribe(
      res => {
        if (res && res.contractNo) {
          policyDetails.ref2 = res.contractNo;
          sessionStorage.setItem('policyDetails', JSON.stringify(policyDetails));
          this.startDeliveryDefense(res.contractNo);
        } else {
          this.loading = false;
          this.systemError = true;
        }
      },
      error => {
        this.loading = false;
        this.systemError = true;
      }
    );
  }

  startDeliveryDefense(contractNum: any) {
    this.loading = true;
    this.systemError = false;
    this.policyService.getDeliveryDefenseUrl({
      emailId: this.userService.getUserInfo().emailAddress,
      contractNumber: contractNum
    }).subscribe(
      res => {
        if(res.data.url && res.data.url != '') {
          this.loading = false;
          window.open(res.data.url);
          this.close();
        } else {
          this.loading = false;
          this.systemError = true;
        }
      },
      error => {
        this.loading = false;
        this.systemError = true;
      }
    );
  }
}
