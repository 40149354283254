import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutComponent } from './flex-layout.component';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { NavigationModule } from '../navigation/navigation.module';
import { RouterModule } from '@angular/router';
import { FlexHeaderComponent } from './flex-header/flex-header.component';
import { FlexAddressValidatorComponent } from './flex-address-validator/flex-address-validator.component';
// import { SafePipe } from '../../pipes/safe.pipe';
import { SharedModule } from '../../shared.module';
import { FlexFooterModule } from '../flex-footer/flex-footer.module';

@NgModule({
  declarations: [
    FlexLayoutComponent,
    FlexHeaderComponent,
    FlexAddressValidatorComponent
  ],
  imports: [
    CommonModule,
    FlexFooterModule,
    HeaderModule,
    RouterModule,
    NavigationModule,
    SharedModule
  ],
  exports: [
    FlexAddressValidatorComponent
  ]
})
export class FlexLayoutModule { }
