<div class="rectangle" [class.firstpage] = "totalwidth == 'firstpage'" [class.otherpages] = "totalwidth == 'otherpages'">
  <div *ngIf="!congrats" class="left" [class.w50] = "percent == 50" [class.w75] = "percent == 75" [class.w99] = "percent == 99" [class.w100] = "percent == 100">
    {{ text }}
  </div>
  <div *ngIf="congrats" class="left" [class.w50] = "percent == 50" [class.w75] = "percent == 75" [class.w99] = "percent == 99" [class.w100] = "percent == 100">
    Congratulations! You are now covered by InsureShield<sup>®</sup> Shipping Insurance.
  </div>
  <div class="right">

  </div>
</div>