<form [formGroup]="searchFormGroup">

    <div class="toolbar-desktop-mobile">
      <div class="input-er-wrapper" *ngIf="showPolicyFilter">
        <mat-form-field>
          <mat-label>{{ 'policies.polNum' | translate }}</mat-label>
          <mat-select formControlName="policy">
            <mat-option *ngFor="let policy of childPolicyList" [value]="policy">{{ policy }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="input-er-wrapper">
        <mat-form-field>
          <mat-label>Select an option</mat-label>
          <mat-select [(ngModel)]="selected" formControlName="searchType" name="searchByValue">
            <mat-option>None</mat-option>
            <mat-option value="email"> {{ 'userManagement.table.email' | translate }}</mat-option>
            <mat-option value="name">{{ 'userManagement.table.name' | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="valueErrors">
            <div class="error-input" *ngIf="searchType.touched && searchType.errors">
              <mat-icon>report_problem</mat-icon>
              <div class="error-text">Please select search option</div>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
  
      <div class="search-bar" [class.disabled]="search.disabled==true">
        <div class="input-er-wrapper">
          <mat-form-field class="custom-width">
            <mat-label>Search User</mat-label>
            <input matInput name="searchQuery" type="text" formControlName="search" [(ngModel)]="srchText">
            <mat-icon matPrefix class="custom-margin pointer">search</mat-icon>
            <mat-icon matSuffix (click)="resetSearch()">close</mat-icon>
            <mat-error *ngIf="valueErrors">
              <div class="error-input" *ngIf="search.touched && search.errors">
                <mat-icon>report_problem</mat-icon>
                <div class="error-text">{{ 'userManagement.errors.search' | translate }}</div>
              </div>
              <div class="error-input" *ngIf="searchType.touched && searchType.errors">
                <mat-icon>report_problem</mat-icon>
                <div class="error-text">{{ 'userManagement.errors.searchBy' | translate }}</div>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="search-button pr-3">
        <div class="input-er-wrapper">
          <button type="button" class="upsc-button" (click)="submitSearch()">
            {{ 'userManagement.arrowButtons.search' | translate }}<mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  
    <div class="toolbar-desktop">
      <div class="toolbar-items">
        <div class="search-bar" [class.disabled]="search.disabled==true">
          <div class="input-er-wrapper mr-3" *ngIf="showPolicyFilter">
            <mat-form-field>
              <mat-label>{{ 'policies.polNum' | translate }}</mat-label>
              <mat-select formControlName="policy">
                <mat-option *ngFor="let policy of childPolicyList" [value]="policy">{{ policy }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="input-er-wrapper">
            <mat-form-field class="custom-width">
              <mat-label>Search User</mat-label>
              <input matInput name="searchQuery" type="text" formControlName="search" [(ngModel)]="srchText">
              <mat-icon matPrefix class="custom-margin pointer">search</mat-icon>
              <mat-icon matSuffix (click)="resetSearch()">close</mat-icon>
              <mat-error *ngIf="valueErrors">
                <div class="error-input" *ngIf="search.touched && search.errors">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'userManagement.errors.search' | translate }}</div>
                </div>
                <div class="error-input" *ngIf="searchType.touched && searchType.errors">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'userManagement.errors.searchBy' | translate }}</div>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="search-button pr-3">
          <button type="button" class="upsc-button" (click)="submitSearch()">
            {{ 'userManagement.arrowButtons.search' | translate }}<mat-icon>chevron_right</mat-icon>
          </button>
        </div>
        <div class="search-category">
          <mat-radio-group formControlName="searchType" name="searchByValue" [(ngModel)]="selected">
            <mat-radio-button [value]="'email'">
              {{ 'userManagement.table.email' | translate }}
            </mat-radio-button>
            <mat-radio-button [value]="'name'">
              {{ 'userManagement.table.name' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </form>