<div class="faqs">
    <div class="center-faqs">
        <div class="page-title" *ngIf="flowType==='freightcenterinc'">InsureShield Shipping Protection </div>
        <div class="page-title-description" *ngIf="flowType==='freightcenterinc'">Internal Partners Claims FAQ</div>

        <div class="page-title" *ngIf="flowType==='upshealthcare'">Claims Portal FAQ</div>
        <div class="page-title-description" *ngIf="flowType==='upshealthcare'">The Claims Portal enables process efficiencies for partners and improved customer  experiences for end-users.  Shippers can now file claims directly with UPS Capital, the claims processor, for faster claims resolution. Here are some frequently asked questions from shippers when filing a claim.</div>

        <upsc-collapsible-panel-light [title]="'ssp.questions.question1.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ssp.questions.question1.explanation' | translate}}"></div>
            <div class="step" innerHTML="{{'ssp.questions.question1.step1' | translate}}"></div>
            <div class="step-detail" innerHTML="{{'ssp.questions.question1.step1Details' | translate}}"></div>
            <div class="step" innerHTML="{{'ssp.questions.question1.step2' | translate}}"></div>
            <div class="step-detail" innerHTML="{{'ssp.questions.question1.step2Details' | translate}}"></div>
            <div class="step" innerHTML="{{'ssp.questions.question1.step3' | translate}}"></div>
            <div class="step-detail" innerHTML="{{'ssp.questions.question1.step3Details' | translate}}"></div>
            <div class="step" innerHTML="{{'ssp.questions.question1.step4' | translate}}"></div>
            <div class="step-detail" innerHTML="{{'ssp.questions.question1.step4Details' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ssp.questions.question2.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ssp.questions.question2.answer' | translate}}"></div>
            <div class="linkTitle" innerHTML="{{'ssp.questions.question2.linkTitle' | translate}}"></div>
            <div class="link">
                <a class="link-color" href="{{'ssp.questions.question2.link' | translate}}" target="_blank">{{'ssp.questions.question2.link' | translate}}</a>
            </div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ssp.questions.question3.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ssp.questions.question3.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ssp.questions.question4.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ssp.questions.question4.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ssp.questions.question5.title' | translate" [isCollapsed]="true">
            <div>{{'ssp.questions.question5.answer1' | translate}} <b>{{'ssp.questions.question5.answer2' | translate}}</b>{{'ssp.questions.question5.answer3' | translate}}</div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ssp.questions.question6.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ssp.questions.question6.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>
    
        <upsc-collapsible-panel-light [title]="'ssp.questions.question7.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ssp.questions.question7.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>    
       
        <upsc-collapsible-panel-light [title]="'ssp.questions.question11.title' | translate" [isCollapsed]="true">
            <div>{{'ssp.questions.question11.answer' | translate}} <a href="mailto:insureshieldsupport@ups.com" class="link">insureshieldsupport@ups.com</a></div>        
        </upsc-collapsible-panel-light>

        <upsc-collapsible-panel-light [title]="'ssp.questions.question12.title' | translate" [isCollapsed]="true">
            <div innerHTML="{{'ssp.questions.question12.answer' | translate}}"></div>
        </upsc-collapsible-panel-light>           
       
    </div> 
</div>