<div class="popup-global-layout width-600 advanced-wrapper">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">{{ 'advancedSearch.title' | translate }}</div>
  <div class="global-green-bar"></div>
  <div class="form-area">
    <!-- Date Range -->
    <div class="global-text d-flex mt-3 mb-3">
      <div *ngIf="searchSource == 'billing' || searchSource == 'claims'">{{ 'advancedSearch.date.range' | translate }}</div>
      <div *ngIf="searchSource == 't-history'">{{ 'advancedSearch.date.shipDate' | translate }}</div>
      <a class="reset ml-auto upsc-link" (click)="reset('date')" *ngIf="dateFilterSelected">{{ 'advancedSearch.reset' | translate }}</a>
    </div>
    <div class="date-range">
      <div class="date-type-selection" *ngIf="searchSource == 'billing' || searchSource == 'claims'">
        <mat-radio-group [formControl]="dateTypeOptions" class="d-flex flex-column justify-content-start">
          <mat-radio-button value="billDate" class="small mr-3" *ngIf="searchSource == 'billing'">{{ 'advancedSearch.date.billDate' | translate }}</mat-radio-button>
          <mat-radio-button value="dueDate" class="small" *ngIf="searchSource == 'billing'">{{ 'advancedSearch.date.dueDate' | translate }}</mat-radio-button>
          <mat-radio-button value="fileDate" class="small mr-3" *ngIf="searchSource == 'claims'">{{ 'advancedSearch.date.fileDate' | translate }}</mat-radio-button>
          <mat-radio-button value="issueDate" class="small" *ngIf="searchSource == 'claims'">{{ 'advancedSearch.date.issueDate' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="quick-date-range-options">
        <mat-radio-group [formControl]="lastXDaysOptions" class="d-flex flex-column justify-content-start">
          <mat-radio-button value="last7Days" *ngIf="searchSource == 'claims' || searchSource == 'billing'">{{ 'advancedSearch.date.last7Days' | translate }}</mat-radio-button>
          <mat-radio-button value="last15Days" *ngIf="searchSource == 'claims' || searchSource == 'billing'">{{ 'advancedSearch.date.last15Days' | translate }}</mat-radio-button>
          <mat-radio-button value="last30Days" *ngIf="searchSource == 'claims' || searchSource == 'billing'">{{ 'advancedSearch.date.last30Days' | translate }}</mat-radio-button>
          <mat-radio-button value="customDateRange" *ngIf="searchSource == 'claims'">{{ 'advancedSearch.date.customDateRange' | translate }}</mat-radio-button>
          <mat-radio-button value="customDateRange" *ngIf="searchSource == 'billing'">{{ 'advancedSearch.date.customDateRange' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="mt-2">
        <mat-form-field class="custom-width cursor mr-4" (click)="picker.open()">
          <mat-label>{{ 'advancedSearch.date.startDate' | translate }}</mat-label>
          <input matInput required readonly [matDatepicker]="picker" [max]="maxDate" [formControl]="startDateControl" name="">
          <mat-datepicker-toggle matSuffix [for]="picker">
            <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
              width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
              <path id="Path_219" data-name="Path 219"
                d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                transform="translate(0.964 0.643)" fill="#737373" />
              <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
            </svg>
          </mat-datepicker-toggle>
          <mat-datepicker [touchUi]="isMobile" #picker></mat-datepicker>
          <mat-error>
            <div *ngIf="startDateControl.invalid && startDateControl.touched" class="mat-error">
              <div *ngIf="startDateControl.errors.required" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.date.startRequired' | translate }}
                </div>
              </div>
              <div *ngIf="startDateControl.errors.invalidRange" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.date.startInvalid' | translate }}
                </div>
              </div>
              <div *ngIf="startDateControl.errors.exceeds30Days" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.date.exceeds30Days' | translate }}
                </div>
              </div>
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="custom-width cursor" (click)="picker1.open()">
          <mat-label>{{ 'advancedSearch.date.endDate' | translate }}</mat-label>
          <input matInput required readonly [matDatepicker]="picker1" [max]="maxDate" [formControl]="endDateControl" name="">
          <mat-datepicker-toggle matSuffix [for]="picker1">
            <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
              width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
              <path id="Path_219" data-name="Path 219"
                d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                transform="translate(0.964 0.643)" fill="#737373" />
              <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
            </svg>
          </mat-datepicker-toggle>
          <mat-datepicker [touchUi]="isMobile" #picker1></mat-datepicker>
          <mat-error>
            <div *ngIf="endDateControl.invalid && endDateControl.touched" class="mat-error">
              <div *ngIf="endDateControl.errors.required" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.date.endRequired' | translate }}
                </div>
              </div>
              <div *ngIf="endDateControl.errors.invalidRange" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.date.endInvalid' | translate }}
                </div>
              </div>
              <div *ngIf="endDateControl.errors.exceeds30Days" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.date.exceeds30Days' | translate }}
                </div>
              </div>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- Amount Range -->
    <div class="global-text d-flex mt-3 mb-3">
      <div>{{ 'advancedSearch.amount.range' | translate }}</div>
      <a class="reset ml-auto upsc-link" (click)="reset('amount')" *ngIf="amountFilterSelected">{{ 'advancedSearch.reset' | translate }}</a>
    </div>
    <div class="amount-range">
      <mat-radio-group [formControl]="amountRangeOptions" class="d-flex flex-column justify-content-start">
        <mat-radio-button value="invoiceAmount" class="small mr-3" *ngIf="searchSource == 'billing'">{{ 'advancedSearch.amount.invoiceAmount' | translate }}</mat-radio-button>
        <mat-radio-button value="amountPayable" class="small" *ngIf="searchSource == 'billing'">{{ 'advancedSearch.amount.amountPayable' | translate }}</mat-radio-button>
        <mat-radio-button value="paidAmount" class="small mr-3" *ngIf="searchSource == 'claims'">{{ 'advancedSearch.amount.paidAmount' | translate }}</mat-radio-button>
        <mat-radio-button value="submittedAmount" class="small" *ngIf="searchSource == 'claims'">{{ 'advancedSearch.amount.submittedAmount' | translate }}</mat-radio-button>
        <mat-radio-button value="insuredValue" class="small mr-3" *ngIf="searchSource == 't-history'">{{ 'advancedSearch.amount.insured' | translate }}</mat-radio-button>
        <mat-radio-button value="premium" class="small" *ngIf="searchSource == 't-history'">{{ 'advancedSearch.amount.premium' | translate }}</mat-radio-button>
      </mat-radio-group>
      <div class="currency-input mt-2" *ngIf="searchSource == 'claims' || searchSource == 'billing'">
        <mat-form-field>
          <mat-select class="currency-select" required [formControl]="currencyTypeControl" [disableOptionCentering]="true" panelClass="advanced-search-bar-spacing" (openedChange)="handleOpenChange2()">
            <mat-option *ngFor="let option of currencyList" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix *ngIf="!panelIsOpen2">keyboard_arrow_up</mat-icon>
          <mat-icon matSuffix *ngIf="panelIsOpen2">keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>
      <div class="">
        <mat-form-field class="amount-input mr-4">
          <mat-label>{{ 'advancedSearch.amount.minimum' | translate }}</mat-label>
          <input matInput type="text" [formControl]="minAmountControl" currencyMask
          [options]="{prefix: isCAD ? 'CAD ' : isGBP ? '£ ' : isEUR ? '€ ' : isUSD ? '$ ' : '', align: 'left', allowNegative: false, precision: 2, inputMode: 'FINANCIAL'}">
          <mat-error>
            <div *ngIf="minAmountControl.invalid && minAmountControl.touched" class="mat-error">
              <div *ngIf="minAmountControl.errors.required" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.amount.minRequired' | translate }}
                </div>
              </div>
              <div *ngIf="minAmountControl.errors.invalidRange" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.amount.minInvalid' | translate }}
                </div>
              </div>
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="amount-input">
          <mat-label>{{ 'advancedSearch.amount.maximum' | translate }}</mat-label>
          <input matInput type="text" [formControl]="maxAmountControl" currencyMask 
          [options]="{prefix: isCAD ? 'CAD ' : isGBP ? '£ ' : isEUR ? '€ ' : isUSD ? '$ ' : '', align: 'left', allowNegative: false, precision: 2, inputMode: 'FINANCIAL'}">
          <mat-error>
            <div *ngIf="maxAmountControl.invalid && maxAmountControl.touched" class="mat-error">
              <div *ngIf="maxAmountControl.errors.required" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.amount.maxRequired' | translate }}
                </div>
              </div>
              <div *ngIf="maxAmountControl.errors.invalidRange" class="d-flex align-items-center errors">
                <div>
                  {{ 'advancedSearch.amount.maxInvalid' | translate }}
                </div>
              </div>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- Status (Billing) / Search for Account + Status (Claims) -->
    <div class="global-text d-flex mt-3 mb-3">
      <div *ngIf="searchSource == 'billing'">{{ 'advancedSearch.status.status' | translate }}</div>
      <div *ngIf="searchSource == 'claims'">{{ 'advancedSearch.status.search' | translate }}</div>
      <div *ngIf="searchSource == 't-history'">{{ 'advancedSearch.status.shipment' | translate }}</div>
      <a class="reset ml-auto upsc-link" (click)="reset('status')" *ngIf="statusFilterSelected">{{ 'advancedSearch.reset' | translate }}</a>
    </div>
    <div class="receiver-account-search" *ngIf="searchSource == 'claims' || searchSource == 't-history'">
      <mat-form-field class="receiver-input mr-4" *ngIf="searchSource == 'claims'">
        <mat-icon matPrefix class="custom-margin pointer">search</mat-icon>
        <mat-label>{{ 'advancedSearch.status.receiver' | translate }}</mat-label>
        <input matInput [formControl]="receiverControl">
        <mat-error>
          <div *ngIf="receiverControl.invalid && receiverControl.touched" class="mat-error">
            <div *ngIf="receiverControl.errors.minlength" class="d-flex align-items-center errors">
              <div>
                {{ 'advancedSearch.status.receiverInvalid' | translate }}
              </div>
            </div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="account-input" *ngIf="searchSource == 'claims'">
        <mat-icon matPrefix class="custom-margin pointer">search</mat-icon>
        <mat-label>{{ 'advancedSearch.status.account' | translate }}</mat-label>
        <input matInput [formControl]="accountControl">
      </mat-form-field>
      <mat-form-field class="invoice-input mr-4" *ngIf="searchSource == 't-history'">
        <mat-icon matPrefix class="custom-margin pointer">search</mat-icon>
        <mat-label>{{ 'advancedSearch.status.invoice' | translate }}</mat-label>
        <input matInput [formControl]="invoiceControl">
      </mat-form-field>
      <mat-form-field class="data-source-input" *ngIf="searchSource == 't-history'">
        <mat-label>{{ 'advancedSearch.status.data' | translate }}</mat-label>
        <mat-select [formControl]="dataSourceControl">
          <mat-option *ngFor="let option of selectDataSources" [value]="option.value">{{ option.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="status-range">
      <mat-form-field class="status-input">
        <mat-label>{{ 'advancedSearch.status.select' | translate }}</mat-label>
        <mat-select [formControl]="statusControl" multiple [disableOptionCentering]="true" panelClass="advanced-search-bar-spacing multiple" (openedChange)="handleOpenChange()">
          <mat-option *ngFor="let option of selectStatusOptions" [value]="option.value" class="checkbox">{{ option.label }}</mat-option>
        </mat-select>
        <mat-icon matSuffix *ngIf="!panelIsOpen">keyboard_arrow_up</mat-icon>
        <mat-icon matSuffix *ngIf="panelIsOpen">keyboard_arrow_down</mat-icon>
      </mat-form-field>
    </div>
    <!-- EA users only -->
    <div class="ea-inputs" *ngIf="isEAUser">
      <mat-form-field class="receiver-input mr-4">
        <mat-icon matPrefix class="custom-margin pointer">search</mat-icon>
        <mat-label>Shipper Name</mat-label>
        <input matInput [formControl]="shipperName">
      </mat-form-field>
      <mat-form-field class="account-input" *ngIf="searchSource == 'claims'">
        <mat-icon matPrefix class="custom-margin pointer">search</mat-icon>
        <mat-label>Shipper Number</mat-label>
        <input matInput [formControl]="shipperNumber">
      </mat-form-field>
    </div>
  </div>
  <!-- Apply/Reset Buttons -->
  <div class="global-button-format">
    <button type="button" class="next-step loading d-flex align-items-center justify-content-between mr-2" *ngIf="loading">
      <div>{{ 'advancedSearch.apply' | translate }}</div>
      <div class="spinner-border" role="status">
        <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
      </div>
    </button>
    <button type="button" class="upsc-green-popup-button" (click)="continue()" *ngIf="!loading" [disabled]="validationErrorsPresent">{{ 'advancedSearch.apply' | translate }}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
    <button type="button" class="upsc-white-button2" (click)="reset('all')">{{ 'advancedSearch.reset' | translate }}<mat-icon class="icon-key">keyboard_arrow_right</mat-icon></button>
  </div>
  <!-- 1000 claims limit messaging -->
  <div class="search-limit" *ngIf="searchSource == 'claims' && (dateFilterSelected || amountFilterSelected || statusFilterSelected)">
    {{ 'advancedSearch.claimsLimit' | translate }}
  </div>
</div>