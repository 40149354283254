<form [formGroup]="whatHappenedFormGroup" class="form-content" (ngSubmit)="saveClaim()">
  <div class="white-on-gray">
    <div class="section mb-3 mb-lg-5">
      <div class="subsection">
        <div class="title">{{ 'claims.edit.whatHappened.reason.title' | translate }}<span class="red">*</span></div>
        <mat-radio-group class="radio-group" formControlName="reason">
          <mat-radio-button class="damage" (click)="toggleFlow('damage')" [value]="1">
            {{ 'claims.edit.whatHappened.reason.tupssDamage' | translate }}
          </mat-radio-button>
          <div class="damage-description" *ngIf="damage">
            <div class="description-label">{{ 'claims.edit.whatHappened.reason.damageDescription' | translate }}</div>
            <mat-form-field class="fill-width">
              <textarea #dmgdescr matInput maxlength="800" formControlName="damageDescription"></textarea>
              <mat-hint align="end">
                {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{800 -
                dmgdescr.value.length}}
              </mat-hint>
              <mat-error *ngIf="whatHappenedFormGroup.get('damageDescription').errors">
                <div *ngIf="whatHappenedFormGroup.get('damageDescription').errors['required']">
                  {{ 'claims.edit.whatHappened.reason.damageDescriptionRequired' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <mat-radio-button class="missing" (click)="toggleFlow('missing')" [value]="2">
            {{ 'claims.edit.whatHappened.reason.missing' | translate }}
          </mat-radio-button>
          <div class="missing-description" *ngIf="missing">
            <div class="description-label">{{ 'claims.edit.whatHappened.reason.missingDescription' | translate }}
            </div>
            <mat-form-field class="fill-width">
              <textarea #missingdescr matInput maxlength="800" formControlName="missingDescription"></textarea>
              <mat-hint align="end">
                {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{800 -
                missingdescr.value.length}}
              </mat-hint>
              <mat-error *ngIf="whatHappenedFormGroup.get('missingDescription').errors">
                <div *ngIf="whatHappenedFormGroup.get('missingDescription').errors['required']">
                  {{ 'claims.edit.whatHappened.reason.missingDescriptionRequired' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </mat-radio-group>
        <mat-error class="standalone"
          *ngIf="whatHappenedFormGroup.get('reason').errors && whatHappenedFormGroup.controls['reason']?.touched">
          {{ 'claims.edit.whatHappened.reason.errors.required' | translate }}
        </mat-error>
        <div class="retain d-flex align-items-center justify-content-center mt-3 p-3" *ngIf="damage || missing">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="28.423" height="27.506" viewBox="0 0 28.423 27.506">
              <g id="Group_448" data-name="Group 448" transform="translate(-1 -2)">
                <g id="Group_447" data-name="Group 447" transform="translate(1 2)">
                  <g id="Group_442" data-name="Group 442" transform="translate(0 0)">
                    <path id="Path_5183" data-name="Path 5183"
                      d="M31.774,36.013a.459.459,0,0,1-.458-.458L31.308,19.75a.459.459,0,0,1,.293-.428l13.611-5.276a.458.458,0,0,1,.624.427V30.3a.458.458,0,0,1-.293.428l-13.6,5.257A.46.46,0,0,1,31.774,36.013Zm.451-15.95.007,14.823,12.687-4.9V15.142Z"
                      transform="translate(-17.414 -8.507)" fill="#e5ad15" />
                    <path id="Path_5184" data-name="Path 5184"
                      d="M14.268,16.2a.466.466,0,0,1-.161-.029l-2.76-1.033a.458.458,0,0,1-.3-.43V11.732a.457.457,0,0,1,.285-.424L24.794,5.8a.458.458,0,0,1,.344,0L27.8,6.863a.458.458,0,0,1,0,.85L14.727,13.073v2.665a.458.458,0,0,1-.458.458Zm-2.3-1.809,1.843.689V12.765a.458.458,0,0,1,.285-.424L26.408,7.294,24.97,6.719l-13,5.321Z"
                      transform="translate(-6.443 -4.04)" fill="#e5ad15" />
                    <path id="Path_5185" data-name="Path 5185"
                      d="M6.074,10.148a.458.458,0,0,1-.164-.03L1.295,8.35A.459.459,0,0,1,1.289,7.5L15.046,2.032a.456.456,0,0,1,.34,0l4.352,1.743a.458.458,0,0,1-.165.884L6.248,10.113A.455.455,0,0,1,6.074,10.148ZM2.72,7.914,6.068,9.2,18.3,4.189,15.214,2.952Z"
                      transform="translate(-1 -2)" fill="#e5ad15" />
                    <path id="Path_5186" data-name="Path 5186"
                      d="M24.056,17a.458.458,0,0,1-.164-.03l-6.527-2.5a.459.459,0,0,1-.295-.428.483.483,0,0,1,.285-.442L30.714,8.123a.457.457,0,0,1,.344,0l6.78,2.716a.458.458,0,0,1-.005.853L24.222,16.967A.466.466,0,0,1,24.056,17Zm-5.306-2.981,5.3,2.031,12.362-4.791L30.889,9.043Z"
                      transform="translate(-9.704 -5.298)" fill="#e5ad15" />
                    <path id="Path_5187" data-name="Path 5187"
                      d="M15.369,35.976a.454.454,0,0,1-.162-.03L1.312,30.689a.458.458,0,0,1-.3-.429V14.4a.459.459,0,0,1,.622-.428l4.576,1.742a.483.483,0,0,1,.312.427.452.452,0,0,1-.266.436.46.46,0,0,1-.378-.01l-3.948-1.5V29.943l12.978,4.91L14.9,20.026l-6.2-2.4a.457.457,0,0,1-.043-.86.478.478,0,0,1,.345-.006l6.52,2.52a.458.458,0,0,1,.293.427l.006,15.806a.458.458,0,0,1-.458.458Z"
                      transform="translate(-1.009 -8.47)" fill="#e5ad15" />
                  </g>
                  <g id="Group_446" data-name="Group 446" transform="translate(2.956 13.906)">
                    <path id="Path_5192" data-name="Path 5192"
                      d="M15.646,36.179a.459.459,0,0,1-.162-.03L7.745,33.221a.458.458,0,1,1,.324-.857l7.74,2.928a.458.458,0,0,1-.162.887Z"
                      transform="translate(-7.448 -32.334)" fill="#e5ad15" />
                    <path id="Path_5194" data-name="Path 5194"
                      d="M7.907,36.179a.459.459,0,0,0,.162-.03l7.739-2.928a.458.458,0,1,0-.324-.857l-7.74,2.928a.458.458,0,0,0,.162.887Z"
                      transform="translate(6.763 -32.334)" fill="#e5ad15" />
                    <path id="Path_5193" data-name="Path 5193"
                      d="M15.646,43.005a.459.459,0,0,1-.162-.03L7.745,40.047a.458.458,0,1,1,.324-.857l7.74,2.928a.458.458,0,0,1-.162.887Z"
                      transform="translate(-7.448 -36.031)" fill="#e5ad15" />
                    <path id="Path_5195" data-name="Path 5195"
                      d="M7.907,43.005a.459.459,0,0,0,.162-.03l7.739-2.928a.458.458,0,1,0-.324-.857l-7.74,2.928a.458.458,0,0,0,.162.887Z"
                      transform="translate(6.763 -36.031)" fill="#e5ad15" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="text ml-3">
            {{ 'claims.edit.retain' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="header">
      <div class="title">
        <div class="text">
          <div class="title-bold"> {{ 'claims.edit.whatHappened.merchandise.title' | translate }}</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="section">
      <div class="subsection">
        <div class="subtitle">{{ 'claims.edit.whatHappened.merchandise.search.tupssTitle' | translate }}<span
            class="red">*</span></div>
        <div class="d-flex flex-column flex-lg-row">
          <mat-form-field class="width-70 mr-lg-3">
            <input type="text"
              placeholder="{{ 'claims.edit.whatHappened.merchandise.search.form.category.label' | translate }}"
              aria-label="category" matInput formControlName="category">
          </mat-form-field>
          <mat-form-field class="width-30">
            <input matInput required formControlName="quantity"
              placeholder="{{ 'claims.edit.whatHappened.merchandise.search.form.quantity.label' | translate }}">
            <mat-error *ngIf="whatHappenedFormGroup.get('quantity').errors">
              <div *ngIf="whatHappenedFormGroup.get('quantity').errors['required']">
                {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.required' | translate }}
              </div>
              <div *ngIf="whatHappenedFormGroup.get('quantity').errors['pattern']">
                {{ 'claims.edit.whatHappened.merchandise.search.form.quantity.errors.pattern' | translate }}
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="section mb-3 mb-lg-5">
      <div class="subsection">
        <div class="subtitle">{{ 'claims.edit.whatHappened.merchandise.description.title' | translate }}<span
            class="red">*</span></div>
        <div class="subtitle squeeze">{{ 'claims.edit.whatHappened.merchandise.description.subtitle' | translate }}
        </div>
        <mat-form-field class="full-width">
          <textarea matInput #descr maxlength="250" formControlName="description"></textarea>
          <mat-hint align="end">
            {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{250 - descr.value.length}}
          </mat-hint>
          <mat-error *ngIf="whatHappenedFormGroup.get('description').errors">
            <div *ngIf="whatHappenedFormGroup.get('description').errors['required']">
              {{ 'claims.edit.whatHappened.merchandise.description.required' | translate }}
            </div>
            <div *ngIf="whatHappenedFormGroup.get('description').errors['maxlength']">
              {{ 'claims.edit.whatHappened.merchandise.description.limit' | translate }}
            </div>
          </mat-error>
        </mat-form-field>
        <div class="merchandise-damage" *ngIf="damage">
          <div class="panel-section-title">
            {{ 'claims.edit.whatHappened.merchandise.description.damage.day.title' | translate }}<span
              class="red">*</span>
          </div>
          <div class="mat-form-fields">
            <mat-form-field class="width-30" (click)="damagePicker.open()">
              <input matInput [matDatepicker]="damagePicker" [max]="maxDate" required readonly
                formControlName="damageDate"
                placeholder="{{ 'claims.edit.whatHappened.merchandise.description.damage.day.label' | translate }}">
              <mat-datepicker-toggle matSuffix [for]="damagePicker">
                <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
                  width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
                  <path id="Path_219" data-name="Path 219"
                    d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                    transform="translate(0.964 0.643)" fill="#737373" />
                  <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                </svg>
              </mat-datepicker-toggle>
              <mat-datepicker [touchUi]="isMobile" #damagePicker></mat-datepicker>
              <mat-error *ngIf="whatHappenedFormGroup.get('damageDate').errors">
                <div *ngIf="whatHappenedFormGroup.get('damageDate').errors['required']">
                  {{ 'claims.edit.whatHappened.merchandise.description.damage.day.required' | translate }}
                </div>
                <div *ngIf="whatHappenedFormGroup.get('damageDate').errors['matDatepickerMin']">
                  {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' | translate }}
                </div>
                <div *ngIf="whatHappenedFormGroup.get('damageDate').errors['matDatepickerMax']">
                  {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panel-section-title damage-repair">
            {{ 'claims.edit.whatHappened.merchandise.description.damage.repair.title' | translate }}<span
              class="red">*</span>
          </div>
          <div class="mat-form-fields">
            <mat-radio-group class="radio-group" formControlName="damageRepairable">
              <mat-radio-button *ngFor="let repairable of repairables" value="{{repairable.id}}">{{ repairable.text }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error class="standalone" style="font-size: 75%"
            *ngIf="whatHappenedFormGroup.get('damageRepairable').errors && whatHappenedFormGroup.controls['damageRepairable']?.touched">
            {{ 'claims.edit.whatHappened.merchandise.description.damage.repair.errors.required' | translate }}
          </mat-error>
          <div class="panel-section-title damage-locations">
            {{ 'claims.edit.whatHappened.merchandise.description.damage.where.title' | translate }}<span
              class="red">*</span>
          </div>
          <div class="mat-form-fields">
            <mat-radio-group class="radio-group" formControlName="damageLocations">
              <mat-radio-button *ngFor="let locations of damagedLocations" value="{{locations.id}}"
                (click)="toggleOther(false)">{{
                locations.text }}</mat-radio-button>
              <mat-radio-button value="3" (click)="toggleOther(true)">{{
                'claims.edit.whatHappened.merchandise.description.damage.where.other' |
                translate }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error class="standalone" style="font-size: 75%"
            *ngIf="whatHappenedFormGroup.get('damageLocations').errors && whatHappenedFormGroup.controls['damageLocations']?.touched">
            {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.required' | translate }}
          </mat-error>
          <div class="mat-form-fields" *ngIf="other">
            <mat-form-field class="medium-full other-response">
              <textarea #dmgother maxlength="35" matInput placeholder="" formControlName="damageOther"></textarea>
              <mat-hint align="end">
                {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{35 -
                dmgother.value.length}}
              </mat-hint>
              <mat-error *ngIf="whatHappenedFormGroup.get('damageOther').errors">
                <div *ngIf="whatHappenedFormGroup.get('damageOther').errors['required']">
                  {{ 'claims.edit.whatHappened.merchandise.description.damage.where.otherRequired' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="merchandise-missing" *ngIf="missing">
          <div class="panel-section-title">
            {{ 'claims.edit.whatHappened.merchandise.description.missing.day.title' | translate }}<span
              class="red">*</span>
          </div>
          <div class="mat-form-fields">
            <mat-form-field class="width-30" (click)="missingPicker.open()">
              <input matInput [matDatepicker]="missingPicker" [max]="maxDate" required readonly
                formControlName="missingDate" class="date-icon"
                placeholder="{{ 'claims.edit.whatHappened.merchandise.description.missing.day.label' | translate }}">
              <mat-datepicker-toggle matSuffix [for]="missingPicker">
                <svg matDatepickerToggleIcon id="baseline-date_range-24px" xmlns="http://www.w3.org/2000/svg"
                  width="31.711" height="31.711" viewBox="0 0 31.711 31.711">
                  <path id="Path_219" data-name="Path 219"
                    d="M10.928,13.892H8.285v2.643h2.643Zm5.285,0H13.57v2.643h2.643Zm5.285,0H18.855v2.643H21.5Zm2.643-9.249H22.819V2H20.177V4.643H9.606V2H6.964V4.643H5.643A2.631,2.631,0,0,0,3.013,7.285L3,25.783a2.642,2.642,0,0,0,2.643,2.643h18.5a2.65,2.65,0,0,0,2.643-2.643V7.285A2.65,2.65,0,0,0,24.141,4.643Zm0,21.141H5.643V11.249h18.5Z"
                    transform="translate(0.964 0.643)" fill="#737373" />
                  <path id="Path_220" data-name="Path 220" d="M0,0H31.711V31.711H0Z" fill="none" />
                </svg>
              </mat-datepicker-toggle>
              <mat-datepicker [touchUi]="isMobile" #missingPicker></mat-datepicker>
              <mat-error *ngIf="whatHappenedFormGroup.get('missingDate').errors">
                <div *ngIf="whatHappenedFormGroup.get('missingDate').errors['required']">
                  {{ 'claims.edit.whatHappened.merchandise.description.damage.day.required' | translate }}
                </div>
                <div *ngIf="whatHappenedFormGroup.get('missingDate').errors['matDatepickerMin']">
                  {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.min' | translate }}
                </div>
                <div *ngIf="whatHappenedFormGroup.get('missingDate').errors['matDatepickerMax']">
                  {{ 'claims.edit.start.shippingInfo.form.shipmentDetails.form.shipDate.errors.max' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panel-section-title missing-locations">
            {{ 'claims.edit.whatHappened.merchandise.description.damage.where.title' | translate }}<span
              class="red">*</span>
          </div>
          <div class="mat-form-fields mb-3">
            <mat-radio-group class="radio-group" formControlName="missingLocations">
              <mat-radio-button *ngFor="let locations of damagedLocations" value="{{locations.id}}"
                (click)="toggleOther(false)">{{
                locations.text
                }}</mat-radio-button>
              <mat-radio-button #other value="3" (click)="toggleOther(true)">{{
                'claims.edit.whatHappened.merchandise.description.damage.where.other' |
                translate }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-error class="standalone" style="font-size: 75%"
            *ngIf="whatHappenedFormGroup.get('missingLocations').errors && whatHappenedFormGroup.controls['missingLocations']?.touched">
            {{ 'claims.edit.whatHappened.merchandise.description.damage.where.errors.required' | translate }}
          </mat-error>
          <div class="mat-form-fields" *ngIf="other.checked">
            <mat-form-field class="medium-full other-response">
              <textarea #missingother maxlength="35" matInput placeholder="" formControlName="missingOther"></textarea>
              <mat-hint align="end">
                {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{35 -
                missingother.value.length}}
              </mat-hint>
              <mat-error *ngIf="whatHappenedFormGroup.get('missingOther').errors">
                <div *ngIf="whatHappenedFormGroup.get('missingOther').errors['required']">
                  {{ 'claims.edit.whatHappened.merchandise.description.missing.otherRequired' | translate }}
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!--           <div class="merchandise-late" *ngIf="late">
            <div class="panel-section-title late-care">{{
              'claims.edit.whatHappened.merchandise.description.late.how.label' | translate }}<span class="red">*</span>
            </div>
            <div class="mat-form-fields mb-3">
              <mat-radio-group class="radio-group" formControlName="lateCare">
                <mat-radio-button value="0" #reship>
                  {{'claims.edit.whatHappened.merchandise.description.late.how.reship.label'
                  | translate }}</mat-radio-button>
                <div class="reship-wrapper" *ngIf="reship.checked">
                  <div class="mat-form-fields">
                    <mat-form-field class="medium-full reship-response">
                      <textarea matInput maxlength="45" #latereship
                        placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.reship.placeholder' | translate }}"
                        formControlName="lateReship"></textarea>
                      <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                        45 - latereship.value.length }}</mat-hint>
                      <mat-error *ngIf="whatHappenedFormGroup.get('lateReship').errors">
                        <div *ngIf="whatHappenedFormGroup.get('lateReship').errors['required']">
                          {{ 'claims.edit.whatHappened.merchandise.description.late.how.reship.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <mat-radio-button value="1" #refund>
                  {{'claims.edit.whatHappened.merchandise.description.late.how.refund.label'
                  | translate }} <mat-icon
                    ngbTooltip="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.tooltip' | translate }}"
                    tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                </mat-radio-button>
                <div class="refund-wrapper" *ngIf="refund.checked">
                  <div class="mat-form-fields">
                    <mat-form-field class="medium-full refund-response">
                      <textarea matInput maxlength="45" #laterefund
                        placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.placeholder' | translate }}"
                        formControlName="lateRefund"></textarea>
                      <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                        45 - laterefund.value.length }}</mat-hint>
                      <mat-error *ngIf="whatHappenedFormGroup.get('lateRefund').errors">
                        <div *ngIf="whatHappenedFormGroup.get('lateRefund').errors['required']">
                          {{ 'claims.edit.whatHappened.merchandise.description.late.how.refund.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-radio-group>
            </div>
            <mat-error class="standalone"
              *ngIf="whatHappenedFormGroup.get('lateCare').errors && whatHappenedFormGroup.controls['lateCare']?.touched">
              {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.required' | translate }}</mat-error>
          </div> -->
        <!-- <div class="merchandise-late" *ngIf="loss && cargo && (isUsaUser || isCanadaUser)">
            <div class="panel-section-title late-care">{{
              'claims.edit.whatHappened.merchandise.description.late.how.label' | translate }}<span class="red">*</span>
            </div>
            <div class="mat-form-fields mb-3">
              <mat-radio-group class="radio-group" formControlName="lossCare">
                <mat-radio-button value="0">{{'claims.edit.whatHappened.merchandise.description.late.how.reship.label'
                  | translate }}</mat-radio-button>
                <div class="reship-wrapper" *ngIf="whatHappenedFormGroup.get('lossCare').value==='0'">
                  <div class="mat-form-fields">
                    <mat-form-field class="medium-full reship-response">
                      <textarea matInput maxlength="45" #lossreship
                        placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.reship.placeholder' | translate }}"
                        formControlName="lossReship"></textarea>
                      <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                        45 - lossreship.value.length }}</mat-hint>
                      <mat-error *ngIf="whatHappenedFormGroup.get('lossReship').errors">
                        <div *ngIf="whatHappenedFormGroup.get('lossReship').errors['required']">
                          {{ 'claims.edit.whatHappened.merchandise.description.late.how.reship.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <mat-radio-button value="1">{{'claims.edit.whatHappened.merchandise.description.late.how.refund.label'
                  | translate }} <mat-icon
                    ngbTooltip="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.tooltip' | translate }}"
                    tooltipClass="ngb-tooltip-class" [autoClose]="false">info_outline</mat-icon>
                </mat-radio-button>
                <div class="refund-wrapper" *ngIf="whatHappenedFormGroup.get('lossCare').value==='1'">
                  <div class="mat-form-fields">
                    <mat-form-field class="medium-full refund-response">
                      <textarea matInput maxlength="45" #lossrefund
                        placeholder="{{'claims.edit.whatHappened.merchandise.description.late.how.refund.placeholder' | translate }}"
                        formControlName="lossRefund"></textarea>
                      <mat-hint align="end">{{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{
                        45 - lossrefund.value.length }}</mat-hint>
                      <mat-error *ngIf="whatHappenedFormGroup.get('lossRefund').errors">
                        <div *ngIf="whatHappenedFormGroup.get('lossRefund').errors['required']">
                          {{ 'claims.edit.whatHappened.merchandise.description.late.how.refund.required' | translate }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-radio-group>
            </div>
          </div> -->
        <!-- <mat-error class="standalone"
            *ngIf="whatHappenedFormGroup.get('lossCare').errors && whatHappenedFormGroup.controls['lossCare']?.touched">
            {{ 'claims.edit.whatHappened.merchandise.description.late.how.errors.required' | translate }}
          </mat-error> -->
        <!-- <div>
            <div class="title mb-2 mt-3">{{ 'claims.edit.whatHappened.merchandise.description.purpose.title' | translate
              }}<span class="red">*</span></div>
            <mat-radio-group class="radio-group" formControlName="purpose">
              <mat-radio-button value="personal">{{ 'claims.edit.whatHappened.merchandise.description.purpose.personal'
                | translate }}</mat-radio-button>
              <mat-radio-button value="sale">{{ 'claims.edit.whatHappened.merchandise.description.purpose.sale' |
                translate }}</mat-radio-button>
              <mat-radio-button value="other">{{ 'claims.edit.whatHappened.merchandise.description.purpose.other' |
                translate }}</mat-radio-button>
              <div class="refund-wrapper" *ngIf="whatHappenedFormGroup.get('purpose').value=='other'">
                <div class="mat-form-fields">
                  <mat-form-field class="medium-full refund-response">
                    <textarea matInput maxlength="45" formControlName="purposeOther" #purposeOther></textarea>
                    <mat-hint align="end">
                      {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}
                      {{ 45 - purposeOther.value.length }}
                    </mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </mat-radio-group>
            <mat-error class="standalone"
              *ngIf="whatHappenedFormGroup.get('purpose').errors && whatHappenedFormGroup.get('purpose').touched">
              <div *ngIf="whatHappenedFormGroup.get('purpose').errors['required']">
                {{ 'claims.edit.whatHappened.merchandise.description.purpose.errors.required' | translate }}
              </div>
            </mat-error>
          </div> -->
      </div>
    </div>

    <div class="header">
      <div class="title">
        <div class="text">
          <div class="title-bold">{{ 'claims.edit.whatHappened.value.title' | translate }}</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="section mb-3">
      <div class="subsection d-flex flex-column flex-xl-row justify-content-between">
        <div class="claim-amount-boxes pr-xl-2 currency" *ngIf="isPageLoad">
          <div class="label">{{ 'claims.edit.whatHappened.value.form.currency.hint' | translate }}</div>
          <mat-form-field>
            <mat-select class="currency-select" required formControlName="currency" [(value)]="defaultCurrency">
              <mat-option *ngFor="let item of listCurrency" [value]="item.value">
                {{ item.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="whatHappenedFormGroup.get('currency').errors">
              <div *ngIf="whatHappenedFormGroup.get('currency').errors['required']">
                {{ 'claims.edit.whatHappened.value.form.currency.errors.required' | translate }}
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="claim-amount-boxes pr-xl-2">
          <div class="label">{{ 'claims.edit.whatHappened.value.form.merchandise.label' | translate }}</div>
          <mat-form-field>
            <input matInput required formControlName="merchandise" (blur)="checkDV()" appDecimalFieldMask
              class="currency-align">
            <mat-error *ngIf="whatHappenedFormGroup.get('merchandise').errors">
              <div *ngIf="whatHappenedFormGroup.get('merchandise').errors['required']">
                {{ 'claims.edit.whatHappened.value.form.merchandise.errors.required' | translate }}
              </div>
              <div *ngIf="whatHappenedFormGroup.get('merchandise').errors['pattern']">
                {{ 'claims.edit.whatHappened.value.form.merchandise.errors.pattern' | translate }}
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <span class="add mr-xl-2">+</span>
        <div class="claim-amount-boxes pr-xl-2">
          <div class="label d-flex justify-content-between">{{ 'claims.edit.whatHappened.value.form.shipping.label' |
            translate }}<mat-icon ngbTooltip="{{ 'claims.edit.whatHappened.value.form.shipping.tooltip' | translate }}"
              tooltipClass="ngb-tooltip-class">info_outline
            </mat-icon>
          </div>
          <mat-form-field>
            <input matInput required formControlName="shipping" appDecimalFieldMask class="currency-align">
            <mat-error *ngIf="whatHappenedFormGroup.get('shipping').errors">
              <div *ngIf="whatHappenedFormGroup.get('shipping').errors['required']">
                {{ 'claims.edit.whatHappened.value.form.shipping.errors.required' | translate }}
              </div>
              <div *ngIf="whatHappenedFormGroup.get('shipping').errors['pattern']">
                {{ 'claims.edit.whatHappened.value.form.shipping.errors.pattern' | translate }}
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <span class="equal mr-xl-2">=</span>
        <div class="claim-amount-boxes">
          <div class="label">{{ 'claims.edit.whatHappened.value.form.amount.label' | translate}}</div>
          <mat-form-field>
            <input matInput required readonly formControlName="amount" class="currency-align">
            <mat-error *ngIf="whatHappenedFormGroup.get('amount').errors">
              <div *ngIf="whatHappenedFormGroup.get('amount').errors['required']">
                {{ 'claims.edit.whatHappened.value.form.amount.errors.required' | translate }}
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- comment -->
    <div class="notifications mb-3">
      <upsc-notification-box [isWarning]="true" class="warning-box" *ngIf="tupssType == 'tccp' && !hideDeclaredWarning">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="icon-warning-wrapper p-1 d-flex">
              <mat-icon>info_outline</mat-icon>
            </div>
            <div class="text">Reminder: At the time of shipment, this package had additional coverage purchased in the
              amount of ${{ persistIShipData?.data.response.cdsresponse?.shipmentInfo.insuredValue_Total }}.</div>
          </div>
          <div class="close-icon" (click)="hideDeclaredWarning = true">
            <mat-icon>cancel</mat-icon>
          </div>
        </div>
      </upsc-notification-box>
      <upsc-notification-box [isWarning]="true" class="warning-box"
        *ngIf="tupssType == 'tccp' && !hideNoDeclaredWarning">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="icon-warning-wrapper p-1 d-flex">
              <mat-icon>info_outline</mat-icon>
            </div>
            <div class="text">Reminder: At the time of shipment, additional coverage for this package was not
              purchased. Fortunately, your claim may still be eligible for a maximum of up to $100 plus shipping
              costs.</div>
          </div>
          <div class="close-icon" (click)="hideNoDeclaredWarning= true">
            <mat-icon>cancel</mat-icon>
          </div>
        </div>
      </upsc-notification-box>
      <!-- <upsc-notification-box [isWarning]="true" class="warning-box" [class.hide]="hideDeclaredWarning"
          *ngIf="tupssType == 'tfcp'">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="icon-warning-wrapper p-1 d-flex">
                <mat-icon>info_outline</mat-icon>
              </div>
              <div class="text">Reminder: At the time of shipment, customer declared the value to ${{
                persistIShipData?.data.response.cdsresponse.shipmentInfo.insuredValue_Total }}.</div>
            </div>
            <div class="close-icon" (click)="hideDeclaredWarning = true">
              <mat-icon>cancel</mat-icon>
            </div>
          </div>
        </upsc-notification-box>
        <upsc-notification-box [isWarning]="true" class="warning-box" [class.hide]="hideNoDeclaredWarning"
          *ngIf="tupssType == 'tfcp'">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="icon-warning-wrapper p-1 d-flex">
                <mat-icon>info_outline</mat-icon>
              </div>
              <div class="text">No declared value was placed on this shipment</div>
            </div>
            <div class="close-icon" (click)="hideNoDeclaredWarning = true">
              <mat-icon>cancel</mat-icon>
            </div>
          </div>
        </upsc-notification-box> -->
    </div>
    <div class="header">
      <div class="title">
        <div class="text">
          <div class="title-bold">
            {{ 'claims.edit.whatHappened.additional.title' | translate }}
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="section">
      <div class="subsection">
        <div class="subtitle">
          {{ 'claims.edit.whatHappened.additional.subtitle' | translate }}
        </div>
        <mat-form-field>
          <textarea matInput #comments maxlength="250" formControlName="additionalComments"></textarea>
          <mat-hint align="end">
            {{ 'claims.edit.whatHappened.merchandise.description.hint' | translate }}{{ 250 - comments.value.length }}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="step-controls">
      <div class="btnAlign">
        <button class="upsc-button" role="cancel" type="button" (click)="cancel()">
          <div>{{ 'claims.edit.cancel' | translate }}</div>
        </button>
      </div>
      <div class="btnAlign">
        <button class="upsc-button" role="button" type="submit">
          <div>{{ 'claims.edit.submit' | translate }}</div>
        </button>
      </div>
    </div>
  </div>
</form>