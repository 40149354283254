import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'upsc-terms-of-agreement',
  templateUrl: './terms-of-agreement.component.html',
  styleUrls: ['./terms-of-agreement.component.scss']
})
export class TermsOfAgreementComponent {

  messageData = {
    title: "",
    message: ""
  };

  constructor(
    private dialogRef: MatDialogRef<TermsOfAgreementComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    
  }

  decline() {
    this.dialogRef.close("Decline");
  }

  accept() {
    this.dialogRef.close("Accept");
  }
}
