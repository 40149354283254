import { Component, HostBinding, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'upsc-flex-layout',
  templateUrl: './flex-layout.component.html',
  styleUrls: ['./flex-layout.component.scss']
})
export class FlexLayoutComponent implements OnInit {
  @HostBinding('class') public hostClass = 'flex-layout';
  isAdjuster: boolean;
  currentUrl: string;
  showInsureshieldFooter: boolean = false;
  showONLFooter: boolean = false;
  constructor(private userService: UserService,
    private router: Router,) { }

  public ngOnInit(): void {
    
  }

}

