import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavigationComponent } from './top-navigation/top-navigation.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactUsPopupComponent } from './contact-us-popup/contact-us-popup.component';



@NgModule({
  declarations: [
    TopNavigationComponent,
    ContactUsPopupComponent
  ],
  imports: [
    SharedModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    MatMenuModule
  ],
  providers: [ { provide: MAT_DIALOG_DATA, useValue: {} }],
  exports: [TopNavigationComponent, ContactUsPopupComponent]
})
export class TopNavigationModule { }
