import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CollapsiblePanel2Component } from './collapsible-panel2.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
  ],
  declarations: [CollapsiblePanel2Component],
  providers: [],
  exports: [CollapsiblePanel2Component],
})
export class CollapsiblePanel2Module {}
