import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment as ENV } from '../../environments/environment';
import { ApiEndpoints as API } from '../../config/api-endpoints';
import { distinctUntilChanged, filter, retry } from 'rxjs/operators';
import { sessionInfo, iCreatePayee, payeeInfo, updateACH, claimFlow } from '../shared/models/payee.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { emptyClaim, IClaim } from '../shared/models/claim.interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PayeeService {

  private options = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  }
  recipientAddFlow: claimFlow = {}; // To track the flow of adding receipient details created claim flow
  // addBankToThisPayeeId = ""; //Used during initiate session transfer
  recentPayeeSubmission: iCreatePayee = {}; //Used for displaying recently created payee info on bank page
  displayPreexistingPayee: payeeInfo = {}; //Used for displaying preexisting payee info on bank page
  achURL = "";  //Used for generating the iFrame url prior to the bank page loading
  initiateSessionFailed = false; //Used for displaying the error message on the bank page if initiateSessionTransfer API failed
  dpsUnavailable = false; //Used for displaying message on unableToFulfill page when the DPS API is down.

  _activePayeeMonitor: BehaviorSubject<string> = new BehaviorSubject('null');
  claimsPayeeInfo = null;
  inDesktopView = true;
  public claim: IClaim = emptyClaim;
  constructor(private route: ActivatedRoute,
    private http: HttpClient,
    private userService: UserService
  ) {
    this._activePayeeMonitor.pipe(
      distinctUntilChanged(),
      filter(payID => payID != 'null')
    ).subscribe(
      payID => {
        if (sessionStorage.getItem('userDetails')) {
            this.retrieveACHInfo(payID);
        }
      }
    )
  }

  getPayee(request): Observable<any> {
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      return this.http.post(`${ENV.baseUrl.api}${API.GET_PAYEES}`, { "policyNumber": request.policyNumber, "objectUID": shopifyUID }, this.options);
    }
    else {
      return this.http.post(`${ENV.baseUrl.api}${API.GET_PAYEES}`, request, this.options);
    }
  }

  getCardInfo(payeeID: string, policyNum: string): Observable<any> {
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      return this.http.post(`${ENV.baseUrl.api}${API.GET_CARD_DIGITS}`, { "PayeeID": payeeID, "policyNumber": policyNum, "objectUID": shopifyUID }, this.options);
    }
    else {
      return this.http.post(`${ENV.baseUrl.api}${API.GET_CARD_DIGITS}`, { "PayeeID": payeeID, "policyNumber": policyNum }, this.options);
    }
  }

  initiatePublicSessionTransfer(sessionInfo: sessionInfo): Observable<any> {
    return this.http.post(`${ENV.baseUrl.api}${API.INITIATE_PUBLIC_SESSION_TRANSFER}`, sessionInfo, this.options).pipe(
      retry(3)
    );
  }

  createPayee(model: iCreatePayee): Observable<any> {
    return this.http.post(`${ENV.baseUrl.api}${API.CREATE_PAYEE}`, model, this.options).pipe(
      retry(3)
    );
  }

  updateACHIndicator(model: updateACH): Observable<any> {
    return this.http.patch(`${ENV.baseUrl.api}${API.UPDATE_ACH_INDICATOR}`, model, this.options).pipe(
      retry(3)
    );
  }

  get activePayeeMonitor() {
    return this._activePayeeMonitor;
  }

  retrieveACHInfo(payeeID) {
    const prefetch = this.route.snapshot.data.prefetch;
    if (prefetch) {
      this.claim = prefetch.claim;
    }

    let policyNumber = JSON.parse(sessionStorage.getItem('policyDetails')).policyNumber;

    this.getCardInfo(payeeID, policyNumber).subscribe(
      data => {
        if (data.accountNumberLast4) {
          this.updateACHIndicator({
            payeeID: payeeID,
            achIndicator: 'T'
          }).subscribe(
            res => {
              this.activePayeeMonitor.next('null');
            },
            err => {
              this.activePayeeMonitor.next('null')
            }
          )
        }
        else {
          // console.log('no banking info found');
          this.activePayeeMonitor.next('null');
        }
      },
      err => {
        this.activePayeeMonitor.next('null');
      }
    );
  }

  getClaimsPayeeInfo() {
    return this.claimsPayeeInfo;
  }

  isInDesktopView() {
    return this.inDesktopView;
  }

  setClaimsPayeeInfo(payeeInfo, inDesktopView) {
    this.claimsPayeeInfo = payeeInfo;
    this.inDesktopView = inDesktopView;
  }

}