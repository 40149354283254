import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { NavigationModule } from '../navigation/navigation.module';
import { LayoutComponent } from './layout.component';
import { TopNavigationModule } from '../top-navigation/top-navigation.module';
import { DigitalWalletBannerModule } from '../digital-wallet-banner/digital-wallet-banner.module';
import { SwitchPolicyNotifyModule } from '../switch-policy-notify/switch-policy-notify.module';
import { VisibilityAgreementFlyoutModule } from '../visibility-agreement-flyout/visibility-agreement-flyout.module';
import { Is4upsBannerModule } from '../is4ups-banner/is4ups-banner.module';
@NgModule({
  imports: [
    FooterModule,
    HeaderModule,
    NavigationModule,
    SharedModule,
    TopNavigationModule,
    DigitalWalletBannerModule,
    SwitchPolicyNotifyModule,
    VisibilityAgreementFlyoutModule,
    Is4upsBannerModule
  ],
  declarations: [LayoutComponent],
  providers: [],
})
export class LayoutModule {}
