import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'upsc-us-checkbox',
  templateUrl: './us-checkbox.component.html',
  styleUrls: ['./us-checkbox.component.scss']
})
export class UsCheckboxComponent implements OnInit {

  @Output() cbChecked: EventEmitter<boolean> = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.cbFormGroup = this.formBuilder.group({
      checkboxFormControl: ['', [(control) => {
        return !control.value ? { 'required': true } : null;
      }]]
    });
  }

  cbFormGroup: UntypedFormGroup;

  public submitForm(): boolean {
    if (this.cbFormGroup.valid) {
      return true;
    } else {
      return false;
    }
  }

  showChecked(event: MatCheckboxChange): void {
    this.cbChecked.emit(event.checked);
  }

}
