<div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
  <div class="spinner-border">
    <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>

<div class="prospect-landing-wrapper" *ngIf="!showSpinner && showEmailVerification">
  <upsc-rectangle-progress-bar [totalwidth]="'firstpage'" [percent]="50" [text]="'You\'re 50% done with your application. Halfway there!'"></upsc-rectangle-progress-bar>
  <div class="title mb-5">
    Let's Get Started
    <div class="green-line"></div>
  </div>
  <upsc-email-verification [flow]="'prospect'" (resend)="sendVerificationCode($event)" [email]="URLemail" *ngIf="showEmailVerification && !showAddressValidation && !internalUser">

  </upsc-email-verification>
  
  <div class="form-wrapper mt-5" *ngIf="!showAddressValidation">
    <form [formGroup]="formGroup" (ngSubmit)="submitForm()">
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Name</mat-label>
        <input matInput required formControlName="name">
        <mat-error>
          <div class="error">
            <div class="error-text">Name is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Email</mat-label>
        <input matInput required formControlName="email">
        <mat-error>
          <div class="error">
            <div class="error-text">Email is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Company Name</mat-label>
        <input matInput required formControlName="company">
        <mat-error>
          <div class="error">
            <div class="error-text">Company Name is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Phone Number</mat-label>
        <input matInput mask="000-000-0000" required formControlName="phone">
        <mat-error>
          <div class="error">
            <div class="error-text">Phone Number is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Country *</mat-label>
        <!-- <input matInput required formControlName="country"> -->
        <mat-select formControlName="country">
          <mat-option [value]="'US'">United States of America</mat-option>
        </mat-select>
        <mat-error>
          <div class="error">
            <div class="error-text">Country is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <div class="city-state-zip">
        <mat-form-field [floatLabel]="'auto'">
          <mat-label>City</mat-label>
          <input matInput required formControlName="city">
          <mat-error>
            <div class="error">
              <div class="error-text">City is required</div>
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'auto'">
          <mat-label>State *</mat-label>
          <!-- <input matInput required formControlName="state"> -->
          <mat-select formControlName="state">
            <mat-option *ngFor="let state of fullStateList" [value]="state.state">{{state.state}}</mat-option>
          </mat-select>
          <mat-error>
            <div class="error">
              <div class="error-text">State is required</div>
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'auto'">
          <mat-label>Zip Code</mat-label>
          <input matInput required formControlName="zip" maxlength="5">
          <mat-error>
            <div class="error">
              <div class="error-text">Zip Code is required</div>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Address Line 1</mat-label>
        <input matInput required formControlName="address1">
        <mat-error>
          <div class="error">
            <div class="error-text">Address Line 1 is required</div>
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'auto'">
        <mat-label>Shipper Number(s) - Separate multiple UPS Shipper Number(s) with commas *</mat-label>
        <!-- <input matInput required formControlName="shipperNumbers"> -->
        <textarea formControlName="shipperNumbers" maxlength="250" matInput></textarea>
        <mat-hint class="d-flex justify-content-between">
          <div>Separate multiple UPS Shipper Number(s) with commas.</div>
          <div>{{ 250 - shipperNumbers.value.length }}</div>
        </mat-hint>
        <mat-error>
          <div class="error">
            <div class="error-text">Shipper Number is required</div>
          </div>
        </mat-error>
      </mat-form-field>
  
      <div class="recaptcha mt-3" *ngIf="!internalUser">
        <re-captcha formControlName="recaptcha"></re-captcha>
        <mat-error *ngIf="recaptcha.invalid && recaptcha.touched">
          <div class="error">
            <div class="error-text">Recaptcha is required</div>
          </div>
        </mat-error>
      </div>
  
      <div class="submit-wrapper">
        <button class="upsc-button" type="submit" *ngIf="!loading">
          Confirm Details <mat-icon>chevron_right</mat-icon>
        </button>
        <button type="button" class="upsc-button continuing" *ngIf="loading">
          <div>Confirm Details</div>
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
          </div>
        </button>
      </div>
      <div class="error" *ngIf="verificationCodeError">
        Verification Code did not match
      </div>
      <div class="error" *ngIf="systemError">
        System error occurred. Please try again later.
      </div>
      <div class="error" *ngIf="denied">
        Unfortunately at this time we are unable to process your request.
      </div>
    </form>
  </div>
  <div class="help my-5" *ngIf="!showAddressValidation">
    <div class="title mb-4">
      Need Help?
      <div class="green-line"></div>
    </div>
    <mat-accordion>
      <mat-expansion-panel hideToggle (click)="onSpeakClick($event)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Speak With A Representative Now
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex">
          <div class="mr-3">
            <img src="../../../../assets/images/agent.PNG">
          </div>
          <div>
            <div class="s32">InsureShield<sup>®</sup></div>
            <div class="s32 mb-3">Customer Service</div>
            <div class="mb-3">M-F 8:00 a.m. to 8:00 p.m. EST.</div>
            <button class="upsc-white-button">
              1-877-242-7930<mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  
  <div class="addressValidation" *ngIf="showAddressValidation">
    <upsc-address-validation [currentAddressFromProspect]="currentAddress" [addressListFromProspect]="addressList" (userInput)="updateAddress($event)">
    </upsc-address-validation>
  </div>
</div>

<div *ngIf="fatalError" class="error ml-3">
  System encountered an error and could not move forward. 
</div>

<div *ngIf="showInvalidCampaign" class="d-flex align-items-center justify-content-center flex-column">
  <div><img src="../../../assets/images/timer.png"></div>
  <div class="invalid-text">We're sorry. Your window to secure these preapproved rates has expired. If you would like to have a consultation with one of our licensed risk experts, please give us a call at 877-263-8772 (option 3).</div>
</div>