import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[upscDebounce]'
})
export class DebounceDirective implements OnInit, OnDestroy {

  @Input()
  debounceTime = 500; //Can be set in .html by using [debounceTime]=""

  //click events
  @Output()
  debounceClick = new EventEmitter();
  //To use in .html, (debounceClick) = originalMethodCall();

  //input events
  @Output()
  debounceInput = new EventEmitter();

  //Used in click events
  private clicks = new Subject();
  private subscription: Subscription;

  //used in input events
  private inputs = new Subject();
  private inputSubscription: Subscription;

  ngOnInit() {
    //click events
    this.subscription = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => this.debounceClick.emit(e.toString()));

    //input events
    this.inputSubscription = this.inputs.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => this.debounceInput.emit(e))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.inputSubscription.unsubscribe();
  }

  //Listens for a click event
  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  //Listens for an input event
  @HostListener('input', ['$event'])
  inputEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.inputs.next(event);
  }

}
