import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiEndpoints as API } from '../../../../../config/api-endpoints';
import { environment } from '../../../../../environments/environment'
import { UserService } from 'src/app/services/user.service';
import { switchMap, map } from 'rxjs/operators';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import  * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TupssService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  _userDetails: { [key: string]: any } = sessionStorage.getItem('userDetails') ? JSON.parse(sessionStorage.getItem('userDetails')) : {}; //this is the mirror data as JSON.parse(sessionStotage.getItem('userDetails')).
  _userData: any; //this is franchise user's data, used for tfcp only.
  userData$: BehaviorSubject<{[key: string]: any}> = new BehaviorSubject(null);
  _claimSubmittedFor: any; //This will hold the tracking number of the successfully submitted claim. If user tries to press back button after submitting a claim, we can check this variable on whether or not to send them back to the Enter TN page
  claimSubmittedFor$: BehaviorSubject<any> = new BehaviorSubject(null);
  policySearchError$: BehaviorSubject<any> = new BehaviorSubject(null);
  pirateShipClaimStep$: BehaviorSubject<any> = new BehaviorSubject(null);
  _priateShipClaimStep: any;  //What the currentStep of the pirateShip flow is inside of tupss-edit component.

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService) {

    }

  public tfcpAuthentication(franchiseIdentity){
    return this.http.post<any>(`${environment.baseUrl.api}${API.FRANCHISE_AUTH}`,
      {
        Token: franchiseIdentity
      },
      this.httpOptions).pipe(
      switchMap((data) => {
        if(data.accessToken && data.tupssUserDetails) {
          sessionStorage.setItem(`access_token`, data.accessToken);
          this.userService.setUserPermissions(data.tupssUserDetails.permissions);
          this.setItem('type', 'tfcp')
          this.userData = {
            ...data.tupssUserDetails,
            'userRoleID': "6" //to indicate that tupss uses a different kind of token
          }
          return of(this.userData)
        }
        else {
          throwError(new Error('Unauthorized'));
        }
    }));
  }

  getContractId(siteId: string, shipperNumber: string = '', shipDate?: string) {
    if(shipDate) {
      shipDate = shipDate.split('T')[0];
    }
    return this.http.post<any>(
      `${environment.baseUrl.api}${API.GET_CONTRACT_ID}`,
      {
        'shipperNumber': shipperNumber,
        'siteID': siteId,
        'shipDate': shipDate
      },
      this.httpOptions);
  }

  getSiteIds() {
    if(this._userData) {
      let siteIds = this.convertToArray(this._userData.tupssScope);

      if(siteIds.length == 1 && siteIds[0].toLowerCase() == 'all') {
        siteIds = [];
      }
      return siteIds;
    } else {
      let siteIds = JSON.parse(sessionStorage.getItem('tupssScope'));
      if(siteIds.length == 1 && siteIds[0].toLowerCase() == 'all') {
        siteIds = [];
      }
      return siteIds;
  }
}

  convertToArray(tupssScope: any): string[] {
    if(typeof tupssScope == 'string') {
      return tupssScope.split(/\s*,\s*/);
    }
    else {// array of site ids
      return _.join(tupssScope, ',').split(/\s*,\s*/);
    }
  }

  get userData() {
    return this._userData;
  }

  get claimSubmittedFor() {
    return this._claimSubmittedFor;
  }

  set pirateShipClaimStep(step) {
    this._priateShipClaimStep = step;
    this.pirateShipClaimStep$.next(this._priateShipClaimStep);
  }

  get pirateShipClaimStep() {
    return this._priateShipClaimStep;
  }

  set claimSubmittedFor(trackingNumber) {
    this._claimSubmittedFor = trackingNumber;
    this.claimSubmittedFor$.next(this._claimSubmittedFor);
  }

  set policySearchError(boolean) {
    this.policySearchError$.next(boolean);
  }

  set userData(data) {
    this._userData = data;
    if(this._userDetails) {
      this.userService.setUserInfo({...this._userDetails, ...this._userData})
    }
    else {
      this.userService.setUserInfo(this._userData);
    }
    this.userData$.next(this._userData);
  }

  setSiteId(siteId: string) {
    this._userData = {...this._userData, ...{'siteId': siteId }};
    if(this._userDetails) {
      this.userService.setUserInfo({...this._userDetails, ...this._userData})
    }
    else {
      this.userService.setUserInfo(this._userData);
    }
  }
  setContractId(contractId: string) {
    this._userData = {...this._userData, ...{'contractId': contractId }};
    if(this._userDetails) {
      this.userService.setUserInfo({...this._userDetails, ...this._userData, ...{policyNumber: contractId}})
    }
    else {
      this.userService.setUserInfo({...this._userData, ...{policyNumber: contractId}});
    }
  }

  setPolicyToUseForFilingAClaim(policyDetails) {  //holds policy details now
    this._userData = {...this._userData, policyDetails: policyDetails};
  }

  get userDetails() {
    return this._userDetails;
  }

  set userDetails(data) {
    this._userDetails = data;
    sessionStorage.setItem('userDetails', JSON.stringify(this._userDetails));
    this.userService.setUserInfo({...this._userDetails, ...{'userRoleID': '6'}}); //add tupss user role id 6 for route guard
  }

  getItem(key) {
    if(!this._userDetails || Object.keys(this._userDetails).length == 0) {
      this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    }
    return this._userDetails ? this._userDetails[key] : null;
  }

  setItem(key, value) {
    this._userDetails[key] = value;
    sessionStorage.setItem('userDetails', JSON.stringify(this._userDetails));
  }

  removeItem(key) {
    delete this._userDetails[key];
    sessionStorage.setItem('userDetails', JSON.stringify(this._userDetails));
  }

  getTfcpTokenString() {
    return sessionStorage.getItem('URLFragment');
  }

  getTfcpIdToken() {
    let params = this.getTfcpTokenString()?.match(/id_token=([\w_.\-]+)/i);
    if(params && params.length > 1) {
      return params[1];
    }
    else {
      return null;
    }
  }

  openFaqs() {
    window.open(`${environment.baseUrl.web}tupss/faqs#${sessionStorage.getItem('userDetails')}`, '_blank');
  }

  openFaqsQ14() {
    window.open(`${environment.baseUrl.web}tupss/faqs?q14=1#${sessionStorage.getItem('userDetails')}`, '_blank');
  }

  sendTFCPClaimSubmissionEmail(payload) {
    return this.http.post<any>(`${environment.baseUrl.api}${API.TFCP_CLAIM_SUBMISSION}`, payload, this.httpOptions)
  }

  sendTCCPClaimSubmissionEmail(payload) {
    return this.http.post<any>(`${environment.baseUrl.api}${API.TCCP_CLAIM_SUBMISSION}`, payload, this.httpOptions)
  }

  sendTCCPContactUs(payload) {
    return this.http.post<any>(`${environment.baseUrl.api}${API.TCCP_CONTACT_US}`, payload, this.httpOptions);
  }

  sendTFCPContactUs(payload) {
    return this.http.post<any>(`${environment.baseUrl.api}${API.TFCP_CONTACT_US}`, payload, this.httpOptions);
  }

  getIShipData(trackingInfo) {
    return this.http.post<any>(`${environment.baseUrl.api}${API.CHECK_SHIPMENT}`, trackingInfo, this.httpOptions).pipe(
      map((res) => {
        let zip = res.data?.zip;
        if (zip && zip.length > 5) {
          zip = `${zip.substring(0, 5)}-${zip.substring(5)}`;
        }
        res.data.zip = zip;
        return res;
      })
    );
  }

  sendTupssDetailVerificationCode(payload) {
    payload.CountryLocale = sessionStorage.getItem('locale');
    return this.http.post<any>(`${environment.baseUrl.api}${API.CLAIM_DETAIL_VERIFICATION_CODE}`, payload, this.httpOptions);
  }
}
