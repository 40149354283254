<form [formGroup]="pageSelectorForm" class="pageDropDownForm">
  <div class="pagination-center">
    <div *ngIf="pager.pages && pager.pages.length" class="pagination">
      <div class="nav-buttons-disabled">
        <a class="compact-link" *ngIf="pager.currentPage<=1">
          <mat-icon class="back-icon">chevron_left</mat-icon> {{ 'claims.list.component.pager.previous' | translate }}
        </a>
      </div>
      <div class="nav-buttons">
        <a class="compact-link" (click)="setPage(pager.currentPage - 1)" *ngIf="pager.currentPage>1">
          <mat-icon class="back-icon">chevron_left</mat-icon> {{ 'claims.list.component.pager.previous' | translate }}
        </a>
      </div>
     <!--  <mat-form-field class="smallWidth">
        <mat-label class="text-select">Page Number</mat-label>
        <input matInput formControlName="numberPage" (keydown.enter)="onPagerChange($event)">
      </mat-form-field> -->

      <div class="pageNumber">
        <mat-form-field>
          <input matInput formControlName="numberPage" (keydown.enter)="onPagerChange($event)">
        </mat-form-field>
      </div>

      <div class="nav-buttons"><span class="text-of-link">of {{pager.totalPages}}</span></div>
      <div class="nav-buttons">
        <a class="compact-link" (click)="setPage(pager.currentPage + 1)" *ngIf="pager.currentPage<pager.totalPages">
          {{ 'claims.list.component.pager.next' | translate }} <mat-icon class="back-icon">chevron_right</mat-icon>
        </a>
      </div>
      <div class="nav-buttons-disabled">
        <a class="compact-link" *ngIf="pager.currentPage===pager.totalPages">
          {{ 'claims.list.component.pager.next' | translate }} <mat-icon class="back-icon">chevron_right</mat-icon>
        </a>
      </div>
      <mat-form-field class="pagerDrop">
        <mat-label class="text-select">{{ 'claims.list.component.pager.assetsPerPage' | translate }}</mat-label>
        <mat-select formControlName="assetsPerPage" (selectionChange)="onAssetChange($event)">
          <mat-option *ngFor="let item of pageAssets" [value]="item.size">{{item.size}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="pagination-center-mobile">
    <div *ngIf="pager.pages && pager.pages.length" class="pagination-nav">
      <div class="nav-buttons-disabled">
        <a class="compact-link" *ngIf="pager.currentPage<=1">
          <mat-icon class="back-icon">chevron_left</mat-icon>
        </a>
      </div>
      <div class="nav-buttons">
        <a class="compact-link" (click)="setPage(pager.currentPage - 1)" *ngIf="pager.currentPage>1">
          <mat-icon class="back-icon">chevron_left</mat-icon>
        </a>
      </div>
      <div class="pageNumber">
        <mat-form-field>
          <input matInput formControlName="numberPage" (keydown.enter)="onPagerChange($event)">
        </mat-form-field>
      </div>
      <div class="nav-buttons"><span class="text-of-link">of {{pager.totalPages}}</span></div>
      <div [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="nav-buttons">
        <a class="compact-link" (click)="setPage(pager.currentPage + 1)" *ngIf="pager.currentPage<pager.totalPages">
          <mat-icon class="back-icon">chevron_right</mat-icon>
        </a>
      </div>
      <div class="nav-buttons-disabled">
        <a class="compact-link" *ngIf="pager.currentPage===pager.totalPages">
          <mat-icon class="back-icon">chevron_right</mat-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="pagination-center-mobile">
    <div class="pagination-asset">
      <mat-form-field class="pagerDrop">
        <mat-label class="text-select">{{ 'claims.list.component.pager.assetsPerPage' | translate }}</mat-label>
        <mat-select formControlName="assetsPerPage" (selectionChange)="onAssetChange($event)">
          <mat-option *ngFor="let item of pageAssets" [value]="item.size">{{item.size}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>