import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { IStep } from './models/step.interface';
import { DatePipe } from '@angular/common';
import { TupssEditComponent } from 'src/app/pages/claims-custom/tupss/tupss-edit/tupss-edit.component';

@Component({
  selector: 'upsc-tupss-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [DatePipe]
})
export class TuppsStepperComponent implements OnChanges {
  @HostBinding('class') public hostClass = 'stepper';

  @Input() public steps: IStep[];
  @Input() public currentStep: number;

  public progress: number;

  constructor(private claimComp: TupssEditComponent) {
  }

 

  public ngOnChanges(changes: SimpleChanges) {
    this.onCurrentStepChanged(changes['currentStep']);
  }

  

  private onCurrentStepChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.setProgress();
  }


  public setCurrentStepManual(order: number){
    this.claimComp.setCurrentStepManually(order);
  }

  private setProgress() {
    if (!this.steps || !this.steps.length) {
      this.progress = 0;
      return;
    }

    this.steps.forEach(
      (step) => {
        step.isActive = step.order === this.currentStep;
      },
    );

    this.progress = (this.currentStep - 1) * 100 / (this.steps.length - 1);
  }
}
