import { Component } from '@angular/core';
import { GuestLinkPreview } from '../../models/guest-link-preview';

@Component({
  selector: 'upsc-dynamic-layout',
  templateUrl: './dynamic-layout.component.html',
  styleUrls: ['./dynamic-layout.component.scss']
})
export class DynamicLayoutComponent {

  flowType = '';
  previewModel: GuestLinkPreview = {};
  color: string = '';

  constructor(){
    this.flowType = sessionStorage.getItem("flowType");
    var previewModelString = sessionStorage.getItem("previewModelString");
      if (previewModelString !== null && previewModelString.length > 3) {
        var previewModel = JSON.parse(previewModelString);
        if (previewModel !== null) {
          this.previewModel = previewModel;
          if (previewModel && previewModel.theme === 'light') {
            document.documentElement.style.setProperty('--backgroundColor', previewModel.backgroundColor);
            document.documentElement.style.setProperty('--foregroundColor', previewModel.foregroundColor);            
          } else {
            document.documentElement.style.setProperty('--backgroundColor', previewModel.backgroundColor);
            document.documentElement.style.setProperty('--foregroundColor', previewModel.foregroundColor);
          }
        }
      }
  }

}
