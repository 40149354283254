<div class="lableMobile" *ngIf="isPagination" [routerLink]="['/claims']">
  <mat-icon class="back-icon">chevron_left</mat-icon>
  {{ 'claimsDetail.back' | translate }}
</div>

<div class="claimInfo">
  <b class="value-wrap2">{{ 'claims.DraftList.ClaimsDrafts' | translate }}</b>
</div>

<div class="main">
  <!-- toolbar desktop start -->
  <div class="toolbar-desktop" *ngIf="isPagination">
    <div class="toolbar-items">
      <div class="search-category">
        <div class="err-container">
        <mat-form-field>
          <mat-label>Search Category</mat-label>
          <mat-select [formControl]="searchTypeControl" [disableOptionCentering]="true" panelClass="search-bar-spacing"
            (openedChange)="handleOpenChange()">
            <mat-option *ngFor="let option of searchFilters" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </div>
      <div class="search-bar">

        <div class="err-container">
         
        <mat-form-field [floatLabel]="'never'">
          <input matInput pattern="\S+" (change)="searchByQuery()" (keyup)="trim($event)" name="searchQuery"
            [formControl]="searchByValueControl" placeholder="{{searchBarPlaceholder}}">
          <mat-icon matSuffix *ngIf="isEnabled" (click)="refreshList()">{{'close'}}</mat-icon>
          <mat-error *ngIf="valueErrors">
            <div *ngIf="searchByValueControl.invalid && searchByValueControl.touched">
              <div *ngIf="searchByValueControl.errors.required">
                <div class="error-input" *ngIf="isReceiverSearch && valueErrors">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'claims.list.claimsSearch.defaultSearch.receiverRequired' | translate }}</div>
                </div>
                <div class="error-input" *ngIf="isTrackingSearch && valueErrors">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text"> {{ 'claims.list.claimsSearch.defaultSearch.trackingNumberRequired' | translate }}</div>
                </div>
              </div>
            </div>
          </mat-error>
        </mat-form-field>
        </div>  
      </div>
      <div class="search-button pr-3">
        <button type="button" class="upsc-button" #searchButton upscDebounce [debounceTime]="1000"
          (debounceClick)="search()" (click)="markedAsTouched()">
          <div class="text"> {{ 'claims.list.claimsSearch.defaultSearch.title' | translate
          }}</div><mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>

   <!-- toolbar mobile start -->
   <div class="toolbar-desktop-mobile" *ngIf="isPagination">
    <div class="toolbar-items-mobile">
      <div class="search-category">
        <div class="err-container">
        <mat-form-field>
          <mat-label>Search Category</mat-label>
          <mat-select [formControl]="searchTypeControl" [disableOptionCentering]="true" panelClass="search-bar-spacing"
            (openedChange)="handleOpenChange()">
            <mat-option *ngFor="let option of searchFilters" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </div>
      <div class="search-bar">
        <div class="err-container">
        <mat-form-field [floatLabel]="'never'">
          <input matInput pattern="\S+" (change)="searchByQuery()" (keyup)="trim($event)" name="searchQuery"
            [formControl]="searchByValueControl" placeholder="{{searchBarPlaceholder}}">
          <mat-icon matSuffix *ngIf="isEnabled" (click)="refreshList()">{{'close'}}</mat-icon>
          <mat-error *ngIf="valueErrors">
            <div *ngIf="searchByValueControl.invalid && searchByValueControl.touched">
              <div *ngIf="searchByValueControl.errors.required">
                <div class="error-input" *ngIf="isReceiverSearch && valueErrors">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text">{{ 'claims.list.claimsSearch.defaultSearch.receiverRequired' | translate }}</div>
                </div>
                <div class="error-input" *ngIf="isTrackingSearch && valueErrors">
                  <mat-icon>report_problem</mat-icon>
                  <div class="error-text"> {{ 'claims.list.claimsSearch.defaultSearch.trackingNumberRequired' | translate }}</div>
                </div>
              </div>
            </div>
          </mat-error>
        </mat-form-field>
        </div>  
      </div>
      <div class="search-button pr-3">
        <button type="button" class="upsc-button" #searchButton upscDebounce [debounceTime]="1000"
          (debounceClick)="search()" (click)="markedAsTouched()">
          {{ 'claims.list.claimsSearch.defaultSearch.title' | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- new table designs below -->
    <div *ngIf="loading" class="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="claims-container" *ngIf="true">
    <!-- no drafts found message for both desktop+mobile -->
    <div class="notification-box" *ngIf="!isDataPresent && !loading">
      <upsc-notification-box [isWarning]="true" *ngIf="!isDataPresent">
        <div class="box-format">
          <mat-icon>warning</mat-icon>
          <div class="text">No Drafts Found.</div>
        </div>
      </upsc-notification-box>
    </div>
    <!-- Desktop -->
    <div class="desktop-claim-table" #table>
      <div class="overflow-format">
        <table class="upsc-table-isop">
          <tbody>
            <tr>
              <th class="headerHeight25">
                {{ 'claims.DraftList.DraftTable.draftCreated' | translate }}
              </th>
              <th class="headerHeight25">
                {{ 'claims.DraftList.DraftTable.trackingBOL' | translate }}
              </th>
              <th class="headerHeight25">
                {{ 'claims.DraftList.DraftTable.receiver' | translate }}
              </th>
              <th class="headerHeight25">
                {{ 'claims.DraftList.DraftTable.draftStatus' | translate }}
              </th>

            </tr>
            <tr *ngFor="let row of pagedItems; let i = index"
              [class.selected]="row.claimNumber && row.claimNumber === currentClaim">
              <td *ngIf="isUsaUser">
                {{row.fileDate | date: 'MM/dd/yy':'UTC'}}
              </td>
              <td *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                {{row.fileDate |date: 'dd/MM/yy':'UTC'}}
              </td>
              <td *ngIf="isFileClaim">
                <a class="draftLink" (click)="startDraftClaim(row.claimNumber)" >
                  {{row.tracking | uppercase }}
                </a>
              </td>
              <td *ngIf="!isFileClaim">
                <a class="draftLink disable-cursor">
                  {{row.tracking | uppercase }}
                </a>
              </td>
              <td>{{row.receiver | titlecase }}</td>
              <td *ngIf="isUsaUser">
                <span class="pad-right10">{{ row.reference}}{{ row.fileDate | date: 'MM/dd/yy':'UTC' }} {{
                  row.claimStatus }}</span>
                <img *ngIf="!emptySearchResult" class="pointerImage" (click)="delete(row.claimNumber)"
                  src="../../../../assets/images/delete_icon.svg">
              </td>
              <td *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
                <span class="pad-right10">
                  {{ row.reference}}{{ row.fileDate | date: 'dd/MM/yy':'UTC' }} {{ row.claimStatus }}</span>
                <img *ngIf="!emptySearchResult" class="pointerImage" (click)="delete(row.claimNumber)"
                  src="../../../../assets/images/delete_icon.svg">
              </td>
            </tr>
          </tbody>
        </table>
      </div>     
    </div>
    <div *ngIf="pager.currentPage>0 && pageSize>0" class="desktop-claim-table">
      <upsc-list-pager [currentPage]="pager.currentPage" [pager]="pager" [pagePerAsset]="pageSize" (pagerNotification)="setPager($event)"
        (assetNotification)="loadAssetPerPage($event)" *ngIf="isPagination && !emptySearchResult"></upsc-list-pager>
    </div>
    <!-- Mobile -->
    <div class="mobile-claim-table" *ngIf="isDataPresent">
      <div *ngFor="let row of pagedItems">
        <div class="claim-item">
          <div class="item-header m1">
            <div class="title-text mr-auto">{{ 'claims.DraftList.DraftTable.draftSaved' | translate }}: {{ row.savedTime |
              date: 'dd/MM/yy h:mm a':'UTC' }}</div>
            <img *ngIf="!emptySearchResult" class="pointerImage" (click)="delete(row.claimNumber)"
              src="../../../../assets/images/delete_icon.svg">
          </div>
          <div class="item-row">
            <div class="item-text left"> {{ 'claims.DraftList.DraftTable.trackingBOL' | translate }}</div>
            <div class="item-text right">{{ 'claims.DraftList.DraftTable.receiver' | translate }}</div>
          </div>
          <div class="item-row m2">
            <div class="left">
              {{row.tracking | uppercase }}</div>
            <div class="right">
              {{row.receiver | titlecase }}</div>
          </div>
          <div class="item-row">
            <div class="item-text left"> {{ 'claims.DraftList.DraftTable.draftCreated' | translate }}</div>
            <div class="item-text right" *ngIf="isUsaUser">{{row.fileDate | date: 'MM/dd/yy':'UTC'}}</div>
            <div class="item-text right" *ngIf="isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser">
              {{row.fileDate | date: 'dd/MM/yy':'UTC'}}</div>
          </div>
          <div class="item-footer">
            <a class="claim-link ml-auto" (click)="startDraftClaim(row.claimNumber)"> {{
              'claims.DraftList.DraftTable.continueFiling' | translate }}<mat-icon
                class="spacing">keyboard_arrow_right</mat-icon></a>
          </div>
        </div>
      </div>
      <div *ngIf="pager.currentPage>0 && pageSize>0" class="paginate-mobile">
        <upsc-list-pager [currentPage]="pager.currentPage" [pager]="pager" [pagePerAsset]="pageSize"
          (pagerNotification)="setPager($event)" (assetNotification)="loadAssetPerPage($event)"
          *ngIf="isPagination && !emptySearchResult"></upsc-list-pager>
      </div>
    </div>   
    <div class="error mt-2" *ngIf="systemError">{{ 'common.systemError' | translate }}</div>
  </div>  
</div>