import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { UtilityService } from '../../../services/utility.service';

@Component({
  selector: 'upsc-collapsible-panel-white',
  templateUrl: './collapsible-panel-white.component.html',
  styleUrls: ['./collapsible-panel-white.component.scss']
})
export class CollapsiblePanelWhiteComponent {
  @HostBinding('class') public hostClass = 'collapsible-panel-container';

  @Input() public isCollapsed = false;
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public showInfo: boolean;
  @Input() public showRed: boolean;
  @Input() public showWarning: boolean;
  @Input() edit: boolean;
  @Output() updatePayee = new EventEmitter<boolean>(false);
  @Output() collapsedEvent = new EventEmitter<boolean>(false);
  public tooltipPosition$: any;

  

  constructor(private utilService: UtilityService) {
    this.tooltipPosition$ = this.utilService.tooltipPositionWatch();
  }

  editPayee() {
    this.updatePayee.emit(true);
  }

  unCollapsed(){
    this.collapsedEvent.emit(true);
  }

  collapsed(){
    this.collapsedEvent.emit(false);
  }

  topCollapsed(isCollapsed){
    this.collapsedEvent.emit(!isCollapsed);
  }
  
}
