<div class="popup-global-layout width-850 p-4 p-md-5">
  <div *ngIf="currentTemplate == 1">
    <ng-container *ngTemplateOutlet="walletNoTCsWithCheckFeeTemplate"></ng-container>
  </div>
  <div *ngIf="currentTemplate == 2">
    <ng-container *ngTemplateOutlet="walletNoTCsNoCheckFeeTemplate"></ng-container>
  </div>
  <div *ngIf="currentTemplate == 3">
    <ng-container *ngTemplateOutlet="noWalletNoCheckFeeTemplate"></ng-container>
  </div>
</div>

<!-- Has a wallet, has not accepted T&Cs yet, and is being charged a check fee -->
<ng-template #walletNoTCsWithCheckFeeTemplate>
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">{{ 'checkFee.title' | translate }}</div>
  <div class="global-green-bar"></div>
  <div class="global-text">{{ 'checkFee.noTCsWithFeeText1' | translate }}<sup>®</sup>{{ 'checkFee.noTCsWithFeeText2' | translate }}
    <span class="bold">{{ 'checkFee.noTCsWithFeeText3' | translate }}</span>{{ 'checkFee.noTCsWithFeeText4' | translate }}<sup>®</sup>
    {{ 'checkFee.noTCsWithFeeText5' | translate }}
  </div>
  <div class="global-button-format">
    <button class="upsc-white-button2 auto" (click)="continueWithCheck()">{{ 'checkFee.proceedWithCheck' | translate }}<mat-icon
        class="icon-key">keyboard_arrow_right</mat-icon></button>
    <button class="upsc-green-popup-button auto" (click)="enrollInWallet()"
      *ngIf="canAccessDigitalWallet">{{ 'checkFee.enroll' | translate }}<mat-icon
        class="icon-key2">keyboard_arrow_right</mat-icon></button>
  </div>
  <div class="error" *ngIf="sessionError">{{ 'common.systemError' | translate }}</div>
</ng-template>

<!-- Has a wallet, has not accepted T&Cs yet, and is NOT being charged a check fee -->
<ng-template #walletNoTCsNoCheckFeeTemplate>
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">{{ 'checkFee.title' | translate }}</div>
  <div class="global-green-bar"></div>
  <div class="global-text">{{ 'checkFee.noTCsNoFeeText1' | translate }}<sup>®</sup>{{ 'checkFee.noTCsNoFeeText2' | translate }}</div>
  <div class="global-button-format">
    <button class="upsc-white-button2 auto" (click)="continueWithCheck()">{{ 'checkFee.proceedWithCheck' | translate }}<mat-icon
        class="icon-key">keyboard_arrow_right</mat-icon></button>
    <button class="upsc-green-popup-button auto" (click)="enrollInWallet()"
      *ngIf="canAccessDigitalWallet">{{ 'checkFee.enroll' | translate }}<mat-icon
        class="icon-key2">keyboard_arrow_right</mat-icon></button>
  </div>
  <div class="error" *ngIf="sessionError">{{ 'common.systemError' | translate }}</div>
</ng-template>

<!-- Does not have a wallet, but is being charged a check fee -->
<ng-template #noWalletNoCheckFeeTemplate>
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">{{ 'checkFee.title' | translate }}</div>
  <div class="global-green-bar"></div>
  <div class="global-text">
    {{ 'checkFee.noWalletWithFeeText1' | translate }}
    {{ checkFeeAmount | currency:'USD' }}
    {{ 'checkFee.noWalletWithFeeText2' | translate }}
  </div>
  <div class="global-button-format">
    <button class="upsc-green-popup-button auto" (click)="continueWithCheck()">{{ 'checkFee.proceedWithClaim' | translate }}<mat-icon
        class="icon-key2">keyboard_arrow_right</mat-icon></button>
  </div>
  <div class="error" *ngIf="sessionError">{{ 'common.systemError' | translate }}</div>
</ng-template>